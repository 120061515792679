import type { Action } from 'store/types';

import type { AddNewUserDataType, EditUserData, User } from './types';
import { UsersActions } from './types';

const {
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILURE,
  GET_FILTERED_USER_REQUEST,
  GET_FILTERED_USER_SUCCESS,
  GET_FILTERED_USER_FAILURE,
  ADD_NEW_USER_REQUEST,
  ADD_NEW_USER_SUCCESS,
  ADD_NEW_USER_FAILURE,
  SEND_CONFIRMATION_EMAIL_TO_USER_REQUEST,
  SEND_CONFIRMATION_EMAIL_TO_USER_SUCCESS,
  SEND_CONFIRMATION_EMAIL_TO_USER_FAILURE,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  ACTIVE_OR_INACTIVE_USER_REQUEST,
  ACTIVE_OR_INACTIVE_USER_SUCCESS,
  ACTIVE_OR_INACTIVE_USER_FAILURE,
} = UsersActions;

function getUsersRequest(): Action {
  return {
    type: GET_ALL_USERS_REQUEST,
  };
}

function getUsersSuccess(users: User[]): Action {
  return {
    type: GET_ALL_USERS_SUCCESS,
    payload: {
      users,
    },
  };
}

function getUsersFailure(): Action {
  return {
    type: GET_ALL_USERS_FAILURE,
  };
}

function getSearchFilteredUserRequest(
  filteredUsers: Array<string | boolean | null>
): Action {
  return {
    type: GET_FILTERED_USER_REQUEST,
    payload: {
      filteredUsers,
    },
  };
}

function getSearchFilteredUserSuccess(users: User[]): Action {
  return {
    type: GET_FILTERED_USER_SUCCESS,
    payload: {
      users,
    },
  };
}

function getSearchFilteredUserFailure(): Action {
  return {
    type: GET_FILTERED_USER_FAILURE,
  };
}

function addNewUserRequest(user: AddNewUserDataType): Action {
  return {
    type: ADD_NEW_USER_REQUEST,
    payload: {
      user,
    },
  };
}

interface AddNewUserSuccessProps {
  newUserAdded: User;
  sendAccessInvitation: boolean;
}

function addNewUserSuccess({
  newUserAdded,
  sendAccessInvitation,
}: AddNewUserSuccessProps): Action {
  return {
    type: ADD_NEW_USER_SUCCESS,
    payload: {
      newUserAdded,
      sendAccessInvitation,
    },
  };
}

function addNewUserFailure(): Action {
  return {
    type: ADD_NEW_USER_FAILURE,
  };
}

function sendConfirmationEmailToUserRequest(userId: string): Action {
  return {
    type: SEND_CONFIRMATION_EMAIL_TO_USER_REQUEST,
    payload: {
      userId,
    },
  };
}

function sendConfirmationEmailToUserSuccess(userId: string): Action {
  return {
    type: SEND_CONFIRMATION_EMAIL_TO_USER_SUCCESS,
    payload: {
      userId,
    },
  };
}

function sendConfirmationEmailToUserFailure(): Action {
  return {
    type: SEND_CONFIRMATION_EMAIL_TO_USER_FAILURE,
  };
}

function editUserRequest(user: EditUserData): Action {
  return {
    type: EDIT_USER_REQUEST,
    payload: {
      user,
    },
  };
}

function editUserSuccess(): Action {
  return {
    type: EDIT_USER_SUCCESS,
  };
}

function editUserFailure(): Action {
  return {
    type: EDIT_USER_FAILURE,
  };
}

function activeOrInactiveUserRequest(
  userId: string,
  newUserStatus: boolean
): Action {
  return {
    type: ACTIVE_OR_INACTIVE_USER_REQUEST,
    payload: {
      userId,
      newUserStatus,
    },
  };
}

function activeOrInactiveUserSuccess(newUserStatus: boolean): Action {
  return {
    type: ACTIVE_OR_INACTIVE_USER_SUCCESS,
    payload: {
      newUserStatus,
    },
  };
}

function activeOrInactiveUserFailure(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_USER_FAILURE,
  };
}

export const usersActionsFunctions = {
  getUsersRequest,
  getUsersSuccess,
  getUsersFailure,
  getSearchFilteredUserRequest,
  getSearchFilteredUserSuccess,
  getSearchFilteredUserFailure,
  addNewUserRequest,
  addNewUserSuccess,
  addNewUserFailure,
  sendConfirmationEmailToUserRequest,
  sendConfirmationEmailToUserSuccess,
  sendConfirmationEmailToUserFailure,
  editUserRequest,
  editUserSuccess,
  editUserFailure,
  activeOrInactiveUserRequest,
  activeOrInactiveUserSuccess,
  activeOrInactiveUserFailure,
};
