import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import { CustomerProjectPayload } from 'store/modules/Manutention/Frameworks/types';
import { Action } from 'store/types';

import { customerSectorsActionsFunctions } from './actions';
import { Sectors, CustomerSectorsActions } from './types';


const {
  GET_ALL_CUSTOMER_SECTORS_REQUEST,
} = CustomerSectorsActions;

const {
  getCustomerSectorsSuccess,
  getCustomerSectorsFailure,
} = customerSectorsActionsFunctions;

const {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE
} = ToastMessages;

const {
  getLastApiFetchDataSuccess,
  getLastApiFetchDataFailure
} = lastApiFetchDataActionsFunctions;

function* fetchAllCustomerSectorsRequest(action: Action): Generator {
  const { isCustomerProjectPage } = action.payload as CustomerProjectPayload;
  try {
    const customerSectorsResponse: AxiosResponse<Sectors[]> | unknown = yield call(
      api,
      'GET',
      '/customers/sectors-subsectors-segments/',
      {}
    );

    const {
      data: sectors,
      config: { url },
      status,
      statusText,
    } = customerSectorsResponse as AxiosResponse<Sectors[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCustomerSectorsSuccess(sectors));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomerSectorsFailure());

    if (currentError?.response?.status === 403) {
      if(!isCustomerProjectPage)
        toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

export function* customerSectorsSagas() {
  yield all([
    takeLatest(GET_ALL_CUSTOMER_SECTORS_REQUEST, fetchAllCustomerSectorsRequest),
  ]);
}
