export enum CrownJewelsActions {
  EDIT_CROWNJEWELS_REQUEST = 'EDIT_CROWNJEWELS_REQUEST',
  EDIT_CROWNJEWELS_SUCCESS = 'EDIT_CROWNJEWELS_SUCCESS',
  EDIT_CROWNJEWELS_FAILURE = 'EDIT_CROWNJEWELS_FAILURE',
  GET_ALL_CROWNJEWELS_REQUEST = 'GET_ALL_CROWNJEWELS_REQUEST',
  GET_ALL_CROWNJEWELS_SUCCESS = 'GET_ALL_CROWNJEWELS_SUCCESS',
  GET_ALL_CROWNJEWELS_FAILURE = 'GET_ALL_CROWNJEWELS_FAILURE',
  ADD_NEW_CROWNJEWELS_REQUEST = 'ADD_NEW_CROWNJEWELS_REQUEST',
  ADD_NEW_CROWNJEWELS_SUCCESS = 'ADD_NEW_CROWNJEWELS_SUCCESS',
  ADD_NEW_CROWNJEWELS_FAILURE = 'ADD_NEW_CROWNJEWELS_FAILURE',
  GET_CROWNJEWELS_DETAILS_REQUEST = 'GET_CROWNJEWELS_DETAILS_REQUEST',
  GET_CROWNJEWELS_DETAILS_SUCCESS = 'GET_CROWNJEWELS_DETAILS_SUCCESS',
  GET_CROWNJEWELS_DETAILS_FAILURE = 'GET_CROWNJEWELS_DETAILS_FAILURE',
  GET_FILTERED_CROWNJEWELS_REQUEST = 'GET_FILTERED_CROWNJEWELS_REQUEST',
  GET_FILTERED_CROWNJEWELS_SUCCESS = 'GET_FILTERED_CROWNJEWELS_SUCCESS',
  GET_FILTERED_CROWNJEWELS_FAILURE = 'GET_FILTERED_CROWNJEWELS_FAILURE',
  RESET_THE_LAST_CROWNJEWELS_DETAILS = 'RESET_THE_LAST_CROWNJEWELS_DETAILS',
  ACTIVE_OR_INACTIVE_CROWNJEWELS_REQUEST = 'ACTIVE_OR_INACTIVE_CROWNJEWELS_REQUEST',
  ACTIVE_OR_INACTIVE_CROWNJEWELS_SUCCESS = 'ACTIVE_OR_INACTIVE_CROWNJEWELS_SUCCESS',
  ACTIVE_OR_INACTIVE_CROWNJEWELS_FAILURE = 'ACTIVE_OR_INACTIVE_CROWNJEWELS_FAILURE',
  GET_ALL_CONTROL_CROWNJEWELS_REQUEST = 'GET_ALL_CONTROL_CROWNJEWELS_REQUEST',
  GET_ALL_CONTROL_CROWNJEWELS_SUCCESS = 'GET_ALL_CONTROL_CROWNJEWELS_SUCCESS',
  GET_ALL_CONTROL_CROWNJEWELS_FAILURE = 'GET_ALL_CONTROL_CROWNJEWELS_FAILURE',
}

export type AddNewCrownJewelsRequest = {
  name: string;
  weight: string | null;
  is_active: boolean;
};

export type CrownJewelsDetailsRequest = {
  crownJewelsId: string;
};

export type AddNewCrownJewelsRequestPayload = {
  crownJewels: AddNewCrownJewelsRequest;
};

export type SearchFilteredCrownJewelsRequestPayloadParams = {
  filteredCrownJewels: Array<string | boolean | null>;
  weight: string | null;
  isActive: boolean | null;
};

export type UpdateStatusRequestPayloadParams = {
  id: string;
  updatedStatus: boolean;
};

export type CrownJewelsResults = {
  results: CrownJewels[];
};

export type CrownJewels = {
  id: string;
  name: string;
  weight: string;
  is_active: boolean;
};

export type CrownJewelsWithId = {
  crownJewelsId: string;
  name: string;
  weight: string;
  is_active: boolean;
};

export type CrownJewelsWithoutId = {
  name: string;
  weight: string;
  is_active: boolean;
};

export type CrownJewelsDetails = {
  id: string;
  name: string;
  weight: string;
  is_active: boolean;
};

export type CrownJewelsState = {
  allCrownJewels: CrownJewels[];
  currentCrownJewelsDetails: CrownJewelsDetails;
  controlCrownJewels: CrownJewels[];
};
