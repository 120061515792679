import type { Action } from 'store/types';

import { ControlActions, ControlBody, ControlDetail, ControlEditBody, ControlEditRequestBody } from './types';

const {
  GET_CONTROL_DETAIL_REQUEST,
  GET_CONTROL_DETAIL_SUCCESS,
  GET_CONTROL_DETAIL_FAILURE,
  CREATE_NEW_CONTROL_REQUEST,
  CREATE_NEW_CONTROL_SUCCESS,
  CREATE_NEW_CONTROL_FAILURE,
  EDIT_CONTROL_REGISTRATION_REQUEST,
  EDIT_CONTROL_REGISTRATION_SUCCESS,
  EDIT_CONTROL_REGISTRATION_FAILURE,
  EDIT_CONTROL_REQUEST,
  EDIT_CONTROL_SUCCESS,
  EDIT_CONTROL_FAILURE,
  EDIT_CONTROL_STATUS_REQUEST,
  EDIT_CONTROL_STATUS_SUCCESS,
  EDIT_CONTROL_STATUS_FAILURE
} = ControlActions;


function getControlDetailRequest(controlId: string): Action {
  return {
    type: GET_CONTROL_DETAIL_REQUEST,
    payload: {
      controlId
    },
  };
}

function getControlDetailSuccess(control: ControlDetail): Action {
  return {
    type: GET_CONTROL_DETAIL_SUCCESS,
    payload: {
      control
    },
  };
}

function getControlDetailFailure(): Action {
  return {
    type: GET_CONTROL_DETAIL_FAILURE,
  };
}

function editControlStatusRequest(controlId: string, controlStatus: boolean): Action {
  return {
    type: EDIT_CONTROL_STATUS_REQUEST,
    payload: {
      controlId,
      controlStatus
    },
  };
}

function editControlStatusSuccess(): Action {
  return {
    type: EDIT_CONTROL_STATUS_SUCCESS,
  };
}

function editControlStatusFailure(): Action {
  return {
    type: EDIT_CONTROL_STATUS_FAILURE,
  };
}

function createNewControlRequest(controlBody: ControlBody): Action {
  return {
    type: CREATE_NEW_CONTROL_REQUEST,
    payload: {
      controlBody
    },
  };
}

function createNewControlSuccess(): Action {
  return {
    type: CREATE_NEW_CONTROL_SUCCESS,
  };
}

function createNewControlFailure(): Action {
  return {
    type: CREATE_NEW_CONTROL_FAILURE,
  };
}

function editControlRegistrationsRequest(controlId: string, controlEditBody: ControlEditBody): Action {
  return {
    type: EDIT_CONTROL_REGISTRATION_REQUEST,
    payload: {
      controlId,
      controlEditBody
    },
  };
}

function editControlRegistrationsSuccess(): Action {
  return {
    type: EDIT_CONTROL_REGISTRATION_SUCCESS,
  };
}

function editControlRegistrationsFailure(): Action {
  return {
    type: EDIT_CONTROL_REGISTRATION_FAILURE,
  };
}

function editControlRequest(controlId: string, controlEditBody: ControlEditRequestBody, controlEditRegistrationBody: ControlEditBody): Action {
  return {
    type: EDIT_CONTROL_REQUEST,
    payload: {
      controlId,
      controlEditBody,
      controlEditRegistrationBody
    },
  };
}

function editControlSuccess(): Action {
  return {
    type: EDIT_CONTROL_SUCCESS,
  };
}

function editControlFailure(): Action {
  return {
    type: EDIT_CONTROL_FAILURE,
  };
}


export const controlActionsFunctions = {
  getControlDetailRequest,
  getControlDetailSuccess,
  getControlDetailFailure,
  createNewControlRequest,
  createNewControlSuccess,
  createNewControlFailure,
  editControlRegistrationsRequest,
  editControlRegistrationsSuccess,
  editControlRegistrationsFailure,
  editControlRequest,
  editControlSuccess,
  editControlFailure,
  editControlStatusRequest,
  editControlStatusSuccess,
  editControlStatusFailure
};
