import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import type { Action } from 'store/types';

import { customerProjectControlActionsFunctions } from './actions';
import { 
  Controls,
  CustomerControlStatic,
  CustomerProjectsControls,
  CustomerControlApiPayload,
  CustomerProjectControlActions,
  CustomerProjetcControlApiPayload,
  CustomerProjetcChangeStatusApiPayload,
  CustomerProjetcControlDetailApiPayload,
  CustomerControlPartiallyPayload,
} from './types';

const {
  GET_CUSTOMER_PROJECT_CONTROLS_REQUEST,
  GET_CONTROLS_CUSTOMER_PROJECT_DETAIL_REQUEST,
  EDIT_CONTROL_CUSTOMER_PROJECT_STATUS_REQUEST,
  GET_CUSTOMER_PROJECT_CONTROLS_STATIC_REQUEST,
  PUT_CUSTOMER_CONTROL_PARTIALLY_REQUEST,
} = CustomerProjectControlActions;

const {
  getCustomerProjectControlsSuccess,
  getCustomerProjectControlsFailure,
  getCustomerProjectControlsDetailSuccess,
  getCustomerProjectControlsDetailFailure,
  editCustomerProjectControlsStatusSuccess,
  editCustomerProjectControlsStatusFailure,
  getCustomerProjectControlStaticRequest,
  getCustomerProjectControlStaticSuccess,
  getCustomerProjectControlStaticFailure,
  putCustomerControlPartiallySuccess,
  putCustomerControlPartiallyFailure,
} = customerProjectControlActionsFunctions;

const {
  getLastApiFetchDataSuccess,
  getLastApiFetchDataFailure
} = lastApiFetchDataActionsFunctions;

const {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE
} = ToastMessages;

function* fetchCustomersProjectsControlsRequest(action: Action): Generator {
  const { customerProjectId } = action.payload as CustomerProjetcControlApiPayload;

  try {
    const customerProjectControlResponse: AxiosResponse<CustomerProjectsControls> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${customerProjectId}/controls/`,
      {}
    );

    const {
      data: customersProjectsControl,
      config: { url },
      status,
      statusText,
    } = customerProjectControlResponse as AxiosResponse<CustomerProjectsControls>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCustomerProjectControlsSuccess(customersProjectsControl));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomerProjectControlsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchThreatsCustomersControlDetailProject(action: Action): Generator {
  const { controlId } = action.payload as CustomerProjetcControlDetailApiPayload;

  try {
    const controlCustomerProjectResponse: AxiosResponse<Controls> | unknown = yield call(
      api,
      'GET',
      `/controls/control-customer-project/${controlId}/detail/`,
      {}
    );

    const {
      data: control,
      config: { url },
      status,
      statusText,
    } = controlCustomerProjectResponse as AxiosResponse<Controls>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCustomerProjectControlsDetailSuccess(control));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomerProjectControlsDetailFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* editControlCustomerProjectStatusRequest(action: Action): Generator {
  const { controlId, changeStatusBody, customerProjectId } = action.payload as CustomerProjetcChangeStatusApiPayload;

  try {
    const customerProjectResponse: AxiosResponse<CustomerProjectsControls> | unknown = yield call(
      api,
      'PUT',
      `/controls/control-customer-project/${controlId}/update-status/`,
      { ...changeStatusBody }
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<CustomerProjectsControls>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editCustomerProjectControlsStatusSuccess());

    toast.success('Status alterado com sucesso.');

    // yield put(getCustomerProjectControlsRequest(customerProjectId));
    yield put(getCustomerProjectControlStaticRequest(customerProjectId));

  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editCustomerProjectControlsStatusFailure());

    toast.success('Erro ao alterar o status.');
  }
}

function* fetchCustomersProjectsControlStaticRequest(action: Action): Generator {
  const { projectId } = action.payload as CustomerControlApiPayload;

  try {
    const customerProjectControlResponse: AxiosResponse<CustomerControlStatic> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${projectId}/controls-customer-project-static/`,
      {}
    );

    const {
      data: customerControls,
      config: { url },
      status,
      statusText,
    } = customerProjectControlResponse as AxiosResponse<CustomerControlStatic>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCustomerProjectControlStaticSuccess(customerControls));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomerProjectControlStaticFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchPutCustomerControlPartially(action: Action): Generator {
  const { justification_for_partially_applied, partially_applied, controlId, customerProjectId } = action.payload as CustomerControlPartiallyPayload;

  try {
    const customerControlPartiallyResponse: AxiosResponse<CustomerProjectControlActions> | unknown = yield call(
      api,
      'PUT',
      `/controls/control-customer-project/${controlId}/update-partially-applied/`,
      {
        partially_applied,
        justification_for_partially_applied,
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerControlPartiallyResponse as AxiosResponse<CustomerProjectControlActions>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(putCustomerControlPartiallySuccess()); 
    
    const toastMessage = partially_applied ? 'Atualização parcialmente aplicada com sucesso.' : 'Atualização totalmente aplicada com sucesso.';
    toast.success(toastMessage);
    
    yield put(getCustomerProjectControlStaticRequest(customerProjectId));

  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(putCustomerControlPartiallyFailure()); 

    toast.error('Erro ao fazer a atualização parcialmente aplicada.');
  }
}

export function* customerProjectControlSagas() {
  yield all([
    takeLatest(GET_CUSTOMER_PROJECT_CONTROLS_REQUEST, fetchCustomersProjectsControlsRequest),
    takeLatest(EDIT_CONTROL_CUSTOMER_PROJECT_STATUS_REQUEST, editControlCustomerProjectStatusRequest),
    takeLatest(GET_CONTROLS_CUSTOMER_PROJECT_DETAIL_REQUEST, fetchThreatsCustomersControlDetailProject),
    takeLatest(GET_CUSTOMER_PROJECT_CONTROLS_STATIC_REQUEST, fetchCustomersProjectsControlStaticRequest),
    takeLatest(PUT_CUSTOMER_CONTROL_PARTIALLY_REQUEST, fetchPutCustomerControlPartially),
  ]);
}