export enum ChecklistsActions {
  GET_ALL_CHECKLISTS_REQUEST = 'GET_ALL_CHECKLISTS_REQUEST',
  GET_ALL_CHECKLISTS_SUCCESS = 'GET_ALL_CHECKLISTS_SUCCESS',
  GET_ALL_CHECKLISTS_FAILURE = 'GET_ALL_CHECKLISTS_FAILURE',
  GET_FILTERED_CHECKLIST_REQUEST = 'GET_FILTERED_CHECKLIST_REQUEST',
  GET_FILTERED_CHECKLIST_SUCCESS = 'GET_FILTERED_CHECKLIST_SUCCESS',
  GET_FILTERED_CHECKLIST_FAILURE = 'GET_FILTERED_CHECKLIST_FAILURE',
  GET_CHECKLIST_DETAIL_REQUEST = 'GET_CHECKLIST_DETAIL_REQUEST',
  GET_CHECKLIST_DETAIL_SUCCESS = 'GET_CHECKLIST_DETAIL_SUCCESS',
  GET_CHECKLIST_DETAIL_FAILURE = 'GET_CHECKLIST_DETAIL_FAILURE',
  ADD_NEW_CHECKLIST_REQUEST = 'ADD_NEW_CHECKLIST_REQUEST',
  ADD_NEW_CHECKLIST_SUCCESS = 'ADD_NEW_CHECKLIST_SUCCESS',
  ADD_NEW_CHECKLIST_FAILURE = 'ADD_NEW_CHECKLIST_FAILURE',
  EDIT_CHECKLIST_REQUEST = 'EDIT_CHECKLIST_REQUEST',
  EDIT_CHECKLIST_SUCCESS = 'EDIT_CHECKLIST_SUCCESS',
  EDIT_CHECKLIST_FAILURE = 'EDIT_CHECKLIST_FAILURE',
  ACTIVE_OR_INACTIVE_CHECKLIST_REQUEST = 'ACTIVE_OR_INACTIVE_CHECKLIST_REQUEST',
  ACTIVE_OR_INACTIVE_CHECKLIST_SUCCESS = 'ACTIVE_OR_INACTIVE_CHECKLIST_SUCCESS',
  ACTIVE_OR_INACTIVE_CHECKLIST_FAILURE = 'ACTIVE_OR_INACTIVE_CHECKLIST_FAILURE',
  RESET_THE_CHECKLIST_DETAIL_STATE = 'RESET_THE_CHECKLIST_DETAIL_STATE',
  GET_ALL_ACTIVITIES_REQUEST = 'GET_ALL_ACTIVITIES_REQUEST',
  GET_ALL_ACTIVITIES_SUCCESS = 'GET_ALL_ACTIVITIES_SUCCESS',
  GET_ALL_ACTIVITIES_FAILURE = 'GET_ALL_ACTIVITIES_FAILURE',
  GET_ALL_QUESTIONS_REQUEST = 'GET_ALL_QUESTIONS_REQUEST',
  GET_ALL_QUESTIONS_SUCCESS = 'GET_ALL_QUESTIONS_SUCCESS',
  GET_ALL_QUESTIONS_FAILURE = 'GET_ALL_QUESTIONS_FAILURE',
  GET_FILTERED_ACTIVITY_REQUEST = 'GET_FILTERED_ACTIVITY_REQUEST',
  GET_FILTERED_ACTIVITY_SUCCESS = 'GET_FILTERED_ACTIVITY_SUCCESS',
  GET_FILTERED_ACTIVITY_FAILURE = 'GET_FILTERED_ACTIVITY_FAILURE',
  GET_ALL_CHECKLISTS_PROJECTSETTINGS_REQUEST = 'GET_ALL_CHECKLISTS_PROJECTSETTINGS_REQUEST',
  GET_FILTERED_CHECKLIST_PROJECTSETTINGS_REQUEST = 'GET_FILTERED_CHECKLIST_PROJECTSETTINGS_REQUEST',
  GET_FILTERED_CHECKLIST_PROJECTSETTINGS_SUCCESS = 'GET_FILTERED_CHECKLIST_PROJECTSETTINGS_SUCCESS',
  GET_FILTERED_CHECKLIST_PROJECTSETTINGS_FAILURE = 'GET_FILTERED_CHECKLIST_PROJECTSETTINGS_FAILURE',
}

export type SearchFilteredChecklistRequestPayload = {
  filteredChecklists: Array<string | boolean | null>;
};

export type SearchFilteredQuestionRequestPayload = {
  filteredQuestions: Array<string | boolean | null>;
};

export type SearchFilteredActivityRequestPayload = {
  filteredActivities: Array<string | boolean | null>;
};

export type AddNewChecklistRequestPayload = {
  newChecklist: AddNewChecklistData;
};

export type ChecklistDetailRequestPayload = {
  checklistID: string;
};

export type EditChecklistRequestPayload = {
  updatedChecklist: EditChecklistData;
};

export type UpdateChecklistStatusRequestPayload = {
  ChecklistId: string;
  newChecklistStatus: boolean;
};

export type ObjectWithId = {
  id: string;
}

export type ChecklistResults = {
  results: Checklist[];
  count: number;
  next: boolean;
}

export type Checklist = {
  id: string;
  subject: string;
  activities: ActivityOfChecklist[];
  create_activities?: CreateActivitiesOfChecklist[];
  questions: QuestionOfChecklist[];
  is_active: boolean;
}

export type ActivityOfChecklist = {
  id: string;
  name: string;
};

export type QuestionOfChecklist = {
  id: string;
  name: string;
};

export type AddNewChecklistData = {
  subject: string;
  activities?: ObjectWithId[];
  create_activities?: CreateActivitiesOfChecklist[];
  questions?: ObjectWithId[];
  is_active: boolean;
}

export type AddNewChecklistResponseData = {
  id: string;
  subject: string;
  activities: ObjectWithId[];
  create_activities: ObjectWithId[];
  questions: ObjectWithId[];
  is_active: boolean;
};

export type EditChecklistData = {
  id: string;
  subject: string;
  is_active: boolean;
}

export type CreateActivitiesOfChecklist = {
  id: string;
  name: string;
  has_evidence: boolean;
  is_active: boolean;
}

export type Activity = {
  id: string;
  name: string;
  has_evidence: boolean;
  is_active: boolean;
}

export type ActivityResults = {
  results: Activity[];
}

export type QuestionResults = {
  results: Question[];
  count: number;
  next: boolean;
}

export type Question = {
  id: string;
  order: number;
  name: string;
  question_type: string;
  has_evidence: boolean;
  is_active: boolean;
  vulnerabilities: ObjectWithId[];
  risk_factors: ObjectWithId[];
  controls: ObjectWithId[];
  departments: ObjectWithId[];
  alternatives: ObjectWithId[];
}

export type CreateActivityFromChecklist = {
  name: string;
  has_evidence: boolean;
  is_active: boolean;
}


export type ChecklistsState = {
  total: number,
  visited_pages: number[];
  has_next: boolean;
  checklists: Checklist[];
  currentChecklistDetail: Checklist;
  activities: Activity[];
  create_activities: CreateActivitiesOfChecklist[];
  questions: Question[];
};




