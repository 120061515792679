import type { Action } from 'store/types';

import { ControlTypesActions } from './types';
import type { 
  ControlTypes,
  ControlTypesDetails,
  AddNewControlTypeRequest
} from './types';

const {
  EDIT_CONTROL_TYPES_REQUEST,
  EDIT_CONTROL_TYPES_SUCCESS,
  EDIT_CONTROL_TYPES_FAILURE,  
  ADD_NEW_CONTROL_TYPES_REQUEST,
  ADD_NEW_CONTROL_TYPES_SUCCESS,
  ADD_NEW_CONTROL_TYPES_FAILURE,  
  GET_ALL_CONTROL_TYPES_REQUEST,
  GET_ALL_CONTROL_TYPES_SUCCESS,
  GET_ALL_CONTROL_TYPES_FAILURE,
  GET_CONTROL_TYPES_DETAILS_REQUEST,
  GET_CONTROL_TYPES_DETAILS_SUCCESS,
  GET_CONTROL_TYPES_DETAILS_FAILURE,
  GET_FILTERED_CONTROL_TYPES_REQUEST,
  GET_FILTERED_CONTROL_TYPES_SUCCESS,
  GET_FILTERED_CONTROL_TYPES_FAILURE,
  RESET_THE_LAST_CONTROL_TYPES_DETAILS,
  ACTIVE_OR_INACTIVE_CONTROL_TYPES_REQUEST,
  ACTIVE_OR_INACTIVE_CONTROL_TYPES_SUCCESS,
  ACTIVE_OR_INACTIVE_CONTROL_TYPES_FAILURE  
} = ControlTypesActions;

function getControlTypesRequest(last_page?: number): Action {
  return {
    type: GET_ALL_CONTROL_TYPES_REQUEST,
    payload: {
      last_page
    }
  };
}

function getControlTypesSuccess(controlTypes: ControlTypes[], total: number, last_page: number, has_next: boolean): Action {
  return {
    type: GET_ALL_CONTROL_TYPES_SUCCESS,
    payload: {
      controlTypes,
      total,
      last_page, 
      has_next
    },
  };
}

function getControlTypesFailure(): Action {
  return {
    type: GET_ALL_CONTROL_TYPES_FAILURE,
  };
}

function getControlTypeDetailsRequest(controlTypeId: string): Action {
  return {
    type: GET_CONTROL_TYPES_DETAILS_REQUEST,
    payload: {
      controlTypeId,
    },
  };
}

function getControlTypeDetailsSuccess(controlTypeDetails: ControlTypesDetails): Action {
  return {
    type: GET_CONTROL_TYPES_DETAILS_SUCCESS,
    payload: {
      controlTypeDetails,
    },
  };
}

function getControlTypeDetailsFailure(): Action {
  return {
    type: GET_CONTROL_TYPES_DETAILS_FAILURE,
  };
}

function getSearchFilteredControlTypeRequest(
  filteredControlType: Array<string | boolean | null>,
  isActive: boolean | null
): Action {
  return {
    type: GET_FILTERED_CONTROL_TYPES_REQUEST,
    payload: {
      filteredControlType,
      isActive
    },
  };
}

function getSearchFilteredControlTypeSuccess(controlTypes: ControlTypes[]): Action {
  return {
    type: GET_FILTERED_CONTROL_TYPES_SUCCESS,
    payload: {
      controlTypes,
    },
  };
}

function getSearchFilteredControlTypeFailure(): Action {
  return {
    type: GET_FILTERED_CONTROL_TYPES_FAILURE,
  };
}

function addNewControlTypeRequest(controlType: AddNewControlTypeRequest): Action {
  return {
    type: ADD_NEW_CONTROL_TYPES_REQUEST,
    payload: {
      controlType,
    },
  };
}

function addNewControlTypeSuccess(): Action {
  return {
    type: ADD_NEW_CONTROL_TYPES_SUCCESS,
  };
}

function addNewControlTypeFailure(): Action {
  return {
    type: ADD_NEW_CONTROL_TYPES_FAILURE,
  };
}

function editControlTypeRequest(controlTypeId: string, name: string, is_active: boolean): Action {
  return {
    type: EDIT_CONTROL_TYPES_REQUEST,
    payload: {
      controlTypeId,
      name,
      is_active
    },
  };
}

function editControlTypeSuccess(): Action {
  return {
    type: EDIT_CONTROL_TYPES_SUCCESS,
  };
}

function editControlTypeFailure(): Action {
  return {
    type: EDIT_CONTROL_TYPES_FAILURE,
  };
}

function activeOrInactiveControlTypeRequest(
  id: string,
  updatedStatus: boolean
): Action {
  return {
    type: ACTIVE_OR_INACTIVE_CONTROL_TYPES_REQUEST,
    payload: {
      id,
      updatedStatus,
    },
  };
}

function activeOrInactiveControlTypeSuccess(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_CONTROL_TYPES_SUCCESS,
  };
}

function activeOrInactiveControlTypeFailure(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_CONTROL_TYPES_FAILURE,
  };
}

function resetTheLastControlTypeDetails(): Action {
  return {
    type: RESET_THE_LAST_CONTROL_TYPES_DETAILS,
  };
}

export const controlTypesActionsFunctions = {
  getControlTypesRequest
  ,getControlTypesSuccess
  ,getControlTypesFailure
  ,editControlTypeRequest
  ,editControlTypeSuccess
  ,editControlTypeFailure
  ,addNewControlTypeRequest
  ,addNewControlTypeSuccess
  ,addNewControlTypeFailure
  ,getControlTypeDetailsRequest
  ,getControlTypeDetailsSuccess
  ,getControlTypeDetailsFailure
  ,resetTheLastControlTypeDetails
  ,activeOrInactiveControlTypeRequest
  ,activeOrInactiveControlTypeSuccess
  ,activeOrInactiveControlTypeFailure
  ,getSearchFilteredControlTypeRequest
  ,getSearchFilteredControlTypeSuccess
  ,getSearchFilteredControlTypeFailure
};