import produce from 'immer';
import { Reducer } from 'redux';

import {
  ProjectForAnonimizationState,
  ProjectsForAnonimizationActions,
} from './types';

const {
  GET_ALL_ANONIMIZATION_REQUEST,
  GET_ALL_ANONIMIZATION_SUCCESS,
  GET_ALL_ANONIMIZATION_FAILURE,
  APPROVED_ANONIMIZATION_REQUEST,
  APPROVED_ANONIMIZATION_SUCCESS,
  APPROVED_ANONIMIZATION_FAILURE,
  GET_ANONIMIZATION_DETAILS_REQUEST,
  GET_ANONIMIZATION_DETAILS_SUCCESS,
  GET_ANONIMIZATION_DETAILS_FAILURE,
  SET_PROJECTS_FOR_ANONIMIZATION_REQUEST,
  SET_PROJECTS_FOR_ANONIMIZATION_SUCCESS,
  SET_PROJECTS_FOR_ANONIMIZATION_FAILURE,
  GET_ALL_PROJECTS_FOR_ANONIMIZATION_REQUEST,
  GET_ALL_PROJECTS_FOR_ANONIMIZATION_SUCCESS,
  GET_ALL_PROJECTS_FOR_ANONIMIZATION_FAILURE,
} = ProjectsForAnonimizationActions;

const initialState: ProjectForAnonimizationState = {
  allProjectsForAnonimizations: {
    customers_projects_for_anonymization: [],
  },
  allAnonymizationRequest: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  anonymizationDetails: {
    id: '',
    name: '',
    request_date: '',
    anonymization_requester: '',
    approved: false,
    approved_by: '',
    date_of_approval_for_anonymization: '',
    customers_projects: [],
  }
};

export const projectsForAnonimizationReducer: Reducer<ProjectForAnonimizationState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_PROJECTS_FOR_ANONIMIZATION_REQUEST:
        break;

      case GET_ALL_PROJECTS_FOR_ANONIMIZATION_SUCCESS: {
        const { allProjectsForAnonimizations } = action.payload;
        draft.allProjectsForAnonimizations = allProjectsForAnonimizations;
        break;
      }

      case GET_ALL_PROJECTS_FOR_ANONIMIZATION_FAILURE: {
        draft.allProjectsForAnonimizations = {
          customers_projects_for_anonymization: [],
        };
        break;
      }

      case SET_PROJECTS_FOR_ANONIMIZATION_REQUEST:
        break;

      case SET_PROJECTS_FOR_ANONIMIZATION_SUCCESS:
        break;

      case SET_PROJECTS_FOR_ANONIMIZATION_FAILURE:
        break;

      case GET_ALL_ANONIMIZATION_REQUEST:
        break;

      case GET_ALL_ANONIMIZATION_SUCCESS: {
        const { allAnonymizationRequest } = action.payload;
        draft.allAnonymizationRequest = allAnonymizationRequest;
        break;
      }

      case GET_ALL_ANONIMIZATION_FAILURE: {
        draft.allAnonymizationRequest = {
          count: 0,
          next: null,
          previous: null,
          results: []
        };
        break;
      }

      case APPROVED_ANONIMIZATION_REQUEST:
        break;

      case APPROVED_ANONIMIZATION_SUCCESS:
        break;

      case APPROVED_ANONIMIZATION_FAILURE:
        break;

      case GET_ANONIMIZATION_DETAILS_REQUEST:
        break;

      case GET_ANONIMIZATION_DETAILS_SUCCESS: {
        const { anonymizationDetails } = action.payload;
        draft.anonymizationDetails = anonymizationDetails;
        break;
      }

      case GET_ANONIMIZATION_DETAILS_FAILURE: {
        draft.anonymizationDetails = {
          id: '',
          name: '',
          request_date: '',
          anonymization_requester: '',
          approved: false,
          approved_by: '',
          date_of_approval_for_anonymization: '',
          customers_projects: [],
        };

        break;
      }

      default: {
        return draft;
      }
    }
  });
};
