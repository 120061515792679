import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { history } from 'services/history';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import { damagesActionsFunctions } from 'store/modules/Manutention/damages/actions';
import { vulnerabilitiesActionsFunctions } from 'store/modules/Manutention/vulnerabilities/actions';
import type { Action } from 'store/types';
import { checkPage } from 'utils/number';

import type {
  EditDamageRequestPayload,
  SearchFilteredDamageRequestPayload,
  UpdateDamageStatusRequestPayload,
  Damage,
  DamageDetailRequestPayload,
  AddNewDamageRequestPayload,
  EditDamageData,
  DamageResults,
} from './types';
import { DamagesActions } from './types';

const {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE,
  NEW_DAMAGE_ADDED_SUCCESS_MESSAGE,
  NEW_DAMAGE_ADDED_ERROR_MESSAGE,
  SEARCH_FILTERED_DAMAGE_SUCCESS_MESSAGE,
  SEARCH_FILTERED_DAMAGE_ERROR_MESSAGE,
  EDIT_DAMAGE_SUCCESS_MESSAGE,
  EDIT_DAMAGE_ERROR_MESSAGE,
  ACTIVE_OR_INACTIVE_DAMAGE_SUCCESS_MESSAGE,
  ACTIVE_OR_INACTIVE_DAMAGE_ERROR_MESSAGE,
} = ToastMessages;

const {
  GET_ALL_DAMAGES_REQUEST,
  GET_FILTERED_DAMAGE_REQUEST,
  GET_DAMAGE_DETAIL_REQUEST,
  ADD_NEW_DAMAGE_REQUEST,
  EDIT_DAMAGE_REQUEST,
  ACTIVE_OR_INACTIVE_DAMAGE_REQUEST,
} = DamagesActions;

const {
  getDamagesRequest,
  getDamagesSuccess,
  getDamagesFailure,
  getSearchFilteredDamageSuccess,
  getSearchFilteredDamageFailure,
  getDamageDetailSuccess,
  getDamageDetailFailure,
  addNewDamageSuccess,
  addNewDamageFailure,
  editDamageSuccess,
  editDamageFailure,
  activeOrInactiveDamageSuccess,
  activeOrInactiveDamageFailure,
  resetTheDamageDetailState,
} = damagesActionsFunctions;

const { getThreatsRequest } = vulnerabilitiesActionsFunctions;

const { getLastApiFetchDataSuccess, getLastApiFetchDataFailure } =
  lastApiFetchDataActionsFunctions;

function* fetchAllDamages(action: Action): Generator {
  try {
    const { payload } = action as any;
    const total: number = (yield select(state => state.damages.total)) as number;
    const last_page = checkPage(payload.last_page, total);
    const path = `controls/damages/?page=${last_page}`;
    const has_next = yield select(state => state.damages.has_next);

    if(has_next || last_page === 1){
      const damagesResponse: AxiosResponse<DamageResults> | unknown = yield call(api,'GET', path, {});

      const {
        data: { results, count, next },
        config: { url },
        status,
        statusText,
      } = damagesResponse as AxiosResponse<DamageResults>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      yield put(getDamagesSuccess(results, count, last_page, !!next));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getDamagesFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchSearchFilteredDamage(action: Action): Generator {
  const { filteredDamages } =
    action.payload as SearchFilteredDamageRequestPayload;

  const hasFilteredOptionsWithoutStatus = filteredDamages
    .filter(response => response !== null && typeof response !== 'boolean')
    .join(',');

  const hasStatusFilteredOption = filteredDamages.some(
    option => typeof option === 'boolean'
  );

  const filteredOptionStatus = `?is_active=${filteredDamages.filter(
    response => typeof response === 'boolean'
  )}`;

  const filteredOptionsWithoutStatus = `?search=${filteredDamages
    .filter(response => response !== null && typeof response !== 'boolean')
    .join(',')}`;

  const routeWithFilter = `controls/damages/${
    hasFilteredOptionsWithoutStatus ? filteredOptionsWithoutStatus : ''
  }${hasStatusFilteredOption ? filteredOptionStatus : ''}`;

  try {
    const damagesResponse: AxiosResponse<DamageResults> | unknown = yield call(
      api,
      'GET',
      routeWithFilter,
      {}
    );

    const {
      data: damages,
      config: { url },
      status,
      statusText,
    } = damagesResponse as AxiosResponse<DamageResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredDamageSuccess(damages.results));

    toast.success(SEARCH_FILTERED_DAMAGE_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredDamageFailure());

    toast.error(
      currentError?.response?.data?.detail ??
        SEARCH_FILTERED_DAMAGE_ERROR_MESSAGE
    );
  }
}

function* fetchAddNewDamage(action: Action): Generator {
  const { newDamage } = action.payload as AddNewDamageRequestPayload;

  try {
    const addNewDamageResponse: AxiosResponse<Damage> | unknown = yield call(
      api,
      'POST',
      'controls/damages/create/',
      {
        ...newDamage,
      }
    );

    const {
      data: damageAdded,
      config: { url },
      status,
      statusText,
    } = addNewDamageResponse as AxiosResponse<Damage>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(addNewDamageSuccess(damageAdded));

    history.push(`/damage-registration/${damageAdded.id}`);

    yield put(getDamagesRequest());
    yield put(getThreatsRequest());

    toast.success(NEW_DAMAGE_ADDED_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(resetTheDamageDetailState());
    yield put(addNewDamageFailure());

    if(currentError?.response?.data?.name[0] === 'damage with this name already exists.') {
      toast.warn('Já existe um dano com este nome.');
    } else {
      toast.error(
        currentError?.response?.data?.name[0] ??
          currentError?.response?.data?.detail ??
          NEW_DAMAGE_ADDED_ERROR_MESSAGE
      );
    }
  }
}

function* fetchDamageDetail(action: Action): Generator {
  const { damageId } = action.payload as DamageDetailRequestPayload;

  try {
    const damageDetailResponse: AxiosResponse<Damage> | unknown = yield call(
      api,
      'GET',
      `controls/damages/${damageId}/detail/`,
      {}
    );

    const {
      data: damage,
      config: { url },
      status,
      statusText,
    } = damageDetailResponse as AxiosResponse<Damage>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getDamageDetailSuccess(damage));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(resetTheDamageDetailState());
    yield put(getDamageDetailFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
        DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchToDamageEditing(action: Action): Generator {
  const { updatedDamage } = action.payload as EditDamageRequestPayload;

  try {
    const damageEditingResponse: AxiosResponse<EditDamageData> | unknown =
      yield call(api, 'PUT', `controls/damages/${updatedDamage.id}/`, {
        ...updatedDamage,
      });

    const {
      config: { url },
      status,
      statusText,
    } = damageEditingResponse as AxiosResponse<EditDamageData>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editDamageSuccess());
    yield put(getDamagesRequest());
    yield put(getThreatsRequest());

    toast.success(EDIT_DAMAGE_SUCCESS_MESSAGE);

    history.push(`/damage-visualization/${updatedDamage.id}`);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(resetTheDamageDetailState());
    yield put(editDamageFailure());

    toast.error(
      currentError?.response?.data?.detail ?? EDIT_DAMAGE_ERROR_MESSAGE
    );
  }
}

function* fetchToUpdateDamageStatus(action: Action): Generator {
  const { damageId, newDamageStatus } =
    action.payload as UpdateDamageStatusRequestPayload;

  try {
    const updateDamageResponse:
      | AxiosResponse<{ is_active: boolean }>
      | unknown = yield call(
      api,
      'PUT',
      `controls/damages/${damageId}/update-status/`,
      {
        is_active: newDamageStatus,
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = updateDamageResponse as AxiosResponse<{ is_active: boolean }>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(activeOrInactiveDamageSuccess());
    yield put(getDamagesRequest());
    yield put(getThreatsRequest());

    toast.success(ACTIVE_OR_INACTIVE_DAMAGE_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(activeOrInactiveDamageFailure());

    toast.error(
      currentError?.response?.data?.detail ??
        ACTIVE_OR_INACTIVE_DAMAGE_ERROR_MESSAGE
    );
  }
}

export function* damagesSagas() {
  yield all([
    takeEvery(GET_ALL_DAMAGES_REQUEST, fetchAllDamages),
    takeLatest(GET_DAMAGE_DETAIL_REQUEST, fetchDamageDetail),
    takeLatest(GET_FILTERED_DAMAGE_REQUEST, fetchSearchFilteredDamage),
    takeLatest(ADD_NEW_DAMAGE_REQUEST, fetchAddNewDamage),
    takeLatest(EDIT_DAMAGE_REQUEST, fetchToDamageEditing),
    takeLatest(ACTIVE_OR_INACTIVE_DAMAGE_REQUEST, fetchToUpdateDamageStatus),
  ]);
}
