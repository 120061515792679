import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { history } from 'services/history';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import type { Action } from 'store/types';

import { projectsForAnonimizationActionsFunctions } from './actions';
import {
  AllAnonymizationRequest,
  AnonymizationDetailsPayload,
  AnonymizationDetailsRequest,
  ProjectsForAnonimizationActions,
  StateAnonymizationPayloadParams,
  UpdateAnonymizationPayloadParams,
  CustomersProjectsForAnonymization,
  CustomersProjectsForAnonymizationPayload,
} from './types';

const {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE
} = ToastMessages;

const {
  GET_ALL_ANONIMIZATION_REQUEST,
  APPROVED_ANONIMIZATION_REQUEST,
  GET_ANONIMIZATION_DETAILS_REQUEST,
  SET_PROJECTS_FOR_ANONIMIZATION_REQUEST,
  GET_ALL_PROJECTS_FOR_ANONIMIZATION_REQUEST,
} = ProjectsForAnonimizationActions;

const {
  getAnonimizationSuccess,
  getAnonimizationFailure,
  updateAnonimizationSuccess,
  updateAnonimizationFailure,
  getAnonimizationDetailsSuccess,
  getAnonimizationDetailsFailure,
  getProjectsForAnonimizationSuccess,
  getProjectsForAnonimizationFailure,
  setProjectsForAnonimizationSuccess,
  setProjectsForAnonimizationFailure,
} = projectsForAnonimizationActionsFunctions;

const {
  getLastApiFetchDataSuccess,
  getLastApiFetchDataFailure
} = lastApiFetchDataActionsFunctions;

function* fetchAllProjectsForAnonimizations(): Generator {
  try {
    const projectsForAnonimizationResponse: AxiosResponse<CustomersProjectsForAnonymization[]> | unknown = yield call(
      api,
      'GET',
      'controls/customers-projects-for-anonymization/',
      {}
    );

    const {
      data: allProjectsForAnonimizations,
      config: { url },
      status,
      statusText,
    } = projectsForAnonimizationResponse as AxiosResponse<CustomersProjectsForAnonymization[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getProjectsForAnonimizationSuccess(allProjectsForAnonimizations));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getProjectsForAnonimizationFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchAddProjectsForAnonimizations(action: Action): Generator {
  const { customers_projects } = action.payload as CustomersProjectsForAnonymizationPayload;

  try {
    const addProjectsForAnonimizationsResponse: AxiosResponse<any> | unknown = yield call(
      api,
      'POST',
      'controls/anonymization-requests/create/',
      customers_projects
    );

    const {
      config: { url },
      status,
      statusText,
    } = addProjectsForAnonimizationsResponse as AxiosResponse<any>;

    yield put(
      getLastApiFetchDataSuccess(
        {
          url,
          status,
          statusText,
        }
      )
    );

    yield put(setProjectsForAnonimizationSuccess());
    toast.success('Projetos adicionados para anonimização.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(setProjectsForAnonimizationFailure());

    toast.error('Tentativa de adicionar projetos para anonimização falhou.');
  }
}

function* fetchAllAnonimizations(action: Action): Generator {
  const { approved } = action.payload as StateAnonymizationPayloadParams;

  let endPoint = 'controls/anonymization-requests/';
  if (approved === true || approved === false) {
    endPoint = endPoint.concat(`?approved=${approved}`);
  }

  try {
    const anonimizationResponse: AxiosResponse<AllAnonymizationRequest> | unknown = yield call(
      api,
      'GET',
      endPoint,
      {}
    );

    const {
      data: allAnonymizationRequest,
      config: { url },
      status,
      statusText,
    } = anonimizationResponse as AxiosResponse<AllAnonymizationRequest>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getAnonimizationSuccess(allAnonymizationRequest));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getAnonimizationFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchToUpdateAnonimization(action: Action): Generator {
  const { id, approved } = action.payload as UpdateAnonymizationPayloadParams;

  try {
    const updateCrownJewelsStatusResponse: AxiosResponse<AnonymizationDetailsRequest> | unknown =
      yield call(
        api,
        'PUT',
        `controls/anonymization-requests/${id}/approve/`,
        {
          approved,
        }
      );

    const {
      config: { url },
      status,
      statusText,
    } = updateCrownJewelsStatusResponse as AxiosResponse<AnonymizationDetailsRequest>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(updateAnonimizationSuccess());
    history.push('/projects-for-anonymization');
    toast.success('Solicitação de anonimozação realizada com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(updateAnonimizationFailure());

    toast.error('Tentativa de solicitação de anonimozação falhou.');
  }
}

function* fetchAnonimizationDetailsRequest(action: Action): Generator {
  const { id } = action.payload as AnonymizationDetailsPayload;

  try {
    const anonimizationDetailResponse: AxiosResponse<AnonymizationDetailsRequest> | unknown = yield call(
      api,
      'GET',
      `controls/anonymization-requests/${id}/detail/`,
      {}
    );

    const {
      data: anonymizationDetails,
      config: { url },
      status,
      statusText,
    } = anonimizationDetailResponse as AxiosResponse<AnonymizationDetailsRequest>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getAnonimizationDetailsSuccess(anonymizationDetails));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getAnonimizationDetailsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

export function* projectsForAnonimizationSagas() {
  yield all([
    takeLatest(GET_ALL_ANONIMIZATION_REQUEST, fetchAllAnonimizations),
    takeLatest(APPROVED_ANONIMIZATION_REQUEST, fetchToUpdateAnonimization),
    takeLatest(GET_ANONIMIZATION_DETAILS_REQUEST, fetchAnonimizationDetailsRequest),
    takeLatest(SET_PROJECTS_FOR_ANONIMIZATION_REQUEST, fetchAddProjectsForAnonimizations),
    takeLatest(GET_ALL_PROJECTS_FOR_ANONIMIZATION_REQUEST, fetchAllProjectsForAnonimizations),
  ]);
}
