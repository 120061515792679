import produce from 'immer';
import {
  Reducer
} from 'redux';

import {
  CrownJewelsActions
} from './types';
import type {
  CrownJewelsState
} from './types';


const {
  EDIT_CROWNJEWELS_REQUEST,
  EDIT_CROWNJEWELS_SUCCESS,
  EDIT_CROWNJEWELS_FAILURE,
  ADD_NEW_CROWNJEWELS_REQUEST,
  ADD_NEW_CROWNJEWELS_SUCCESS,
  ADD_NEW_CROWNJEWELS_FAILURE,
  GET_ALL_CROWNJEWELS_REQUEST,
  GET_ALL_CROWNJEWELS_SUCCESS,
  GET_ALL_CROWNJEWELS_FAILURE,
  GET_CROWNJEWELS_DETAILS_REQUEST,
  GET_CROWNJEWELS_DETAILS_SUCCESS,
  GET_CROWNJEWELS_DETAILS_FAILURE,
  GET_FILTERED_CROWNJEWELS_REQUEST,
  GET_FILTERED_CROWNJEWELS_SUCCESS,
  GET_FILTERED_CROWNJEWELS_FAILURE,
  RESET_THE_LAST_CROWNJEWELS_DETAILS,
  ACTIVE_OR_INACTIVE_CROWNJEWELS_REQUEST,
  ACTIVE_OR_INACTIVE_CROWNJEWELS_SUCCESS,
  ACTIVE_OR_INACTIVE_CROWNJEWELS_FAILURE,
  GET_ALL_CONTROL_CROWNJEWELS_REQUEST,
  GET_ALL_CONTROL_CROWNJEWELS_SUCCESS,
  GET_ALL_CONTROL_CROWNJEWELS_FAILURE
} = CrownJewelsActions;

const initialState: CrownJewelsState = {
  allCrownJewels: [],
  currentCrownJewelsDetails: {
    id: '',
    name: '',
    weight: '',
    is_active: false,
  },
  controlCrownJewels: [],
};

export const crownJewelsReducer: Reducer<CrownJewelsState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_CROWNJEWELS_REQUEST:
        break;

      case GET_ALL_CROWNJEWELS_SUCCESS: {
        const { crownJewels } = action.payload;
        draft.allCrownJewels = crownJewels;
        break;
      }

      case GET_ALL_CROWNJEWELS_FAILURE: {
        draft.allCrownJewels = [];
        break;
      }

      case GET_ALL_CONTROL_CROWNJEWELS_REQUEST:
        break;

      case GET_ALL_CONTROL_CROWNJEWELS_SUCCESS: {
        const { controlCrownJewels } = action.payload;

        draft.controlCrownJewels = controlCrownJewels;
        break;
      }

      case GET_ALL_CONTROL_CROWNJEWELS_FAILURE: {
        draft.controlCrownJewels = [];
        break;
      }

      case GET_CROWNJEWELS_DETAILS_REQUEST:
        break;

      case GET_CROWNJEWELS_DETAILS_SUCCESS: {
        const { crownJewelsDetails } = action.payload;
        draft.currentCrownJewelsDetails.id = crownJewelsDetails.id;
        draft.currentCrownJewelsDetails.name = crownJewelsDetails.name;
        draft.currentCrownJewelsDetails.weight = crownJewelsDetails.weight;
        draft.currentCrownJewelsDetails.is_active = crownJewelsDetails.is_active;
        break;
      }

      case GET_CROWNJEWELS_DETAILS_FAILURE: {
        draft.currentCrownJewelsDetails.id = '';
        draft.currentCrownJewelsDetails.name = '';
        draft.currentCrownJewelsDetails.weight = '';
        draft.currentCrownJewelsDetails.is_active = false;
        break;
      }

      case GET_FILTERED_CROWNJEWELS_REQUEST:
        break;

      case GET_FILTERED_CROWNJEWELS_SUCCESS: {
        const { crownJewels } = action.payload;
        draft.allCrownJewels = crownJewels;
        break;
      }

      case GET_FILTERED_CROWNJEWELS_FAILURE:
        break;

      case ADD_NEW_CROWNJEWELS_REQUEST:
        break;

      case ADD_NEW_CROWNJEWELS_SUCCESS:
        break;

      case ADD_NEW_CROWNJEWELS_FAILURE:
        break;

      case EDIT_CROWNJEWELS_REQUEST:
        break;

      case EDIT_CROWNJEWELS_SUCCESS:
        break;

      case EDIT_CROWNJEWELS_FAILURE:
        break;

      case ACTIVE_OR_INACTIVE_CROWNJEWELS_REQUEST:
        break;

      case ACTIVE_OR_INACTIVE_CROWNJEWELS_SUCCESS:
        break;

      case ACTIVE_OR_INACTIVE_CROWNJEWELS_FAILURE:
        break;

      case RESET_THE_LAST_CROWNJEWELS_DETAILS: {
        draft.currentCrownJewelsDetails.id = '';
        draft.currentCrownJewelsDetails.name = '';
        draft.currentCrownJewelsDetails.weight = '';
        draft.currentCrownJewelsDetails.is_active = false;
        break;
      }

      default: {
        return draft;
      }
    }
  });
};
