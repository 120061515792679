import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import moment from 'moment';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { history } from 'services/history';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import type { Action } from 'store/types';
import { returnFileNameWithExtension } from 'utils/functions';

import { customerProjectActionsFunctions } from '../customerProject/actions';
import { customerProjectRiskActionsFunctions } from './actions';
import {
  CustomerProjectRisksActions,
  CustomerProjetcChangeStatusApiPayload,
  CustomerProjetcDetailApiPayload,
  CustomerProjetcIdApiPayload,
  CustomerProjetcRiskQuestionsApiPayload,
  CustomerProjetcRiskRecommendationsApiPayload,
  CustomerProjetcRiskRegistrationsApiPayload,
  DeleteCustomerProjetcRiskEvidencesApiPayload,
  DeleteEvidencesResponse,
  QuestionsAnswerBodyType,
  RiskCustomerProject,
  RiskMatrixCustomerProjetcApiPayload,
  EvidenceIdApiPayload,
  ProjetcIdApiPayload,
  RiskMatrixCustomerProject,
  EvidenceIdNameApiPayload,
  EvidenceAlterPositionApiPayload,
  RiskIdApiPayload,
  Evidences,
  EvidenceAlterStatusApiPayload,
} from './types';

const {
  GET_ALL_RISKS_CUSTOMER_PROJECT_REQUEST,
  GENERATE_RISKS_CUSTOMER_PROJECT_REQUEST,
  GET_RISKS_CUSTOMER_PROJECT_DETAIL_REQUEST,
  EDIT_RISKS_CUSTOMER_PROJECT_STATUS_REQUEST,
  EDIT_RISKS_CUSTOMER_PROJECT_QUESTIONS_ANSWER_REQUEST,
  EDIT_RISKS_CUSTOMER_PROJECT_REGISTRATION_REQUEST,
  EDIT_RISKS_CUSTOMER_PROJECT_RECOMMENDATION_REQUEST,
  DELETE_RISK_CUSTOMER_PROJECT_EVIDENCES_REQUEST,
  GET_EXPORT_RISKS_CUSTOMER_PROJECT_REQUEST,
  GET_RISK_CUSTOMER_PROJECT_EVIDENCES_DOWNLOAD_REQUEST,
  GET_ALL_RISKS_MATRIX_CUSTOMER_PROJECT_REQUEST,
  EDIT_EVIDENCE_NAME_REQUEST,
  ALTER_POSITION_EVIDENCE_REQUEST,
  GET_ALL_EVIDENCES_REQUEST,
  ALTER_STATUS_EVIDENCE_REQUEST,
  CONFIRM_CLIENT_DATA_REQUEST,
  CONFIRM_CLIENT_DATA_SUCCESS,
  CONFIRM_CLIENT_DATA_FAILURE
} = CustomerProjectRisksActions;

const {
  getCustomerProjectRisksSuccess,
  getCustomerProjectRisksFailure,
  generateCustomerProjectRisksSuccess,
  generateCustomerProjectRisksFailure,
  getCustomerProjectRisksDetailSuccess,
  getCustomerProjectRisksDetailFailure,
  editCustomerProjectRisksStatusSuccess,
  editCustomerProjectRisksStatusFailure,
  editCustomerProjectRisksQuestionInformationSuccess,
  editCustomerProjectRisksQuestionInformationFailure,
  editCustomerProjectRisksRegistrationsSuccess,
  editCustomerProjectRisksRegistrationsFailure,
  editCustomerProjectRecommendationSuccess,
  editCustomerProjectRecommendationFailure,
  deleteRiskCustomerProjectEvidenceSuccess,
  deleteRiskCustomerProjectEvidenceFailure,
  getExportCustomerProjectRiskSuccess,
  getExportCustomerProjectRiskFailure,
  getRiskCustomerProjectEvidenceDownloadSuccess,
  getRiskCustomerProjectEvidenceDownloadFailure,
  getCustomerProjectRiskMatrixSuccess,
  getCustomerProjectRiskMatrixFailure,
  getCustomerProjectRiskMatrixRequest,
  renameEvidenceSuccess,
  renameEvidenceFailure,
  alterPositionEvidenceSuccess,
  alterPositionEvidenceFailure,
  getAllEvidencesRequest,
  getAllEvidencesSuccess,
  getAllEvidencesFailure,
  alterStatusEvidenceSuccess,
  alterStatusEvidenceFailure,
  getCustomerProjectRisksDetailRequest,
  confirmClientDataRequest,
  confirmClientDataSuccess,
  confirmClientDataFailure
} = customerProjectRiskActionsFunctions;

const {
  getRiskDonutChartRequest,
  getRiskHeatMapRequest
} = customerProjectActionsFunctions;

const {
  getLastApiFetchDataSuccess,
  getLastApiFetchDataFailure
} = lastApiFetchDataActionsFunctions;

const {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE
} = ToastMessages;


function* fetchAllRisksCustomersProject(action: Action): Generator {
  const { customerProjectId } = action.payload as CustomerProjetcIdApiPayload;
  try {
    const customerProjectResponse: AxiosResponse<RiskCustomerProject> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${customerProjectId}/risks/`,
      {}
    );

    const {
      data: customersProjectsRisk,
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<RiskCustomerProject>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCustomerProjectRisksSuccess(customersProjectsRisk));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomerProjectRisksFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchRisksCustomersDetailProject(action: Action): Generator {
  const { riskCustomerProjectId } = action.payload as CustomerProjetcDetailApiPayload;
  try {
    const customerProjectResponse: AxiosResponse<RiskCustomerProject> | unknown = yield call(
      api,
      'GET',
      `/controls/risks-customer-project/${riskCustomerProjectId}/detail/`,
      {}
    );

    const {
      data: customersProjectsRisk,
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<RiskCustomerProject>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCustomerProjectRisksDetailSuccess(customersProjectsRisk));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomerProjectRisksDetailFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
      DEFAULT_ERROR_MESSAGE
    );
  }
}

function* generateRisksCustomersProject(action: Action): Generator {
  const { customerProjectId } = action.payload as CustomerProjetcIdApiPayload;
  try {
    const customerProjectResponse: AxiosResponse<RiskCustomerProject> | unknown = yield call(
      api,
      'GET',
      `/controls/generate-risk-matrix/${customerProjectId}/`,
      {}
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<RiskCustomerProject>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(generateCustomerProjectRisksSuccess());
    yield put(getCustomerProjectRiskMatrixRequest(customerProjectId));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(generateCustomerProjectRisksFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
      DEFAULT_ERROR_MESSAGE
    );
  }
}

function* editRiskCustomerProjectStatusRequest(action: Action): Generator {
  const { riskId, changeStatusBody, customerProjectId } = action.payload as CustomerProjetcChangeStatusApiPayload;
  try {
    const customerProjectResponse: AxiosResponse<RiskCustomerProject> | unknown = yield call(
      api,
      'PUT',
      `/controls/risks-customer-project/${riskId}/update-status/`,
      { ...changeStatusBody }
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<RiskCustomerProject>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    if (status === 200 || status === 201) {
      yield put(editCustomerProjectRisksStatusSuccess());
      yield put(getCustomerProjectRiskMatrixRequest(customerProjectId));

      toast.success('Status alterado com sucesso.');
      yield put(getRiskDonutChartRequest(customerProjectId));
      yield put(getRiskHeatMapRequest(customerProjectId));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editCustomerProjectRisksStatusFailure());

    toast.success('Erro ao alterar o status.');
  }
}

function* editRiskCustomerProjectQuestionsAnswerRequest(action: Action): Generator {
  const { riskId, questionsAnswerBody } = action.payload as CustomerProjetcRiskQuestionsApiPayload;
  try {
    const customerProjectResponse: AxiosResponse<QuestionsAnswerBodyType> | unknown = yield call(
      api,
      'PUT',
      `/controls/risks-customer-project/${riskId}/`,
      { ...questionsAnswerBody }
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<QuestionsAnswerBodyType>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editCustomerProjectRisksQuestionInformationSuccess());
    yield put(getCustomerProjectRisksDetailRequest(riskId));

    toast.success('Risco do projeto editado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editCustomerProjectRisksQuestionInformationFailure());

    toast.error('Falha ao editar risco do projeto.');
  }
}

function* editRiskCustomerProjectRegistrationsRequest(action: Action): Generator {
  const { riskId, riskRegistrationsBody, customerProjectId, disableChange } = action.payload as CustomerProjetcRiskRegistrationsApiPayload;
  try {
    const customerProjectResponse: AxiosResponse<QuestionsAnswerBodyType> | unknown = yield call(
      api,
      'PUT',
      `/controls/risks-customer-project/${riskId}/update-damages/`,
      { ...riskRegistrationsBody }
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<QuestionsAnswerBodyType>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editCustomerProjectRisksRegistrationsSuccess());

    toast.success('Vínculos do risco do projeto editado com sucesso.');
    history.push(`/customer-project-risk-visualization/${customerProjectId}/${riskId}/${disableChange}`);

  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editCustomerProjectRisksRegistrationsFailure());

    toast.error('Falha ao editar vínculos.');
  }
}

function* editRiskCustomerProjectRecommendationsRequest(action: Action): Generator {
  const { riskId, riskRecommendation, customerProjectId, disableChange } = action.payload as CustomerProjetcRiskRecommendationsApiPayload;
  try {
    const customerProjectResponse: AxiosResponse<QuestionsAnswerBodyType> | unknown = yield call(
      api,
      'PUT',
      `/controls/risks-customer-project/${riskId}/update-stages/`,
      { ...riskRecommendation }
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<QuestionsAnswerBodyType>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editCustomerProjectRecommendationSuccess());

    toast.success('Recomendações do risco do projeto editado com sucesso.');
    history.push(`/customer-project-risk-visualization/${customerProjectId}/${riskId}/${disableChange}`);

  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editCustomerProjectRecommendationFailure());

    if (currentError.response?.data.msg === 'stage with this name already exists') {
      toast.warn('Já existe etapas com este nome.');
    } else {
      toast.error('Falha ao editar Recomendações.');
    }
  }
}

function* deleteRiskCustomerProjectEvidencesRequest(action: Action): Generator {
  const { riskId, evidencesRemove } = action.payload as DeleteCustomerProjetcRiskEvidencesApiPayload;
  try {
    const customerProjectResponse: AxiosResponse<DeleteEvidencesResponse> | unknown = yield call(
      api,
      'PUT',
      `/controls/risks-customer-project/${riskId}/update-evidences/`,
      { evidences_remove: evidencesRemove }
    );

    const {
      data: response,
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<DeleteEvidencesResponse>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(deleteRiskCustomerProjectEvidenceSuccess(response));
    toast.success('Evidência removida com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(deleteRiskCustomerProjectEvidenceFailure());

    toast.error('Falha ao remover a evidência.');

  }
}

function* getExportRiskCustomerProjectRequest(action: Action): Generator {
  const { customerProjectId, customerName } = action.payload as RiskMatrixCustomerProjetcApiPayload;
  try {
    const customerProjectResponse: AxiosResponse<any> | unknown = yield call(
      api,
      'GET',
      `/controls/customer-project/${customerProjectId}/generate-risk-excel/`,
      {},
      'application/ms-excel',
      'blob'
    );

    const {
      data: excelRiskCustomerProject,
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<any>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );
    const formatedDate = moment(Date.now()).format('DD/MM/YYYY');

    const outputFilename = `Matriz_de_risco_${customerName}_${formatedDate}.xls`;

    const fileUrl = URL.createObjectURL(new Blob([excelRiskCustomerProject], { type: 'application/ms-excel' }));

    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();

    toast.success('Matriz de risco exportada com sucesso.');

    yield put(getExportCustomerProjectRiskSuccess());

  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getExportCustomerProjectRiskFailure());

    toast.error('Falha ao exportar matriz de risco.');

  }
}

function* getRiskCustomerProjectEvidencesDownloadRequest(action: Action): Generator {
  const { evidenceId, evidenceName } = action.payload as EvidenceIdApiPayload;

  try {
    const evidencesDownloadResponse: AxiosResponse<any> | unknown = yield call(
      api,
      'GET',
      `/controls/evidences/${evidenceId}/visualizate/`,
      {},
      'image/*',
      'blob'
    );

    const {
      data: evidence,
      config: { url },
      status,
      statusText,
    } = evidencesDownloadResponse as AxiosResponse<any>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    const outputFilename = returnFileNameWithExtension(evidenceName, evidence.type);

    const fileUrl = URL.createObjectURL(new Blob([evidence], { type: evidence.type }));

    const link = document.createElement('a');
    link.href = fileUrl;

    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();

    toast.success('Download da evidência realizado com sucesso.');

    yield put(getRiskCustomerProjectEvidenceDownloadSuccess());
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getRiskCustomerProjectEvidenceDownloadFailure());

    toast.error('Erro ao fazer o download da evidência.');
  }
}

function* fetchAllRiskMatrixCustomersProject(action: Action): Generator {
  const { projectId } = action.payload as ProjetcIdApiPayload;

  try {
    const customerProjectResponse: AxiosResponse<RiskMatrixCustomerProject> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${projectId}/risks-matrix/`,
      {}
    );

    const {
      data: customerProjectRiskMatrix,
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<RiskMatrixCustomerProject>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCustomerProjectRiskMatrixSuccess(customerProjectRiskMatrix));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomerProjectRiskMatrixFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* renameProjectEvidencesRequest(action: Action): Generator {
  const { evidence_id, evidence_name, riskId } = action.payload as EvidenceIdNameApiPayload;
  try {
    const evidenceResponse: AxiosResponse<any> | unknown = yield call(
      api,
      'PUT',
      `/controls/evidences/${evidence_id}/update-name/`,
      { evidence_name }
    );

    const {
      config: { url },
      status,
      statusText,
    } = evidenceResponse as AxiosResponse<any>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(renameEvidenceSuccess());

    if (status === 200) {
      yield put(getAllEvidencesRequest(riskId, 'Evidência renomeada com sucesso.'));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(renameEvidenceFailure());

    toast.error('Falha ao renomear a evidência.');
  }
}

function* alterPositionEvidencesRequest(action: Action): Generator {
  const { evidence_id, position, riskId } = action.payload as EvidenceAlterPositionApiPayload;
  try {
    const evidenceResponse: AxiosResponse<any> | unknown = yield call(
      api,
      'PUT',
      `/controls/risk-customer-project-evidence/${evidence_id}/update-position/`,
      { position }
    );

    const {
      config: { url },
      status,
      statusText,
    } = evidenceResponse as AxiosResponse<any>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(alterPositionEvidenceSuccess());

    if (status === 200) {
      yield put(getAllEvidencesRequest(riskId, 'Posição da evidência alterada com sucesso.'));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(alterPositionEvidenceFailure());

    toast.error('Falha ao alterar a posição da evidência.');
  }
}

function* alterStatusEvidencesRequest(action: Action): Generator {
  const { riskId, typeStatus, evidences } = action.payload as EvidenceAlterStatusApiPayload;

  if (typeStatus === 'ATIVAR') {
    try {
      const evidenceResponse: AxiosResponse<any> | unknown = yield call(
        api,
        'PUT',
        `/controls/risk-customer-project/${riskId}/activate-evidences/`,
        { evidences }
      );

      const {
        config: { url },
        status,
        statusText,
      } = evidenceResponse as AxiosResponse<any>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      yield put(alterStatusEvidenceSuccess());

      if (status === 200) {
        yield put(getAllEvidencesRequest(riskId, 'Status alterado com sucesso.'));
      }
    } catch (error) {
      const currentError = error as AxiosError;

      yield put(getLastApiFetchDataFailure(currentError));
      yield put(alterStatusEvidenceFailure());

      toast.error('Falha ao alterar o status.');
    }
  } else if (typeStatus === 'INATIVAR') {
    try {
      const evidenceResponse: AxiosResponse<any> | unknown = yield call(
        api,
        'PUT',
        `/controls/risk-customer-project/${riskId}/inactivate-evidences/`,
        { evidences }
      );

      const {
        config: { url },
        status,
        statusText,
      } = evidenceResponse as AxiosResponse<any>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      yield put(alterStatusEvidenceSuccess());

      if (status === 200) {
        yield put(getAllEvidencesRequest(riskId, 'Status alterado com sucesso.'));
      }
    } catch (error) {
      const currentError = error as AxiosError;

      yield put(getLastApiFetchDataFailure(currentError));
      yield put(alterStatusEvidenceFailure());

      toast.error('Falha ao alterar o status.');
    }
  }
}

function* fetchAllEvidencesRisks(action: Action): Generator {
  const { risk_id, msg } = action.payload as RiskIdApiPayload;
  try {
    const evidenceResponse: AxiosResponse<Evidences[]> | unknown = yield call(
      api,
      'GET',
      `/controls/risk-customer-project/${risk_id}/evidences/`,
      {}
    );

    const {
      data: evidences,
      config: { url },
      status,
      statusText,
    } = evidenceResponse as AxiosResponse<Evidences[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getAllEvidencesSuccess(evidences));
    toast.success(msg);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getAllEvidencesFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchCustomerDataConfirmation(action: Action): Generator {
  const { customerProjectId } = action.payload as CustomerProjetcIdApiPayload;
  try {
    const customerProjectResponse: AxiosResponse<RiskCustomerProject> | unknown = yield call(
      api,
      'PUT',
      `/controls/customers-projects/${customerProjectId}/update-accept-additional-customer-data/`,
      {'accept_additional_customer_data': true}
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<any>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(confirmClientDataSuccess());
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(confirmClientDataFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
      DEFAULT_ERROR_MESSAGE
    );
  }
}

export function* customerProjectRisksSagas() {
  yield all([
    takeLatest(GET_ALL_RISKS_CUSTOMER_PROJECT_REQUEST, fetchAllRisksCustomersProject),
    takeLatest(GENERATE_RISKS_CUSTOMER_PROJECT_REQUEST, generateRisksCustomersProject),
    takeLatest(GET_RISKS_CUSTOMER_PROJECT_DETAIL_REQUEST, fetchRisksCustomersDetailProject),
    takeLatest(EDIT_RISKS_CUSTOMER_PROJECT_STATUS_REQUEST, editRiskCustomerProjectStatusRequest),
    takeLatest(EDIT_RISKS_CUSTOMER_PROJECT_QUESTIONS_ANSWER_REQUEST, editRiskCustomerProjectQuestionsAnswerRequest),
    takeLatest(EDIT_RISKS_CUSTOMER_PROJECT_REGISTRATION_REQUEST, editRiskCustomerProjectRegistrationsRequest),
    takeLatest(EDIT_RISKS_CUSTOMER_PROJECT_RECOMMENDATION_REQUEST, editRiskCustomerProjectRecommendationsRequest),
    takeLatest(DELETE_RISK_CUSTOMER_PROJECT_EVIDENCES_REQUEST, deleteRiskCustomerProjectEvidencesRequest),
    takeLatest(GET_EXPORT_RISKS_CUSTOMER_PROJECT_REQUEST, getExportRiskCustomerProjectRequest),
    takeLatest(GET_RISK_CUSTOMER_PROJECT_EVIDENCES_DOWNLOAD_REQUEST, getRiskCustomerProjectEvidencesDownloadRequest),
    takeLatest(GET_ALL_RISKS_MATRIX_CUSTOMER_PROJECT_REQUEST, fetchAllRiskMatrixCustomersProject),
    takeLatest(EDIT_EVIDENCE_NAME_REQUEST, renameProjectEvidencesRequest),
    takeLatest(ALTER_POSITION_EVIDENCE_REQUEST, alterPositionEvidencesRequest),
    takeLatest(GET_ALL_EVIDENCES_REQUEST, fetchAllEvidencesRisks),
    takeLatest(ALTER_STATUS_EVIDENCE_REQUEST, alterStatusEvidencesRequest),
    takeLatest(CONFIRM_CLIENT_DATA_REQUEST, fetchCustomerDataConfirmation),
  ]);
}
