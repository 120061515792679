import produce from 'immer';
import { Reducer } from 'redux';

import { StandardState, StandardsActions } from './types';

const {
  EDIT_STANDARDS_REQUEST,
  EDIT_STANDARDS_SUCCESS,
  EDIT_STANDARDS_FAILURE,
  GET_ALL_STANDARDS_REQUEST,
  GET_ALL_STANDARDS_SUCCESS,
  GET_ALL_STANDARDS_FAILURE,
  ADD_NEW_STANDARDS_REQUEST,
  ADD_NEW_STANDARDS_SUCCESS,
  ADD_NEW_STANDARDS_FAILURE,
  GET_FILTERED_STANDARDS_REQUEST,
  GET_FILTERED_STANDARDS_SUCCESS,
  GET_FILTERED_STANDARDS_FAILURE,
  ACTIVE_OR_INACTIVE_STANDARDS_REQUEST,
  ACTIVE_OR_INACTIVE_STANDARDS_SUCCESS,
  ACTIVE_OR_INACTIVE_STANDARDS_FAILURE,
  EDIT_STANDARDS_DETAILS_REQUEST,
  EDIT_STANDARDS_DETAILS_SUCCESS,
  EDIT_STANDARDS_DETAILS_FAILURE,
  ADD_NEW_STANDARDS_DETAILS_REQUEST,
  ADD_NEW_STANDARDS_DETAILS_SUCCESS,
  ADD_NEW_STANDARDS_DETAILS_FAILURE,
  GET_STANDARDS_DETAIL_REQUEST,
  GET_STANDARDS_DETAIL_SUCCESS,
  GET_STANDARDS_DETAIL_FAILURE,
  ACTIVE_OR_INACTIVE_STANDARDS_DETAILS_REQUEST,
  ACTIVE_OR_INACTIVE_STANDARDS_DETAILS_SUCCESS,
  ACTIVE_OR_INACTIVE_STANDARDS_DETAILS_FAILURE,
  CHANGE_STANDARDS_REQUEST,
  CHANGE_STANDARDS_SUCCESS,
  CHANGE_STANDARDS_FAILURE,
} = StandardsActions;

const initialState: StandardState = {
  allStandards: {
    count: null,
    next: null,
    previous: null,
    results: [],
  },
  standardsDetails: {
    id: '',
    name: '',
    version: '',
    approved: false,
    approval_date: '',
    publication_date: '',
    is_active: false,
    items: [],
  },
};

export const standardsReducer: Reducer<StandardState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_STANDARDS_REQUEST:
        break;

      case GET_ALL_STANDARDS_FAILURE: {
        draft.allStandards = {
          count: null,
          next: null,
          previous: null,
          results: [],
        };
        break;
      }

      case ACTIVE_OR_INACTIVE_STANDARDS_REQUEST:
        break;

      case ACTIVE_OR_INACTIVE_STANDARDS_SUCCESS:
        break;

      case ACTIVE_OR_INACTIVE_STANDARDS_FAILURE:
        break;

      case ADD_NEW_STANDARDS_REQUEST:
        break;

      case ADD_NEW_STANDARDS_SUCCESS:
        break;

      case ADD_NEW_STANDARDS_FAILURE:
        break;

      case EDIT_STANDARDS_REQUEST:
        break;

      case EDIT_STANDARDS_SUCCESS:
        break;

      case EDIT_STANDARDS_FAILURE:
        break;

      case GET_FILTERED_STANDARDS_REQUEST:
        break;

      case GET_FILTERED_STANDARDS_FAILURE:
        break;

      case GET_ALL_STANDARDS_SUCCESS:
      case GET_FILTERED_STANDARDS_SUCCESS: {
        const { standards } = action.payload;
        draft.allStandards = standards;
        break;
      }
      case GET_STANDARDS_DETAIL_REQUEST:
        break;

      case GET_STANDARDS_DETAIL_SUCCESS: {
        const { standardsDetails } = action.payload;
        draft.standardsDetails = standardsDetails;
        break;
      }

      case GET_STANDARDS_DETAIL_FAILURE:
        break;

      case ACTIVE_OR_INACTIVE_STANDARDS_DETAILS_REQUEST:
        break;

      case ACTIVE_OR_INACTIVE_STANDARDS_DETAILS_SUCCESS:
        break;

      case ACTIVE_OR_INACTIVE_STANDARDS_DETAILS_FAILURE:
        break;

      case ADD_NEW_STANDARDS_DETAILS_REQUEST:
        break;

      case ADD_NEW_STANDARDS_DETAILS_SUCCESS:
        break;

      case ADD_NEW_STANDARDS_DETAILS_FAILURE:
        break;

      case EDIT_STANDARDS_DETAILS_REQUEST:
        break;

      case EDIT_STANDARDS_DETAILS_SUCCESS:
        break;

      case EDIT_STANDARDS_DETAILS_FAILURE:
        break;

      case CHANGE_STANDARDS_REQUEST:
        break;

      case CHANGE_STANDARDS_SUCCESS:
        break;

      case CHANGE_STANDARDS_FAILURE:
        break;

      default: {
        return draft;
      }
    }
  });
};
