import produce from 'immer';
import { Reducer } from 'redux';

import type { UsersState } from './types';
import { UsersActions } from './types';

const {
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILURE,
  GET_FILTERED_USER_REQUEST,
  GET_FILTERED_USER_SUCCESS,
  GET_FILTERED_USER_FAILURE,
  ADD_NEW_USER_REQUEST,
  ADD_NEW_USER_SUCCESS,
  ADD_NEW_USER_FAILURE,
  SEND_CONFIRMATION_EMAIL_TO_USER_REQUEST,
  SEND_CONFIRMATION_EMAIL_TO_USER_SUCCESS,
  SEND_CONFIRMATION_EMAIL_TO_USER_FAILURE,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  ACTIVE_OR_INACTIVE_USER_REQUEST,
  ACTIVE_OR_INACTIVE_USER_SUCCESS,
  ACTIVE_OR_INACTIVE_USER_FAILURE,
} = UsersActions;

const initialState: UsersState = {
  users: [],
  lastUserAdded: {
    id: null,
    document_number: null,
    corporate_name_or_name: null,
    fantasy_name: null,
    user_profile: {id: '', name: ''},
    phone: null,
    position: null,
    email: null,
    password_inclusion_date: null,
    status: null,
    status_user: null,
    sendAccessInvitation: null,
  },
  lastUserIdClickedToResendEmail: null,
};

export const usersReducer: Reducer<UsersState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_USERS_REQUEST: {
        break;
      }

      case GET_ALL_USERS_SUCCESS: {
        const { users } = action.payload;

        draft.users = users;

        break;
      }

      case GET_ALL_USERS_FAILURE: {
        draft.users = [];

        break;
      }

      case GET_FILTERED_USER_REQUEST: {
        break;
      }

      case GET_FILTERED_USER_SUCCESS: {
        const { users } = action.payload;

        draft.users = users;

        break;
      }

      case GET_FILTERED_USER_FAILURE: {
        break;
      }

      case ADD_NEW_USER_REQUEST: {
        break;
      }

      case ADD_NEW_USER_SUCCESS: {
        const { newUserAdded, sendAccessInvitation } = action.payload;

        draft.lastUserAdded.id = newUserAdded.id;
        draft.lastUserAdded.document_number = newUserAdded.document_number;
        draft.lastUserAdded.corporate_name_or_name =
          newUserAdded.corporate_name_or_name;
        draft.lastUserAdded.fantasy_name = newUserAdded.fantasy_name;
        draft.lastUserAdded.user_profile = newUserAdded.user_profile;
        draft.lastUserAdded.phone = newUserAdded.phone;
        draft.lastUserAdded.position = newUserAdded.position;
        draft.lastUserAdded.email = newUserAdded.email;
        draft.lastUserAdded.password_inclusion_date =
          newUserAdded.password_inclusion_date;
        draft.lastUserAdded.status = newUserAdded.status;
        draft.lastUserAdded.status_user = newUserAdded.status_user;
        draft.lastUserAdded.sendAccessInvitation = sendAccessInvitation;

        break;
      }

      case ADD_NEW_USER_FAILURE: {
        draft.lastUserAdded.id = null;
        draft.lastUserAdded.document_number = null;
        draft.lastUserAdded.corporate_name_or_name = null;
        draft.lastUserAdded.fantasy_name = null;
        draft.lastUserAdded.user_profile = {id: '', name: ''};
        draft.lastUserAdded.phone = null;
        draft.lastUserAdded.position = null;
        draft.lastUserAdded.email = null;
        draft.lastUserAdded.password_inclusion_date = null;
        draft.lastUserAdded.status = null;
        draft.lastUserAdded.status_user = null;
        draft.lastUserAdded.sendAccessInvitation = null;

        break;
      }

      case SEND_CONFIRMATION_EMAIL_TO_USER_REQUEST: {
        break;
      }

      case SEND_CONFIRMATION_EMAIL_TO_USER_SUCCESS: {
        const { userId } = action.payload;

        draft.lastUserIdClickedToResendEmail = userId;

        break;
      }

      case SEND_CONFIRMATION_EMAIL_TO_USER_FAILURE: {
        draft.lastUserIdClickedToResendEmail = null;

        break;
      }

      case EDIT_USER_REQUEST: {
        break;
      }

      case EDIT_USER_SUCCESS: {
        break;
      }

      case EDIT_USER_FAILURE: {
        draft.lastUserAdded.id = null;
        draft.lastUserAdded.document_number = null;
        draft.lastUserAdded.corporate_name_or_name = null;
        draft.lastUserAdded.fantasy_name = null;
        draft.lastUserAdded.user_profile =  {id: '', name: ''};
        draft.lastUserAdded.phone = null;
        draft.lastUserAdded.position = null;
        draft.lastUserAdded.email = null;
        draft.lastUserAdded.password_inclusion_date = null;
        draft.lastUserAdded.status = null;
        draft.lastUserAdded.status_user = null;
        draft.lastUserAdded.sendAccessInvitation = null;

        break;
      }

      case ACTIVE_OR_INACTIVE_USER_REQUEST: {
        break;
      }

      case ACTIVE_OR_INACTIVE_USER_SUCCESS: {
        const { newUserStatus } = action.payload;

        draft.lastUserAdded.status = newUserStatus;

        break;
      }

      case ACTIVE_OR_INACTIVE_USER_FAILURE: {
        draft.lastUserAdded.id = null;
        draft.lastUserAdded.document_number = null;
        draft.lastUserAdded.corporate_name_or_name = null;
        draft.lastUserAdded.fantasy_name = null;
        draft.lastUserAdded.user_profile =  {id: '', name: ''};
        draft.lastUserAdded.phone = null;
        draft.lastUserAdded.position = null;
        draft.lastUserAdded.email = null;
        draft.lastUserAdded.password_inclusion_date = null;
        draft.lastUserAdded.status = null;
        draft.lastUserAdded.status_user = null;
        draft.lastUserAdded.sendAccessInvitation = null;

        break;
      }

      default: {
        return draft;
      }
    }
  });
};
