export enum ProjectsForAnonimizationActions {
  GET_ALL_ANONIMIZATION_REQUEST = 'GET_ALL_ANONIMIZATION_REQUEST',
  GET_ALL_ANONIMIZATION_SUCCESS = 'GET_ALL_ANONIMIZATION_SUCCESS',
  GET_ALL_ANONIMIZATION_FAILURE = 'GET_ALL_ANONIMIZATION_FAILURE',
  APPROVED_ANONIMIZATION_REQUEST = 'APPROVED_ANONIMIZATION_REQUEST',
  APPROVED_ANONIMIZATION_SUCCESS = 'APPROVED_ANONIMIZATION_SUCCESS',
  APPROVED_ANONIMIZATION_FAILURE = 'APPROVED_ANONIMIZATION_FAILURE',
  GET_ANONIMIZATION_DETAILS_REQUEST = 'GET_ANONIMIZATION_DETAILS_REQUEST',
  GET_ANONIMIZATION_DETAILS_SUCCESS = 'GET_ANONIMIZATION_DETAILS_SUCCESS',
  GET_ANONIMIZATION_DETAILS_FAILURE = 'GET_ANONIMIZATION_DETAILS_FAILURE',
  SET_PROJECTS_FOR_ANONIMIZATION_REQUEST = 'SET_PROJECTS_FOR_ANONIMIZATION_REQUEST',
  SET_PROJECTS_FOR_ANONIMIZATION_SUCCESS = 'SET_PROJECTS_FOR_ANONIMIZATION_SUCCESS',
  SET_PROJECTS_FOR_ANONIMIZATION_FAILURE = 'SET_PROJECTS_FOR_ANONIMIZATION_FAILURE',
  GET_ALL_PROJECTS_FOR_ANONIMIZATION_REQUEST = 'GET_ALL_PROJECTS_FOR_ANONIMIZATION_REQUEST',
  GET_ALL_PROJECTS_FOR_ANONIMIZATION_SUCCESS = 'GET_ALL_PROJECTS_FOR_ANONIMIZATION_SUCCESS',
  GET_ALL_PROJECTS_FOR_ANONIMIZATION_FAILURE = 'GET_ALL_PROJECTS_FOR_ANONIMIZATION_FAILURE',
}

export type CustomersProjectsForAnonymization = {
  id: string;
  name: string;
  responsible: string;
  responsible_project_manager: string;
  project_status: string;
  created_at: string;
  project_expiration_date: string;
  last_modification: string;
};

export type Anonymization = {
  id: string;
  name: string;
  request_date: string;
  anonymization_requester: string;
  approved: boolean;
  approved_by: string;
  date_of_approval_for_anonymization: string;
}

export type AllAnonymizationRequest = {
  count: number;
  next: boolean | null;
  previous: boolean | null;
  results: Anonymization[];
}

export type AllCustomersProjectsForAnonymization = {
  customers_projects_for_anonymization: CustomersProjectsForAnonymization[];
}

export type AnonymizationDetailsRequest = {
  id: string;
  name: string;
  request_date: string;
  anonymization_requester: string;
  approved: boolean;
  approved_by: string;
  date_of_approval_for_anonymization: string;
  customers_projects: CustomersProjectsForAnonymization[];
}

export type ProjectForAnonimizationState = {
  allProjectsForAnonimizations: AllCustomersProjectsForAnonymization;
  allAnonymizationRequest: AllAnonymizationRequest;
  anonymizationDetails: AnonymizationDetailsRequest;
};

export type CustomersProjectsId = {
  id: string;
}

export type CustomersProjectsForAnonymizationPayload = {
  customers_projects: CustomersProjectsId[];
}

export type UpdateAnonymizationPayloadParams = {
  id: string;
  approved: boolean;
}

export type StateAnonymizationPayloadParams = {
  approved: boolean;
}

export type SearchedAnonimizations = {
  approved: string | null;
};

export type AnonymizationDetailsPayload = {
  id: string;
}