import type { Action } from 'store/types';

import { CustomerSectorsActions, Sectors } from './types';


const {
  GET_ALL_CUSTOMER_SECTORS_REQUEST,
  GET_ALL_CUSTOMER_SECTORS_SUCCESS,
  GET_ALL_CUSTOMER_SECTORS_FAILURE,
} = CustomerSectorsActions;

function getCustomerSectorsRequest(isCustomerProjectPage = false): Action {
  return {
    type: GET_ALL_CUSTOMER_SECTORS_REQUEST,
    payload: {
      isCustomerProjectPage,
    },
  };
}

function getCustomerSectorsSuccess(sectors: Sectors[]): Action {
  return {
    type: GET_ALL_CUSTOMER_SECTORS_SUCCESS,
    payload: {
      sectors,
    },
  };
}

function getCustomerSectorsFailure(): Action {
  return {
    type: GET_ALL_CUSTOMER_SECTORS_FAILURE,
  };
}

export const customerSectorsActionsFunctions = {
  getCustomerSectorsRequest,
  getCustomerSectorsSuccess,
  getCustomerSectorsFailure,
};
