/* eslint-disable no-case-declarations */
import produce from 'immer';
import {
  Reducer
} from 'redux';

import {
  ProjectSettingsActions
} from './types';
import type {
  ProjectSettingsState
} from './types';


const {
  EDIT_PROJECTSETTING_REQUEST,
  EDIT_PROJECTSETTING_SUCCESS,
  EDIT_PROJECTSETTING_FAILURE,
  ADD_NEW_PROJECTSETTING_REQUEST,
  ADD_NEW_PROJECTSETTING_SUCCESS,
  ADD_NEW_PROJECTSETTING_FAILURE,
  GET_ALL_PROJECTSETTING_REQUEST,
  GET_ALL_PROJECTSETTING_SUCCESS,
  GET_ALL_PROJECTSETTING_FAILURE,
  GET_PROJECTSETTING_DETAILS_REQUEST,
  GET_PROJECTSETTING_DETAILS_SUCCESS,
  GET_PROJECTSETTING_DETAILS_FAILURE,
  GET_FILTERED_PROJECTSETTING_REQUEST,
  GET_FILTERED_PROJECTSETTING_SUCCESS,
  GET_FILTERED_PROJECTSETTING_FAILURE,
  RESET_THE_LAST_PROJECTSETTING_DETAILS,
  RESET_THE_PROJECTSETTING_DETAIL_STATE,
  ACTIVE_OR_INACTIVE_PROJECTSETTING_REQUEST,
  ACTIVE_OR_INACTIVE_PROJECTSETTING_SUCCESS,
  ACTIVE_OR_INACTIVE_PROJECTSETTING_FAILURE,
  GET_ALL_QUESTIONNAIRE_PROJECTSETTINGS_REQUEST,
  GET_ALL_QUESTIONNAIRE_PROJECTSETTINGS_SUCCESS,
  GET_ALL_QUESTIONNAIRE_PROJECTSETTINGS_FAILURE,
  GET_FILTERED_QUESTIONNAIRE_PROJECTSETTINGS_REQUEST,
  GET_FILTERED_QUESTIONNAIRE_PROJECTSETTINGS_SUCCESS,
  GET_FILTERED_QUESTIONNAIRE_PROJECTSETTINGS_FAILURE
} = ProjectSettingsActions;

const initialState: ProjectSettingsState = {
  allProjectSettings: [],
  allQuestionnaires: [],
  currentProjectSettingsDetails: {
    id: '',
    project_name: '',
    is_active: false,
    activities: [],
    checklists: [],
    questionnaires: [],
    crown_jewels: []
  }
};

export const projectSettingsReducer: Reducer<ProjectSettingsState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_PROJECTSETTING_REQUEST:
        break;

      case GET_ALL_PROJECTSETTING_SUCCESS: {
        const { projectSettings } = action.payload;
        draft.allProjectSettings = projectSettings;
        break;
      }

      case GET_ALL_PROJECTSETTING_FAILURE: {
        draft.allProjectSettings = [];
        break;
      }

      case GET_PROJECTSETTING_DETAILS_REQUEST:
        break;

      case GET_PROJECTSETTING_DETAILS_SUCCESS: {
        const { projectSettingsDetails } = action.payload;
        draft.currentProjectSettingsDetails.id = projectSettingsDetails.id;
        draft.currentProjectSettingsDetails.project_name = projectSettingsDetails.project_name;
        draft.currentProjectSettingsDetails.is_active = projectSettingsDetails.is_active;
        draft.currentProjectSettingsDetails.questionnaires = projectSettingsDetails.questionnaires;
        draft.currentProjectSettingsDetails.checklists = projectSettingsDetails.checklists;
        draft.currentProjectSettingsDetails.activities = projectSettingsDetails.activities;
        draft.currentProjectSettingsDetails.crown_jewels = projectSettingsDetails.crown_jewels;
        break;
      }

      case GET_PROJECTSETTING_DETAILS_FAILURE: {
        draft.currentProjectSettingsDetails.id = '';
        draft.currentProjectSettingsDetails.project_name = '';
        draft.currentProjectSettingsDetails.is_active = false;
        draft.currentProjectSettingsDetails.questionnaires = [];
        draft.currentProjectSettingsDetails.checklists = [];
        draft.currentProjectSettingsDetails.activities = [];
        draft.currentProjectSettingsDetails.crown_jewels = [];
        break;
      }

      case GET_FILTERED_PROJECTSETTING_REQUEST:
        break;

      case GET_FILTERED_PROJECTSETTING_SUCCESS: {
        const { projectSettings } = action.payload;
        draft.allProjectSettings = projectSettings;
        break;
      }

      case GET_FILTERED_PROJECTSETTING_FAILURE:
        break;

      case ADD_NEW_PROJECTSETTING_REQUEST:
        break;

      case ADD_NEW_PROJECTSETTING_SUCCESS:
        break;

      case ADD_NEW_PROJECTSETTING_FAILURE:
        break;

      case EDIT_PROJECTSETTING_REQUEST:
        break;

      case EDIT_PROJECTSETTING_SUCCESS:
        break;

      case EDIT_PROJECTSETTING_FAILURE:
        break;

      case ACTIVE_OR_INACTIVE_PROJECTSETTING_REQUEST:
        break;

      case ACTIVE_OR_INACTIVE_PROJECTSETTING_SUCCESS:
        break;

      case ACTIVE_OR_INACTIVE_PROJECTSETTING_FAILURE:
        break;

      case RESET_THE_LAST_PROJECTSETTING_DETAILS: {
        draft.currentProjectSettingsDetails.id = '';
        draft.currentProjectSettingsDetails.project_name = '';
        draft.currentProjectSettingsDetails.is_active = false;
        draft.currentProjectSettingsDetails.activities = [];
        draft.currentProjectSettingsDetails.checklists = [];
        draft.currentProjectSettingsDetails.crown_jewels = [];
        draft.currentProjectSettingsDetails.questionnaires = [];
        break;
      }

      case RESET_THE_PROJECTSETTING_DETAIL_STATE: {
        draft.currentProjectSettingsDetails.id = '';
        draft.currentProjectSettingsDetails.project_name = '';
        draft.currentProjectSettingsDetails.is_active = false;
        draft.currentProjectSettingsDetails.activities = [];
        draft.currentProjectSettingsDetails.checklists = [];
        draft.currentProjectSettingsDetails.crown_jewels = [];
        draft.currentProjectSettingsDetails.questionnaires = [];

        break;
      }

      case GET_ALL_QUESTIONNAIRE_PROJECTSETTINGS_REQUEST:
        break;

      case GET_ALL_QUESTIONNAIRE_PROJECTSETTINGS_SUCCESS: {
        const { questionnaires } = action.payload;
        draft.allQuestionnaires = questionnaires;
        break;
      }

      case GET_ALL_QUESTIONNAIRE_PROJECTSETTINGS_FAILURE:
        break;

      case GET_FILTERED_QUESTIONNAIRE_PROJECTSETTINGS_REQUEST:
        break;

      case GET_FILTERED_QUESTIONNAIRE_PROJECTSETTINGS_SUCCESS: {
        const { questionnaires } = action.payload;

        draft.allQuestionnaires = questionnaires;
        break;
      }

      case GET_FILTERED_QUESTIONNAIRE_PROJECTSETTINGS_FAILURE:
        break;

      default: {
        return draft;
      }
    }
  });
};