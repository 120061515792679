import type { Action } from 'store/types';

import { ObjectWithId } from '../vulnerabilities/types';
import { AllQuestions, RecomendationsBodyType, Risk, RiskActions, RiskDetail, RiskPostBody, RiskPutBody, TypesRecommendation } from './types';

const {
  GET_ALL_RISK_REQUEST,
  GET_ALL_RISK_SUCCESS,
  GET_ALL_RISK_FAILURE,
  GET_ALL_RISK_OPTIONS_REQUEST,
  GET_ALL_RISK_OPTIONS_SUCCESS,
  GET_ALL_RISK_OPTIONS_FAILURE,
  CREATE_NEW_RISK_REQUEST,
  CREATE_NEW_RISK_SUCCESS,
  CREATE_NEW_RISK_FAILURE,
  GET_SEARCH_FILTERED_RISKS_REQUEST,
  GET_SEARCH_FILTERED_RISKS_SUCCESS,
  GET_SEARCH_FILTERED_RISKS_FAILURE,
  GET_RISK_DETAIL_REQUEST,
  GET_RISK_DETAIL_SUCCESS,
  GET_RISK_DETAIL_FAILURE,
  GET_ALL_RECOMENDATIONS_REQUEST,
  GET_ALL_RECOMENDATIONS_SUCCESS,
  GET_ALL_RECOMENDATIONS_FAILURE,
  EDIT_RECOMENDATIONS_REQUEST,
  EDIT_RECOMENDATIONS_SUCCESS,
  EDIT_RECOMENDATIONS_FAILURE,
  GET_ALL_RISK_STATUS_REQUEST,
  GET_ALL_RISK_STATUS_SUCCESS,
  GET_ALL_RISK_STATUS_FAILURE,
  EDIT_RISK_REQUEST,
  EDIT_RISK_SUCCESS,
  EDIT_RISK_FAILURE,
  UPDATE_RISK_THREAT_REQUEST,
  UPDATE_RISK_THREAT_SUCCESS,
  UPDATE_RISK_THREAT_FAILURE
} = RiskActions;

function getAllRisksRequest(last_page?: number): Action {
  return {
    type: GET_ALL_RISK_REQUEST,
    payload: {
      last_page
    }
  };
}

function getAllRisksSuccess(risk: Risk[], total: number, last_page: number, has_next: boolean): Action {
  return {
    type: GET_ALL_RISK_SUCCESS,
    payload: {
      risk,
      total,
      last_page, 
      has_next
    },
  };
}

function getAllRisksFailure(): Action {
  return {
    type: GET_ALL_RISK_FAILURE,
  };
}

function updateRiskThreatRequest(riskId: string, riskUpdateAdd: ObjectWithId[], riskUpdateRemove: ObjectWithId[]): Action {
  return {
    type: UPDATE_RISK_THREAT_REQUEST,
    payload: {
      riskId,
      riskUpdateAdd,
      riskUpdateRemove
    }
  };
}

function updateRiskThreatSuccess(): Action {
  return {
    type: UPDATE_RISK_THREAT_SUCCESS,
  };
}

function updateRiskThreatFailure(): Action {
  return {
    type: UPDATE_RISK_THREAT_FAILURE,
  };
}

function editRecommendationsRequest(riskId: string, currentRecommendation: RecomendationsBodyType): Action {
  return {
    type: EDIT_RECOMENDATIONS_REQUEST,
    payload: {
      riskId,
      currentRecommendation
    }
  };
}

function editRecommendationsSuccess(): Action {
  return {
    type: EDIT_RECOMENDATIONS_SUCCESS,
  };
}

function editRecommendationsFailure(): Action {
  return {
    type: EDIT_RECOMENDATIONS_FAILURE,
  };
}


function getAllRecomendationsRequest(): Action {
  return {
    type: GET_ALL_RECOMENDATIONS_REQUEST,
  };
}

function getAllRecomendationsSuccess(recomendations: TypesRecommendation): Action {
  return {
    type: GET_ALL_RECOMENDATIONS_SUCCESS,
    payload: {
      recomendations,
    },
  };
}

function getAllRecomendationsFailure(): Action {
  return {
    type: GET_ALL_RECOMENDATIONS_FAILURE,
  };
}


function editRiskRequest(riskId: string, riskPutBody: RiskPutBody): Action {
  return {
    type: EDIT_RISK_REQUEST,
    payload: {
      riskId,
      riskPutBody
    }
  };
}

function editRiskSuccess(): Action {
  return {
    type: EDIT_RISK_SUCCESS,
  };
}

function editRiskFailure(): Action {
  return {
    type: EDIT_RISK_FAILURE,
  };
}

function editRiskStatusRequest(riskId: string, riskStatus: boolean): Action {
  return {
    type: GET_ALL_RISK_STATUS_REQUEST,
    payload: {
      riskId,
      riskStatus
    },
  };
}

function editRiskStatusSuccess(): Action {
  return {
    type: GET_ALL_RISK_STATUS_SUCCESS,
  };
}

function editRiskStatusFailure(): Action {
  return {
    type: GET_ALL_RISK_STATUS_FAILURE,
  };
}

function getRiskDetailRequest(riskId: string): Action {
  return {
    type: GET_RISK_DETAIL_REQUEST,
    payload: {
      riskId
    },
  };
}

function getRiskDetailSuccess(risk: RiskDetail): Action {
  return {
    type: GET_RISK_DETAIL_SUCCESS,
    payload: {
      risk
    },
  };
}

function getRiskDetailFailure(): Action {
  return {
    type: GET_RISK_DETAIL_FAILURE,
  };
}

function getSearchFilteredRisksRequest(
  filteredRisks: Array<string | boolean | null>,
  isActive: boolean | null
): Action {
  return {
    type: GET_SEARCH_FILTERED_RISKS_REQUEST,
    payload: {
      filteredRisks,
      isActive
    },
  };
}

function getSearchFilteredRisksSuccess(risks: Risk[]): Action {
  return {
    type: GET_SEARCH_FILTERED_RISKS_SUCCESS,
    payload: {
      risks
    },
  };
}

function getSearchFilteredRisksFailure(): Action {
  return {
    type: GET_SEARCH_FILTERED_RISKS_FAILURE,
  };
}


function getAllQuestionsRequest(): Action {
  return {
    type: GET_ALL_RISK_OPTIONS_REQUEST,
  };
}

function getAllQuestionsSuccess(allQuestions: AllQuestions): Action {
  return {
    type: GET_ALL_RISK_OPTIONS_SUCCESS,
    payload: {
      allQuestions,
    },
  };
}

function getAllQuestionsFailure(): Action {
  return {
    type: GET_ALL_RISK_OPTIONS_FAILURE,
  };
}

function createNewRiskRequest(riskBody: RiskPostBody): Action {
  return {
    type: CREATE_NEW_RISK_REQUEST,
    payload: {
      riskBody
    }
  };
}

function createNewRiskSuccess(): Action {
  return {
    type: CREATE_NEW_RISK_SUCCESS,
  };
}

function createNewRiskFailure(): Action {
  return {
    type: CREATE_NEW_RISK_FAILURE,
  };
}

export const riskActionsFunctions = {
  editRiskRequest,
  editRiskSuccess,
  editRiskFailure,
  getAllRisksRequest,
  getAllRisksSuccess,
  getAllRisksFailure,
  getAllQuestionsRequest,
  getAllQuestionsSuccess,
  getAllQuestionsFailure,
  createNewRiskRequest,
  createNewRiskSuccess,
  createNewRiskFailure,
  getSearchFilteredRisksRequest,
  getSearchFilteredRisksSuccess,
  getSearchFilteredRisksFailure,
  getRiskDetailRequest,
  getRiskDetailSuccess,
  getRiskDetailFailure,
  getAllRecomendationsRequest,
  getAllRecomendationsSuccess,
  getAllRecomendationsFailure,
  editRecommendationsRequest,
  editRecommendationsSuccess,
  editRecommendationsFailure,
  editRiskStatusRequest,
  editRiskStatusSuccess,
  editRiskStatusFailure,
  updateRiskThreatRequest,
  updateRiskThreatSuccess,
  updateRiskThreatFailure
};
