import type { ForgotPasswordFormData } from 'pages/ForgotPassword/types';
import type { LoginFormData } from 'pages/Login/types';
import type { ChangePasswordFormData } from 'pages/Profile/ModalChangePassword/types';
import type { Action } from 'store/types';

import type {
  ApiFetchTokenExpiredResponse,
  CurrentUserDataSuccessResponse,
  UserAuthState,
  UserRegisterPasswordSuccessRequest,
  UserResetPasswordSuccessRequest,
} from './types';
import { AuthStateActions } from './types';

const {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  CURRENT_USER_DATA_REQUEST,
  CURRENT_USER_DATA_SUCCESS,
  CURRENT_USER_DATA_FAILURE,
  USER_CREATE_TOTP_DEVICE_REQUEST,
  USER_CREATE_TOTP_DEVICE_SUCCESS,
  USER_CREATE_TOTP_DEVICE_FAILURE,
  USER_AUTH_TOTP_DEVICE_REQUEST,
  USER_AUTH_TOTP_DEVICE_SUCCESS,
  USER_AUTH_TOTP_DEVICE_FAILURE,
  USER_VERIFY_OR_ENABLE_USER_TOTP_DEVICE_REQUEST,
  USER_VERIFY_OR_ENABLE_USER_TOTP_DEVICE_SUCCESS,
  USER_VERIFY_OR_ENABLE_USER_TOTP_DEVICE_FAILURE,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILURE,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_FORGOT_PASSWORD_FAILURE,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAILURE,
  USER_REGISTER_PASSWORD_REQUEST,
  USER_REGISTER_PASSWORD_SUCCESS,
  USER_REGISTER_PASSWORD_FAILURE,
  USER_TOKEN_VERIFY_REQUEST,
  USER_TOKEN_VERIFY_SUCCESS,
  USER_TOKEN_VERIFY_FAILURE,
  UPDATE_USER_PICTURE_REQUEST,
  UPDATE_USER_PICTURE_SUCCESS,
  UPDATE_USER_PICTURE_FAILURE,
  DELETE_USER_PICTURE_REQUEST,
  DELETE_USER_PICTURE_SUCCESS,
  DELETE_USER_PICTURE_FAILURE,
  USER_CHANGE_PASSWORD_REQUEST,
  USER_CHANGE_PASSWORD_SUCCESS,
  USER_CHANGE_PASSWORD_FAILURE,
  RESET_THE_USERS_LAST_EMAIL_ON_THE_FORGOT_PASSWORD_SCREEN,
} = AuthStateActions;

function getUserLoginRequest({ email, password }: LoginFormData): Action {
  return {
    type: USER_LOGIN_REQUEST,
    payload: {
      email,
      password,
    },
  };
}

function getUserLoginSuccess(user: Partial<UserAuthState>): Action {
  return {
    type: USER_LOGIN_SUCCESS,
    payload: {
      user,
    },
  };
}

function getUserLoginFailure(): Action {
  return {
    type: USER_LOGIN_FAILURE,
  };
}

function getCurrentUserDataRequest(userId: string): Action {
  return {
    type: CURRENT_USER_DATA_REQUEST,
    payload: {
      userId,
    },
  };
}

function getCurrentUserDataSuccess(
  userData: CurrentUserDataSuccessResponse
): Action {
  return {
    type: CURRENT_USER_DATA_SUCCESS,
    payload: {
      userData,
    },
  };
}

function getCurrentUserDataFailure(): Action {
  return {
    type: CURRENT_USER_DATA_FAILURE,
  };
}

function getUserCreateTotpDeviceRequest(): Action {
  return {
    type: USER_CREATE_TOTP_DEVICE_REQUEST,
  };
}

function getUserCreateTotpDeviceSuccess(totpUrl: string): Action {
  return {
    type: USER_CREATE_TOTP_DEVICE_SUCCESS,
    payload: {
      totpUrl,
    },
  };
}

function getUserCreateTotpDeviceFailure(): Action {
  return {
    type: USER_CREATE_TOTP_DEVICE_FAILURE,
  };
}

function getVerifyOrEnableUserTotpDeviceRequest(totpToken: string, isCustomer: boolean): Action {
  return {
    type: USER_VERIFY_OR_ENABLE_USER_TOTP_DEVICE_REQUEST,
    payload: {
      totpToken,
      isCustomer,
    },
  };
}

function getVerifyOrEnableUserTotpDeviceSuccess(isSuccess: boolean): Action {
  return {
    type: USER_VERIFY_OR_ENABLE_USER_TOTP_DEVICE_SUCCESS,
    payload: {
      isSuccess,
    },
  };
}

function getVerifyOrEnableUserTotpDeviceFailure(): Action {
  return {
    type: USER_VERIFY_OR_ENABLE_USER_TOTP_DEVICE_FAILURE,
  };
}

function getUserAuthTotpDeviceRequest(totpToken: string, isCustomer: boolean | null): Action {
  return {
    type: USER_AUTH_TOTP_DEVICE_REQUEST,
    payload: {
      totpToken,
      isCustomer,
    },
  };
}

function getUserAuthTotpDeviceSuccess(isSuccess: boolean): Action {
  return {
    type: USER_AUTH_TOTP_DEVICE_SUCCESS,
    payload: {
      isSuccess,
    },
  };
}

function getUserAuthTotpDeviceFailure(): Action {
  return {
    type: USER_AUTH_TOTP_DEVICE_FAILURE,
  };
}

function getUserLogoutRequest(): Action {
  return {
    type: USER_LOGOUT_REQUEST,
  };
}

function getUserLogoutSuccess(): Action {
  return {
    type: USER_LOGOUT_SUCCESS,
  };
}

function getUserLogoutFailure(
  errorStatus: number,
  errorResponseData: unknown
): Action {
  return {
    type: USER_LOGOUT_FAILURE,
    payload: {
      errorStatus,
      errorResponseData,
    },
  };
}

function getUserForgotPasswordRequest({
  email,
}: ForgotPasswordFormData): Action {
  return {
    type: USER_FORGOT_PASSWORD_REQUEST,
    payload: {
      email,
    },
  };
}

function getUserForgotPasswordSuccess(email: string): Action {
  return {
    type: USER_FORGOT_PASSWORD_SUCCESS,
    payload: {
      email,
    },
  };
}

function getUserForgotPasswordFailure(): Action {
  return {
    type: USER_FORGOT_PASSWORD_FAILURE,
  };
}

function getUserResetPasswordRequest({
  newPassword,
  confirmNewPassword,
  uuid,
  token,
}: UserResetPasswordSuccessRequest): Action {
  return {
    type: USER_RESET_PASSWORD_REQUEST,
    payload: {
      newPassword,
      confirmNewPassword,
      uuid,
      token,
    },
  };
}

function getUserResetPasswordSuccess(): Action {
  return {
    type: USER_RESET_PASSWORD_SUCCESS,
  };
}

function getUserResetPasswordFailure(): Action {
  return {
    type: USER_RESET_PASSWORD_FAILURE,
  };
}

function getUserRegisterPasswordRequest({
  newPassword,
  confirmNewPassword,
  uuid,
  token,
}: UserRegisterPasswordSuccessRequest): Action {
  return {
    type: USER_REGISTER_PASSWORD_REQUEST,
    payload: {
      newPassword,
      confirmNewPassword,
      uuid,
      token,
    },
  };
}

function getUserRegisterPasswordSuccess(): Action {
  return {
    type: USER_REGISTER_PASSWORD_SUCCESS,
  };
}

function getUserRegisterPasswordFailure(): Action {
  return {
    type: USER_REGISTER_PASSWORD_FAILURE,
  };
}

function getTokenVerifyRequest(): Action {
  return {
    type: USER_TOKEN_VERIFY_REQUEST,
  };
}

function getTokenVerifySuccess({
  detail,
  code,
  messages,
}: ApiFetchTokenExpiredResponse): Action {
  return {
    type: USER_TOKEN_VERIFY_SUCCESS,
    payload: {
      detail,
      code,
      messages,
    },
  };
}

function getTokenVerifyFailure(errorMessage: string): Action {
  return {
    type: USER_TOKEN_VERIFY_FAILURE,
    payload: {
      errorMessage,
    },
  };
}

function getUpdateUserPictureRequest(
  userId: string,
  newPictureFormData: FormData
): Action {
  return {
    type: UPDATE_USER_PICTURE_REQUEST,
    payload: {
      userId,
      newPictureFormData,
    },
  };
}

function getUpdateUserPictureSuccess(): Action {
  return {
    type: UPDATE_USER_PICTURE_SUCCESS,
  };
}

function getUpdateUserPictureFailure(): Action {
  return {
    type: UPDATE_USER_PICTURE_FAILURE,
  };
}

function getDeleteUserPictureRequest(userId: string): Action {
  return {
    type: DELETE_USER_PICTURE_REQUEST,
    payload: {
      userId,
    },
  };
}

function getDeleteUserPictureSuccess(): Action {
  return {
    type: DELETE_USER_PICTURE_SUCCESS,
  };
}

function getDeleteUserPictureFailure(): Action {
  return {
    type: DELETE_USER_PICTURE_FAILURE,
  };
}

function getUserChangePasswordRequest(
  changePasswordData: ChangePasswordFormData
): Action {
  return {
    type: USER_CHANGE_PASSWORD_REQUEST,
    payload: {
      changePasswordData,
    },
  };
}

function getUserChangePasswordSuccess(newPassword: string): Action {
  return {
    type: USER_CHANGE_PASSWORD_SUCCESS,
    payload: {
      newPassword,
    },
  };
}

function getUserChangePasswordFailure(): Action {
  return {
    type: USER_CHANGE_PASSWORD_FAILURE,
  };
}

function resetTheUsersLastEmailOnTheForgotPasswordScreen(): Action {
  return {
    type: RESET_THE_USERS_LAST_EMAIL_ON_THE_FORGOT_PASSWORD_SCREEN,
  };
}

export const authActionsFunctions = {
  getUserLoginRequest,
  getUserLoginSuccess,
  getUserLoginFailure,
  getCurrentUserDataRequest,
  getCurrentUserDataSuccess,
  getCurrentUserDataFailure,
  getUserCreateTotpDeviceRequest,
  getUserCreateTotpDeviceSuccess,
  getUserCreateTotpDeviceFailure,
  getVerifyOrEnableUserTotpDeviceRequest,
  getVerifyOrEnableUserTotpDeviceSuccess,
  getVerifyOrEnableUserTotpDeviceFailure,
  getUserAuthTotpDeviceRequest,
  getUserAuthTotpDeviceSuccess,
  getUserAuthTotpDeviceFailure,
  getUserLogoutRequest,
  getUserLogoutSuccess,
  getUserLogoutFailure,
  getUserForgotPasswordRequest,
  getUserForgotPasswordSuccess,
  getUserForgotPasswordFailure,
  getUserResetPasswordRequest,
  getUserResetPasswordSuccess,
  getUserResetPasswordFailure,
  getUserRegisterPasswordRequest,
  getUserRegisterPasswordSuccess,
  getUserRegisterPasswordFailure,
  getTokenVerifyRequest,
  getTokenVerifySuccess,
  getTokenVerifyFailure,
  getUpdateUserPictureRequest,
  getUpdateUserPictureSuccess,
  getUpdateUserPictureFailure,
  getDeleteUserPictureRequest,
  getDeleteUserPictureSuccess,
  getDeleteUserPictureFailure,
  getUserChangePasswordRequest,
  getUserChangePasswordSuccess,
  getUserChangePasswordFailure,
  resetTheUsersLastEmailOnTheForgotPasswordScreen,
};
