import produce from 'immer';
import { Reducer } from 'redux';

import type { RiskFactorsState } from './types';
import { RiskFactorsActions } from './types';

const {
  GET_ALL_RISK_FACTORS_REQUEST,
  GET_ALL_RISK_FACTORS_SUCCESS,
  GET_ALL_RISK_FACTORS_FAILURE,
  GET_FILTERED_RISK_FACTOR_REQUEST,
  GET_FILTERED_RISK_FACTOR_SUCCESS,
  GET_FILTERED_RISK_FACTOR_FAILURE,
  GET_RISK_FACTOR_DETAIL_REQUEST,
  GET_RISK_FACTOR_DETAIL_SUCCESS,
  GET_RISK_FACTOR_DETAIL_FAILURE,
  ADD_NEW_RISK_FACTOR_REQUEST,
  ADD_NEW_RISK_FACTOR_SUCCESS,
  ADD_NEW_RISK_FACTOR_FAILURE,
  EDIT_RISK_FACTOR_REQUEST,
  EDIT_RISK_FACTOR_SUCCESS,
  EDIT_RISK_FACTOR_FAILURE,
  ACTIVE_OR_INACTIVE_RISK_FACTOR_REQUEST,
  ACTIVE_OR_INACTIVE_RISK_FACTOR_SUCCESS,
  ACTIVE_OR_INACTIVE_RISK_FACTOR_FAILURE,
  RESET_THE_RISK_FACTOR_DETAIL_STATE,
  GET_ALL_RISK_FACTORS_STATIC_REQUEST,
  GET_ALL_RISK_FACTORS_STATIC_SUCCESS,
  GET_ALL_RISK_FACTORS_STATIC_FAILURE,
  GET_FILTERED_RISK_STATIC_FACTOR_REQUEST,
  GET_FILTERED_RISK_FACTOR_STATIC_SUCCESS,
  GET_FILTERED_RISK_FACTOR_STATIC_FAILURE,
} = RiskFactorsActions;

const initialState: RiskFactorsState = {
  riskFactors: [],
  visited_pages: [],
  has_next: true,
  total: 0,
  currentRiskFactorDetail: {
    id: '',
    friendly_id: '',
    name: '',
    is_active: false,
    registration_status: '',
    controls: [],
    threats: [],
  },
  riskFactorStatic: [],
};

export const riskFactorsReducer: Reducer<RiskFactorsState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_RISK_FACTORS_REQUEST: {
        break;
      }

      case GET_ALL_RISK_FACTORS_SUCCESS: {
        const { riskFactors, total, last_page, has_next } = action.payload;
        const pagineAlreadyVisited = state.visited_pages.find (page => page === last_page);

        if(!pagineAlreadyVisited || draft.riskFactors) {
          draft.visited_pages.push(last_page);
          draft.riskFactors = last_page === 1 ? riskFactors : [...state.riskFactors, ...riskFactors];
          draft.total = total;
          draft.has_next = has_next;
        }

        break;
      }

      case GET_ALL_RISK_FACTORS_FAILURE: {
        draft.riskFactors = [];

        break;
      }

      case GET_FILTERED_RISK_FACTOR_REQUEST: {
        break;
      }

      case GET_FILTERED_RISK_FACTOR_SUCCESS: {
        const { riskFactors } = action.payload;

        draft.riskFactors = riskFactors;

        break;
      }

      case GET_FILTERED_RISK_FACTOR_FAILURE: {
        break;
      }

      case GET_RISK_FACTOR_DETAIL_REQUEST: {
        break;
      }

      case GET_RISK_FACTOR_DETAIL_SUCCESS: {
        const { riskFactor } = action.payload;

        draft.currentRiskFactorDetail = riskFactor;

        break;
      }

      case GET_RISK_FACTOR_DETAIL_FAILURE: {
        break;
      }

      case ADD_NEW_RISK_FACTOR_REQUEST: {
        break;
      }

      case ADD_NEW_RISK_FACTOR_SUCCESS: {
        const { riskFactorAdded } = action.payload;

        draft.currentRiskFactorDetail = riskFactorAdded;

        break;
      }

      case ADD_NEW_RISK_FACTOR_FAILURE: {
        break;
      }

      case EDIT_RISK_FACTOR_REQUEST: {
        break;
      }

      case EDIT_RISK_FACTOR_SUCCESS: {
        break;
      }

      case EDIT_RISK_FACTOR_FAILURE: {
        break;
      }

      case ACTIVE_OR_INACTIVE_RISK_FACTOR_REQUEST: {
        break;
      }

      case ACTIVE_OR_INACTIVE_RISK_FACTOR_SUCCESS: {
        break;
      }

      case ACTIVE_OR_INACTIVE_RISK_FACTOR_FAILURE: {
        break;
      }

      case RESET_THE_RISK_FACTOR_DETAIL_STATE: {
        draft.currentRiskFactorDetail.id = '';
        draft.currentRiskFactorDetail.friendly_id = '';
        draft.currentRiskFactorDetail.name = '';
        draft.currentRiskFactorDetail.is_active = false;
        draft.currentRiskFactorDetail.registration_status = '';
        draft.currentRiskFactorDetail.threats = [];
        draft.currentRiskFactorDetail.controls = [];

        break;
      }

      case GET_ALL_RISK_FACTORS_STATIC_REQUEST: {
        break;
      }

      case GET_ALL_RISK_FACTORS_STATIC_SUCCESS: {
        const { riskFactorStatic, total, last_page, has_next } = action.payload;
        const pagineAlreadyVisited = state.visited_pages.find (page => page === last_page);

        if(!pagineAlreadyVisited || draft.riskFactorStatic) {
          draft.visited_pages.push(last_page);
          draft.riskFactorStatic = last_page === 1 ? riskFactorStatic : [...state.riskFactorStatic, ...riskFactorStatic];
          draft.total = total;
          draft.has_next = has_next;
        }
        
        break;
      }

      case GET_ALL_RISK_FACTORS_STATIC_FAILURE: {
        draft.riskFactorStatic = [];
        break;
      }

      case GET_FILTERED_RISK_STATIC_FACTOR_REQUEST: {
        break;
      }

      case GET_FILTERED_RISK_FACTOR_STATIC_SUCCESS: {
        const { riskFactorStatic } = action.payload;
        draft.riskFactorStatic = riskFactorStatic;
        break;
      }

      case GET_FILTERED_RISK_FACTOR_STATIC_FAILURE: {
        break;
      }

      default: {
        return draft;
      }
    }
  });
};
