import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import type { Action } from 'store/types';

import { sectorsActionsFunctions } from './actions';
import type {
  Sector,
  Subsector,
  Segment,
  Department,
  AddNewSectorRequestPayload,
  AddNewSubsectorRequestPayload,
  AddNewSegmentRequestPayload,
  AddNewDepartmentRequestPayload,
  SearchFilteredSectorRequestPayloadParams,
  UpdateStatusRequestPayloadParams,
  EditDepartmentRequestPayload,
  SectorDetailsRequest,
  SectorDetails,
  SearchFilteredDepartmentRequestPayloadParams,
  EditSubsectorRequestPayload,
  EditSegmentRequestPayload,
  EditSectorRequestPayload,
} from './types';
import { SectorsActions } from './types';

const {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE,
  NEW_SECTOR_ADDED_SUCCESS_MESSAGE,
  NEW_SECTOR_ADDED_ERROR_MESSAGE,
  GET_SECTOR_DETAILS_SUCCESS_MESSAGE,
  GET_SECTOR_DETAILS_ERROR_MESSAGE,
  SEARCH_FILTERED_SECTOR_SUCCESS_MESSAGE,
  SEARCH_FILTERED_SECTOR_ERROR_MESSAGE,
  SEARCH_FILTERED_DEPARTMENT_SUCCESS_MESSAGE,
  SEARCH_FILTERED_DEPARTMENT_ERROR_MESSAGE,
  EDIT_SECTOR_SUCCESS_MESSAGE,
  EDIT_SECTOR_ERROR_MESSAGE,
  ACTIVE_OR_INACTIVE_SECTOR_SUCCESS_MESSAGE,
  ACTIVE_OR_INACTIVE_SECTOR_ERROR_MESSAGE,
  NEW_SUB_SECTOR_ADDED_SUCCESS_MESSAGE,
  NEW_SUB_SECTOR_ADDED_ERROR_MESSAGE,
  EDIT_SUB_SECTOR_SUCCESS_MESSAGE,
  EDIT_SUB_SECTOR_ERROR_MESSAGE,
  ACTIVE_OR_INACTIVE_SUB_SECTOR_SUCCESS_MESSAGE,
  ACTIVE_OR_INACTIVE_SUB_SECTOR_ERROR_MESSAGE,
  NEW_SEGMENT_ADDED_SUCCESS_MESSAGE,
  NEW_SEGMENT_ADDED_ERROR_MESSAGE,
  EDIT_SEGMENT_SUCCESS_MESSAGE,
  EDIT_SEGMENT_ERROR_MESSAGE,
  ACTIVE_OR_INACTIVE_SEGMENT_SUCCESS_MESSAGE,
  ACTIVE_OR_INACTIVE_SEGMENT_ERROR_MESSAGE,
  NEW_DEPARTMENT_ADDED_SUCCESS_MESSAGE,
  NEW_DEPARTMENT_ADDED_ERROR_MESSAGE,
  EDIT_DEPARTMENT_SUCCESS_MESSAGE,
  EDIT_DEPARTMENT_ERROR_MESSAGE,
  ACTIVE_OR_INACTIVE_DEPARTMENT_SUCCESS_MESSAGE,
  ACTIVE_OR_INACTIVE_DEPARTMENT_ERROR_MESSAGE,
} = ToastMessages;

const {
  GET_ALL_SECTORS_REQUEST,
  GET_SECTOR_DETAILS_REQUEST,
  GET_ALL_SUB_SECTORS_REQUEST,
  GET_ALL_SEGMENTS_REQUEST,
  GET_ALL_DEPARTMENTS_REQUEST,
  GET_FILTERED_SECTOR_REQUEST,
  GET_FILTERED_DEPARTMENT_REQUEST,
  ADD_NEW_SECTOR_REQUEST,
  EDIT_SECTOR_REQUEST,
  ADD_NEW_SUB_SECTOR_REQUEST,
  EDIT_SUB_SECTOR_REQUEST,
  ADD_NEW_SEGMENT_REQUEST,
  EDIT_SEGMENT_REQUEST,
  ADD_NEW_DEPARTMENT_REQUEST,
  EDIT_DEPARTMENT_REQUEST,
  ACTIVE_OR_INACTIVE_SECTOR_REQUEST,
  ACTIVE_OR_INACTIVE_SUB_SECTOR_REQUEST,
  ACTIVE_OR_INACTIVE_SEGMENT_REQUEST,
  ACTIVE_OR_INACTIVE_DEPARTMENT_REQUEST,
} = SectorsActions;

const {
  getSectorsRequest,
  getSectorsSuccess,
  getSectorsFailure,
  getSectorDetailsSuccess,
  getSectorDetailsFailure,
  getSubSectorsRequest,
  getSubSectorsSuccess,
  getSubSectorsFailure,
  getSegmentsRequest,
  getSegmentsSuccess,
  getSegmentsFailure,
  getDepartmentsRequest,
  getDepartmentsSuccess,
  getDepartmentsFailure,
  getSearchFilteredSectorSuccess,
  getSearchFilteredSectorFailure,
  getSearchFilteredDepartmentSuccess,
  getSearchFilteredDepartmentFailure,
  addNewSectorSuccess,
  addNewSectorFailure,
  editSectorSuccess,
  editSectorFailure,
  addNewSubsectorSuccess,
  addNewSubsectorFailure,
  editSubsectorSuccess,
  editSubsectorFailure,
  addNewSegmentSuccess,
  addNewSegmentFailure,
  editSegmentSuccess,
  editSegmentFailure,
  addNewDepartmentSuccess,
  addNewDepartmentFailure,
  editDepartmentSuccess,
  editDepartmentFailure,
  activeOrInactiveSectorSuccess,
  activeOrInactiveSectorFailure,
  activeOrInactiveSubsectorSuccess,
  activeOrInactiveSubsectorFailure,
  activeOrInactiveSegmentSuccess,
  activeOrInactiveSegmentFailure,
  activeOrInactiveDepartmentSuccess,
  activeOrInactiveDepartmentFailure,
  resetTheLastSectorDetails,
} = sectorsActionsFunctions;

const { getLastApiFetchDataSuccess, getLastApiFetchDataFailure } =
  lastApiFetchDataActionsFunctions;

function* fetchAllSectors(): Generator {
  try {
    const sectorsResponse: AxiosResponse<Sector[]> | unknown = yield call(
      api,
      'GET',
      'customers/sectors/',
      {}
    );

    const {
      data: sectors,
      config: { url },
      status,
      statusText,
    } = sectorsResponse as AxiosResponse<Sector[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSectorsSuccess(sectors));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSectorsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchSectorDetails(action: Action): Generator {
  const { sectorId } = action.payload as SectorDetailsRequest;

  try {
    const sectorDetailsResponse: AxiosResponse<SectorDetails> | unknown =
      yield call(api, 'GET', `customers/sectors/${sectorId}/detail/`, {});

    const {
      data: sectorDetails,
      config: { url },
      status,
      statusText,
    } = sectorDetailsResponse as AxiosResponse<SectorDetails>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSectorDetailsSuccess(sectorDetails));

    toast.success(GET_SECTOR_DETAILS_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSectorDetailsFailure());

    toast.error(GET_SECTOR_DETAILS_ERROR_MESSAGE);
  }
}

function* fetchAllSubsectors(): Generator {
  try {
    const subSectorsResponse: AxiosResponse<Subsector[]> | unknown = yield call(
      api,
      'GET',
      'customers/subsectors/',
      {}
    );

    const {
      data: subSectors,
      config: { url },
      status,
      statusText,
    } = subSectorsResponse as AxiosResponse<Subsector[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSubSectorsSuccess(subSectors));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSubSectorsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchAllSegments(): Generator {
  try {
    const segmentsResponse: AxiosResponse<Segment[]> | unknown = yield call(
      api,
      'GET',
      'customers/segments/',
      {}
    );

    const {
      data: segments,
      config: { url },
      status,
      statusText,
    } = segmentsResponse as AxiosResponse<Segment[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSegmentsSuccess(segments));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSegmentsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchAllDepartments(): Generator {
  try {
    const departmentsResponse: AxiosResponse<Department[]> | unknown =
      yield call(api, 'GET', 'customers/departments/', {});

    const {
      data: departments,
      config: { url },
      status,
      statusText,
    } = departmentsResponse as AxiosResponse<Department[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getDepartmentsSuccess(departments));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getDepartmentsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchSearchFilteredSector(action: Action): Generator {
  const { filteredSector } =
    action.payload as SearchFilteredSectorRequestPayloadParams;

  const parsedResponses = filteredSector
    .filter(response => response !== null)
    .join(',');

  try {
    const filteredSectorsResponse: AxiosResponse<Sector[]> | unknown =
      yield call(
        api,
        'GET',
        `customers/sectors/?search=${parsedResponses}`,
        {}
      );

    const {
      data: filteredSectors,
      config: { url },
      status,
      statusText,
    } = filteredSectorsResponse as AxiosResponse<Sector[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredSectorSuccess(filteredSectors));

    toast.success(SEARCH_FILTERED_SECTOR_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredSectorFailure());

    toast.error(
      currentError?.response?.data?.detail ??
        SEARCH_FILTERED_SECTOR_ERROR_MESSAGE
    );
  }
}

function* fetchSearchFilteredDepartment(action: Action): Generator {
  const { filteredDepartment } =
    action.payload as SearchFilteredDepartmentRequestPayloadParams;

  const parsedResponses = filteredDepartment
    .filter(response => response !== null)
    .join(',');

  try {
    const filteredDepartmentsResponse: AxiosResponse<Department[]> | unknown =
      yield call(
        api,
        'GET',
        `customers/departments/?search=${parsedResponses}`,
        {}
      );

    const {
      data: filteredDepartments,
      config: { url },
      status,
      statusText,
    } = filteredDepartmentsResponse as AxiosResponse<Department[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredDepartmentSuccess(filteredDepartments));

    toast.success(SEARCH_FILTERED_DEPARTMENT_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredDepartmentFailure());

    toast.error(
      currentError?.response?.data?.detail ??
        SEARCH_FILTERED_DEPARTMENT_ERROR_MESSAGE
    );
  }
}

function* fetchAddNewSector(action: Action): Generator {
  const {
    sector: { name, is_active },
  } = action.payload as AddNewSectorRequestPayload;

  try {
    const addNewSectorResponse: AxiosResponse<Sector> | unknown = yield call(
      api,
      'POST',
      'customers/sectors/create/',
      {
        name,
        is_active,
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = addNewSectorResponse as AxiosResponse<Sector>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(addNewSectorSuccess());
    yield put(resetTheLastSectorDetails());
    yield put(getSectorsRequest());

    toast.success(NEW_SECTOR_ADDED_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(addNewSectorFailure());

    toast.error(
      currentError?.response?.data?.name[0] ??
        currentError?.response?.data?.detail ??
        NEW_SECTOR_ADDED_ERROR_MESSAGE
    );
  }
}

function* fetchToSectorEditing(action: Action): Generator {
  const {
    sector: { id, name, is_active },
  } = action.payload as EditSectorRequestPayload;

  try {
    const sectorEditingResponse: AxiosResponse<Sector> | unknown = yield call(
      api,
      'PUT',
      `customers/sectors/${id}/`,
      {
        name,
        is_active,
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = sectorEditingResponse as AxiosResponse<Sector>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editSectorSuccess());

    yield put(getSectorsRequest());

    toast.success(EDIT_SECTOR_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editSectorFailure());

    toast.error(
      currentError?.response?.data?.detail ?? EDIT_SECTOR_ERROR_MESSAGE
    );
  }
}

function* fetchToUpdateSectorStatus(action: Action): Generator {
  const { id, updatedStatus } =
    action.payload as UpdateStatusRequestPayloadParams;

  try {
    const updateSectorStatusResponse: AxiosResponse<Sector> | unknown =
      yield call(api, 'PUT', `customers/sectors/${id}/update-status/`, {
        is_active: updatedStatus,
      });

    const {
      config: { url },
      status,
      statusText,
    } = updateSectorStatusResponse as AxiosResponse<Sector>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(activeOrInactiveSectorSuccess());

    yield put(getSectorsRequest());

    toast.success(ACTIVE_OR_INACTIVE_SECTOR_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(activeOrInactiveSectorFailure());

    toast.error(
      currentError?.response?.data?.detail ??
        ACTIVE_OR_INACTIVE_SECTOR_ERROR_MESSAGE
    );
  }
}

function* fetchAddNewSubsector(action: Action): Generator {
  const {
    subsector: { sector, name, is_active },
  } = action.payload as AddNewSubsectorRequestPayload;

  try {
    const addNewSubsectorResponse: AxiosResponse<Subsector> | unknown =
      yield call(api, 'POST', 'customers/subsectors/create/', {
        sector,
        name,
        is_active,
      });

    const {
      config: { url },
      status,
      statusText,
    } = addNewSubsectorResponse as AxiosResponse<Subsector>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(addNewSubsectorSuccess());
    yield put(resetTheLastSectorDetails());
    yield put(getSubSectorsRequest());

    toast.success(NEW_SUB_SECTOR_ADDED_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(addNewSubsectorFailure());

    toast.error(
      currentError?.response?.data?.name[0] ??
        currentError?.response?.data?.detail ??
        NEW_SUB_SECTOR_ADDED_ERROR_MESSAGE
    );
  }
}

function* fetchToSubsectorEditing(action: Action): Generator {
  const {
    subsector: { id, sector, name, is_active },
  } = action.payload as EditSubsectorRequestPayload;

  try {
    const subSectorEditingResponse: AxiosResponse<Subsector> | unknown =
      yield call(api, 'PUT', `customers/subsectors/${id}/`, {
        sector,
        name,
        is_active,
      });

    const {
      config: { url },
      status,
      statusText,
    } = subSectorEditingResponse as AxiosResponse<Subsector>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editSubsectorSuccess());
    yield put(resetTheLastSectorDetails());
    yield put(getSubSectorsRequest());

    toast.success(EDIT_SUB_SECTOR_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editSubsectorFailure());

    toast.error(
      currentError?.response?.data?.detail ?? EDIT_SUB_SECTOR_ERROR_MESSAGE
    );
  }
}

function* fetchToUpdateSubsectorStatus(action: Action): Generator {
  const { id, updatedStatus } =
    action.payload as UpdateStatusRequestPayloadParams;

  try {
    const updateSubsectorStatusResponse: AxiosResponse<Subsector> | unknown =
      yield call(api, 'PUT', `customers/subsectors/${id}/update-status/`, {
        is_active: updatedStatus,
      });

    const {
      config: { url },
      status,
      statusText,
    } = updateSubsectorStatusResponse as AxiosResponse<Subsector>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(activeOrInactiveSubsectorSuccess());
    yield put(resetTheLastSectorDetails());
    yield put(getSubSectorsRequest());

    toast.success(ACTIVE_OR_INACTIVE_SUB_SECTOR_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(activeOrInactiveSubsectorFailure());

    toast.error(
      currentError?.response?.data?.detail ??
        ACTIVE_OR_INACTIVE_SUB_SECTOR_ERROR_MESSAGE
    );
  }
}

function* fetchAddNewSegment(action: Action): Generator {
  const {
    segment: { subsector, name, is_active },
  } = action.payload as AddNewSegmentRequestPayload;

  try {
    const addNewSegmentResponse: AxiosResponse<Segment> | unknown = yield call(
      api,
      'POST',
      'customers/segments/create/',
      {
        subsector,
        name,
        is_active,
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = addNewSegmentResponse as AxiosResponse<Segment>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(addNewSegmentSuccess());
    yield put(resetTheLastSectorDetails());
    yield put(getSegmentsRequest());

    toast.success(NEW_SEGMENT_ADDED_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(addNewSegmentFailure());

    toast.error(
      currentError?.response?.data?.name[0] ??
        currentError?.response?.data?.detail ??
        NEW_SEGMENT_ADDED_ERROR_MESSAGE
    );
  }
}

function* fetchToSegmentEditing(action: Action): Generator {
  const {
    segment: { id, subsector, name, is_active },
  } = action.payload as EditSegmentRequestPayload;

  try {
    const segmentEditingResponse: AxiosResponse<Segment> | unknown = yield call(
      api,
      'PUT',
      `customers/segments/${id}/`,
      {
        subsector,
        name,
        is_active,
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = segmentEditingResponse as AxiosResponse<Segment>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editSegmentSuccess());

    yield put(getSegmentsRequest());

    toast.success(EDIT_SEGMENT_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editSegmentFailure());

    toast.error(
      currentError?.response?.data?.detail ?? EDIT_SEGMENT_ERROR_MESSAGE
    );
  }
}

function* fetchToUpdateSegmentStatus(action: Action): Generator {
  const { id, updatedStatus } =
    action.payload as UpdateStatusRequestPayloadParams;

  try {
    const updateSegmentStatusResponse: AxiosResponse<Segment> | unknown =
      yield call(api, 'PUT', `customers/segments/${id}/update-status/`, {
        is_active: updatedStatus,
      });

    const {
      config: { url },
      status,
      statusText,
    } = updateSegmentStatusResponse as AxiosResponse<Segment>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(activeOrInactiveSegmentSuccess());

    yield put(getSegmentsRequest());

    toast.success(ACTIVE_OR_INACTIVE_SEGMENT_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(activeOrInactiveSegmentFailure());

    toast.error(
      currentError?.response?.data?.detail ??
        ACTIVE_OR_INACTIVE_SEGMENT_ERROR_MESSAGE
    );
  }
}

function* fetchAddNewDepartment(action: Action): Generator {
  const { department } = action.payload as AddNewDepartmentRequestPayload;

  const newDepartmentPayload = department.segments
    ? {
        name: department.name,
        segments: department.segments,
        is_active: department.is_active,
      }
    : { 
        name: department.name, 
        segments: [],
        is_active: department.is_active 
      };

  try {
    const addNewDepartmentResponse: AxiosResponse<Department> | unknown =
      yield call(
        api,
        'POST',
        'customers/departments/create/',
        newDepartmentPayload
      );

    const {
      config: { url },
      status,
      statusText,
    } = addNewDepartmentResponse as AxiosResponse<Department>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(addNewDepartmentSuccess());
    yield put(resetTheLastSectorDetails());
    yield put(getDepartmentsRequest());
    yield put(getSectorsRequest());

    toast.success(NEW_DEPARTMENT_ADDED_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(addNewDepartmentFailure());

    toast.error(
      currentError?.response?.data?.name[0] ??
        currentError?.response?.data?.detail ??
        NEW_DEPARTMENT_ADDED_ERROR_MESSAGE
    );
  }
}

function* fetchToDepartmentEditing(action: Action): Generator {
  const {
    department: { id, name, is_active, segments_add, segments_remove },
  } = action.payload as EditDepartmentRequestPayload;

  try {
    const departmentEditingResponse: AxiosResponse<unknown> | unknown =
      yield call(api, 'PUT', `customers/departments/${id}/`, {
        name,
        is_active,
        segments_add,
        segments_remove,
      });

    const {
      config: { url },
      status,
      statusText,
    } = departmentEditingResponse as AxiosResponse<unknown>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editDepartmentSuccess());

    yield put(resetTheLastSectorDetails());
    yield put(getDepartmentsRequest());
    yield put(getSectorsRequest());

    toast.success(EDIT_DEPARTMENT_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editDepartmentFailure());

    toast.error(
      currentError?.response?.data?.detail ?? EDIT_DEPARTMENT_ERROR_MESSAGE
    );
  }
}

function* fetchToUpdateDepartmentStatus(action: Action): Generator {
  const { id, updatedStatus } =
    action.payload as UpdateStatusRequestPayloadParams;

  try {
    const updateDepartmentStatusResponse: AxiosResponse<unknown> | unknown =
      yield call(api, 'PUT', `customers/departments/${id}/update-status/`, {
        is_active: updatedStatus,
      });

    const {
      config: { url },
      status,
      statusText,
    } = updateDepartmentStatusResponse as AxiosResponse<unknown>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(activeOrInactiveDepartmentSuccess());
    yield put(resetTheLastSectorDetails());
    yield put(getDepartmentsRequest());
    yield put(getSectorsRequest());

    toast.success(ACTIVE_OR_INACTIVE_DEPARTMENT_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(activeOrInactiveDepartmentFailure());

    toast.error(
      currentError?.response?.data?.detail ??
        ACTIVE_OR_INACTIVE_DEPARTMENT_ERROR_MESSAGE
    );
  }
}

export function* sectorsSagas() {
  yield all([
    takeLatest(GET_ALL_SECTORS_REQUEST, fetchAllSectors),
    takeLatest(GET_SECTOR_DETAILS_REQUEST, fetchSectorDetails),
    takeLatest(GET_ALL_SUB_SECTORS_REQUEST, fetchAllSubsectors),
    takeLatest(GET_ALL_SEGMENTS_REQUEST, fetchAllSegments),
    takeLatest(GET_ALL_DEPARTMENTS_REQUEST, fetchAllDepartments),
    takeLatest(GET_FILTERED_SECTOR_REQUEST, fetchSearchFilteredSector),
    takeLatest(GET_FILTERED_DEPARTMENT_REQUEST, fetchSearchFilteredDepartment),
    takeLatest(ADD_NEW_SECTOR_REQUEST, fetchAddNewSector),
    takeLatest(EDIT_SECTOR_REQUEST, fetchToSectorEditing),
    takeLatest(ACTIVE_OR_INACTIVE_SECTOR_REQUEST, fetchToUpdateSectorStatus),
    takeLatest(ADD_NEW_SUB_SECTOR_REQUEST, fetchAddNewSubsector),
    takeLatest(EDIT_SUB_SECTOR_REQUEST, fetchToSubsectorEditing),
    takeLatest(
      ACTIVE_OR_INACTIVE_SUB_SECTOR_REQUEST,
      fetchToUpdateSubsectorStatus
    ),
    takeLatest(ADD_NEW_SEGMENT_REQUEST, fetchAddNewSegment),
    takeLatest(EDIT_SEGMENT_REQUEST, fetchToSegmentEditing),
    takeLatest(ACTIVE_OR_INACTIVE_SEGMENT_REQUEST, fetchToUpdateSegmentStatus),
    takeLatest(ADD_NEW_DEPARTMENT_REQUEST, fetchAddNewDepartment),
    takeLatest(EDIT_DEPARTMENT_REQUEST, fetchToDepartmentEditing),
    takeLatest(
      ACTIVE_OR_INACTIVE_DEPARTMENT_REQUEST,
      fetchToUpdateDepartmentStatus
    ),
  ]);
}
