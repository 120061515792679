import produce from 'immer';
import {
  Reducer
} from 'redux';

import {
  FrameworkState,
  FrameworksActions
} from './types';

const {
  EDIT_FRAMEWORKS_REQUEST,
  EDIT_FRAMEWORKS_SUCCESS,
  EDIT_FRAMEWORKS_FAILURE,
  GET_ALL_FRAMEWORKS_REQUEST,
  GET_ALL_FRAMEWORKS_SUCCESS,
  GET_ALL_FRAMEWORKS_FAILURE,
  ADD_NEW_FRAMEWORKS_REQUEST,
  ADD_NEW_FRAMEWORKS_SUCCESS,
  ADD_NEW_FRAMEWORKS_FAILURE,
  APPROVED_FRAMEWORKS_REQUEST,
  APPROVED_FRAMEWORKS_SUCCESS,
  APPROVED_FRAMEWORKS_FAILURE,
  ADD_STANDARDS_ITEMS_REQUEST,
  ADD_STANDARDS_ITEMS_SUCCESS,
  ADD_STANDARDS_ITEMS_FAILURE,
  GET_STANDARDS_ITEMS_REQUEST,
  GET_STANDARDS_ITEMS_SUCCESS,
  GET_STANDARDS_ITEMS_FAILURE,
  GET_FRAMEWORKS_DETAIL_REQUEST,
  GET_FRAMEWORKS_DETAIL_SUCCESS,
  GET_FRAMEWORKS_DETAIL_FAILURE,
  CREATE_CSFCOREREF_ITEM_REQUEST,
  CREATE_CSFCOREREF_ITEM_SUCCESS,
  CREATE_CSFCOREREF_ITEM_FAILURE,
  ADD_FRAMEWORKS_CONTROLS_REQUEST,
  ADD_FRAMEWORKS_CONTROLS_SUCCESS,
  ADD_FRAMEWORKS_CONTROLS_FAILURE,
  GET_FILTERED_FRAMEWORKS_REQUEST,
  GET_FILTERED_FRAMEWORKS_SUCCESS,
  GET_FILTERED_FRAMEWORKS_FAILURE,
  EDIT_CSFCOREREF_FRAMEWORKS_REQUEST,
  EDIT_CSFCOREREF_FRAMEWORKS_SUCCESS,
  EDIT_CSFCOREREF_FRAMEWORKS_FAILURE,
  EDIT_ALTERNATIVES_FRAMEWORKS_REQUEST,
  EDIT_ALTERNATIVES_FRAMEWORKS_SUCCESS,
  EDIT_ALTERNATIVES_FRAMEWORKS_FAILURE,
  DELETE_CSFCOREREF_FRAMEWORKS_REQUEST,
  DELETE_CSFCOREREF_FRAMEWORKS_SUCCESS,
  DELETE_CSFCOREREF_FRAMEWORKS_FAILURE,
  CREATE_CSFCOREREF_ALTERNATIVE_REQUEST,
  CREATE_CSFCOREREF_ALTERNATIVE_SUCCESS,
  CREATE_CSFCOREREF_ALTERNATIVE_FAILURE,
  ACTIVE_OR_INACTIVE_FRAMEWORKS_REQUEST,
  ACTIVE_OR_INACTIVE_FRAMEWORKS_SUCCESS,
  ACTIVE_OR_INACTIVE_FRAMEWORKS_FAILURE,
  GET_FRAMEWORKS_CONTROLS_DETAIL_REQUEST,
  GET_FRAMEWORKS_CONTROLS_DETAIL_SUCCESS,
  GET_FRAMEWORKS_CONTROLS_DETAIL_FAILURE,
  DELETE_CSFCOREREF_ALTERNATIVES_REQUEST,
  DELETE_CSFCOREREF_ALTERNATIVES_SUCCESS,
  DELETE_CSFCOREREF_ALTERNATIVES_FAILURE,
  GET_FRAMEWORKS_STANDARDS_DETAIL_REQUEST,
  GET_FRAMEWORKS_STANDARDS_DETAIL_SUCCESS,
  GET_FRAMEWORKS_STANDARDS_DETAIL_FAILURE,
  GET_FRAMEWORKS_ITEMS_DETAIL_REQUEST,
  GET_FRAMEWORKS_ITEMS_DETAIL_SUCCESS,
  GET_FRAMEWORKS_ITEMS_DETAIL_FAILURE,
  ADD_FRAMEWORKS_ITEMS_SUCCESS
} = FrameworksActions;

const initialState: FrameworkState = {
  allStandards: {
    count: null,
    next: null,
    previous: null,
    results: [],
  },
  allFrameworks: {
    count: null,
    next: null,
    previous: null,
    results: [],
  },
  frameworkDetails: {
    id: '',
    name: '',
    version: '',
    publication_date: '',
    successor: {
      id: '',
      name: '',
      version: '',
      is_active: false,
    },
    is_active: false,
    csf_core: {}
  },
  frameworkControlsDetails: {
    id: '',
    friendly_id: '',
    csf_function: '',
    category_name: '',
    subcategory: '',
    subcategory_ptbr: '',
    controls: [],
  },
  frameworkStandardsDetails: {
    id: '',
    friendly_id: '',
    csf_function: '',
    category_name: '',
    subcategory: '',
    subcategory_ptbr: '',
    standards: [],
  },
  frameworkItemsDetails: {
    frameworks: []
  },
  csf_core_ref_items: {
    id: "",
  friendly_id: "",
  csf_function: "",
  category_name: "",
  subcategory: "",
  has_link: false,
  }
};

export const frameworksReducer: Reducer<FrameworkState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_FRAMEWORKS_REQUEST:
        break;

      case GET_ALL_FRAMEWORKS_FAILURE: {
        draft.allFrameworks = {
          count: null,
          next: null,
          previous: null,
          results: [],
        };
        break;
      }

      case ACTIVE_OR_INACTIVE_FRAMEWORKS_REQUEST:
        break;

      case ACTIVE_OR_INACTIVE_FRAMEWORKS_SUCCESS:
        break;

      case ACTIVE_OR_INACTIVE_FRAMEWORKS_FAILURE:
        break;

      case ADD_NEW_FRAMEWORKS_REQUEST:
        break;

      case ADD_NEW_FRAMEWORKS_SUCCESS:
        break;

      case ADD_NEW_FRAMEWORKS_FAILURE:
        break;

      case EDIT_FRAMEWORKS_REQUEST:
        break;

      case EDIT_FRAMEWORKS_SUCCESS:
        break;

      case EDIT_FRAMEWORKS_FAILURE:
        break;

      case GET_FILTERED_FRAMEWORKS_REQUEST:
        break;

      case GET_FILTERED_FRAMEWORKS_FAILURE:
        break;

      case GET_ALL_FRAMEWORKS_SUCCESS:
      case GET_FILTERED_FRAMEWORKS_SUCCESS: {
        const { frameworks } = action.payload;
        draft.allFrameworks = frameworks;
        break;
      }

      case GET_FRAMEWORKS_DETAIL_REQUEST:
        break;

      case GET_FRAMEWORKS_DETAIL_SUCCESS: {
        const { frameworkDetails } = action.payload;
        draft.frameworkDetails = frameworkDetails;
        break;
      }

      case GET_FRAMEWORKS_DETAIL_FAILURE:
        break;

      case EDIT_ALTERNATIVES_FRAMEWORKS_REQUEST:
        break;

      case EDIT_ALTERNATIVES_FRAMEWORKS_SUCCESS:
        break;

      case EDIT_ALTERNATIVES_FRAMEWORKS_FAILURE:
        break;

      case EDIT_CSFCOREREF_FRAMEWORKS_REQUEST:
        break;

      case EDIT_CSFCOREREF_FRAMEWORKS_SUCCESS:
        break;

      case EDIT_CSFCOREREF_FRAMEWORKS_FAILURE:
        break;

      case APPROVED_FRAMEWORKS_REQUEST:
        break;

      case APPROVED_FRAMEWORKS_SUCCESS:
        break;

      case APPROVED_FRAMEWORKS_FAILURE:
        break;

      case GET_FRAMEWORKS_STANDARDS_DETAIL_REQUEST:
        break;

      case GET_FRAMEWORKS_STANDARDS_DETAIL_SUCCESS: {
        const { frameworkStandardsDetails } = action.payload;
        draft.frameworkStandardsDetails = frameworkStandardsDetails;
        break;
      }

      case GET_FRAMEWORKS_STANDARDS_DETAIL_FAILURE: {
        draft.frameworkStandardsDetails = {
          id: '',
          friendly_id: '',
          csf_function: '',
          category_name: '',
          subcategory: '',
          subcategory_ptbr: '',
          standards: [],
        };
        break;
      }

      case GET_STANDARDS_ITEMS_REQUEST:
        break;

      case GET_STANDARDS_ITEMS_SUCCESS: {
        const { allStandards } = action.payload;
        draft.allStandards = allStandards;
        break;
      }

      case GET_STANDARDS_ITEMS_FAILURE: {
        draft.allStandards = {
          count: null,
          next: null,
          previous: null,
          results: [],
        };
        break;
      }

      case ADD_STANDARDS_ITEMS_REQUEST:
        break;

      case ADD_STANDARDS_ITEMS_SUCCESS:
        break;

      case ADD_STANDARDS_ITEMS_FAILURE:
        break;

      case GET_FRAMEWORKS_CONTROLS_DETAIL_REQUEST:
        break;

      case GET_FRAMEWORKS_CONTROLS_DETAIL_SUCCESS: {
        const { frameworkControlsDetails } = action.payload;
        draft.frameworkControlsDetails = frameworkControlsDetails;
        break;
      }

      case GET_FRAMEWORKS_CONTROLS_DETAIL_FAILURE: {
        draft.frameworkControlsDetails = {
          id: '',
          friendly_id: '',
          csf_function: '',
          category_name: '',
          subcategory: '',
          subcategory_ptbr: '',
          controls: [],
        };
        break;
      }

      case ADD_FRAMEWORKS_CONTROLS_REQUEST:
        break;

      case ADD_FRAMEWORKS_CONTROLS_SUCCESS:
        break;

      case ADD_FRAMEWORKS_CONTROLS_FAILURE:
        break;

      case DELETE_CSFCOREREF_FRAMEWORKS_REQUEST:
        break;

      case DELETE_CSFCOREREF_FRAMEWORKS_SUCCESS:
        break;

      case DELETE_CSFCOREREF_FRAMEWORKS_FAILURE:
        break;

      case CREATE_CSFCOREREF_ITEM_REQUEST:
        break;

      case CREATE_CSFCOREREF_ITEM_SUCCESS:
        break;

      case CREATE_CSFCOREREF_ITEM_FAILURE:
        break;

      case DELETE_CSFCOREREF_ALTERNATIVES_REQUEST:
        break;

      case DELETE_CSFCOREREF_ALTERNATIVES_SUCCESS:
        break;

      case DELETE_CSFCOREREF_ALTERNATIVES_FAILURE:
        break;

      case CREATE_CSFCOREREF_ALTERNATIVE_REQUEST:
        break;

      case CREATE_CSFCOREREF_ALTERNATIVE_SUCCESS:
        break;

      case CREATE_CSFCOREREF_ALTERNATIVE_FAILURE:
        break;

      case GET_FRAMEWORKS_ITEMS_DETAIL_REQUEST:
          break;
  
      case GET_FRAMEWORKS_ITEMS_DETAIL_SUCCESS: {
          const { frameworkItemsDetails } = action.payload;
          
          draft.frameworkItemsDetails = frameworkItemsDetails;
          break;
        }
  
       case ADD_FRAMEWORKS_ITEMS_SUCCESS:
        break;
        
      default: {
        return draft;
      }
    }
  });
};
