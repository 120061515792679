import { CircularProgress } from '@mui/material';

import styles from './styles.module.scss';

export function Loading() {
  return (
    <div className={styles['loading-container']}>
      <CircularProgress color='secondary' />
    </div>
  );
}
