import type { Action } from 'store/types';

import { OpenedModal, OpenedModalActions } from './types';

const { SET_OPENED_MODAL, RESET_THE_OPENED_MODAL } = OpenedModalActions;

function setOpenedModal(value: OpenedModal): Action {
  return {
    type: SET_OPENED_MODAL,
    payload: {
      value,
    },
  };
}

function resetTheOpenedModal(): Action {
  return {
    type: RESET_THE_OPENED_MODAL,
  };
}

export const openedModalActionsFunctions = {
  setOpenedModal,
  resetTheOpenedModal,
};
