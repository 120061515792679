import { IdAndName } from '../Manutention/vulnerabilities/types';

export enum ThreatsActions {
  GET_ALL_THREATS_MODULE_REQUEST = 'GET_ALL_THREATS_MODULE_REQUEST',
  GET_ALL_THREATS_MODULE_SUCCESS = 'GET_ALL_THREATS_MODULE_SUCCESS',
  GET_ALL_THREATS_MODULE_FAILURE = 'GET_ALL_THREATS_MODULE_FAILURE',
  GET_THREATS_DETAILS_REQUEST = 'GET_THREATS_DETAILS_REQUEST',
  GET_THREATS_DETAILS_SUCCESS = 'GET_THREATS_DETAILS_SUCCESS',
  GET_THREATS_DETAILS_FAILURE = 'GET_THREATS_DETAILS_FAILURE',
  CREATE_NEW_THREAT_REQUEST = 'CREATE_NEW_THREAT_REQUEST',
  CREATE_NEW_THREAT_SUCCESS = 'CREATE_NEW_THREAT_SUCCESS',
  CREATE_NEW_THREAT_FAILURE = 'CREATE_NEW_THREAT_FAILURE',
  EDIT_THREAT_REQUEST = 'EDIT_THREAT_REQUEST',
  EDIT_THREAT_SUCCESS = 'EDIT_THREAT_SUCCESS',
  EDIT_THREAT_FAILURE = 'EDIT_THREAT_FAILURE',
  ACTIVE_OR_INACTIVE_THREAT_REQUEST = 'ACTIVE_OR_INACTIVE_THREAT_REQUEST',
  ACTIVE_OR_INACTIVE_THREAT_SUCCESS = 'ACTIVE_OR_INACTIVE_THREAT_SUCCESS',
  ACTIVE_OR_INACTIVE_THREAT_FAILURE = 'ACTIVE_OR_INACTIVE_THREAT_FAILURE',
  GET_SEARCH_FILTER_THREAT_REQUEST = 'GET_SEARCH_FILTER_THREAT_REQUEST',
  GET_SEARCH_FILTER_THREAT_SUCCESS = 'GET_SEARCH_FILTER_THREAT_SUCCESS',
  GET_SEARCH_FILTER_THREAT_FAILURE = 'GET_SEARCH_FILTER_THREAT_FAILURE',
  UPDATE_RISK_REQUEST = 'UPDATE_RISK_REQUEST',
  UPDATE_RISK_SUCCESS = 'UPDATE_RISK_SUCCESS',
  UPDATE_RISK_FAILURE = 'UPDATE_RISK_FAILURE',
  EDIT_THREAT_CUSTOMER_PROJECT_STATUS_REQUEST = 'EDIT_THREAT_CUSTOMER_PROJECT_STATUS_REQUEST',
  EDIT_THREAT_CUSTOMER_PROJECT_STATUS_SUCCESS = 'EDIT_THREAT_CUSTOMER_PROJECT_STATUS_SUCCESS',
  EDIT_THREAT_CUSTOMER_PROJECT_STATUS_FAILURE = 'EDIT_THREAT_CUSTOMER_PROJECT_STATUS_FAILURE',
  GET_LINKED_CONTROLS_REQUEST = 'GET_LINKED_CONTROLS_REQUEST',
  GET_LINKED_CONTROLS_SUCCESS = 'GET_LINKED_CONTROLS_SUCCESS',
  GET_LINKED_CONTROLS_FAILURE = 'GET_LINKED_CONTROLS_FAILURE',
  GET_ALL_SIMPLE_THREATS_MODULE_REQUEST = 'GET_ALL_SIMPLE_THREATS_MODULE_REQUEST',
  GET_ALL_SIMPLE_THREATS_MODULE_SUCCESS = 'GET_ALL_SIMPLE_THREATS_MODULE_SUCCESS',
  GET_ALL_SIMPLE_THREATS_MODULE_FAILURE = 'GET_ALL_SIMPLE_THREATS_MODULE_FAILURE',
  GET_SEARCH_FILTER_SIMPLE_THREAT_REQUEST = 'GET_SEARCH_FILTER_SIMPLE_THREAT_REQUEST',
  GET_SEARCH_FILTER_SIMPLE_THREAT_SUCCESS = 'GET_SEARCH_FILTER_SIMPLE_THREAT_SUCCESS',
  GET_SEARCH_FILTER_SIMPLE_THREAT_FAILURE = 'GET_SEARCH_FILTER_SIMPLE_THREAT_FAILURE',
}

export type UpdateThreatRiskRequest = {
  threatId: string;
  riskUpdateAdd: ThreatArraysId[];
  riskUpdateRemove: ThreatArraysId[]; 
}

export type reuqestUpdateThrearRiskPayloadResponse = {
  riskUpdateAdd: ThreatArraysId[];
  riskUpdateRemove: ThreatArraysId[]; 
}


export type UpdateThreatStatusRequestPayload = {
  threatId: string;
  newThreatStatus: boolean;
}

export type ThreatDetailPayloadApi = {
  threatId: string;
}

export type LinkedControlsType = {
  controls: IdAndName[]
  friendly_id: string
  id: string
  name: string
}

export type CreateNewThreatPayloadApi = {
  threatId: string;
  threatPostBody: ThreatPutBody;
}

export type ControlOfVulnerability = {
  id: string;
  name: string;
};

export type ThreatPostBody = {
  friendly_id: string,
  name: string,
  description: string,
  is_active: boolean,
  registration_status: string,
  vulnerabilities: ControlOfVulnerability[],
  risk_factors: riskFactor[],
  threats: ThreatArraysId[],
  damages: ThreatArraysId[],
  controls: ThreatArraysId[]
}

export type ObjectWithId = {
  id: string;
};

export type ThreatPutBody = {
  id: string;
  friendly_id: string;
  name: string;
  description: string;
  is_active: boolean;
  registration_status: string;
  vulnerabilities_add: ObjectWithId[];
  vulnerabilities_remove: ObjectWithId[];
  threats_add: ObjectWithId[];
  threats_remove: ObjectWithId[];
  damages_add: ObjectWithId[];
  damages_remove: ObjectWithId[];
  controls_add: ObjectWithId[];
  controls_remove: ObjectWithId[];
  risk_factors_add: ObjectWithId[];
  risk_factors_remove: ObjectWithId[];
}

export type ThreatsResults = {
  results: Threat[];
  count: number;
  next: boolean;
} 

export type ThreatDetailsDamages = {
  id: string;
  name: string;
  type: string;
}

export type Threat = {
  id: string,
  friendly_id: string,
  name: string,
  description: string,
  is_active: boolean,
  registration_status: string,
  vulnerabilities: ControlOfVulnerability[],
  risk_factors: riskFactor[],
  threats: ThreatsArrayNameId[],
  damages: ThreatDetailsDamages[],
  controls: ThreatsArrayNameId[],
  risks: risk[],
  questionnaires_origin: questionnaires[],
  checklists_origin: checklists[]
}

export type ThreatsArrayNameId = {
  id: string;
  name: string;
  description: string;
  is_active?: boolean;
}

export type ThreatTest = {
  id: string,
  friendly_id: string,
  name: string,
  description: string,
  is_active: boolean,
  registration_status: string,
  vulnerabilities: ObjectWithId[],
  risk_factors: riskFactor[],
  threats: ThreatArraysId[],
  damages: ThreatArraysId[],
  controls: ThreatArraysId[]
}

export type ThreatArraysId = {
  id: string
}

export type riskFactor = {
  id: string;
  name: string;
}

export type SimpleThreat = {
  id: string;
  name: string;
  description: string;
  threats: number;
  controls: number;  
  risk_factors: number;
  vulnerabilities: number;
  is_active: boolean;
}

export type SimpleThreatsResults = {
  results: SimpleThreat[];
  count: number;
  next: boolean;
}

export type ThreatState = {
  allThreats: Threat[];
  threatDetail: Threat;
  total: number,
  visited_pages: number[];
  has_next: boolean;
  linkedControls: LinkedControlsType;
  simpleThreat: SimpleThreat[];
}

export type risk = {
  id: string;
  name: string;
  description: string;
}

export type questionnaires = {
  id: string;
  name: string;
  description: string;
  type_of_questionnaire: string;
}

export type checklists = {
  id: string;
  subject: string;
}

export type ChangeStatusBodyType = {
  justification_of_inactivation: string,
  is_active: boolean
}

export type CustomerProjetcChangeStatusApiPayload = {
  threatId: string;
  changeStatusBody: ChangeStatusBodyType;
  customerProjectId: string;
}

export type ThreatCustomerProject = {
  id: string,
  name: string,
  is_active: boolean;
  project_status: string;
  threats: Threat[]
}