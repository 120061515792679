import type { Action } from 'store/types';

import type {
  AddNewChecklistData,
  AddNewChecklistResponseData,
  EditChecklistData,
  Checklist,
  Activity,
  Question
} from './types';
import { ChecklistsActions } from './types';

const {
  ACTIVE_OR_INACTIVE_CHECKLIST_FAILURE,
  ACTIVE_OR_INACTIVE_CHECKLIST_SUCCESS,
  ACTIVE_OR_INACTIVE_CHECKLIST_REQUEST,
  ADD_NEW_CHECKLIST_FAILURE,
  ADD_NEW_CHECKLIST_REQUEST,
  ADD_NEW_CHECKLIST_SUCCESS,
  EDIT_CHECKLIST_FAILURE,
  EDIT_CHECKLIST_REQUEST,
  EDIT_CHECKLIST_SUCCESS,
  GET_ALL_CHECKLISTS_FAILURE,
  GET_ALL_CHECKLISTS_REQUEST,
  GET_ALL_CHECKLISTS_SUCCESS,
  GET_CHECKLIST_DETAIL_FAILURE,
  GET_CHECKLIST_DETAIL_REQUEST,
  GET_CHECKLIST_DETAIL_SUCCESS,
  GET_FILTERED_CHECKLIST_FAILURE,
  GET_FILTERED_CHECKLIST_REQUEST,
  GET_FILTERED_CHECKLIST_SUCCESS,
  RESET_THE_CHECKLIST_DETAIL_STATE,
  GET_ALL_ACTIVITIES_REQUEST,
  GET_ALL_ACTIVITIES_SUCCESS,
  GET_ALL_ACTIVITIES_FAILURE,
  GET_ALL_QUESTIONS_REQUEST,
  GET_ALL_QUESTIONS_SUCCESS,
  GET_ALL_QUESTIONS_FAILURE,
  GET_FILTERED_ACTIVITY_REQUEST,
  GET_FILTERED_ACTIVITY_SUCCESS,
  GET_FILTERED_ACTIVITY_FAILURE,
  GET_ALL_CHECKLISTS_PROJECTSETTINGS_REQUEST,
  GET_FILTERED_CHECKLIST_PROJECTSETTINGS_REQUEST,
  GET_FILTERED_CHECKLIST_PROJECTSETTINGS_SUCCESS,
  GET_FILTERED_CHECKLIST_PROJECTSETTINGS_FAILURE,
} = ChecklistsActions;

function getChecklistsRequest(last_page?: number): Action {
  return {
    type: GET_ALL_CHECKLISTS_REQUEST,
    payload: {
      last_page
    },
  };
}

function getChecklistsSuccess(checklists: Checklist[], total: number, last_page: number, has_next: boolean): Action {
  return {
    type: GET_ALL_CHECKLISTS_SUCCESS,
    payload: {
      checklists,
      total,
      last_page,
      has_next
    },
  };
}

function getChecklistsFailure(): Action {
  return {
    type: GET_ALL_CHECKLISTS_FAILURE,
  };
}

function getQuestionsRequest(): Action {
  return {
    type: GET_ALL_QUESTIONS_REQUEST,
  };
}

function getQuestionsSuccess(questions: Question[]): Action {
  return {
    type: GET_ALL_QUESTIONS_SUCCESS,
    payload: {
      questions,
    },
  };
}

function getQuestionsFailure(): Action {
  return {
    type: GET_ALL_QUESTIONS_FAILURE,
  };
}

function getActivitiesRequest(): Action {
  return {
    type: GET_ALL_ACTIVITIES_REQUEST,
  };
}

function getActivitiesSuccess(activity: Activity[]): Action {
  return {
    type: GET_ALL_ACTIVITIES_SUCCESS,
    payload: {
      activity,
    },
  };
}

function getActivitiesFailure(): Action {
  return {
    type: GET_ALL_ACTIVITIES_FAILURE,
  };
}

function getSearchFilteredChecklistRequest(
  filteredChecklists: Array<string | boolean | null>
): Action {
  return {
    type: GET_FILTERED_CHECKLIST_REQUEST,
    payload: {
      filteredChecklists,
    },
  };
}

function getSearchFilteredChecklistSuccess(
  checklists: Checklist[]
): Action {
  return {
    type: GET_FILTERED_CHECKLIST_SUCCESS,
    payload: {
      checklists,
    },
  };
}

function getSearchFilteredChecklistFailure(): Action {
  return {
    type: GET_FILTERED_CHECKLIST_FAILURE,
  };
}

function getSearchFilteredActivityRequest(
  filteredActivities: Array<string | boolean | null>
): Action {
  return {
    type: GET_FILTERED_ACTIVITY_REQUEST,
    payload: {
      filteredActivities,
    },
  };
}

function getSearchFilteredActivitySuccess(activities: Activity[]): Action {
  return {
    type: GET_FILTERED_ACTIVITY_SUCCESS,
    payload: {
      activities,
    },
  };
}

function getSearchFilteredActivityFailure(): Action {
  return {
    type: GET_FILTERED_ACTIVITY_FAILURE,
  };
}

function getChecklistDetailRequest(checklistID: string): Action {
  return {
    type: GET_CHECKLIST_DETAIL_REQUEST,
    payload: {
      checklistID,
    },
  };
}

function getChecklistDetailSuccess(checklist: Checklist): Action {
  return {
    type: GET_CHECKLIST_DETAIL_SUCCESS,
    payload: {
      checklist,
    },
  };
}

function getChecklistDetailFailure(): Action {
  return {
    type: GET_CHECKLIST_DETAIL_FAILURE,
  };
}

function addNewChecklistRequest(
  newChecklist: AddNewChecklistData
): Action {
  return {
    type: ADD_NEW_CHECKLIST_REQUEST,
    payload: {
      newChecklist,
    },
  };
}

function addNewChecklistSuccess(
  ChecklistAdded: AddNewChecklistResponseData
): Action {
  return {
    type: ADD_NEW_CHECKLIST_SUCCESS,
    payload: {
      ChecklistAdded,
    },
  };
}

function addNewChecklistFailure(): Action {
  return {
    type: ADD_NEW_CHECKLIST_FAILURE,
  };
}

function editChecklistRequest(
  updatedChecklist: EditChecklistData,
): Action {
  return {
    type: EDIT_CHECKLIST_REQUEST,
    payload: {
      updatedChecklist,
    },
  };
}

function editChecklistSuccess(): Action {
  return {
    type: EDIT_CHECKLIST_SUCCESS,
  };
}

function editChecklistFailure(): Action {
  return {
    type: EDIT_CHECKLIST_FAILURE,
  };
}

function activeOrInactiveChecklistRequest(
  ChecklistId: string,
  newChecklistStatus: boolean
): Action {
  return {
    type: ACTIVE_OR_INACTIVE_CHECKLIST_REQUEST,
    payload: {
      ChecklistId,
      newChecklistStatus,
    },
  };
}

function activeOrInactiveChecklistSuccess(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_CHECKLIST_SUCCESS,
  };
}

function activeOrInactiveChecklistFailure(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_CHECKLIST_FAILURE,
  };
}

function resetTheChecklistDetailState(): Action {
  return {
    type: RESET_THE_CHECKLIST_DETAIL_STATE,
  };
}

function getChecklistsProjectSettingsRequest(): Action {
  return {
    type: GET_ALL_CHECKLISTS_PROJECTSETTINGS_REQUEST,
  };
}

function getSearchFilteredChecklistProjectSettingsRequest(
  filteredChecklists: Array<string | boolean | null>
): Action {
  return {
    type: GET_FILTERED_CHECKLIST_PROJECTSETTINGS_REQUEST,
    payload: {
      filteredChecklists,
    },
  };
}

function getSearchFilteredChecklistProjectSettingsSuccess(
  filteredChecklists: Checklist[]
): Action {
  return {
    type: GET_FILTERED_CHECKLIST_PROJECTSETTINGS_SUCCESS,
    payload: {
      filteredChecklists,
    },
  };
}

function getSearchFilteredChecklistProjectSettingsFailure(): Action {
  return {
    type: GET_FILTERED_CHECKLIST_PROJECTSETTINGS_FAILURE,
  };
}

export const checklistsActionsFunctions = {
  getChecklistsRequest,
  getChecklistsSuccess,
  getChecklistsFailure,
  getSearchFilteredChecklistRequest,
  getSearchFilteredChecklistSuccess,
  getSearchFilteredChecklistFailure,
  getChecklistDetailRequest,
  getChecklistDetailSuccess,
  getChecklistDetailFailure,
  addNewChecklistRequest,
  addNewChecklistSuccess,
  addNewChecklistFailure,
  editChecklistRequest,
  editChecklistSuccess,
  editChecklistFailure,
  activeOrInactiveChecklistRequest,
  activeOrInactiveChecklistSuccess,
  activeOrInactiveChecklistFailure,
  resetTheChecklistDetailState,
  getQuestionsRequest,
  getQuestionsSuccess,
  getQuestionsFailure,
  getActivitiesRequest,
  getActivitiesSuccess,
  getActivitiesFailure,
  getSearchFilteredActivityRequest,
  getSearchFilteredActivitySuccess,
  getSearchFilteredActivityFailure,
  getChecklistsProjectSettingsRequest,
  getSearchFilteredChecklistProjectSettingsRequest,
  getSearchFilteredChecklistProjectSettingsSuccess,
  getSearchFilteredChecklistProjectSettingsFailure
};

