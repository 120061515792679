import type { Action } from 'store/types';

import { UserProfileActions, UserProfile, AllProfilesResponse, AddProfilePayload, AllGroupsResponse, AddEditGroupPayload, Group, Permission } from './types';

const {
    GET_ALL_USER_PROFILE_REQUEST,
    GET_ALL_USER_PROFILE_SUCCESS,
    GET_ALL_USER_PROFILE_FAILURE,
    ADD_NEW_USER_PROFILE_REQUEST,
    ADD_NEW_USER_PROFILE_SUCCESS,
    ADD_NEW_USER_PROFILE_FAILURE,
    EDIT_USER_PROFILE_REQUEST,
    EDIT_USER_PROFILE_SUCCESS,
    EDIT_USER_PROFILE_FAILURE,
    GET_ONE_USER_PROFILE_REQUEST,
    GET_ONE_USER_PROFILE_SUCCESS,
    GET_ONE_USER_PROFILE_FAILURE,
    GET_ALL_GROUPS_REQUEST,
    GET_ALL_GROUPS_SUCCESS,
    GET_ALL_GROUPS_FAILURE,
    ADD_NEW_GROUP_REQUEST,
    ADD_NEW_GROUP_SUCCESS,
    ADD_NEW_GROUP_FAILURE,
    EDIT_GROUP_REQUEST,
    EDIT_GROUP_SUCCESS,
    EDIT_GROUP_FAILURE,
    GET_ONE_GROUP_REQUEST,
    GET_ONE_GROUP_SUCCESS,
    GET_ONE_GROUP_FAILURE,
    GET_ALL_PERMISSIONS_REQUEST,
    GET_ALL_PERMISSIONS_SUCCESS,
    GET_ALL_PERMISSIONS_FAILURE,
    GET_FILTERED_USER_PROFILE_REQUEST,
    GET_FILTERED_USER_PROFILE_SUCCESS,
    GET_FILTERED_USER_PROFILE_FAILURE,
    GET_FILTERED_GROUP_REQUEST,
    GET_FILTERED_GROUP_SUCCESS,
    GET_FILTERED_GROUP_FAILURE,
    GET_FILTERED_PERMISSION_REQUEST,
    GET_FILTERED_PERMISSION_SUCCESS,
    GET_FILTERED_PERMISSION_FAILURE,
  } = UserProfileActions;

  function getUserProfilesRequest(): Action {
    return {
      type:GET_ALL_USER_PROFILE_REQUEST,
    };
  }

  function getUserProfilesSuccess(userProfiles: AllProfilesResponse): Action {
    return {
      type: GET_ALL_USER_PROFILE_SUCCESS,
      payload: {
        userProfiles,
      },
    };
  }
  
  function getUserProfilesFailure(): Action {
    return {
      type: GET_ALL_USER_PROFILE_FAILURE,
    };
  }

  function addUserProfilesRequest(userProfile: AddProfilePayload): Action {
    return {
      type: ADD_NEW_USER_PROFILE_REQUEST,
      payload: {
        userProfile
      }
    };

  }

  function addUserProfilesSuccess(): Action {
    return {
      type: ADD_NEW_USER_PROFILE_SUCCESS,
    };
  }
  
  function addUserProfilesFailure(): Action {
    return {
      type: ADD_NEW_USER_PROFILE_FAILURE,
    };
  }

  function editUserProfilesRequest(userProfile: AddProfilePayload): Action {
    return {
      type: EDIT_USER_PROFILE_REQUEST,
      payload: {
        userProfile
      }
    };
  }

  function editUserProfilesSuccess(): Action {
    return {
      type: EDIT_USER_PROFILE_SUCCESS,
    };
  }
  
  function editUserProfilesFailure(): Action {
    return {
      type: EDIT_USER_PROFILE_FAILURE,
    };
  }

  function getOneUserProfileRequest(profileId: string): Action {
    return {
      type:GET_ONE_USER_PROFILE_REQUEST,
      payload: {
        profileId
      }
    };
  }

  function getOneUserProfileSuccess(userProfile: UserProfile): Action {
    return {
      type: GET_ONE_USER_PROFILE_SUCCESS,
      payload: {
        userProfile,
      },
    };
  }
  
  function getOneUserProfileFailure(): Action {
    return {
      type: GET_ONE_USER_PROFILE_FAILURE,
    };
  }

  function getAllGroupsRequest(): Action {
    return {
      type: GET_ALL_GROUPS_REQUEST,
    };
  }

  function getAllGroupsSuccess(groups: AllGroupsResponse): Action {
    return {
      type: GET_ALL_GROUPS_SUCCESS,
      payload: {
        groups,
      },
    };
  }
  
  function getAllGroupsFailure(): Action {
    return {
      type: GET_ALL_GROUPS_FAILURE,
    };
  }

  function addGroupRequest(group: AddEditGroupPayload): Action {
    return {
      type: ADD_NEW_GROUP_REQUEST,
      payload: {
        group
      }
    };

  }

  function addGroupSuccess(): Action {
    return {
      type: ADD_NEW_GROUP_SUCCESS,
    };
  }
  
  function addGroupFailure(): Action {
    return {
      type: ADD_NEW_GROUP_FAILURE,
    };
  }

  function editGroupRequest(group: AddEditGroupPayload): Action {
    return {
      type: EDIT_GROUP_REQUEST,
      payload: {
        group, 
      }
    };
  }

  function editGroupSuccess(): Action {
    return {
      type: EDIT_GROUP_SUCCESS,
    };
  }
  
  function editGroupFailure(): Action {
    return {
      type: EDIT_GROUP_FAILURE,
    };
  }

  function getOneGroupRequest(groupId: string, microsservice?: string): Action {
    return {
      type:GET_ONE_GROUP_REQUEST,
      payload: {
        groupId,
        microsservice
      }
    };
  }

  function getOneGroupSuccess(group: Group, microsservice: string): Action {
    return {
      type: GET_ONE_GROUP_SUCCESS,
      payload: {
        group,
        microsservice
      },
    };
  }
  
  function getOneGroupFailure(): Action {
    return {
      type: GET_ONE_GROUP_FAILURE,
    };
  }

  function getAllPermissionsRequest(): Action {
    return {
      type: GET_ALL_PERMISSIONS_REQUEST,
    };
  }

  function getAllPermissionsSuccess(permissions: Permission[], microsservice: string): Action {
    return {
      type: GET_ALL_PERMISSIONS_SUCCESS,
      payload: {
        permissions,
        microsservice
      },
    };
  }
  
  function getAllPermissionsFailure(): Action {
    return {
      type: GET_ALL_PERMISSIONS_FAILURE,
    };
  }

  function getFilteredUserProfileRequest(
    filtered: string,
  ): Action {
    return {
      type: GET_FILTERED_USER_PROFILE_REQUEST,
      payload: {
        filtered
      },
    };
  }
  
  function getFilteredUserProfileSuccess(userProfiles: AllProfilesResponse): Action {
    return {
      type: GET_FILTERED_USER_PROFILE_SUCCESS,
      payload: {
        userProfiles,
      },
    };
  }
  
  function getFilteredUserProfileFailure(): Action {
    return {
      type: GET_FILTERED_USER_PROFILE_FAILURE,
    };
  }

  function getFilteredGroupRequest(
    filtered: string,
  ): Action {
    return {
      type: GET_FILTERED_GROUP_REQUEST,
      payload: {
        filtered
      },
    };
  }
  
  function getFilteredGroupSuccess(groups: AllGroupsResponse): Action {
    return {
      type: GET_FILTERED_GROUP_SUCCESS,
      payload: {
        groups,
      },
    };
  }
  
  function getFilteredGroupFailure(): Action {
    return {
      type: GET_FILTERED_GROUP_FAILURE,
    };
  }

  function getFilteredPermissionRequest(
    microsservice: string,
    filtered: string,
  ): Action {
    return {
      type: GET_FILTERED_PERMISSION_REQUEST,
      payload: {
        microsservice,
        filtered
      },
    };
  }
  
  function getFilteredPermissionSuccess(permissions: Permission[], microsservice: string): Action {
    return {
      type: GET_FILTERED_PERMISSION_SUCCESS,
      payload: {
        permissions,
        microsservice
      },
    };
  }
  
  function getFilteredPermissionFailure(): Action {
    return {
      type: GET_FILTERED_PERMISSION_FAILURE,
    };
  }

  export const userProfileActionsFunctions = {
    getUserProfilesRequest,
    getUserProfilesSuccess,
    getUserProfilesFailure,
    addUserProfilesRequest,
    addUserProfilesSuccess,
    addUserProfilesFailure,
    editUserProfilesRequest,
    editUserProfilesSuccess,
    editUserProfilesFailure,
    getOneUserProfileRequest,
    getOneUserProfileSuccess,
    getOneUserProfileFailure,
    getAllGroupsRequest,
    getAllGroupsSuccess,
    getAllGroupsFailure,
    addGroupRequest,
    addGroupSuccess,
    addGroupFailure,
    editGroupRequest,
    editGroupSuccess,
    editGroupFailure,
    getOneGroupRequest,
    getOneGroupSuccess,
    getOneGroupFailure,
    getAllPermissionsRequest,
    getAllPermissionsSuccess,
    getAllPermissionsFailure,
    getFilteredUserProfileRequest,
    getFilteredUserProfileSuccess,
    getFilteredUserProfileFailure,
    getFilteredGroupRequest,
    getFilteredGroupSuccess,
    getFilteredGroupFailure,
    getFilteredPermissionRequest,
    getFilteredPermissionSuccess,
    getFilteredPermissionFailure
  };