import type { Action } from 'store/types';

import type {
  Sector,
  Subsector,
  Segment,
  Department,
  AddNewSectorRequest,
  AddNewSegmentRequest,
  AddNewDepartmentRequest,
  AddNewSubsectorRequest,
  SectorDetails,
  DepartmentOfEditingRequest,
} from './types';
import { SectorsActions } from './types';

const {
  GET_ALL_SECTORS_REQUEST,
  GET_ALL_SECTORS_SUCCESS,
  GET_ALL_SECTORS_FAILURE,
  GET_SECTOR_DETAILS_REQUEST,
  GET_SECTOR_DETAILS_SUCCESS,
  GET_SECTOR_DETAILS_FAILURE,
  GET_ALL_SUB_SECTORS_REQUEST,
  GET_ALL_SUB_SECTORS_SUCCESS,
  GET_ALL_SUB_SECTORS_FAILURE,
  GET_ALL_SEGMENTS_REQUEST,
  GET_ALL_SEGMENTS_SUCCESS,
  GET_ALL_SEGMENTS_FAILURE,
  GET_ALL_DEPARTMENTS_REQUEST,
  GET_ALL_DEPARTMENTS_SUCCESS,
  GET_ALL_DEPARTMENTS_FAILURE,
  GET_FILTERED_SECTOR_REQUEST,
  GET_FILTERED_SECTOR_SUCCESS,
  GET_FILTERED_SECTOR_FAILURE,
  GET_FILTERED_DEPARTMENT_REQUEST,
  GET_FILTERED_DEPARTMENT_SUCCESS,
  GET_FILTERED_DEPARTMENT_FAILURE,
  ADD_NEW_SECTOR_REQUEST,
  ADD_NEW_SECTOR_SUCCESS,
  ADD_NEW_SECTOR_FAILURE,
  EDIT_SECTOR_REQUEST,
  EDIT_SECTOR_SUCCESS,
  EDIT_SECTOR_FAILURE,
  ADD_NEW_SUB_SECTOR_REQUEST,
  ADD_NEW_SUB_SECTOR_SUCCESS,
  ADD_NEW_SUB_SECTOR_FAILURE,
  EDIT_SUB_SECTOR_REQUEST,
  EDIT_SUB_SECTOR_SUCCESS,
  EDIT_SUB_SECTOR_FAILURE,
  ADD_NEW_SEGMENT_REQUEST,
  ADD_NEW_SEGMENT_SUCCESS,
  ADD_NEW_SEGMENT_FAILURE,
  EDIT_SEGMENT_REQUEST,
  EDIT_SEGMENT_SUCCESS,
  EDIT_SEGMENT_FAILURE,
  ADD_NEW_DEPARTMENT_REQUEST,
  ADD_NEW_DEPARTMENT_SUCCESS,
  ADD_NEW_DEPARTMENT_FAILURE,
  EDIT_DEPARTMENT_REQUEST,
  EDIT_DEPARTMENT_SUCCESS,
  EDIT_DEPARTMENT_FAILURE,
  ACTIVE_OR_INACTIVE_SECTOR_REQUEST,
  ACTIVE_OR_INACTIVE_SECTOR_SUCCESS,
  ACTIVE_OR_INACTIVE_SECTOR_FAILURE,
  ACTIVE_OR_INACTIVE_SUB_SECTOR_REQUEST,
  ACTIVE_OR_INACTIVE_SUB_SECTOR_SUCCESS,
  ACTIVE_OR_INACTIVE_SUB_SECTOR_FAILURE,
  ACTIVE_OR_INACTIVE_SEGMENT_REQUEST,
  ACTIVE_OR_INACTIVE_SEGMENT_SUCCESS,
  ACTIVE_OR_INACTIVE_SEGMENT_FAILURE,
  ACTIVE_OR_INACTIVE_DEPARTMENT_REQUEST,
  ACTIVE_OR_INACTIVE_DEPARTMENT_SUCCESS,
  ACTIVE_OR_INACTIVE_DEPARTMENT_FAILURE,
  RESET_THE_LAST_SECTOR_DETAILS,
} = SectorsActions;

function getSectorsRequest(): Action {
  return {
    type: GET_ALL_SECTORS_REQUEST,
  };
}

function getSectorsSuccess(sectors: Sector[]): Action {
  return {
    type: GET_ALL_SECTORS_SUCCESS,
    payload: {
      sectors,
    },
  };
}

function getSectorsFailure(): Action {
  return {
    type: GET_ALL_SECTORS_FAILURE,
  };
}

function getSectorDetailsRequest(sectorId: string): Action {
  return {
    type: GET_SECTOR_DETAILS_REQUEST,
    payload: {
      sectorId,
    },
  };
}

function getSectorDetailsSuccess(sectorDetails: SectorDetails): Action {
  return {
    type: GET_SECTOR_DETAILS_SUCCESS,
    payload: {
      sectorDetails,
    },
  };
}

function getSectorDetailsFailure(): Action {
  return {
    type: GET_SECTOR_DETAILS_FAILURE,
  };
}

function getSubSectorsRequest(): Action {
  return {
    type: GET_ALL_SUB_SECTORS_REQUEST,
  };
}

function getSubSectorsSuccess(subSectors: Subsector[]): Action {
  return {
    type: GET_ALL_SUB_SECTORS_SUCCESS,
    payload: {
      subSectors,
    },
  };
}

function getSubSectorsFailure(): Action {
  return {
    type: GET_ALL_SUB_SECTORS_FAILURE,
  };
}

function getSegmentsRequest(): Action {
  return {
    type: GET_ALL_SEGMENTS_REQUEST,
  };
}

function getSegmentsSuccess(segments: Segment[]): Action {
  return {
    type: GET_ALL_SEGMENTS_SUCCESS,
    payload: {
      segments,
    },
  };
}

function getSegmentsFailure(): Action {
  return {
    type: GET_ALL_SEGMENTS_FAILURE,
  };
}

function getDepartmentsRequest(): Action {
  return {
    type: GET_ALL_DEPARTMENTS_REQUEST,
  };
}

function getDepartmentsSuccess(departments: Department[]): Action {
  return {
    type: GET_ALL_DEPARTMENTS_SUCCESS,
    payload: {
      departments,
    },
  };
}

function getDepartmentsFailure(): Action {
  return {
    type: GET_ALL_DEPARTMENTS_FAILURE,
  };
}

function getSearchFilteredSectorRequest(
  filteredSector: Array<string | boolean | null>
): Action {
  return {
    type: GET_FILTERED_SECTOR_REQUEST,
    payload: {
      filteredSector,
    },
  };
}

function getSearchFilteredSectorSuccess(sectors: Sector[]): Action {
  return {
    type: GET_FILTERED_SECTOR_SUCCESS,
    payload: {
      sectors,
    },
  };
}

function getSearchFilteredSectorFailure(): Action {
  return {
    type: GET_FILTERED_SECTOR_FAILURE,
  };
}

function getSearchFilteredDepartmentRequest(
  filteredDepartment: Array<string | boolean | null>
): Action {
  return {
    type: GET_FILTERED_DEPARTMENT_REQUEST,
    payload: {
      filteredDepartment,
    },
  };
}

function getSearchFilteredDepartmentSuccess(departments: Department[]): Action {
  return {
    type: GET_FILTERED_DEPARTMENT_SUCCESS,
    payload: {
      departments,
    },
  };
}

function getSearchFilteredDepartmentFailure(): Action {
  return {
    type: GET_FILTERED_DEPARTMENT_FAILURE,
  };
}

function addNewSectorRequest(sector: AddNewSectorRequest): Action {
  return {
    type: ADD_NEW_SECTOR_REQUEST,
    payload: {
      sector,
    },
  };
}

function addNewSectorSuccess(): Action {
  return {
    type: ADD_NEW_SECTOR_SUCCESS,
  };
}

function addNewSectorFailure(): Action {
  return {
    type: ADD_NEW_SECTOR_FAILURE,
  };
}

function editSectorRequest(sector: Sector): Action {
  return {
    type: EDIT_SECTOR_REQUEST,
    payload: {
      sector,
    },
  };
}

function editSectorSuccess(): Action {
  return {
    type: EDIT_SECTOR_SUCCESS,
  };
}

function editSectorFailure(): Action {
  return {
    type: EDIT_SECTOR_FAILURE,
  };
}

function addNewSubsectorRequest(subsector: AddNewSubsectorRequest): Action {
  return {
    type: ADD_NEW_SUB_SECTOR_REQUEST,
    payload: {
      subsector,
    },
  };
}

function addNewSubsectorSuccess(): Action {
  return {
    type: ADD_NEW_SUB_SECTOR_SUCCESS,
  };
}

function addNewSubsectorFailure(): Action {
  return {
    type: ADD_NEW_SUB_SECTOR_FAILURE,
  };
}

function editSubsectorRequest(subsector: Subsector): Action {
  return {
    type: EDIT_SUB_SECTOR_REQUEST,
    payload: {
      subsector,
    },
  };
}

function editSubsectorSuccess(): Action {
  return {
    type: EDIT_SUB_SECTOR_SUCCESS,
  };
}

function editSubsectorFailure(): Action {
  return {
    type: EDIT_SUB_SECTOR_FAILURE,
  };
}

function addNewSegmentRequest(segment: AddNewSegmentRequest): Action {
  return {
    type: ADD_NEW_SEGMENT_REQUEST,
    payload: {
      segment,
    },
  };
}

function addNewSegmentSuccess(): Action {
  return {
    type: ADD_NEW_SEGMENT_SUCCESS,
  };
}

function addNewSegmentFailure(): Action {
  return {
    type: ADD_NEW_SEGMENT_FAILURE,
  };
}

function editSegmentRequest(segment: Segment): Action {
  return {
    type: EDIT_SEGMENT_REQUEST,
    payload: {
      segment,
    },
  };
}

function editSegmentSuccess(): Action {
  return {
    type: EDIT_SEGMENT_SUCCESS,
  };
}

function editSegmentFailure(): Action {
  return {
    type: EDIT_SEGMENT_FAILURE,
  };
}

function addNewDepartmentRequest(department: AddNewDepartmentRequest): Action {
  return {
    type: ADD_NEW_DEPARTMENT_REQUEST,
    payload: {
      department,
    },
  };
}

function addNewDepartmentSuccess(): Action {
  return {
    type: ADD_NEW_DEPARTMENT_SUCCESS,
  };
}

function addNewDepartmentFailure(): Action {
  return {
    type: ADD_NEW_DEPARTMENT_FAILURE,
  };
}

function editDepartmentRequest(department: DepartmentOfEditingRequest): Action {
  return {
    type: EDIT_DEPARTMENT_REQUEST,
    payload: {
      department,
    },
  };
}

function editDepartmentSuccess(): Action {
  return {
    type: EDIT_DEPARTMENT_SUCCESS,
  };
}

function editDepartmentFailure(): Action {
  return {
    type: EDIT_DEPARTMENT_FAILURE,
  };
}

function activeOrInactiveSectorRequest(
  id: string,
  updatedStatus: boolean
): Action {
  return {
    type: ACTIVE_OR_INACTIVE_SECTOR_REQUEST,
    payload: {
      id,
      updatedStatus,
    },
  };
}

function activeOrInactiveSectorSuccess(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_SECTOR_SUCCESS,
  };
}

function activeOrInactiveSectorFailure(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_SECTOR_FAILURE,
  };
}

function activeOrInactiveSubsectorRequest(
  id: string,
  updatedStatus: boolean
): Action {
  return {
    type: ACTIVE_OR_INACTIVE_SUB_SECTOR_REQUEST,
    payload: {
      id,
      updatedStatus,
    },
  };
}

function activeOrInactiveSubsectorSuccess(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_SUB_SECTOR_SUCCESS,
  };
}

function activeOrInactiveSubsectorFailure(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_SUB_SECTOR_FAILURE,
  };
}

function activeOrInactiveSegmentRequest(
  id: string,
  updatedStatus: boolean
): Action {
  return {
    type: ACTIVE_OR_INACTIVE_SEGMENT_REQUEST,
    payload: {
      id,
      updatedStatus,
    },
  };
}

function activeOrInactiveSegmentSuccess(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_SEGMENT_SUCCESS,
  };
}

function activeOrInactiveSegmentFailure(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_SEGMENT_FAILURE,
  };
}

function activeOrInactiveDepartmentRequest(
  id: string,
  updatedStatus: boolean
): Action {
  return {
    type: ACTIVE_OR_INACTIVE_DEPARTMENT_REQUEST,
    payload: {
      id,
      updatedStatus,
    },
  };
}

function activeOrInactiveDepartmentSuccess(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_DEPARTMENT_SUCCESS,
  };
}

function activeOrInactiveDepartmentFailure(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_DEPARTMENT_FAILURE,
  };
}

function resetTheLastSectorDetails(): Action {
  return {
    type: RESET_THE_LAST_SECTOR_DETAILS,
  };
}

export const sectorsActionsFunctions = {
  getSectorsRequest,
  getSectorsSuccess,
  getSectorsFailure,
  getSectorDetailsRequest,
  getSectorDetailsSuccess,
  getSectorDetailsFailure,
  getSubSectorsRequest,
  getSubSectorsSuccess,
  getSubSectorsFailure,
  getSegmentsRequest,
  getSegmentsSuccess,
  getSegmentsFailure,
  getDepartmentsRequest,
  getDepartmentsSuccess,
  getDepartmentsFailure,
  getSearchFilteredSectorRequest,
  getSearchFilteredSectorSuccess,
  getSearchFilteredSectorFailure,
  getSearchFilteredDepartmentRequest,
  getSearchFilteredDepartmentSuccess,
  getSearchFilteredDepartmentFailure,
  addNewSectorRequest,
  addNewSectorSuccess,
  addNewSectorFailure,
  editSectorRequest,
  editSectorSuccess,
  editSectorFailure,
  addNewSubsectorRequest,
  addNewSubsectorSuccess,
  addNewSubsectorFailure,
  editSubsectorRequest,
  editSubsectorSuccess,
  editSubsectorFailure,
  addNewSegmentRequest,
  addNewSegmentSuccess,
  addNewSegmentFailure,
  editSegmentRequest,
  editSegmentSuccess,
  editSegmentFailure,
  addNewDepartmentRequest,
  addNewDepartmentSuccess,
  addNewDepartmentFailure,
  editDepartmentRequest,
  editDepartmentSuccess,
  editDepartmentFailure,
  activeOrInactiveSectorRequest,
  activeOrInactiveSectorSuccess,
  activeOrInactiveSectorFailure,
  activeOrInactiveSubsectorRequest,
  activeOrInactiveSubsectorSuccess,
  activeOrInactiveSubsectorFailure,
  activeOrInactiveSegmentRequest,
  activeOrInactiveSegmentSuccess,
  activeOrInactiveSegmentFailure,
  activeOrInactiveDepartmentRequest,
  activeOrInactiveDepartmentSuccess,
  activeOrInactiveDepartmentFailure,
  resetTheLastSectorDetails,
};
