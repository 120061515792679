import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest, select, takeEvery } from 'redux-saga/effects';
import { api } from 'services/api';
import { history } from 'services/history';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import { questionsActionsFunctions } from 'store/modules/Manutention/questions/actions';
import type { Action } from 'store/types';
import { checkPage } from 'utils/number';

import type {
  AddNewQuestionRequestPayload,
  AddNewQuestionResponseData,
  EditQuestionRequestPayload,
  SearchFilteredQuestionRequestPayload,
  SearchFilteredControlRequestPayload,
  SearchFilteredRiskFactorRequestPayload,
  SearchFilteredVulnerabilityRequestPayload,
  UpdateQuestionStatusRequestPayload,
  Question,
  QuestionDetailRequestPayload,
  RiskFactor,
  EditQuestion,
  QuestionResults,
  DepartmentResults,
  ControlResults,
  VulnerabilityResults,
  RiskFactorResults,
  QuestionListResults,
} from './types';
import { QuestionsActions } from './types';

const {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE,
  NEW_QUESTION_ADDED_SUCCESS_MESSAGE,
  NEW_QUESTION_ADDED_ERROR_MESSAGE,
  SEARCH_FILTERED_QUESTION_SUCCESS_MESSAGE,
  SEARCH_FILTERED_QUESTION_ERROR_MESSAGE,
  SEARCH_FILTERED_CONTROL_SUCCESS_MESSAGE,
  SEARCH_FILTERED_CONTROL_ERROR_MESSAGE,
  SEARCH_FILTERED_VULNERABILITY_SUCCESS_MESSAGE,
  SEARCH_FILTERED_VULNERABILITY_ERROR_MESSAGE,
  SEARCH_FILTERED_RISK_FACTOR_SUCCESS_MESSAGE,
  SEARCH_FILTERED_RISK_FACTOR_ERROR_MESSAGE,
  EDIT_QUESTION_SUCCESS_MESSAGE,
  EDIT_QUESTION_ERROR_MESSAGE,
  ACTIVE_OR_INACTIVE_QUESTION_SUCCESS_MESSAGE,
  ACTIVE_OR_INACTIVE_QUESTION_ERROR_MESSAGE,
} = ToastMessages;

const {
  GET_ALL_QUESTIONS_MODULE_REQUEST,
  GET_QUESTION_DETAIL_REQUEST,
  GET_FILTERED_QUESTION_REQUEST,
  ADD_NEW_QUESTION_REQUEST,
  EDIT_QUESTION_REQUEST,
  EDIT_QUESTION_ADD_ALTERNATIVE_REQUEST,
  ACTIVE_OR_INACTIVE_QUESTION_REQUEST,
  GET_ALL_QUESTIONS_DEPARTMENTS_REQUEST,
  GET_ALL_QUESTIONS_VULNERABILITIES_REQUEST,
  GET_ALL_RISKFACTORS_REQUEST,
  GET_ALL_QUESTIONS_CONTROLS_REQUEST,
  GET_FILTERED_CONTROL_REQUEST,
  GET_FILTERED_RISKFACTOR_REQUEST,
  GET_FILTERED_VULNERABILITY_REQUEST,
} = QuestionsActions;

const {
  getQuestionsModuleRequest,
  getQuestionsModuleSuccess,
  getQuestionsModuleFailure,
  getSearchFilteredQuestionSuccess,
  getSearchFilteredQuestionFailure,
  getQuestionDetailSuccess,
  getQuestionDetailFailure,
  addNewQuestionSuccess,
  addNewQuestionFailure,
  editQuestionSuccess,
  editQuestionFailure,
  activeOrInactiveQuestionSuccess,
  activeOrInactiveQuestionFailure,
  resetTheQuestionDetailState,
  getQuestionsDepartmentsSuccess,
  getQuestionsDepartmentsFailure,
  getQuestionsControlsSuccess,
  getQuestionsControlsFailure,
  getQuestionsVulnerabilitiesRequest,
  getQuestionsVulnerabilitiesSuccess,
  getQuestionsVulnerabilitiesFailure,
  getRiskFactorsSuccess,
  getQuestionsControlsRequest,
  getRiskFactorsRequest,
  getSearchFilteredRiskFactorSuccess,
  getSearchFilteredRiskFactorFailure,
  getSearchFilteredVulnerabilitySuccess,
  getSearchFilteredControlFailure,
  getSearchFilteredControlSuccess,
} = questionsActionsFunctions;

const { getLastApiFetchDataSuccess, getLastApiFetchDataFailure } =
  lastApiFetchDataActionsFunctions;

function* fetchAllQuestionsModuleQuestions(action: Action): Generator {
  try {
    const { payload } = action as any;
    const total: number = (yield select(state => state.questions.total)) as number;
    const last_page = checkPage(payload.last_page, total);
    const path = `controls/questions/?page=${last_page}`;
    const has_next = yield select(state => state.questions.has_next);
    if (has_next || last_page === 1) {
      const questionsResponse: AxiosResponse<QuestionResults | QuestionListResults> | unknown =
        yield call(api, 'GET', path, {});

      const {
        data: { results, count, next },
        config: { url },
        status,
        statusText,
      } = questionsResponse as AxiosResponse<QuestionResults | QuestionListResults>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );
      yield put(getQuestionsModuleSuccess(results, count, last_page, !!next));
    }

  } catch (error) {

    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getQuestionsModuleFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchSearchFilteredQuestion(action: Action): Generator {
  const { filteredQuestions, filterDepartment } =
    action.payload as SearchFilteredQuestionRequestPayload;

  const hasFilteredOptionsWithoutStatus = filteredQuestions
    .filter(response => response !== null && typeof response !== 'boolean')
    .join(',');

  const hasStatusFilteredOption = filteredQuestions.some(
    option => typeof option === 'boolean'
  );

  const filteredOptionStatus = `is_active=${filteredQuestions.filter(
    response => typeof response === 'boolean'
  )}`;

  const filteredOptionsWithoutStatus = `search=${filteredQuestions
    .filter(response => response !== null && typeof response !== 'boolean')
    .join(',')}`;

  const filteredDepartment = `departments__name=${filterDepartment}`;

  const searchFiltered = () => {
    if (hasFilteredOptionsWithoutStatus !== '' && !hasStatusFilteredOption && filterDepartment === '') {
      return `?${filteredOptionsWithoutStatus}`;
    }

    if (hasFilteredOptionsWithoutStatus !== '' && hasStatusFilteredOption && filterDepartment === '') {
      return `?${filteredOptionsWithoutStatus}&&${filteredOptionStatus}`;
    }

    if (hasFilteredOptionsWithoutStatus !== '' && !hasStatusFilteredOption && filterDepartment !== '') {
      return `?${filteredOptionsWithoutStatus}&&${filteredDepartment}`;
    }

    if (hasFilteredOptionsWithoutStatus !== '' && hasStatusFilteredOption && filterDepartment !== '') {
      return `?${filteredOptionsWithoutStatus}&&${filteredOptionStatus}&&${filteredDepartment}`;
    }

    if (hasFilteredOptionsWithoutStatus === '' && hasStatusFilteredOption && filterDepartment === '') {
      return `?${filteredOptionStatus}`;
    }

    if (hasFilteredOptionsWithoutStatus === '' && hasStatusFilteredOption && filterDepartment !== '') {
      return `?${filteredOptionStatus}&&${filteredDepartment}`;
    }

    if (hasFilteredOptionsWithoutStatus === '' && !hasStatusFilteredOption && filterDepartment !== '') {
      return `?${filteredDepartment}`;
    }
  };

  const routeWithFilter = `controls/questions/${searchFiltered()}`;

  try {
    const questionsResponse: AxiosResponse<QuestionResults | QuestionListResults> | unknown =
      yield call(api, 'GET', routeWithFilter, {});

    const {
      data: { results, count },
      config: { url },
      status,
      statusText,
    } = questionsResponse as AxiosResponse<QuestionResults | QuestionListResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredQuestionSuccess(results, count));

    toast.success(SEARCH_FILTERED_QUESTION_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredQuestionFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      SEARCH_FILTERED_QUESTION_ERROR_MESSAGE
    );
  }
}

function* fetchSearchFilteredRiskFactor(action: Action): Generator {
  const { filteredRiskFactors } =
    action.payload as SearchFilteredRiskFactorRequestPayload;

  const parsedResponses = filteredRiskFactors
    .filter(response => response !== null)
    .join(',');

  try {
    const filteredRiskFactorsResponse: AxiosResponse<RiskFactor[]> | unknown =
      yield call(api, 'GET', `controls/risk-factors/?search=${parsedResponses}`, {});

    const {
      data: riskFactors,
      config: { url },
      status,
      statusText,
    } = filteredRiskFactorsResponse as AxiosResponse<RiskFactor[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredRiskFactorSuccess(riskFactors));

    toast.success(SEARCH_FILTERED_RISK_FACTOR_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredRiskFactorFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      SEARCH_FILTERED_RISK_FACTOR_ERROR_MESSAGE
    );
  }
}

function* fetchSearchFilteredControl(action: Action): Generator {
  const { filteredControls } =
    action.payload as SearchFilteredControlRequestPayload;

  const parsedResponses = filteredControls
    .filter(response => response !== null)
    .join(',');

  try {
    const filteredControlsResponse: AxiosResponse<ControlResults> | unknown =
      yield call(
        api,
        'GET',
        `controls/controls/?search=${parsedResponses}`,
        {}
      );

    const {
      data: controls,
      config: { url },
      status,
      statusText,
    } = filteredControlsResponse as AxiosResponse<ControlResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredControlSuccess(controls.results));

    toast.success(SEARCH_FILTERED_CONTROL_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredControlFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      SEARCH_FILTERED_CONTROL_ERROR_MESSAGE
    );
  }
}

function* fetchSearchFilteredVulnerability(action: Action): Generator {
  const { filteredVulnerabilities } =
    action.payload as SearchFilteredVulnerabilityRequestPayload;

  const parsedResponses = filteredVulnerabilities
    .filter(response => response !== null)
    .join(',');

  try {
    const filteredVulnerabilitiesResponse: AxiosResponse<VulnerabilityResults> | unknown =
      yield call(
        api,
        'GET',
        `controls/vulnerabilities/?search=${parsedResponses}`,
        {}
      );

    const {
      data: vulnerabilities,
      config: { url },
      status,
      statusText,
    } = filteredVulnerabilitiesResponse as AxiosResponse<VulnerabilityResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredVulnerabilitySuccess(vulnerabilities.results));

    toast.success(SEARCH_FILTERED_VULNERABILITY_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredControlFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      SEARCH_FILTERED_VULNERABILITY_ERROR_MESSAGE
    );
  }
}

function* fetchAllQuestionsDepartments(): Generator {
  try {
    const departmentsResponse: AxiosResponse<DepartmentResults> | unknown = yield call(
      api,
      'GET',
      'controls/departments/',
      {}
    );

    const {
      data: departments,
      config: { url },
      status,
      statusText,
    } = departmentsResponse as AxiosResponse<DepartmentResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getQuestionsDepartmentsSuccess(departments.results));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getQuestionsDepartmentsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchAllQuestionsControls(): Generator {
  try {
    const controlsResponse: AxiosResponse<ControlResults> | unknown = yield call(
      api,
      'GET',
      'controls/controls/',
      {}
    );

    const {
      data: controls,
      config: { url },
      status,
      statusText,
    } = controlsResponse as AxiosResponse<ControlResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getQuestionsControlsSuccess(controls.results));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getQuestionsControlsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchAllVulnerabilities(): Generator {
  try {
    const vulnerabilitiesResponse: AxiosResponse<VulnerabilityResults> | unknown = yield call(
      api,
      'GET',
      'controls/vulnerabilities/',
      {}
    );

    const {
      data: vulnerabilities,
      config: { url },
      status,
      statusText,
    } = vulnerabilitiesResponse as AxiosResponse<VulnerabilityResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getQuestionsVulnerabilitiesSuccess(vulnerabilities.results));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getQuestionsVulnerabilitiesFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchAllRiskFactors(): Generator {
  try {
    const riskFactorsResponse: AxiosResponse<RiskFactorResults> | unknown = yield call(
      api,
      'GET',
      'controls/risk-factors/',
      {}
    );

    const {
      data: riskFactors,
      config: { url },
      status,
      statusText,
    } = riskFactorsResponse as AxiosResponse<RiskFactorResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getRiskFactorsSuccess(riskFactors.results));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getQuestionsControlsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchAddNewQuestion(action: Action): Generator {
  const { newQuestion } = action.payload as AddNewQuestionRequestPayload;

  const existsCustomTable = newQuestion.columns.length > 0;

  try {
    const addNewQuestionResponse:
      | AxiosResponse<AddNewQuestionResponseData>
      | unknown = yield call(api, 'POST', 'controls/questions/create/', {
        ...newQuestion,
      });

    const {
      data: questionAdded,
      config: { url },
      status,
      statusText,
    } = addNewQuestionResponse as AxiosResponse<AddNewQuestionResponseData>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );


    yield put(addNewQuestionSuccess(questionAdded));

    if (existsCustomTable && status === 201) {
      history.push('/questions');
    } else {
      history.push(`/question-registration/${questionAdded.id}`);

      yield put(getQuestionsModuleRequest());
      yield put(getQuestionsVulnerabilitiesRequest());
      yield put(getRiskFactorsRequest());
      yield put(getQuestionsControlsRequest());
    }

    toast.success(NEW_QUESTION_ADDED_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(resetTheQuestionDetailState());
    yield put(addNewQuestionFailure());

    if (currentError.response?.data.name[0] === 'question with this name already exists.')
      toast.warn('Já existe uma pergunta com este nome.');
    else
      toast.error(NEW_QUESTION_ADDED_ERROR_MESSAGE);
  }
}

function* fetchQuestionDetail(action: Action): Generator {
  const { questionID } =
    action.payload as QuestionDetailRequestPayload;

  try {
    const questionDetailResponse: AxiosResponse<Question> | unknown =
      yield call(
        api,
        'GET',
        `controls/questions/${questionID}/detail/`,
        {}
      );

    const {
      data: questionDetail,
      config: { url },
      status,
      statusText,
    } = questionDetailResponse as AxiosResponse<Question>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getQuestionDetailSuccess(questionDetail));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(resetTheQuestionDetailState());
    yield put(getQuestionDetailFailure());

    toast.error(
      DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchToQuestionEditing(action: Action): Generator {
  const { updatedQuestion } =
    action.payload as EditQuestionRequestPayload;
  try {
    const questionEditingResponse: AxiosResponse<EditQuestion> | unknown =
      yield call(
        api,
        'PUT',
        `controls/questions/${updatedQuestion.id}/`,
        {
          ...updatedQuestion,
        }
      );

    const {
      config: { url },
      status,
      statusText,
    } = questionEditingResponse as AxiosResponse<EditQuestion>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editQuestionSuccess());
    yield put(getQuestionsModuleRequest());

    toast.success(EDIT_QUESTION_SUCCESS_MESSAGE);

  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(resetTheQuestionDetailState());
    yield put(editQuestionFailure());

    toast.error(
      EDIT_QUESTION_ERROR_MESSAGE
    );
  }
}

function* fetchToAddAlternativeToQuestionEditing(action: Action): Generator {
  const { updatedQuestion } =
    action.payload as EditQuestionRequestPayload;
  try {
    const questionEditingResponse: AxiosResponse<EditQuestion> | unknown =
      yield call(
        api,
        'PUT',
        `controls/questions/${updatedQuestion.id}/`,
        {
          ...updatedQuestion,
        }
      );

    const {
      config: { url },
      status,
      statusText
    } = questionEditingResponse as AxiosResponse<EditQuestion>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText
      })
    );

    yield put(editQuestionSuccess());
    yield put(getQuestionsModuleRequest());

    toast.success(EDIT_QUESTION_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(resetTheQuestionDetailState());
    yield put(editQuestionFailure());

    toast.error(
      EDIT_QUESTION_ERROR_MESSAGE
    );
  }
}

function* fetchToUpdateQuestionStatus(action: Action): Generator {
  const { QuestionId, newQuestionStatus } =
    action.payload as UpdateQuestionStatusRequestPayload;

  try {
    const updateQuestionResponse:
      | AxiosResponse<{ is_active: boolean }>
      | unknown = yield call(
        api,
        'PUT',
        `controls/questions/${QuestionId}/update-status/`,
        {
          is_active: newQuestionStatus,
        }
      );

    const {
      config: { url },
      status,
      statusText,
    } = updateQuestionResponse as AxiosResponse<{ is_active: boolean }>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(activeOrInactiveQuestionSuccess());
    yield put(getQuestionsModuleRequest());

    toast.success(ACTIVE_OR_INACTIVE_QUESTION_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(activeOrInactiveQuestionFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      ACTIVE_OR_INACTIVE_QUESTION_ERROR_MESSAGE
    );
  }
}

export function* questionsSagas() {
  yield all([
    takeEvery(GET_ALL_QUESTIONS_MODULE_REQUEST, fetchAllQuestionsModuleQuestions),
    takeLatest(GET_QUESTION_DETAIL_REQUEST, fetchQuestionDetail),
    takeLatest(GET_FILTERED_QUESTION_REQUEST, fetchSearchFilteredQuestion),
    takeLatest(ADD_NEW_QUESTION_REQUEST, fetchAddNewQuestion),
    takeLatest(EDIT_QUESTION_REQUEST, fetchToQuestionEditing),
    takeLatest(EDIT_QUESTION_ADD_ALTERNATIVE_REQUEST, fetchToAddAlternativeToQuestionEditing),
    takeLatest(ACTIVE_OR_INACTIVE_QUESTION_REQUEST, fetchToUpdateQuestionStatus),
    takeLatest(GET_ALL_QUESTIONS_DEPARTMENTS_REQUEST, fetchAllQuestionsDepartments),
    takeLatest(GET_ALL_QUESTIONS_CONTROLS_REQUEST, fetchAllQuestionsControls),
    takeLatest(GET_ALL_QUESTIONS_VULNERABILITIES_REQUEST, fetchAllVulnerabilities),
    takeLatest(GET_ALL_RISKFACTORS_REQUEST, fetchAllRiskFactors),
    takeLatest(GET_FILTERED_RISKFACTOR_REQUEST, fetchSearchFilteredRiskFactor),
    takeLatest(GET_FILTERED_VULNERABILITY_REQUEST, fetchSearchFilteredVulnerability),
    takeLatest(GET_FILTERED_CONTROL_REQUEST, fetchSearchFilteredControl),
  ]);
}
