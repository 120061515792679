import axios, { AxiosResponse } from 'axios';
import type { AuthState } from 'store/modules/auth/types';

const httpConfig = axios.create({
  baseURL: String(process.env.REACT_APP_LISA_BACKEND_BASE_URL),
  headers: {
    Accept: 'application/json',
  },
});

httpConfig.interceptors.request.use(
  (config: any) => {
    const rootState = JSON.parse(
      localStorage.getItem(
        `persist:${process.env.REACT_APP_LISA_APP_NAME}`
      ) as string
    );

    const authState: AuthState = JSON.parse(rootState.auth);

    const {
      user: { accessToken: token },
    } = authState;

    if (token !== null) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => Promise.reject(error)
);

export const api = async (
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  url: string,
  data = {},
  contentType = 'application/json',
  responseType: 'blob' | 'json' = 'json'
): Promise<AxiosResponse<any>> => {
  httpConfig.defaults.headers.post['Content-Type'] = contentType;
  return httpConfig
    .request({
      method,
      url,
      data,
      responseType
    })
    .then(response => response);
};
