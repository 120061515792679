import type { Action } from 'store/types';

import type {
  AddNewQuizData,
  AddNewQuizResponseData,
  EditQuiz,
  Quiz,
} from './types';
import { QuestionnariesActions } from './types';

const {
  GET_ALL_QUESTIONNARIES_MODULE_FAILURE,
  GET_ALL_QUESTIONNARIES_MODULE_REQUEST,
  GET_ALL_QUESTIONNARIES_MODULE_SUCCESS,
  GET_FILTERED_QUIZ_REQUEST,
  GET_FILTERED_QUIZ_SUCCESS,
  GET_FILTERED_QUIZ_FAILURE,
  GET_QUIZ_DETAIL_REQUEST,
  GET_QUIZ_DETAIL_SUCCESS,
  GET_QUIZ_DETAIL_FAILURE,
  ADD_NEW_QUIZ_REQUEST,
  ADD_NEW_QUIZ_SUCCESS,
  ADD_NEW_QUIZ_FAILURE,
  EDIT_QUIZ_ADD_QUESTION_REQUEST,
  EDIT_QUIZ_REQUEST,
  EDIT_QUIZ_SUCCESS,
  EDIT_QUIZ_FAILURE,
  ACTIVE_OR_INACTIVE_QUIZ_REQUEST,
  ACTIVE_OR_INACTIVE_QUIZ_SUCCESS,
  ACTIVE_OR_INACTIVE_QUIZ_FAILURE,
  QUIZ_QUANTITY_REQUEST,
  QUIZ_QUANTITY_SUCCESS,
  QUIZ_QUANTITY_FAILURE,
  RESET_THE_QUIZ_DETAIL_STATE,
} = QuestionnariesActions;

function getQuestionnariesModuleRequest(last_page?: number): Action {
  return {
    type: GET_ALL_QUESTIONNARIES_MODULE_REQUEST,
    payload: {
      last_page
    }
  };
}

function getQuestionnariesModuleSuccess(questionnaires: Quiz[], total_questionnaire: number, last_page: number, has_next_questionnaire: boolean): Action {
  return {
    type: GET_ALL_QUESTIONNARIES_MODULE_SUCCESS,
    payload: {
      questionnaires,
      total_questionnaire,
      last_page,
      has_next_questionnaire
    },
  };
}

function getQuestionnariesModuleFailure(): Action {
  return {
    type: GET_ALL_QUESTIONNARIES_MODULE_FAILURE,
  };
}

function getSearchFilteredQuizRequest(
  filteredQuestionnaries: Array<string | boolean | null>
): Action {
  return {
    type: GET_FILTERED_QUIZ_REQUEST,
    payload: {
      filteredQuestionnaries,
    },
  };
}

function getSearchFilteredQuizSuccess(
  questionnaires: Quiz[]
): Action {
  return {
    type: GET_FILTERED_QUIZ_SUCCESS,
    payload: {
      questionnaires,
    },
  };
}

function getSearchFilteredQuizFailure(): Action {
  return {
    type: GET_FILTERED_QUIZ_FAILURE,
  };
}

function getQuizDetailRequest(quizID: string): Action {
  return {
    type: GET_QUIZ_DETAIL_REQUEST,
    payload: {
      quizID,
    },
  };
}

function getQuizDetailSuccess(quiz: Quiz): Action {
  return {
    type: GET_QUIZ_DETAIL_SUCCESS,
    payload: {
      quiz,
    },
  };
}

function getQuizDetailFailure(): Action {
  return {
    type: GET_QUIZ_DETAIL_FAILURE,
  };
}

function addNewQuizRequest(  
  newQuiz: AddNewQuizData,
  id?: string,
): Action {
  return {
    type: ADD_NEW_QUIZ_REQUEST,
    payload: {      
      newQuiz,
      id,
    },
  };
}

function addNewQuizSuccess(
  QuizAdded: AddNewQuizResponseData
): Action {
  return {
    type: ADD_NEW_QUIZ_SUCCESS,
    payload: {
      QuizAdded,
    },
  };
}

function addNewQuizFailure(): Action {
  return {
    type: ADD_NEW_QUIZ_FAILURE,
  };
}

function editQuizRequest(  
  updatedQuiz: EditQuiz,
  id?: string,
): Action {
  return {
    type: EDIT_QUIZ_REQUEST,
    payload: {      
      updatedQuiz,
      id,
    },
  };
}

function editQuestionAddAlternativeRequest(
  updatedQuiz: EditQuiz
): Action {
  return {
    type: EDIT_QUIZ_ADD_QUESTION_REQUEST,
    payload: {
      updatedQuiz,
    },
  };
}

function editQuizSuccess(): Action {
  return {
    type: EDIT_QUIZ_SUCCESS,
  };
}

function editQuizFailure(): Action {
  return {
    type: EDIT_QUIZ_FAILURE,
  };
}

function activeOrInactiveQuizRequest(
 quizID: string,
  newQuizStatus: boolean
): Action {
  return {
    type: ACTIVE_OR_INACTIVE_QUIZ_REQUEST,
    payload: {
      quizID,
      newQuizStatus,
    },
  };
}

function activeOrInactiveQuizSuccess(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_QUIZ_SUCCESS,
  };
}

function activeOrInactiveQuizFailure(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_QUIZ_FAILURE,
  };
}

function resetTheQuizDetailState(): Action {
  return {
    type: RESET_THE_QUIZ_DETAIL_STATE,
  };
}

function quizQuantityFormQuizRequest(
  quizID: string,
   quantityQuiz: number
 ): Action {
   return {
     type: QUIZ_QUANTITY_REQUEST,
     payload: {
       quizID,
       quantityQuiz,
     },
   };
 }
 
 function quizQuantityFormQuizSuccess(): Action {
   return {
     type: QUIZ_QUANTITY_SUCCESS,
   };
 }
 
 function quizQuantityFormQuizFailure(): Action {
   return {
     type: QUIZ_QUANTITY_FAILURE,
   };
 }

export const questionnariesActionsFunctions = {
  getQuestionnariesModuleRequest,
  getQuestionnariesModuleSuccess,
  getQuestionnariesModuleFailure,
  getSearchFilteredQuizRequest,
  getSearchFilteredQuizSuccess,
  getSearchFilteredQuizFailure,
  getQuizDetailRequest,
  getQuizDetailSuccess,
  getQuizDetailFailure,
  addNewQuizRequest,
  addNewQuizSuccess,
  addNewQuizFailure,
  editQuizRequest,
  editQuizSuccess,
  editQuizFailure,
  activeOrInactiveQuizRequest,
  activeOrInactiveQuizSuccess,
  activeOrInactiveQuizFailure,
  quizQuantityFormQuizRequest,
  quizQuantityFormQuizSuccess,
  quizQuantityFormQuizFailure,
  resetTheQuizDetailState,
};

