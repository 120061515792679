import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import type { Action } from 'store/types';

import { customerActionsFunctions } from './actions';
import type { AddNewCustomerData, ApiFetchCustomerRequestPayloadParams, ApiFetchSearchFilteredCustomersRequestPayloadParams, Classes, customerInfoUpdateStatus, CustomerInterviewees, EditCustomerParamsType, EditCustomerStatus, ZipAdressObject } from './types';
import { Customer, CustomersActions } from './types';



const {
  GET_ALL_CUSTOMERS_REQUEST,
  GET_FILTERED_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_DETAIL_REQUEST,
  GET_CEP_REQUEST,
  EDIT_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_REQUEST,
  GET_CLASSES_REQUEST,
  EDIT_CUSTOMER_STATUS_REQUEST
} = CustomersActions;


const {
  SEARCH_ZIP_CODE_SUCCESS_MESSAGE,
  SEARCH_ZIP_CODE_ERROR_MESSAGE,
  EDIT_CUSTOMER_REQUEST_SUCCESS_MESSAGE,
  EDIT_CUSTOMER_REQUEST_ERROR_MESSAGE,
} = ToastMessages;


const {
  getCustomerSuccess,
  getCustomerFailure,
  getCustomerDetailRequest,
  getCustomerDetailSuccess,
  getCustomerDetailFailure,
  getCepRequestSuccess,
  getCepRequestFailure,
  editCustomerSuccess,
  editCustomerFailure,
  createNewCustomerSuccess,
  createNewCustomerFailure,
  getClassesSuccess,
  getClassesFailure,
  editCustomerStatusSuccess,
  editCustomerStatusFailure,
  getSearchFilteredCustomerSuccess,
  getSearchFilteredCustomerFailure,
} = customerActionsFunctions;

const {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE
} = ToastMessages;

const {
  getLastApiFetchDataSuccess,
  getLastApiFetchDataFailure
} = lastApiFetchDataActionsFunctions;

function* fetchAllCustomers(): Generator {
  try {
    const customerResponse: AxiosResponse<Customer[]> | unknown = yield call(
      api,
      'GET',
      '/customers/customers/',
      {}
    );

    const {
      data: customers,
      config: { url },
      status,
      statusText,
    } = customerResponse as AxiosResponse<Customer[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCustomerSuccess(customers));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomerFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchSearchFilteredCustomer(action: Action): Generator {
  const { 
    filteredCustomers, 
    isActive, 
    customerSector, 
    customerSubSector,
    customerSegment
  } = action.payload as ApiFetchSearchFilteredCustomersRequestPayloadParams;

  const parsedResponses = filteredCustomers.filter(response => response !== null).join(',');
  
  let commandSearch = `/customers/customers/?search=${parsedResponses}`;
  if(isActive !== null)
    commandSearch = commandSearch.concat(`&&is_active=${isActive}`);
  if(customerSector !== null)
    commandSearch = commandSearch.concat(`&&segment__subsector__sector__name=${customerSector}`);
  if(customerSubSector !== null)
    commandSearch = commandSearch.concat(`&&segment__subsector__name=${customerSubSector}`);
  if(customerSegment !== '')
    commandSearch = commandSearch.concat(`&&segment__name=${customerSegment}`);

  try {
    const customerResponse: AxiosResponse<Customer[]> | unknown = yield call(
      api,
      'GET',
      commandSearch,
      {}
    );

    const {
      data: customers,
      config: { url },
      status,
      statusText,
    } = customerResponse as AxiosResponse<Customer[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredCustomerSuccess(customers));
    toast.success('Filtro aplicado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredCustomerFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
        DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchAllClasses(): Generator {
  try {
    const customerResponse: AxiosResponse<Classes> | unknown = yield call(
      api,
      'GET',
      'customers/enums/',
      {}
    );

    const {
      data: classes,
      config: { url },
      status,
      statusText,
    } = customerResponse as AxiosResponse<Classes>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getClassesSuccess(classes));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getClassesFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchCustomerDetail(action: Action): Generator {
  const { customerId } = action.payload as ApiFetchCustomerRequestPayloadParams;
  try {
    const customerResponse: AxiosResponse<CustomerInterviewees> | unknown = yield call(
      api,
      'GET',
      `/customers/customers/${customerId}/detail/`,
      {}
    );

    const {
      data: customersDetail,
      config: { url },
      status,
      statusText,
    } = customerResponse as AxiosResponse<CustomerInterviewees>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCustomerDetailSuccess(customersDetail));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomerDetailFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
        DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchEditCustomerStatus(action: Action): Generator {
  const { customerId, customerStatus } = action.payload as customerInfoUpdateStatus;
  try {
    const editCustomerStatus: AxiosResponse<EditCustomerStatus> | unknown = yield call(
      api,
      'PUT',
      `/customers/customers/${customerId}/update-status/`,
      {
        is_active: customerStatus
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = editCustomerStatus as AxiosResponse<EditCustomerStatus>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editCustomerStatusSuccess());
    toast.success('Status Editado com Sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editCustomerStatusFailure());

    toast.error('Falha ao editar status.');
  }
}

function* fetchCustomerZipCode(action: Action): Generator {
  const { cep } = action.payload as ApiFetchCustomerRequestPayloadParams;
  try {
    const customerResponse: AxiosResponse<ZipAdressObject> | unknown = yield call(
      api,
      'GET',
      `/customers/customers/cep/${cep}/`,
      {}
    );

    const {
      data: cepInfos,
      config: { url },
      status,
      statusText,
    } = customerResponse as AxiosResponse<ZipAdressObject>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCepRequestSuccess(cepInfos));
    toast.success(SEARCH_ZIP_CODE_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCepRequestFailure());

    toast.error(SEARCH_ZIP_CODE_ERROR_MESSAGE);
  }
}

function* EditCustomerInformations(action: Action): Generator {
  const { customerId, customerData } = action.payload as EditCustomerParamsType;
  try {
    const customerResponse: AxiosResponse<AddNewCustomerData> | unknown = yield call(
      api,
      'PUT',
      `/customers/customers/${customerId}/`,
      { ...customerData }
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerResponse as AxiosResponse<AddNewCustomerData>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editCustomerSuccess());
    yield put(getCustomerDetailRequest(customerId));

    toast.success(EDIT_CUSTOMER_REQUEST_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editCustomerFailure());

    toast.success(EDIT_CUSTOMER_REQUEST_ERROR_MESSAGE);
  }
}

function* fetchCreateNewCustomer(action: Action): Generator {
  const { customerData } = action.payload as ApiFetchCustomerRequestPayloadParams;

  try {
    const customerResponse: AxiosResponse<Customer> | unknown = yield call(
      api,
      'POST',
      '/customers/customers/create/',
      { ...customerData }
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerResponse as AxiosResponse<Customer>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(createNewCustomerSuccess());
    toast.success('Cliente cadastrado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(createNewCustomerFailure());

    toast.error('Falha ao cadastrar cliente.');
  }
}

export function* customerSagas() {
  yield all([
    takeLatest(GET_ALL_CUSTOMERS_REQUEST, fetchAllCustomers),
    takeLatest(GET_CUSTOMERS_DETAIL_REQUEST, fetchCustomerDetail),
    takeLatest(GET_CEP_REQUEST, fetchCustomerZipCode),
    takeLatest(EDIT_CUSTOMER_REQUEST, EditCustomerInformations),
    takeLatest(CREATE_CUSTOMER_REQUEST, fetchCreateNewCustomer),
    takeLatest(GET_CLASSES_REQUEST, fetchAllClasses),
    takeLatest(EDIT_CUSTOMER_STATUS_REQUEST, fetchEditCustomerStatus),
    takeLatest(GET_FILTERED_CUSTOMERS_REQUEST, fetchSearchFilteredCustomer),
  ]);
}
