export enum StandardsActions {
  EDIT_STANDARDS_REQUEST = 'EDIT_STANDARDS_REQUEST',
  EDIT_STANDARDS_SUCCESS = 'EDIT_STANDARDS_SUCCESS',
  EDIT_STANDARDS_FAILURE = 'EDIT_STANDARDS_FAILURE',
  GET_ALL_STANDARDS_REQUEST = 'GET_ALL_STANDARDS_REQUEST',
  GET_ALL_STANDARDS_SUCCESS = 'GET_ALL_STANDARDS_SUCCESS',
  GET_ALL_STANDARDS_FAILURE = 'GET_ALL_STANDARDS_FAILURE',
  ADD_NEW_STANDARDS_REQUEST = 'ADD_NEW_STANDARDS_REQUEST',
  ADD_NEW_STANDARDS_SUCCESS = 'ADD_NEW_STANDARDS_SUCCESS',
  ADD_NEW_STANDARDS_FAILURE = 'ADD_NEW_STANDARDS_FAILURE',
  GET_FILTERED_STANDARDS_REQUEST = 'GET_FILTERED_STANDARDS_REQUEST',
  GET_FILTERED_STANDARDS_SUCCESS = 'GET_FILTERED_STANDARDS_SUCCESS',
  GET_FILTERED_STANDARDS_FAILURE = 'GET_FILTERED_STANDARDS_FAILURE',
  ACTIVE_OR_INACTIVE_STANDARDS_REQUEST = 'ACTIVE_OR_INACTIVE_STANDARDS_REQUEST',
  ACTIVE_OR_INACTIVE_STANDARDS_SUCCESS = 'ACTIVE_OR_INACTIVE_STANDARDS_SUCCESS',
  ACTIVE_OR_INACTIVE_STANDARDS_FAILURE = 'ACTIVE_OR_INACTIVE_STANDARDS_FAILURE',
  EDIT_STANDARDS_DETAILS_REQUEST = 'EDIT_STANDARDS_DETAILS_REQUEST',
  EDIT_STANDARDS_DETAILS_SUCCESS = 'EDIT_STANDARDS_DETAILS_SUCCESS',
  EDIT_STANDARDS_DETAILS_FAILURE = 'EDIT_STANDARDS_DETAILS_FAILURE',
  GET_STANDARDS_DETAILS_REQUEST = 'GET_STANDARDS_DETAILS_REQUEST',
  GET_STANDARDS_DETAILS_SUCCESS = 'GET_STANDARDS_DETAILS_SUCCESS',
  GET_STANDARDS_DETAILS_FAILURE = 'GET_STANDARDS_DETAILS_FAILURE',
  ADD_NEW_STANDARDS_DETAILS_REQUEST = 'ADD_NEW_STANDARDS_DETAILS_REQUEST',
  ADD_NEW_STANDARDS_DETAILS_SUCCESS = 'ADD_NEW_STANDARDS_DETAILS_SUCCESS',
  ADD_NEW_STANDARDS_DETAILS_FAILURE = 'ADD_NEW_STANDARDS_DETAILS_FAILURE',
  GET_STANDARDS_DETAIL_REQUEST = 'GET_STANDARDS_DETAIL_REQUEST',
  GET_STANDARDS_DETAIL_SUCCESS = 'GET_STANDARDS_DETAIL_SUCCESS',
  GET_STANDARDS_DETAIL_FAILURE = 'GET_STANDARDS_DETAIL_FAILURE',
  ACTIVE_OR_INACTIVE_STANDARDS_DETAILS_REQUEST = 'ACTIVE_OR_INACTIVE_STANDARDS_DETAILS_REQUEST',
  ACTIVE_OR_INACTIVE_STANDARDS_DETAILS_SUCCESS = 'ACTIVE_OR_INACTIVE_STANDARDS_DETAILS_SUCCESS',
  ACTIVE_OR_INACTIVE_STANDARDS_DETAILS_FAILURE = 'ACTIVE_OR_INACTIVE_STANDARDS_DETAILS_FAILURE',
  CHANGE_STANDARDS_REQUEST = 'CHANGE_STANDARDS_REQUEST',
  CHANGE_STANDARDS_SUCCESS = 'CHANGE_STANDARDS_SUCCESS',
  CHANGE_STANDARDS_FAILURE = 'CHANGE_STANDARDS_FAILURE',
}

export type Standard = {
  id: string;
  name: string;
  version: string;
  approved: boolean;
  approval_date: string;
  publication_date: string;
  is_active: boolean;
}

export type StandardsItems = {
  id: string;
  name: string;
  is_active: boolean;
  friendly_id: string;
  category: string;
  subcategory: string;
}

export type StandardsDetails = {
  id: string;
  name: string;
  version: string;
  approved: boolean;
  approval_date: string;
  publication_date: string;
  is_active: boolean;
  items: StandardsItems[];
}

export type AllStandards = {
  count: number | null;
  next: number | null;
  previous: number | null;
  results: Standard[];
}

export type StandardState = {
  allStandards: AllStandards;
  standardsDetails: StandardsDetails;
};

export type UpdateStatusRequestPayloadParams = {
  id: string;
  is_active: boolean;
}

export type UpdateStatusRequestItemsPayloadParams = {
  id: string;
  standard: string;
  is_active: boolean;
}

export type AddNewStandardRequestPayload = {
  name: string;
  version: string;
  approved: boolean;
  approval_date: string;
  publication_date: string;
  is_active: boolean;
}

export type AddNewStandardDetailsRequestPayload = {
  name: string;
  friendly_id: string;
  category: string;
  subcategory: string;
  standardId: string;
}

export type StandardEditPayload = {
  id: string;
  name: string;
  version: string;
  approved: boolean;
  approval_date: string;
  publication_date: string;
  is_active: boolean;
}

export type StandardDetailsEditPayload = {
  id: string;
  name: string;
  friendly_id: string;
  category: string;
  subcategory: string;
  standardId: string;
}

export type SearchFilteredStandardsRequestPayloadParams = {
  name: string;
  is_active: boolean | null;
}

export type StandardsDetailsRequestPayload = {
  id: string;
}

export type UpdateApproveRequestPayload = {
  id: string;
  approved: boolean;
}