export enum OpenedModalActions {
  SET_OPENED_MODAL = 'SET_OPENED_MODAL',
  RESET_THE_OPENED_MODAL = 'RESET_THE_OPENED_MODAL',
}

export type OpenedModal =
  | 'addNewUserOpenedModal'
  | 'changePasswordOpenedModal'
  | 'forgotPasswordOpenedModal'
  | 'riskOpenedModal';

export type OpenedModalState = {
  whichIsTheOpenedModal: OpenedModal | null;
};
