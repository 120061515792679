import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { api } from 'services/api';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import type { Action } from 'store/types';
import { simpleDateFormatting } from 'utils/functions';
import { checkPage } from 'utils/number';

import { customerProjectActionsFunctions } from '../customerProject/actions';
import { CustomerProjetcIdApiPayload } from '../customerProjectRisk/types';
import { customerProjectReportActionsFunctions } from './actions';
import {
  ApproveGeneratedApiPayload,
  CancelDownloadReportIdApiPayload,
  CustomerProjectReportActions,
  DownloadReportIdApiPayload,
  EditReporCustomPdfPayload,
  EditReportBody,
  EditReportTextApiActionPayload,
  Report,
  ReportsResults
} from './types';

const {
  DEFAULT_ERROR_MESSAGE
} = ToastMessages;

const {
  getLastApiFetchDataSuccess,
  getLastApiFetchDataFailure
} = lastApiFetchDataActionsFunctions;

const {
  GET_ALL_REPORTS_CUSTOMER_PROJECT_REQUEST,
  EDIT_REPORTS_CUSTOMER_PROJECT_REQUEST,
  EDIT_REPORTS_CUSTOM_PDF_REQUEST,
  EDIT_REPORTS_CUSTOMER_PROJECT_SCOPE_REQUEST,
  GET_REPORTS_PDF_CUSTOMER_PROJECT_REQUEST,
  GET_PDF_SEND_BY_ANALYST_REQUEST,
  GET_REPORT_DOC_REQUEST,
  CREATE_A_REPORT_REQUEST,
  GET_ALL_REPORTS_REQUEST,
  DOWNLOAD_REPORTS_PDF_CUSTOMER_PROJECT_REQUEST,
  CANCEL_DOWNLOAD_REPORTS_PDF_REQUEST,
  APPROVE_GENERATED_FILE_REQUEST,
  CREATE_EXCEL_FILE_REQUEST,
} = CustomerProjectReportActions;

const {
  getCustomerProjectTextsRequest,
  getCustomerProjectReportsProjectRequest
} = customerProjectActionsFunctions;

const {
  getCustomerProjectReportsSuccess,
  getCustomerProjectReportsFailure,
  editCustomerProjectReportsSuccess,
  editCustomerProjectReportsFailure,
  editReportsCustomPDFuccess,
  editReportsCustomPDFFailure,
  editCustomerProjectReportScopeSuccess,
  editCustomerProjectReportScopeFailure,
  getCustomerProjectReportPDFSuccess,
  getCustomerProjectReportPDFFailure,
  getCustomerProjectReportPdfSendByAnalystSuccess,
  getCustomerProjectReportPdfSendByAnalystFailure,
  getCustomerProjectReportDocSuccess,
  getCustomerProjectReportDocFailure,
  getNewCustomerProjectReportsSuccess,
  getNewCustomerProjectReportsFailure,
  getReportsModuleSuccess,
  getReportsModuleFailure,
  downloadCustomerProjectReportFileSuccess,
  downloadCustomerProjectReportFileFailure,
  getReportsModuleRequest,
  cancelDownloadReportPDFSuccess,
  cancelDownloadReportPDFFailure,
  approveGeneratedFileSuccess,
  approveGeneratedFileFailure,
  getCreateExcelFileSuccess,
  getCreateExcelFileFailure,
} = customerProjectReportActionsFunctions;

function* fetchAllReportsCustomerProject(action: Action): Generator {
  const { customerProjectId } = action.payload as CustomerProjetcIdApiPayload;
  try {
    const customerProjectResponse: AxiosResponse<any> | unknown = yield call(
      api,
      'GET',
      `/controls/customer-project/${customerProjectId}/generate-pdf/`,
      {},
      'application/pdf',
      'blob'
    );

    const {
      data: customersProjectsReports,
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<any>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCustomerProjectReportsSuccess(customersProjectsReports));

    const fileURL = URL.createObjectURL(new Blob([customersProjectsReports], { type: 'application/pdf' }));
    const pdfWindow = window.open();
    if (pdfWindow) {
      pdfWindow.location.href = fileURL;
      toast.success('PDF gerado com sucesso.');
    }

    yield put(getCustomerProjectReportsProjectRequest(customerProjectId));
    toast.success('PDF gerado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomerProjectReportsFailure());

    toast.error('Erro ao gerar PDF.');
  }
}

function* fetchReportPDFRequest(action: Action): Generator {
  const { customerProjectId } = action.payload as CustomerProjetcIdApiPayload;
  try {
    const customerProjectResponse: AxiosResponse<any> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${customerProjectId}/report-pdf/`,
      {},
      'application/pdf',
      'blob'
    );

    const {
      data: customersProjectsReports,
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<any>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    const fileURL = URL.createObjectURL(new Blob([customersProjectsReports], { type: 'application/pdf' }));
    const pdfWindow = window.open();
    if (pdfWindow) {
      pdfWindow.location.href = fileURL;
      toast.success('PDF gerado com sucesso.');
    }

    yield put(getCustomerProjectReportPDFSuccess());


  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomerProjectReportPDFFailure());

    toast.error('Erro ao gerar PDF.');
  }
}

function* fetchReportPDFSendByAnalystRequest(action: Action): Generator {
  const { customerProjectId } = action.payload as CustomerProjetcIdApiPayload;
  try {
    const customerProjectResponse: AxiosResponse<any> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${customerProjectId}/report-pdf-send-by-analyst/`,
      {},
      'application/pdf',
      'blob'
    );

    const {
      data: customersProjectsReports,
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<any>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    const fileURL = URL.createObjectURL(new Blob([customersProjectsReports], { type: 'application/pdf' }));
    const pdfWindow = window.open();
    if (pdfWindow) {
      pdfWindow.location.href = fileURL;
      toast.success('PDF gerado com sucesso.');
    }

    yield put(getCustomerProjectReportPdfSendByAnalystSuccess());


  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomerProjectReportPdfSendByAnalystFailure());

    toast.error('Erro ao gerar PDF.');
  }
}

function* fetchReportDocxRequest(action: Action): Generator {
  const { customerProjectId } = action.payload as CustomerProjetcIdApiPayload;
  try {
    const customerProjectResponse: AxiosResponse<any> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${customerProjectId}/report-docx/`,
      {},
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'blob'
    );

    const {
      data: customersProjectsReports,
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<any>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    const fileURL = URL.createObjectURL(new Blob([customersProjectsReports], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }));
    const pdfWindow = window.open();
    if (pdfWindow) {
      pdfWindow.location.href = fileURL;
      toast.success('DOC gerado com sucesso.');
    }

    yield put(getCustomerProjectReportDocSuccess());


  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomerProjectReportDocFailure());

    toast.error('Erro ao gerar o DOC.');
  }
}

function* editReportsCustomerProject(action: Action): Generator {
  const { customerProjectId, editReportBody } = action.payload as EditReportTextApiActionPayload;
  try {
    const customerProjectResponse: AxiosResponse<EditReportBody> | unknown = yield call(
      api,
      'PUT',
      `/controls/customers-projects/${customerProjectId}/update-text/`,
      { ...editReportBody },
    );

    const {
      data,
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<EditReportBody>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editCustomerProjectReportsSuccess());

    yield put(getCustomerProjectTextsRequest(customerProjectId));

    toast.success('Texto editado com sucesso.');



  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editCustomerProjectReportsFailure());

    toast.error('Erro ao editar texto.');
  }
}

function* editCustomReportsPdfRequest(action: Action): Generator {
  const { customerProjectId, editReportCustomPDFBody } = action.payload as EditReporCustomPdfPayload;

  const formData = new FormData();

  editReportCustomPDFBody.forEach(element => {
    formData.append('file', element as unknown as Blob, element.name);
  });

  try {
    const customerProjectResponse: AxiosResponse<EditReportBody> | unknown = yield call(
      api,
      'PUT',
      `/controls/customers-projects/${customerProjectId}/send-custom-pdf-report/`,
      formData,
      'multipart/form-data'
    );

    const {
      data,
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<EditReportBody>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editReportsCustomPDFuccess());

    yield put(getCustomerProjectReportsProjectRequest(customerProjectId));

    toast.success('Texto editado com sucesso.');

  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editReportsCustomPDFFailure());

    toast.error('Erro ao editar texto.');
  }
}

function* editReportsCustomerProjectScope(action: Action): Generator {
  const { customerProjectId, editReportBody } = action.payload as EditReportTextApiActionPayload;
  try {
    const customerProjectResponse: AxiosResponse<EditReportBody> | unknown = yield call(
      api,
      'PUT',
      `/controls/customers-projects/${customerProjectId}/update-project-scope/`,
      { ...editReportBody },
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<EditReportBody>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editCustomerProjectReportScopeSuccess());

    yield put(getCustomerProjectTextsRequest(customerProjectId));

    toast.success('Texto editado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editCustomerProjectReportScopeFailure());

    toast.error('Erro ao editar texto.');
  }
}

function* fetchNewReportsCustomerProject(action: Action): Generator {
  const { customerProjectId } = action.payload as CustomerProjetcIdApiPayload;
  try {
    const customerProjectResponse: AxiosResponse<Report> | unknown = yield call(
      api,
      'GET',
      `/controls/customer-project/${customerProjectId}/generate-report-pdf/`,
      {}
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<Report>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getNewCustomerProjectReportsSuccess());
    yield put(getReportsModuleRequest(customerProjectId));

    toast.success('PDF gerado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getNewCustomerProjectReportsFailure());

    if (currentError.response?.status !== 504) {
      if (currentError.response?.data.msg === 'There is already a report being generated in the queue') {
        toast.warn('Já existe relatório gerado.');
      } else {
        toast.error('Erro ao gerar PDF.');
      }
    }
  }
}

function* fetchAllReportsModule(action: Action): Generator {
  const { customerProjectId, isCustomer } = action.payload as CustomerProjetcIdApiPayload;
  try {
    const { payload } = action as any;
    const total: number = (yield select(state => state.activities.total)) as number;
    const last_page = checkPage(payload.last_page, total);
    const path = isCustomer ? 
      `/controls/customers-projects/${customerProjectId}/reports-approved/?page=${last_page}` : 
      `/controls/customers-projects/${customerProjectId}/reports-customer-project/?page=${last_page}`;
    const has_next = yield select(state => state.activities.has_next);

    if (has_next || last_page === 1) {
      const reportsResponse: AxiosResponse<ReportsResults> | unknown = yield call(api, 'GET', path, {});

      const {
        data: { reports, count, next },
        config: { url },
        status,
        statusText,
      } = reportsResponse as AxiosResponse<ReportsResults>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      yield put(getReportsModuleSuccess(reports, count, last_page, !!next));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getReportsModuleFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
      DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchDownloadReportFileRequest(action: Action): Generator {
  const { reportId, type, name } = action.payload as DownloadReportIdApiPayload;
  if (type === 'PDF') {
    try {
      const downloadReportResponse: AxiosResponse<any> | unknown = yield call(
        api,
        'GET',
        `/controls/report-customer-project/${reportId}/download/`,
        {},
        'application/pdf',
        'blob'
      );

      const {
        data: downloadReport,
        config: { url },
        status,
        statusText,
      } = downloadReportResponse as AxiosResponse<any>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      const fileURL = URL.createObjectURL(new Blob([downloadReport], { type: 'application/pdf' }));
      const pdfWindow = window.open();

      if (pdfWindow) {
        pdfWindow.location.href = fileURL;
        toast.success('PDF gerado com sucesso.');
      }

      yield put(downloadCustomerProjectReportFileSuccess());
    } catch (error) {
      const currentError = error as AxiosError;

      yield put(getLastApiFetchDataFailure(currentError));
      yield put(downloadCustomerProjectReportFileFailure());

      toast.error('Erro ao gerar PDF.');
    }
  } else {
    try {
      const downloadReportResponse: AxiosResponse<any> | unknown = yield call(
        api,
        'GET',
        `/controls/report-customer-project/${reportId}/download/`,
        {},
        'application/ms-excel',
        'blob'
      );

      const {
        data: downloadReport,
        config: { url },
        status,
        statusText,
      } = downloadReportResponse as AxiosResponse<any>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      const dataAtual = Date.now();
      const formatedDate = simpleDateFormatting(dataAtual);
      const outputFilename = `Matriz_de_risco_${name}_${formatedDate}.xls`;

      const fileURL = URL.createObjectURL(new Blob([downloadReport], { type: 'application/ms-excel' }));

      const link = document.createElement('a');
      link.href = fileURL;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();

      toast.success('Matriz de risco exportada com sucesso.');
      yield put(downloadCustomerProjectReportFileSuccess());
    } catch (error) {
      const currentError = error as AxiosError;

      yield put(getLastApiFetchDataFailure(currentError));
      yield put(downloadCustomerProjectReportFileFailure());

      toast.error('Falha ao exportar matriz de risco.');
    }
  }
}

function* fetchCancelDownloadReportPDFRequest(action: Action): Generator {
  const { reportId, customerProjectId } = action.payload as CancelDownloadReportIdApiPayload;
  try {
    const downloadReportResponse: AxiosResponse<any> | unknown = yield call(
      api,
      'PUT',
      `/controls/report-customer-project/${reportId}/canceled/`,
      {},
    );

    const {
      config: { url },
      status,
      statusText,
    } = downloadReportResponse as AxiosResponse<any>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    if (status === 200) {
      yield put(cancelDownloadReportPDFSuccess());
      toast.success('Arquivo cancelado com sucesso.');
    }

    yield put(getReportsModuleRequest(customerProjectId));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(cancelDownloadReportPDFFailure());

    toast.error('Erro ao cancelar arquivo.');
  }
}

function* fetchApproveGeneratedFileRequest(action: Action): Generator {
  const { reportId, customerProjectId, approved } = action.payload as ApproveGeneratedApiPayload;
  try {
    const downloadReportResponse: AxiosResponse<any> | unknown = yield call(
      api,
      'PUT',
      `/controls/report-customer-project/${reportId}/approved/`,
      { approved },
    );

    const {
      config: { url },
      status,
      statusText,
    } = downloadReportResponse as AxiosResponse<any>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    if (status === 200) {
      yield put(approveGeneratedFileSuccess());
      toast.success('Arquivo aprovado com sucesso.');
    }

    yield put(getReportsModuleRequest(customerProjectId));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(approveGeneratedFileFailure());

    toast.error('Erro ao aprovar o arquivo.');
  }
}

function* fetchCreateExcelFileReport(action: Action): Generator {
  const { customerProjectId } = action.payload as CustomerProjetcIdApiPayload;
  try {
    const customerProjectResponse: AxiosResponse<any> | unknown = yield call(
      api,
      'GET',
      `/controls/customer-project/${customerProjectId}/generate-report-excel/`,
      {}
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<any>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCreateExcelFileSuccess());
    yield put(getReportsModuleRequest(customerProjectId));

    toast.success('Arquivo em Excel gerado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCreateExcelFileFailure());

    if (currentError.response?.status !== 504) {
      if (currentError.response?.data.msg === 'There is already a report being generated in the queue') {
        toast.warn('Já existe relatório gerado.');
      } else {
        toast.error('Erro ao gerar arquivo em Excel.');
      }
    }
  }
}

export function* customerProjectReportsSagas() {
  yield all([
    takeLatest(GET_ALL_REPORTS_CUSTOMER_PROJECT_REQUEST, fetchAllReportsCustomerProject),
    takeLatest(EDIT_REPORTS_CUSTOMER_PROJECT_REQUEST, editReportsCustomerProject),
    takeLatest(EDIT_REPORTS_CUSTOM_PDF_REQUEST, editCustomReportsPdfRequest),
    takeLatest(EDIT_REPORTS_CUSTOMER_PROJECT_SCOPE_REQUEST, editReportsCustomerProjectScope),
    takeLatest(GET_REPORTS_PDF_CUSTOMER_PROJECT_REQUEST, fetchReportPDFRequest),
    takeLatest(GET_PDF_SEND_BY_ANALYST_REQUEST, fetchReportPDFSendByAnalystRequest),
    takeLatest(GET_REPORT_DOC_REQUEST, fetchReportDocxRequest),
    takeLatest(CREATE_A_REPORT_REQUEST, fetchNewReportsCustomerProject),
    takeLatest(GET_ALL_REPORTS_REQUEST, fetchAllReportsModule),
    takeLatest(DOWNLOAD_REPORTS_PDF_CUSTOMER_PROJECT_REQUEST, fetchDownloadReportFileRequest),
    takeLatest(CANCEL_DOWNLOAD_REPORTS_PDF_REQUEST, fetchCancelDownloadReportPDFRequest),
    takeLatest(APPROVE_GENERATED_FILE_REQUEST, fetchApproveGeneratedFileRequest),
    takeLatest(CREATE_EXCEL_FILE_REQUEST, fetchCreateExcelFileReport),
  ]);
}
