import type { Action } from 'store/types';

import {
  CustomerProjectReportActions,
  EditReportBody,
  Report
} from './types';

const {
  GET_ALL_REPORTS_CUSTOMER_PROJECT_REQUEST,
  GET_ALL_REPORTS_CUSTOMER_PROJECT_SUCCESS,
  GET_ALL_REPORTS_CUSTOMER_PROJECT_FAILURE,
  EDIT_REPORTS_CUSTOMER_PROJECT_REQUEST,
  EDIT_REPORTS_CUSTOMER_PROJECT_SUCCESS,
  EDIT_REPORTS_CUSTOMER_PROJECT_FAILURE,
  EDIT_REPORTS_CUSTOM_PDF_REQUEST,
  EDIT_REPORTS_CUSTOM_PDF_SUCCESS,
  EDIT_REPORTS_CUSTOM_PDF_FAILURE,
  EDIT_REPORTS_CUSTOMER_PROJECT_SCOPE_REQUEST,
  EDIT_REPORTS_CUSTOMER_PROJECT_SCOPE_SUCCESS,
  EDIT_REPORTS_CUSTOMER_PROJECT_SCOPE_FAILURE,
  GET_REPORTS_PDF_CUSTOMER_PROJECT_REQUEST,
  GET_REPORTS_PDF_CUSTOMER_PROJECT_SUCCESS,
  GET_REPORTS_PDF_CUSTOMER_PROJECT_FAILURE,
  GET_PDF_SEND_BY_ANALYST_REQUEST,
  GET_PDF_SEND_BY_ANALYST_SUCCESS,
  GET_PDF_SEND_BY_ANALYST_FAILURE,
  GET_REPORT_DOC_REQUEST,
  GET_REPORT_DOC_SUCCESS,
  GET_REPORT_DOC_FAILURE,
  CREATE_A_REPORT_REQUEST,
  CREATE_A_REPORT_SUCCESS,
  CREATE_A_REPORT_FAILURE,
  GET_ALL_REPORTS_REQUEST,
  GET_ALL_REPORTS_SUCCESS,
  GET_ALL_REPORTS_FAILURE,
  DOWNLOAD_REPORTS_PDF_CUSTOMER_PROJECT_REQUEST,
  DOWNLOAD_REPORTS_PDF_CUSTOMER_PROJECT_SUCCESS,
  DOWNLOAD_REPORTS_PDF_CUSTOMER_PROJECT_FAILURE,
  CANCEL_DOWNLOAD_REPORTS_PDF_REQUEST,
  CANCEL_DOWNLOAD_REPORTS_PDF_SUCCESS,
  CANCEL_DOWNLOAD_REPORTS_PDF_FAILURE,
  APPROVE_GENERATED_FILE_REQUEST,
  APPROVE_GENERATED_FILE_SUCCESS,
  APPROVE_GENERATED_FILE_FAILURE,
  CREATE_EXCEL_FILE_REQUEST,
  CREATE_EXCEL_FILE_SUCCESS,
  CREATE_EXCEL_FILE_FAILURE,
} = CustomerProjectReportActions;

function getCustomerProjectReportsRequest(customerProjectId: string): Action {
  return {
    type: GET_ALL_REPORTS_CUSTOMER_PROJECT_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function getCustomerProjectReportsSuccess(allReports: any): Action {
  return {
    type: GET_ALL_REPORTS_CUSTOMER_PROJECT_SUCCESS,
    payload: {
      allReports
    }
  };
}

function getCustomerProjectReportsFailure(): Action {
  return {
    type: GET_ALL_REPORTS_CUSTOMER_PROJECT_FAILURE,
  };
}

function getCustomerProjectReportPDFRequest(customerProjectId: string): Action {
  return {
    type: GET_REPORTS_PDF_CUSTOMER_PROJECT_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function getCustomerProjectReportPDFSuccess(): Action {
  return {
    type: GET_REPORTS_PDF_CUSTOMER_PROJECT_SUCCESS,
  };
}

function getCustomerProjectReportPDFFailure(): Action {
  return {
    type: GET_REPORTS_PDF_CUSTOMER_PROJECT_FAILURE,
  };
}

function getCustomerProjectReportPdfSendByAnalystRequest(customerProjectId: string): Action {
  return {
    type: GET_PDF_SEND_BY_ANALYST_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function getCustomerProjectReportPdfSendByAnalystSuccess(): Action {
  return {
    type: GET_PDF_SEND_BY_ANALYST_SUCCESS,
  };
}

function getCustomerProjectReportPdfSendByAnalystFailure(): Action {
  return {
    type: GET_PDF_SEND_BY_ANALYST_FAILURE,
  };
}

function getCustomerProjectReportDocRequest(customerProjectId: string): Action {
  return {
    type: GET_REPORT_DOC_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function getCustomerProjectReportDocSuccess(): Action {
  return {
    type: GET_REPORT_DOC_SUCCESS,
  };
}

function getCustomerProjectReportDocFailure(): Action {
  return {
    type: GET_REPORT_DOC_FAILURE,
  };
}

function editCustomerProjectReportsRequest(customerProjectId: string, editReportBody: EditReportBody): Action {
  return {
    type: EDIT_REPORTS_CUSTOMER_PROJECT_REQUEST,
    payload: {
      customerProjectId,
      editReportBody
    }
  };
}

function editCustomerProjectReportsSuccess(): Action {
  return {
    type: EDIT_REPORTS_CUSTOMER_PROJECT_SUCCESS,
  };
}

function editCustomerProjectReportsFailure(): Action {
  return {
    type: EDIT_REPORTS_CUSTOMER_PROJECT_FAILURE,
  };
}

function editReportsCustomPDFRequest(customerProjectId: string, editReportCustomPDFBody: any[]): Action {
  return {
    type: EDIT_REPORTS_CUSTOM_PDF_REQUEST,
    payload: {
      customerProjectId,
      editReportCustomPDFBody
    }
  };
}

function editReportsCustomPDFuccess(): Action {
  return {
    type: EDIT_REPORTS_CUSTOM_PDF_SUCCESS,
  };
}

function editReportsCustomPDFFailure(): Action {
  return {
    type: EDIT_REPORTS_CUSTOM_PDF_FAILURE,
  };
}

function editCustomerProjectReportScopeRequest(customerProjectId: string, editReportBody: EditReportBody): Action {
  return {
    type: EDIT_REPORTS_CUSTOMER_PROJECT_SCOPE_REQUEST,
    payload: {
      customerProjectId,
      editReportBody
    }
  };
}

function editCustomerProjectReportScopeSuccess(): Action {
  return {
    type: EDIT_REPORTS_CUSTOMER_PROJECT_SCOPE_SUCCESS,
  };
}

function editCustomerProjectReportScopeFailure(): Action {
  return {
    type: EDIT_REPORTS_CUSTOMER_PROJECT_SCOPE_FAILURE,
  };
}

function getNewCustomerProjectReportsRequest(customerProjectId: string): Action {
  return {
    type: CREATE_A_REPORT_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function getNewCustomerProjectReportsSuccess(): Action {
  return {
    type: CREATE_A_REPORT_SUCCESS,
  };
}

function getNewCustomerProjectReportsFailure(): Action {
  return {
    type: CREATE_A_REPORT_FAILURE,
  };
}

function getReportsModuleRequest(customerProjectId?: string, last_page?: number | null, isCustomer?: boolean | null): Action {
  return {
    type: GET_ALL_REPORTS_REQUEST,
    payload: {
      customerProjectId,
      last_page,
      isCustomer,
    },
  };
}

function getReportsModuleSuccess(
  reports: Report[],
  total: number,
  last_page: number,
  has_next: boolean
): Action {
  return {
    type: GET_ALL_REPORTS_SUCCESS,
    payload: {
      reports,
      total,
      last_page,
      has_next
    },
  };
}

function getReportsModuleFailure(): Action {
  return {
    type: GET_ALL_REPORTS_FAILURE,
  };
}

function downloadCustomerProjectReportFileRequest(
  reportId: string,
  type: string,
  name?: string
): Action {
  return {
    type: DOWNLOAD_REPORTS_PDF_CUSTOMER_PROJECT_REQUEST,
    payload: {
      reportId,
      type,
      name
    }
  };
}

function downloadCustomerProjectReportFileSuccess(): Action {
  return {
    type: DOWNLOAD_REPORTS_PDF_CUSTOMER_PROJECT_SUCCESS,
  };
}

function downloadCustomerProjectReportFileFailure(): Action {
  return {
    type: DOWNLOAD_REPORTS_PDF_CUSTOMER_PROJECT_FAILURE,
  };
}

function cancelDownloadReportPDFRequest(
  reportId: string,
  customerProjectId?: string | undefined
): Action {
  return {
    type: CANCEL_DOWNLOAD_REPORTS_PDF_REQUEST,
    payload: {
      reportId,
      customerProjectId
    }
  };
}

function cancelDownloadReportPDFSuccess(): Action {
  return {
    type: CANCEL_DOWNLOAD_REPORTS_PDF_SUCCESS,
  };
}

function cancelDownloadReportPDFFailure(): Action {
  return {
    type: CANCEL_DOWNLOAD_REPORTS_PDF_FAILURE,
  };
}

function approveGeneratedFileRequest(
  reportId: string,
  customerProjectId?: string | undefined,
  approved?: boolean
): Action {
  return {
    type: APPROVE_GENERATED_FILE_REQUEST,
    payload: {
      reportId,
      customerProjectId,
      approved,
    }
  };
}

function approveGeneratedFileSuccess(): Action {
  return {
    type: APPROVE_GENERATED_FILE_SUCCESS,
  };
}

function approveGeneratedFileFailure(): Action {
  return {
    type: APPROVE_GENERATED_FILE_FAILURE,
  };
}

function getCreateExcelFileRequest(customerProjectId: string): Action {
  return {
    type: CREATE_EXCEL_FILE_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function getCreateExcelFileSuccess(): Action {
  return {
    type: CREATE_EXCEL_FILE_SUCCESS,
  };
}

function getCreateExcelFileFailure(): Action {
  return {
    type: CREATE_EXCEL_FILE_FAILURE,
  };
}

export const customerProjectReportActionsFunctions = {
  editReportsCustomPDFRequest,
  editReportsCustomPDFuccess,
  editReportsCustomPDFFailure,
  getCustomerProjectReportsRequest,
  getCustomerProjectReportsSuccess,
  getCustomerProjectReportsFailure,
  editCustomerProjectReportsRequest,
  editCustomerProjectReportsSuccess,
  editCustomerProjectReportsFailure,
  editCustomerProjectReportScopeRequest,
  editCustomerProjectReportScopeSuccess,
  editCustomerProjectReportScopeFailure,
  getCustomerProjectReportPDFRequest,
  getCustomerProjectReportPDFSuccess,
  getCustomerProjectReportPDFFailure,
  getCustomerProjectReportPdfSendByAnalystRequest,
  getCustomerProjectReportPdfSendByAnalystSuccess,
  getCustomerProjectReportPdfSendByAnalystFailure,
  getCustomerProjectReportDocRequest,
  getCustomerProjectReportDocSuccess,
  getCustomerProjectReportDocFailure,
  getNewCustomerProjectReportsRequest,
  getNewCustomerProjectReportsSuccess,
  getNewCustomerProjectReportsFailure,
  getReportsModuleRequest,
  getReportsModuleSuccess,
  getReportsModuleFailure,
  downloadCustomerProjectReportFileRequest,
  downloadCustomerProjectReportFileSuccess,
  downloadCustomerProjectReportFileFailure,
  cancelDownloadReportPDFRequest,
  cancelDownloadReportPDFSuccess,
  cancelDownloadReportPDFFailure,
  approveGeneratedFileRequest,
  approveGeneratedFileSuccess,
  approveGeneratedFileFailure,
  getCreateExcelFileRequest,
  getCreateExcelFileSuccess,
  getCreateExcelFileFailure,
};
