/* eslint-disable no-case-declarations */
import produce from 'immer';
import { Reducer } from 'redux';

import type { ChecklistsState } from './types';
import { ChecklistsActions } from './types';

const {
  ACTIVE_OR_INACTIVE_CHECKLIST_FAILURE,
  ACTIVE_OR_INACTIVE_CHECKLIST_SUCCESS,
  ACTIVE_OR_INACTIVE_CHECKLIST_REQUEST,
  ADD_NEW_CHECKLIST_FAILURE,
  ADD_NEW_CHECKLIST_REQUEST,
  ADD_NEW_CHECKLIST_SUCCESS,
  EDIT_CHECKLIST_FAILURE,
  EDIT_CHECKLIST_REQUEST,
  EDIT_CHECKLIST_SUCCESS,
  GET_ALL_CHECKLISTS_FAILURE,
  GET_ALL_CHECKLISTS_REQUEST,
  GET_ALL_CHECKLISTS_SUCCESS,
  GET_CHECKLIST_DETAIL_FAILURE,
  GET_CHECKLIST_DETAIL_REQUEST,
  GET_CHECKLIST_DETAIL_SUCCESS,
  GET_FILTERED_CHECKLIST_FAILURE,
  GET_FILTERED_CHECKLIST_REQUEST,
  GET_FILTERED_CHECKLIST_SUCCESS,
  RESET_THE_CHECKLIST_DETAIL_STATE,
  GET_ALL_ACTIVITIES_REQUEST,
  GET_ALL_ACTIVITIES_SUCCESS,
  GET_ALL_ACTIVITIES_FAILURE,
  GET_ALL_QUESTIONS_REQUEST,
  GET_ALL_QUESTIONS_SUCCESS,
  GET_ALL_QUESTIONS_FAILURE,
  GET_FILTERED_ACTIVITY_REQUEST,
  GET_FILTERED_ACTIVITY_SUCCESS,
  GET_FILTERED_ACTIVITY_FAILURE,
  GET_ALL_CHECKLISTS_PROJECTSETTINGS_REQUEST,
  GET_FILTERED_CHECKLIST_PROJECTSETTINGS_REQUEST,
  GET_FILTERED_CHECKLIST_PROJECTSETTINGS_SUCCESS,
  GET_FILTERED_CHECKLIST_PROJECTSETTINGS_FAILURE,
} = ChecklistsActions;

const initialState: ChecklistsState = {
  checklists: [],
  visited_pages: [],
  has_next: true,
  total: 0,
  currentChecklistDetail: {
    id: '',
    subject: '',
    activities: [],
    create_activities: [],
    questions: [],
    is_active: false
  },
  activities: [],
  questions: [],
  create_activities: [],
};

export const checklistsReducer: Reducer<ChecklistsState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_CHECKLISTS_REQUEST: {

        break;
      }

      case GET_ALL_CHECKLISTS_SUCCESS: {
        const { checklists, total, last_page, has_next } = action.payload;
        const pagineAlreadyVisited = state.visited_pages.find(page => page === last_page);

        if (!pagineAlreadyVisited || draft.checklists) {
          draft.visited_pages.push(last_page);
          draft.checklists = last_page === 1 ? checklists : [...state.checklists, ...checklists];
          draft.total = total;
          draft.has_next = has_next;
        }

        break;
      }

      case GET_ALL_CHECKLISTS_FAILURE: {
        draft.checklists = [];

        break;
      }

      case GET_ALL_ACTIVITIES_REQUEST: {
        break;
      }

      case GET_ALL_ACTIVITIES_SUCCESS: {
        const { activity } = action.payload;

        draft.activities = activity;

        break;
      }

      case GET_ALL_ACTIVITIES_FAILURE: {
        draft.activities = [];

        break;
      }

      case GET_ALL_QUESTIONS_REQUEST: {
        break;
      }

      case GET_ALL_QUESTIONS_SUCCESS: {
        const { questions } = action.payload;

        draft.questions = questions;

        break;
      }

      case GET_ALL_QUESTIONS_FAILURE: {
        draft.questions = [];

        break;
      }

      case GET_FILTERED_CHECKLIST_REQUEST: {
        break;
      }

      case GET_FILTERED_CHECKLIST_SUCCESS: {
        const { checklists } = action.payload;

        draft.checklists = checklists;

        break;
      }

      case GET_FILTERED_CHECKLIST_FAILURE: {
        break;
      }

      case GET_FILTERED_ACTIVITY_REQUEST: {
        break;
      }

      case GET_FILTERED_ACTIVITY_SUCCESS: {
        const { activities } = action.payload;

        draft.activities = activities.results;

        break;
      }

      case GET_FILTERED_ACTIVITY_FAILURE: {
        break;
      }

      case GET_CHECKLIST_DETAIL_REQUEST: {
        break;
      }

      case GET_CHECKLIST_DETAIL_SUCCESS: {
        const { checklist } = action.payload;

        draft.currentChecklistDetail = checklist;

        break;
      }

      case GET_CHECKLIST_DETAIL_FAILURE: {
        break;
      }

      case ADD_NEW_CHECKLIST_REQUEST: {
        break;
      }

      case ADD_NEW_CHECKLIST_SUCCESS: {
        const { ChecklistAdded } = action.payload;

        draft.currentChecklistDetail = ChecklistAdded;

        break;
      }

      case ADD_NEW_CHECKLIST_FAILURE: {
        break;
      }

      case EDIT_CHECKLIST_REQUEST: {
        break;
      }

      case EDIT_CHECKLIST_SUCCESS: {
        break;
      }

      case EDIT_CHECKLIST_FAILURE: {
        break;
      }

      case ACTIVE_OR_INACTIVE_CHECKLIST_REQUEST: {
        break;
      }

      case ACTIVE_OR_INACTIVE_CHECKLIST_SUCCESS: {
        break;
      }

      case ACTIVE_OR_INACTIVE_CHECKLIST_FAILURE: {
        break;
      }

      case RESET_THE_CHECKLIST_DETAIL_STATE: {
        draft.currentChecklistDetail.id = '';
        draft.currentChecklistDetail.subject = '';
        draft.currentChecklistDetail.is_active = false;
        draft.currentChecklistDetail.create_activities = [];
        draft.currentChecklistDetail.activities = [];
        draft.currentChecklistDetail.questions = [];

        break;
      }

      case GET_ALL_CHECKLISTS_PROJECTSETTINGS_REQUEST:
        break;

      case GET_FILTERED_CHECKLIST_PROJECTSETTINGS_REQUEST:
        break;

      case GET_FILTERED_CHECKLIST_PROJECTSETTINGS_SUCCESS: {
        const { filteredChecklists } = action.payload;
        draft.checklists = filteredChecklists;
        break;
      }

      case GET_FILTERED_CHECKLIST_PROJECTSETTINGS_FAILURE:
        break;

      default: {
        return draft;
      }
    }
  });
};
