import type { Action } from 'store/types';

import {
  Controls,
  CustomerControlStatic,
  ChangeStatusBodyType,
  CustomerProjectsControls,
  CustomerProjectControlActions,
} from './types';

const {
  GET_CUSTOMER_PROJECT_CONTROLS_REQUEST,
  GET_CUSTOMER_PROJECT_CONTROLS_SUCCESS,
  GET_CUSTOMER_PROJECT_CONTROLS_FAILURE,
  GET_CONTROLS_CUSTOMER_PROJECT_DETAIL_REQUEST,
  GET_CONTROLS_CUSTOMER_PROJECT_DETAIL_SUCCESS,
  GET_CONTROLS_CUSTOMER_PROJECT_DETAIL_FAILURE,
  EDIT_CONTROL_CUSTOMER_PROJECT_STATUS_REQUEST,
  EDIT_CONTROL_CUSTOMER_PROJECT_STATUS_SUCCESS,
  EDIT_CONTROL_CUSTOMER_PROJECT_STATUS_FAILURE,
  GET_CUSTOMER_PROJECT_CONTROLS_STATIC_REQUEST,
  GET_CUSTOMER_PROJECT_CONTROLS_STATIC_SUCCESS,
  GET_CUSTOMER_PROJECT_CONTROLS_STATIC_FAILURE,
  PUT_CUSTOMER_CONTROL_PARTIALLY_REQUEST,
  PUT_CUSTOMER_CONTROL_PARTIALLY_SUCCESS,
  PUT_CUSTOMER_CONTROL_PARTIALLY_FAILURE,
} = CustomerProjectControlActions;

function putCustomerControlPartiallyRequest(
  partially_applied: boolean,
  justification_for_partially_applied: string,
  controlId: string,
  customerProjectId: string,
): Action {
  return {
    type: PUT_CUSTOMER_CONTROL_PARTIALLY_REQUEST,
    payload: {
      partially_applied,
      justification_for_partially_applied,
      controlId,
      customerProjectId
    }
  };
}

function putCustomerControlPartiallySuccess(): Action {
  return {
    type: PUT_CUSTOMER_CONTROL_PARTIALLY_SUCCESS,
  };
}

function putCustomerControlPartiallyFailure(): Action {
  return {
    type: PUT_CUSTOMER_CONTROL_PARTIALLY_FAILURE,
  };
}

function getCustomerProjectControlsRequest(customerProjectId: string): Action {
  return {
    type: GET_CUSTOMER_PROJECT_CONTROLS_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function getCustomerProjectControlsSuccess(customerProjectControls: CustomerProjectsControls): Action {
  return {
    type: GET_CUSTOMER_PROJECT_CONTROLS_SUCCESS,
    payload: {
      customerProjectControls
    }
  };
}

function getCustomerProjectControlsFailure(): Action {
  return {
    type: GET_CUSTOMER_PROJECT_CONTROLS_FAILURE,
  };
}

function getCustomerProjectControlsDetailRequest(controlId: string): Action {
  return {
    type: GET_CONTROLS_CUSTOMER_PROJECT_DETAIL_REQUEST,
    payload: {
      controlId,
    }
  };
}

function getCustomerProjectControlsDetailSuccess(control: Controls): Action {
  return {
    type: GET_CONTROLS_CUSTOMER_PROJECT_DETAIL_SUCCESS,
    payload: {
      control
    }
  };
}

function getCustomerProjectControlsDetailFailure(): Action {
  return {
    type: GET_CONTROLS_CUSTOMER_PROJECT_DETAIL_FAILURE,
  };
}

function editCustomerProjectControlsStatusRequest(
  controlId: string,
  changeStatusBody: ChangeStatusBodyType,
  customerProjectId: string
): Action {
  return {
    type: EDIT_CONTROL_CUSTOMER_PROJECT_STATUS_REQUEST,
    payload: {
      controlId,
      changeStatusBody,
      customerProjectId
    }
  };
}

function editCustomerProjectControlsStatusSuccess(): Action {
  return {
    type: EDIT_CONTROL_CUSTOMER_PROJECT_STATUS_SUCCESS,
  };
}

function editCustomerProjectControlsStatusFailure(): Action {
  return {
    type: EDIT_CONTROL_CUSTOMER_PROJECT_STATUS_FAILURE,
  };
}

function getCustomerProjectControlStaticRequest(projectId: string): Action {
  return {
    type: GET_CUSTOMER_PROJECT_CONTROLS_STATIC_REQUEST,
    payload: {
      projectId,
    }
  };
}

function getCustomerProjectControlStaticSuccess(customerControls: CustomerControlStatic): Action {
  return {
    type: GET_CUSTOMER_PROJECT_CONTROLS_STATIC_SUCCESS,
    payload: {
      customerControls
    }
  };
}

function getCustomerProjectControlStaticFailure(): Action {
  return {
    type: GET_CUSTOMER_PROJECT_CONTROLS_STATIC_FAILURE,
  };
}

export const customerProjectControlActionsFunctions = {
  getCustomerProjectControlsRequest,
  getCustomerProjectControlsSuccess,
  getCustomerProjectControlsFailure,
  getCustomerProjectControlsDetailRequest,
  getCustomerProjectControlsDetailSuccess,
  getCustomerProjectControlsDetailFailure,
  editCustomerProjectControlsStatusRequest,
  editCustomerProjectControlsStatusSuccess,
  editCustomerProjectControlsStatusFailure,
  getCustomerProjectControlStaticRequest,
  getCustomerProjectControlStaticSuccess,
  getCustomerProjectControlStaticFailure,
  putCustomerControlPartiallyRequest,
  putCustomerControlPartiallySuccess,
  putCustomerControlPartiallyFailure,
};