export enum DamagesActions {
  GET_ALL_DAMAGES_REQUEST = 'GET_ALL_DAMAGES_REQUEST',
  GET_ALL_DAMAGES_SUCCESS = 'GET_ALL_DAMAGES_SUCCESS',
  GET_ALL_DAMAGES_FAILURE = 'GET_ALL_DAMAGES_FAILURE',
  GET_FILTERED_DAMAGE_REQUEST = 'GET_FILTERED_DAMAGE_REQUEST',
  GET_FILTERED_DAMAGE_SUCCESS = 'GET_FILTERED_DAMAGE_SUCCESS',
  GET_FILTERED_DAMAGE_FAILURE = 'GET_FILTERED_DAMAGE_FAILURE',
  GET_DAMAGE_DETAIL_REQUEST = 'GET_DAMAGE_DETAIL_REQUEST',
  GET_DAMAGE_DETAIL_SUCCESS = 'GET_DAMAGE_DETAIL_SUCCESS',
  GET_DAMAGE_DETAIL_FAILURE = 'GET_DAMAGE_DETAIL_FAILURE',
  ADD_NEW_DAMAGE_REQUEST = 'ADD_NEW_DAMAGE_REQUEST',
  ADD_NEW_DAMAGE_SUCCESS = 'ADD_NEW_DAMAGE_SUCCESS',
  ADD_NEW_DAMAGE_FAILURE = 'ADD_NEW_DAMAGE_FAILURE',
  EDIT_DAMAGE_REQUEST = 'EDIT_DAMAGE_REQUEST',
  EDIT_DAMAGE_SUCCESS = 'EDIT_DAMAGE_SUCCESS',
  EDIT_DAMAGE_FAILURE = 'EDIT_DAMAGE_FAILURE',
  ACTIVE_OR_INACTIVE_DAMAGE_REQUEST = 'ACTIVE_OR_INACTIVE_DAMAGE_REQUEST',
  ACTIVE_OR_INACTIVE_DAMAGE_SUCCESS = 'ACTIVE_OR_INACTIVE_DAMAGE_SUCCESS',
  ACTIVE_OR_INACTIVE_DAMAGE_FAILURE = 'ACTIVE_OR_INACTIVE_DAMAGE_FAILURE',
  RESET_THE_DAMAGE_DETAIL_STATE = 'RESET_THE_DAMAGE_DETAIL_STATE',
}

type ObjectWithId = {
  id: string;
};

export type SearchFilteredDamageRequestPayload = {
  filteredDamages: Array<string | boolean | null>;
};

export type AddNewDamageRequestPayload = {
  newDamage: AddNewDamageData;
};

export type DamageDetailRequestPayload = {
  damageId: string;
};

export type EditDamageRequestPayload = {
  updatedDamage: EditDamageData;
};

export type UpdateDamageStatusRequestPayload = {
  damageId: string;
  newDamageStatus: boolean;
};

export type AddNewDamageData = {
  name: string;
  type: string;
  threats: ObjectWithId[];
  is_active: boolean;
};

export type EditDamageData = {
  id: string;
  friendly_id: string;
  name: string;
  type: string;
  is_active: boolean;
  threats_add: ObjectWithId[];
  threats_remove: ObjectWithId[];
};

export type ThreatOfDamage = {
  id: string;
  name: string;
  description: string;
};

export type DamageResults = {
  results: Damage[];
  count: number;
  next: boolean;
}

export type Damage = {
  id: string;
  friendly_id: string;
  name: string;
  type: string;
  threats: ThreatOfDamage[];
  is_active: boolean;
};

export type DamagesState = {
  damages: Damage[];
  currentDamageDetail: Damage;
  total: number,
  visited_pages: number[];
  has_next: boolean;
};
