export enum UserProfileActions {
    GET_ALL_USER_PROFILE_REQUEST = 'GET_ALL_USER_PROFILE_REQUEST',
    GET_ALL_USER_PROFILE_SUCCESS = 'GET_ALL_USER_PROFILE_SUCCESS',
    GET_ALL_USER_PROFILE_FAILURE = 'GET_ALL_USER_PROFILE_FAILURE',
    ADD_NEW_USER_PROFILE_REQUEST = 'ADD_NEW_USER_PROFILE_REQUEST',
    ADD_NEW_USER_PROFILE_SUCCESS = 'ADD_NEW_USER_PROFILE_SUCCESS',
    ADD_NEW_USER_PROFILE_FAILURE = 'ADD_NEW_USER_PROFILE_FAILURE',
    EDIT_USER_PROFILE_REQUEST = 'EDIT_USER_PROFILE_REQUEST',
    EDIT_USER_PROFILE_SUCCESS = 'EDIT_USER_PROFILE_SUCCESS',
    EDIT_USER_PROFILE_FAILURE = 'EDIT_USER_PROFILE_FAILURE',
    ACTIVE_OR_INACTIVE_USER_PROFILE_REQUEST = 'ACTIVE_OR_INACTIVE_USER_PROFILE_REQUEST',
    ACTIVE_OR_INACTIVE_USER_PROFILE_SUCCESS = 'ACTIVE_OR_INACTIVE_USER_PROFILE_SUCCESS',
    ACTIVE_OR_INACTIVE_USER_PROFILE_FAILURE = 'ACTIVE_OR_INACTIVE_USER_PROFILE_FAILURE',
    GET_ONE_USER_PROFILE_REQUEST =  'GET_ONE_USER_PROFILE_REQUEST',
    GET_ONE_USER_PROFILE_SUCCESS = 'GET_ONE_USER_PROFILE_SUCCESS',
    GET_ONE_USER_PROFILE_FAILURE = 'GET_ONE_USER_PROFILE_FAILURE',
    GET_ALL_GROUPS_REQUEST = 'GET_ALL_GROUPS_REQUEST',
    GET_ALL_GROUPS_SUCCESS = 'GET_ALL_GROUPS_SUCCESS',
    GET_ALL_GROUPS_FAILURE = 'GET_ALL_GROUPS_FAILURE',
    ADD_NEW_GROUP_REQUEST = 'ADD_NEW_GROUP_REQUEST',
    ADD_NEW_GROUP_SUCCESS = 'ADD_NEW_GROUP_SUCCESS',
    ADD_NEW_GROUP_FAILURE = 'ADD_NEW_GROUP_FAILURE',
    EDIT_GROUP_REQUEST = 'EDIT_GROUP_REQUEST',
    EDIT_GROUP_SUCCESS = 'EDIT_GROUP_SUCCESS',
    EDIT_GROUP_FAILURE = 'EDIT_GROUP_FAILURE',
    GET_ONE_GROUP_REQUEST =  'GET_ONE_GROUP_REQUEST',
    GET_ONE_GROUP_SUCCESS = 'GET_ONE_GROUP_SUCCESS',
    GET_ONE_GROUP_FAILURE = 'GET_ONE_GROUP_FAILURE',
    GET_ALL_PERMISSIONS_REQUEST = 'GET_ALL_PERMISSIONS_REQUEST',
    GET_ALL_PERMISSIONS_SUCCESS = 'GET_ALL_PERMISSIONS_SUCCESS',
    GET_ALL_PERMISSIONS_FAILURE = 'GET_ALL_PERMISSIONS_FAILURE',
    EDIT_GROUP_PERMISSIONS_REQUEST = 'EDIT_GROUP_PERMISSIONS_REQUEST',
    EDIT_GROUP_PERMISSIONS_SUCCESS = 'EDIT_GROUP_PERMISSIONS_SUCCESS',
    EDIT_GROUP_PERMISSIONS_FAILURE = 'EDIT_GROUP_PERMISSIONS_FAILURE',
    GET_FILTERED_USER_PROFILE_REQUEST = 'GET_FILTERED_USER_PROFILE_REQUEST',
    GET_FILTERED_USER_PROFILE_SUCCESS = 'GET_FILTERED_USER_PROFILE_SUCCESS',
    GET_FILTERED_USER_PROFILE_FAILURE = 'GET_FILTERED_USER_PROFILE_FAILURE',
    GET_FILTERED_GROUP_REQUEST = 'GET_FILTERED_GROUP_REQUEST',
    GET_FILTERED_GROUP_SUCCESS = 'GET_FILTERED_GROUP_SUCCESS',
    GET_FILTERED_GROUP_FAILURE = 'GET_FILTERED_GROUP_FAILURE',
    GET_FILTERED_PERMISSION_REQUEST = 'GET_FILTERED_PERMISSION_REQUEST',
    GET_FILTERED_PERMISSION_SUCCESS = 'GET_FILTERED_PERMISSION_SUCCESS',
    GET_FILTERED_PERMISSION_FAILURE = 'GET_FILTERED_PERMISSION_FAILURE',
  }


  export type UserProfile = {
    id: string;
    name: string;
    groups: Group[] | [];
    child_profiles: ChildProfile[] | [];
    users: User[] | [];
    is_active: boolean;
  };

  export type Group = {
    id: string;
    name: string;
    permissions?: Permission[] | []
  }

  export type User = {
    id: string;
    name: string;
  }

  export type ChildProfile = {
    id: string;
    name: string;
  }

  export type Permission = {
    id: string;
    name: string;
    codename: string;
    content_type: number;
  }

  export type AllProfilesResponse = {
    count: number | null;
    next: number | null;
    previous: number | null;
    results: UserProfile[] | []
  }

  export type AddProfilePayload = {
    id?: string;
    name: string;
    groups?: number[];
    child_profiles?: string[];
    is_active?: boolean;
  }

  export type UserProfilePayload = {
    userProfile: AddProfilePayload;
  }

  export type GroupPayload = {
    group: AddEditGroupPayload;
  }

  export type UserProfilesState = {
    userProfiles: UserProfile[];
    filteredUserProfiles: UserProfile[];
    userProfile: UserProfile;
    groups: Group[];
    filteredGroups: Group[];
    group: Group;
    groupPermissions: MicrosservicePermissions,
    allPermissions: MicrosservicePermissions,
    filteredPermissions: MicrosservicePermissions
  };

  export type MicrosservicePermissions = {
    [key: string]: Permission[],
  }

  export type AddEditGroupPayload = {
    id?: string;
    name: string;
    permissions?: number[];
    microsservice?: string;
  }

  export type OneUserProfilePayload = {
    profileId:string;
  }

  export type OneGroupPayload = {
    groupId: string;
    microsservice: string;
  }

  export type AllPermissionsPayload = {
    microsservice: string;
  }

  export type VinculatedGroupProfile = {
    id: string;
    name: string;
    isVinculated: boolean;
  }

  export type VinculatedProfile = {
    id: string;
    name: string;
  }

  export type VinculatedPermission = {
    id: string;
    name: string;
    isVinculated: boolean;
  }

  export type AllGroupsResponse = {
    count: number | null;
    next: number | null;
    previous: number | null;
    results: Group[] | []
  }

  export type FilteredPayload = {
    filtered: string;
  }

  export type FilteredPermissionPayload = {
    microsservice: string;
    filtered: string;
  }