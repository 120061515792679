import type { Action } from 'store/types';

import { ProjectSettingsActions } from './types';
import type { 
  Questionnaire,
  ProjectSettings,
  ProjectSettingsDetails,
  ProjectSettingsPostBody,
  AddNewProjectSettingsRequest,
  ProjectSettingsWithAddAndRemove
} from './types';


const {
  EDIT_PROJECTSETTING_REQUEST,
  EDIT_PROJECTSETTING_SUCCESS,
  EDIT_PROJECTSETTING_FAILURE,  
  ADD_NEW_PROJECTSETTING_REQUEST,
  ADD_NEW_PROJECTSETTING_SUCCESS,
  ADD_NEW_PROJECTSETTING_FAILURE,  
  GET_ALL_PROJECTSETTING_REQUEST,
  GET_ALL_PROJECTSETTING_SUCCESS,
  GET_ALL_PROJECTSETTING_FAILURE, 
  CREATE_NEW_PROJECTSETTING_REQUEST,
  CREATE_NEW_PROJECTSETTING_SUCCESS,
  CREATE_NEW_PROJECTSETTING_FAILURE, 
  GET_PROJECTSETTING_DETAILS_REQUEST,
  GET_PROJECTSETTING_DETAILS_SUCCESS,
  GET_PROJECTSETTING_DETAILS_FAILURE,
  GET_FILTERED_PROJECTSETTING_REQUEST,
  GET_FILTERED_PROJECTSETTING_SUCCESS,
  GET_FILTERED_PROJECTSETTING_FAILURE,
  RESET_THE_PROJECTSETTING_DETAIL_STATE,
  RESET_THE_LAST_PROJECTSETTING_DETAILS,
  ACTIVE_OR_INACTIVE_PROJECTSETTING_REQUEST,
  ACTIVE_OR_INACTIVE_PROJECTSETTING_SUCCESS,
  ACTIVE_OR_INACTIVE_PROJECTSETTING_FAILURE,
  GET_ALL_QUESTIONNAIRE_SUCCESS,
  GET_ALL_QUESTIONNAIRE_FAILURE,
  GET_FILTERED_QUESTIONNAIRE_FAILURE,
  GET_ALL_QUESTIONNAIRE_PROJECTSETTINGS_REQUEST,
  GET_ALL_QUESTIONNAIRE_PROJECTSETTINGS_SUCCESS,
  GET_ALL_QUESTIONNAIRE_PROJECTSETTINGS_FAILURE,
  GET_FILTERED_QUESTIONNAIRE_PROJECTSETTINGS_REQUEST,
  GET_FILTERED_QUESTIONNAIRE_PROJECTSETTINGS_SUCCESS,
  GET_FILTERED_QUESTIONNAIRE_PROJECTSETTINGS_FAILURE
} = ProjectSettingsActions;

function createNewProjectSettingRequest(projectSettingPostBody: ProjectSettingsPostBody): Action {
  return {
    type: CREATE_NEW_PROJECTSETTING_REQUEST,
    payload: {
      projectSettingPostBody
    }
  };
}

function createNewProjectSettingSuccess(): Action {
  return {
    type: CREATE_NEW_PROJECTSETTING_SUCCESS,
  };
}

function createNewProjectSettingFailure(): Action {
  return {
    type: CREATE_NEW_PROJECTSETTING_FAILURE,
  };
}

function getProjectSettingsRequest(): Action {
  return {
    type: GET_ALL_PROJECTSETTING_REQUEST,
  };
}

function getProjectSettingsSuccess(projectSettings: ProjectSettings[]): Action {
  return {
    type: GET_ALL_PROJECTSETTING_SUCCESS,
    payload: {
      projectSettings,
    },
  };
}

function getProjectSettingsFailure(): Action {
  return {
    type: GET_ALL_PROJECTSETTING_FAILURE,
  };
}

function getProjectSettingsDetailsRequest(projectSettingsId: string): Action {
  return {
    type: GET_PROJECTSETTING_DETAILS_REQUEST,
    payload: {
      projectSettingsId,
    },
  };
}

function getProjectSettingsDetailsSuccess(projectSettingsDetails: ProjectSettingsDetails): Action {
  return {
    type: GET_PROJECTSETTING_DETAILS_SUCCESS,
    payload: {
      projectSettingsDetails,
    },
  };
}

function getProjectSettingsDetailsFailure(): Action {
  return {
    type: GET_PROJECTSETTING_DETAILS_FAILURE,
  };
}

function getSearchFilteredProjectSettingsRequest(
  filteredProjectSettings: Array<string | boolean | null>,
  isActive: boolean | null
): Action {
  return {
    type: GET_FILTERED_PROJECTSETTING_REQUEST,
    payload: {
      filteredProjectSettings,
      isActive
    },
  };
}

function getSearchFilteredProjectSettingsSuccess(projectSettings: ProjectSettings[]): Action {
  return {
    type: GET_FILTERED_PROJECTSETTING_SUCCESS,
    payload: {
      projectSettings,
    },
  };
}

function getSearchFilteredProjectSettingsFailure(): Action {
  return {
    type: GET_FILTERED_PROJECTSETTING_FAILURE,
  };
}

function addNewProjectSettingsRequest(projectSettings: AddNewProjectSettingsRequest): Action {
  return {
    type: ADD_NEW_PROJECTSETTING_REQUEST,
    payload: {
      projectSettings,
    },
  };
}

function addNewProjectSettingsSuccess(): Action {
  return {
    type: ADD_NEW_PROJECTSETTING_SUCCESS,
  };
}

function addNewProjectSettingsFailure(): Action {
  return {
    type: ADD_NEW_PROJECTSETTING_FAILURE,
  };
}
 
function editProjectSettingsRequest(editProjectSettings: ProjectSettingsWithAddAndRemove): Action {
  return {
    type: EDIT_PROJECTSETTING_REQUEST,
    payload: {
      editProjectSettings
    }
  };
}

function editProjectSettingsSuccess(): Action {
  return {
    type: EDIT_PROJECTSETTING_SUCCESS,
  };
}

function editProjectSettingsFailure(): Action {
  return {
    type: EDIT_PROJECTSETTING_FAILURE,
  };
}

function activeOrInactiveProjectSettingsRequest(
  id: string,
  updatedStatus: boolean
): Action {
  return {
    type: ACTIVE_OR_INACTIVE_PROJECTSETTING_REQUEST,
    payload: {
      id,
      updatedStatus,
    },
  };
}

function activeOrInactiveProjectSettingsSuccess(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_PROJECTSETTING_SUCCESS,
  };
}

function activeOrInactiveProjectSettingsFailure(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_PROJECTSETTING_FAILURE,
  };
}

function resetTheLastProjectSettingsDetails(): Action {
  return {
    type: RESET_THE_LAST_PROJECTSETTING_DETAILS,
  };
}

function getSearchFilteredProjectSettingRequest(
  filteredProjectSettings: Array<string | boolean | null>
): Action {
  return {
    type: GET_FILTERED_PROJECTSETTING_REQUEST,
    payload: {
      filteredProjectSettings,
    },
  };
}

function resetProjectSettingsDetailState(): Action {
  return {
    type: RESET_THE_PROJECTSETTING_DETAIL_STATE,
  };
}

function getQuestionnaireProjectSettingsRequest(): Action {
  return {
    type: GET_ALL_QUESTIONNAIRE_PROJECTSETTINGS_REQUEST,
  };
}

function getQuestionnaireSuccess(questionnaires: Questionnaire[]): Action {
  return {
    type: GET_ALL_QUESTIONNAIRE_PROJECTSETTINGS_SUCCESS,
    payload: {
      questionnaires,
    },
  };
}

function getQuestionnaireFailure(): Action {
  return {
    type: GET_ALL_QUESTIONNAIRE_PROJECTSETTINGS_FAILURE,
  };
}

function getSearchFilteredQuestionnaireProjectSettingsRequest(
  filteredQuestionnaires: Array<string | boolean | null>,
  filterQuizType?: string,
): Action {
  return {
    type: GET_FILTERED_QUESTIONNAIRE_PROJECTSETTINGS_REQUEST,
    payload: {
      filteredQuestionnaires,
      filterQuizType,
    },
  };
}

function getSearchFilteredQuestionnaireSuccess(
  questionnaires: Questionnaire[]
): Action {
  return {
    type: GET_FILTERED_QUESTIONNAIRE_PROJECTSETTINGS_SUCCESS,
    payload: {
      questionnaires,
    },
  };
}

function getSearchFilteredQuestionnaireFailure(): Action {
  return {
    type: GET_FILTERED_QUESTIONNAIRE_PROJECTSETTINGS_FAILURE,
  };
}

export const projectSettingsActionsFunctions = {
  getProjectSettingsRequest
  ,getProjectSettingsSuccess
  ,getProjectSettingsFailure
  ,editProjectSettingsRequest
  ,editProjectSettingsSuccess
  ,editProjectSettingsFailure
  ,addNewProjectSettingsRequest
  ,addNewProjectSettingsSuccess
  ,addNewProjectSettingsFailure
  ,getProjectSettingsDetailsRequest
  ,getProjectSettingsDetailsSuccess
  ,getProjectSettingsDetailsFailure
  ,resetTheLastProjectSettingsDetails
  ,getSearchFilteredProjectSettingRequest
  ,activeOrInactiveProjectSettingsRequest
  ,activeOrInactiveProjectSettingsSuccess
  ,activeOrInactiveProjectSettingsFailure
  ,getSearchFilteredProjectSettingsRequest
  ,getSearchFilteredProjectSettingsSuccess
  ,getSearchFilteredProjectSettingsFailure
  ,resetProjectSettingsDetailState
  ,createNewProjectSettingRequest
  ,createNewProjectSettingSuccess
  ,createNewProjectSettingFailure
  ,getQuestionnaireSuccess
  ,getQuestionnaireFailure
  ,getQuestionnaireProjectSettingsRequest
  ,getSearchFilteredQuestionnaireProjectSettingsRequest
  ,getSearchFilteredQuestionnaireSuccess
  ,getSearchFilteredQuestionnaireFailure
};