export enum CustomerProjectReportActions {
  GET_ALL_REPORTS_CUSTOMER_PROJECT_REQUEST = 'GET_ALL_REPORTS_CUSTOMER_PROJECT_REQUEST',
  GET_ALL_REPORTS_CUSTOMER_PROJECT_SUCCESS = 'GET_ALL_REPORTS_CUSTOMER_PROJECT_SUCCESS',
  GET_ALL_REPORTS_CUSTOMER_PROJECT_FAILURE = 'GET_ALL_REPORTS_CUSTOMER_PROJECT_FAILURE',
  EDIT_REPORTS_CUSTOMER_PROJECT_REQUEST = 'EDIT_REPORTS_CUSTOMER_PROJECT_REQUEST',
  EDIT_REPORTS_CUSTOMER_PROJECT_SUCCESS = 'EDIT_REPORTS_CUSTOMER_PROJECT_SUCCESS',
  EDIT_REPORTS_CUSTOMER_PROJECT_FAILURE = 'EDIT_REPORTS_CUSTOMER_PROJECT_FAILURE',
  EDIT_REPORTS_CUSTOM_PDF_REQUEST = 'EDIT_REPORTS_CUSTOM_PDF_REQUEST',
  EDIT_REPORTS_CUSTOM_PDF_SUCCESS = 'EDIT_REPORTS_CUSTOM_PDF_SUCCESS',
  EDIT_REPORTS_CUSTOM_PDF_FAILURE = 'EDIT_REPORTS_CUSTOM_PDF_FAILURE',
  EDIT_REPORTS_CUSTOMER_PROJECT_SCOPE_REQUEST = 'EDIT_REPORTS_CUSTOMER_PROJECT_SCOPE_REQUEST',
  EDIT_REPORTS_CUSTOMER_PROJECT_SCOPE_SUCCESS = 'EDIT_REPORTS_CUSTOMER_PROJECT_SCOPE_SUCCESS',
  EDIT_REPORTS_CUSTOMER_PROJECT_SCOPE_FAILURE = 'EDIT_REPORTS_CUSTOMER_PROJECT_SCOPE_FAILURE',
  GET_REPORTS_PDF_CUSTOMER_PROJECT_REQUEST = 'GET_REPORTS_PDF_CUSTOMER_PROJECT_REQUEST',
  GET_REPORTS_PDF_CUSTOMER_PROJECT_SUCCESS = 'GET_REPORTS_PDF_CUSTOMER_PROJECT_SUCCESS',
  GET_REPORTS_PDF_CUSTOMER_PROJECT_FAILURE = 'GET_REPORTS_PDF_CUSTOMER_PROJECT_FAILURE',
  GET_PDF_SEND_BY_ANALYST_REQUEST = 'GET_PDF_SEND_BY_ANALYST_REQUEST',
  GET_PDF_SEND_BY_ANALYST_SUCCESS = 'GET_PDF_SEND_BY_ANALYST_SUCCESS',
  GET_PDF_SEND_BY_ANALYST_FAILURE = 'GET_PDF_SEND_BY_ANALYST_FAILURE',
  GET_REPORT_DOC_REQUEST = 'GET_REPORT_DOC_REQUEST',
  GET_REPORT_DOC_SUCCESS = 'GET_REPORT_DOC_SUCCESS',
  GET_REPORT_DOC_FAILURE = 'GET_REPORT_DOC_FAILURE',
  CREATE_A_REPORT_REQUEST = 'CREATE_A_REPORT_REQUEST',
  CREATE_A_REPORT_SUCCESS = 'CREATE_A_REPORT_SUCCESS',
  CREATE_A_REPORT_FAILURE = 'CREATE_A_REPORT_FAILURE',
  GET_ALL_REPORTS_REQUEST = 'GET_ALL_REPORTS_REQUEST',
  GET_ALL_REPORTS_SUCCESS = 'GET_ALL_REPORTS_SUCCESS',
  GET_ALL_REPORTS_FAILURE = 'GET_ALL_REPORTS_FAILURE',
  DOWNLOAD_REPORTS_PDF_CUSTOMER_PROJECT_REQUEST = 'DOWNLOAD_REPORTS_PDF_CUSTOMER_PROJECT_REQUEST',
  DOWNLOAD_REPORTS_PDF_CUSTOMER_PROJECT_SUCCESS = 'DOWNLOAD_REPORTS_PDF_CUSTOMER_PROJECT_SUCCESS',
  DOWNLOAD_REPORTS_PDF_CUSTOMER_PROJECT_FAILURE = 'DOWNLOAD_REPORTS_PDF_CUSTOMER_PROJECT_FAILURE',
  CANCEL_DOWNLOAD_REPORTS_PDF_REQUEST = 'CANCEL_DOWNLOAD_REPORTS_PDF_REQUEST',
  CANCEL_DOWNLOAD_REPORTS_PDF_SUCCESS = 'CANCEL_DOWNLOAD_REPORTS_PDF_SUCCESS',
  CANCEL_DOWNLOAD_REPORTS_PDF_FAILURE = 'CANCEL_DOWNLOAD_REPORTS_PDF_FAILURE',
  APPROVE_GENERATED_FILE_REQUEST = 'APPROVE_GENERATED_FILE_REQUEST',
  APPROVE_GENERATED_FILE_SUCCESS = 'APPROVE_GENERATED_FILE_SUCCESS',
  APPROVE_GENERATED_FILE_FAILURE = 'APPROVE_GENERATED_FILE_FAILURE',
  CREATE_EXCEL_FILE_REQUEST = 'CREATE_EXCEL_FILE_REQUEST',
  CREATE_EXCEL_FILE_SUCCESS = 'CREATE_EXCEL_FILE_SUCCESS',
  CREATE_EXCEL_FILE_FAILURE = 'CREATE_EXCEL_FILE_FAILURE',
}

export type EditReportBody = {
  introduction?: string,
  the_organization?: string,
  recommendation?: string,
  final_considerations?: string,
  project_scope?: string
}

export type EditReportTextApiActionPayload = {
  customerProjectId: string,
  editReportBody: EditReportBody
}

export type EditReporCustomPdfPayload = {
  customerProjectId: string,
  editReportCustomPDFBody: any[]
}

export type Report = {
  id: string;
  name: string;
  report_type: string;
  status: string;
  user: string;
  approved: boolean;
  approval_user: string;
  created_at: string;
  updated_at: string;
  version: number;
}

export type ReportsResults = {
  reports: Report[];
  count: number;
  next: boolean;
};

export type CustomerProjectReportState = {
  allCustomerProjectReports: any;
  allProjectReports: Report[];
  total: number,
  visited_pages: number[];
  has_next: boolean;
}

export type DownloadReportIdApiPayload = {
  reportId: string;
  type: string;
  name?: string;
}

export type CancelDownloadReportIdApiPayload = {
  reportId: string;
  customerProjectId: string | undefined;
}

export type ApproveGeneratedApiPayload = {
  reportId: string;
  customerProjectId: string | undefined;
  approved?: boolean;
}