import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { history } from 'services/history';
import { customerProjectActionsFunctions } from 'store/modules/customerProject/actions';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import { Action } from 'store/types';
import { checkPage } from 'utils/number';

import { SearchFilteredThreatRequestPayload } from '../Manutention/vulnerabilities/types';
import { openedModalActionsFunctions } from '../openedModal/actions';
import { threatsActionsFunctions } from './actions';
import { 
  CreateNewThreatPayloadApi, 
  reuqestUpdateThrearRiskPayloadResponse, 
  Threat, 
  ThreatDetailPayloadApi, 
  ThreatPutBody, 
  ThreatsActions, 
  ThreatsResults, 
  UpdateThreatRiskRequest, 
  UpdateThreatStatusRequestPayload,
  CustomerProjetcChangeStatusApiPayload,
  ThreatCustomerProject,
  LinkedControlsType,
  SimpleThreatsResults,
} from './types';

const {
  GET_ALL_THREATS_MODULE_REQUEST ,
  GET_THREATS_DETAILS_REQUEST,
  CREATE_NEW_THREAT_REQUEST,
  EDIT_THREAT_REQUEST,
  ACTIVE_OR_INACTIVE_THREAT_REQUEST,
  GET_SEARCH_FILTER_THREAT_REQUEST,
  UPDATE_RISK_REQUEST,
  EDIT_THREAT_CUSTOMER_PROJECT_STATUS_REQUEST,
  GET_LINKED_CONTROLS_REQUEST,
  GET_ALL_SIMPLE_THREATS_MODULE_REQUEST,
  GET_SEARCH_FILTER_SIMPLE_THREAT_REQUEST,
} = ThreatsActions;


const {
  getAllThreatsSuccess,
  getAllThreatsFailure,
  getAllThreatsRequest,
  getThreatDetailSuccess,
  getThreatDetailFailure,
  createNewThreatSuccess,
  createNewThreatFailure,
  editThreatFailure,
  editThreatSuccess,
  activeOrInactiveThreatSuccess,
  activeOrInactiveThreatFailure,
  getSearchFilterThreatsSuccess,
  getSearchFilterThreatsFailure,
  updateRiskSuccess,
  updateRiskFailure,
  editCustomerProjectThreatsStatusSuccess,
  editCustomerProjectThreatsStatusFailure,
  getAllLinkedControlsSuccess,
  getAllLinkedControlsFailure,
  getAllSimpleThreatsSuccess,
  getAllSimpleThreatsFailure,
  getSearchFilterSimpleThreatsSuccess,
  getSearchFilterSimpleThreatsFailure,
} = threatsActionsFunctions;

const { getCustomerProjectThreatsRequest } = customerProjectActionsFunctions;

const {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE,
} = ToastMessages;

const {
  getLastApiFetchDataSuccess,
  getLastApiFetchDataFailure
} = lastApiFetchDataActionsFunctions;

function* fetchAllThreats(action: Action): Generator {
  try {
    const { payload } = action as any;
    const total: number = (yield select(state => state.threats.total)) as number;
    const last_page = checkPage(payload.last_page, total);
    const path = `controls/threats/?page=${last_page}`;
    const has_next = yield select(state => state.threats.has_next);

      if(has_next || last_page === 1){
        const threatResponse: AxiosResponse<ThreatsResults> | unknown = yield call(api, 'GET', path, {});

        const {
          data: {results, count, next},
          config: { url },
          status,
          statusText,
        } = threatResponse as AxiosResponse<ThreatsResults>;

        yield put(
          getLastApiFetchDataSuccess({
            url,
            status,
            statusText,
          })
        );

        yield put(getAllThreatsSuccess(results, count, last_page, !!next));
      }

  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getAllThreatsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchThreatDetail(action: Action): Generator {
  const { threatId } = action.payload as ThreatDetailPayloadApi;
  try {
    const threatResponse: AxiosResponse<Threat> | unknown = yield call(
      api,
      'GET',
      `/controls/threats/${threatId}/detail/`,
      {}
    );

    const {
      data: threat,
      config: { url },
      status,
      statusText,
    } = threatResponse as AxiosResponse<Threat>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getThreatDetailSuccess(threat));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getThreatDetailFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
        DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchLinkedControlsRequest(action: Action): Generator {
  const { threatId } = action.payload as ThreatDetailPayloadApi;
  try {
    const threatResponse: AxiosResponse<LinkedControlsType> | unknown = yield call(
      api,
      'GET',
      `/controls/threats/${threatId}/linked-controls/`,
      {}
    );

    const {
      data: threat,
      config: { url },
      status,
      statusText,
    } = threatResponse as AxiosResponse<LinkedControlsType>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getAllLinkedControlsSuccess(threat));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getAllLinkedControlsFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
        DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchCreateNewThreatRequest(action: Action): Generator {
  const { threatPostBody } = action.payload as CreateNewThreatPayloadApi;
  try {
    const threatResponse: AxiosResponse<Threat> | unknown = yield call(
      api,
      'POST',
      '/controls/threats/create/',
      { ...threatPostBody }
    );

    const {
      data: threatApiResponse,
      config: { url },
      status,
      statusText,
    } = threatResponse as AxiosResponse<Threat>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(createNewThreatSuccess());

    history.push(`/threat-registration/${threatApiResponse.id}/`);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(createNewThreatFailure());
    
    if(currentError?.response?.data?.name[0] === 'threat with this name already exists.')
      toast.warn('Já existe uma ameaça cadastrada com este nome.');
    else
      toast.error('Erro ao cadastrar Ameaça');
  }
}

function* fetchSearchFilteredThreat(action: Action): Generator {
  const { filteredThreats, isActive} = action.payload as SearchFilteredThreatRequestPayload;

  const parsedResponses = filteredThreats.filter(response => response !== null).join(',');

  try {
    const filteredThreatsResponse: AxiosResponse<ThreatsResults> | unknown =
      yield call(api, 'GET', `controls/threats/?search=${parsedResponses}&&is_active=${isActive}`, {});

    const {
      data: threats,
      config: { url },
      status,
      statusText,
    } = filteredThreatsResponse as AxiosResponse<ThreatsResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilterThreatsSuccess(threats.results));
  } catch (error) {
    const currentError = error as AxiosError;
    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilterThreatsFailure());
  }
}

function* fetchEditRiskUpdateRequest(action: Action): Generator {
  const { threatId, riskUpdateAdd, riskUpdateRemove } = action.payload as UpdateThreatRiskRequest;

  const risks_add = riskUpdateAdd;
  const risks_remove = riskUpdateRemove;
  try {
    const threatResponse: AxiosResponse<reuqestUpdateThrearRiskPayloadResponse> | unknown = yield call(
      api,
      'PUT',
      `/controls/threats/${threatId}/update-risks/`,
      { risks_add, risks_remove }
    );

    const {
      config: { url },
      status,
      statusText,
    } = threatResponse as AxiosResponse<reuqestUpdateThrearRiskPayloadResponse>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(updateRiskSuccess());

    toast.success('Ameaça Editada com sucesso');
    history.push('/threat');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(updateRiskFailure());

    toast.error('Erro ao editar Ameaça');
  }
}

const { setOpenedModal } = openedModalActionsFunctions;

function* fetchEditThreatRequest(action: Action): Generator {
  const { threatId, threatPostBody } = action.payload as CreateNewThreatPayloadApi;
  try {
    const threatResponse: AxiosResponse<ThreatPutBody> | unknown = yield call(
      api,
      'PUT',
      `/controls/threats/${threatId}/`,
      { ...threatPostBody }
    );

    const {
      config: { url },
      status,
      statusText,
    } = threatResponse as AxiosResponse<ThreatPutBody>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editThreatSuccess());

    toast.success('Ameaça editada com sucesso.');

    if (threatPostBody.damages_add.length > 0) {
      yield put(setOpenedModal('riskOpenedModal'));
    } else {
      history.push(`/threat-vizualization/${threatId}/`);
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editThreatFailure());

    toast.error('Erro ao editar ameaça.');
  }



}

function* fetchToUpdateThreatStatus(action: Action): Generator {
  const { threatId, newThreatStatus } =
    action.payload as UpdateThreatStatusRequestPayload;

  try {
    const updateVulnerabilityResponse:
      | AxiosResponse<{ is_active: boolean }>
      | unknown = yield call(
      api,
      'PUT',
      `controls/threats/${threatId}/update-status/`,
      {
        is_active: newThreatStatus,
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = updateVulnerabilityResponse as AxiosResponse<{ is_active: boolean }>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(activeOrInactiveThreatSuccess());
    yield put(getAllThreatsRequest());

    toast.success('Status alterado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(activeOrInactiveThreatFailure());

    toast.error('Falha ao alterar o status.');
  }
}

function* editThreatCustomerProjectStatusRequest(action: Action): Generator {
  const { threatId, changeStatusBody, customerProjectId } = action.payload as CustomerProjetcChangeStatusApiPayload;
  try {
    const customerProjectResponse: AxiosResponse<ThreatCustomerProject> | unknown = yield call(
      api,
      'PUT',
      `/controls/threats-customer-project/${threatId}/update-status/`,
      { ...changeStatusBody }
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerProjectResponse as AxiosResponse<ThreatCustomerProject>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editCustomerProjectThreatsStatusSuccess());

    toast.success('Status alterado com sucesso.');

    yield put(getCustomerProjectThreatsRequest(customerProjectId));

  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editCustomerProjectThreatsStatusFailure());

    toast.success('Erro ao alterar o status.');
  }
}

function* fetchAllSimpleThreats(action: Action): Generator {
  try {
    const { payload } = action as any;
    const total: number = (yield select(state => state.threats.total)) as number;
    const last_page = checkPage(payload.last_page, total);
    const path = `controls/threats/?page=${last_page}`;
    const has_next = yield select(state => state.threats.has_next);

      if(has_next || last_page === 1){
        const simpleThreatResponse: AxiosResponse<SimpleThreatsResults> | unknown = yield call(api, 'GET', path, {});

        const {
          data: {results, count, next},
          config: { url },
          status,
          statusText,
        } = simpleThreatResponse as AxiosResponse<SimpleThreatsResults>;

        yield put(
          getLastApiFetchDataSuccess({
            url,
            status,
            statusText,
          })
        );

        yield put(getAllSimpleThreatsSuccess(results, count, last_page, !!next));
      }

  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getAllSimpleThreatsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchSearchFilteredSimpleThreat(action: Action): Generator {
  const { filteredThreats, isActive} = action.payload as SearchFilteredThreatRequestPayload;

  const parsedResponses = filteredThreats.filter(response => response !== null).join(',');

  try {
    const filteredSimpleThreatsResponse: AxiosResponse<SimpleThreatsResults> | unknown =
      yield call(api, 'GET', `controls/threats/?search=${parsedResponses}&&is_active=${isActive}`, {});

    const {
      data: simpleThreat,
      config: { url },
      status,
      statusText,
    } = filteredSimpleThreatsResponse as AxiosResponse<SimpleThreatsResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilterSimpleThreatsSuccess(simpleThreat.results));
  } catch (error) {
    const currentError = error as AxiosError;
    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilterSimpleThreatsFailure());
  }
}

export function* threatsSagas() {
  yield all([
    takeEvery(GET_ALL_THREATS_MODULE_REQUEST, fetchAllThreats),
    takeLatest(GET_THREATS_DETAILS_REQUEST, fetchThreatDetail),
    takeLatest(CREATE_NEW_THREAT_REQUEST, fetchCreateNewThreatRequest),
    takeLatest(EDIT_THREAT_REQUEST, fetchEditThreatRequest),
    takeLatest(ACTIVE_OR_INACTIVE_THREAT_REQUEST, fetchToUpdateThreatStatus),
    takeLatest(GET_SEARCH_FILTER_THREAT_REQUEST, fetchSearchFilteredThreat),
    takeLatest(UPDATE_RISK_REQUEST, fetchEditRiskUpdateRequest),
    takeLatest(EDIT_THREAT_CUSTOMER_PROJECT_STATUS_REQUEST, editThreatCustomerProjectStatusRequest),
    takeLatest(GET_LINKED_CONTROLS_REQUEST, fetchLinkedControlsRequest),
    takeLatest(GET_ALL_SIMPLE_THREATS_MODULE_REQUEST, fetchAllSimpleThreats),
    takeLatest(GET_SEARCH_FILTER_SIMPLE_THREAT_REQUEST, fetchSearchFilteredSimpleThreat),
  ]);
}
