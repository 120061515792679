export enum ActivitiesActions {
  EDIT_ACTIVITIES_REQUEST = 'EDIT_ACTIVITIES_REQUEST',
  EDIT_ACTIVITIES_SUCCESS = 'EDIT_ACTIVITIES_SUCCESS',
  EDIT_ACTIVITIES_FAILURE = 'EDIT_ACTIVITIES_FAILURE',
  GET_ALL_ACTIVITIES_MODULE_REQUEST = 'GET_ALL_ACTIVITIES_MODULE_REQUEST',
  GET_ALL_ACTIVITIES_MODULE_SUCCESS = 'GET_ALL_ACTIVITIES_MODULE_SUCCESS',
  GET_ALL_ACTIVITIES_MODULE_FAILURE = 'GET_ALL_ACTIVITIES_MODULE_FAILURE',
  ADD_NEW_ACTIVITIES_REQUEST = 'ADD_NEW_ACTIVITIES_REQUEST',
  ADD_NEW_ACTIVITIES_SUCCESS = 'ADD_NEW_ACTIVITIES_SUCCESS',
  ADD_NEW_ACTIVITIES_FAILURE = 'ADD_NEW_ACTIVITIES_FAILURE',
  GET_ACTIVITIES_DETAILS_REQUEST = 'GET_ACTIVITIES_DETAILS_REQUEST',
  GET_ACTIVITIES_DETAILS_SUCCESS = 'GET_ACTIVITIES_DETAILS_SUCCESS',
  GET_ACTIVITIES_DETAILS_FAILURE = 'GET_ACTIVITIES_DETAILS_FAILURE',
  GET_FILTERED_ACTIVITIES_REQUEST = 'GET_FILTERED_ACTIVITIES_REQUEST',
  GET_FILTERED_ACTIVITIES_SUCCESS = 'GET_FILTERED_ACTIVITIES_SUCCESS',
  GET_FILTERED_ACTIVITIES_FAILURE = 'GET_FILTERED_ACTIVITIES_FAILURE',
  RESET_THE_LAST_ACTIVITIES_DETAILS = 'RESET_THE_LAST_ACTIVITIES_DETAILS',
  ACTIVE_OR_INACTIVE_ACTIVITIES_REQUEST = 'ACTIVE_OR_INACTIVE_ACTIVITIES_REQUEST',
  ACTIVE_OR_INACTIVE_ACTIVITIES_SUCCESS = 'ACTIVE_OR_INACTIVE_ACTIVITIES_SUCCESS',
  ACTIVE_OR_INACTIVE_ACTIVITIES_FAILURE = 'ACTIVE_OR_INACTIVE_ACTIVITIES_FAILURE',
  GET_ALL_ACTIVITIES_PROJECTSETTINGS_REQUEST = 'GET_ALL_ACTIVITIES_PROJECTSETTINGS_REQUEST',
  GET_FILTERED_ACTIVITIES_PROJECTSETTINGS_REQUEST = 'GET_FILTERED_ACTIVITIES_PROJECTSETTINGS_REQUEST',
}

export type AddNewActivitiesRequest = {
  name: string;
  has_evidence: boolean | null;
  is_active: boolean;
};

export type ActivitiesDetailsRequest = {
  activitiesId: string;
};

export type AddNewActivitiesRequestPayload = {
  activities: AddNewActivitiesRequest;
};

export type SearchFilteredActivitiesRequestPayloadParams = {
  filteredActivities: Array<string | boolean | null>;
  hasEvidence: boolean | null;
  isActive: boolean | null;
};

export type UpdateStatusRequestPayloadParams = {
  id: string;
  updatedStatus: boolean;
};

export type ActivitiesResults = {
  results: Activities[];
  count: number;
  next: boolean;
};

export type Activities = {
  id: string;
  name: string;
  has_evidence: boolean;
  is_active: boolean;
};

export type ActivitiesWithId = {
  activitiesId: string;
  name: string;
  has_evidence: boolean;
  is_active: boolean;
};

export type ActivitiesWithoutId = {
  name: string;
  has_evidence: boolean;
  is_active: boolean;
};

export type ActivitiesDetails = {
  id: string;
  name: string;
  has_evidence: boolean;
  is_active: boolean;
};
 
export type ActivitiesState = {
  allActivities: Activities[];
  total: number,
  visited_pages: number[];
  has_next: boolean;
  currentActivitiesDetails: ActivitiesDetails;
};