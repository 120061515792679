export enum ControlTypesActions {
  EDIT_CONTROL_TYPES_REQUEST = 'EDIT_CONTROL_TYPES_REQUEST',
  EDIT_CONTROL_TYPES_SUCCESS = 'EDIT_CONTROL_TYPES_SUCCESS',
  EDIT_CONTROL_TYPES_FAILURE = 'EDIT_CONTROL_TYPES_FAILURE',
  GET_ALL_CONTROL_TYPES_REQUEST = 'GET_ALL_CONTROL_TYPES_REQUEST',
  GET_ALL_CONTROL_TYPES_SUCCESS = 'GET_ALL_CONTROL_TYPES_SUCCESS',
  GET_ALL_CONTROL_TYPES_FAILURE = 'GET_ALL_CONTROL_TYPES_FAILURE',
  ADD_NEW_CONTROL_TYPES_REQUEST = 'ADD_NEW_CONTROL_TYPES_REQUEST',
  ADD_NEW_CONTROL_TYPES_SUCCESS = 'ADD_NEW_CONTROL_TYPES_SUCCESS',
  ADD_NEW_CONTROL_TYPES_FAILURE = 'ADD_NEW_CONTROL_TYPES_FAILURE',
  GET_CONTROL_TYPES_DETAILS_REQUEST = 'GET_CONTROL_TYPES_DETAILS_REQUEST',
  GET_CONTROL_TYPES_DETAILS_SUCCESS = 'GET_CONTROL_TYPES_DETAILS_SUCCESS',
  GET_CONTROL_TYPES_DETAILS_FAILURE = 'GET_CONTROL_TYPES_DETAILS_FAILURE',
  GET_FILTERED_CONTROL_TYPES_REQUEST = 'GET_FILTERED_CONTROL_TYPES_REQUEST',
  GET_FILTERED_CONTROL_TYPES_SUCCESS = 'GET_FILTERED_CONTROL_TYPES_SUCCESS',
  GET_FILTERED_CONTROL_TYPES_FAILURE = 'GET_FILTERED_CONTROL_TYPES_FAILURE',
  RESET_THE_LAST_CONTROL_TYPES_DETAILS = 'RESET_THE_LAST_CONTROL_TYPES_DETAILS',
  ACTIVE_OR_INACTIVE_CONTROL_TYPES_REQUEST = 'ACTIVE_OR_INACTIVE_CONTROL_TYPES_REQUEST',
  ACTIVE_OR_INACTIVE_CONTROL_TYPES_SUCCESS = 'ACTIVE_OR_INACTIVE_CONTROL_TYPES_SUCCESS',
  ACTIVE_OR_INACTIVE_CONTROL_TYPES_FAILURE = 'ACTIVE_OR_INACTIVE_CONTROL_TYPES_FAILURE',  
}

export type AddNewControlTypeRequest = {
  name: string;
  is_active: boolean;
};

export type ControlTypeDetailsRequest = {
  controlTypeId: string;
};

export type AddNewControlTypeRequestPayload = {
  controlType: AddNewControlTypeRequest;
};

export type SearchFilteredControlTypeRequestPayloadParams = {
  filteredControlType: Array<string | boolean | null>;
  isActive: boolean | null;
};

export type UpdateStatusRequestPayloadParams = {
  id: string;
  updatedStatus: boolean;
};

export type ControlTypesResults = {
  results: ControlTypes[];
  count: number;
  next: boolean;
}

export type ControlTypes = {
  id: string;
  name: string;
  is_active: boolean;
};

export type ControlTypesWithId = {
  controlTypeId: string;
  name: string;
  is_active: boolean;
};

export type ControlTypesWithoutId = {
  name: string;
  is_active: boolean;
};

export type ControlTypesDetails = {
  id: string;
  name: string;
  is_active: boolean;
};
 
export type ControlTypesState = {
  allControlTypes: ControlTypes[];
  currentControlTypesDetails: ControlTypesDetails;
  visited_pages: number[],
  has_next: true,
  total: 0,
};