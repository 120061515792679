import produce from 'immer';
import { Reducer } from 'redux';

import type { AuthState } from './types';
import { AuthStateActions } from './types';

const {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  CURRENT_USER_DATA_REQUEST,
  CURRENT_USER_DATA_SUCCESS,
  CURRENT_USER_DATA_FAILURE,
  USER_CREATE_TOTP_DEVICE_REQUEST,
  USER_CREATE_TOTP_DEVICE_SUCCESS,
  USER_CREATE_TOTP_DEVICE_FAILURE,
  USER_VERIFY_OR_ENABLE_USER_TOTP_DEVICE_REQUEST,
  USER_VERIFY_OR_ENABLE_USER_TOTP_DEVICE_SUCCESS,
  USER_VERIFY_OR_ENABLE_USER_TOTP_DEVICE_FAILURE,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILURE,
  USER_TOKEN_VERIFY_REQUEST,
  USER_TOKEN_VERIFY_SUCCESS,
  USER_TOKEN_VERIFY_FAILURE,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_FORGOT_PASSWORD_FAILURE,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAILURE,
  USER_REGISTER_PASSWORD_REQUEST,
  USER_REGISTER_PASSWORD_SUCCESS,
  USER_REGISTER_PASSWORD_FAILURE,
  UPDATE_USER_PICTURE_REQUEST,
  UPDATE_USER_PICTURE_SUCCESS,
  UPDATE_USER_PICTURE_FAILURE,
  DELETE_USER_PICTURE_REQUEST,
  DELETE_USER_PICTURE_SUCCESS,
  DELETE_USER_PICTURE_FAILURE,
  USER_CHANGE_PASSWORD_REQUEST,
  USER_CHANGE_PASSWORD_SUCCESS,
  USER_CHANGE_PASSWORD_FAILURE,
  RESET_THE_USERS_LAST_EMAIL_ON_THE_FORGOT_PASSWORD_SCREEN,
} = AuthStateActions;

const initialState: AuthState = {
  user: {
    isAuthenticated: false,
    isVerified: null,
    tokenStatus: null,
    totpUrl: null,
    totpDevice: null,
    id: null,
    email: null,
    lastEmailOfForgotPassword: null,
    accessToken: null,
    accessTokenLifetime: null,
    refreshToken: null,
    refreshTokenLifetime: null,
    username: null,
    phone: null,
    position: null,
    profile: null,
    photo: null,
    expiredTokenData: {
      detail: '',
      code: '',
      messages: [
        {
          token_class: '',
          token_type: '',
          message: '',
        },
      ],
    },
    is_customer: null,
    first_access: null,
  },
};

export const authReducer: Reducer<AuthState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {      
      case USER_LOGIN_SUCCESS: {
        const { user } = action.payload;

        draft.user.isAuthenticated = true;
        draft.user.isVerified = user.isVerified;
        draft.user.tokenStatus = 'valid';
        draft.user.totpUrl = null;
        draft.user.totpDevice = user.totpDevice;
        draft.user.id = user.id;
        draft.user.email = user.email;
        draft.user.lastEmailOfForgotPassword = null;
        draft.user.accessToken = user.accessToken;
        draft.user.refreshToken = user.refreshToken;
        draft.user.accessTokenLifetime = user.accessTokenLifetime;
        draft.user.refreshTokenLifetime = user.refreshTokenLifetime;
        draft.user.username = user.username;
        draft.user.phone = user.phone;
        draft.user.position = user.position;
        draft.user.profile = user.profile;
        draft.user.photo = user.photo;
        draft.user.is_customer = user.is_customer;
        draft.user.first_access = user.first_access;

        break;
      }

      case USER_LOGIN_FAILURE: {
        draft.user.isAuthenticated = false;
        draft.user.isVerified = null;
        draft.user.tokenStatus = null;
        draft.user.totpUrl = null;
        draft.user.totpDevice = null;
        draft.user.id = null;
        draft.user.email = null;
        draft.user.lastEmailOfForgotPassword = null;
        draft.user.accessToken = null;
        draft.user.refreshToken = null;
        draft.user.accessTokenLifetime = null;
        draft.user.refreshTokenLifetime = null;
        draft.user.username = null;
        draft.user.phone = null;
        draft.user.position = null;
        draft.user.profile = null;
        draft.user.photo = null;
        draft.user.is_customer = null;
        draft.user.first_access = null;

        break;
      }      

      case CURRENT_USER_DATA_SUCCESS: {
        const { userData } = action.payload;

        draft.user.username = userData.corporate_name_or_name;
        draft.user.position = userData.position;
        draft.user.email = userData.email;
        draft.user.phone = userData.phone;

        break;
      }

      case CURRENT_USER_DATA_FAILURE: {
        draft.user.totpUrl = null;

        break;
      }

      case USER_CREATE_TOTP_DEVICE_SUCCESS: {
        const { totpUrl } = action.payload;

        draft.user.totpUrl = totpUrl;

        break;
      }

      case USER_CREATE_TOTP_DEVICE_FAILURE: {
        draft.user.totpUrl = null;

        break;
      }

      case USER_VERIFY_OR_ENABLE_USER_TOTP_DEVICE_SUCCESS: {
        const { isSuccess } = action.payload;

        if (isSuccess) {
          draft.user.isVerified = true;
          draft.user.totpDevice = true;
        }

        break;
      }

      case USER_LOGOUT_SUCCESS: {
        draft.user.isAuthenticated = false;
        draft.user.isVerified = null;
        draft.user.tokenStatus = 'invalid';
        draft.user.totpUrl = null;
        draft.user.totpDevice = null;
        draft.user.id = null;
        draft.user.email = null;
        draft.user.lastEmailOfForgotPassword = null;
        draft.user.accessToken = null;
        draft.user.refreshToken = null;
        draft.user.accessTokenLifetime = null;
        draft.user.refreshTokenLifetime = null;
        draft.user.username = null;
        draft.user.phone = null;
        draft.user.position = null;
        draft.user.profile = null;
        draft.user.photo = null;
        draft.user.is_customer = null;
        draft.user.first_access = null;

        localStorage.removeItem(
          `persist:${process.env.REACT_APP_LISA_APP_NAME}`
        );

        window.location.reload();

        break;
      }

      case USER_LOGOUT_FAILURE: {
        const { errorStatus, errorResponseData } = action.payload;

        draft.user.expiredTokenData.detail = errorResponseData?.detail;
        draft.user.expiredTokenData.code = errorResponseData?.code;
        draft.user.expiredTokenData.messages = errorResponseData?.messages;

        if (
          errorStatus === 401 &&
          errorResponseData?.code === 'token_not_valid'
        ) {
          draft.user.isAuthenticated = false;
          draft.user.isVerified = null;
          draft.user.tokenStatus = 'invalid';
          draft.user.totpUrl = null;
          draft.user.totpDevice = null;
          draft.user.id = null;
          draft.user.email = null;
          draft.user.lastEmailOfForgotPassword = null;
          draft.user.accessToken = null;
          draft.user.refreshToken = null;
          draft.user.accessTokenLifetime = null;
          draft.user.refreshTokenLifetime = null;
          draft.user.username = null;
          draft.user.phone = null;
          draft.user.position = null;
          draft.user.profile = null;
          draft.user.photo = null;
          draft.user.is_customer = null;
          draft.user.first_access = null;

          localStorage.removeItem(
            `persist:${process.env.REACT_APP_LISA_APP_NAME}`
          );

          window.location.reload();
        }

        break;
      }

      case USER_FORGOT_PASSWORD_REQUEST: {
        draft.user.lastEmailOfForgotPassword = null;

        break;
      }

      case USER_FORGOT_PASSWORD_SUCCESS: {
        const { email } = action.payload;

        draft.user.lastEmailOfForgotPassword = email;

        draft.user.isAuthenticated = false;
        draft.user.isVerified = null;
        draft.user.tokenStatus = null;
        draft.user.totpUrl = null;
        draft.user.totpDevice = null;
        draft.user.id = null;
        draft.user.email = null;
        draft.user.lastEmailOfForgotPassword = null;
        draft.user.accessToken = null;
        draft.user.refreshToken = null;
        draft.user.accessTokenLifetime = null;
        draft.user.refreshTokenLifetime = null;
        draft.user.username = null;
        draft.user.phone = null;
        draft.user.position = null;
        draft.user.profile = null;
        draft.user.photo = null;
        draft.user.is_customer = null;
        draft.user.first_access = null;

        break;
      }      

      case USER_TOKEN_VERIFY_REQUEST: {
        draft.user.expiredTokenData.detail = '';
        draft.user.expiredTokenData.code = '';
        draft.user.expiredTokenData.messages = [
          {
            token_class: '',
            token_type: '',
            message: '',
          },
        ];

        break;
      }

      case USER_TOKEN_VERIFY_SUCCESS: {
        const { detail, code, messages } = action.payload;

        draft.user.expiredTokenData.detail = detail;
        draft.user.expiredTokenData.code = code;
        draft.user.expiredTokenData.messages = messages;

        break;
      }

      case USER_TOKEN_VERIFY_FAILURE: {
        draft.user.expiredTokenData.detail = '';
        draft.user.expiredTokenData.code = '';
        draft.user.expiredTokenData.messages = [
          {
            token_class: '',
            token_type: '',
            message: '',
          },
        ];

        const { errorMessage } = action.payload;

        if (errorMessage === 'token_not_valid') {
          draft.user.tokenStatus = 'invalid';
        }

        break;
      }    

      case RESET_THE_USERS_LAST_EMAIL_ON_THE_FORGOT_PASSWORD_SCREEN: {
        draft.user.lastEmailOfForgotPassword = null;

        break;
      }

      case USER_LOGIN_REQUEST:
      case USER_LOGOUT_REQUEST:
      case CURRENT_USER_DATA_REQUEST:      
      case USER_RESET_PASSWORD_REQUEST:
      case USER_RESET_PASSWORD_SUCCESS:
      case USER_RESET_PASSWORD_FAILURE:      
      case UPDATE_USER_PICTURE_REQUEST:
      case UPDATE_USER_PICTURE_SUCCESS:
      case UPDATE_USER_PICTURE_FAILURE:
      case DELETE_USER_PICTURE_REQUEST:
      case DELETE_USER_PICTURE_SUCCESS:
      case DELETE_USER_PICTURE_FAILURE:
      case USER_CHANGE_PASSWORD_REQUEST:
      case USER_CHANGE_PASSWORD_SUCCESS:
      case USER_CHANGE_PASSWORD_FAILURE:
      case USER_FORGOT_PASSWORD_FAILURE:
      case USER_REGISTER_PASSWORD_REQUEST:
      case USER_REGISTER_PASSWORD_SUCCESS:
      case USER_REGISTER_PASSWORD_FAILURE:
      case USER_CREATE_TOTP_DEVICE_REQUEST:
      case USER_VERIFY_OR_ENABLE_USER_TOTP_DEVICE_FAILURE:
      case USER_VERIFY_OR_ENABLE_USER_TOTP_DEVICE_REQUEST:   
        break;    

      default:
    }
  });
};
