import produce from 'immer';
import {
  Reducer
} from 'redux';

import {
  ApplicationLogState,
  ApplicationLogActions,
} from './types';

const {
  GET_FILTERED_LOGS_REQUEST,
  GET_FILTERED_LOGS_SUCCESS,
  GET_FILTERED_LOGS_FAILURE,
  GET_ALL_CONTROL_LOG_REQUEST,
  GET_ALL_CONTROL_LOG_SUCCESS,
  GET_ALL_CONTROL_LOG_FAILURE,
  GET_ALL_ACCOUNTS_LOG_REQUEST,
  GET_ALL_ACCOUNTS_LOG_SUCCESS,
  GET_ALL_ACCOUNTS_LOG_FAILURE,
  GET_ALL_PROJECTS_LOG_REQUEST,
  GET_ALL_PROJECTS_LOG_SUCCESS,
  GET_ALL_PROJECTS_LOG_FAILURE,
  GET_MODEL_LOGS_DETAIL_REQUEST,
  GET_MODEL_LOGS_DETAIL_SUCCESS,
  GET_MODEL_LOGS_DETAIL_FAILURE,
  GET_ALL_CUSTOMERS_LOG_REQUEST,
  GET_ALL_CUSTOMERS_LOG_SUCCESS,
  GET_ALL_CUSTOMERS_LOG_FAILURE,
} = ApplicationLogActions;

const initialState: ApplicationLogState = {
  allControlsLog: {
    next: null,
    count: null,
    previous: null,
    last_page: null,
    results: [],
  },
  allAccountsLog: {
    next: null,
    count: null,
    previous: null,
    last_page: null,
    results: [],
  },
  allProjectsLog: {
    next: null,
    count: null,
    previous: null,
    last_page: null,
    results: [],
  },
  allCustomersLog: {
    next: null,
    count: null,
    previous: null,
    last_page: null,
    results: [],
  },
  logDetails: {
    id: '',
    object_pk: '',
    model_str: '',
    object_repr: '',
    action: '',
    timestamp: '',
    actor: '',
    changes: '',
  },
  visited_pages_projects: [],
  visited_pages_controls: [],
  visited_pages_accounts: [],
  visited_pages_customers: [],
};

export const applicationLogReducer: Reducer<ApplicationLogState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_CONTROL_LOG_REQUEST:
        break;

      case GET_ALL_CONTROL_LOG_SUCCESS: {
        const { next, count, previous, last_page, results } = action.payload;
        const pagineAlreadyVisitedControls = state.visited_pages_controls.find (page => page === last_page);

        if(!pagineAlreadyVisitedControls || draft.allControlsLog.results) {
          draft.visited_pages_controls.push(last_page);
          draft.allControlsLog.results = last_page === 1 ? results : [...state.allControlsLog.results, ...results];
          draft.allControlsLog.count = count;
          draft.allControlsLog.next = next;
          draft.allControlsLog.previous = previous;
        }

        break;
      }

      case GET_ALL_CONTROL_LOG_FAILURE: {
        draft.allControlsLog = {
          next: null,
          count: null,
          previous: null,
          last_page: null,
          results: [],
        };
        break;
      }

      case GET_ALL_ACCOUNTS_LOG_REQUEST:
        break;

      case GET_ALL_ACCOUNTS_LOG_SUCCESS: {
        const { next, count, previous, last_page, results } = action.payload;
        const pagineAlreadyVisitedAccounts = state.visited_pages_accounts.find (page => page === last_page);

        if(!pagineAlreadyVisitedAccounts || draft.allAccountsLog.results) {
          draft.visited_pages_accounts.push(last_page);
          draft.allAccountsLog.results = last_page === 1 ? results : [...state.allAccountsLog.results, ...results];
          draft.allAccountsLog.count = count;
          draft.allAccountsLog.next = next;
          draft.allAccountsLog.previous = previous;
        }

        break;
      }

      case GET_ALL_ACCOUNTS_LOG_FAILURE: {
        draft.allAccountsLog = {
          next: null,
          count: null,
          previous: null,
          last_page: null,
          results: [],
        };
        break;
      }

      case GET_MODEL_LOGS_DETAIL_REQUEST:
        break;

      case GET_MODEL_LOGS_DETAIL_SUCCESS: {
        const { details } = action.payload;
        draft.logDetails = details;
        break;
      }

      case GET_MODEL_LOGS_DETAIL_FAILURE: {
        draft.logDetails = {
          id: '',
          object_pk: '',
          model_str: '',
          object_repr: '',
          action: '',
          timestamp: '',
          actor: '',
          changes: '',
        };
        break;
      }

      case GET_ALL_PROJECTS_LOG_REQUEST:
        break;

      case GET_ALL_PROJECTS_LOG_SUCCESS: {
        const { next, count, previous, last_page, results } = action.payload;
        const pagineAlreadyVisitedProjects = state.visited_pages_projects.find (page => page === last_page);

        if(!pagineAlreadyVisitedProjects || draft.allProjectsLog.results) {
          draft.visited_pages_projects.push(last_page);
          draft.allProjectsLog.results = last_page === 1 ? results : [...state.allProjectsLog.results, ...results];
          draft.allProjectsLog.count = count;
          draft.allProjectsLog.next = next;
          draft.allProjectsLog.previous = previous;
        }

        break;
      }

      case GET_ALL_PROJECTS_LOG_FAILURE: {
        draft.allProjectsLog = {
          next: null,
          count: null,          
          previous: null,
          last_page: null,
          results: [],
        };
        break;
      }

      case GET_ALL_CUSTOMERS_LOG_REQUEST:
        break;

      case GET_ALL_CUSTOMERS_LOG_SUCCESS: {
        const { next, count, previous, last_page, results } = action.payload;
        const pagineAlreadyVisitedCustomers = state.visited_pages_customers.find (page => page === last_page);

        if(!pagineAlreadyVisitedCustomers || draft.allCustomersLog.results) {
          draft.visited_pages_controls.push(last_page);
          draft.allCustomersLog.results = last_page === 1 ? results : [...state.allCustomersLog.results, ...results];
          draft.allCustomersLog.count = count;
          draft.allCustomersLog.next = next;
          draft.allCustomersLog.previous = previous;
        }

        break;
      }

      case GET_ALL_CUSTOMERS_LOG_FAILURE: {
        draft.allCustomersLog = {
          next: null,
          count: null,
          previous: null,
          last_page: null,
          results: [],
        };
        break;
      }

      case GET_FILTERED_LOGS_REQUEST:
        break;

      case GET_FILTERED_LOGS_SUCCESS: {
        const { logs, type } = action.payload;

        switch (type) {
          case 'accounts': {
            draft.allAccountsLog = logs;
            break;
          }

          case 'controls': {
            draft.allControlsLog = logs;
            break;
          }

          case 'projects': {
            draft.allProjectsLog = logs;
            break;
          }

          case 'customers': {
            draft.allCustomersLog = logs;
            break;
          }

          default:
            break;
        }
        
        break;
      }

      case GET_FILTERED_LOGS_FAILURE:
        break;

      default: {
        return draft;
      }
    }
  });
};
