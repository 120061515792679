import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { history } from 'services/history';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import { questionnariesActionsFunctions } from 'store/modules/Manutention/questionnaries/actions';
import type { Action } from 'store/types';
import { addDelay } from 'utils/functions';
import { checkPage } from 'utils/number';

import type {
  AddNewQuizRequestPayload,
  AddNewQuizResponseData,
  EditQuizRequestPayload,
  SearchFilteredQuizRequestPayload,
  UpdateQuizStatusRequestPayload,
  Quiz,
  EditQuiz,
  QuizDetailRequestPayload,
  QuizResults,
  quantityQuizPayload,
} from './types';
import { QuestionnariesActions } from './types';

const {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE,
  NEW_QUIZ_ADDED_SUCCESS_MESSAGE,
  NEW_QUIZ_ADDED_ERROR_MESSAGE,
  SEARCH_FILTERED_QUIZ_SUCCESS_MESSAGE,
  SEARCH_FILTERED_QUIZ_ERROR_MESSAGE,
  EDIT_QUIZ_SUCCESS_MESSAGE,
  EDIT_QUIZ_ERROR_MESSAGE,
  ACTIVE_OR_INACTIVE_QUIZ_SUCCESS_MESSAGE,
  ACTIVE_OR_INACTIVE_QUIZ_ERROR_MESSAGE,
} = ToastMessages;

const {
  GET_ALL_QUESTIONNARIES_MODULE_REQUEST,
  GET_QUIZ_DETAIL_REQUEST,
  GET_FILTERED_QUIZ_REQUEST,
  ADD_NEW_QUIZ_REQUEST,
  EDIT_QUIZ_REQUEST,
  EDIT_QUIZ_ADD_QUESTION_REQUEST,
  ACTIVE_OR_INACTIVE_QUIZ_REQUEST,
  QUIZ_QUANTITY_REQUEST,
} = QuestionnariesActions;

const {
  getQuestionnariesModuleRequest,
  getQuestionnariesModuleSuccess,
  getQuestionnariesModuleFailure,
  getSearchFilteredQuizSuccess,
  getSearchFilteredQuizFailure,
  getQuizDetailSuccess,
  getQuizDetailFailure,
  addNewQuizSuccess,
  addNewQuizFailure,
  editQuizSuccess,
  editQuizFailure,
  activeOrInactiveQuizSuccess,
  activeOrInactiveQuizFailure,
  quizQuantityFormQuizSuccess,
  quizQuantityFormQuizFailure,
  resetTheQuizDetailState,
} = questionnariesActionsFunctions;

const { getLastApiFetchDataSuccess, getLastApiFetchDataFailure } =
  lastApiFetchDataActionsFunctions;

function* fetchAllQuestionnariesModuleQuestions(action: Action): Generator {
  try {
    const { payload } = action as any;
    const total: number = (yield select(state => state.questionnaires.total)) as number;
    const last_page = checkPage(payload.last_page, total);
    const path = `controls/questionnaires/?page=${last_page}`;
    const has_next = yield select(state => state.questionnaires.has_next);

    if(has_next || last_page === 1) {
      const questionnairesResponse: AxiosResponse<QuizResults> | unknown =
        yield call(api, 'GET', path, {});

      const {
        data: { results, count, next },
        config: { url },
        status,
        statusText,
      } = questionnairesResponse as AxiosResponse<QuizResults>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );
      yield put(getQuestionnariesModuleSuccess(results, count, last_page, !!next));
    }

  } catch (error) {

    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getQuestionnariesModuleFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchAddNewQuiz(action: Action): Generator {
  const { newQuiz } = action.payload as AddNewQuizRequestPayload;

  try {
    const addNewQuizResponse:
      | AxiosResponse<AddNewQuizResponseData>
      | unknown = yield call(api, 'POST', 'controls/questionnaires/create/', {
      ...newQuiz,
    });

    const {
      data: quizAdded,
      config: { url },
      status,
      statusText,
    } = addNewQuizResponse as AxiosResponse<AddNewQuizResponseData>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(addNewQuizSuccess(quizAdded));

    if(status === 200 || status === 201){
      (async () => {
        await addDelay(2000);
      })();
      
      history.push(`/quiz-visualization/${quizAdded.id}`);
      toast.success(NEW_QUIZ_ADDED_SUCCESS_MESSAGE);
    }    
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(resetTheQuizDetailState());
    yield put(addNewQuizFailure());

    if(currentError?.response?.data?.name[0] === 'questionnaire with this name already exists.')
      toast.warn('já existe um questionário com este nome.');
    else 
      toast.error(NEW_QUIZ_ADDED_ERROR_MESSAGE);
  }
}

function* fetchToUpdateQuizStatus(action: Action): Generator {
  const { quizID, newQuizStatus } =
    action.payload as UpdateQuizStatusRequestPayload;

  try {
    const updateQuizResponse:
      | AxiosResponse<{ is_active: boolean }>
      | unknown = yield call(
      api,
      'PUT',
      `controls/questionnaires/${quizID}/update-status/`,
      {
        is_active: newQuizStatus,
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = updateQuizResponse as AxiosResponse<{ is_active: boolean }>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(activeOrInactiveQuizSuccess());
    yield put(getQuestionnariesModuleRequest());

    toast.success(ACTIVE_OR_INACTIVE_QUIZ_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(activeOrInactiveQuizFailure());

    toast.error(
      currentError?.response?.data?.detail ??
        ACTIVE_OR_INACTIVE_QUIZ_ERROR_MESSAGE
    );
  }
}

function* fetchToQuizEditing(action: Action): Generator {
  const { updatedQuiz, id } = action.payload as EditQuizRequestPayload;
  const updateId = updatedQuiz.id === '' ? id : updatedQuiz.id;
  try {
    const quizEditingResponse: AxiosResponse<EditQuiz> | unknown =
      yield call(
        api,
        'PUT',
        `controls/questionnaires/${updateId}/`,
        {
          ...updatedQuiz,
        }
      );

    const {
      config: { url },
      status,
      statusText,
    } = quizEditingResponse as AxiosResponse<EditQuiz>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editQuizSuccess());
    if(status === 200 || status === 201){
      (async () => {
        await addDelay(2000);
      })();
      
      history.push(`/quiz-visualization/${updateId}`);
      toast.success(EDIT_QUIZ_SUCCESS_MESSAGE);
    }      
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(resetTheQuizDetailState());
    yield put(editQuizFailure());

    toast.error(
      EDIT_QUIZ_ERROR_MESSAGE
    );
  }
}

function* fetchSearchFilteredQuiz(action: Action): Generator {
  const { filteredQuestionnaries } =
    action.payload as SearchFilteredQuizRequestPayload;

  const hasFilteredOptionsWithoutStatus = filteredQuestionnaries
    .filter(response => response !== null && typeof response !== 'boolean')
    .join(',');

  const hasStatusFilteredOption = filteredQuestionnaries.some(
    option => typeof option === 'boolean'
  );

  const filteredOptionStatus = `?is_active=${filteredQuestionnaries.filter(
    response => typeof response === 'boolean'
  )}`;

  const filteredOptionsWithoutStatus = `?search=${filteredQuestionnaries
    .filter(response => response !== null && typeof response !== 'boolean')
    .join(',')}`;

  const routeWithFilter = `controls/questionnaires/${
    hasFilteredOptionsWithoutStatus ? filteredOptionsWithoutStatus : ''
  }${hasStatusFilteredOption ? filteredOptionStatus : ''}`;

  try {
    const questionsResponse: AxiosResponse<Quiz[]> | unknown =
      yield call(api, 'GET', routeWithFilter, {});

    const {
      data: questionnaires,
      config: { url },
      status,
      statusText,
    } = questionsResponse as AxiosResponse<Quiz[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );



    yield put(getSearchFilteredQuizSuccess(questionnaires));

    toast.success(SEARCH_FILTERED_QUIZ_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredQuizFailure());

    toast.error(
      currentError?.response?.data?.detail ??
        SEARCH_FILTERED_QUIZ_ERROR_MESSAGE
    );
  }
}

function* fetchQuizDetail(action: Action): Generator {
  const { quizID } =
    action.payload as QuizDetailRequestPayload;

  try {
    const questionDetailResponse: AxiosResponse<Quiz> | unknown =
      yield call(
        api,
        'GET',
        `controls/questionnaires/${quizID}/detail/`,
        {}
      );

    const {
      data: quizDetail,
      config: { url },
      status,
      statusText,
    } = questionDetailResponse as AxiosResponse<Quiz>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getQuizDetailSuccess(quizDetail));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(resetTheQuizDetailState());
    yield put(getQuizDetailFailure());

    toast.error(
        DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchToAddQuestionToQuizEditing(action: Action): Generator {
  const { updatedQuiz } =
    action.payload as EditQuizRequestPayload;
  try {
    const quizEditingResponse: AxiosResponse<EditQuiz> | unknown =
      yield call(
        api,
        'PATCH',
        `controls/questionnaires/${updatedQuiz.id}/`,
        {
          ...updatedQuiz,
        }
      );

    const {
      config: { url },
      status,
      statusText
    } = quizEditingResponse as AxiosResponse<EditQuiz>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText
      })
    );

    yield put(editQuizSuccess());
    yield put(getQuestionnariesModuleRequest());

    toast.success(EDIT_QUIZ_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(resetTheQuizDetailState());
    yield put(editQuizFailure());

    toast.error(
      EDIT_QUIZ_ERROR_MESSAGE
    );
  }
}

function* fetchToQuizQuantityCopy(action: Action): Generator {
  const { quizID, quantityQuiz } =
    action.payload as quantityQuizPayload;

  try {
    const quantityQuizCopyResponse:
      | AxiosResponse<{ copies: number }>
      | unknown = yield call(
      api,
      'POST',
      `controls/questionnaires/${quizID}/copy/`,
      {
        copies: quantityQuiz,
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = quantityQuizCopyResponse as AxiosResponse<{ copies: number }>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(quizQuantityFormQuizSuccess());
    yield put(getQuestionnariesModuleRequest());

    toast.success('Cópias realizadas com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(quizQuantityFormQuizFailure());

    toast.error(
      currentError?.response?.data?.detail ??
        ACTIVE_OR_INACTIVE_QUIZ_ERROR_MESSAGE
    );
  }
}

export function* questionnairesSagas() {
  yield all([
    takeLatest(GET_ALL_QUESTIONNARIES_MODULE_REQUEST, fetchAllQuestionnariesModuleQuestions),
    takeLatest(ADD_NEW_QUIZ_REQUEST, fetchAddNewQuiz),
    takeLatest(ACTIVE_OR_INACTIVE_QUIZ_REQUEST, fetchToUpdateQuizStatus),
    takeLatest(EDIT_QUIZ_REQUEST, fetchToQuizEditing),
    takeLatest(EDIT_QUIZ_ADD_QUESTION_REQUEST, fetchToAddQuestionToQuizEditing),
    takeLatest(GET_FILTERED_QUIZ_REQUEST, fetchSearchFilteredQuiz),
    takeLatest(GET_QUIZ_DETAIL_REQUEST, fetchQuizDetail),
    takeLatest(QUIZ_QUANTITY_REQUEST, fetchToQuizQuantityCopy),
  ]);
}
