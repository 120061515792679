import { useMySelector } from 'hooks/useMySelector';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { authActionsFunctions } from 'store/modules/auth/actions';
import type { AuthState } from 'store/modules/auth/types';
import type { LastApiFetchDataState } from 'store/modules/lastApiFetchData/types';

const { getTokenVerifyRequest, getUserLogoutRequest } = authActionsFunctions;

export interface CustomRouteProps extends RouteProps {
  readonly title: string;
  readonly isPrivateRoute: boolean;
}

export function CustomRoute({
  title,
  isPrivateRoute,
  ...restProps
}: CustomRouteProps) {
  const dispatch = useDispatch();

  const {
    user: { isAuthenticated, isVerified, totpDevice, tokenStatus },
  } = useMySelector<AuthState>('auth');

  const lastApiFetchData =
    useMySelector<LastApiFetchDataState>('lastApiFetchData');

  useEffect(() => {
    // token verify
    if (
      lastApiFetchData.error.status === 401 &&
      lastApiFetchData.error.url.includes('token/verify')
    ) {
      dispatch(getTokenVerifyRequest());
    }
  }, [lastApiFetchData.error.status, lastApiFetchData.error.url]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // logout
    if (lastApiFetchData.error.status === 401 && tokenStatus === 'valid') {
      dispatch(getUserLogoutRequest());
    }
  }, [lastApiFetchData.error.status, tokenStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isAuthenticated && isPrivateRoute) {
    return (
      <Route key={title} {...restProps}>
        <Redirect to='/' />
      </Route>
    );
  }

  if (
    isPrivateRoute &&
    restProps.path !== '/two-factor-authentication' &&
    restProps.path !== '/multiple-factor-authentication'
  ) {
    if (isAuthenticated && !isVerified && totpDevice) {
      return (
        <Route key={title} {...restProps}>
          <Redirect to='/two-factor-authentication' />
        </Route>
      );
    }

    if (isAuthenticated && !isVerified && !totpDevice) {
      return (
        <Route key={title} {...restProps}>
          <Redirect to='/multiple-factor-authentication' />
        </Route>
      );
    }
  }

  return <Route key={title} {...restProps} />;
}
