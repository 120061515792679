import type { Action } from 'store/types';

import {
  AddNewStandardRequestPayload,
  AllStandards,
  StandardsDetails,
  StandardEditPayload,
  StandardsActions,
  SearchFilteredStandardsRequestPayloadParams,
  StandardsDetailsRequestPayload,
  AddNewStandardDetailsRequestPayload,
  UpdateStatusRequestPayloadParams,
  UpdateStatusRequestItemsPayloadParams,
  StandardDetailsEditPayload,
  UpdateApproveRequestPayload,
} from './types';

const {
  EDIT_STANDARDS_REQUEST,
  EDIT_STANDARDS_SUCCESS,
  EDIT_STANDARDS_FAILURE,
  GET_ALL_STANDARDS_REQUEST,
  GET_ALL_STANDARDS_SUCCESS,
  GET_ALL_STANDARDS_FAILURE,
  ADD_NEW_STANDARDS_REQUEST,
  ADD_NEW_STANDARDS_SUCCESS,
  ADD_NEW_STANDARDS_FAILURE,
  GET_FILTERED_STANDARDS_REQUEST,
  GET_FILTERED_STANDARDS_SUCCESS,
  GET_FILTERED_STANDARDS_FAILURE,
  ACTIVE_OR_INACTIVE_STANDARDS_REQUEST,
  ACTIVE_OR_INACTIVE_STANDARDS_SUCCESS,
  ACTIVE_OR_INACTIVE_STANDARDS_FAILURE,
  EDIT_STANDARDS_DETAILS_REQUEST,
  EDIT_STANDARDS_DETAILS_SUCCESS,
  EDIT_STANDARDS_DETAILS_FAILURE,
  ADD_NEW_STANDARDS_DETAILS_REQUEST,
  ADD_NEW_STANDARDS_DETAILS_SUCCESS,
  ADD_NEW_STANDARDS_DETAILS_FAILURE,
  GET_STANDARDS_DETAIL_REQUEST,
  GET_STANDARDS_DETAIL_SUCCESS,
  GET_STANDARDS_DETAIL_FAILURE,
  ACTIVE_OR_INACTIVE_STANDARDS_DETAILS_REQUEST,
  ACTIVE_OR_INACTIVE_STANDARDS_DETAILS_SUCCESS,
  ACTIVE_OR_INACTIVE_STANDARDS_DETAILS_FAILURE,
  CHANGE_STANDARDS_REQUEST,
  CHANGE_STANDARDS_SUCCESS,
  CHANGE_STANDARDS_FAILURE,
} = StandardsActions;

function getStandardsRequest(): Action {
  return {
    type: GET_ALL_STANDARDS_REQUEST,
  };
}

function getStandardsSuccess(standards: AllStandards): Action {
  return {
    type: GET_ALL_STANDARDS_SUCCESS,
    payload: {
      standards,
    },
  };
}

function getStandardsFailure(): Action {
  return {
    type: GET_ALL_STANDARDS_FAILURE,
  };
}

function getSearchFilteredStandardsRequest({
  name,
  is_active
}: SearchFilteredStandardsRequestPayloadParams
): Action {
  return {
    type: GET_FILTERED_STANDARDS_REQUEST,
    payload: {
      name,
      is_active
    },
  };
}

function getSearchFilteredStandardsSuccess(standards: AllStandards): Action {
  return {
    type: GET_FILTERED_STANDARDS_SUCCESS,
    payload: {
      standards,
    },
  };
}

function getSearchFilteredStandardsFailure(): Action {
  return {
    type: GET_FILTERED_STANDARDS_FAILURE,
  };
}

function activeOrInactiveStandardsRequest({
  id,
  is_active
}: UpdateStatusRequestPayloadParams): Action {
  return {
    type: ACTIVE_OR_INACTIVE_STANDARDS_REQUEST,
    payload: {
      id,
      is_active,
    },
  };
}

function activeOrInactiveStandardsSuccess(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_STANDARDS_SUCCESS,
  };
}

function activeOrInactiveStandardsFailure(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_STANDARDS_FAILURE,
  };
}

function addNewStandardRequest({
  name,
  version,
  approved,
  approval_date,
  publication_date,
  is_active,
}: AddNewStandardRequestPayload): Action {
  return {
    type: ADD_NEW_STANDARDS_REQUEST,
    payload: {
      name,
      version,
      approved,
      approval_date,
      publication_date,
      is_active,
    },
  };
}

function addNewStandardSuccess(): Action {
  return {
    type: ADD_NEW_STANDARDS_SUCCESS,
  };
}

function addNewStandardFailure(): Action {
  return {
    type: ADD_NEW_STANDARDS_FAILURE,
  };
}

function editStandardRequest(
  {
    id,
    name,
    version,
    approved,
    approval_date,
    publication_date,
    is_active,
  }: StandardEditPayload): Action {
  return {
    type: EDIT_STANDARDS_REQUEST,
    payload: {
      id,
      name,
      version,
      approved,
      approval_date,
      publication_date,
      is_active,
    },
  };
}

function editStandardSuccess(): Action {
  return {
    type: EDIT_STANDARDS_SUCCESS,
  };
}

function editStandardFailure(): Action {
  return {
    type: EDIT_STANDARDS_FAILURE,
  };
}

function getStandardsDetailsRequest({ id }: StandardsDetailsRequestPayload): Action {
  return {
    type: GET_STANDARDS_DETAIL_REQUEST,
    payload: {
      id,
    },
  };
}

function getStandardsDetailsSuccess(standardsDetails: StandardsDetails): Action {
  return {
    type: GET_STANDARDS_DETAIL_SUCCESS,
    payload: {
      standardsDetails,
    },
  };
}

function getStandardsDetailsFailure(): Action {
  return {
    type: GET_STANDARDS_DETAIL_FAILURE,
  };
}

function activeOrInactiveStandardsDetailsRequest({
  id,
  is_active,
  standard,
}: UpdateStatusRequestItemsPayloadParams): Action {
  return {
    type: ACTIVE_OR_INACTIVE_STANDARDS_DETAILS_REQUEST,
    payload: {
      id,
      is_active,
      standard,
    },
  };
}

function activeOrInactiveStandardsDetailsSuccess(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_STANDARDS_DETAILS_SUCCESS,
  };
}

function activeOrInactiveStandardsDetailsFailure(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_STANDARDS_DETAILS_FAILURE,
  };
}

function addNewStandardDetailsRequest({
  name,
  friendly_id,
  category,
  subcategory,
  standardId,
}: AddNewStandardDetailsRequestPayload): Action {
  return {
    type: ADD_NEW_STANDARDS_DETAILS_REQUEST,
    payload: {
      name,
      friendly_id,
      category,
      subcategory,
      standardId,
    },
  };
}
function addNewStandardDetailsSuccess(): Action {
  return {
    type: ADD_NEW_STANDARDS_DETAILS_SUCCESS,
  };
}

function addNewStandardDetailsFailure(): Action {
  return {
    type: ADD_NEW_STANDARDS_DETAILS_FAILURE,
  };
}

function editStandardDetailsRequest(
  {
    id,
    name,
    friendly_id,
    category,
    subcategory,
    standardId,
  }: StandardDetailsEditPayload): Action {
  return {
    type: EDIT_STANDARDS_DETAILS_REQUEST,
    payload: {
      id,
      name,
      friendly_id,
      category,
      subcategory,
      standardId,
    },
  };
}

function editStandardDetailsSuccess(): Action {
  return {
    type: EDIT_STANDARDS_DETAILS_SUCCESS,
  };
}

function editStandardDetailsFailure(): Action {
  return {
    type: EDIT_STANDARDS_DETAILS_FAILURE,
  };
}

function changeStandardsRequest({
  id,
  approved
}: UpdateApproveRequestPayload): Action {
  return {
    type: CHANGE_STANDARDS_REQUEST,
    payload: {
      id,
      approved,
    },
  };
}

function changeStandardsSuccess(): Action {
  return {
    type: CHANGE_STANDARDS_SUCCESS,
  };
}

function changeStandardsFailure(): Action {
  return {
    type: CHANGE_STANDARDS_FAILURE,
  };
}

export const StandardsActionsFunctions = {
  editStandardRequest,
  editStandardSuccess,
  editStandardFailure,
  getStandardsRequest,
  getStandardsSuccess,
  getStandardsFailure,
  addNewStandardRequest,
  addNewStandardSuccess,
  addNewStandardFailure,
  activeOrInactiveStandardsRequest,
  activeOrInactiveStandardsSuccess,
  activeOrInactiveStandardsFailure,
  getSearchFilteredStandardsRequest,
  getSearchFilteredStandardsSuccess,
  getSearchFilteredStandardsFailure,
  editStandardDetailsRequest,
  editStandardDetailsSuccess,
  editStandardDetailsFailure,
  getStandardsDetailsRequest,
  getStandardsDetailsSuccess,
  getStandardsDetailsFailure,
  addNewStandardDetailsRequest,
  addNewStandardDetailsSuccess,
  addNewStandardDetailsFailure,
  activeOrInactiveStandardsDetailsRequest,
  activeOrInactiveStandardsDetailsSuccess,
  activeOrInactiveStandardsDetailsFailure,
  changeStandardsRequest,
  changeStandardsSuccess,
  changeStandardsFailure,
};
