import produce from 'immer';
import { Reducer } from 'redux';

import { UserProfileActions, UserProfilesState } from './types';

const {
    GET_ALL_USER_PROFILE_REQUEST,
    GET_ALL_USER_PROFILE_SUCCESS,
    GET_ALL_USER_PROFILE_FAILURE,
    GET_ONE_USER_PROFILE_REQUEST,
    GET_ONE_USER_PROFILE_SUCCESS,
    GET_ONE_USER_PROFILE_FAILURE,
    GET_ALL_GROUPS_REQUEST,
    GET_ALL_GROUPS_SUCCESS,
    GET_ALL_GROUPS_FAILURE,
    GET_ONE_GROUP_REQUEST,
    GET_ONE_GROUP_SUCCESS,
    GET_ONE_GROUP_FAILURE,
    GET_ALL_PERMISSIONS_REQUEST,
    GET_ALL_PERMISSIONS_SUCCESS,
    GET_ALL_PERMISSIONS_FAILURE,
    GET_FILTERED_USER_PROFILE_REQUEST,
    GET_FILTERED_USER_PROFILE_SUCCESS,
    GET_FILTERED_USER_PROFILE_FAILURE,
    GET_FILTERED_GROUP_REQUEST,
    GET_FILTERED_GROUP_SUCCESS,
    GET_FILTERED_GROUP_FAILURE,
    GET_FILTERED_PERMISSION_REQUEST,
    GET_FILTERED_PERMISSION_SUCCESS,
    GET_FILTERED_PERMISSION_FAILURE,
  } = UserProfileActions;

  const initialState: UserProfilesState = {
    userProfiles: [],
    filteredUserProfiles: [],
    userProfile: {
      id: '',
      name: '',
      groups: [],
      child_profiles: [],
      users: [],
      is_active: false,
    },
    groups: [],
    filteredGroups: [],
    group: {
      id: '',
      name: '',
    },
    groupPermissions: {
      accounts: [],
      controls: [],
      customers: [],
      projects: []
    },
    allPermissions: {
      accounts: [],
      controls: [],
      customers: [],
      projects: []
    },
    filteredPermissions: {
      accounts: [],
      controls: [],
      customers: [],
      projects: []
    },
    
  };

  export const userProfilesReducer: Reducer<UserProfilesState> = (
    state = initialState,
    action
  ) => {
    return produce(state, draft => {
      switch (action.type) {
        case  GET_ALL_USER_PROFILE_REQUEST: {
          break;
        }
  
        case GET_ALL_USER_PROFILE_SUCCESS: {
          const { userProfiles } = action.payload;
          const { results } = userProfiles;
          draft.userProfiles = results;
  
          break;
        }
  
        case GET_ALL_USER_PROFILE_FAILURE: {
          draft.userProfiles = [];
  
          break;
        }

        case  GET_ONE_USER_PROFILE_REQUEST: {
          break;
        }
  
        case GET_ONE_USER_PROFILE_SUCCESS: {
          const { userProfile } = action.payload;
          draft.userProfile = userProfile;
  
          break;
        }
  
        case GET_ONE_USER_PROFILE_FAILURE: {
          draft.userProfile = {
            id: '',
            name: '',
            groups: [],
            child_profiles: [],
            users: [],
            is_active: false,
          };

          break;
        }

        case  GET_ALL_GROUPS_REQUEST: {
          break;
        }
  
        case GET_ALL_GROUPS_SUCCESS: {
          const { groups } = action.payload;
          const { results } = groups;
          draft.groups = results;
  
          break;
        }
  
        case GET_ALL_GROUPS_FAILURE: {
          break;
        }

        case  GET_ONE_GROUP_REQUEST: {
          break;
        }
  
        case GET_ONE_GROUP_SUCCESS: {
          const { group, microsservice } = action.payload;
          draft.group = {
            id: group.id,
            name: group.name
          };

          const serviceKey = microsservice as keyof typeof draft.groupPermissions;
  
          draft.groupPermissions[serviceKey] = [...group.permissions];

          break;
        }
  
        case GET_ONE_GROUP_FAILURE: {
          break;
        }

        case  GET_ALL_PERMISSIONS_REQUEST: {
          break;
        }
  
        case GET_ALL_PERMISSIONS_SUCCESS: {
          const { permissions, microsservice } = action.payload;
      
          const serviceKey = microsservice as keyof typeof draft.allPermissions;
 
          draft.allPermissions[serviceKey] = [...permissions];

          break;
        }
  
        case GET_ALL_PERMISSIONS_FAILURE: {
          break;
        }

        case  GET_FILTERED_USER_PROFILE_REQUEST: {
          break;
        }
  
        case GET_FILTERED_USER_PROFILE_SUCCESS: {
          const { userProfiles } = action.payload;
          draft.userProfiles = userProfiles.results;
  
          break;
        }
  
        case GET_FILTERED_USER_PROFILE_FAILURE: {
          draft.userProfiles = [];
  
          break;
        }

        case  GET_FILTERED_GROUP_REQUEST: {
          break;
        }
  
        case GET_FILTERED_GROUP_SUCCESS: {
          const { groups } = action.payload;
          draft.groups = groups.results;  
          break;
        }
  
        case GET_FILTERED_GROUP_FAILURE: {
          draft.groups = [];
          break;
        }

        case  GET_FILTERED_PERMISSION_REQUEST: {
          break;
        }
  
        case GET_FILTERED_PERMISSION_SUCCESS: {
          const { permissions, microsservice } = action.payload;
      
          const serviceKey = microsservice as keyof typeof draft.allPermissions;
 
          draft.filteredPermissions[serviceKey] = [...permissions];

          break;
        }
  
        case GET_FILTERED_PERMISSION_FAILURE: {
          break;
        }


        default: {
          return draft;
        }
      }
    });
  };