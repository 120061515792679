export enum ApplicationLogActions {
  GET_FILTERED_LOGS_REQUEST = 'GET_FILTERED_LOGS_REQUEST',
  GET_FILTERED_LOGS_SUCCESS = 'GET_FILTERED_LOGS_SUCCESS',
  GET_FILTERED_LOGS_FAILURE = 'GET_FILTERED_LOGS_FAILURE',
  GET_ALL_CONTROL_LOG_REQUEST = 'GET_ALL_CONTROL_LOG_REQUEST',
  GET_ALL_CONTROL_LOG_SUCCESS = 'GET_ALL_CONTROL_LOG_SUCCESS',
  GET_ALL_CONTROL_LOG_FAILURE = 'GET_ALL_CONTROL_LOG_FAILURE',
  GET_ALL_ACCOUNTS_LOG_REQUEST = 'GET_ALL_ACCOUNTS_LOG_REQUEST',
  GET_ALL_ACCOUNTS_LOG_SUCCESS = 'GET_ALL_ACCOUNTS_LOG_SUCCESS',
  GET_ALL_ACCOUNTS_LOG_FAILURE = 'GET_ALL_ACCOUNTS_LOG_FAILURE',
  GET_ALL_PROJECTS_LOG_REQUEST = 'GET_ALL_PROJECTS_LOG_REQUEST',
  GET_ALL_PROJECTS_LOG_SUCCESS = 'GET_ALL_PROJECTS_LOG_SUCCESS',
  GET_ALL_PROJECTS_LOG_FAILURE = 'GET_ALL_PROJECTS_LOG_FAILURE',
  GET_MODEL_LOGS_DETAIL_REQUEST = 'GET_MODEL_LOGS_DETAIL_REQUEST',
  GET_MODEL_LOGS_DETAIL_SUCCESS = 'GET_MODEL_LOGS_DETAIL_SUCCESS',
  GET_MODEL_LOGS_DETAIL_FAILURE = 'GET_MODEL_LOGS_DETAIL_FAILURE',
  GET_ALL_CUSTOMERS_LOG_REQUEST = 'GET_ALL_CUSTOMERS_LOG_REQUEST',
  GET_ALL_CUSTOMERS_LOG_SUCCESS = 'GET_ALL_CUSTOMERS_LOG_SUCCESS',
  GET_ALL_CUSTOMERS_LOG_FAILURE = 'GET_ALL_CUSTOMERS_LOG_FAILURE',
}

export type LogModel = {
  id: string;
  object_pk: string;
  model_str: string;
  object_repr: string;
  action: string;
  timestamp: string;
  actor: string;
}

export type AllLogs = {  
  next: boolean | null;
  count: number | null;
  previous: number | null;
  last_page: number | null;
  results: LogModel[];
}

export type ModelLogsDetail = {
  id: string;
  object_pk: string;
  model_str: string;
  object_repr: string;
  action: string;
  timestamp: string;
  actor: string;
  changes: string;
}

export type ApplicationLogState = {
  allControlsLog: AllLogs;
  allAccountsLog: AllLogs;
  allProjectsLog: AllLogs;
  allCustomersLog: AllLogs;
  logDetails: ModelLogsDetail;
  visited_pages_projects: number[];
  visited_pages_controls: number[];
  visited_pages_accounts: number[];
  visited_pages_customers: number[];
};

export type LogDetailsRequestPayload = {
  id: string;
  type: string;
}

export type SearchFilteredLogsRequestPayload = {
  search: string | null;
  actor: string | null;
  time: string | null;
  type: string | null;
}

export type SearchFilteredLogsSuccessPayload = {
  logs: AllLogs;
  type: string | null;
}

export type SearchedLog = {
  search: string | null;
  actor: string | null;
  time: string | null;
  type: string | null;
};