import produce from 'immer';
import {
  Reducer
} from 'redux';

import {
  ActivitiesActions
} from './types';
import type {
  ActivitiesState
} from './types';


const {
  EDIT_ACTIVITIES_REQUEST,
  EDIT_ACTIVITIES_SUCCESS,
  EDIT_ACTIVITIES_FAILURE,
  ADD_NEW_ACTIVITIES_REQUEST,
  ADD_NEW_ACTIVITIES_SUCCESS,
  ADD_NEW_ACTIVITIES_FAILURE,
  GET_ALL_ACTIVITIES_MODULE_REQUEST,
  GET_ALL_ACTIVITIES_MODULE_SUCCESS,
  GET_ALL_ACTIVITIES_MODULE_FAILURE,
  GET_ACTIVITIES_DETAILS_REQUEST,
  GET_ACTIVITIES_DETAILS_SUCCESS,
  GET_ACTIVITIES_DETAILS_FAILURE,
  GET_FILTERED_ACTIVITIES_REQUEST,
  GET_FILTERED_ACTIVITIES_SUCCESS,
  GET_FILTERED_ACTIVITIES_FAILURE,
  RESET_THE_LAST_ACTIVITIES_DETAILS,
  ACTIVE_OR_INACTIVE_ACTIVITIES_REQUEST,
  ACTIVE_OR_INACTIVE_ACTIVITIES_SUCCESS,
  ACTIVE_OR_INACTIVE_ACTIVITIES_FAILURE,
  GET_ALL_ACTIVITIES_PROJECTSETTINGS_REQUEST,
  GET_FILTERED_ACTIVITIES_PROJECTSETTINGS_REQUEST
} = ActivitiesActions;

const initialState: ActivitiesState = {
  allActivities: [],
  visited_pages: [],
  has_next: true,
  total: 0,
  currentActivitiesDetails: {
    id: '',
    name: '',
    has_evidence: false,
    is_active: false,
  }
};

export const activitiesReducer: Reducer<ActivitiesState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_ACTIVITIES_MODULE_REQUEST:
        break;

      case GET_ALL_ACTIVITIES_MODULE_SUCCESS: {
        const { activities, total, last_page, has_next } = action.payload;
        const pagineAlreadyVisited = state.visited_pages.find (page => page === last_page);

        if(!pagineAlreadyVisited || draft.allActivities) {
          draft.visited_pages.push(last_page);
          draft.allActivities = last_page === 1 ? activities : [...state.allActivities, ...activities];
          draft.total = total;
          draft.has_next = has_next;
        }
        break;
      }

      case GET_ALL_ACTIVITIES_MODULE_FAILURE: {
        draft.allActivities = [];
        break;
      }

      case GET_ACTIVITIES_DETAILS_REQUEST:
        break;

      case GET_ACTIVITIES_DETAILS_SUCCESS: {
        const { activitiesDetails } = action.payload;
        draft.currentActivitiesDetails.id = activitiesDetails.id;
        draft.currentActivitiesDetails.name = activitiesDetails.name;
        draft.currentActivitiesDetails.has_evidence = activitiesDetails.has_evidence;
        draft.currentActivitiesDetails.is_active = activitiesDetails.is_active;
        break;
      }

      case GET_ACTIVITIES_DETAILS_FAILURE: {
        draft.currentActivitiesDetails.id = '';
        draft.currentActivitiesDetails.name = '';
        draft.currentActivitiesDetails.has_evidence = false;
        draft.currentActivitiesDetails.is_active = false;
        break;
      }

      case GET_FILTERED_ACTIVITIES_REQUEST:
        break;

      case GET_FILTERED_ACTIVITIES_SUCCESS: {
        const { activities } = action.payload;
        draft.allActivities = activities;
        break;
      }

      case GET_FILTERED_ACTIVITIES_FAILURE:
        break;

      case ADD_NEW_ACTIVITIES_REQUEST:
        break;

      case ADD_NEW_ACTIVITIES_SUCCESS:
        break;

      case ADD_NEW_ACTIVITIES_FAILURE:
        break;

      case EDIT_ACTIVITIES_REQUEST:
        break;

      case EDIT_ACTIVITIES_SUCCESS:
        break;

      case EDIT_ACTIVITIES_FAILURE:
        break;

      case ACTIVE_OR_INACTIVE_ACTIVITIES_REQUEST:
        break;

      case ACTIVE_OR_INACTIVE_ACTIVITIES_SUCCESS:
        break;

      case ACTIVE_OR_INACTIVE_ACTIVITIES_FAILURE:
        break;

      case RESET_THE_LAST_ACTIVITIES_DETAILS: {
        draft.currentActivitiesDetails.id = '';
        draft.currentActivitiesDetails.name = '';
        draft.currentActivitiesDetails.has_evidence = false;
        draft.currentActivitiesDetails.is_active = false;
        break;
      }

      case GET_ALL_ACTIVITIES_PROJECTSETTINGS_REQUEST:
        break;

      case GET_FILTERED_ACTIVITIES_PROJECTSETTINGS_REQUEST:
        break;

      default: {
        return draft;
      }
    }
  });
};
