import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import type { Action } from 'store/types';

import { crownJewelsActionsFunctions } from './actions';
import type {
  CrownJewels,
  CrownJewelsWithId,
  CrownJewelsDetails,
  CrownJewelsDetailsRequest,
  AddNewCrownJewelsRequestPayload,
  UpdateStatusRequestPayloadParams,
  SearchFilteredCrownJewelsRequestPayloadParams,
  CrownJewelsResults
} from './types';
import { CrownJewelsActions } from './types';

const {
  DEFAULT_ERROR_MESSAGE,
  EDIT_CROWNJEWELS_ERROR_MESSAGE,
  EDIT_CROWNJEWELS_SUCCESS_MESSAGE,
  NEW_CROWNJEWELS_ADDED_ERROR_MESSAGE,
  NEW_CROWNJEWELS_ADDED_SUCCESS_MESSAGE,
  GET_CROWNJEWELS_DETAILS_ERROR_MESSAGE,
  GET_CROWNJEWELS_DETAILS_SUCCESS_MESSAGE,
  SEARCH_FILTERED_CROWNJEWELS_ERROR_MESSAGE,
  SEARCH_FILTERED_CROWNJEWELS_SUCCESS_MESSAGE,
  ACTIVE_OR_INACTIVE_CROWNJEWELS_ERROR_MESSAGE,
  ACTIVE_OR_INACTIVE_CROWNJEWELS_SUCCESS_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE
} = ToastMessages;

const {
  EDIT_CROWNJEWELS_REQUEST,
  ADD_NEW_CROWNJEWELS_REQUEST,
  GET_ALL_CROWNJEWELS_REQUEST,
  GET_CROWNJEWELS_DETAILS_REQUEST,
  GET_FILTERED_CROWNJEWELS_REQUEST,
  ACTIVE_OR_INACTIVE_CROWNJEWELS_REQUEST,
  GET_ALL_CONTROL_CROWNJEWELS_REQUEST
} = CrownJewelsActions;

const {
  getCrownJewelsRequest,
  getCrownJewelsSuccess,
  getCrownJewelsFailure,
  editCrownJewelsSuccess,
  editCrownJewelsFailure,
  addNewCrownJewelsSuccess,
  addNewCrownJewelsFailure,
  getCrownJewelsDetailsSuccess,
  getCrownJewelsDetailsFailure,
  resetTheLastCrownJewelsDetails,
  activeOrInactiveCrownJewelsSuccess,
  activeOrInactiveCrownJewelsFailure,
  getSearchFilteredCrownJewelsSuccess,
  getSearchFilteredCrownJewelsFailure,
  getControlModuleCrownJewelsSuccess,
  getControlModuleCrownJewelsFailure
} = crownJewelsActionsFunctions;

const { getLastApiFetchDataSuccess, getLastApiFetchDataFailure } = lastApiFetchDataActionsFunctions;

function* fetchAllCrownJewels(): Generator {
  try {
    const crownJewelsResponse: AxiosResponse<CrownJewels[]> | unknown = yield call(
      api,
      'GET',
      'projects/config-crown-jewels/',
      {}
    );

    const {
      data: crownJewels,
      config: { url },
      status,
      statusText,
    } = crownJewelsResponse as AxiosResponse<CrownJewels[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCrownJewelsSuccess(crownJewels));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCrownJewelsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchAllControlsModuleCrownJewels(): Generator {
  try {
    const crownJewelsResponse: AxiosResponse<CrownJewelsResults> | unknown = yield call(
      api,
      'GET',
      'controls/config-crown-jewels/',
      {}
    );

    const {
      data: crownJewels,
      config: { url },
      status,
      statusText,
    } = crownJewelsResponse as AxiosResponse<CrownJewelsResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getControlModuleCrownJewelsSuccess(crownJewels.results));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getControlModuleCrownJewelsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchCrownJewelsDetails(action: Action): Generator {
  const { crownJewelsId } = action.payload as CrownJewelsDetailsRequest;

  try {
    const crownJewelsDetailsResponse: AxiosResponse<CrownJewelsDetails> | unknown = yield call(api, 'GET', `crown-jewels/${crownJewelsId}/detail/`, {});

    const {
      data: crownJewelsDetails,
      config: { url },
      status,
      statusText,
    } = crownJewelsDetailsResponse as AxiosResponse<CrownJewelsDetails>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCrownJewelsDetailsSuccess(crownJewelsDetails));

    toast.success(GET_CROWNJEWELS_DETAILS_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCrownJewelsDetailsFailure());

    toast.error(GET_CROWNJEWELS_DETAILS_ERROR_MESSAGE);
  }
}

function* fetchSearchFilteredCrownJewels(action: Action): Generator {
  const { filteredCrownJewels, isActive } = action.payload as SearchFilteredCrownJewelsRequestPayloadParams;

  const parsedResponses = filteredCrownJewels.filter(response => response !== null).join(',');

  try {
    const filteredCrownJewelsResponse: AxiosResponse<CrownJewels[]> | unknown = yield call(
      api,
      'GET',
      `projects/config-crown-jewels/?search=${parsedResponses}&&is_active=${isActive}`,
      {}
    );

    const {
      data: filteredCrownJewels,
      config: { url },
      status,
      statusText,
    } = filteredCrownJewelsResponse as AxiosResponse<CrownJewels[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredCrownJewelsSuccess(filteredCrownJewels));
    toast.success(SEARCH_FILTERED_CROWNJEWELS_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredCrownJewelsFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      SEARCH_FILTERED_CROWNJEWELS_ERROR_MESSAGE
    );
  }
}

function* fetchAddNewCrownJewels(action: Action): Generator {
  const {
    crownJewels: { name, weight, is_active },
  } = action.payload as AddNewCrownJewelsRequestPayload;

  try {
    const addNewCrownJewelsResponse: AxiosResponse<CrownJewels> | unknown = yield call(
      api,
      'POST',
      'projects/config-crown-jewels/create/',
      {
        name,
        weight,
        is_active,
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = addNewCrownJewelsResponse as AxiosResponse<CrownJewels>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(addNewCrownJewelsSuccess());
    yield put(resetTheLastCrownJewelsDetails());
    yield put(getCrownJewelsRequest());

    toast.success(NEW_CROWNJEWELS_ADDED_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(addNewCrownJewelsFailure());

    toast.error(
      currentError?.response?.data?.name[0] ??
        currentError?.response?.data?.detail ??
        NEW_CROWNJEWELS_ADDED_ERROR_MESSAGE
    );
  }
}

function* fetchToCrownJewelsEditing(action: Action): Generator {
  const { crownJewelsId, name, weight, is_active } = action.payload as CrownJewelsWithId;

  try {
    const crownJewelsEditingResponse: AxiosResponse<CrownJewels> | unknown = yield call(
      api,
      'PUT',
      `projects/config-crown-jewels/${crownJewelsId}/`,
      {
        name,
        weight,
        is_active
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = crownJewelsEditingResponse as AxiosResponse<CrownJewels>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editCrownJewelsSuccess());
    yield put(getCrownJewelsRequest());

    toast.success(EDIT_CROWNJEWELS_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editCrownJewelsFailure());

    toast.error(
      currentError?.response?.data?.detail ?? EDIT_CROWNJEWELS_ERROR_MESSAGE
    );
  }
}

function* fetchToUpdateCrownJewelsStatus(action: Action): Generator {
  const { id, updatedStatus } =
    action.payload as UpdateStatusRequestPayloadParams;

  try {
    const updateCrownJewelsStatusResponse: AxiosResponse<CrownJewels> | unknown =
      yield call(api, 'PUT', `projects/config-crown-jewels/${id}/update-status/`, {
        is_active: updatedStatus,
      });

    const {
      config: { url },
      status,
      statusText,
    } = updateCrownJewelsStatusResponse as AxiosResponse<CrownJewels>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(activeOrInactiveCrownJewelsSuccess());
    yield put(getCrownJewelsRequest());

    toast.success(ACTIVE_OR_INACTIVE_CROWNJEWELS_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(activeOrInactiveCrownJewelsFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      ACTIVE_OR_INACTIVE_CROWNJEWELS_ERROR_MESSAGE
    );
  }
}

export function* crownJewelsSagas() {
  yield all([
    takeLatest(GET_ALL_CROWNJEWELS_REQUEST, fetchAllCrownJewels),
    takeLatest(GET_CROWNJEWELS_DETAILS_REQUEST, fetchCrownJewelsDetails),
    takeLatest(GET_FILTERED_CROWNJEWELS_REQUEST, fetchSearchFilteredCrownJewels),
    takeLatest(ADD_NEW_CROWNJEWELS_REQUEST, fetchAddNewCrownJewels),
    takeLatest(EDIT_CROWNJEWELS_REQUEST, fetchToCrownJewelsEditing),
    takeLatest(ACTIVE_OR_INACTIVE_CROWNJEWELS_REQUEST, fetchToUpdateCrownJewelsStatus),
    takeLatest(GET_ALL_CONTROL_CROWNJEWELS_REQUEST, fetchAllControlsModuleCrownJewels),
  ]);
}
