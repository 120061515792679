import type { Action } from 'store/types';

import {
  ModelLogsDetail,
  ApplicationLogActions,
  LogDetailsRequestPayload,
  SearchFilteredLogsSuccessPayload,
  SearchFilteredLogsRequestPayload,
  LogModel,
} from './types';

const {
  GET_FILTERED_LOGS_REQUEST,
  GET_FILTERED_LOGS_SUCCESS,
  GET_FILTERED_LOGS_FAILURE,
  GET_ALL_CONTROL_LOG_REQUEST,
  GET_ALL_CONTROL_LOG_SUCCESS,
  GET_ALL_CONTROL_LOG_FAILURE,
  GET_ALL_ACCOUNTS_LOG_REQUEST,
  GET_ALL_ACCOUNTS_LOG_SUCCESS,
  GET_ALL_ACCOUNTS_LOG_FAILURE,
  GET_ALL_PROJECTS_LOG_REQUEST,
  GET_ALL_PROJECTS_LOG_SUCCESS,
  GET_ALL_PROJECTS_LOG_FAILURE,
  GET_MODEL_LOGS_DETAIL_REQUEST,
  GET_MODEL_LOGS_DETAIL_SUCCESS,
  GET_MODEL_LOGS_DETAIL_FAILURE,
  GET_ALL_CUSTOMERS_LOG_REQUEST,
  GET_ALL_CUSTOMERS_LOG_SUCCESS,
  GET_ALL_CUSTOMERS_LOG_FAILURE,
} = ApplicationLogActions;

function getControlsLogRequest(last_page?: number): Action {
  return {
    type: GET_ALL_CONTROL_LOG_REQUEST,
    payload: {
      last_page
    },
  };
}

function getControlsLogSuccess(
  next: boolean | null,
  count: number | null,  
  previous: number | null,
  last_page: number | null,
  results: LogModel[]
): Action {
  return {
    type: GET_ALL_CONTROL_LOG_SUCCESS,
    payload: {
      next,
      count,
      previous,
      last_page,
      results,
    },
  };
}

function getControlsLogFailure(): Action {
  return {
    type: GET_ALL_CONTROL_LOG_FAILURE,
  };
}

function getAccountsLogRequest(last_page?: number): Action {
  return {
    type: GET_ALL_ACCOUNTS_LOG_REQUEST,
    payload: {
      last_page
    },
  };
}

function getAccountsLogSuccess(
  next: boolean | null,
  count: number | null,  
  previous: number | null,
  last_page: number | null,
  results: LogModel[]
): Action {
  return {
    type: GET_ALL_ACCOUNTS_LOG_SUCCESS,
    payload: {
      next,
      count,
      previous,
      last_page,
      results,
    },
  };
}

function getAccountsLogFailure(): Action {
  return {
    type: GET_ALL_ACCOUNTS_LOG_FAILURE,
  };
}

function getLogDetailsRequest({
  id,
  type,
}: LogDetailsRequestPayload): Action {
  return {
    type: GET_MODEL_LOGS_DETAIL_REQUEST,
    payload: {
      id,
      type,
    },
  };
}

function getLogDetailsSuccess(details: ModelLogsDetail): Action {
  return {
    type: GET_MODEL_LOGS_DETAIL_SUCCESS,
    payload: {
      details,
    },
  };
}

function getLogDetailsFailure(): Action {
  return {
    type: GET_MODEL_LOGS_DETAIL_FAILURE,
  };
}

function getProjectsLogRequest(last_page?: number): Action {
  return {
    type: GET_ALL_PROJECTS_LOG_REQUEST,
    payload: {
      last_page
    },
  };
}

function getProjectsLogSuccess(
  next: boolean | null,
  count: number | null,  
  previous: number | null,
  last_page: number | null,
  results: LogModel[]): Action {
  return {
    type: GET_ALL_PROJECTS_LOG_SUCCESS,
    payload: {
      next,
      count,      
      previous,
      last_page,
      results
    },
  };
}

function getProjectsLogFailure(): Action {
  return {
    type: GET_ALL_PROJECTS_LOG_FAILURE,
  };
}

function getCustomersLogRequest(last_page?: number): Action {
  return {
    type: GET_ALL_CUSTOMERS_LOG_REQUEST,
    payload: {
      last_page
    },
  };
}

function getCustomersLogSuccess(
  next: boolean | null,
  count: number | null,  
  previous: number | null,
  last_page: number | null,
  results: LogModel[]
): Action {
  return {
    type: GET_ALL_CUSTOMERS_LOG_SUCCESS,
    payload: {
      next,
      count,      
      previous,
      last_page,
      results
    },
  };
}

function getCustomersLogFailure(): Action {
  return {
    type: GET_ALL_CUSTOMERS_LOG_FAILURE,
  };
}

function getSearchFilteredLogsRequest({
  search,
  actor,
  time,
  type,
}: SearchFilteredLogsRequestPayload): Action {
  return {
    type: GET_FILTERED_LOGS_REQUEST,
    payload: {
      search,
      actor,
      time,
      type,
    },
  };
}

function getSearchFilteredLogsSuccess({
  logs,
  type,
}: SearchFilteredLogsSuccessPayload): Action {
  return {
    type: GET_FILTERED_LOGS_SUCCESS,
    payload: {
      logs,
      type,
    },
  };
}

function getSearchFilteredLogsFailure(): Action {
  return {
    type: GET_FILTERED_LOGS_FAILURE,
  };
}

export const ApplicationLogFunctions = {
  getLogDetailsRequest,
  getLogDetailsSuccess,
  getLogDetailsFailure,
  getControlsLogRequest,
  getControlsLogSuccess,
  getControlsLogFailure,
  getAccountsLogRequest,
  getAccountsLogSuccess,
  getAccountsLogFailure,
  getProjectsLogRequest,
  getProjectsLogSuccess,
  getProjectsLogFailure,
  getCustomersLogRequest,
  getCustomersLogSuccess,
  getCustomersLogFailure,
  getSearchFilteredLogsRequest,
  getSearchFilteredLogsSuccess,
  getSearchFilteredLogsFailure,
};
