export enum ProjectSettingsActions {
  EDIT_PROJECTSETTING_REQUEST = 'EDIT_PROJECTSETTING_REQUEST',
  EDIT_PROJECTSETTING_SUCCESS = 'EDIT_PROJECTSETTING_SUCCESS',
  EDIT_PROJECTSETTING_FAILURE = 'EDIT_PROJECTSETTING_FAILURE',
  GET_ALL_PROJECTSETTING_REQUEST = 'GET_ALL_PROJECTSETTING_REQUEST',
  GET_ALL_PROJECTSETTING_SUCCESS = 'GET_ALL_PROJECTSETTING_SUCCESS',
  GET_ALL_PROJECTSETTING_FAILURE = 'GET_ALL_PROJECTSETTING_FAILURE',
  CREATE_NEW_PROJECTSETTING_REQUEST = 'CREATE_NEW_PROJECTSETTINGS_REQUEST',
  CREATE_NEW_PROJECTSETTING_SUCCESS = 'CREATE_NEW_PROJECTSETTING_SUCCESS',
  CREATE_NEW_PROJECTSETTING_FAILURE = 'CREATE_NEW_PROJECTSETTING_FAILURE',
  ADD_NEW_PROJECTSETTING_REQUEST = 'ADD_NEW_PROJECTSETTING_REQUEST',
  ADD_NEW_PROJECTSETTING_SUCCESS = 'ADD_NEW_PROJECTSETTING_SUCCESS',
  ADD_NEW_PROJECTSETTING_FAILURE = 'ADD_NEW_PROJECTSETTING_FAILURE',
  GET_PROJECTSETTING_DETAILS_REQUEST = 'GET_PROJECTSETTING_DETAILS_REQUEST',
  GET_PROJECTSETTING_DETAILS_SUCCESS = 'GET_PROJECTSETTING_DETAILS_SUCCESS',
  GET_PROJECTSETTING_DETAILS_FAILURE = 'GET_PROJECTSETTING_DETAILS_FAILURE',
  GET_FILTERED_PROJECTSETTING_REQUEST = 'GET_FILTERED_PROJECTSETTING_REQUEST',
  GET_FILTERED_PROJECTSETTING_SUCCESS = 'GET_FILTERED_PROJECTSETTING_SUCCESS',
  GET_FILTERED_PROJECTSETTING_FAILURE = 'GET_FILTERED_PROJECTSETTING_FAILURE',
  RESET_THE_LAST_PROJECTSETTING_DETAILS = 'RESET_THE_LAST_PROJECTSETTING_DETAILS',
  ACTIVE_OR_INACTIVE_PROJECTSETTING_REQUEST = 'ACTIVE_OR_INACTIVE_PROJECTSETTING_REQUEST',
  ACTIVE_OR_INACTIVE_PROJECTSETTING_SUCCESS = 'ACTIVE_OR_INACTIVE_PROJECTSETTING_SUCCESS',
  ACTIVE_OR_INACTIVE_PROJECTSETTING_FAILURE = 'ACTIVE_OR_INACTIVE_PROJECTSETTING_FAILURE',
  RESET_THE_PROJECTSETTING_DETAIL_STATE = 'RESET_THE_PROJECTSETTING_DETAIL_STATE',
  GET_ALL_QUESTIONNAIRE_SUCCESS = 'GET_ALL_QUESTIONNAIRE_SUCCESS',
  GET_ALL_QUESTIONNAIRE_FAILURE = 'GET_ALL_QUESTIONNAIRE_FAILURE',
  GET_FILTERED_QUESTIONNAIRE_SUCCESS = 'GET_FILTERED_QUESTIONNAIRE_SUCCESS',
  GET_FILTERED_QUESTIONNAIRE_FAILURE = 'GET_FILTERED_QUESTIONNAIRE_FAILURE',
  GET_ALL_QUESTIONNAIRE_PROJECTSETTINGS_REQUEST = 'GET_ALL_QUESTIONNAIRE_PROJECTSETTINGS_REQUEST',
  GET_ALL_QUESTIONNAIRE_PROJECTSETTINGS_SUCCESS = 'GET_ALL_QUESTIONNAIRE_PROJECTSETTINGS_SUCCESS',
  GET_ALL_QUESTIONNAIRE_PROJECTSETTINGS_FAILURE = 'GET_ALL_QUESTIONNAIRE_PROJECTSETTINGS_FAILURE',
  GET_FILTERED_QUESTIONNAIRE_PROJECTSETTINGS_REQUEST = 'GET_FILTERED_QUESTIONNAIRE_PROJECTSETTINGS_REQUEST',
  GET_FILTERED_QUESTIONNAIRE_PROJECTSETTINGS_SUCCESS = 'GET_FILTERED_QUESTIONNAIRE_PROJECTSETTINGS_SUCCESS',
  GET_FILTERED_QUESTIONNAIRE_PROJECTSETTINGS_FAILURE = 'GET_FILTERED_QUESTIONNAIRE_PROJECTSETTINGS_FAILURE'
}

export type ObjectWithId = {
  id: string;
};

export type Questionnaire = {
  id: string;
  name: string;
  type_of_questionnaire: string;
  is_active: boolean;
  number_of_questions: string;
};

export type SearchFilteredQuestionnaireRequestPayload = {
  filteredQuestionnaires: Array<string | boolean | null>;
  filterQuizType: string;
};

export type Activity = {
  id: string;
  name: string;
  has_evidence: boolean;
  is_active: boolean;
};

export type Checklist = {
  id: string;
  subject: string;
  is_active: boolean;
  activities: Activity[];
};

export type ConfigCrownJewel = {
  id: string;
  name: string;
  weight: string;
};

export type ProjectSettingsPostBody = {
  project_name: string,  
  is_active: boolean,
  activities: Activity[],
  checklists: Checklist[],  
  questionnaires: Questionnaire[],
  crown_jewels: ConfigCrownJewel[]
}

export type AddNewProjectSettingsRequest = {
  project_name: string,  
  is_active: boolean,
  activities: Activity[],
  checklists: Checklist[],  
  questionnaires: Questionnaire[],
  crown_jewels: ConfigCrownJewel[]
};

export type ProjectSettingsDetailsRequest = {
  projectSettingsId: string;
};
 
export type AddNewProjectSettingsRequestPayload = {
  projectSettings: AddNewProjectSettingsRequest;
};

export type SearchFilteredProjectSettingsRequestPayloadParams = {
  filteredProjectSettings: Array<string | boolean | null>;
  isActive: boolean | null;
};

export type UpdateStatusRequestPayloadParams = {
  id: string;
  updatedStatus: boolean;
};

export type ProjectSettingsResults = {
  results: ProjectSettings[];
}

export type ProjectSettings = {
  id: string;
  project_name: string,  
  is_active: boolean,
  activities: Activity[],
  checklists: Checklist[],  
  questionnaires: Questionnaire[],
  crown_jewels: ConfigCrownJewel[]
};
 
export type ProjectSettingsWithId = {
  projectSettingsId: string;
  project_name: string,  
  is_active: boolean,
  activities: Activity[],
  checklists: Checklist[],  
  questionnaires: Questionnaire[],
  crown_jewels: ConfigCrownJewel[]
};

export type ProjectSettingsWithoutId = {
  project_name: string,  
  is_active: boolean,
  activities: Activity[],
  checklists: Checklist[],  
  questionnaires: Questionnaire[],
  crown_jewels: ConfigCrownJewel[]
};

export type ProjectSettingsDetails = {
  id: string;
  project_name: string,  
  is_active: boolean,
  activities: Activity[],
  checklists: Checklist[],  
  questionnaires: Questionnaire[],
  crown_jewels: ConfigCrownJewel[]
};
 
export type ProjectSettingsState = {
  allQuestionnaires: Questionnaire[];
  allProjectSettings: ProjectSettings[];  
  currentProjectSettingsDetails: ProjectSettingsDetails;
};

export type ProjectSettingsTest = {
  id: string,
  project_name: string,  
  is_active: boolean,
  activities: Activity[],
  checklists: Checklist[],  
  questionnaires: Questionnaire[],
  crown_jewels: ConfigCrownJewel[]
}

export type ProjectSettingsWithAddAndRemove = {
  id: string,
  project_name: string,  
  is_active: boolean,
  questionnaires_add: ObjectWithId[],
  questionnaires_remove: ObjectWithId[],
  checklists_add: ObjectWithId[],
  checklists_remove: ObjectWithId[],  
  activities_add: ObjectWithId[],
  activities_remove: ObjectWithId[],  
  crown_jewels_add: ObjectWithId[],
  crown_jewels_remove: ObjectWithId[],
}

export type CreateNewProjectSettingsPayloadApi = {
  projectSettingId: string;
  projectSettingPostBody: ProjectSettingsPostBody;
}

export type EditProjectSettingsRequestPayload = {
  editProjectSettings: ProjectSettingsWithAddAndRemove;
};