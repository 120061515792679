import { ToastContainerProps } from 'react-toastify';

export enum ToastMessages {
  DEFAULT_SUCCESS_MESSAGE = 'Requisição realizada com sucesso.',
  DEFAULT_ERROR_MESSAGE = 'Requisição falhou.',
  LOGIN_SUCCESS_MESSAGE = 'Login do usuário realizada com sucesso.',
  LOGIN_ERROR_MESSAGE = 'Tentativa de login do usuário falhou.',
  LOGOUT_SUCCESS_MESSAGE = 'Logout do usuário realizada com sucesso.',
  CURRENT_USER_DATA_SUCCESS_MESSAGE = 'Seus dados foram atualizados com sucesso.',
  CURRENT_USER_DATA_ERROR_MESSAGE = 'Tentativa de capturar seus dados atualizados falhou.',
  NEW_USER_ADDED_SUCCESS_MESSAGE = 'Novo usuário adicionado com sucesso.',
  NEW_USER_ADDED_ERROR_MESSAGE = 'Tentativa de adicionar o novo usuário falhou.',
  SEARCH_FILTERED_USER_SUCCESS_MESSAGE = 'Filtragem da pesquisa do(s) usuário(s) realizada com sucesso.',
  SEARCH_FILTERED_USER_ERROR_MESSAGE = 'Filtragem da pesquisa do(s) usuário(s) falhou.',
  SEND_EMAIL_TO_NEW_USER_SUCCESS_MESSAGE = 'Email enviado para o usuário com sucesso.',
  SEND_EMAIL_TO_NEW_USER_ERROR_MESSAGE = 'Tentativa de enviar o email para o usuário falhou.',
  EDIT_USER_SUCCESS_MESSAGE = 'Edição do usuário realizada com sucesso.',
  EDIT_USER_ERROR_MESSAGE = 'Tentativa de edição do usuário falhou.',
  ACTIVE_OR_INACTIVE_USER_SUCCESS_MESSAGE = 'Edição do status do usuário realizada com sucesso.',
  ACTIVE_OR_INACTIVE_USER_ERROR_MESSAGE = 'Tentativa de edição do status do usuário falhou.',
  USER_CREATE_TOTP_DEVICE_SUCCESS_MESSAGE = 'Criação do dispositivo TOTP realizada com sucesso.',
  USER_CREATE_TOTP_DEVICE_ERROR_MESSAGE = 'Tentativa de criação do dispositivo TOTP falhou.',
  UPDATE_USER_PICTURE_SUCCESS_MESSAGE = 'Edição da sua foto foi realizada com sucesso.',
  UPDATE_USER_PICTURE_FAILURE_MESSAGE = 'Tentativa de edição da sua foto falhou.',
  DELETE_USER_PICTURE_SUCCESS_MESSAGE = 'Remoção da sua foto foi realizada com sucesso.',
  DELETE_USER_PICTURE_FAILURE_MESSAGE = 'Tentativa de remoção da sua foto falhou.',
  USER_CHANGE_PASSWORD_SUCCESS_MESSAGE = 'Alteração de senha foi realizada com sucesso.',
  USER_CHANGE_PASSWORD_FAILURE_MESSAGE = 'Tentativa de alteração da senha falhou.',
  EDIT_USER_INFO_SUCCESS_MESSAGE = 'Edição das informações do usuário foi realizada com sucesso.',
  EDIT_USER_INFO_FAILURE_MESSAGE = 'Tentativa de edição das informações do usuário falhou.',
  SEARCH_FILTERED_SECTOR_SUCCESS_MESSAGE = 'Filtragem da pesquisa do(s) setor(es) foi realizada com sucesso.',
  SEARCH_FILTERED_SECTOR_ERROR_MESSAGE = 'Tentativa de filtragem da pesquisa do(s) setor(es) falhou.',
  SEARCH_FILTERED_DEPARTMENT_SUCCESS_MESSAGE = 'Filtragem da pesquisa do(s) departamento(s) foi realizada com sucesso.',
  SEARCH_FILTERED_DEPARTMENT_ERROR_MESSAGE = 'Tentativa de filtragem da pesquisa do(s) departamento(s) falhou.',
  NEW_SECTOR_ADDED_SUCCESS_MESSAGE = 'Novo setor adicionado com sucesso.',
  NEW_SECTOR_ADDED_ERROR_MESSAGE = 'Tentativa de adicionar o novo setor falhou.',
  GET_SECTOR_DETAILS_SUCCESS_MESSAGE = 'Detalhes do setor capturados com sucesso.',
  GET_SECTOR_DETAILS_ERROR_MESSAGE = 'Tentativa de capturar detalhes do setor falhou.',
  EDIT_SECTOR_SUCCESS_MESSAGE = 'Edição do setor foi realizada com sucesso.',
  EDIT_SECTOR_ERROR_MESSAGE = 'Tentativa de edição do setor falhou.',
  ACTIVE_OR_INACTIVE_SECTOR_SUCCESS_MESSAGE = 'Edição do status do setor foi realizada com sucesso.',
  ACTIVE_OR_INACTIVE_SECTOR_ERROR_MESSAGE = 'Tentativa de edição do status do setor falhou.',
  NEW_SUB_SECTOR_ADDED_SUCCESS_MESSAGE = 'Novo subsetor adicionado com sucesso.',
  NEW_SUB_SECTOR_ADDED_ERROR_MESSAGE = 'Tentativa de adicionar o novo subsetor falhou.',
  EDIT_SUB_SECTOR_SUCCESS_MESSAGE = 'Edição do subsetor foi realizada com sucesso.',
  EDIT_SUB_SECTOR_ERROR_MESSAGE = 'Tentativa de edição do subsetor falhou.',
  ACTIVE_OR_INACTIVE_SUB_SECTOR_SUCCESS_MESSAGE = 'Edição do status do subsetor foi realizada com sucesso.',
  ACTIVE_OR_INACTIVE_SUB_SECTOR_ERROR_MESSAGE = 'Tentativa de edição do status do subsetor falhou.',
  NEW_SEGMENT_ADDED_SUCCESS_MESSAGE = 'Novo segmento adicionado com sucesso.',
  NEW_SEGMENT_ADDED_ERROR_MESSAGE = 'Tentativa de adicionar o novo segmento falhou.',
  EDIT_SEGMENT_SUCCESS_MESSAGE = 'Edição do segmento foi realizada com sucesso.',
  EDIT_SEGMENT_ERROR_MESSAGE = 'Tentativa de edição do segmento falhou.',
  ACTIVE_OR_INACTIVE_SEGMENT_SUCCESS_MESSAGE = 'Edição do status do segmento foi realizada com sucesso.',
  ACTIVE_OR_INACTIVE_SEGMENT_ERROR_MESSAGE = 'Tentativa de edição do status do segmento falhou.',
  NEW_DEPARTMENT_ADDED_SUCCESS_MESSAGE = 'Novo departamento adicionado com sucesso.',
  NEW_DEPARTMENT_ADDED_ERROR_MESSAGE = 'Tentativa de adicionar o novo departamento falhou.',
  EDIT_DEPARTMENT_SUCCESS_MESSAGE = 'Edição do departamento foi realizada com sucesso.',
  EDIT_DEPARTMENT_ERROR_MESSAGE = 'Tentativa de edição do departamento falhou.',
  ACTIVE_OR_INACTIVE_DEPARTMENT_SUCCESS_MESSAGE = 'Edição do status do departamento realizado com sucesso.',
  ACTIVE_OR_INACTIVE_DEPARTMENT_ERROR_MESSAGE = 'Tentativa de edição do status do departamento falhou.',
  NEW_VULNERABILITY_ADDED_SUCCESS_MESSAGE = 'Nova vulnerabilidade adicionada com sucesso.',
  NEW_VULNERABILITY_ADDED_ERROR_MESSAGE = 'Tentativa de adicionar a nova vulnerabilidade falhou.',
  SEARCH_FILTERED_VULNERABILITY_SUCCESS_MESSAGE = 'Filtragem da pesquisa da(s) vulnerabilidade(s) realizada com sucesso.',
  SEARCH_FILTERED_VULNERABILITY_ERROR_MESSAGE = 'Filtragem da pesquisa da(s) vulnerabilidade(s) falhou.',
  EDIT_VULNERABILITY_SUCCESS_MESSAGE = 'Edição da vulnerabilidade realizada com sucesso.',
  EDIT_VULNERABILITY_ERROR_MESSAGE = 'Tentativa de edição da vulnerabilidade falhou.',
  ACTIVE_OR_INACTIVE_VULNERABILITY_SUCCESS_MESSAGE = 'Edição do status da vulnerabilidade realizada com sucesso.',
  ACTIVE_OR_INACTIVE_VULNERABILITY_ERROR_MESSAGE = 'Tentativa de edição do status da vulnerabilidade falhou.',
  SEARCH_FILTERED_THREAT_SUCCESS_MESSAGE = 'Filtragem da pesquisa da(s) ameaça(s) foi realizada com sucesso.',
  SEARCH_FILTERED_THREAT_ERROR_MESSAGE = 'Tentativa de filtragem da pesquisa da(s) ameaça(s) falhou.',
  SEARCH_FILTERED_CONTROL_SUCCESS_MESSAGE = 'Filtragem da pesquisa do(s) controle(s) foi realizada com sucesso.',
  SEARCH_FILTERED_CONTROL_ERROR_MESSAGE = 'Tentativa de filtragem da pesquisa do(s) controle(s) falhou.',
  NEW_RISK_FACTOR_ADDED_SUCCESS_MESSAGE = 'Novo fator de risco adicionado com sucesso.',
  NEW_RISK_FACTOR_ADDED_ERROR_MESSAGE = 'Tentativa de adicionar o novo fator de risco falhou.',
  SEARCH_FILTERED_RISK_FACTOR_SUCCESS_MESSAGE = 'Filtragem da pesquisa de fator(es) de risco realizada com sucesso.',
  SEARCH_FILTERED_RISK_FACTOR_ERROR_MESSAGE = 'Filtragem da pesquisa de fator(es) de risco falhou.',
  EDIT_RISK_FACTOR_SUCCESS_MESSAGE = 'Edição do fator de risco realizada com sucesso.',
  EDIT_RISK_FACTOR_ERROR_MESSAGE = 'Tentativa de edição do fator de risco falhou.',
  ACTIVE_OR_INACTIVE_RISK_FACTOR_SUCCESS_MESSAGE = 'Edição do status do fator de risco realizada com sucesso.',
  ACTIVE_OR_INACTIVE_RISK_FACTOR_ERROR_MESSAGE = 'Tentativa de edição do status do fator de risco falhou.',
  NEW_DAMAGE_ADDED_SUCCESS_MESSAGE = 'Novo dano adicionado com sucesso.',
  NEW_DAMAGE_ADDED_ERROR_MESSAGE = 'Tentativa de adicionar o novo dano falhou.',
  SEARCH_FILTERED_DAMAGE_SUCCESS_MESSAGE = 'Filtragem da pesquisa de dano(s) realizada com sucesso.',
  SEARCH_FILTERED_DAMAGE_ERROR_MESSAGE = 'Filtragem da pesquisa de dano(s) falhou.',
  EDIT_DAMAGE_SUCCESS_MESSAGE = 'Edição do dano realizada com sucesso.',
  EDIT_DAMAGE_ERROR_MESSAGE = 'Tentativa de edição do dano falhou.',
  ACTIVE_OR_INACTIVE_DAMAGE_SUCCESS_MESSAGE = 'Edição do status do dano realizada com sucesso.',
  ACTIVE_OR_INACTIVE_DAMAGE_ERROR_MESSAGE = 'Tentativa de edição do status do dano falhou.',
  NEW_CHECKLIST_ADDED_SUCCESS_MESSAGE = 'Novo checklist adicionado com sucesso.',
  NEW_CHECKLIST_ADDED_ERROR_MESSAGE = 'Tentativa de adicionar o novo checklist falhou.',
  SEARCH_FILTERED_CHECKLIST_SUCCESS_MESSAGE = 'Filtragem da pesquisa do(s) checklist(s) foi realizada com sucesso.',
  SEARCH_FILTERED_CHECKLIST_ERROR_MESSAGE = 'Tentativa de filtragem da pesquisa do(s) checklist(s) falhou.',
  EDIT_CHECKLIST_SUCCESS_MESSAGE = 'Edição do checklist foi realizada com sucesso.',
  EDIT_CHECKLIST_ERROR_MESSAGE = 'Tentativa de edição do checklist falhou.',
  ACTIVE_OR_INACTIVE_CHECKLIST_SUCCESS_MESSAGE = 'Edição do status do checkist realizada com sucesso.',
  ACTIVE_OR_INACTIVE_CHECKLIST_ERROR_MESSAGE = 'Tentativa de edição do status do checklist falhou.',
  SEARCH_ZIP_CODE_SUCCESS_MESSAGE = 'CEP encontrado com sucesso.',
  SEARCH_ZIP_CODE_ERROR_MESSAGE = 'Falha ao buscar CEP.',
  EDIT_CUSTOMER_REQUEST_SUCCESS_MESSAGE = 'Cliente editado com sucesso.',
  EDIT_CUSTOMER_REQUEST_ERROR_MESSAGE = 'Falha ao editar informações do Cliente',
  NEW_CONTROL_TYPE_ADDED_SUCCESS_MESSAGE = 'Novo tipo de controle adicionado com sucesso.',
  NEW_CONTROL_TYPE_ADDED_ERROR_MESSAGE = 'Tentativa de adicionar o novo tipo de controle falhou.',
  GET_CONTROL_TYPE_DETAILS_SUCCESS_MESSAGE = 'Detalhes do tipo de controle capturados com sucesso.',
  GET_CONTROL_TYPE_DETAILS_ERROR_MESSAGE = 'Tentativa de capturar detalhes do tipo de controle falhou.',
  SEARCH_FILTERED_CONTROL_TYPE_SUCCESS_MESSAGE = 'Filtragem da pesquisa do(s) tipo(s) de controle(es) foi realizada com sucesso.',
  SEARCH_FILTERED_CONTROL_TYPE_ERROR_MESSAGE = 'Tentativa de filtragem da pesquisa do(s) tipo(s) de controle(es) falhou.',
  EDIT_CONTROL_TYPE_SUCCESS_MESSAGE = 'Edição do tipo de controle foi realizada com sucesso.',
  EDIT_CONTROL_TYPE_ERROR_MESSAGE = 'Tentativa de edição do tipo de controle falhou.',
  ACTIVE_OR_INACTIVE_CONTROL_TYPE_SUCCESS_MESSAGE = 'Edição do status do tipo de controle foi realizada com sucesso.',
  ACTIVE_OR_INACTIVE_CONTROL_TYPE_ERROR_MESSAGE = 'Tentativa de edição do status do tipo de controle falhou.',
  NEW_ACTIVITIES_ADDED_SUCCESS_MESSAGE = 'Nova atividade adicionada com sucesso.',
  NEW_ACTIVITIES_ADDED_ERROR_MESSAGE = 'Tentativa de adicionar uma nova atividade falhou.',
  GET_ACTIVITIES_DETAILS_SUCCESS_MESSAGE = 'Detalhes da atividade capturadas com sucesso.',
  GET_ACTIVITIES_DETAILS_ERROR_MESSAGE = 'Tentativa de capturar detalhes da atividade falhou.',
  SEARCH_FILTERED_ACTIVITIES_SUCCESS_MESSAGE = 'Filtragem da pesquisa da(s) atividade(s) foi realizada com sucesso.',
  SEARCH_FILTERED_ACTIVITIES_ERROR_MESSAGE = 'Tentativa de filtragem da pesquisa da(s) atividade(s) falhou.',
  EDIT_ACTIVITIES_SUCCESS_MESSAGE = 'Edição da atividade foi realizada com sucesso.',
  EDIT_ACTIVITIES_ERROR_MESSAGE = 'Tentativa de edição da atividade falhou.',
  ACTIVE_OR_INACTIVE_ACTIVITIES_SUCCESS_MESSAGE = 'Edição do status da atividade foi realizada com sucesso.',
  ACTIVE_OR_INACTIVE_ACTIVITIES_ERROR_MESSAGE = 'Tentativa de edição do status da atividade falhou.',
  NEW_QUESTION_ADDED_SUCCESS_MESSAGE = 'Nova pergunta adicionada com sucesso.',
  NEW_QUESTION_ADDED_ERROR_MESSAGE = 'Tentativa de adicionar a nova pergunta falhou.',
  SEARCH_FILTERED_QUESTION_SUCCESS_MESSAGE = 'Filtragem da pesquisa da(s) pergunta(s) foi realizada com sucesso.',
  SEARCH_FILTERED_QUESTION_ERROR_MESSAGE = 'Tentativa de filtragem da pesquisa da(s) pergunta(s) falhou.',
  EDIT_QUESTION_SUCCESS_MESSAGE = 'Edição da pergunta foi realizada com sucesso.',
  EDIT_QUESTION_ERROR_MESSAGE = 'Tentativa de edição da pergunta falhou.',
  ACTIVE_OR_INACTIVE_QUESTION_SUCCESS_MESSAGE = 'Edição do status da pergunta realizada com sucesso.',
  ACTIVE_OR_INACTIVE_QUESTION_ERROR_MESSAGE = 'Tentativa de edição do status da pergunta falhou.',
  NEW_CROWNJEWELS_ADDED_SUCCESS_MESSAGE = 'Nova jóia da coroa adicionada com sucesso.',
  NEW_CROWNJEWELS_ADDED_ERROR_MESSAGE = 'Tentativa de adicionar uma nova jóia da coroa falhou.',
  GET_CROWNJEWELS_DETAILS_SUCCESS_MESSAGE = 'Detalhes da jóia da coroa capturadas com sucesso.',
  GET_CROWNJEWELS_DETAILS_ERROR_MESSAGE = 'Tentativa de capturar detalhes da jóia da coroa falhou.',
  SEARCH_FILTERED_CROWNJEWELS_SUCCESS_MESSAGE = 'Filtragem da pesquisa da(s) jóia(s) da coroa foi realizada com sucesso.',
  SEARCH_FILTERED_CROWNJEWELS_ERROR_MESSAGE = 'Tentativa de filtragem da pesquisa da(s) jóia(s) da coroa falhou.',
  EDIT_CROWNJEWELS_SUCCESS_MESSAGE = 'Edição da jóia da coroa foi realizada com sucesso.',
  EDIT_CROWNJEWELS_ERROR_MESSAGE = 'Tentativa de edição da jóia da coroa falhou.',
  ACTIVE_OR_INACTIVE_CROWNJEWELS_SUCCESS_MESSAGE = 'Edição do status da jóia da coroa foi realizada com sucesso.',
  ACTIVE_OR_INACTIVE_CROWNJEWELS_ERROR_MESSAGE = 'Tentativa de edição do status da jóia da coroa falhou.',
  EDIT_PROJECTSETTINGS_ERROR_MESSAGE = 'Tentativa de edição da configuração do projeto falhou.',
  EDIT_PROJECTSETTINGS_SUCCESS_MESSAGE = 'Edição da configuração do projeto foi realizada com sucesso.',
  NEW_PROJECTSETTINGS_ADDED_ERROR_MESSAGE = 'Tentativa de adicionar uma nova configuração do projeto falhou.',
  NEW_PROJECTSETTINGS_ADDED_SUCCESS_MESSAGE = 'Nova configuração do projeto adicionada com sucesso.',
  GET_PROJECTSETTINGS_DETAILS_ERROR_MESSAGE = 'Tentativa de capturar detalhes da configuração do projeto falhou.',
  GET_PROJECTSETTINGS_DETAILS_SUCCESS_MESSAGE = 'Detalhes da configuração do projeto capturadas com sucesso.',
  SEARCH_FILTERED_PROJECTSETTINGS_ERROR_MESSAGE = 'Tentativa de filtragem da pesquisa da configuração do projeto falhou.',
  SEARCH_FILTERED_PROJECTSETTINGS_SUCCESS_MESSAGE = 'Filtragem da pesquisa da configuração do projeto foi realizada com sucesso.',
  ACTIVE_OR_INACTIVE_PROJECTSETTINGS_ERROR_MESSAGE = 'Tentativa de edição do status da configuração do projeto falhou.',
  ACTIVE_OR_INACTIVE_PROJECTSETTINGS_SUCCESS_MESSAGE = 'Edição do status da configuração do projeto foi realizada com sucesso.',
  NEW_QUIZ_ADDED_SUCCESS_MESSAGE = 'Novo questionário adicionada com sucesso.',
  NEW_QUIZ_ADDED_ERROR_MESSAGE = 'Tentativa de adicionar o novo questionário falhou.',
  SEARCH_FILTERED_QUIZ_SUCCESS_MESSAGE = 'Filtragem da pesquisa da(s) questionário(s) foi realizada com sucesso.',
  SEARCH_FILTERED_QUIZ_ERROR_MESSAGE = 'Tentativa de filtragem da pesquisa da(s) questionário(s) falhou.',
  EDIT_QUIZ_SUCCESS_MESSAGE = 'Edição do questionário foi realizada com sucesso.',
  EDIT_QUIZ_ERROR_MESSAGE = 'Tentativa de edição do questionário falhou.',
  ACTIVE_OR_INACTIVE_QUIZ_SUCCESS_MESSAGE = 'Edição do status do questionário realizada com sucesso.',
  ACTIVE_OR_INACTIVE_QUIZ_ERROR_MESSAGE = 'Tentativa de edição do status do questionário falhou.',
  SEARCH_FILTERED_QUESTIONNAIRE_SUCCESS_MESSAGE = 'Filtragem da pesquisa do(s) questionário(s) foi realizada com sucesso.',
  SEARCH_FILTERED_QUESTIONNAIRE_ERROR_MESSAGE = 'Tentativa de filtragem da pesquisa do(s) questionário(s) falhou.',
  SEARCH_FILTERED_CVE_SUCCESS_MESSAGE = 'Filtragem da pesquisa da(s) cve(s) foi realizada com sucesso.',
  SEARCH_FILTERED_CVE_ERROR_MESSAGE = 'Tentativa de filtragem da pesquisa da(s) cve(s) falhou.',
  SEARCH_FILTERED_CUSTOMER_PROJECT_ERROR_MESSAGE = 'Tentativa de filtragem de projetos falhou.',
  SEARCH_FILTERED_CUSTOMER_PROJECT_SUCCESS_MESSAGE = 'Filtragem da pesquisa de projetos foi realizada com sucesso.',
  SEARCH_FILTERED_SOLUTIONS_SUCCESS_MESSAGE = 'Filtragem da pesquisa de soluções foi realizada com sucesso.',
  SEARCH_FILTERED_SOLUTIONS_ERROR_MESSAGE = 'Tentativa de filtragem de soluções falhou.',
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE = 'Você não possui permissão para realizar esta ação.',
}

export const toastDefaultProps: Partial<ToastContainerProps> = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: true,
  closeOnClick: true,
  pauseOnHover: true,
  pauseOnFocusLoss: true,
  draggable: true,
};
