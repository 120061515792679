export enum MarketAverageActions {
  GET_ALL_MARKET_AVERAGE_REQUEST = 'GET_ALL_MARKET_AVERAGE_REQUEST',
  GET_ALL_MARKET_AVERAGE_SUCCESS = 'GET_ALL_MARKET_AVERAGE_SUCCESS',
  GET_ALL_MARKET_AVERAGE_FAILURE = 'GET_ALL_MARKET_AVERAGE_FAILURE',
  GET_MARKET_SCORECARD_AVERAGE_REQUEST = 'GET_MARKET_SCORECARD_AVERAGE_REQUEST',
  GET_MARKET_SCORECARD_AVERAGE_SUCCESS = 'GET_MARKET_SCORECARD_AVERAGE_SUCCESS',
  GET_MARKET_SCORECARD_AVERAGE_FAILURE = 'GET_MARKET_SCORECARD_AVERAGE_FAILURE',
  GET_MARKET_AVERAGE_DETAILS_REQUEST = 'GET_MARKET_AVERAGE_DETAILS_REQUEST',
  GET_MARKET_AVERAGE_DETAILS_SUCCESS = 'GET_MARKET_AVERAGE_DETAILS_SUCCESS',
  GET_MARKET_AVERAGE_DETAILS_FAILURE = 'GET_MARKET_AVERAGE_DETAILS_FAILURE',
}

export type Segment = {
  id: string;
  name: string;
  qtd_projects: number;
  average_final_score: number;
  rating_category: string;
};

export type SubSector = {
  id: string;
  name: string;
  qtd_projects: number;
  average_final_score: number;
  rating_category: string;
  segments: Segment[];
};

export type Sector = {
  id: string;
  name: string;
  qtd_projects: number;
  rating_category: string;
  average_final_score: number;
  subsectors: SubSector[];
};

export type MarketScoreCardAverage = {
  average_score_card_rating: string;
  from_number: number;
  to_number: number;
  average_final_score: number;
}

export type MarketAverageProject = {
  id: string;
  name: string;
}

export type AverageScoreCardRating = {
  id: string;
  name: string;
  category: string;
  from: number;
  to: number;
}

export type MarketAverageDetail = {
  id: string;
  name: string;
  average_final_score: number;
  average_score_card_rating: AverageScoreCardRating;
  projects: MarketAverageProject[];
}

export type MarketAverageState = {
  marketAverageOfAllSectors: Sector[];
  marketScoreCardAverage: MarketScoreCardAverage;
  marketAverageDetail: MarketAverageDetail;
};

export type MarketAverageResults = {
  results: Sector[];
  count: number;
  next: boolean;
  previous: boolean;
};

export type ApiFetchMarketAverageDetailsRequestPayloadParams = {
  id: string;
  type: string;
}
