import type { Action } from 'store/types';

import { CrownJewelsActions } from './types';
import type {
  CrownJewels,
  CrownJewelsDetails,
  AddNewCrownJewelsRequest
} from './types';

const {
  EDIT_CROWNJEWELS_REQUEST,
  EDIT_CROWNJEWELS_SUCCESS,
  EDIT_CROWNJEWELS_FAILURE,
  ADD_NEW_CROWNJEWELS_REQUEST,
  ADD_NEW_CROWNJEWELS_SUCCESS,
  ADD_NEW_CROWNJEWELS_FAILURE,
  GET_ALL_CROWNJEWELS_REQUEST,
  GET_ALL_CROWNJEWELS_SUCCESS,
  GET_ALL_CROWNJEWELS_FAILURE,
  GET_CROWNJEWELS_DETAILS_REQUEST,
  GET_CROWNJEWELS_DETAILS_SUCCESS,
  GET_CROWNJEWELS_DETAILS_FAILURE,
  GET_FILTERED_CROWNJEWELS_REQUEST,
  GET_FILTERED_CROWNJEWELS_SUCCESS,
  GET_FILTERED_CROWNJEWELS_FAILURE,
  RESET_THE_LAST_CROWNJEWELS_DETAILS,
  ACTIVE_OR_INACTIVE_CROWNJEWELS_REQUEST,
  ACTIVE_OR_INACTIVE_CROWNJEWELS_SUCCESS,
  ACTIVE_OR_INACTIVE_CROWNJEWELS_FAILURE,
  GET_ALL_CONTROL_CROWNJEWELS_REQUEST,
  GET_ALL_CONTROL_CROWNJEWELS_SUCCESS,
  GET_ALL_CONTROL_CROWNJEWELS_FAILURE
} = CrownJewelsActions;

function getCrownJewelsRequest(): Action {
  return {
    type: GET_ALL_CROWNJEWELS_REQUEST,
  };
}

function getCrownJewelsSuccess(crownJewels: CrownJewels[]): Action {
  return {
    type: GET_ALL_CROWNJEWELS_SUCCESS,
    payload: {
      crownJewels,
    },
  };
}

function getCrownJewelsFailure(): Action {
  return {
    type: GET_ALL_CROWNJEWELS_FAILURE,
  };
}

function getControlModuleCrownJewelsRequest(): Action {
  return {
    type: GET_ALL_CONTROL_CROWNJEWELS_REQUEST,
  };
}

function getControlModuleCrownJewelsSuccess(controlCrownJewels: CrownJewels[]): Action {
  return {
    type: GET_ALL_CONTROL_CROWNJEWELS_SUCCESS,
    payload: {
      controlCrownJewels,
    },
  };
}

function getControlModuleCrownJewelsFailure(): Action {
  return {
    type: GET_ALL_CONTROL_CROWNJEWELS_FAILURE,
  };
}

function getCrownJewelsDetailsRequest(crownJewelsId: string): Action {
  return {
    type: GET_CROWNJEWELS_DETAILS_REQUEST,
    payload: {
      crownJewelsId,
    },
  };
}

function getCrownJewelsDetailsSuccess(crownJewelsDetails: CrownJewelsDetails): Action {
  return {
    type: GET_CROWNJEWELS_DETAILS_SUCCESS,
    payload: {
      crownJewelsDetails,
    },
  };
}

function getCrownJewelsDetailsFailure(): Action {
  return {
    type: GET_CROWNJEWELS_DETAILS_FAILURE,
  };
}

function getSearchFilteredCrownJewelsRequest(
  filteredCrownJewels: Array<string | boolean | null>,
  weight: string | null,
  isActive: boolean | null
): Action {
  return {
    type: GET_FILTERED_CROWNJEWELS_REQUEST,
    payload: {
      filteredCrownJewels,
      weight,
      isActive
    },
  };
}

function getSearchFilteredCrownJewelsSuccess(crownJewels: CrownJewels[]): Action {
  return {
    type: GET_FILTERED_CROWNJEWELS_SUCCESS,
    payload: {
      crownJewels,
    },
  };
}

function getSearchFilteredCrownJewelsFailure(): Action {
  return {
    type: GET_FILTERED_CROWNJEWELS_FAILURE,
  };
}

function addNewCrownJewelsRequest(crownJewels: AddNewCrownJewelsRequest): Action {
  return {
    type: ADD_NEW_CROWNJEWELS_REQUEST,
    payload: {
      crownJewels,
    },
  };
}

function addNewCrownJewelsSuccess(): Action {
  return {
    type: ADD_NEW_CROWNJEWELS_SUCCESS,
  };
}

function addNewCrownJewelsFailure(): Action {
  return {
    type: ADD_NEW_CROWNJEWELS_FAILURE,
  };
}

function editCrownJewelsRequest(crownJewelsId: string, name: string, weight: string, is_active: boolean): Action {
  return {
    type: EDIT_CROWNJEWELS_REQUEST,
    payload: {
      crownJewelsId,
      name,
      weight,
      is_active
    },
  };
}

function editCrownJewelsSuccess(): Action {
  return {
    type: EDIT_CROWNJEWELS_SUCCESS,
  };
}

function editCrownJewelsFailure(): Action {
  return {
    type: EDIT_CROWNJEWELS_FAILURE,
  };
}

function activeOrInactiveCrownJewelsRequest(
  id: string,
  updatedStatus: boolean
): Action {
  return {
    type: ACTIVE_OR_INACTIVE_CROWNJEWELS_REQUEST,
    payload: {
      id,
      updatedStatus,
    },
  };
}

function activeOrInactiveCrownJewelsSuccess(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_CROWNJEWELS_SUCCESS,
  };
}

function activeOrInactiveCrownJewelsFailure(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_CROWNJEWELS_FAILURE,
  };
}

function resetTheLastCrownJewelsDetails(): Action {
  return {
    type: RESET_THE_LAST_CROWNJEWELS_DETAILS,
  };
}

function getSearchFilteredCrownJewelRequest(
  filteredCrownJewels: Array<string | boolean | null>
): Action {
  return {
    type: GET_FILTERED_CROWNJEWELS_REQUEST,
    payload: {
      filteredCrownJewels,
    },
  };
}

export const crownJewelsActionsFunctions = {
  getCrownJewelsRequest
  ,getCrownJewelsSuccess
  ,getCrownJewelsFailure
  ,editCrownJewelsRequest
  ,editCrownJewelsSuccess
  ,editCrownJewelsFailure
  ,addNewCrownJewelsRequest
  ,addNewCrownJewelsSuccess
  ,addNewCrownJewelsFailure
  ,getCrownJewelsDetailsRequest
  ,getCrownJewelsDetailsSuccess
  ,getCrownJewelsDetailsFailure
  ,resetTheLastCrownJewelsDetails
  ,getSearchFilteredCrownJewelRequest
  ,activeOrInactiveCrownJewelsRequest
  ,activeOrInactiveCrownJewelsSuccess
  ,activeOrInactiveCrownJewelsFailure
  ,getSearchFilteredCrownJewelsRequest
  ,getSearchFilteredCrownJewelsSuccess
  ,getSearchFilteredCrownJewelsFailure
  ,getControlModuleCrownJewelsRequest
  ,getControlModuleCrownJewelsSuccess
  ,getControlModuleCrownJewelsFailure
};
