import produce from 'immer';
import { Reducer } from 'redux';

import { ControlActions, ControlState } from './types';

const {
  GET_CONTROL_DETAIL_REQUEST,
  GET_CONTROL_DETAIL_SUCCESS,
  GET_CONTROL_DETAIL_FAILURE,
  CREATE_NEW_CONTROL_REQUEST,
  CREATE_NEW_CONTROL_SUCCESS,
  CREATE_NEW_CONTROL_FAILURE,
  EDIT_CONTROL_REGISTRATION_REQUEST,
  EDIT_CONTROL_REGISTRATION_SUCCESS,
  EDIT_CONTROL_REGISTRATION_FAILURE,
  EDIT_CONTROL_REQUEST,
  EDIT_CONTROL_SUCCESS,
  EDIT_CONTROL_FAILURE,
  EDIT_CONTROL_STATUS_REQUEST,
  EDIT_CONTROL_STATUS_SUCCESS,
  EDIT_CONTROL_STATUS_FAILURE
} = ControlActions;

const initialState: ControlState = {
  controlDetail: {
    attack_complexity: [],
    attack_vector: [],
    availability: false,
    availability_level: [],
    benefit: 0,
    category: {
      id: '',
      name: '',
      description: '',
      importance: 0
    },
    class_name: {
      id: '',
      name: '',
    },
    confidentiality: false,
    confidentiality_level: [],
    control_type: {
      id: '',
      name: '',
    },
    cost: 0,
    cost_benefit: 0,
    detect: false,
    final_benefit: 0,
    friendly_id: '',
    group_name: {
      id: '',
      name: '',
    },
    id: '',
    identify: false,
    integrity: false,
    integrity_level: [],
    is_active: false,
    name: '',
    privileges_required: [],
    protect: false,
    recover: false,
    respond: false,
    risk_factors: [],
    scope: [],
    subcontrols: [],
    technical_capacity: 0,
    threats: [],
    type_name: {
      id: '',
      name: '',
    },
    user_interaction: [],
    vulnerabilities: []
  }
};

export const controlReducer: Reducer<ControlState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      
      case GET_CONTROL_DETAIL_REQUEST: {
        break;
      }

      case GET_CONTROL_DETAIL_SUCCESS: {
        const { control } = action.payload;

        draft.controlDetail = control;

        break;
      }

      case GET_CONTROL_DETAIL_FAILURE: {
        draft.controlDetail = {
          attack_complexity: [],
          attack_vector: [],
          availability: false,
          availability_level: [],
          benefit: 0,
          category: {
            id: '',
            name: '',
            description: '',
            importance: 0
          },
          class_name: {
            id: '',
            name: '',
          },
          confidentiality: false,
          confidentiality_level: [],
          control_type: {
            id: '',
            name: '',
          },
          cost: 0,
          cost_benefit: 0,
          detect: false,
          final_benefit: 0,
          friendly_id: '',
          group_name: {
            id: '',
            name: '',
          },
          id: '',
          identify: false,
          integrity: false,
          integrity_level: [],
          is_active: false,
          name: '',
          privileges_required: [],
          protect: false,
          recover: false,
          respond: false,
          risk_factors: [],
          scope: [],
          subcontrols: [],
          technical_capacity: 0,
          threats: [],
          type_name: {
            id: '',
            name: '',
          },
          user_interaction: [],
          vulnerabilities: []
        };

        break;
      }

      case CREATE_NEW_CONTROL_REQUEST: {
        break;
      }

      case CREATE_NEW_CONTROL_SUCCESS: {
        break;
      }

      case CREATE_NEW_CONTROL_FAILURE: {
        break;
      }

      case EDIT_CONTROL_STATUS_REQUEST: {
        break;
      }

      case EDIT_CONTROL_STATUS_SUCCESS: {
        break;
      }

      case EDIT_CONTROL_STATUS_FAILURE: {
        break;
      }
      
      case EDIT_CONTROL_REGISTRATION_REQUEST: {
        break;
      }

      case EDIT_CONTROL_REGISTRATION_SUCCESS: {
        break;
      }

      case EDIT_CONTROL_REGISTRATION_FAILURE: {
        break;
      }

      case EDIT_CONTROL_REQUEST: {
        break;
      }

      case EDIT_CONTROL_SUCCESS: {
        break;
      }

      case EDIT_CONTROL_FAILURE: {
        break;
      }

      default: {
        return draft;
      }
    }
  });
};
