import produce from 'immer';
import { Reducer } from 'redux';

import type { ControlTypesState } from './types';
import { ControlTypesActions } from './types';

const {
  EDIT_CONTROL_TYPES_REQUEST,
  EDIT_CONTROL_TYPES_SUCCESS,
  EDIT_CONTROL_TYPES_FAILURE,
  ADD_NEW_CONTROL_TYPES_REQUEST,
  ADD_NEW_CONTROL_TYPES_SUCCESS,
  ADD_NEW_CONTROL_TYPES_FAILURE,
  GET_ALL_CONTROL_TYPES_REQUEST,
  GET_ALL_CONTROL_TYPES_SUCCESS,
  GET_ALL_CONTROL_TYPES_FAILURE,
  GET_CONTROL_TYPES_DETAILS_REQUEST,
  GET_CONTROL_TYPES_DETAILS_SUCCESS,
  GET_CONTROL_TYPES_DETAILS_FAILURE,
  GET_FILTERED_CONTROL_TYPES_REQUEST,
  GET_FILTERED_CONTROL_TYPES_SUCCESS,
  GET_FILTERED_CONTROL_TYPES_FAILURE,
  RESET_THE_LAST_CONTROL_TYPES_DETAILS,
  ACTIVE_OR_INACTIVE_CONTROL_TYPES_REQUEST,
  ACTIVE_OR_INACTIVE_CONTROL_TYPES_SUCCESS,
  ACTIVE_OR_INACTIVE_CONTROL_TYPES_FAILURE,
} = ControlTypesActions;

const initialState: ControlTypesState = {
  allControlTypes: [],
  visited_pages: [],
  has_next: true,
  total: 0,
  currentControlTypesDetails: {
    id: '',
    name: '',
    is_active: false,
  }
};

export const controlTypesReducer: Reducer<ControlTypesState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_CONTROL_TYPES_REQUEST:
        break;

      case GET_ALL_CONTROL_TYPES_SUCCESS: {
        const { controlTypes, total, last_page, has_next } = action.payload;
        const pagineAlreadyVisited = state.visited_pages.find (page => page === last_page);

        if(!pagineAlreadyVisited || draft.allControlTypes) {
          draft.visited_pages.push(last_page);
          draft.allControlTypes = last_page === 1 ? controlTypes : [...state.allControlTypes, ...controlTypes];
          draft.total = total;
          draft.has_next = has_next;
        }

        break;
      }

      case GET_ALL_CONTROL_TYPES_FAILURE: {
        draft.allControlTypes = [];
        break;
      }

      case GET_CONTROL_TYPES_DETAILS_REQUEST:
        break;

      case GET_CONTROL_TYPES_DETAILS_SUCCESS: {
        const { controlTypeDetails } = action.payload;
        draft.currentControlTypesDetails.id = controlTypeDetails.id;
        draft.currentControlTypesDetails.name = controlTypeDetails.name;
        draft.currentControlTypesDetails.is_active = controlTypeDetails.is_active;
        break;
      }

      case GET_CONTROL_TYPES_DETAILS_FAILURE: {
        draft.currentControlTypesDetails.id = '';
        draft.currentControlTypesDetails.name = '';
        draft.currentControlTypesDetails.is_active = false;
        break;
      }

      case GET_FILTERED_CONTROL_TYPES_REQUEST:
        break;

      case GET_FILTERED_CONTROL_TYPES_SUCCESS: {
        const { controlTypes } = action.payload;
        draft.allControlTypes = controlTypes;
        break;
      }

      case GET_FILTERED_CONTROL_TYPES_FAILURE:
        break;

      case ADD_NEW_CONTROL_TYPES_REQUEST:
        break;

      case ADD_NEW_CONTROL_TYPES_SUCCESS:
        break;

      case ADD_NEW_CONTROL_TYPES_FAILURE:
        break;

      case EDIT_CONTROL_TYPES_REQUEST:
        break;

      case EDIT_CONTROL_TYPES_SUCCESS:
        break;

      case EDIT_CONTROL_TYPES_FAILURE:
        break;

      case ACTIVE_OR_INACTIVE_CONTROL_TYPES_REQUEST:
        break;

      case ACTIVE_OR_INACTIVE_CONTROL_TYPES_SUCCESS:
        break;

      case ACTIVE_OR_INACTIVE_CONTROL_TYPES_FAILURE:
        break;

      case RESET_THE_LAST_CONTROL_TYPES_DETAILS: {
        draft.currentControlTypesDetails.id = '';
        draft.currentControlTypesDetails.name = '';
        draft.currentControlTypesDetails.is_active = false;
        break;
      }

      default: {
        return draft;
      }
    }
  });
};
