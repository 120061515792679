import type { Action } from 'store/types';

import { AddNewCustomerData, Classes, Customer, CustomerInterviewees, CustomersActions, ZipAdressObject } from './types';


const {
  GET_ALL_CUSTOMERS_REQUEST,
  GET_ALL_CUSTOMERS_SUCCESS,
  GET_ALL_CUSTOMERS_FAILURE,
  GET_CUSTOMERS_DETAIL_REQUEST,
  GET_CUSTOMERS_DETAIL_SUCCESS,
  GET_CUSTOMERS_DETAIL_FAILURE,
  GET_CEP_REQUEST,
  GET_CEP_REQUEST_SUCCESS,
  GET_CEP_REQUEST_FAILURE,
  EDIT_CUSTOMER_REQUEST,
  EDIT_CUSTOMER_SUCCESS,
  EDIT_CUSTOMER_FAILURE,
  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAILURE,
  GET_CLASSES_REQUEST,
  GET_CLASSES_SUCCESS,
  GET_CLASSES_FAILURE,
  EDIT_CUSTOMER_STATUS_REQUEST,
  EDIT_CUSTOMER_STATUS_SUCCESS,
  EDIT_CUSTOMER_STATUS_FAILURE,
  GET_FILTERED_CUSTOMERS_REQUEST,
  GET_FILTERED_CUSTOMERS_SUCCESS,
  GET_FILTERED_CUSTOMERS_FAILURE
} = CustomersActions;

function getCustomerRequest(): Action {
  return {
    type: GET_ALL_CUSTOMERS_REQUEST,
  };
}

function getCustomerSuccess(customers: Customer[]): Action {
  return {
    type: GET_ALL_CUSTOMERS_SUCCESS,
    payload: {
      customers,
    },
  };
}

function getCustomerFailure(): Action {
  return {
    type: GET_ALL_CUSTOMERS_FAILURE,
  };
}

function getSearchFilteredCustomerRequest(
  filteredCustomers: Array<string | boolean | null>,
  isActive: boolean | null,
  customerSector: string | null,
  customerSubSector: string | null,
  customerSegment: string | null
): Action {
  return {
    type: GET_FILTERED_CUSTOMERS_REQUEST,
    payload: {
      filteredCustomers,
      isActive,
      customerSector,
      customerSubSector,
      customerSegment
    },
  };
}

function getSearchFilteredCustomerSuccess(customers: Customer[]): Action {
  return {
    type: GET_FILTERED_CUSTOMERS_SUCCESS,
    payload: {
      customers,
    },
  };
}

function getSearchFilteredCustomerFailure(): Action {
  return {
    type: GET_FILTERED_CUSTOMERS_FAILURE,
  };
}



function getClassesRequest(): Action {
  return {
    type: GET_CLASSES_REQUEST,
  };
}

function getClassesSuccess(classes: Classes): Action {
  return {
    type: GET_CLASSES_SUCCESS,
    payload: {
      classes
    }
  };
}

function getClassesFailure(): Action {
  return {
    type: GET_CLASSES_FAILURE,
  };
}

function getCustomerDetailRequest(customerId: string): Action {
  return {
    type: GET_CUSTOMERS_DETAIL_REQUEST,
    payload: {
      customerId
    }
  };
}

function getCustomerDetailSuccess(customer: CustomerInterviewees): Action {
  return {
    type: GET_CUSTOMERS_DETAIL_SUCCESS,
    payload: {
      customer,
    },
  };
}

function getCustomerDetailFailure(): Action {
  return {
    type: GET_CUSTOMERS_DETAIL_FAILURE,
  };
}

function getCepRequest(cep: string): Action {
  return {
    type: GET_CEP_REQUEST,
    payload: {
      cep
    }
  };
}

function getCepRequestSuccess(cepInfos: ZipAdressObject): Action {
  return {
    type: GET_CEP_REQUEST_SUCCESS,
    payload: {
      cepInfos,
    },
  };
}

function getCepRequestFailure(): Action {
  return {
    type: GET_CEP_REQUEST_FAILURE,
  };
}

function editCustomerRequest(customerId: string | undefined, customerData: AddNewCustomerData): Action {
  return {
    type: EDIT_CUSTOMER_REQUEST,
    payload: {
      customerId,
      customerData
    }
  };
}

function editCustomerSuccess(): Action {
  return {
    type: EDIT_CUSTOMER_SUCCESS
  };
}

function editCustomerFailure(): Action {
  return {
    type: EDIT_CUSTOMER_FAILURE,
  };
}

function createNewCustomerRequest(customerData: AddNewCustomerData): Action {
  return {
    type: CREATE_CUSTOMER_REQUEST,
    payload: {
      customerData
    }
  };
}

function createNewCustomerSuccess(): Action {
  return {
    type: CREATE_CUSTOMER_SUCCESS,
  };
}

function createNewCustomerFailure(): Action {
  return {
    type: CREATE_CUSTOMER_FAILURE
  };
}

function editCustomerStatusRequest(customerId: string | undefined, customerStatus: boolean): Action {
  return {
    type: EDIT_CUSTOMER_STATUS_REQUEST,
    payload: {
      customerId,
      customerStatus
    }
  };
}

function editCustomerStatusSuccess(): Action {
  return {
    type: EDIT_CUSTOMER_STATUS_SUCCESS
  };
}


function editCustomerStatusFailure(): Action {
  return {
    type: EDIT_CUSTOMER_STATUS_FAILURE
  };
}

function createNewInterwees(customer: Customer): Action {
  return {
    type: EDIT_CUSTOMER_SUCCESS,
    payload: {
      customer
    }
  };
}

function createNewInterweesSuccess(customer: Customer): Action {
  return {
    type: EDIT_CUSTOMER_SUCCESS,
    payload: {
      customer
    }
  };
}

function createNewInterweesFailure(customer: Customer): Action {
  return {
    type: EDIT_CUSTOMER_SUCCESS,
    payload: {
      customer
    }
  };
}

export const customerActionsFunctions = {
  getCustomerRequest,
  getCustomerSuccess,
  getCustomerFailure,
  getCustomerDetailRequest,
  getCustomerDetailSuccess,
  getCustomerDetailFailure,
  getCepRequest,
  getCepRequestSuccess,
  getCepRequestFailure,
  editCustomerRequest,
  editCustomerSuccess,
  editCustomerFailure,
  createNewCustomerRequest,
  createNewCustomerSuccess,
  createNewCustomerFailure,
  createNewInterwees,
  createNewInterweesSuccess,
  createNewInterweesFailure,
  getClassesRequest,
  getClassesSuccess,
  getClassesFailure,
  editCustomerStatusRequest,
  editCustomerStatusSuccess,
  editCustomerStatusFailure,
  getSearchFilteredCustomerRequest,
  getSearchFilteredCustomerSuccess,
  getSearchFilteredCustomerFailure
};
