import { Question } from '../questions/types';

export enum QuestionnariesActions {
  GET_ALL_QUESTIONNARIES_MODULE_FAILURE = 'GET_ALL_QUESTIONNARIES_MODULE_FAILURE',
  GET_ALL_QUESTIONNARIES_MODULE_REQUEST = 'GET_ALL_QUESTIONNARIES_MODULE_REQUEST',
  GET_ALL_QUESTIONNARIES_MODULE_SUCCESS = 'GET_ALL_QUESTIONNARIES_MODULE_SUCCESS',
  GET_FILTERED_QUIZ_REQUEST = 'GET_FILTERED_QUIZ_REQUEST',
  GET_FILTERED_QUIZ_SUCCESS = 'GET_FILTERED_QUIZ_SUCCESS',
  GET_FILTERED_QUIZ_FAILURE = 'GET_FILTERED_QUIZ_FAILURE',
  GET_QUIZ_DETAIL_REQUEST = 'GET_QUIZ_DETAIL_REQUEST',
  GET_QUIZ_DETAIL_SUCCESS = 'GET_QUIZ_DETAIL_SUCCESS',
  GET_QUIZ_DETAIL_FAILURE = 'GET_QUIZ_DETAIL_FAILURE',
  ADD_NEW_QUIZ_REQUEST = 'ADD_NEW_QUIZ_REQUEST',
  ADD_NEW_QUIZ_SUCCESS = 'ADD_NEW_QUIZ_SUCCESS',
  ADD_NEW_QUIZ_FAILURE = 'ADD_NEW_QUIZ_FAILURE',
  EDIT_QUIZ_ADD_QUESTION_REQUEST = 'EDIT_QUIZ_ADD_QUESTION_REQUEST',
  EDIT_QUIZ_REQUEST = 'EDIT_QUIZ_REQUEST',
  EDIT_QUIZ_SUCCESS = 'EDIT_QUIZ_SUCCESS',
  EDIT_QUIZ_FAILURE = 'EDIT_QUIZ_FAILURE',
  ACTIVE_OR_INACTIVE_QUIZ_REQUEST = 'ACTIVE_OR_INACTIVE_QUIZ_REQUEST',
  ACTIVE_OR_INACTIVE_QUIZ_SUCCESS = 'ACTIVE_OR_INACTIVE_QUIZ_SUCCESS',
  ACTIVE_OR_INACTIVE_QUIZ_FAILURE = 'ACTIVE_OR_INACTIVE_QUIZ_FAILURE',
  QUIZ_QUANTITY_REQUEST = 'QUIZ_QUANTITY_REQUEST',
  QUIZ_QUANTITY_SUCCESS = 'QUIZ_QUANTITY_SUCCESS',
  QUIZ_QUANTITY_FAILURE = 'QUIZ_QUANTITY_FAILURE',
  RESET_THE_QUIZ_DETAIL_STATE = 'RESET_THE_QUIZ_DETAIL_STATE',
};

interface QuestionOfQuestionnaireDetail extends Question {
  order: number
}

export type SearchFilteredQuizRequestPayload = {
  filteredQuestionnaries: Array<string | boolean | null>;
};

export type AddNewQuizRequestPayload = {  
  newQuiz: AddNewQuizData;
  id?: string;
};

export type QuizDetailRequestPayload = {
  quizID: string;
};

export type EditQuizRequestPayload = {
  id?: string;
  updatedQuiz: EditQuiz;
};

export type Quiz = {
  id: string;
  description: string;
  type_of_questionnaire: string;
  name: string;
  departments: ObjectWithId[];
  questions: QuestionOfQuestionnaireDetail[];
  is_active: boolean;
}

export type UpdateQuizStatusRequestPayload = {
  quizID: string;
  newQuizStatus: boolean;
};

export type quantityQuizPayload = {
  quizID: string;
  quantityQuiz: number;
};

export type ObjectWithId = {
  id: string;
};

export type AddNewQuizData = {
  type_of_questionnaire: string;
  name: string;
  description: string;
  questions_add: QuestionsOfQuiz[];
  questions_create: Question[];
  departments: ObjectWithId[];
};

export type QuestionsOfQuiz = {
  id: string;
  order: number;
};

export type AddNewQuizResponseData = {
  id: string;
  type_of_questionnaire: string;
  name: string;
  questions_add: QuestionsOfQuiz[];
  questions_create: Question[];
  departments: ObjectWithId[];
};

export type EditQuiz = {
  id: string;
  name: string;
  type_of_questionnaire: string;
  questions_add: QuestionsOfQuiz[];
  questions_create: Question[];
  questions_remove: ObjectWithId[];
  questions_update_order: QuestionsOfQuiz[];
  departments_add: ObjectWithId[];
  departments_remove: ObjectWithId[];
};

export type QuizResults = {
  count: number,
  next: boolean,
  previous: boolean,
  results: Quiz[];
};

export type Department = {
  id: string;
  name: string;
};

export type DepartmentResults = {
  results: Department[];
};

export type QuestionnariesState = {
  questionnaires: Quiz[];
  currentQuizDetail: Quiz;
  departments: Department[];
  visited_pages: number[],
  has_next_questionnaire: boolean,
  total_questionnaire: number,
};




