import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import type { Action } from 'store/types';
import { checkPage } from 'utils/number';

import { controlTypesActionsFunctions } from './actions';
import type {
  ControlTypes,
  ControlTypesDetails,
  ControlTypeDetailsRequest,
  AddNewControlTypeRequestPayload,
  UpdateStatusRequestPayloadParams,
  SearchFilteredControlTypeRequestPayloadParams,
  ControlTypesWithId,
  ControlTypesResults
} from './types';
import { ControlTypesActions } from './types';

const {
  DEFAULT_ERROR_MESSAGE,
  EDIT_CONTROL_TYPE_ERROR_MESSAGE,
  EDIT_CONTROL_TYPE_SUCCESS_MESSAGE,
  NEW_CONTROL_TYPE_ADDED_ERROR_MESSAGE,
  NEW_CONTROL_TYPE_ADDED_SUCCESS_MESSAGE,
  GET_CONTROL_TYPE_DETAILS_ERROR_MESSAGE,
  GET_CONTROL_TYPE_DETAILS_SUCCESS_MESSAGE,
  SEARCH_FILTERED_CONTROL_TYPE_ERROR_MESSAGE,
  SEARCH_FILTERED_CONTROL_TYPE_SUCCESS_MESSAGE,
  ACTIVE_OR_INACTIVE_CONTROL_TYPE_ERROR_MESSAGE,
  ACTIVE_OR_INACTIVE_CONTROL_TYPE_SUCCESS_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE
} = ToastMessages;

const {
  EDIT_CONTROL_TYPES_REQUEST,
  ADD_NEW_CONTROL_TYPES_REQUEST,
  GET_ALL_CONTROL_TYPES_REQUEST,
  GET_CONTROL_TYPES_DETAILS_REQUEST,
  GET_FILTERED_CONTROL_TYPES_REQUEST,
  ACTIVE_OR_INACTIVE_CONTROL_TYPES_REQUEST,
} = ControlTypesActions;

const {
  getControlTypesRequest,
  getControlTypesSuccess,
  getControlTypesFailure,
  editControlTypeSuccess,
  editControlTypeFailure,
  addNewControlTypeSuccess,
  addNewControlTypeFailure,
  getControlTypeDetailsSuccess,
  getControlTypeDetailsFailure,
  resetTheLastControlTypeDetails,
  activeOrInactiveControlTypeSuccess,
  activeOrInactiveControlTypeFailure,
  getSearchFilteredControlTypeSuccess,
  getSearchFilteredControlTypeFailure,
} = controlTypesActionsFunctions;

const { getLastApiFetchDataSuccess, getLastApiFetchDataFailure } = lastApiFetchDataActionsFunctions;

function* fetchAllControlTypes(action: Action): Generator {
  try {
    const { payload } = action as any;
    const total: number = (yield select(state => state.controlTypes.total)) as number;
    const last_page = checkPage(payload.last_page, total);
    const path = `controls/control-types/?page=${last_page}`;
    const has_next = yield select(state => state.controlTypes.has_next);

    if(has_next || last_page === 1){
      const controlTypesResponse: AxiosResponse<ControlTypesResults> | unknown = yield call(api, 'GET', path, {});

      const {
        data: { results, count, next },
        config: { url },
        status,
        statusText,
      } = controlTypesResponse as AxiosResponse<ControlTypesResults>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      yield put(getControlTypesSuccess(results, count, last_page, !!next));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getControlTypesFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchControlTypeDetails(action: Action): Generator {
  const { controlTypeId } = action.payload as ControlTypeDetailsRequest;

  try {
    const controlTypeDetailsResponse: AxiosResponse<ControlTypesDetails> | unknown = yield call(api, 'GET', `control-types/${controlTypeId}/detail/`, {});

    const {
      data: controlTypeDetails,
      config: { url },
      status,
      statusText,
    } = controlTypeDetailsResponse as AxiosResponse<ControlTypesDetails>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getControlTypeDetailsSuccess(controlTypeDetails));

    toast.success(GET_CONTROL_TYPE_DETAILS_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getControlTypeDetailsFailure());

    toast.error(GET_CONTROL_TYPE_DETAILS_ERROR_MESSAGE);
  }
}

function* fetchSearchFilteredControlType(action: Action): Generator {
  const { filteredControlType, isActive } = action.payload as SearchFilteredControlTypeRequestPayloadParams;

  const parsedResponses = filteredControlType.filter(response => response !== null).join(',');

  try {
    const filteredControlTypesResponse: AxiosResponse<ControlTypesResults> | unknown = yield call(
      api,
      'GET',
      `controls/control-types/?search=${parsedResponses}&&is_active=${isActive}`,
      {}
    );

    const {
      data: filteredControlTypes,
      config: { url },
      status,
      statusText,
    } = filteredControlTypesResponse as AxiosResponse<ControlTypesResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredControlTypeSuccess(filteredControlTypes.results));
    toast.success(SEARCH_FILTERED_CONTROL_TYPE_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredControlTypeFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      SEARCH_FILTERED_CONTROL_TYPE_ERROR_MESSAGE
    );
  }
}

function* fetchAddNewControlType(action: Action): Generator {
  const {
    controlType: { name, is_active },
  } = action.payload as AddNewControlTypeRequestPayload;

  try {
    const addNewControlTypesResponse: AxiosResponse<ControlTypes> | unknown = yield call(
      api,
      'POST',
      'controls/control-types/create/',
      {
        name,
        is_active,
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = addNewControlTypesResponse as AxiosResponse<ControlTypes>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(addNewControlTypeSuccess());
    yield put(resetTheLastControlTypeDetails());
    yield put(getControlTypesRequest());

    toast.success(NEW_CONTROL_TYPE_ADDED_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(addNewControlTypeFailure());

    if(currentError?.response?.data?.name[0] === 'control type with this name already exists.') {
      toast.warn('Já existe um tipo de controle com este nome.');
    } else {
      toast.error(
        currentError?.response?.data?.name[0] ??
          currentError?.response?.data?.detail ??
          NEW_CONTROL_TYPE_ADDED_ERROR_MESSAGE
      );
    }    
  }
}

function* fetchToControlTypeEditing(action: Action): Generator {
  const { controlTypeId, name, is_active } = action.payload as ControlTypesWithId;

  try {
    const controlTypeEditingResponse: AxiosResponse<ControlTypes> | unknown = yield call(
      api,
      'PUT',
      `controls/control-types/${controlTypeId}/`,
      {
        name,
        is_active
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = controlTypeEditingResponse as AxiosResponse<ControlTypes>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editControlTypeSuccess());
    yield put(getControlTypesRequest());

    toast.success(EDIT_CONTROL_TYPE_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editControlTypeFailure());

    toast.error(
      currentError?.response?.data?.detail ?? EDIT_CONTROL_TYPE_ERROR_MESSAGE
    );
  }
}

function* fetchToUpdateControlTypeStatus(action: Action): Generator {
  const { id, updatedStatus } =
    action.payload as UpdateStatusRequestPayloadParams;

  try {
    const updateControlTypeStatusResponse: AxiosResponse<ControlTypes> | unknown =
      yield call(api, 'PUT', `controls/control-types/${id}/update-status/`, {
        is_active: updatedStatus,
      });

    const {
      config: { url },
      status,
      statusText,
    } = updateControlTypeStatusResponse as AxiosResponse<ControlTypes>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(activeOrInactiveControlTypeSuccess());
    yield put(getControlTypesRequest());

    toast.success(ACTIVE_OR_INACTIVE_CONTROL_TYPE_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(activeOrInactiveControlTypeFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      ACTIVE_OR_INACTIVE_CONTROL_TYPE_ERROR_MESSAGE
    );
  }
}

export function* controlTypesSagas() {
  yield all([
    takeEvery(GET_ALL_CONTROL_TYPES_REQUEST, fetchAllControlTypes),
    takeLatest(GET_CONTROL_TYPES_DETAILS_REQUEST, fetchControlTypeDetails),
    takeLatest(GET_FILTERED_CONTROL_TYPES_REQUEST, fetchSearchFilteredControlType),
    takeLatest(ADD_NEW_CONTROL_TYPES_REQUEST, fetchAddNewControlType),
    takeLatest(EDIT_CONTROL_TYPES_REQUEST, fetchToControlTypeEditing),
    takeLatest(ACTIVE_OR_INACTIVE_CONTROL_TYPES_REQUEST, fetchToUpdateControlTypeStatus),
  ]);
}
