import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import { userProfileActionsFunctions } from 'store/modules/userProfile/actions';
import type { Action } from 'store/types';

import { UserProfileActions, UserProfile, AllProfilesResponse, UserProfilePayload, OneUserProfilePayload, AllGroupsResponse, GroupPayload, Group, OneGroupPayload, Permission, FilteredPayload, FilteredPermissionPayload } from './types';

const {
    GET_ALL_USER_PROFILE_REQUEST,
    ADD_NEW_USER_PROFILE_REQUEST,
    EDIT_USER_PROFILE_REQUEST,
    GET_ONE_USER_PROFILE_REQUEST,
    GET_ALL_GROUPS_REQUEST,
    ADD_NEW_GROUP_REQUEST,
    EDIT_GROUP_REQUEST,
    GET_ONE_GROUP_REQUEST,
    GET_ALL_PERMISSIONS_REQUEST,
    GET_FILTERED_USER_PROFILE_REQUEST,
    GET_FILTERED_GROUP_REQUEST,
    GET_FILTERED_PERMISSION_REQUEST,
  } = UserProfileActions;

  const {
    DEFAULT_ERROR_MESSAGE,
    DEFAULT_AUTHORIZATION_ERROR_MESSAGE
  } = ToastMessages;

  const {
    getUserProfilesRequest,
    getUserProfilesSuccess,
    getUserProfilesFailure,
    addUserProfilesSuccess,
    addUserProfilesFailure,
    editUserProfilesSuccess,
    editUserProfilesFailure,
    getOneUserProfileSuccess,
    getOneUserProfileFailure,
    getAllGroupsRequest,
    getAllGroupsSuccess,
    getAllGroupsFailure,
    addGroupSuccess,
    addGroupFailure,
    editGroupSuccess,
    editGroupFailure,
    getOneUserProfileRequest,
    getOneGroupRequest,
    getOneGroupSuccess,
    getOneGroupFailure,
    getAllPermissionsSuccess,
    getAllPermissionsFailure,
    getFilteredUserProfileSuccess,
    getFilteredUserProfileFailure,
    getFilteredGroupSuccess,
    getFilteredGroupFailure,
    getFilteredPermissionSuccess,
    getFilteredPermissionFailure
  } = userProfileActionsFunctions;

  const { getLastApiFetchDataSuccess, getLastApiFetchDataFailure } =
  lastApiFetchDataActionsFunctions;

  function* fetchAllUserProfiles(): Generator {
    try {
      const userProfilesResponse: AxiosResponse<AllProfilesResponse> | unknown = yield call(
        api,
        'GET',
        'accounts/user-profiles/',
        {}
      );
  
      const {
        data: userProfiles,
        config: { url },
        status,
        statusText,
      } = userProfilesResponse as AxiosResponse<AllProfilesResponse>;
  
      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );
    
      yield put(getUserProfilesSuccess(userProfiles));
    } catch (error) {
      const currentError = error as AxiosError;
  
      yield put(getLastApiFetchDataFailure(currentError));
      yield put(getUserProfilesFailure());
  
      if (currentError?.response?.status === 403) {
        toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
      } else {
        toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
      }
    }
  }

  function* fetchAddProfile(action: Action): Generator {
    const { userProfile } = action.payload as UserProfilePayload;

    try {
      const addNewUserResponse: AxiosResponse<UserProfile> | unknown = yield call(
        api,
        'POST',
        'accounts/user-profiles/create/',
        {
          name: userProfile.name
        }
      );
  
      const {
        config: { url },
        status,
        statusText,
      } = addNewUserResponse as AxiosResponse<UserProfile>;
  
      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );
  
      yield put(addUserProfilesSuccess());
     yield put(getUserProfilesRequest());  
     toast.success('Perfil criado com sucesso');
    
    } catch (error) {
      const currentError = error as AxiosError;
      yield put(getLastApiFetchDataFailure(currentError));
      yield put(addUserProfilesFailure());
  
      toast.error(
        currentError?.response?.data?.msg[0] ??
          currentError?.response?.data?.email[0] ??
          currentError?.response?.data?.detail ??
          'Erro ao criar um perfil'
      );
    }
  }

  function* fetchEditProfile(action: Action): Generator {
    const { userProfile } = action.payload as UserProfilePayload;
    const profilesPayload = {
      name: userProfile.name,
      ...(userProfile.groups && { groups: userProfile.groups }),
      ...(userProfile.child_profiles && { child_profiles: userProfile.child_profiles }),
      ...(userProfile.is_active !== undefined && { is_active: userProfile.is_active })
  };
  
    try {
      const addNewUserResponse: AxiosResponse<UserProfile> | unknown = yield call(
        api,
        'PUT',
        `accounts/user-profiles/${userProfile.id}/update/`,
        profilesPayload
      );
  
      const {
        config: { url },
        status,
        statusText,
      } = addNewUserResponse as AxiosResponse<UserProfile>;
  
      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );
  
      yield put(editUserProfilesSuccess());
     
      yield put(getUserProfilesRequest());  

      if(userProfile.id) {
        yield put(getOneUserProfileRequest(userProfile.id)); 
      };
      
      toast.success('Perfil editado com sucesso');

  
    } catch (error) {
      const currentError = error as AxiosError;
  
      yield put(getLastApiFetchDataFailure(currentError));
      yield put(editUserProfilesFailure());
  
      toast.error(
        currentError?.response?.data?.msg[0] ??
          currentError?.response?.data?.email[0] ??
          currentError?.response?.data?.detail ??
          'Erro ao editar um perfil'
      );
    }
  }

  function* fetchOneUserProfile(action: Action): Generator {
    const { profileId } = action.payload as OneUserProfilePayload;
    
    try {
      const userProfilesResponse: AxiosResponse<UserProfile> | unknown = yield call(
        api,
        'GET',
        `accounts/user-profiles/${profileId}/detail/`,
        {}
      );
  
      const {
        data: userProfile,
        config: { url },
        status,
        statusText,
      } = userProfilesResponse as AxiosResponse<UserProfile>;
  
      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );
    
      yield put(getOneUserProfileSuccess(userProfile));
    } catch (error) {
      const currentError = error as AxiosError;
  
      yield put(getLastApiFetchDataFailure(currentError));
      yield put(getOneUserProfileFailure());
  
      toast.error(
        currentError?.response?.data?.messages[0]?.message ??
          DEFAULT_ERROR_MESSAGE
      );
    }
  }

  function* fetchAllGroups(): Generator {
    try {
      const groupsResponse: AxiosResponse<AllGroupsResponse> | unknown = yield call(
        api,
        'GET',
        'accounts/groups/',
        {}
      );
  
      const {
        data: groups,
        config: { url },
        status,
        statusText,
      } = groupsResponse as AxiosResponse<AllGroupsResponse>;
  
      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );
    
      yield put(getAllGroupsSuccess(groups));
    } catch (error) {
      const currentError = error as AxiosError;
  
      yield put(getLastApiFetchDataFailure(currentError));
      yield put(getAllGroupsFailure());
  
      if (currentError?.response?.status === 403) {
        toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
      } else {
        toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
      }
    }
  }

  function* fetchAddGroup(action: Action): Generator {
    const { group } = action.payload as GroupPayload;

    try {
      const addNewGroupResponse: AxiosResponse<Group> | unknown = yield call(
        api,
        'POST',
        'accounts/groups/create/',
        {
          name: group.name
        }
      );
  
      const {
        config: { url },
        status,
        statusText,
      } = addNewGroupResponse as AxiosResponse<Group>;
  
      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );
  
      yield put(
        addGroupSuccess()
      );
      toast.success('Grupo criado com sucesso');
      yield put(getAllGroupsRequest());  
  
    } catch (error) {
      const currentError = error as AxiosError;
  
      yield put(getLastApiFetchDataFailure(currentError));
      yield put(addGroupFailure());
  
      toast.error(
        currentError?.response?.data?.msg[0] ??
          currentError?.response?.data?.email[0] ??
          currentError?.response?.data?.detail ??
          'Erro ao criar um grupo'
      );
    }
  }

  function* fetchEditGroup(action: Action): Generator {
    const { group } = action.payload as GroupPayload;
    const currentMicrosservice = group.microsservice ? group.microsservice : 'accounts';
    let groupPayload;
    
    if(group.permissions) {
      groupPayload = {
          name: group.name,
          permissions: group.permissions,
      };
    };

    if(!group.permissions) {
      groupPayload = {
          name: group.name,
      };
    };

    
    try {
      const editGroupResponse: AxiosResponse<Group> | unknown = yield call(
        api,
        'PUT',
        `${currentMicrosservice}/groups/${group.id}/update/`,
        groupPayload
      );
  
      const {
        config: { url },
        status,
        statusText,
      } = editGroupResponse as AxiosResponse<Group>;
  
      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );
  
      yield put(
        editGroupSuccess()
      );
      toast.success('Grupo editado com sucesso');
      yield put(getAllGroupsRequest());  
      if(group.id) {
        yield put(getOneGroupRequest(group.id, currentMicrosservice)); 
      };
  
    } catch (error) {
      const currentError = error as AxiosError;
  
      yield put(getLastApiFetchDataFailure(currentError));
      yield put(editGroupFailure());
  
      toast.error(
        currentError?.response?.data?.msg[0] ??
          currentError?.response?.data?.email[0] ??
          currentError?.response?.data?.detail ??
          'Erro ao editar um grupo'
      );
    }
  }

  function* fetchGroupByMicrosservice(groupId: string, microsservice: string): Generator {
    try {
      const groupResponse: AxiosResponse<Group> | unknown = yield call(
        api,
        'GET',
        `${microsservice}/groups/${groupId}/detail/`,
        {}
      );
  
      const {
        data: group,
        config: { url },
        status,
        statusText,
      } = groupResponse as AxiosResponse<Group>;
  
      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );
  
      yield put(getOneGroupSuccess(group, microsservice));
    } catch (error) {
      const currentError = error as AxiosError;
  
      yield put(getLastApiFetchDataFailure(currentError));
      yield put(getOneGroupFailure());
  
      toast.error(
        currentError?.response?.data?.messages[0]?.message ??
          `Erro ao buscar as permissões do grupo em ${microsservice}`
      );
    }
  }

  function* fetchGroup(action: Action): Generator {
    const { groupId, microsservice } = action.payload as OneGroupPayload;
    const microsservices = ['accounts', 'customers', 'controls', 'projects'];
  
    try {
      if (microsservice !== undefined) {
        yield call(fetchGroupByMicrosservice, groupId, microsservice);

      } else {
        yield all(
          microsservices.map(microsservice =>
            call(fetchGroupByMicrosservice, groupId, microsservice)
          )
        );
      }
    } catch (error) {
      toast.error('Erro ao buscar permissões do grupo');
    }
  }


 function* fetchPermissionByMicrosservice(microsservice: string): Generator {
    
  try {
    const permissionResponse: AxiosResponse<Permission[]> | unknown = yield call(
      api,
      'GET',
      `${microsservice}/permissions/`,
      {}
    );

    const {
      data: permission,
      config: { url },
      status,
      statusText,
    } = permissionResponse as AxiosResponse<Permission[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );
  
    yield put(getAllPermissionsSuccess(permission, microsservice));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getAllPermissionsFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
      `Erro ao buscar as permissões no microsservice ${microsservice}`
    );
  }
  }

  function* fetchAllPermissions(): Generator {
    const microsservices = ['accounts', 'customers', 'controls', 'projects'];
  
    try {
      yield all(
        microsservices.map(microsservice =>
          call(fetchPermissionByMicrosservice, microsservice)
        )
      );
    } catch (error) {
      toast.error('Erro ao buscar permissões');
    }
  }

  function* fetchFilteredUserProfiles(action: Action): Generator {
    const { 
      filtered
    } = action.payload as FilteredPayload;
    try {
      const userProfilesResponse: AxiosResponse<AllProfilesResponse> | unknown = yield call(
        api,
        'GET',
        `accounts/user-profiles/?search=${filtered}`,
        {}
      );
  
      const {
        data: userProfiles,
        config: { url },
        status,
        statusText,
      } = userProfilesResponse as AxiosResponse<AllProfilesResponse>;
  
      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );
    
      yield put(getFilteredUserProfileSuccess(userProfiles));
      toast.success('Filtro aplicado com sucesso!');
    } catch (error) {
      const currentError = error as AxiosError;
  
      yield put(getLastApiFetchDataFailure(currentError));
      yield put(getFilteredUserProfileFailure());
  
      if (currentError?.response?.status === 403) {
        toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
      } else {
        toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
      }
    }
  }

  function* fetchFilteredGroups(action: Action): Generator {
    const { 
      filtered
    } = action.payload as FilteredPayload;
    try {
      const groupsResponse: AxiosResponse<AllGroupsResponse> | unknown = yield call(
        api,
        'GET',
        `accounts/groups/?search=${filtered}`,
        {}
      );
  
      const {
        data: groups,
        config: { url },
        status,
        statusText,
      } = groupsResponse as AxiosResponse<AllGroupsResponse>;
  
      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );
    
      yield put(getFilteredGroupSuccess(groups));
      toast.success('Filtro aplicado com sucesso!');
    } catch (error) {
      const currentError = error as AxiosError;
  
      yield put(getLastApiFetchDataFailure(currentError));
      yield put(getFilteredGroupFailure());
  
      toast.error(
        currentError?.response?.data?.messages[0]?.message ??
          DEFAULT_ERROR_MESSAGE
      );
    }
  }

  function* fetchFilteredPermissionByMicrosservice(action: Action): Generator {
    const {microsservice, filtered} = action.payload as FilteredPermissionPayload;
    
    try {
      const permissionResponse: AxiosResponse<Permission[]> | unknown = yield call(
        api,
        'GET',
        `${microsservice}/permissions/?search=${filtered}`,
        {}
      );
  
      const {
        data: permission,
        config: { url },
        status,
        statusText,
      } = permissionResponse as AxiosResponse<Permission[]>;
  
      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );
    
      yield put(getFilteredPermissionSuccess(permission, microsservice));
    } catch (error) {
      const currentError = error as AxiosError;
  
      yield put(getLastApiFetchDataFailure(currentError));
      yield put(getFilteredPermissionFailure());
  
      toast.error(
        currentError?.response?.data?.messages[0]?.message ??
        `Erro ao filtrar as permissões no microsserviço ${microsservice}`
      );
    }
    }

  export function* userProfilesSagas() {
    yield all([
      takeLatest(GET_ALL_USER_PROFILE_REQUEST, fetchAllUserProfiles),
      takeLatest(ADD_NEW_USER_PROFILE_REQUEST, fetchAddProfile),
      takeLatest(EDIT_USER_PROFILE_REQUEST, fetchEditProfile),
      takeLatest(GET_ONE_USER_PROFILE_REQUEST, fetchOneUserProfile),
      takeLatest(GET_ALL_GROUPS_REQUEST, fetchAllGroups),
      takeLatest(ADD_NEW_GROUP_REQUEST, fetchAddGroup),
      takeLatest(EDIT_GROUP_REQUEST, fetchEditGroup),
      takeLatest(GET_ONE_GROUP_REQUEST, fetchGroup),
      takeLatest(GET_ALL_PERMISSIONS_REQUEST, fetchAllPermissions),
      takeLatest(GET_FILTERED_USER_PROFILE_REQUEST, fetchFilteredUserProfiles),
      takeLatest(GET_FILTERED_GROUP_REQUEST, fetchFilteredGroups),
      takeLatest(GET_FILTERED_PERMISSION_REQUEST, fetchFilteredPermissionByMicrosservice),
    ]);
  }