import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { history } from 'services/history';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import { checklistsActionsFunctions } from 'store/modules/Manutention/checklists/actions';
import type { Action } from 'store/types';
import { checkPage } from 'utils/number';

import type {
  AddNewChecklistRequestPayload,
  AddNewChecklistResponseData,
  EditChecklistRequestPayload,
  SearchFilteredChecklistRequestPayload,
  UpdateChecklistStatusRequestPayload,
  Checklist,
  ChecklistDetailRequestPayload,
  Activity,
  Question,
  SearchFilteredActivityRequestPayload,
  ChecklistResults,
  QuestionResults,
  ActivityResults,
} from './types';
import { ChecklistsActions } from './types';

const {
  DEFAULT_ERROR_MESSAGE,
  NEW_CHECKLIST_ADDED_SUCCESS_MESSAGE,
  NEW_CHECKLIST_ADDED_ERROR_MESSAGE,
  SEARCH_FILTERED_CHECKLIST_SUCCESS_MESSAGE,
  SEARCH_FILTERED_CHECKLIST_ERROR_MESSAGE,
  EDIT_CHECKLIST_SUCCESS_MESSAGE,
  EDIT_CHECKLIST_ERROR_MESSAGE,
  ACTIVE_OR_INACTIVE_CHECKLIST_SUCCESS_MESSAGE,
  ACTIVE_OR_INACTIVE_CHECKLIST_ERROR_MESSAGE,
  SEARCH_FILTERED_QUESTION_ERROR_MESSAGE,
  SEARCH_FILTERED_QUESTION_SUCCESS_MESSAGE,
  SEARCH_FILTERED_ACTIVITIES_ERROR_MESSAGE,
  SEARCH_FILTERED_ACTIVITIES_SUCCESS_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE
} = ToastMessages;

const {
  GET_ALL_CHECKLISTS_REQUEST,
  GET_CHECKLIST_DETAIL_REQUEST,
  GET_FILTERED_CHECKLIST_REQUEST,
  ADD_NEW_CHECKLIST_REQUEST,
  EDIT_CHECKLIST_REQUEST,
  ACTIVE_OR_INACTIVE_CHECKLIST_REQUEST,
  GET_ALL_ACTIVITIES_REQUEST,
  GET_ALL_QUESTIONS_REQUEST,
  GET_FILTERED_ACTIVITY_REQUEST,
  GET_ALL_CHECKLISTS_PROJECTSETTINGS_REQUEST,
  GET_FILTERED_CHECKLIST_PROJECTSETTINGS_REQUEST,
} = ChecklistsActions;

const {
  getChecklistsRequest,
  getChecklistsSuccess,
  getChecklistsFailure,
  getSearchFilteredChecklistSuccess,
  getSearchFilteredChecklistFailure,
  getChecklistDetailSuccess,
  getChecklistDetailFailure,
  addNewChecklistSuccess,
  addNewChecklistFailure,
  editChecklistSuccess,
  editChecklistFailure,
  activeOrInactiveChecklistSuccess,
  activeOrInactiveChecklistFailure,
  resetTheChecklistDetailState,
  getActivitiesFailure,
  getActivitiesSuccess,
  getQuestionsSuccess,
  getQuestionsFailure,
  getSearchFilteredActivityFailure,
  getSearchFilteredActivitySuccess,
} = checklistsActionsFunctions;

const { getLastApiFetchDataSuccess, getLastApiFetchDataFailure } =
  lastApiFetchDataActionsFunctions;

function* fetchAllChecklists(action: Action): Generator {
  try {
    const { payload } = action as any;
    const total: number = (yield select(state => state.checklists.total)) as number;
    const last_page = checkPage(payload.last_page, total);
    const path = `controls/checklists/?page=${last_page}`;
    const has_next = yield select(state => state.checklists.has_next);

    if(has_next || last_page === 1){
      const checklistsResponse: AxiosResponse<ChecklistResults> | unknown =
        yield call(api, 'GET', path, {});

      const {
        data:  { results, count, next },
        config: { url },
        status,
        statusText,
      } = checklistsResponse as AxiosResponse<ChecklistResults>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      yield put(getChecklistsSuccess(results, count, last_page, !!next));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getChecklistsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}
function* fetchAllQuestions(): Generator {
  try {
    const questionsResponse: AxiosResponse<QuestionResults> | unknown =
      yield call(api, 'GET', 'controls/questions/', {});

    const {
      data: questions,
      config: { url },
      status,
      statusText,
    } = questionsResponse as AxiosResponse<QuestionResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );
    yield put(getQuestionsSuccess(questions.results));
  } catch (error) {

    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getQuestionsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchAllActivities(): Generator {
  try {
    const activitiesResponse: AxiosResponse<ActivityResults> | unknown = yield call(
      api,
      'GET',
      'controls/activities/',
      {}
    );

    const {
      data: activities,
      config: { url },
      status,
      statusText,
    } = activitiesResponse as AxiosResponse<ActivityResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getActivitiesSuccess(activities.results));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getActivitiesFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchSearchFilteredChecklist(action: Action): Generator {
  const { filteredChecklists } =
    action.payload as SearchFilteredChecklistRequestPayload;

  const hasFilteredOptionsWithoutStatus = filteredChecklists
    .filter(response => response !== null && typeof response !== 'boolean')
    .join(',');

  const hasStatusFilteredOption = filteredChecklists.some(
    option => typeof option === 'boolean'
  );

  const filteredOptionStatus = `?is_active=${filteredChecklists.filter(
    response => typeof response === 'boolean'
  )}`;

  const filteredOptionsWithoutStatus = `?search=${filteredChecklists
    .filter(response => response !== null && typeof response !== 'boolean')
    .join(',')}`;

  const routeWithFilter = `controls/checklists/${
    hasFilteredOptionsWithoutStatus ? filteredOptionsWithoutStatus : ''
  }${hasStatusFilteredOption ? filteredOptionStatus : ''}`;

  try {
    const checklistsResponse: AxiosResponse<ChecklistResults> | unknown =
      yield call(api, 'GET', routeWithFilter, {});

    const {
      data: checklists,
      config: { url },
      status,
      statusText,
    } = checklistsResponse as AxiosResponse<ChecklistResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );



    yield put(getSearchFilteredChecklistSuccess(checklists.results));

    toast.success(SEARCH_FILTERED_QUESTION_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredChecklistFailure());

    toast.error(
      currentError?.response?.data?.detail ??
        SEARCH_FILTERED_QUESTION_ERROR_MESSAGE
    );
  }
}

function* fetchSearchFilteredActivity(action: Action): Generator {
  const { filteredActivities } =
    action.payload as SearchFilteredActivityRequestPayload;

  const parsedResponses = filteredActivities
    .filter(response => response !== null)
    .join(',');

  try {
    const filteredActivitiesResponse: AxiosResponse<Activity[]> | unknown =
      yield call(api, 'GET', `controls/activities/?search=${parsedResponses}`, {});

    const {
      data: activities,
      config: { url },
      status,
      statusText,
    } = filteredActivitiesResponse as AxiosResponse<Question[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredActivitySuccess(activities));

    toast.success(SEARCH_FILTERED_ACTIVITIES_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredActivityFailure());

    toast.error(
      currentError?.response?.data?.detail ??
        SEARCH_FILTERED_ACTIVITIES_ERROR_MESSAGE
    );
  }
}

function* fetchAddNewChecklist(action: Action): Generator {
  const { newChecklist } =
    action.payload as AddNewChecklistRequestPayload;

  try {
    const addNewChecklistResponse:
      | AxiosResponse<AddNewChecklistResponseData>
      | unknown = yield call(api, 'POST', 'controls/checklists/create/', {
      ...newChecklist,
    });

    const {
      data: checklistAdded,
      config: { url },
      status,
      statusText,
    } = addNewChecklistResponse as AxiosResponse<AddNewChecklistResponseData>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(addNewChecklistSuccess(checklistAdded));

    history.push(`/checklist-registration/${checklistAdded.id}`);

    yield put(getChecklistsRequest());

    toast.success(NEW_CHECKLIST_ADDED_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(resetTheChecklistDetailState());
    yield put(addNewChecklistFailure());

    if(currentError?.response?.data?.subject[0] === 'checklist with this subject already exists.')
      toast.warn('Já existe um checklist cadastrado com este nome.');
    else
      toast.error(currentError?.response?.data?.email[0] ?? currentError?.response?.data?.detail ?? NEW_CHECKLIST_ADDED_ERROR_MESSAGE);
  }
}

function* fetchChecklistDetail(action: Action): Generator {
  const { checklistID } =
    action.payload as ChecklistDetailRequestPayload;

  try {
    const checklistDetailResponse: AxiosResponse<Checklist> | unknown =
      yield call(
        api,
        'GET',
        `controls/checklists/${checklistID}/detail/`,
        {}
      );

    const {
      data: checklistDetail,
      config: { url },
      status,
      statusText,
    } = checklistDetailResponse as AxiosResponse<Checklist>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getChecklistDetailSuccess(checklistDetail));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(resetTheChecklistDetailState());
    yield put(getChecklistDetailFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
        DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchToChecklistEditing(action: Action): Generator {
  const { 
    updatedChecklist,
  } = action.payload as EditChecklistRequestPayload;

  try {
    const checklistEditingResponse: AxiosResponse<Checklist> | unknown =
      yield call(
        api,
        'PUT',
        `controls/checklists/${updatedChecklist.id}/`,
        {
          ...updatedChecklist,
        }
      );

    const {
      config: { url },
      status,
      statusText,
    } = checklistEditingResponse as AxiosResponse<Checklist>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editChecklistSuccess());
    yield put(getChecklistsRequest());

    toast.success(EDIT_CHECKLIST_SUCCESS_MESSAGE);

    history.push(`/checklist-visualization/${updatedChecklist.id}`);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(resetTheChecklistDetailState());
    yield put(editChecklistFailure());

    toast.error(
      currentError?.response?.data?.detail ?? EDIT_CHECKLIST_ERROR_MESSAGE
    );
  }
}

function* fetchToUpdateChecklistStatus(action: Action): Generator {
  const { ChecklistId, newChecklistStatus } =
    action.payload as UpdateChecklistStatusRequestPayload;

  try {
    const updateChecklistResponse:
      | AxiosResponse<{ is_active: boolean }>
      | unknown = yield call(
      api,
      'PUT',
      `controls/checklists/${ChecklistId}/update-status/`,
      {
        is_active: newChecklistStatus,
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = updateChecklistResponse as AxiosResponse<{ is_active: boolean }>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(activeOrInactiveChecklistSuccess());
    yield put(getChecklistsRequest());

    toast.success(ACTIVE_OR_INACTIVE_CHECKLIST_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(activeOrInactiveChecklistFailure());

    toast.error(
      currentError?.response?.data?.detail ??
        ACTIVE_OR_INACTIVE_CHECKLIST_ERROR_MESSAGE
    );
  }
}

function* fetchAllChecklistsProjectSettings(): Generator {
  try {
    const checklistsResponse: AxiosResponse<Checklist[]> | unknown = yield call(
      api,
      'GET',
      'projects/checklists/',
      {}
    );

    const {
      data: checklists,
      config: { url },
      status,
      statusText,
    } = checklistsResponse as AxiosResponse<Checklist[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    /* Corrigir isso aqui para funcionar em projects */
     yield put(getChecklistsSuccess(checklists, 1000, 1, true));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getChecklistsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchSearchFilteredChecklistProjectSettings(action: Action): Generator {
  const { filteredChecklists } =
    action.payload as SearchFilteredChecklistRequestPayload;

  const hasFilteredOptionsWithoutStatus = filteredChecklists
    .filter(response => response !== null && typeof response !== 'boolean')
    .join(',');

  const hasStatusFilteredOption = filteredChecklists.some(
    option => typeof option === 'boolean'
  );

  const filteredOptionStatus = `?is_active=${filteredChecklists.filter(
    response => typeof response === 'boolean'
  )}`;

  const filteredOptionsWithoutStatus = `?search=${filteredChecklists
    .filter(response => response !== null && typeof response !== 'boolean')
    .join(',')}`;

  const routeWithFilter = `projects/checklists/${
    hasFilteredOptionsWithoutStatus ? filteredOptionsWithoutStatus : ''
  }${hasStatusFilteredOption ? filteredOptionStatus : ''}`;

  try {
    const checklistsResponse: AxiosResponse<Checklist[]> | unknown =
      yield call(api, 'GET', routeWithFilter, {});

    const {
      data: checklists,
      config: { url },
      status,
      statusText,
    } = checklistsResponse as AxiosResponse<Checklist[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredChecklistSuccess(checklists));

    toast.success(SEARCH_FILTERED_CHECKLIST_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredChecklistFailure());

    toast.error(
      currentError?.response?.data?.detail ??
        SEARCH_FILTERED_CHECKLIST_ERROR_MESSAGE
    );
  }
}

export function* checklistsSagas() {
  yield all([
    takeEvery(GET_ALL_CHECKLISTS_REQUEST, fetchAllChecklists),
    takeLatest(GET_CHECKLIST_DETAIL_REQUEST, fetchChecklistDetail),
    takeLatest(GET_FILTERED_CHECKLIST_REQUEST, fetchSearchFilteredChecklist),
    takeLatest(ADD_NEW_CHECKLIST_REQUEST, fetchAddNewChecklist),
    takeLatest(EDIT_CHECKLIST_REQUEST, fetchToChecklistEditing),
    takeLatest(ACTIVE_OR_INACTIVE_CHECKLIST_REQUEST, fetchToUpdateChecklistStatus),
    takeLatest(GET_ALL_QUESTIONS_REQUEST, fetchAllQuestions),
    takeLatest(GET_ALL_ACTIVITIES_REQUEST, fetchAllActivities),
    takeLatest(GET_FILTERED_ACTIVITY_REQUEST, fetchSearchFilteredActivity),
    takeLatest(GET_ALL_CHECKLISTS_PROJECTSETTINGS_REQUEST, fetchAllChecklistsProjectSettings),
    takeLatest(GET_FILTERED_CHECKLIST_PROJECTSETTINGS_REQUEST, fetchSearchFilteredChecklistProjectSettings)
  ]);
}
