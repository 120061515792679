export enum FrameworksActions {
  EDIT_FRAMEWORKS_REQUEST = 'EDIT_FRAMEWORKS_REQUEST',
  EDIT_FRAMEWORKS_SUCCESS = 'EDIT_FRAMEWORKS_SUCCESS',
  EDIT_FRAMEWORKS_FAILURE = 'EDIT_FRAMEWORKS_FAILURE',
  GET_ALL_FRAMEWORKS_REQUEST = 'GET_ALL_FRAMEWORKS_REQUEST',
  GET_ALL_FRAMEWORKS_SUCCESS = 'GET_ALL_FRAMEWORKS_SUCCESS',
  GET_ALL_FRAMEWORKS_FAILURE = 'GET_ALL_FRAMEWORKS_FAILURE',
  ADD_NEW_FRAMEWORKS_REQUEST = 'ADD_NEW_FRAMEWORKS_REQUEST',
  ADD_NEW_FRAMEWORKS_SUCCESS = 'ADD_NEW_FRAMEWORKS_SUCCESS',
  ADD_NEW_FRAMEWORKS_FAILURE = 'ADD_NEW_FRAMEWORKS_FAILURE',
  APPROVED_FRAMEWORKS_REQUEST = 'APPROVED_FRAMEWORKS_REQUEST',
  APPROVED_FRAMEWORKS_SUCCESS = 'APPROVED_FRAMEWORKS_SUCCESS',
  APPROVED_FRAMEWORKS_FAILURE = 'APPROVED_FRAMEWORKS_FAILURE',
  GET_STANDARDS_ITEMS_REQUEST = 'GET_STANDARDS_ITEMS_REQUEST',
  GET_STANDARDS_ITEMS_SUCCESS = 'GET_STANDARDS_ITEMS_SUCCESS',
  GET_STANDARDS_ITEMS_FAILURE = 'GET_STANDARDS_ITEMS_FAILURE',
  ADD_STANDARDS_ITEMS_REQUEST = 'ADD_STANDARDS_ITEMS_REQUEST',
  ADD_STANDARDS_ITEMS_SUCCESS = 'ADD_STANDARDS_ITEMS_SUCCESS',
  ADD_STANDARDS_ITEMS_FAILURE = 'ADD_STANDARDS_ITEMS_FAILURE',
  GET_FRAMEWORKS_DETAIL_REQUEST = 'GET_FRAMEWORKS_DETAIL_REQUEST',
  GET_FRAMEWORKS_DETAIL_SUCCESS = 'GET_FRAMEWORKS_DETAIL_SUCCESS',
  GET_FRAMEWORKS_DETAIL_FAILURE = 'GET_FRAMEWORKS_DETAIL_FAILURE',
  CREATE_CSFCOREREF_ITEM_REQUEST = 'CREATE_CSFCOREREF_ITEM_REQUEST',
  CREATE_CSFCOREREF_ITEM_SUCCESS = 'CREATE_CSFCOREREF_ITEM_SUCCESS',
  CREATE_CSFCOREREF_ITEM_FAILURE = 'CREATE_CSFCOREREF_ITEM_FAILURE',
  GET_FILTERED_FRAMEWORKS_REQUEST = 'GET_FILTERED_FRAMEWORKS_REQUEST',
  GET_FILTERED_FRAMEWORKS_SUCCESS = 'GET_FILTERED_FRAMEWORKS_SUCCESS',
  GET_FILTERED_FRAMEWORKS_FAILURE = 'GET_FILTERED_FRAMEWORKS_FAILURE',
  ADD_FRAMEWORKS_CONTROLS_REQUEST = 'ADD_FRAMEWORKS_CONTROLS_REQUEST',
  ADD_FRAMEWORKS_CONTROLS_SUCCESS = 'ADD_FRAMEWORKS_CONTROLS_SUCCESS',
  ADD_FRAMEWORKS_CONTROLS_FAILURE = 'ADD_FRAMEWORKS_CONTROLS_FAILURE',
  EDIT_CSFCOREREF_FRAMEWORKS_REQUEST = 'EDIT_CSFCOREREF_FRAMEWORKS_REQUEST',
  EDIT_CSFCOREREF_FRAMEWORKS_SUCCESS = 'EDIT_CSFCOREREF_FRAMEWORKS_SUCCESS',
  EDIT_CSFCOREREF_FRAMEWORKS_FAILURE = 'EDIT_CSFCOREREF_FRAMEWORKS_FAILURE',
  EDIT_ALTERNATIVES_FRAMEWORKS_REQUEST = 'EDIT_ALTERNATIVES_FRAMEWORKS_REQUEST',
  EDIT_ALTERNATIVES_FRAMEWORKS_SUCCESS = 'EDIT_ALTERNATIVES_FRAMEWORKS_SUCCESS',
  EDIT_ALTERNATIVES_FRAMEWORKS_FAILURE = 'EDIT_ALTERNATIVES_FRAMEWORKS_FAILURE',
  DELETE_CSFCOREREF_FRAMEWORKS_REQUEST = 'DELETE_CSFCOREREF_FRAMEWORKS_REQUEST',
  DELETE_CSFCOREREF_FRAMEWORKS_SUCCESS = 'DELETE_CSFCOREREF_FRAMEWORKS_SUCCESS',
  DELETE_CSFCOREREF_FRAMEWORKS_FAILURE = 'DELETE_CSFCOREREF_FRAMEWORKS_FAILURE',
  ACTIVE_OR_INACTIVE_FRAMEWORKS_REQUEST = 'ACTIVE_OR_INACTIVE_FRAMEWORKS_REQUEST',
  ACTIVE_OR_INACTIVE_FRAMEWORKS_SUCCESS = 'ACTIVE_OR_INACTIVE_FRAMEWORKS_SUCCESS',
  ACTIVE_OR_INACTIVE_FRAMEWORKS_FAILURE = 'ACTIVE_OR_INACTIVE_FRAMEWORKS_FAILURE',
  CREATE_CSFCOREREF_ALTERNATIVE_REQUEST = 'CREATE_CSFCOREREF_ALTERNATIVE_REQUEST',
  CREATE_CSFCOREREF_ALTERNATIVE_SUCCESS = 'CREATE_CSFCOREREF_ALTERNATIVE_SUCCESS',
  CREATE_CSFCOREREF_ALTERNATIVE_FAILURE = 'CREATE_CSFCOREREF_ALTERNATIVE_FAILURE',
  GET_FRAMEWORKS_CONTROLS_DETAIL_REQUEST = 'GET_FRAMEWORKS_CONTROLS_DETAIL_REQUEST',
  GET_FRAMEWORKS_CONTROLS_DETAIL_SUCCESS = 'GET_FRAMEWORKS_CONTROLS_DETAIL_SUCCESS',
  GET_FRAMEWORKS_CONTROLS_DETAIL_FAILURE = 'GET_FRAMEWORKS_CONTROLS_DETAIL_FAILURE',
  DELETE_CSFCOREREF_ALTERNATIVES_REQUEST = 'DELETE_CSFCOREREF_ALTERNATIVES_REQUEST',
  DELETE_CSFCOREREF_ALTERNATIVES_SUCCESS = 'DELETE_CSFCOREREF_ALTERNATIVES_SUCCESS',
  DELETE_CSFCOREREF_ALTERNATIVES_FAILURE = 'DELETE_CSFCOREREF_ALTERNATIVES_FAILURE',
  GET_FRAMEWORKS_STANDARDS_DETAIL_REQUEST = 'GET_FRAMEWORKS_STANDARDS_DETAIL_REQUEST',
  GET_FRAMEWORKS_STANDARDS_DETAIL_SUCCESS = 'GET_FRAMEWORKS_STANDARDS_DETAIL_SUCCESS',
  GET_FRAMEWORKS_STANDARDS_DETAIL_FAILURE = 'GET_FRAMEWORKS_STANDARDS_DETAIL_FAILURE',
  GET_FRAMEWORKS_ITEMS_DETAIL_REQUEST = 'GET_FRAMEWORKS_ITEMS_DETAIL_REQUEST',
  GET_FRAMEWORKS_ITEMS_DETAIL_SUCCESS = 'GET_FRAMEWORKS_ITEMS_DETAIL_SUCCESS',
  GET_FRAMEWORKS_ITEMS_DETAIL_FAILURE = 'GET_FRAMEWORKS_ITEMS_DETAIL_FAILURE',
  ADD_FRAMEWORKS_ITEMS_REQUEST = 'ADD_FRAMEWORKS_ITEMS_REQUEST',
  ADD_FRAMEWORKS_ITEMS_SUCCESS = 'ADD_FRAMEWORKS_ITEMS_SUCCESS',
  ADD_FRAMEWORKS_ITEMS_FAILURE = 'ADD_FRAMEWORKS_ITEMS_FAILURE'
}

export type Successor = {
  id: string;
  name: string;
  version: string;
  is_active: boolean;
}

export type Framework = {
  id: string;
  name: string;
  version: string;
  publication_date: string;
  successor: Successor | null;
  is_active: boolean;
  approved: boolean;
  approval_date: string;
}

export type AllFramewoks = {
  count: number | null;
  next: number | null;
  previous: number | null;
  results: Framework[];
}

export type Alternatives = {
  id: string;
  peso: number;
  name: string;
}

export type Item = {
  id: string;
  name: string;
  friendly_id?: string;
  category?: string;
  subcategory?: string;
}

export type Standards = {
  id: string;
  reference_id: string;
  name: string;
  items: Item[];
}

export type AllStandards = {
  count: number | null;
  next: number | null;
  previous: number | null;
  results: Standards[];
}

export type CSFCoreModel = {
  id: string;
  friendly_id: string;
  csf_function: string;
  category_name: string;
  category_description: string;
  subcategory: string;
  subcategory_ptbr: string;
  version: string;
  alternatives: Alternatives[];
  evidence_to_be_collected: string;
  implementation_examples: string;
}

export type FrameworkDetails = {
  id: string;
  name: string;
  version: string;
  publication_date: string;
  successor: Successor;
  is_active: boolean;
  csf_core: {
    [key: string]: CSFCoreModel[],
  };
}

export type FrameworkStandardsDetails = {
  id: string;
  friendly_id: string;
  csf_function: string;
  category_name: string;
  subcategory: string;
  subcategory_ptbr: string;
  standards: Standards[];
}

export type ControlType = {
  id: string;
  name: string;
}

export type ClassName = {
  id: string;
  name: string;
}

export type GroupName = {
  id: string;
  name: string;
}

export type TypeName = {
  id: string;
  name: string;
}

export type Category = {
  id: string;
  name: string;
  importance: number;
  description: string;
}

export type FrameworkControl = {
  id: string;
  name: string;
  control_type: ControlType;
  class_name: ClassName;
  group_name: GroupName;
  type_name: TypeName;
  category: Category;
}

export type FrameworkControlsDetails = {
  id: string;
  friendly_id: string;
  csf_function: string;
  category_name: string;
  subcategory: string;
  subcategory_ptbr: string;
  controls: FrameworkControl[];
}

export type FrameworkState = {
  allStandards: AllStandards;
  allFrameworks: AllFramewoks;
  frameworkDetails: FrameworkDetails;
  frameworkControlsDetails: FrameworkControlsDetails;
  frameworkStandardsDetails: FrameworkStandardsDetails;
  frameworkItemsDetails: FrameworkItemsDetailsResponse;
  csf_core_ref_items: ItemDetails;

};

export type UpdateStatusRequestPayloadParams = {
  id: string;
  is_active: boolean;
}

export type AddNewFrameworkRequestPayload = {
  name: string;
  version: string;
  publication_date: string;
  successor: string;
}

export type FrameworkEditPayload = {
  id: string;
  name: string;
  version: string;
  publication_date: string;
  successor: string;
  is_active: boolean;
}

export type SearchFilteredFrameworksRequestPayloadParams = {
  name: string;
  is_active: boolean | null;
}

export type FrameworkDetailsRequestPayload = {
  id: string;
}

export type AlternativesEditPayload = {
  id: string;
  name: string;
  peso: number;
  csf_core_ref: string;
  frameworkId: string;
}

export type CSFCoreRefEditPayload = {
  id: string;
  friendly_id: string;
  csf_function: string;
  category_name: string;
  category_description: string;
  subcategory: string;
  subcategory_ptbr: string;
  version: string;
  evidence_to_be_collected: string;
  frameworkId: string;
  implementation_examples: string;
}

export type FrameworkVersion = {
  id: string;
  name: string;
  version: string;
  is_active: boolean;
}

export type ApprovedRequestPayloadParams = {
  id: string;
  name: string;
  approved: boolean;
  approval_date: string;
}

export type FrameworkStandardsDetailsRequestPayload = {
  id: string;
}

export type JustId = {
  id: string;
}
export type StandardItemsPayload = {
  id: string;
  standardItemsAdd: JustId[];
  standardItemsRemove: JustId[];
}

export type FrameworkControlsDetailsRequestPayload = {
  id: string;
}

export type FrameworksControlsPayload = {
  id: string;
  frameworksControlsAdd: JustId[];
  frameworksControlsRemove: JustId[];
}

export type DeleteItemCSFCoreRefParam = {
  csfCoreRefId: string;
  frameworkId: string;
}

export type CSFCoreRefCreatePayload = {
  friendly_id: string;
  csf_function: string;
  category_name: string;
  category_description: string;
  subcategory: string;
  subcategory_ptbr: string;
  framework: string;
  evidence_to_be_collected: string;
  implementation_examples: string;
}

export type AlternativeIdPayload = {
  id: string;
}

export type CreateAlternative = {
  id: string;
  title: string;
  type: string;
}

export type CreateAlternativePayload = {
  peso: string;
  name: string;
  csf_core_ref: string;
  frameworkId: string;
}

export type FrameworkItemsDetails = {
    id: string,
    name: string, 
    version: string,
    csf_core_refs: ItemDetails[]
}

export type ItemDetails = {
  id: string;
  friendly_id: string;
  csf_function: string;
  category_name: string;
  subcategory: string;
  has_link: boolean;
}

export type FrameworkItemsDetailsResponse = {
  frameworks: FrameworkItemsDetails[];
}

export type FrameworkItemsDetailsRequestPayload = {
  id: string;
}

export type FrameworksItemsPayload = {
  id: string;
  frameworksItems: string[];
  frameworkId: string;
}

export type FrameworksItemsChecked = {
  frameworkId: string,
  itemId: string
}

export type CustomerProjectPayload = {
  isCustomerProjectPage: boolean;
}