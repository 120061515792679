export enum RiskFactorsActions {
  GET_ALL_RISK_FACTORS_REQUEST = 'GET_ALL_RISK_FACTORS_REQUEST',
  GET_ALL_RISK_FACTORS_SUCCESS = 'GET_ALL_RISK_FACTORS_SUCCESS',
  GET_ALL_RISK_FACTORS_FAILURE = 'GET_ALL_RISK_FACTORS_FAILURE',
  GET_FILTERED_RISK_FACTOR_REQUEST = 'GET_FILTERED_RISK_FACTOR_REQUEST',
  GET_FILTERED_RISK_FACTOR_SUCCESS = 'GET_FILTERED_RISK_FACTOR_SUCCESS',
  GET_FILTERED_RISK_FACTOR_FAILURE = 'GET_FILTERED_RISK_FACTOR_FAILURE',

  GET_RISK_FACTOR_DETAIL_REQUEST = 'GET_RISK_FACTOR_DETAIL_REQUEST',
  GET_RISK_FACTOR_DETAIL_SUCCESS = 'GET_RISK_FACTOR_DETAIL_SUCCESS',
  GET_RISK_FACTOR_DETAIL_FAILURE = 'GET_RISK_FACTOR_DETAIL_FAILURE',
  ADD_NEW_RISK_FACTOR_REQUEST = 'ADD_NEW_RISK_FACTOR_REQUEST',
  ADD_NEW_RISK_FACTOR_SUCCESS = 'ADD_NEW_RISK_FACTOR_SUCCESS',
  ADD_NEW_RISK_FACTOR_FAILURE = 'ADD_NEW_RISK_FACTOR_FAILURE',
  EDIT_RISK_FACTOR_REQUEST = 'EDIT_RISK_FACTOR_REQUEST',
  EDIT_RISK_FACTOR_SUCCESS = 'EDIT_RISK_FACTOR_SUCCESS',
  EDIT_RISK_FACTOR_FAILURE = 'EDIT_RISK_FACTOR_FAILURE',
  ACTIVE_OR_INACTIVE_RISK_FACTOR_REQUEST = 'ACTIVE_OR_INACTIVE_RISK_FACTOR_REQUEST',
  ACTIVE_OR_INACTIVE_RISK_FACTOR_SUCCESS = 'ACTIVE_OR_INACTIVE_RISK_FACTOR_SUCCESS',
  ACTIVE_OR_INACTIVE_RISK_FACTOR_FAILURE = 'ACTIVE_OR_INACTIVE_RISK_FACTOR_FAILURE',
  RESET_THE_RISK_FACTOR_DETAIL_STATE = 'RESET_THE_RISK_FACTOR_DETAIL_STATE',

  GET_ALL_RISK_FACTORS_STATIC_REQUEST = 'GET_ALL_RISK_FACTORS_STATIC_REQUEST',
  GET_ALL_RISK_FACTORS_STATIC_SUCCESS = 'GET_ALL_RISK_FACTORS_STATIC_SUCCESS',
  GET_ALL_RISK_FACTORS_STATIC_FAILURE = 'GET_ALL_RISK_FACTORS_STATIC_FAILURE',
  GET_FILTERED_RISK_STATIC_FACTOR_REQUEST = 'GET_FILTERED_RISK_FACTOR_STATIC_REQUEST',
  GET_FILTERED_RISK_FACTOR_STATIC_SUCCESS = 'GET_FILTERED_RISK_FACTOR_STATIC_SUCCESS',
  GET_FILTERED_RISK_FACTOR_STATIC_FAILURE = 'GET_FILTERED_RISK_FACTOR_STATIC_FAILURE',
}
type ObjectWithId = {
  id: string;
};

export type SearchFilteredRiskFactorRequestPayload = {
  filteredRiskFactors: Array<string | boolean | null>;
};

export type AddNewRiskFactorRequestPayload = {
  newRiskFactor: AddNewRiskFactorData;
};

export type RiskFactorDetailRequestPayload = {
  riskFactorId: string;
};

export type EditRiskFactorRequestPayload = {
  updatedRiskFactor: EditRiskFactorData;
};

export type UpdateRiskFactorStatusRequestPayload = {
  riskFactorId: string;
  newRiskFactorStatus: boolean;
};

export type AddNewRiskFactorData = {
  name: string;
  is_active: boolean;
};

export type EditRiskFactorData = {
  id: string;
  friendly_id: string;
  name: string;
  is_active: boolean;
  controls_add: ObjectWithId[];
  controls_remove: ObjectWithId[];
  threats_add: ObjectWithId[];
  threats_remove: ObjectWithId[];
  registration_status: string;
};

export type ControlOfRiskFactor = {
  id: string;
  name: string;
};

export type ThreatOfRiskFactor = {
  id: string;
  name: string;
  description: string;
};

export type RiskFactorResults = {
  results: RiskFactor[];
  count: number;
  next: boolean;
}

export type RiskFactor = {
  id: string;
  friendly_id: string;
  name: string;
  is_active: boolean;
  registration_status: string;
  controls: ControlOfRiskFactor[];
  threats: ThreatOfRiskFactor[];
};

export type RiskFactorStaticResults = {
  results: RiskFactorStatic[];
  count: number;
  next: boolean;
}

export type RiskFactorStatic = {
  id: string;
  name: string;
  controls: number;
  threats: number;
  is_active: boolean;    
};

export type RiskFactorsState = {
  riskFactors: RiskFactor[];
  currentRiskFactorDetail: RiskFactor;
  total: number,
  visited_pages: number[];
  has_next: boolean;
  riskFactorStatic: RiskFactorStatic[];
};