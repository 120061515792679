import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import type { Action } from 'store/types';

import { StandardsActionsFunctions } from './actions';
import {
  AddNewStandardRequestPayload,
  AllStandards,
  Standard,
  StandardEditPayload,
  StandardsActions,
  SearchFilteredStandardsRequestPayloadParams,
  UpdateStatusRequestPayloadParams,
  StandardsDetails,
  StandardsDetailsRequestPayload,
  AddNewStandardDetailsRequestPayload,
  UpdateStatusRequestItemsPayloadParams,
  StandardDetailsEditPayload,
  UpdateApproveRequestPayload,
} from './types';

const {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE
} = ToastMessages;

const {
  EDIT_STANDARDS_REQUEST,
  GET_ALL_STANDARDS_REQUEST,
  ADD_NEW_STANDARDS_REQUEST,
  GET_FILTERED_STANDARDS_REQUEST,
  ACTIVE_OR_INACTIVE_STANDARDS_REQUEST,
  CHANGE_STANDARDS_REQUEST,
  GET_STANDARDS_DETAIL_REQUEST,
  ADD_NEW_STANDARDS_DETAILS_REQUEST,
  EDIT_STANDARDS_DETAILS_REQUEST,
  ACTIVE_OR_INACTIVE_STANDARDS_DETAILS_REQUEST,
} = StandardsActions;

const {
  editStandardSuccess,
  editStandardFailure,
  getStandardsRequest,
  getStandardsSuccess,
  getStandardsFailure,
  addNewStandardSuccess,
  addNewStandardFailure,
  activeOrInactiveStandardsSuccess,
  activeOrInactiveStandardsFailure,
  getSearchFilteredStandardsSuccess,
  getSearchFilteredStandardsFailure,
  editStandardDetailsSuccess,
  editStandardDetailsFailure,
  getStandardsDetailsRequest,
  getStandardsDetailsSuccess,
  getStandardsDetailsFailure,
  addNewStandardDetailsSuccess,
  addNewStandardDetailsFailure,
  activeOrInactiveStandardsDetailsSuccess,
  activeOrInactiveStandardsDetailsFailure,
  changeStandardsSuccess,
  changeStandardsFailure,
} = StandardsActionsFunctions;

const { getLastApiFetchDataSuccess, getLastApiFetchDataFailure } = lastApiFetchDataActionsFunctions;


function* fetchAllStandards(): Generator {
  try {
    const standardsResponse: AxiosResponse<AllStandards> | unknown = yield call(
      api,
      'GET',
      'controls/standards/',
      {}
    );

    const {
      data: standards,
      config: { url },
      status,
      statusText,
    } = standardsResponse as AxiosResponse<AllStandards>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getStandardsSuccess(standards));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getStandardsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchAddStandards(action: Action): Generator {
  const {
    name,
    version,
    approved,
    approval_date,
    publication_date,
    is_active,
  } = action.payload as AddNewStandardRequestPayload;

  try {
    const addNewStandardResponse: AxiosResponse<Standard> | unknown = yield call(
      api,
      'POST',
      'controls/standards/create/',
      {
        name,
        version,
        approved,
        approval_date,
        publication_date,
        is_active,
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = addNewStandardResponse as AxiosResponse<Standard>;

    yield put(
      getLastApiFetchDataSuccess(
        {
          url,
          status,
          statusText,
        }
      )
    );

    yield put(addNewStandardSuccess());
    yield put(getStandardsRequest());

    toast.success('Nova norma adicionada com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(addNewStandardFailure());

    toast.error('Tentativa de adicionar uma nova norma falhou.');
  }
}

function* fetchToStandardEditing(action: Action): Generator {
  const {
    id,
    name,
    version,
    approved,
    approval_date,
    publication_date,
    is_active,
  } = action.payload as StandardEditPayload;

  try {
    const crownJewelsEditingResponse: AxiosResponse<Standard> | unknown = yield call(
      api,
      'PUT',
      `controls/standards/${id}/`,
      {
        name,
        version,
        approved,
        approval_date,
        publication_date,
        is_active,
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = crownJewelsEditingResponse as AxiosResponse<Standard>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editStandardSuccess());
    yield put(getStandardsRequest());

    toast.success('Edição da norma foi realizada com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editStandardFailure());

    toast.error('Tentativa de edição da norma falhou.');
  }
}

function* fetchToUpdateStandardsStatus(action: Action): Generator {
  const { id, is_active } = action.payload as UpdateStatusRequestPayloadParams;

  try {
    const updateCrownJewelsStatusResponse: AxiosResponse<Standard> | unknown =
      yield call(
        api,
        'PUT',
        `controls/standards/${id}/update-status/`,
        {
          is_active,
        }
      );

    const {
      config: { url },
      status,
      statusText,
    } = updateCrownJewelsStatusResponse as AxiosResponse<Standard>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(activeOrInactiveStandardsSuccess());
    yield put(getStandardsRequest());

    toast.success('Edição do status da norma foi realizada com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(activeOrInactiveStandardsFailure());

    toast.error('Tentativa de edição do status da norma falhou.');
  }
}

function* fetchSearchFilteredStandards(action: Action): Generator {
  const { name, is_active } = action.payload as SearchFilteredStandardsRequestPayloadParams;

  let searchStandards = 'controls/standards/';
  if (name !== null) {
    searchStandards = searchStandards.concat(`?search=${name}`);
    if (is_active !== null) {
      searchStandards = searchStandards.concat(`&&is_active=${is_active}`);
    }
  } else if (is_active !== null) {
    searchStandards = searchStandards.concat(`?is_active=${is_active}`);
  }

  try {
    const filteredStandardsResponse: AxiosResponse<AllStandards> | unknown = yield call(
      api,
      'GET',
      searchStandards,
      {}
    );

    const {
      data: filteredStandards,
      config: { url },
      status,
      statusText,
    } = filteredStandardsResponse as AxiosResponse<AllStandards>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredStandardsSuccess(filteredStandards));
    toast.success('Filtragem da pesquisa da(s) normas(s) foi realizada com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredStandardsFailure());

    toast.error('Tentativa de filtragem da pesquisa da(s) normas(s) da coroa falhou.');
  }
}

function* fetchStandardsDetails(action: Action): Generator {
  const { id } = action.payload as StandardsDetailsRequestPayload;

  try {
    const standardsResponse = (yield call(
      api,
      'GET',
      `controls/standards/${id}/detail/`,
      {}
    )) as AxiosResponse<StandardsDetails>;

    const {
      data: standards,
      config: { url },
      status,
      statusText,
    } = standardsResponse as AxiosResponse<StandardsDetails>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getStandardsDetailsSuccess(standards));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getStandardsDetailsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchAddStandardsDetails(action: Action): Generator {
  const {
    name,
    friendly_id,
    category,
    subcategory,
    standardId,
  } = action.payload as AddNewStandardDetailsRequestPayload;

  try {
    const addNewStandardDetailsResponse = (yield call(
      api,
      'POST',
      'controls/standards-items/create/',
      {
        standard: standardId,
        name,
        friendly_id,
        category,
        subcategory
      }
    )) as AxiosResponse<StandardsDetails>;

    const {
      config: { url },
      status,
      statusText,
    } = addNewStandardDetailsResponse as AxiosResponse<StandardsDetails>;

    yield put(
      getLastApiFetchDataSuccess(
        {
          url,
          status,
          statusText,
        }
      )
    );

    yield put(addNewStandardDetailsSuccess());
    yield put(getStandardsDetailsRequest({ id: standardId }));

    toast.success('Novo item adicionado a norma com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(addNewStandardDetailsFailure());

    toast.error('Tentativa de adicionar novo item a norma falhou.');
  }
}

function* fetchToStandardDetailsEditing(action: Action): Generator {
  const {
    id,
    name,
    friendly_id,
    category,
    subcategory,
    standardId,
  } = action.payload as StandardDetailsEditPayload;

  try {
    const crownJewelsEditingResponse = (yield call(
      api,
      'PUT',
      `controls/standards-items/${id}/`,
      {
        name,
        friendly_id,
        category,
        subcategory
      }
    )) as AxiosResponse<StandardsDetails>;

    const {
      config: { url },
      status,
      statusText,
    } = crownJewelsEditingResponse as AxiosResponse<StandardsDetails>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editStandardDetailsSuccess());
    yield put(getStandardsDetailsRequest({ id: standardId }));

    toast.success('Edição do item da norma foi realizada com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editStandardDetailsFailure());

    toast.error('Tentativa de edição do item da norma falhou.');
  }
}

function* fetchToUpdateStandardsDetailsStatus(action: Action): Generator {
  const { id, is_active, standard } = action.payload as UpdateStatusRequestItemsPayloadParams;

  try {
    const updateCrownJewelsStatusResponse = (
      yield call(
        api,
        'PUT',
        `controls/standards-items/${id}/update-status/`,
        {
          is_active,
        }
      )) as AxiosResponse<StandardsDetails>;

    const {
      config: { url },
      status,
      statusText,
    } = updateCrownJewelsStatusResponse as AxiosResponse<StandardsDetails>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(activeOrInactiveStandardsDetailsSuccess());
    yield put(getStandardsDetailsRequest({ id: standard }));

    toast.success('Edição do status do item da norma foi realizada com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(activeOrInactiveStandardsDetailsFailure());

    toast.error('Tentativa de edição do status do item da norma falhou.');
  }
}

function* fetchToUpdateStandardApprovad(action: Action): Generator {
  const { id, approved } = action.payload as UpdateApproveRequestPayload;

  try {
    const updateCrownJewelsStatusResponse: AxiosResponse<Standard> | unknown =
      yield call(
        api,
        'PUT',
        `controls/standards/${id}/update-approved/`,
        {
          approved,
        }
      );

    const {
      config: { url },
      status,
      statusText,
    } = updateCrownJewelsStatusResponse as AxiosResponse<Standard>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(changeStandardsSuccess());
    yield put(getStandardsRequest());

    toast.success('Edição da aprovação da norma foi realizada com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(changeStandardsFailure());

    toast.error('Tentativa de edição da aprovação da norma falhou.');
  }
}

export function* standardsSagas() {
  yield all([
    takeLatest(ADD_NEW_STANDARDS_REQUEST, fetchAddStandards),
    takeLatest(GET_ALL_STANDARDS_REQUEST, fetchAllStandards),
    takeLatest(EDIT_STANDARDS_REQUEST, fetchToStandardEditing),
    takeLatest(GET_FILTERED_STANDARDS_REQUEST, fetchSearchFilteredStandards),
    takeLatest(ACTIVE_OR_INACTIVE_STANDARDS_REQUEST, fetchToUpdateStandardsStatus),
    takeLatest(ADD_NEW_STANDARDS_DETAILS_REQUEST, fetchAddStandardsDetails),
    takeLatest(GET_STANDARDS_DETAIL_REQUEST, fetchStandardsDetails),
    takeLatest(EDIT_STANDARDS_DETAILS_REQUEST, fetchToStandardDetailsEditing),
    takeLatest(ACTIVE_OR_INACTIVE_STANDARDS_DETAILS_REQUEST, fetchToUpdateStandardsDetailsStatus),
    takeLatest(CHANGE_STANDARDS_REQUEST, fetchToUpdateStandardApprovad),
  ]);
}
