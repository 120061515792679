import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import type { Action } from 'store/types';

import { SolutionsFunctions } from './actions';
import {
  AllSolutions,
  ChangeRankingControlSolutionPayload,
  ControlSolution,
  EditSolutionPayload,
  Solution,
  SolutionControlsDetail,
  SolutionControlsPayload,
  SolutionDetails,
  SolutionIdPayload,
  SolutionPayload,
  SolutionProject,
  SolutionProjectIdPayload,
  SolutionProjectStatusPayload,
  SolutionsActions,
  SolutionsProjectDetail,
  UpdateStatusControlSolutionPayload,
  UpdateStatusSolutionPayload,
  SolutionControlPayload,
  SolutionInformationPayload,
  SearchFilteredSolutionsRequestPayloadParams,
  SolutionCsfCoreProject,
  JustId,
  SolutionCsfCoreProjectPayload
} from './types';

const {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE,
  SEARCH_FILTERED_SOLUTIONS_ERROR_MESSAGE,
  SEARCH_FILTERED_SOLUTIONS_SUCCESS_MESSAGE,    
} = ToastMessages;

const {
  EDIT_SOLUTION_REQUEST,
  ADD_NEW_SOLUTION_REQUEST,
  GET_ALL_SOLUTIONS_REQUEST,
  GET_SOLUCTION_DETAIL_REQUEST,
  GET_SOLUTIONS_PROJECT_REQUEST,
  EDIT_SOLUTION_CONTROL_REQUEST,
  LINK_CSFCORE_SOLUTION_REQUEST,
  GET_FILTERED_SOLUTIONS_REQUEST,
  UPDATE_CSFCORE_SOLUTION_REQUEST,
  LINK_OR_UNLINK_CONTROLS_REQUEST,
  EDIT_SOLUTION_INFORMATION_REQUEST,
  ACTIVE_OR_INACTIVE_SOLUCTIONS_REQUEST,
  GET_SOLUTIONS_CSFCORE_PROJECT_REQUEST,
  EDIT_CONTROLS_SOLUTION_RANKING_REQUEST,
  ADD_AND_REMOVE_SOLUTION_CONTROLS_REQUEST,
  GET_CONTROL_SOLUTIONS_PROJECT_DETAIL_REQUEST,
  ACTIVE_OR_INACTIVE_CONTROL_SOLUCTION_REQUEST,
  EDIT_SOLUTION_CUSTOMER_PROJECT_STATUS_REQUEST,
  GENERATE_CONTROL_SOLUTION_CUSTOMER_PROJECT_REQUEST,  
} = SolutionsActions;

const {
  getSolutionsRequest,
  getSolutionsSuccess,
  getSolutionsFailure,
  editSolutionSuccess,
  editSolutionFailure,
  addNewSolutionSuccess,
  addNewSolutionFailure,
  getSoluctionDetailSuccess,
  getSoluctionDetailFailure,
  editSolutionControlSuccess,
  editSolutionControlFailure,
  linkCSFCoreSolutionSuccess,
  linkCSFCoreSolutionFailure,
  linkOrUnlinkControlsSuccess,
  linkOrUnlinkControlsFailure,
  editSolutionInformationSuccess,
  editSolutionInformationFailure,
  getSoluctionProjectDetailRequest,
  getSoluctionProjectDetailSuccess,
  getSoluctionProjectDetailFailure,
  activeOrInactiveSoluctionSuccess,
  activeOrInactiveSoluctionFailure,
  editSolutionProjectStatusSuccess,
  editSolutionProjectStatusFailure,
  getSearchFilteredSolutionsSuccess,
  getSearchFilteredSolutionsFailure,
  getSoluctionCSFCoreProjectRequest,
  getSoluctionCSFCoreProjectSuccess,
  getSoluctionCSFCoreProjectFailure,
  editControlsSolutionRankingSuccess,
  editControlsSolutionRankingFailure,
  addAndRemoveSolutionControlsSuccess,
  addAndRemoveSolutionControlsFailure,
  activeOrInactiveControlSolutionSuccess,
  activeOrInactiveControlSolutionFailure,
  getControlSolutionProjectDetailRequest,
  getControlSolutionProjectDetailSuccess,
  getControlSolutionProjectDetailFailure,
  generateControlSolutionCustomerProjectSuccess,
  generateControlSolutionCustomerProjectFailure,    
} = SolutionsFunctions;

const { getLastApiFetchDataSuccess, getLastApiFetchDataFailure } = lastApiFetchDataActionsFunctions;

function* fetchAllSolutions(): Generator {
  try {
    const solutionsResponse: AxiosResponse<AllSolutions> | unknown = yield call(
      api,
      'GET',
      'controls/solutions/',
      {}
    );

    const {
      data: solutions,
      config: { url },
      status,
      statusText,
    } = solutionsResponse as AxiosResponse<AllSolutions>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSolutionsSuccess(solutions));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSolutionsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchAddNewSolution(action: Action): Generator {
  const { name } = action.payload as SolutionPayload;

  try {
    const addNewSolutionResponse: AxiosResponse<Solution> | unknown = yield call(
      api,
      'POST',
      'controls/solutions/create/',
      {
        name
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = addNewSolutionResponse as AxiosResponse<Solution>;

    yield put(
      getLastApiFetchDataSuccess(
        {
          url,
          status,
          statusText,
        }
      )
    );

    yield put(addNewSolutionSuccess());
    if (status === 200 || status === 201) {
      yield put(getSolutionsRequest());
      toast.success('Nova solução adicionada com sucesso.');
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(addNewSolutionFailure());

    toast.error('Tentativa de adicionar uma nova solução falhou.');
  }
}

function* fetchToSolutionEditing(action: Action): Generator {
  const { id, name } = action.payload as EditSolutionPayload;

  try {
    const solutionEditingResponse: AxiosResponse<Solution> | unknown = yield call(
      api,
      'PUT',
      `controls/solutions/${id}/`,
      {
        name,
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = solutionEditingResponse as AxiosResponse<Solution>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editSolutionSuccess());
    if (status === 200 || status === 201) {
      yield put(getSolutionsRequest());
      toast.success('Edição da solução foi realizada com sucesso.');
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editSolutionFailure());

    toast.error('Tentativa de edição da solução falhou.');
  }
}

function* fetchToUpdateStatusSolution(action: Action): Generator {
  const { id, is_active } = action.payload as UpdateStatusSolutionPayload;

  try {
    const updateSocutionResponse: AxiosResponse<Solution> | unknown =
      yield call(
        api,
        'PUT',
        `controls/solutions/${id}/update-status/`,
        {
          is_active,
        }
      );

    const {
      config: { url },
      status,
      statusText,
    } = updateSocutionResponse as AxiosResponse<Solution>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(activeOrInactiveSoluctionSuccess());
    yield put(getSolutionsRequest());

    toast.success('Edição do status da solução foi realizada com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(activeOrInactiveSoluctionFailure());

    toast.error('Tentativa de edição do status da solução falhou.');
  }
}

function* fetchSolutionDetails(action: Action): Generator {
  const { id } = action.payload as SolutionIdPayload;

  try {
    const solutionDetailsResponse: AxiosResponse<SolutionDetails> | unknown =
      yield call(
        api,
        'GET',
        `controls/solutions/${id}/detail/`,
        {}
      );

    const {
      data: solutionDetails,
      config: { url },
      status,
      statusText,
    } = solutionDetailsResponse as AxiosResponse<SolutionDetails>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSoluctionDetailSuccess(solutionDetails));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSoluctionDetailFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchToAddAndRemoveSolutionControls(action: Action): Generator {
  let [statusAdd, statusRemove] = [0, 0];
  const { id, controlsAdd, controlsRemove } = action.payload as SolutionControlsPayload;

  try {
    if (controlsAdd.length) {
      const solutionControlsAddResponse: AxiosResponse<Solution> | unknown = yield call(
        api,
        'PUT',
        `controls/solutions/${id}/add-controls/`,
        {
          controls: controlsAdd
        }
      );

      const {
        config: { url },
        status,
        statusText,
      } = solutionControlsAddResponse as AxiosResponse<Solution>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      statusAdd = status;
    }

    if (controlsRemove.length) {
      const solutionControlsRemoveResponse: AxiosResponse<Solution> | unknown = yield call(
        api,
        'PUT',
        `controls/solutions/${id}/remove-controls/`,
        {
          controls: controlsRemove
        }
      );

      const {
        config: { url },
        status,
        statusText,
      } = solutionControlsRemoveResponse as AxiosResponse<Solution>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      statusRemove = status;
    }

    if ((statusAdd === 200 || statusAdd === 201) || (statusRemove === 200 || statusRemove === 201)) {
      yield put(addAndRemoveSolutionControlsSuccess());
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(addAndRemoveSolutionControlsFailure());

    toast.error('Tentativa de vícular soluções e controles falhou.');
  }
}

function* fetchSolutionProjectDetails(action: Action): Generator {
  const { id } = action.payload as SolutionProjectIdPayload;

  try {
    const solutionProjectResponse: AxiosResponse<SolutionsProjectDetail> | unknown =
      yield call(
        api,
        'GET',
        `controls/customers-projects/${id}/solutions/`,
        {}
      );

    const {
      data: solutionsProjectDetail,
      config: { url },
      status,
      statusText,
    } = solutionProjectResponse as AxiosResponse<SolutionsProjectDetail>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSoluctionProjectDetailSuccess(solutionsProjectDetail));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSoluctionProjectDetailFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* generateControlSolutionsCustomersProject(action: Action): Generator {
  const { id } = action.payload as SolutionProjectIdPayload;

  try {
    const solutionStatusResponse: AxiosResponse<SolutionsProjectDetail> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${id}/generate-solutions/`,
      {}
    );

    const {
      config: { url },
      status,
      statusText,
    } = solutionStatusResponse as AxiosResponse<SolutionsProjectDetail>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    if (status === 200 || status === 201) {
      yield put(generateControlSolutionCustomerProjectSuccess());
      yield put(getSoluctionProjectDetailRequest({ id }));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(generateControlSolutionCustomerProjectFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
      DEFAULT_ERROR_MESSAGE
    );
  }
}

function* editSolutionProjectStatusRequest(action: Action): Generator {
  const { id, justification_of_inactivation, is_active, customerProjectId } = action.payload as SolutionProjectStatusPayload;

  try {
    const solutionStatusResponse: AxiosResponse<SolutionProject> | unknown = yield call(
      api,
      'PUT',
      `/controls/solutions-project/${id}/update-status/`,
      {
        justification_of_inactivation,
        is_active
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = solutionStatusResponse as AxiosResponse<SolutionProject>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editSolutionProjectStatusSuccess());
    yield put(getSoluctionProjectDetailRequest({ id: customerProjectId }));

    toast.success('Status alterado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editSolutionProjectStatusFailure());

    toast.success('Erro ao alterar o status.');
  }
}

function* fetchControlSolutionsDetailProject(action: Action): Generator {
  const { id } = action.payload as SolutionProjectIdPayload;

  try {
    const solutionDetailResponse: AxiosResponse<SolutionControlsDetail> | unknown = yield call(
      api,
      'GET',
      `/controls/solutions-project/${id}/detail/`,
      {}
    );

    const {
      data: solutionControlsDetail,
      config: { url },
      status,
      statusText,
    } = solutionDetailResponse as AxiosResponse<SolutionControlsDetail>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getControlSolutionProjectDetailSuccess(solutionControlsDetail));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getControlSolutionProjectDetailFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchToLinkOrUnlinkControls(action: Action): Generator {
  let [statusAdd, statusRemove] = [0, 0];
  const { id, controlsAdd, controlsRemove } = action.payload as SolutionControlsPayload;

  try {
    if (controlsAdd.length) {
      const controlsLinkResponse: AxiosResponse<ControlSolution> | unknown = yield call(
        api,
        'PUT',
        `controls/solutions-project/${id}/add-controls/`,
        {
          controls: controlsAdd
        }
      );

      const {
        config: { url },
        status,
        statusText,
      } = controlsLinkResponse as AxiosResponse<ControlSolution>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      statusAdd = status;
    }

    if (controlsRemove.length) {
      const controlsUnlinkResponse: AxiosResponse<ControlSolution> | unknown = yield call(
        api,
        'PUT',
        `controls/solutions-project/${id}/remove-controls/`,
        {
          controls: controlsRemove
        }
      );

      const {
        config: { url },
        status,
        statusText,
      } = controlsUnlinkResponse as AxiosResponse<ControlSolution>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      statusRemove = status;
    }

    if ((statusAdd === 200 || statusAdd === 201) || (statusRemove === 200 || statusRemove === 201)) {
      yield put(linkOrUnlinkControlsSuccess());
      yield put(getControlSolutionProjectDetailRequest(id));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(linkOrUnlinkControlsFailure());

    toast.error('Tentativa de vícular controles a solução falhou.');
  }
}

function* fetchToUpdateStatusControlSolution(action: Action): Generator {
  const { controlId, is_active, solutionId } = action.payload as UpdateStatusControlSolutionPayload;

  try {
    const updateSocutionResponse: AxiosResponse<ControlSolution> | unknown =
      yield call(
        api,
        'PUT',
        `controls/solution-control-risk/${controlId}/update-status/`,
        {
          is_active,
        }
      );

    const {
      config: { url },
      status,
      statusText,
    } = updateSocutionResponse as AxiosResponse<ControlSolution>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(activeOrInactiveControlSolutionSuccess());
    yield put(getControlSolutionProjectDetailRequest(solutionId));

    toast.success('Edição do status do controle da solução foi realizada com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(activeOrInactiveControlSolutionFailure());

    toast.error('Tentativa de edição do status do controle da solução falhou.');
  }
}

function* fetchEditControlSolutionRankingRequest(action: Action): Generator {
  const { id, position, customerProjectId } = action.payload as ChangeRankingControlSolutionPayload;

  try {
    const controlRecommendationsResponse: AxiosResponse<SolutionControlsDetail> | unknown = yield call(
      api,
      'PUT',
      `/controls/solutions-project/${id}/update-position/`,
      { position }
    );

    const {
      config: { url },
      status,
      statusText,
    } = controlRecommendationsResponse as AxiosResponse<SolutionControlsDetail>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editControlsSolutionRankingSuccess());
    yield put(getSoluctionProjectDetailRequest({ id: customerProjectId }));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editControlsSolutionRankingFailure());

    toast.success('Erro ao alterar a posição do controle da solução.');
  }
}

function* fetchEditSolutionControlRequest(action: Action): Generator {
  const {  solution_control_risk_id, unit_of_measure, amount } = action.payload as SolutionControlPayload;

  try {
    const solutionControlResponse: AxiosResponse<SolutionProject> | unknown = yield call(
      api,
      'PUT',
      `controls/solution-control-risk/${solution_control_risk_id}/update/`,
      {
        unit_of_measure,
        amount
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = solutionControlResponse as AxiosResponse<SolutionProject>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editSolutionControlSuccess());
    yield put(getSolutionsRequest());
    toast.success('Controle da solução editado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editSolutionControlFailure());

    toast.error('Erro ao editar o controle da solução.');
  }
}

function* fetchEditSolutionInformationRequest(action: Action): Generator {
  const {  solution_project_id, total_value, solution_implementation_deadline_months } = action.payload as SolutionInformationPayload;

  try {
    const solutionInformationResponse: AxiosResponse<SolutionProject> | unknown = yield call(
      api,
      'PUT',
      `controls/solutions-project/${solution_project_id}/update/`,
      {
        total_value, 
        solution_implementation_deadline_months
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = solutionInformationResponse as AxiosResponse<SolutionProject>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editSolutionInformationSuccess());
    yield put(getSolutionsRequest());
    toast.success('Solução editada com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editSolutionInformationFailure());

    toast.error('Erro ao editar solução.');
  }
}

function* fetchSearchFilteredSolutions(action: Action): Generator {
  const { filteredSolutions, isActive } = action.payload as SearchFilteredSolutionsRequestPayloadParams;
  const parsedResponses = filteredSolutions.filter(response => response !== null).join(',');

  try {
    const filteredSolutionsResponse: AxiosResponse<AllSolutions> | unknown = yield call(
      api,
      'GET',
      `controls/solutions/?search=${parsedResponses}&&is_active=${isActive}`,
      {}
    );

    const {
      data: filteredSolutions,
      config: { url },
      status,
      statusText,
    } = filteredSolutionsResponse as AxiosResponse<AllSolutions>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredSolutionsSuccess(filteredSolutions));
    toast.success(SEARCH_FILTERED_SOLUTIONS_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredSolutionsFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      SEARCH_FILTERED_SOLUTIONS_ERROR_MESSAGE
    );
  }
}

function* fetchSolutionCSFCoreProjectDetails(action: Action): Generator {
  const { id } = action.payload as SolutionProjectIdPayload;

  try {
    const solutionProjectResponse: AxiosResponse<SolutionCsfCoreProject> | unknown =
      yield call(
        api,
        'GET',
        `controls/customers-projects/${id}/solutions-to-csf-core-projects/`,
        {}
      );

    const {
      data: solutionCsfCoreProject,
      config: { url },
      status,
      statusText,
    } = solutionProjectResponse as AxiosResponse<SolutionCsfCoreProject>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSoluctionCSFCoreProjectSuccess(solutionCsfCoreProject));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSoluctionCSFCoreProjectFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchLinkCSFCoreSolution(action: Action): Generator {
  const { id } = action.payload as JustId;

  try {
    const linkCSFCoreSolutionResponse: AxiosResponse<Solution> | unknown = yield call(
      api,
      'POST',
      `controls/customers-projects/${id}/link-solutions-to-csf-core-projects/`,
      {}
    );

    const {
      config: { url },
      status,
      statusText,
    } = linkCSFCoreSolutionResponse as AxiosResponse<Solution>;

    yield put(
      getLastApiFetchDataSuccess(
        {
          url,
          status,
          statusText,
        }
      )
    );

    yield put(linkCSFCoreSolutionSuccess());
    if (status === 200 || status === 201) {
      yield put(getSoluctionCSFCoreProjectRequest({id}));
      toast.success('Novo vinculo de CSFCore a solução realizada com sucesso.');
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(linkCSFCoreSolutionFailure());

    toast.error('Tentativa de vincular um CSFCore a uma solução falhou.');
  }
}

function* fetchToUpdateCSFCoreSolution(action: Action): Generator {
  const { id, projectId, solution_projects } = action.payload as SolutionCsfCoreProjectPayload;

  try {
    const solutionEditingResponse: AxiosResponse<Solution> | unknown = yield call(
      api,
      'PUT',
      `controls/csf-core-project/${id}/update-solutions/`,
      {
        solution_projects,
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = solutionEditingResponse as AxiosResponse<Solution>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editSolutionSuccess());
    if (status === 200 || status === 201) {
      yield put(getSoluctionCSFCoreProjectRequest({id: projectId}));
      toast.success('Edição de CSFCore x Soluções foi realizada com sucesso.');
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editSolutionFailure());

    toast.error('Tentativa de edição de CSFCore x Soluções falhou.');
  }
}

export function* solutionsSagas() {
  yield all([
    takeLatest(GET_ALL_SOLUTIONS_REQUEST, fetchAllSolutions),
    takeLatest(ADD_NEW_SOLUTION_REQUEST, fetchAddNewSolution),
    takeLatest(EDIT_SOLUTION_REQUEST, fetchToSolutionEditing),
    takeLatest(GET_SOLUCTION_DETAIL_REQUEST, fetchSolutionDetails),
    takeLatest(LINK_CSFCORE_SOLUTION_REQUEST, fetchLinkCSFCoreSolution),
    takeLatest(GET_SOLUTIONS_PROJECT_REQUEST, fetchSolutionProjectDetails),
    takeLatest(LINK_OR_UNLINK_CONTROLS_REQUEST, fetchToLinkOrUnlinkControls),
    takeLatest(GET_FILTERED_SOLUTIONS_REQUEST, fetchSearchFilteredSolutions),
    takeLatest(UPDATE_CSFCORE_SOLUTION_REQUEST, fetchToUpdateCSFCoreSolution),
    takeLatest(EDIT_SOLUTION_CONTROL_REQUEST, fetchEditSolutionControlRequest),
    takeLatest(ACTIVE_OR_INACTIVE_SOLUCTIONS_REQUEST, fetchToUpdateStatusSolution),
    takeLatest(EDIT_SOLUTION_INFORMATION_REQUEST, fetchEditSolutionInformationRequest),
    takeLatest(GET_SOLUTIONS_CSFCORE_PROJECT_REQUEST, fetchSolutionCSFCoreProjectDetails),
    takeLatest(ADD_AND_REMOVE_SOLUTION_CONTROLS_REQUEST, fetchToAddAndRemoveSolutionControls),
    takeLatest(EDIT_CONTROLS_SOLUTION_RANKING_REQUEST, fetchEditControlSolutionRankingRequest),
    takeLatest(EDIT_SOLUTION_CUSTOMER_PROJECT_STATUS_REQUEST, editSolutionProjectStatusRequest),
    takeLatest(GET_CONTROL_SOLUTIONS_PROJECT_DETAIL_REQUEST, fetchControlSolutionsDetailProject),
    takeLatest(ACTIVE_OR_INACTIVE_CONTROL_SOLUCTION_REQUEST, fetchToUpdateStatusControlSolution),
    takeLatest(GENERATE_CONTROL_SOLUTION_CUSTOMER_PROJECT_REQUEST, generateControlSolutionsCustomersProject),    
  ]);
}
