import produce from 'immer';
import { Reducer } from 'redux';

import { 
  CustomerProjectControlActions, 
  CustomerProjectControlState 
} from './types';

const {
  GET_CUSTOMER_PROJECT_CONTROLS_REQUEST,
  GET_CUSTOMER_PROJECT_CONTROLS_SUCCESS,
  GET_CUSTOMER_PROJECT_CONTROLS_FAILURE,
  GET_CONTROLS_CUSTOMER_PROJECT_DETAIL_REQUEST,
  GET_CONTROLS_CUSTOMER_PROJECT_DETAIL_SUCCESS,
  GET_CONTROLS_CUSTOMER_PROJECT_DETAIL_FAILURE,
  GET_CUSTOMER_PROJECT_CONTROLS_STATIC_REQUEST,
  GET_CUSTOMER_PROJECT_CONTROLS_STATIC_SUCCESS,
  GET_CUSTOMER_PROJECT_CONTROLS_STATIC_FAILURE,
} = CustomerProjectControlActions;

const initialState: CustomerProjectControlState = {
  customerProjectControls: {
    id: '',
    name: '',
    controls_customer_project: [],
  },
  customerControls: {
    controls: [],
  },
  control: {
    id: '',
    name: '',
    control_type: {
      id: '',
      name: '',
    },
    class_name: {
      id: '',
      name: '',
    },
    group_name: {
      id: '',
      name: '',
    },
    type_name: {
      id: '',
      name: '',
    },
    category: {
      id: '',
      name: '',
      importance: '',
      description: '',
    },
    final_benefit: '',
    vulnerabilities: [],
    risk_factors: [],
    threats: [],
    checklists_origin: [],
    questionnaires_origin: [],
    is_active: false,
    justification_of_inactivation: '',
    reduced_risks: '',
    partially_applied: false,
    justification_for_partially_applied: '',
  }
};

export const customerProjectControlReducer: Reducer<CustomerProjectControlState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_CUSTOMER_PROJECT_CONTROLS_REQUEST:
        break;

      case GET_CUSTOMER_PROJECT_CONTROLS_SUCCESS: {
        const { customerProjectControls } = action.payload;
        draft.customerProjectControls = customerProjectControls;
        break;
      }

      case GET_CUSTOMER_PROJECT_CONTROLS_FAILURE: {
        draft.customerProjectControls = {
          id: '',
          name: '',
          controls_customer_project: [],
        };
        break;
      }

      case GET_CONTROLS_CUSTOMER_PROJECT_DETAIL_REQUEST:
        break;

      case GET_CONTROLS_CUSTOMER_PROJECT_DETAIL_SUCCESS: {
        const { control } = action.payload;
        draft.control = control;
        break;
      }

      case GET_CONTROLS_CUSTOMER_PROJECT_DETAIL_FAILURE: {
        draft.control = {
          id: '',
          name: '',
          control_type: {
            id: '',
            name: '',
          },
          class_name: {
            id: '',
            name: '',
          },
          group_name: {
            id: '',
            name: '',
          },
          type_name: {
            id: '',
            name: '',
          },
          category: {
            id: '',
            name: '',
            importance: '',
            description: '',
          },
          final_benefit: '',
          vulnerabilities: [],
          risk_factors: [],
          threats: [],
          checklists_origin: [],
          questionnaires_origin: [],
          is_active: false,
          justification_of_inactivation: '',
          reduced_risks: '',
          partially_applied: false,
          justification_for_partially_applied: '',
        };
        break;
      }

      case GET_CUSTOMER_PROJECT_CONTROLS_STATIC_REQUEST:
        break;

      case GET_CUSTOMER_PROJECT_CONTROLS_STATIC_SUCCESS: {
        const { customerControls } = action.payload;
        draft.customerControls = customerControls;
        break;
      }

      case GET_CUSTOMER_PROJECT_CONTROLS_STATIC_FAILURE: {
        draft.customerControls = {
          controls: [],
        };
        break;
      }

      default: {
        return draft;
      }
    }
  });
};