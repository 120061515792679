
export enum CustomerProjectNistActions {
  GET_CUSTOMER_PROJECT_NIST_REQUEST = ' GET_CUSTOMER_PROJECT_NIST_REQUEST',
  GET_CUSTOMER_PROJECT_NIST_SUCCESS = 'GET_CUSTOMER_PROJECT_NIST_SUCCESS',
  GET_CUSTOMER_PROJECT_NIST_FAILURE = 'GET_CUSTOMER_PROJECT_NIST_FAILURE',
}

export type Alternatives = {
  id: string;
  peso: string;
  name: string;
}

export type CSFCoreModel = {
  id: string;
  function: string;
  friendly_id: string;
  category: string;
  subcategory: string;
  subcategory_ptbr: string;
  maturity_level: string;
  version: number;
  alternatives: Alternatives[];
  evidence_to_be_collected: string;
}

export type CSFCoreIdentify = {
  id: string;
  function: string;
  friendly_id: string;
  category: string;
  subcategory: string;
  subcategory_ptbr: string;
  maturity_level: string;
  version: number;
  alternatives: Alternatives[];
  evidence_to_be_collected: string;
}

export type CSFCoreProtect = {
  id: string;
  function: string;
  friendly_id: string;
  category: string;
  subcategory: string;
  subcategory_ptbr: string;
  maturity_level: string;
  version: number;
  alternatives: Alternatives[];
  evidence_to_be_collected: string;
}

export type CSFCoreDetect = {
  id: string;
  function: string;
  friendly_id: string;
  category: string;
  subcategory: string;
  subcategory_ptbr: string;
  maturity_level: string;
  version: number;
  alternatives: Alternatives[];
  evidence_to_be_collected: string;
}

export type CSFCoreRespond = {
  id: string;
  function: string;
  friendly_id: string;
  category: string;
  subcategory: string;
  subcategory_ptbr: string;
  maturity_level: string;
  version: number;
  alternatives: Alternatives[];
  evidence_to_be_collected: string;
}

export type CSFCoreRecover = {
  id: string;
  function: string;
  friendly_id: string;
  category: string;
  subcategory: string;
  subcategory_ptbr: string;
  maturity_level: string;
  version: number;
  alternatives: Alternatives[];
  evidence_to_be_collected: string;
}

export type Framework = {
  id: string;
  name: string;
  version: string;
  is_active: boolean;
}

export type CustomerProjectNist = {
  csf_core: {
    [key: string]: CSFCoreModel[],
  };
  framework: Framework;
}

export type CustomerProjectNistState = {
  customerProjectNist: CustomerProjectNist;
}

export type CustomerProjectNistQuestionnaireIdPayload = {
  customerProjectId: string;
}