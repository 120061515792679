/* eslint-disable no-plusplus */
import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import type { Action } from 'store/types';

import { customerProjectNistActionsFunctions } from './actions';
import { CustomerProjectNist, CustomerProjectNistActions, CustomerProjectNistQuestionnaireIdPayload } from './type';

const {
  GET_CUSTOMER_PROJECT_NIST_REQUEST,
} = CustomerProjectNistActions;

const {
  getCustomersProjectNISTQuestionnaireSuccess,
  getCustomersProjectNISTQuestionnaireFailure,
} = customerProjectNistActionsFunctions;

const {
  getLastApiFetchDataSuccess,
  getLastApiFetchDataFailure
} = lastApiFetchDataActionsFunctions;

const {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE
} = ToastMessages;

function* fetchCustomersProjectsNistQuestionnairesRequest(action: Action): Generator {
  const { customerProjectId } = action.payload as CustomerProjectNistQuestionnaireIdPayload;

  try {
    const customerProjectNistResponse: AxiosResponse<CustomerProjectNist> | unknown = yield call(
      api,
      'GET',
      `/controls/customers-projects/${customerProjectId}/csf-core-questionnaire/`,
      {}
    );

    const {
      data: customerProjectNist,
      config: { url },
      status,
      statusText,
    } = customerProjectNistResponse as AxiosResponse<CustomerProjectNist>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCustomersProjectNISTQuestionnaireSuccess(customerProjectNist));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomersProjectNISTQuestionnaireFailure());
    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

export function* customerProjectNistSagas() {
  yield all([
    takeLatest(GET_CUSTOMER_PROJECT_NIST_REQUEST, fetchCustomersProjectsNistQuestionnairesRequest),
  ]);
}