import type { Action } from 'store/types';

import { ActivitiesActions } from './types';
import type { 
  Activities,
  ActivitiesDetails,
  AddNewActivitiesRequest
} from './types';

const {
  EDIT_ACTIVITIES_REQUEST,
  EDIT_ACTIVITIES_SUCCESS,
  EDIT_ACTIVITIES_FAILURE,  
  ADD_NEW_ACTIVITIES_REQUEST,
  ADD_NEW_ACTIVITIES_SUCCESS,
  ADD_NEW_ACTIVITIES_FAILURE,  
  GET_ALL_ACTIVITIES_MODULE_REQUEST,
  GET_ALL_ACTIVITIES_MODULE_SUCCESS,
  GET_ALL_ACTIVITIES_MODULE_FAILURE,  
  GET_ACTIVITIES_DETAILS_REQUEST,
  GET_ACTIVITIES_DETAILS_SUCCESS,
  GET_ACTIVITIES_DETAILS_FAILURE,
  GET_FILTERED_ACTIVITIES_REQUEST,
  GET_FILTERED_ACTIVITIES_SUCCESS,
  GET_FILTERED_ACTIVITIES_FAILURE,
  RESET_THE_LAST_ACTIVITIES_DETAILS,
  ACTIVE_OR_INACTIVE_ACTIVITIES_REQUEST,
  ACTIVE_OR_INACTIVE_ACTIVITIES_SUCCESS,
  ACTIVE_OR_INACTIVE_ACTIVITIES_FAILURE,
  GET_ALL_ACTIVITIES_PROJECTSETTINGS_REQUEST,
  GET_FILTERED_ACTIVITIES_PROJECTSETTINGS_REQUEST
} = ActivitiesActions;

function getActivitiesModuleRequest(last_page?: number): Action {
  return {
    type: GET_ALL_ACTIVITIES_MODULE_REQUEST,
    payload: {
      last_page
    },
  };
}

function getActivitiesModuleSuccess(activities: Activities[],  total: number, last_page: number, has_next: boolean): Action {
  return {
    type: GET_ALL_ACTIVITIES_MODULE_SUCCESS,
    payload: {
      activities,
      total,
      last_page, 
      has_next
    },
  };
}

function getActivitiesModuleFailure(): Action {
  return {
    type: GET_ALL_ACTIVITIES_MODULE_FAILURE,
  };
}

function getActivitiesDetailsRequest(activitiesId: string): Action {
  return {
    type: GET_ACTIVITIES_DETAILS_REQUEST,
    payload: {
      activitiesId,
    },
  };
}

function getActivitiesDetailsSuccess(activitiesDetails: ActivitiesDetails): Action {
  return {
    type: GET_ACTIVITIES_DETAILS_SUCCESS,
    payload: {
      activitiesDetails,
    },
  };
}

function getActivitiesDetailsFailure(): Action {
  return {
    type: GET_ACTIVITIES_DETAILS_FAILURE,
  };
}

function getSearchFilteredActivitiesRequest(
  filteredActivities: Array<string | boolean | null>,
  hasEvidence: boolean | null,
  isActive: boolean | null
): Action {
  return {
    type: GET_FILTERED_ACTIVITIES_REQUEST,
    payload: {
      filteredActivities,
      hasEvidence,
      isActive
    },
  };
}

function getSearchFilteredActivitiesSuccess(activities: Activities[]): Action {
  return {
    type: GET_FILTERED_ACTIVITIES_SUCCESS,
    payload: {
      activities,
    },
  };
}

function getSearchFilteredActivitiesFailure(): Action {
  return {
    type: GET_FILTERED_ACTIVITIES_FAILURE,
  };
}

function addNewActivitiesRequest(activities: AddNewActivitiesRequest): Action {
  return {
    type: ADD_NEW_ACTIVITIES_REQUEST,
    payload: {
      activities,
    },
  };
}

function addNewActivitiesSuccess(): Action {
  return {
    type: ADD_NEW_ACTIVITIES_SUCCESS,
  };
}

function addNewActivitiesFailure(): Action {
  return {
    type: ADD_NEW_ACTIVITIES_FAILURE,
  };
}

function editActivitiesRequest(activitiesId: string, name: string, has_evidence: boolean, is_active: boolean): Action {
  return {
    type: EDIT_ACTIVITIES_REQUEST,
    payload: {
      activitiesId,
      name,
      has_evidence,
      is_active
    },
  };
}

function editActivitiesSuccess(): Action {
  return {
    type: EDIT_ACTIVITIES_SUCCESS,
  };
}

function editActivitiesFailure(): Action {
  return {
    type: EDIT_ACTIVITIES_FAILURE,
  };
}

function activeOrInactiveActivitiesRequest(
  id: string,
  updatedStatus: boolean
): Action {
  return {
    type: ACTIVE_OR_INACTIVE_ACTIVITIES_REQUEST,
    payload: {
      id,
      updatedStatus,
    },
  };
}

function activeOrInactiveActivitiesSuccess(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_ACTIVITIES_SUCCESS,
  };
}

function activeOrInactiveActivitiesFailure(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_ACTIVITIES_FAILURE,
  };
}

function resetTheLastActivitiesDetails(): Action {
  return {
    type: RESET_THE_LAST_ACTIVITIES_DETAILS,
  };
}

function getActivitiesProjectSettingsRequest(): Action {
  return {
    type: GET_ALL_ACTIVITIES_PROJECTSETTINGS_REQUEST,
  };
}

function getSearchFilteredActivitiesProjectSettingsRequest(
  filteredActivities: Array<string | boolean | null>,
  hasEvidence: boolean | null,
  isActive: boolean | null
): Action {
  return {
    type: GET_FILTERED_ACTIVITIES_PROJECTSETTINGS_REQUEST,
    payload: {
      filteredActivities,
      hasEvidence,
      isActive
    },
  };
}

export const activitiesActionsFunctions = {
  getActivitiesModuleRequest
  ,getActivitiesModuleSuccess
  ,getActivitiesModuleFailure
  ,editActivitiesRequest
  ,editActivitiesSuccess
  ,editActivitiesFailure
  ,addNewActivitiesRequest
  ,addNewActivitiesSuccess
  ,addNewActivitiesFailure
  ,getActivitiesDetailsRequest
  ,getActivitiesDetailsSuccess
  ,getActivitiesDetailsFailure
  ,resetTheLastActivitiesDetails
  ,activeOrInactiveActivitiesRequest
  ,activeOrInactiveActivitiesSuccess
  ,activeOrInactiveActivitiesFailure
  ,getSearchFilteredActivitiesRequest
  ,getSearchFilteredActivitiesSuccess
  ,getSearchFilteredActivitiesFailure
  ,getActivitiesProjectSettingsRequest
  ,getSearchFilteredActivitiesProjectSettingsRequest
};