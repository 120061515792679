export enum CustomerSectorsActions {
  GET_ALL_CUSTOMER_SECTORS_REQUEST = 'GET_ALL_CUSTOMER_SECTORS_REQUEST',
  GET_ALL_CUSTOMER_SECTORS_SUCCESS = 'GET_ALL_CUSTOMER_SECTORS_SUCCESS',
  GET_ALL_CUSTOMER_SECTORS_FAILURE = 'GET_ALL_CUSTOMER_SECTORS_FAILURE',
}

export type Segments = {
  id: string;
  name: string;
};

export type SubSectors = {
  id: string;
  name: string;
  segments: Segments[];
};

export type Sectors = {
  id: string;
  name: string;
  subsectors: SubSectors[];
};

export type CustomerSectorsState = {
  allSectorsCustomer: Sectors[];
};
