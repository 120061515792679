import type { Action } from 'store/types';

import type {
  AddNewVulnerabilityData,
  AddNewVulnerabilityResponseData,
  Control,
  ControlEnums,
  CVE,
  EditVulnerabilityData,
  Threat,
  Vulnerability,
  SimpleVulnerability,
  SimpleControl,
} from './types';
import { VulnerabilitiesActions } from './types';

const {
  GET_ALL_VULNERABILITIES_REQUEST,
  GET_ALL_VULNERABILITIES_SUCCESS,
  GET_ALL_VULNERABILITIES_FAILURE,
  GET_FILTERED_PROJECTS_VULNERABILITY_REQUEST,
  GET_FILTERED_VULNERABILITY_SUCCESS,
  GET_FILTERED_VULNERABILITY_FAILURE,
  GET_FILTERED_THREAT_REQUEST,
  GET_FILTERED_THREAT_SUCCESS,
  GET_FILTERED_THREAT_FAILURE,
  GET_FILTERED_PROJECTS_CONTROL_REQUEST,
  GET_FILTERED_CONTROL_SUCCESS,
  GET_FILTERED_CONTROL_FAILURE,
  GET_VULNERABILITY_DETAIL_REQUEST,
  GET_VULNERABILITY_DETAIL_SUCCESS,
  GET_VULNERABILITY_DETAIL_FAILURE,
  ADD_NEW_VULNERABILITY_REQUEST,
  ADD_NEW_VULNERABILITY_SUCCESS,
  ADD_NEW_VULNERABILITY_FAILURE,
  EDIT_VULNERABILITY_REQUEST,
  EDIT_VULNERABILITY_SUCCESS,
  EDIT_VULNERABILITY_FAILURE,
  ACTIVE_OR_INACTIVE_VULNERABILITY_REQUEST,
  ACTIVE_OR_INACTIVE_VULNERABILITY_SUCCESS,
  ACTIVE_OR_INACTIVE_VULNERABILITY_FAILURE,
  GET_ALL_CVEs_REQUEST,
  GET_ALL_CVEs_SUCCESS,
  GET_ALL_CVEs_FAILURE,
  GET_ALL_THREATS_REQUEST,
  GET_ALL_THREATS_SUCCESS,
  GET_ALL_THREATS_FAILURE,
  GET_ALL_CONTROLS_REQUEST,
  GET_ALL_CONTROLS_SUCCESS,
  GET_ALL_CONTROLS_FAILURE,
  GET_ALL_CONTROL_ENUMS_REQUEST,
  GET_ALL_CONTROL_ENUMS_SUCCESS,
  GET_ALL_CONTROL_ENUMS_FAILURE,
  RESET_THE_VULNERABILITY_DETAIL_STATE,
  GET_FILTERED_CVE_REQUEST,
  GET_FILTERED_CVE_SUCCESS,
  GET_FILTERED_CVE_FAILURE,
  GET_CVE_DETAIL_REQUEST,
  GET_CVE_DETAIL_SUCCESS,
  GET_CVE_DETAIL_FAILURE,
  GET_ALL_CVES_MODULE_REQUEST,
  GET_ALL_CVES_MODULE_SUCCESS,
  GET_ALL_CVES_MODULE_FAILURE,
  GET_ALL_SIMPLE_VULNERABILITIES_REQUEST,
  GET_ALL_SIMPLE_VULNERABILITIES_SUCCESS,
  GET_ALL_SIMPLE_VULNERABILITIES_FAILURE,
  GET_ALL_SIMPLE_CONTROLS_REQUEST,
  GET_ALL_SIMPLE_CONTROLS_SUCCESS,
  GET_ALL_SIMPLE_CONTROLS_FAILURE,
  GET_FILTERED_SIMPLE_CONTROL_REQUEST,
  GET_FILTERED_SIMPLE_CONTROL_SUCCESS,
  GET_FILTEREDSIMPLE_CONTROL_FAILURE,
} = VulnerabilitiesActions;

function getVulnerabilitiesRequest(last_page?: number): Action {
  return {
    type: GET_ALL_VULNERABILITIES_REQUEST,
    payload: {
      last_page,
    },
  };
}

function getVulnerabilitiesSuccess(vulnerabilities: Vulnerability[], total_vulnerabilities: number, last_page: number, has_next_vulnerabilities: boolean): Action {
  return {
    type: GET_ALL_VULNERABILITIES_SUCCESS,
    payload: {
      vulnerabilities,
      total_vulnerabilities,
      last_page,
      has_next_vulnerabilities
    },
  };
}

function getVulnerabilitiesFailure(): Action {
  return {
    type: GET_ALL_VULNERABILITIES_FAILURE,
  };
}

function getSearchFilteredVulnerabilityRequest(
  filteredVulnerabilities: Array<string | boolean | null>
): Action {
  return {
    type: GET_FILTERED_PROJECTS_VULNERABILITY_REQUEST,
    payload: {
      filteredVulnerabilities,
    },
  };
}

function getSearchFilteredVulnerabilitySuccess(
  simpleVulnerability: SimpleVulnerability[]
): Action {
  return {
    type: GET_FILTERED_VULNERABILITY_SUCCESS,
    payload: {
      simpleVulnerability,
    },
  };
}

function getSearchFilteredVulnerabilityFailure(): Action {
  return {
    type: GET_FILTERED_VULNERABILITY_FAILURE,
  };
}

function getSearchFilteredThreatRequest(
  filteredThreats: Array<string | boolean | null>
): Action {
  return {
    type: GET_FILTERED_THREAT_REQUEST,
    payload: {
      filteredThreats,
    },
  };
}

function getSearchFilteredThreatSuccess(threats: Threat[]): Action {
  return {
    type: GET_FILTERED_THREAT_SUCCESS,
    payload: {
      threats,
    },
  };
}

function getSearchFilteredThreatFailure(): Action {
  return {
    type: GET_FILTERED_THREAT_FAILURE,
  };
}

function getSearchFilteredControlRequest(
  filteredControls: Array<string | boolean | null>,
  isActive: boolean | null
): Action {
  return {
    type: GET_FILTERED_PROJECTS_CONTROL_REQUEST,
    payload: {
      filteredControls,
      isActive
    },
  };
}

function getSearchFilteredControlSuccess(controls: Control[]): Action {
  return {
    type: GET_FILTERED_CONTROL_SUCCESS,
    payload: {
      controls,
    },
  };
}

function getSearchFilteredControlFailure(): Action {
  return {
    type: GET_FILTERED_CONTROL_FAILURE,
  };
}

function getVulnerabilityDetailRequest(vulnerabilityId: string): Action {
  return {
    type: GET_VULNERABILITY_DETAIL_REQUEST,
    payload: {
      vulnerabilityId,
    },
  };
}

function getVulnerabilityDetailSuccess(vulnerability: Vulnerability): Action {
  return {
    type: GET_VULNERABILITY_DETAIL_SUCCESS,
    payload: {
      vulnerability,
    },
  };
}

function getVulnerabilityDetailFailure(): Action {
  return {
    type: GET_VULNERABILITY_DETAIL_FAILURE,
  };
}

function addNewVulnerabilityRequest(
  newVulnerability: AddNewVulnerabilityData
): Action {
  return {
    type: ADD_NEW_VULNERABILITY_REQUEST,
    payload: {
      newVulnerability,
    },
  };
}

function addNewVulnerabilitySuccess(
  vulnerabilityAdded: AddNewVulnerabilityResponseData
): Action {
  return {
    type: ADD_NEW_VULNERABILITY_SUCCESS,
    payload: {
      vulnerabilityAdded,
    },
  };
}

function addNewVulnerabilityFailure(): Action {
  return {
    type: ADD_NEW_VULNERABILITY_FAILURE,
  };
}

function editVulnerabilityRequest(
  updatedVulnerability: EditVulnerabilityData
): Action {
  return {
    type: EDIT_VULNERABILITY_REQUEST,
    payload: {
      updatedVulnerability,
    },
  };
}

function editVulnerabilitySuccess(): Action {
  return {
    type: EDIT_VULNERABILITY_SUCCESS,
  };
}

function editVulnerabilityFailure(): Action {
  return {
    type: EDIT_VULNERABILITY_FAILURE,
  };
}

function activeOrInactiveVulnerabilityRequest(
  vulnerabilityId: string,
  newVulnerabilityStatus: boolean
): Action {
  return {
    type: ACTIVE_OR_INACTIVE_VULNERABILITY_REQUEST,
    payload: {
      vulnerabilityId,
      newVulnerabilityStatus,
    },
  };
}

function activeOrInactiveVulnerabilitySuccess(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_VULNERABILITY_SUCCESS,
  };
}

function activeOrInactiveVulnerabilityFailure(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_VULNERABILITY_FAILURE,
  };
}

function getCVEsRequest(): Action {
  return {
    type: GET_ALL_CVEs_REQUEST,
  };
}

function getCVEsSuccess(cves: CVE[]): Action {
  return {
    type: GET_ALL_CVEs_SUCCESS,
    payload: {
      cves,
    },
  };
}

function getCVEsFailure(): Action {
  return {
    type: GET_ALL_CVEs_FAILURE,
  };
}

function getThreatsRequest(last_page?: number): Action {
  return {
    type: GET_ALL_THREATS_REQUEST,
    payload: {
      last_page
    }
  };
}

function getThreatsSuccess(threats: Threat[], totalThreats: number, last_page: number, has_next: boolean): Action {
  return {
    type: GET_ALL_THREATS_SUCCESS,
    payload: {
      threats,
      totalThreats,
      last_page,
      has_next
    },
  };
}

function getThreatsFailure(): Action {
  return {
    type: GET_ALL_THREATS_FAILURE,
  };
}

function getControlsRequest(
  last_page?: number, 
  isActive?: boolean
): Action {
  return {
    type: GET_ALL_CONTROLS_REQUEST,
    payload: {
      last_page,
      isActive
    }
  };
}

function getControlsSuccess(
  controls: Control[], 
  total: number, 
  last_page: number, 
  has_next: boolean
): Action {
  return {
    type: GET_ALL_CONTROLS_SUCCESS,
    payload: {
      controls,
      total,
      last_page,
      has_next
    },
  };
}

function getControlsFailure(): Action {
  return {
    type: GET_ALL_CONTROLS_FAILURE,
  };
}

function getControlEnumsRequest(): Action {
  return {
    type: GET_ALL_CONTROL_ENUMS_REQUEST,
  };
}

function getControlEnumsSuccess(controlEnums: ControlEnums): Action {
  return {
    type: GET_ALL_CONTROL_ENUMS_SUCCESS,
    payload: {
      controlEnums,
    },
  };
}

function getControlEnumsFailure(): Action {
  return {
    type: GET_ALL_CONTROL_ENUMS_FAILURE,
  };
}

function resetTheVulnerabilityDetailState(): Action {
  return {
    type: RESET_THE_VULNERABILITY_DETAIL_STATE,
  };
}

function getSearchFilteredCVERequest(
  filteredCVEs: Array<string | boolean | null>
): Action {
  return {
    type: GET_FILTERED_CVE_REQUEST,
    payload: {
      filteredCVEs,
    },
  };
}

function getSearchFilteredCVESuccess(cves: CVE[]): Action {
  return {
    type: GET_FILTERED_CVE_SUCCESS,
    payload: {
      cves,
    },
  };
}

function getSearchFilteredCVEFailure(): Action {
  return {
    type: GET_FILTERED_CVE_FAILURE,
  };
}

function getCVEDetailRequest(cveId: string): Action {
  return {
    type: GET_CVE_DETAIL_REQUEST,
    payload: {
      cveId,
    },
  };
}

function getCVEDetailSuccess(cve: CVE): Action {
  return {
    type: GET_CVE_DETAIL_SUCCESS,
    payload: {
      cve,
    },
  };
}

function getCVEDetailFailure(): Action {
  return {
    type: GET_CVE_DETAIL_FAILURE,
  };
}

function getCVEsModuleRequest(last_page?: number): Action {
  return {
    type: GET_ALL_CVES_MODULE_REQUEST,
    payload: {
      last_page
    },
  };
}

function getCVEsModuleSuccess(cves: CVE[], total_cve: number, last_page: number, has_next_cve: boolean): Action {
  return {
    type: GET_ALL_CVES_MODULE_SUCCESS,
    payload: {
      cves,
      total_cve,
      last_page,
      has_next_cve
    },
  };
}

function getCVEsModuleFailure(): Action {
  return {
    type: GET_ALL_CVES_MODULE_FAILURE,
  };
}

function getSimpleVulnerabilitiesRequest(
  last_page?: number
): Action {
  return {
    type: GET_ALL_SIMPLE_VULNERABILITIES_REQUEST,
    payload: {
      last_page,
    },
  };
}

function getSimpleVulnerabilitiesSuccess(
  simpleVulnerability: SimpleVulnerability[], 
  total_vulnerabilities: number, 
  last_page: number, 
  has_next_vulnerabilities: boolean
): Action {
  return {
    type: GET_ALL_SIMPLE_VULNERABILITIES_SUCCESS,
    payload: {
      simpleVulnerability,
      total_vulnerabilities,
      last_page,
      has_next_vulnerabilities
    },
  };
}

function getSimpleVulnerabilitiesFailure(): Action {
  return {
    type: GET_ALL_SIMPLE_VULNERABILITIES_FAILURE,
  };
}

function getSimpleControlsRequest(
  last_page?: number, 
  isActive?: boolean
): Action {
  return {
    type: GET_ALL_SIMPLE_CONTROLS_REQUEST,
    payload: {
      last_page,
      isActive
    }
  };
}

function getSimpleControlsSuccess(
  simpleControl: SimpleControl[], 
  total: number, 
  last_page: number, 
  has_next: boolean
): Action {
  return {
    type: GET_ALL_SIMPLE_CONTROLS_SUCCESS,
    payload: {
      simpleControl,
      total,
      last_page,
      has_next
    },
  };
}

function getSimpleControlsFailure(): Action {
  return {
    type: GET_ALL_SIMPLE_CONTROLS_FAILURE,
  };
}

function getSearchFilteredSimpleControlRequest(
  filteredControls: Array<string | boolean | null>,
  isActive: boolean | null
): Action {
  return {
    type: GET_FILTERED_SIMPLE_CONTROL_REQUEST,
    payload: {
      filteredControls,
      isActive
    },
  };
}

function getSearchFilteredSimpleControlSuccess(simpleControl: SimpleControl[]): Action {
  return {
    type: GET_FILTERED_SIMPLE_CONTROL_SUCCESS,
    payload: {
      simpleControl,
    },
  };
}

function getSearchFilteredSimpleControlFailure(): Action {
  return {
    type: GET_FILTEREDSIMPLE_CONTROL_FAILURE,
  };
}

export const vulnerabilitiesActionsFunctions = {
  getVulnerabilitiesRequest,
  getVulnerabilitiesSuccess,
  getVulnerabilitiesFailure,
  getSearchFilteredVulnerabilityRequest,
  getSearchFilteredVulnerabilitySuccess,
  getSearchFilteredVulnerabilityFailure,
  getSearchFilteredThreatRequest,
  getSearchFilteredThreatSuccess,
  getSearchFilteredThreatFailure,
  getSearchFilteredControlRequest,
  getSearchFilteredControlSuccess,
  getSearchFilteredControlFailure,
  getVulnerabilityDetailRequest,
  getVulnerabilityDetailSuccess,
  getVulnerabilityDetailFailure,
  addNewVulnerabilityRequest,
  addNewVulnerabilitySuccess,
  addNewVulnerabilityFailure,
  editVulnerabilityRequest,
  editVulnerabilitySuccess,
  editVulnerabilityFailure,
  activeOrInactiveVulnerabilityRequest,
  activeOrInactiveVulnerabilitySuccess,
  activeOrInactiveVulnerabilityFailure,
  getCVEsRequest,
  getCVEsSuccess,
  getCVEsFailure,
  getThreatsRequest,
  getThreatsSuccess,
  getThreatsFailure,
  getControlsRequest,
  getControlsSuccess,
  getControlsFailure,
  getControlEnumsRequest,
  getControlEnumsSuccess,
  getControlEnumsFailure,
  resetTheVulnerabilityDetailState,
  getSearchFilteredCVERequest,
  getSearchFilteredCVESuccess,
  getSearchFilteredCVEFailure,
  getCVEDetailRequest,
  getCVEDetailSuccess,
  getCVEDetailFailure,
  getCVEsModuleRequest,
  getCVEsModuleSuccess,
  getCVEsModuleFailure,
  getSimpleVulnerabilitiesRequest,
  getSimpleVulnerabilitiesSuccess,
  getSimpleVulnerabilitiesFailure,
  getSimpleControlsRequest,
  getSimpleControlsSuccess,
  getSimpleControlsFailure,
  getSearchFilteredSimpleControlRequest,
  getSearchFilteredSimpleControlSuccess,
  getSearchFilteredSimpleControlFailure,
};
