import produce from 'immer';
import { Reducer } from 'redux';

import type { OpenedModal, OpenedModalState } from './types';
import { OpenedModalActions } from './types';

const { SET_OPENED_MODAL, RESET_THE_OPENED_MODAL } = OpenedModalActions;

const initialState: OpenedModalState = {
  whichIsTheOpenedModal: null,
};

export const openedModalReducer: Reducer<OpenedModalState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case SET_OPENED_MODAL: {
        const { value } = action.payload;

        draft.whichIsTheOpenedModal = value as OpenedModal;

        break;
      }

      case RESET_THE_OPENED_MODAL: {
        draft.whichIsTheOpenedModal = null;

        break;
      }

      default: {
        return draft;
      }
    }
  });
};
