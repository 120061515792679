export enum VulnerabilitiesActions {
  GET_ALL_VULNERABILITIES_REQUEST = 'GET_ALL_VULNERABILITIES_REQUEST',
  GET_ALL_VULNERABILITIES_SUCCESS = 'GET_ALL_VULNERABILITIES_SUCCESS',
  GET_ALL_VULNERABILITIES_FAILURE = 'GET_ALL_VULNERABILITIES_FAILURE',
  GET_FILTERED_PROJECTS_VULNERABILITY_REQUEST = 'GET_FILTERED_PROJECTS_VULNERABILITY_REQUEST',
  GET_FILTERED_VULNERABILITY_SUCCESS = 'GET_FILTERED_VULNERABILITY_SUCCESS',
  GET_FILTERED_VULNERABILITY_FAILURE = 'GET_FILTERED_VULNERABILITY_FAILURE',
  GET_FILTERED_THREAT_REQUEST = 'GET_FILTERED_THREAT_REQUEST',
  GET_FILTERED_THREAT_SUCCESS = 'GET_FILTERED_THREAT_SUCCESS',
  GET_FILTERED_THREAT_FAILURE = 'GET_FILTERED_THREAT_FAILURE',
  GET_FILTERED_PROJECTS_CONTROL_REQUEST = 'GET_FILTERED_PROJECTS_CONTROL_REQUEST',
  GET_FILTERED_CONTROL_SUCCESS = 'GET_FILTERED_CONTROL_SUCCESS',
  GET_FILTERED_CONTROL_FAILURE = 'GET_FILTERED_CONTROL_FAILURE',
  GET_VULNERABILITY_DETAIL_REQUEST = 'GET_VULNERABILITY_DETAIL_REQUEST',
  GET_VULNERABILITY_DETAIL_SUCCESS = 'GET_VULNERABILITY_DETAIL_SUCCESS',
  GET_VULNERABILITY_DETAIL_FAILURE = 'GET_VULNERABILITY_DETAIL_FAILURE',
  ADD_NEW_VULNERABILITY_REQUEST = 'ADD_NEW_VULNERABILITY_REQUEST',
  ADD_NEW_VULNERABILITY_SUCCESS = 'ADD_NEW_VULNERABILITY_SUCCESS',
  ADD_NEW_VULNERABILITY_FAILURE = 'ADD_NEW_VULNERABILITY_FAILURE',
  EDIT_VULNERABILITY_REQUEST = 'EDIT_VULNERABILITY_REQUEST',
  EDIT_VULNERABILITY_SUCCESS = 'EDIT_VULNERABILITY_SUCCESS',
  EDIT_VULNERABILITY_FAILURE = 'EDIT_VULNERABILITY_FAILURE',
  ACTIVE_OR_INACTIVE_VULNERABILITY_REQUEST = 'ACTIVE_OR_INACTIVE_VULNERABILITY_REQUEST',
  ACTIVE_OR_INACTIVE_VULNERABILITY_SUCCESS = 'ACTIVE_OR_INACTIVE_VULNERABILITY_SUCCESS',
  ACTIVE_OR_INACTIVE_VULNERABILITY_FAILURE = 'ACTIVE_OR_INACTIVE_VULNERABILITY_FAILURE',
  GET_ALL_CVEs_REQUEST = 'GET_ALL_CVEs_REQUEST',
  GET_ALL_CVEs_SUCCESS = 'GET_ALL_CVEs_SUCCESS',
  GET_ALL_CVEs_FAILURE = 'GET_ALL_CVEs_FAILURE',
  GET_ALL_THREATS_REQUEST = 'GET_ALL_THREATS_REQUEST',
  GET_ALL_THREATS_SUCCESS = 'GET_ALL_THREATS_SUCCESS',
  GET_ALL_THREATS_FAILURE = 'GET_ALL_THREATS_FAILURE',
  GET_ALL_CONTROLS_REQUEST = 'GET_ALL_CONTROLS_REQUEST',
  GET_ALL_CONTROLS_SUCCESS = 'GET_ALL_CONTROLS_SUCCESS',
  GET_ALL_CONTROLS_FAILURE = 'GET_ALL_CONTROLS_FAILURE',
  GET_ALL_CONTROL_ENUMS_REQUEST = 'GET_ALL_CONTROL_ENUMS_REQUEST',
  GET_ALL_CONTROL_ENUMS_SUCCESS = 'GET_ALL_CONTROL_ENUMS_SUCCESS',
  GET_ALL_CONTROL_ENUMS_FAILURE = 'GET_ALL_CONTROL_ENUMS_FAILURE',
  RESET_THE_VULNERABILITY_DETAIL_STATE = 'RESET_THE_VULNERABILITY_DETAIL_STATE',
  GET_FILTERED_CVE_REQUEST = 'GET_FILTERED_CVE_REQUEST',
  GET_FILTERED_CVE_SUCCESS = 'GET_FILTERED_CVE_SUCCESS',
  GET_FILTERED_CVE_FAILURE = 'GET_FILTERED_CVE_FAILURE',
  GET_CVE_DETAIL_REQUEST = 'GET_CVE_DETAIL_REQUEST',
  GET_CVE_DETAIL_SUCCESS = 'GET_CVE_DETAIL_SUCCESS',
  GET_CVE_DETAIL_FAILURE = 'GET_CVE_DETAIL_FAILURE',
  GET_ALL_CVES_MODULE_REQUEST = 'GET_ALL_CVES_MODULE_REQUEST',
  GET_ALL_CVES_MODULE_SUCCESS = 'GET_ALL_CVES_MODULE_SUCCESS',
  GET_ALL_CVES_MODULE_FAILURE = 'GET_ALL_CVES_MODULE_FAILURE',
  GET_ALL_SIMPLE_VULNERABILITIES_REQUEST = 'GET_ALL_SIMPLE_VULNERABILITIES_REQUEST',
  GET_ALL_SIMPLE_VULNERABILITIES_SUCCESS = 'GET_ALL_SIMPLE_VULNERABILITIES_SUCCESS',
  GET_ALL_SIMPLE_VULNERABILITIES_FAILURE = 'GET_ALL_SIMPLE_VULNERABILITIES_FAILURE',
  GET_ALL_SIMPLE_CONTROLS_REQUEST = 'GET_ALL_SIMPLE_CONTROLS_REQUEST',
  GET_ALL_SIMPLE_CONTROLS_SUCCESS = 'GET_ALL_SIMPLE_CONTROLS_SUCCESS',
  GET_ALL_SIMPLE_CONTROLS_FAILURE = 'GET_ALL_SIMPLE_CONTROLS_FAILURE',
  GET_FILTERED_SIMPLE_CONTROL_REQUEST = 'GET_FILTERED_SIMPLE_CONTROL_REQUEST',
  GET_FILTERED_SIMPLE_CONTROL_SUCCESS = 'GET_FILTERED_SIMPLE_CONTROL_SUCCESS',
  GET_FILTEREDSIMPLE_CONTROL_FAILURE = 'GET_FILTEREDSIMPLE_CONTROL_FAILURE',
}

export type ObjectWithId = {
  id: string;
};

export type SearchFilteredVulnerabilityRequestPayload = {
  filteredVulnerabilities: Array<string | boolean | null>;
};

export type SearchFilteredThreatRequestPayload = {
  filteredThreats: Array<string | boolean | null>;
  isActive: boolean | null;
};

export type SearchFilteredControlRequestPayload = {
  filteredControls: Array<string | boolean | null>;
  isActive: boolean | null;
};

export type AddNewVulnerabilityRequestPayload = {
  newVulnerability: AddNewVulnerabilityData;
};

export type VulnerabilityDetailRequestPayload = {
  vulnerabilityId: string;
};

export type CVEDetailRequestPayload = {
  cveId: string;
};

export type EditVulnerabilityRequestPayload = {
  updatedVulnerability: EditVulnerabilityData;
};

export type UpdateVulnerabilityStatusRequestPayload = {
  vulnerabilityId: string;
  newVulnerabilityStatus: boolean;
};

export type AddNewVulnerabilityData = {
  name: string;
  vulnerability_type: string;
  attack_vector: string;
  attack_complexity: string;
  privileges_required: string;
  user_interaction: string;
  scope: string;
  confidentiality: string;
  integrity: string;
  availability: string;
  is_active?: boolean;
  controls: ObjectWithId[];
  cves: ObjectWithId[];
};

export type AddNewVulnerabilityResponseData = {
  id: string;
  friendly_id: string;
  name: string;
  vulnerability_type: string;
  attack_vector: string;
  attack_complexity: string;
  privileges_required: string;
  user_interaction: string;
  scope: string;
  confidentiality: string;
  integrity: string;
  availability: string;
  is_active: boolean;
  registration_status: string;
  controls: ObjectWithId[];
  cves: ObjectWithId[];
};

export type EditVulnerabilityData = {
  id: string;
  friendly_id: string;
  name: string;
  vulnerability_type: string;
  attack_vector: string;
  attack_complexity: string;
  privileges_required: string;
  user_interaction: string;
  scope: string;
  confidentiality: string;
  integrity: string;
  availability: string;
  is_active: boolean;
  controls_add: ObjectWithId[];
  controls_remove: ObjectWithId[];
  threats_add: ObjectWithId[];
  threats_remove: ObjectWithId[];
  cves_add: ObjectWithId[];
  cves_remove: ObjectWithId[];
  registration_status: string;
};

export type ThreatOfVulnerability = {
  id: string;
  name: string;
  description: string;
};

export type ControlOfVulnerability = {
  id: string;
  name: string;
};

export type VulnerabilityTableColumn = {
  cves: CVE[];
  id: string;
  name: string;
  vulnerability_type: string;
};

export type CVEOfVulnerability = {
  id: string;
  cve_name_id: string;
  cve_description: string;
};

export type VulnerabilityResults = {
  results: Vulnerability[];
  count: number;
  next: boolean;
}

export type Vulnerability = {
  id: string;
  friendly_id: string;
  name: string;
  vulnerability_type: string;
  attack_vector: string;
  attack_complexity: string;
  privileges_required: string;
  user_interaction: string;
  scope: string;
  confidentiality: string;
  integrity: string;
  availability: string;
  is_active: boolean;
  registration_status: string;
  threats: ThreatOfVulnerability[];
  controls: ControlOfVulnerability[];
  cves: CVEOfVulnerability[];
};

export type VulnerabilityWithAdd = {
  id: string;
  friendly_id: string;
  name: string;
  vulnerability_type: string;
  attack_vector: string;
  attack_complexity: string;
  privileges_required: string;
  user_interaction: string;
  scope: string;
  confidentiality: string;
  integrity: string;
  availability: string;
  is_active: boolean;
  registration_status: string;
  threats: ThreatOfVulnerability[];
  controls: ControlOfVulnerability[];
  cves: CVEOfVulnerability[];
  vulnerability_add: ObjectWithId,
};

export type CVEResults = {
  results: CVE[];
  count: number;
  next: boolean;
}

export type CVE = {
  id: string;
  cve_status: string;
  cve_reference: string;
  cve_name_id: string;
  cve_description: string;
};

export type ThreatResults = {
  count: number;
  next: boolean
  previous: boolean
  results: Threat[];
}

export type Threat = {
  id: string;
  friendly_id: string;
  name: string;
  description: string;
  is_active: boolean;
  registration_status: string;
  vulnerabilities: Vulnerability[];
  threats: ObjectWithId[];
  damages: ObjectWithId[];
  controls: ObjectWithId[];
};

export type ThreatWithAddAndRemove = {
  id: string;
  friendly_id: string;
  name: string;
  description: string;
  is_active: boolean;
  registration_status: string;
  vulnerabilities_add: ObjectWithId[];
  vulnerabilities_remove: ObjectWithId[];
  threats_add: ObjectWithId[];
  threats_remove: ObjectWithId[];
  damages_add: ObjectWithId[];
  damages_remove: ObjectWithId[];
  controls_add: ObjectWithId[];
  controls_remove: ObjectWithId[];
  risk_factors_add: ObjectWithId[];
  risk_factors_remove: ObjectWithId[];
}

export type Subcontrol = {
  id: string;
  control: string;
  name: string;
  cost: number;
  benefit: number;
  cost_benefit: string;
  technical_capacity: number;
  final_benefit: string;
  is_active: boolean;
};

export type IdAndName = {
  id: string;
  name: string;
}

export type CategoriesType = {
  id: string;
  name: string;
  description: string;
  importance: number;
}

export type VulnerabilityControlResults = {
  results: Control[] | SimpleControl[];
}

export type Control = {
  id: string;
  friendly_id: string;
  name: string;
  class_name: IdAndName;
  group_name: IdAndName;
  type_name: IdAndName;
  category: CategoriesType;
  control_type: IdAndName;
  confidentiality: boolean;
  integrity: boolean;
  availability: boolean;
  identify: boolean;
  protect: boolean;
  detect: boolean;
  respond: boolean;
  recover: boolean;
  attack_vector: string;
  attack_complexity: string;
  privileges_required: string;
  user_interaction: string;
  scope: string;
  confidentiality_level: string;
  integrity_level: string;
  availability_level: string;
  cost: number;
  technical_capacity: number;
  benefit: number;
  cost_benefit: string;
  final_benefit: string;
  subcontrols: Subcontrol[];
  is_active: boolean;
};

export type ControlThreatVizualizationColumns = {
  id: string;
  name: string;
  type_enum: TypeEnumInterface;
  type_name: IdAndName;
  group_enum: ControlGroup;
  group_name: IdAndName;
  control_type: ControlType;
  class_enum: ControlClass;
  class_name: IdAndName;
  category: ControlCategory;
};

export type ControlRiskVizualizationColumns = {
  name: string;
  control_type: string;
  group_name: string;
  type_name: string;
  category: string;
  class_name: string;
  control_alternative: string;
  total_benefit: string
};

export type TypeEnumInterface = {
  id: string;
  name: string;
};

export type TypeControl = {
  id: string;
  name: string;
  is_active: boolean;
};

export type ControlGroup = {
  id: string;
  name: string;
};

export type ControlType = {
  id: string;
  name: string;
};

export type ControlCategory = {
  id: string;
  name: string;
  importance: number;
  description: string;
};

export type ControlClass = {
  id: string;
  name: string;
};

export type ControlEnums = {
  classes: ControlClass[];
  groups: ControlGroup[];
  types: ControlType[];
  categories: ControlCategory[];
  control_types: TypeControl[];
};

export type SimpleVulnerability = {
  id: string;
  name: string;
  vulnerability_type: string;
  cves: CVEOfVulnerability[];
  threats: number;
  controls: number;
  is_active: boolean;
}

export type SimpleVulnerabilityResults = {
  results: SimpleVulnerability[];
  count: number;
  next: boolean;
}

export type SimpleControl = {
  id: string;
  name: string;
  category: string;
  type_name: string;
  group_name: string;
  class_name: string;
  control_type: string;
  is_active: boolean;
}

export type VulnerabilitiesState = {
  vulnerabilities: Vulnerability[];
  currentVulnerabilityDetail: Vulnerability;
  cves: CVE[];
  currentCVEDetail: CVE;
  threats: Threat[];
  controls: Control[];
  simpleControl: SimpleControl[];
  controlEnums: {
    controlTypes: TypeControl[];
    groups: ControlGroup[];
    types: ControlType[];
    categories: ControlCategory[];
    classes: ControlClass[];
  };
  visited_pages: number[];
  has_next: true;
  total: number;
  total_threats: number;
  has_next_threats: boolean;
  total_vulnerabilities: number;
  has_next_vulnerabilities: boolean;
  total_cve: number;
  has_next_cve: boolean;
  simpleVulnerability: SimpleVulnerability[];
};

export type SearchFilteredCVEsRequestPayload = {
  filteredCVEs: Array<string | boolean | null>;
};
