import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import type { Action } from 'store/types';

import { marketAverageActionsFunctions } from './actions';
import { 
  ApiFetchMarketAverageDetailsRequestPayloadParams, 
  MarketAverageActions, 
  MarketAverageDetail, 
  MarketAverageResults, 
  MarketScoreCardAverage 
} from './types';


const {
  GET_ALL_MARKET_AVERAGE_REQUEST,
  GET_MARKET_SCORECARD_AVERAGE_REQUEST,
  GET_MARKET_AVERAGE_DETAILS_REQUEST,
} = MarketAverageActions;

const {
  getMarketAverageSectorsSuccess,
  getMarketAverageSectorsFailure,
  getMarketScoreCardAverageSuccess,
  getMarketScoreCardAverageFailure,
  getMarketAverageDetailSuccess,
  getMarketAverageDetailFailure,
} = marketAverageActionsFunctions;

const {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE
} = ToastMessages;

const {
  getLastApiFetchDataSuccess,
  getLastApiFetchDataFailure
} = lastApiFetchDataActionsFunctions;

function* fetchMarketAverageSectorsRequest(): Generator {
  try {
    const marketAverageSectorsResponse: AxiosResponse<MarketAverageResults> | unknown = yield call(
      api,
      'GET',
      '/controls/sectors/',
      {}
    );

    const {
      data: { results, count, next, previous },
      config: { url },
      status,
      statusText,
    } = marketAverageSectorsResponse as AxiosResponse<MarketAverageResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getMarketAverageSectorsSuccess(results, count, next, previous));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getMarketAverageSectorsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchMarketScoreCardAverageRequest(): Generator {
  try {
    const marketScoreCardAverageResponse: AxiosResponse<MarketScoreCardAverage> | unknown = yield call(
      api,
      'GET',
      '/controls/market-scorecard-average/',
      {}
    );

    const {
      data: marketScoreCardAverage,
      config: { url },
      status,
      statusText,
    } = marketScoreCardAverageResponse as AxiosResponse<MarketScoreCardAverage>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getMarketScoreCardAverageSuccess(marketScoreCardAverage));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getMarketScoreCardAverageFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchMarketAverageDetailsRequest(action: Action): Generator {
  const { id, type } = action.payload as ApiFetchMarketAverageDetailsRequestPayloadParams;
  const returnEndPoint = ({
    id,
    type
  }: ApiFetchMarketAverageDetailsRequestPayloadParams): string => {
    switch (type) {
      case 'sector':
        return `/controls/sectors/${id}/customers-projects/`;

      case 'subsector':
        return `/controls/subsectors/${id}/customers-projects/`;

      case 'segment':
        return `/controls/segments/${id}/customers-projects/`;

      default:
        return '';
    }
  };

  try {
    const marketAverageDetailsResponse: AxiosResponse<MarketAverageDetail> | unknown = yield call(
      api,
      'GET',
      returnEndPoint({ id, type }),
      {}
    );

    const {
      data: marketAverageDetail,
      config: { url },
      status,
      statusText,
    } = marketAverageDetailsResponse as AxiosResponse<MarketAverageDetail>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getMarketAverageDetailSuccess(marketAverageDetail));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getMarketAverageDetailFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

export function* marketAverageSagas() {
  yield all([
    takeLatest(GET_ALL_MARKET_AVERAGE_REQUEST, fetchMarketAverageSectorsRequest),
    takeLatest(GET_MARKET_SCORECARD_AVERAGE_REQUEST, fetchMarketScoreCardAverageRequest),
    takeLatest(GET_MARKET_AVERAGE_DETAILS_REQUEST, fetchMarketAverageDetailsRequest),
  ]);
}
