export enum CustomerProjectControlActions {
  GET_CUSTOMER_PROJECT_CONTROLS_REQUEST = 'GET_CUSTOMER_PROJECT_CONTROLS_REQUEST',
  GET_CUSTOMER_PROJECT_CONTROLS_SUCCESS = 'GET_CUSTOMER_PROJECT_CONTROLS_SUCCESS',
  GET_CUSTOMER_PROJECT_CONTROLS_FAILURE = 'GET_CUSTOMER_PROJECT_CONTROLS_FAILURE',
  GET_CONTROLS_CUSTOMER_PROJECT_DETAIL_REQUEST = 'GET_CONTROLS_CUSTOMER_PROJECT_DETAIL_REQUEST',
  GET_CONTROLS_CUSTOMER_PROJECT_DETAIL_SUCCESS = 'GET_CONTROLS_CUSTOMER_PROJECT_DETAIL_SUCCESS',
  GET_CONTROLS_CUSTOMER_PROJECT_DETAIL_FAILURE = 'GET_CONTROLS_CUSTOMER_PROJECT_DETAIL_FAILURE',
  EDIT_CONTROL_CUSTOMER_PROJECT_STATUS_REQUEST = 'EDIT_CONTROL_CUSTOMER_PROJECT_STATUS_REQUEST',
  EDIT_CONTROL_CUSTOMER_PROJECT_STATUS_SUCCESS = 'EDIT_CONTROL_CUSTOMER_PROJECT_STATUS_SUCCESS',
  EDIT_CONTROL_CUSTOMER_PROJECT_STATUS_FAILURE = 'EDIT_CONTROL_CUSTOMER_PROJECT_STATUS_FAILURE',
  GET_CUSTOMER_PROJECT_CONTROLS_STATIC_REQUEST = 'GET_CUSTOMER_PROJECT_CONTROLS_STATIC_REQUEST',
  GET_CUSTOMER_PROJECT_CONTROLS_STATIC_SUCCESS = 'GET_CUSTOMER_PROJECT_CONTROLS_STATIC_SUCCESS',
  GET_CUSTOMER_PROJECT_CONTROLS_STATIC_FAILURE = 'GET_CUSTOMER_PROJECT_CONTROLS_STATIC_FAILURE',
  PUT_CUSTOMER_CONTROL_PARTIALLY_REQUEST = 'PUT_CUSTOMER_CONTROL_PARTIALLY_REQUEST',
  PUT_CUSTOMER_CONTROL_PARTIALLY_SUCCESS = 'PUT_CUSTOMER_CONTROL_PARTIALLY_SUCCESS',
  PUT_CUSTOMER_CONTROL_PARTIALLY_FAILURE =  'PUT_CUSTOMER_CONTROL_PARTIALLY_FAILURE'
}

export type ControlType = {
  id: string;
  name: string;
}

export type ClassName = {
  id: string;
  name: string;
}

export type GroupName = {
  id: string;
  name: string;
}

export type TypeName = {
  id: string;
  name: string;
}

export type Category = {
  id: string;
  name: string;
  importance: string;
  description: string;
}

export type Vulnerability = {
  id: string;
  name: string;
};

export type RiskFactor = {
  id: string;
  name: string;
};

export type Threat = {
  id: string;
  name: string;
  description: string;
}

export type ChecklistOrigin = {
  id: string,
  subject: string,
}

export type QuestionnairesOrigin = {
  id: string;
  name: string;
  description: string;
  type_of_questionnaire: string;
}

export type Controls = {
  id: string;
  name: string;
  control_type: ControlType;
  class_name: ClassName;
  group_name: GroupName;
  type_name: TypeName;
  category: Category;
  final_benefit: string;
  vulnerabilities: Array<Vulnerability>;
  risk_factors: Array<RiskFactor>;
  threats: Array<Threat>;
  checklists_origin: Array<ChecklistOrigin>;
  questionnaires_origin: Array<QuestionnairesOrigin>;
  is_active: boolean;
  justification_of_inactivation: string;
  reduced_risks: string;
  partially_applied: boolean;
  justification_for_partially_applied: string;
}

export type CustomerProjectsControls = {
  id: string;
  name: string;
  controls_customer_project: Array<Controls>;
}

export type CustomerControlApiPayload = {
  projectId: string;
}

export type CustomerControls = {
  id: string;
  name: string;
  control_type: string;
  group_name: string;
  type_name: string;
  class_name: string;
  category: string;
  reduced_risks: number;
  final_benefit: number;
  is_active: boolean;
  partially_applied: boolean;
}

export type CustomerControlStatic = {
  controls: CustomerControls[];
}

export type CustomerProjectControlState = {
  control: Controls;
  customerProjectControls: CustomerProjectsControls;
  customerControls: CustomerControlStatic;
}

export type CustomerProjetcControlApiPayload = {
  customerProjectId: string;
}

export type CustomerProjetcControlDetailApiPayload = {
  controlId: string;
}

export type ChangeStatusBodyType = {
  justification_of_inactivation: string,
  is_active: boolean
}

export type CustomerProjetcChangeStatusApiPayload = {
  controlId: string;
  changeStatusBody: ChangeStatusBodyType;
  customerProjectId: string;
}

export type CustomerControlPartiallyPayload = {
  justification_for_partially_applied: string;
  partially_applied: boolean;
  controlId: string;
  customerProjectId: string;
}


