import { ObjectWithId } from 'store/modules/threats/types';

import { Control, SimpleControl } from '../vulnerabilities/types';

export enum ControlActions {
  GET_CONTROL_DETAIL_REQUEST = 'GET_CONTROL_DETAIL_REQUEST',
  GET_CONTROL_DETAIL_SUCCESS = 'GET_CONTROL_DETAIL_SUCCESS',
  GET_CONTROL_DETAIL_FAILURE = 'GET_CONTROL_DETAIL_FAILURE',
  CREATE_NEW_CONTROL_REQUEST = 'CREATE_NEW_CONTROL_REQUEST',
  CREATE_NEW_CONTROL_SUCCESS = 'CREATE_NEW_CONTROL_SUCCESS',
  CREATE_NEW_CONTROL_FAILURE = 'CREATE_NEW_CONTROL_FAILURE',
  EDIT_CONTROL_REGISTRATION_REQUEST = 'EDIT_CONTROL_REGISTRATION_REQUEST',
  EDIT_CONTROL_REGISTRATION_SUCCESS = 'EDIT_CONTROL_REGISTRATION_SUCCESS',
  EDIT_CONTROL_REGISTRATION_FAILURE = 'EDIT_CONTROL_REGISTRATION_FAILURE',
  EDIT_CONTROL_REQUEST = 'EDIT_CONTROL_REQUEST',
  EDIT_CONTROL_SUCCESS = 'EDIT_CONTROL_SUCCESS',
  EDIT_CONTROL_FAILURE = 'EDIT_CONTROL_FAILURE',
  EDIT_CONTROL_STATUS_REQUEST = 'EDIT_CONTROL_STATUS_REQUEST',
  EDIT_CONTROL_STATUS_SUCCESS = 'EDIT_CONTROL_STATUS_SUCCESS',
  EDIT_CONTROL_STATUS_FAILURE = 'EDIT_CONTROL_STATUS_FAILURE',
}

export type ControlEditBody = {
  vulnerabilities_add: ObjectWithId[],
  vulnerabilities_remove: ObjectWithId[],
  risk_factors_add: ObjectWithId[],
  risk_factors_remove: ObjectWithId[],
  threats_add: ObjectWithId[],
  threats_remove: ObjectWithId[]
}

export type ControlInfoUpdateStatus = {
  controlId: string;
  controlStatus: boolean
}

export type SubControlsWithControl = {
  benefit: number;
  cost: number;
  cost_benefit?: number;
  final_benefit?: number;
  id?: string | number;
  name: string;
  technical_capacity: number;
  control?: string;
}

export type ControlEditRequestBody = {
  friendly_id: string,
  name: string,
  class_name: number,
  group_name: number,
  type_name: number,
  category: number,
  control_type: string,
  confidentiality: boolean,
  integrity: boolean,
  availability: boolean,
  identify: boolean,
  protect: boolean,
  detect: boolean,
  respond: boolean,
  recover: boolean,
  attack_vector: string[] | undefined,
  attack_complexity: string[] | undefined,
  privileges_required: string[] | undefined,
  user_interaction: string[] | undefined,
  scope: string[] | undefined,
  confidentiality_level: string[] | undefined,
  integrity_level: string[] | undefined,
  availability_level: string[] | undefined,
  cost: number | null,
  technical_capacity: number | null,
  benefit: number | null,
  cost_benefit: number | null,
  final_benefit: number | null,
  subcontrols_add: SubControlsWithControl[],
  subcontrols_update?: SubControlsWithControl[],
  subcontrols_delete: ObjectWithId[],
  is_active: true
}

export type ControlEditBodyResponse = {
  id: string;
  vulnerabilities_add: ObjectWithId[],
  vulnerabilities_remove: ObjectWithId[],
  risk_factors_add: ObjectWithId[],
  risk_factors_remove: ObjectWithId[],
  threats_add: ObjectWithId[],
  threats_remove: ObjectWithId[]
}

export type apiControlRequestActionPayload = {
  controlId: string;
}

export type apiEditControlRegistrationsRequestActionPayload = {
  controlId: string;
  controlEditBody: ControlEditRequestBody;
  controlEditRegistrationBody: ControlEditBody;
}

export type apiCreateNewControlRequestActionPayload = {
  controlBody: ControlBody;
}

export type ControlRiskFactor = {
  id: string;
  name: string;
}

export type ControlThreats = {
  id: string;
  name: string;
  description: string;
}

export type ControlVulnerabilities = {
  id: string;
  name: string;
  vulnerability_type: string;
  cves: []
}

export type SubControls = {
  benefit: number;
  cost: number;
  cost_benefit?: number;
  final_benefit?: number;
  id?: string | number;
  name: string;
  technical_capacity: number;
}


export type ControlBody = { 
  name: string;
  class_name: string;
  group_name: string;
  type_name: string;
  category: string;
  control_type: string;
  confidentiality: boolean;
  integrity: boolean;
  availability: boolean;
  identify: boolean;
  protect: boolean;
  detect: boolean;
  respond: boolean;
  recover: boolean;
  attack_vector: string[] | undefined;
  attack_complexity: string[] | undefined;
  privileges_required: string[] | undefined;
  user_interaction: string[] | undefined;
  scope: string[] | undefined;
  confidentiality_level: string[] | undefined;
  integrity_level: string[] | undefined;
  availability_level: string[] | undefined;
  cost: number | null;
  subcontrols: SubControls[];
  technical_capacity: number | null;
  benefit: number | null;
  is_active: boolean
}

export type IdAndName = {
  id: string;
  name: string;
}

export type CategoriesType = {
  id: string;
  name: string;
  description: string;
  importance: number;
}

export type ControlDetailResults = {
  results: Control[] | SimpleControl[];
  count: number;
  next: boolean;
}

export type ControlDetail = {
  attack_complexity: string[] ;
  attack_vector: string[] ;
  availability: boolean;
  availability_level: string[];
  benefit: number;
  category: CategoriesType;
  class_name: IdAndName;
  confidentiality: boolean;
  confidentiality_level: string[] ;
  control_type: IdAndName;
  cost: number;
  cost_benefit: number;
  detect: boolean;
  final_benefit: number;
  friendly_id: string;
  group_name: IdAndName;
  id: string;
  identify: boolean;
  integrity: boolean;
  integrity_level: string[] ;
  is_active: boolean;
  name: string;
  privileges_required: string[] ;
  protect: boolean;
  recover: boolean;
  respond: boolean;
  risk_factors: ControlRiskFactor[];
  scope: string[] ;
  subcontrols: SubControls[];
  technical_capacity: number;
  threats: ControlThreats[];
  type_name: IdAndName;
  user_interaction: string[] ;
  vulnerabilities: ControlVulnerabilities[];
}

export type ControlDetailWithOptions = {
  attack_complexity?: string[] ;
  attack_vector?: string[] ;
  availability?: boolean;
  availability_level?: string[] ;
  benefit?: number;
  category?: CategoriesType;
  class_name?: IdAndName;
  confidentiality?: boolean;
  confidentiality_level?: string[] ;
  control_type?: IdAndName;
  cost?: number;
  cost_benefit?: number;
  detect?: boolean;
  final_benefit?: number;
  friendly_id?: string;
  group_name?: IdAndName;
  id: string;
  identify?: boolean;
  integrity?: boolean;
  integrity_level?: string[] ;
  is_active?: boolean;
  name: string;
  privileges_required?: string[] ;
  protect?: boolean;
  recover?: boolean;
  respond?: boolean;
  risk_factors?: ControlRiskFactor[];
  scope?: string[] ;
  subcontrols?: SubControls[];
  technical_capacity?: number;
  threats?: ControlThreats[];
  type_name?: IdAndName;
  user_interaction?: string[] ;
  vulnerabilities?: ControlVulnerabilities[];
}

export type ControlState = {
  controlDetail: ControlDetail
}