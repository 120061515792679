export enum SectorsActions {
  GET_ALL_SECTORS_REQUEST = 'GET_ALL_SECTORS_REQUEST',
  GET_ALL_SECTORS_SUCCESS = 'GET_ALL_SECTORS_SUCCESS',
  GET_ALL_SECTORS_FAILURE = 'GET_ALL_SECTORS_FAILURE',
  GET_SECTOR_DETAILS_REQUEST = 'GET_SECTOR_DETAILS_REQUEST',
  GET_SECTOR_DETAILS_SUCCESS = 'GET_SECTOR_DETAILS_SUCCESS',
  GET_SECTOR_DETAILS_FAILURE = 'GET_SECTOR_DETAILS_FAILURE',
  GET_ALL_SUB_SECTORS_REQUEST = 'GET_ALL_SUB_SECTORS_REQUEST',
  GET_ALL_SUB_SECTORS_SUCCESS = 'GET_ALL_SUB_SECTORS_SUCCESS',
  GET_ALL_SUB_SECTORS_FAILURE = 'GET_ALL_SUB_SECTORS_FAILURE',
  GET_ALL_SEGMENTS_REQUEST = 'GET_ALL_SEGMENTS_REQUEST',
  GET_ALL_SEGMENTS_SUCCESS = 'GET_ALL_SEGMENTS_SUCCESS',
  GET_ALL_SEGMENTS_FAILURE = 'GET_ALL_SEGMENTS_FAILURE',
  GET_ALL_DEPARTMENTS_REQUEST = 'GET_ALL_DEPARTMENTS_REQUEST',
  GET_ALL_DEPARTMENTS_SUCCESS = 'GET_ALL_DEPARTMENTS_SUCCESS',
  GET_ALL_DEPARTMENTS_FAILURE = 'GET_ALL_DEPARTMENTS_FAILURE',
  GET_FILTERED_SECTOR_REQUEST = 'GET_FILTERED_SECTOR_REQUEST',
  GET_FILTERED_SECTOR_SUCCESS = 'GET_FILTERED_SECTOR_SUCCESS',
  GET_FILTERED_SECTOR_FAILURE = 'GET_FILTERED_SECTOR_FAILURE',
  GET_FILTERED_DEPARTMENT_REQUEST = 'GET_FILTERED_DEPARTMENT_REQUEST',
  GET_FILTERED_DEPARTMENT_SUCCESS = 'GET_FILTERED_DEPARTMENT_SUCCESS',
  GET_FILTERED_DEPARTMENT_FAILURE = 'GET_FILTERED_DEPARTMENT_FAILURE',
  ADD_NEW_SECTOR_REQUEST = 'ADD_NEW_SECTOR_REQUEST',
  ADD_NEW_SECTOR_SUCCESS = 'ADD_NEW_SECTOR_SUCCESS',
  ADD_NEW_SECTOR_FAILURE = 'ADD_NEW_SECTOR_FAILURE',
  EDIT_SECTOR_REQUEST = 'EDIT_SECTOR_REQUEST',
  EDIT_SECTOR_SUCCESS = 'EDIT_SECTOR_SUCCESS',
  EDIT_SECTOR_FAILURE = 'EDIT_SECTOR_FAILURE',
  ADD_NEW_SUB_SECTOR_REQUEST = 'ADD_NEW_SUB_SECTOR_REQUEST',
  ADD_NEW_SUB_SECTOR_SUCCESS = 'ADD_NEW_SUB_SECTOR_SUCCESS',
  ADD_NEW_SUB_SECTOR_FAILURE = 'ADD_NEW_SUB_SECTOR_FAILURE',
  EDIT_SUB_SECTOR_REQUEST = 'EDIT_SUB_SECTOR_REQUEST',
  EDIT_SUB_SECTOR_SUCCESS = 'EDIT_SUB_SECTOR_SUCCESS',
  EDIT_SUB_SECTOR_FAILURE = 'EDIT_SUB_SECTOR_FAILURE',
  ADD_NEW_SEGMENT_REQUEST = 'ADD_NEW_SEGMENT_REQUEST',
  ADD_NEW_SEGMENT_SUCCESS = 'ADD_NEW_SEGMENT_SUCCESS',
  ADD_NEW_SEGMENT_FAILURE = 'ADD_NEW_SEGMENT_FAILURE',
  EDIT_SEGMENT_REQUEST = 'EDIT_SEGMENT_REQUEST',
  EDIT_SEGMENT_SUCCESS = 'EDIT_SEGMENT_SUCCESS',
  EDIT_SEGMENT_FAILURE = 'EDIT_SEGMENT_FAILURE',
  ADD_NEW_DEPARTMENT_REQUEST = 'ADD_NEW_DEPARTMENT_REQUEST',
  ADD_NEW_DEPARTMENT_SUCCESS = 'ADD_NEW_DEPARTMENT_SUCCESS',
  ADD_NEW_DEPARTMENT_FAILURE = 'ADD_NEW_DEPARTMENT_FAILURE',
  EDIT_DEPARTMENT_REQUEST = 'EDIT_DEPARTMENT_REQUEST',
  EDIT_DEPARTMENT_SUCCESS = 'EDIT_DEPARTMENT_SUCCESS',
  EDIT_DEPARTMENT_FAILURE = 'EDIT_DEPARTMENT_FAILURE',
  ACTIVE_OR_INACTIVE_SECTOR_REQUEST = 'ACTIVE_OR_INACTIVE_SECTOR_REQUEST',
  ACTIVE_OR_INACTIVE_SECTOR_SUCCESS = 'ACTIVE_OR_INACTIVE_SECTOR_SUCCESS',
  ACTIVE_OR_INACTIVE_SECTOR_FAILURE = 'ACTIVE_OR_INACTIVE_SECTOR_FAILURE',
  ACTIVE_OR_INACTIVE_SUB_SECTOR_REQUEST = 'ACTIVE_OR_INACTIVE_SUB_SECTOR_REQUEST',
  ACTIVE_OR_INACTIVE_SUB_SECTOR_SUCCESS = 'ACTIVE_OR_INACTIVE_SUB_SECTOR_SUCCESS',
  ACTIVE_OR_INACTIVE_SUB_SECTOR_FAILURE = 'ACTIVE_OR_INACTIVE_SUB_SECTOR_FAILURE',
  ACTIVE_OR_INACTIVE_SEGMENT_REQUEST = 'ACTIVE_OR_INACTIVE_SEGMENT_REQUEST',
  ACTIVE_OR_INACTIVE_SEGMENT_SUCCESS = 'ACTIVE_OR_INACTIVE_SEGMENT_SUCCESS',
  ACTIVE_OR_INACTIVE_SEGMENT_FAILURE = 'ACTIVE_OR_INACTIVE_SEGMENT_FAILURE',
  ACTIVE_OR_INACTIVE_DEPARTMENT_REQUEST = 'ACTIVE_OR_INACTIVE_DEPARTMENT_REQUEST',
  ACTIVE_OR_INACTIVE_DEPARTMENT_SUCCESS = 'ACTIVE_OR_INACTIVE_DEPARTMENT_SUCCESS',
  ACTIVE_OR_INACTIVE_DEPARTMENT_FAILURE = 'ACTIVE_OR_INACTIVE_DEPARTMENT_FAILURE',
  RESET_THE_LAST_SECTOR_DETAILS = 'RESET_THE_LAST_SECTOR_DETAILS',
}

export type AddNewSectorRequest = {
  name: string;
  is_active: boolean;
};

export type SectorDetailsRequest = {
  sectorId: string;
};

export type AddNewSectorRequestPayload = {
  sector: AddNewSectorRequest;
};

export type AddNewSubsectorRequest = {
  sector: string;
  name: string;
  is_active: boolean;
};

export type AddNewSubsectorRequestPayload = {
  subsector: AddNewSubsectorRequest;
};

export type AddNewSegmentRequest = {
  subsector: string;
  name: string;
  is_active: boolean;
};

export type AddNewSegmentRequestPayload = {
  segment: AddNewSegmentRequest;
};

export type SegmentId = {
  id: string;
};

export type AddNewDepartmentRequest = {
  name: string;
  segments?: SegmentId[];
  is_active: boolean;
};

export type AddNewDepartmentRequestPayload = {
  department: AddNewDepartmentRequest;
};

export type DepartmentOfEditingRequest = {
  id: string;
  name: string;
  is_active: boolean;
  segments_add: { id: string }[];
  segments_remove: { id: string }[];
};

export type EditSectorRequestPayload = {
  sector: Sector;
};

export type EditSubsectorRequestPayload = {
  subsector: Subsector;
};

export type EditSegmentRequestPayload = {
  segment: Segment;
};

export type EditDepartmentRequestPayload = {
  department: DepartmentOfEditingRequest;
};

export type SearchFilteredSectorRequestPayloadParams = {
  filteredSector: Array<string | boolean | null>;
};

export type SearchFilteredDepartmentRequestPayloadParams = {
  filteredDepartment: Array<string | boolean | null>;
};

export type UpdateStatusRequestPayloadParams = {
  id: string;
  updatedStatus: boolean;
};

export type Sector = {
  id: string;
  name: string;
  is_active: boolean;
};

export type Subsector = {
  id: string;
  sector: string;
  name: string;
  is_active: boolean;
};

export type Segment = {
  id: string;
  subsector: string;
  name: string;
  is_active: boolean;
};

export type SegmentOfDepartment = {
  id: string;
  name: string;
  departments: { id: string; name: string }[];
};

export type Department = {
  id: string;
  name: string;
  is_active: boolean;
  segments: SegmentOfDepartment[];
};

export type DepartmentsOfSectorDetails = {
  id: string;
  name: string;
  is_active: boolean;
};

export type SegmentsOfSectorDetails = {
  id: string;
  subsector: {
    id: string;
    name: string;
    is_active: boolean;
  };
  name: string;
  is_active: boolean;
  departments: DepartmentsOfSectorDetails[];
};

export interface SubsectorsOfSectorDetails extends Subsector {
  segments: SegmentsOfSectorDetails[];
}

export type SectorDetails = {
  id: string;
  name: string;
  subsectors: SubsectorsOfSectorDetails[];
  segments: SegmentsOfSectorDetails[];
  departments: DepartmentsOfSectorDetails[];
  is_active: boolean;
};

export type SectorsState = {
  allSectors: Sector[];
  loadedSectorsWithDetails: Sector[];
  currentSectorDetails: SectorDetails;
  allSubSectors: Subsector[];
  allSegments: Segment[];
  allDepartments: Department[];
};
