import { applyMiddleware, createStore, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './modules/rootReducer';
import { rootSaga } from './modules/rootSaga';

const persistConfig = {
  key: String(process.env.REACT_APP_LISA_APP_NAME),
  storage,
  whitelist: ['auth'],
};

const isDevelopmentEnvironment =
  typeof window !== 'undefined' && process.env.NODE_ENV !== 'production';

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

const store = createStore(
  persistedReducer,
  isDevelopmentEnvironment
    ? composeWithDevTools(applyMiddleware(...middlewares))
    : compose(applyMiddleware(...middlewares))
);

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
