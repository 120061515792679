import produce from 'immer';
import { Reducer } from 'redux';

import {
  CustomerProjectActions,
  CustomerProjectState
} from './types';

const {
  GET_ALL_CUSTOMER_PROJECT_REQUEST,
  GET_ALL_CUSTOMER_PROJECT_SUCCESS,
  GET_ALL_CUSTOMER_PROJECT_FAILURE,
  CREATE_NEW_CUSTOMER_PROJECT_REQUEST,
  CREATE_NEW_CUSTOMER_PROJECT_SUCCESS,
  CREATE_NEW_CUSTOMER_PROJECT_FAILURE,
  GET_ALL_ANALYSTS_REQUEST,
  GET_ALL_ANALYSTS_SUCCESS,
  GET_ALL_ANALYSTS_FAILURE,
  GET_ALL_CONTROL_CUSTOMERS_REQUEST,
  GET_ALL_CONTROL_CUSTOMERS_SUCCESS,
  GET_ALL_CONTROL_CUSTOMERS_FAILURE,
  GET_ALL_CONTROL_PROJECT_TYPE_REQUEST,
  GET_ALL_CONTROL_PROJECT_TYPE_SUCCESS,
  GET_ALL_CONTROL_PROJECT_TYPE_FAILURE,
  EDIT_CUSTOMER_PROJECT_REQUEST,
  EDIT_CUSTOMER_PROJECT_SUCCESS,
  EDIT_CUSTOMER_PROJECT_FAILURE,
  GET_CUSTOMER_PROJECT_DETAIL_REQUEST,
  GET_CUSTOMER_PROJECT_DETAIL_SUCCESS,
  GET_CUSTOMER_PROJECT_DETAIL_FAILURE,
  EDIT_QUESTIONNAIRES_INTERWEES_REQUEST,
  EDIT_QUESTIONNAIRES_INTERWEES_SUCCESS,
  EDIT_QUESTIONNAIRES_INTERWEES_FAILURE,
  ANALYST_ANSWERING_QUESTION_REQUEST,
  ANALYST_ANSWERING_QUESTION_SUCCESS,
  ANALYST_ANSWERING_QUESTION_FAILURE,
  GET_CUSTOMER_QUESTIONNAIRE_ANSWER_DETAIL_REQUEST,
  GET_CUSTOMER_QUESTIONNAIRE_ANSWER_DETAIL_SUCCESS,
  GET_CUSTOMER_QUESTIONNAIRE_ANSWER_DETAIL_FAILURE,
  DELETE_CUSTOMER_PROJECT_QUESTIONNAIRE_REQUEST,
  DELETE_CUSTOMER_PROJECT_QUESTIONNAIRE_SUCCESS,
  DELETE_CUSTOMER_PROJECT_QUESTIONNAIRE_FAILURE,
  CHECKLIST_ACTIVITY_ANSWER_REQUEST,
  CHECKLIST_ACTIVITY_ANSWER_SUCCESS,
  CHECKLIST_ACTIVITY_ANSWER_FAILURE,
  CHECKLIST_ACTIVITY_EVIDENCES_REQUEST,
  CHECKLIST_ACTIVITY_EVIDENCES_SUCCESS,
  CHECKLIST_ACTIVITY_EVIDENCES_FAILURE,
  EDIT_ACTIVITY_REGISTRATIONS_REQUEST,
  EDIT_ACTIVITY_REGISTRATIONS_SUCCESS,
  EDIT_ACTIVITY_REGISTRATIONS_FAILURE,
  GET_ACTIVITY_DETAILS_REQUEST,
  GET_ACTIVITY_DETAILS_SUCCESS,
  GET_ACTIVITY_DETAILS_FAILURE,
  GET_QUESTION_DETAILS_REQUEST,
  GET_QUESTION_DETAILS_SUCCESS,
  GET_QUESTION_DETAILS_FAILURE,
  GET_CUSTOMER_PROJECT_CHECKLISTS_REQUEST,
  GET_CUSTOMER_PROJECT_CHECKLISTS_SUCCESS,
  GET_CUSTOMER_PROJECT_CHECKLISTS_FAILURE,
  EDIT_ACTIVITY_ANSWER_REQUEST,
  EDIT_ACTIVITY_ANSWER_SUCCESS,
  EDIT_ACTIVITY_ANSWER_FAILURE,
  EDIT_ACTIVITY_ANSWER_EVIDENCES_REQUEST,
  EDIT_ACTIVITY_ANSWER_EVIDENCES_SUCCESS,
  EDIT_ACTIVITY_ANSWER_EVIDENCES_FAILURE,
  EDIT_QUESTION_ANSWER_EVIDENCES_REQUEST,
  EDIT_QUESTION_ANSWER_EVIDENCES_SUCCESS,
  EDIT_QUESTION_ANSWER_EVIDENCES_FAILURE,
  GET_CUSTOMER_PROJECT_THREATS_REQUEST,
  GET_CUSTOMER_PROJECT_THREATS_SUCCESS,
  GET_CUSTOMER_PROJECT_THREATS_FAILURE,
  GET_CUSTOMER_PROJECT_SCORE_CARD_REQUEST,
  GET_CUSTOMER_PROJECT_SCORE_CARD_SUCCESS,
  GET_CUSTOMER_PROJECT_SCORE_CARD_FAILURE,
  GET_THREATS_CUSTOMER_PROJECT_DETAIL_REQUEST,
  GET_THREATS_CUSTOMER_PROJECT_DETAIL_SUCCESS,
  GET_THREATS_CUSTOMER_PROJECT_DETAIL_FAILURE,
  GET_ALL_REPORTS_TEXTS_CUSTOMER_PROJECT_REQUEST,
  GET_ALL_REPORTS_TEXTS_CUSTOMER_PROJECT_SUCCESS,
  GET_ALL_REPORTS_TEXTS_CUSTOMER_PROJECT_FAILURE,
  GET_ORGANOGRAM_CUSTOMER_PROJECT_REQUEST,
  GET_ORGANOGRAM_CUSTOMER_PROJECT_SUCCESS,
  GET_ORGANOGRAM_CUSTOMER_PROJECT_FAILURE,
  GET_ALL_REPORTS_PROJECT_CUSTOMER_PROJECT_REQUEST,
  GET_ALL_REPORTS_PROJECT_CUSTOMER_PROJECT_SUCCESS,
  GET_ALL_REPORTS_PROJECT_CUSTOMER_PROJECT_FAILURE,
  EDIT_ACTIVITY_REGISTRATIONS_REQUEST_DETAIL,
  EDIT_ACTIVITY_REGISTRATIONS_REQUEST_DETAIL_TWO,
  GET_FILTERED_CUSTOMER_PROJECT_REQUEST,
  GET_FILTERED_CUSTOMER_PROJECT_SUCCESS,
  GET_FILTERED_CUSTOMER_PROJECT_FAILURE,

  GET_CUSTOMER_PROJECT_QUESTIONNAIRES_REQUEST,
  GET_CUSTOMER_PROJECT_QUESTIONNAIRES_SUCCESS,
  GET_CUSTOMER_PROJECT_QUESTIONNAIRES_FAILURE,
  NEW_GET_CUSTOMER_PROJECT_QUESTIONNAIRES_REQUEST,
  NEW_GET_CUSTOMER_PROJECT_QUESTIONNAIRES_SUCCESS,
  NEW_GET_CUSTOMER_PROJECT_QUESTIONNAIRES_FAILURE,
  GET_CUSTOMER_PROJECT_QUESTIONNAIRES_DETAIL_REQUEST,
  GET_CUSTOMER_PROJECT_QUESTIONNAIRES_DETAIL_SUCCESS,
  GET_CUSTOMER_PROJECT_QUESTIONNAIRES_DETAIL_FAILURE,
  GET_SCORE_CARD_CHART_REQUEST,
  GET_SCORE_CARD_CHART_SUCCESS,
  GET_SCORE_CARD_CHART_FAILURE,
  GET_RISK_DONUT_CHART_REQUEST,
  GET_RISK_DONUT_CHART_SUCCESS,
  GET_RISK_DONUT_CHART_FAILURE,
  GET_RISK_HEAT_MAP_REQUEST,
  GET_RISK_HEAT_MAP_SUCCESS,
  GET_RISK_HEAT_MAP_FAILURE,
  UPDATE_PROJECT_EXPIRATION_DATE_REQUEST,
  UPDATE_PROJECT_EXPIRATION_DATE_SUCCESS,
  UPDATE_PROJECT_EXPIRATION_DATE_FAILURE,
  GET_PROJECT_CUSTOMER_DETAIL_REQUEST,
  GET_PROJECT_CUSTOMER_DETAIL_SUCCESS,
  GET_PROJECT_CUSTOMER_DETAIL_FAILURE,
  GET_ALL_CUSTOMER_PROJECT_CUSTOMER_REQUEST,
  GET_ALL_CUSTOMER_PROJECT_CUSTOMER_SUCCESS,
  GET_ALL_CUSTOMER_PROJECT_CUSTOMER_FAILURE,
} = CustomerProjectActions;

const initialState: CustomerProjectState = {
  allCustomerProjects: [],
  customersProjectDetail: {
    id: '',
    name: '',
    project_type: {
      activities: [],
      checklists: [],
      crown_jewels: [],
      id: '',
      project_name: '',
      questionnaires: [],
    },
    customer: {
      id: '',
      corporate_name_or_name: '',
      revenue: 0,
      it_budget: 0,
      company_size: '',
      interviewees: []
    },
    sector: '',
    subsector: '',
    segment: '',
    accept_additional_customer_data: false,
    responsible: {
      id: '',
      corporate_name_or_name: ''
    },
    responsible_project_manager: {
      id: '',
      corporate_name_or_name: ''
    },
    project_status: '',
    created_at: '',
    project_expiration_date: '',
    has_scorecard: false,
    justification_of_inactivation: '',
    is_active: true,
    framework: {
      id: '',
      name: '',
      version: '',
      is_active: false,
      approved: false,
      approval_date: '',
    },
    analysts: [],    
    nist_version: '',    
  },
  allAnalysts: [],
  allCustomers: [],
  allProjectType: [],
  checklistActivityDetailsData: {
    activity_id: '',
    activity_name: '',
    has_evidence: false,
    answer: '',
    checklist_id: '',
    controls: [],
    customer_project_id: '',
    evidences: [],
    id: '',
    risk_factors: [],
    vulnerabilities: [],
  },
  checklistQuestionDetails: {
    answer: '',
    checklist_id: '',
    alternative_id: '',
    comment: '',
    controls: [],
    customer_project_id: '',
    evidences: [],
    id: '',
    question: {
      id: '',
      name: '',
      question_type: '',
      has_evidence: false,
      example: '',
      is_active: false,
      alternatives: []
    },
    risk_factors: [],
    vulnerabilities: []
  },
  customerProjectQuestionnaires: {
    id: '',
    name: '',
    customer: {
      id: '',
      corporate_name_or_name: '',
      revenue: 0,
      it_budget: 0,
      company_size: '',
      interviewees: []
    },
    questionnaires: [],
    project_status: '',
    is_active: false
  },
  newCustomerProjectQuestionnaires: {
    id: '',
    name: '',
    customer: {
      id: '',
      corporate_name_or_name: '',
      revenue: 0,
      it_budget: 0,
      company_size: '',
      interviewees: []
    },
    questionnaires: [],
    project_status: '',
    is_active: false,
    questionnaires_external_percentage_total: 0,
    questionnaires_internal_percentage_total: 0,
  },
  customerProjectsQuestionnaireDetails: {
    customer_project_questionnaire_id: '',
    questionnaire_id: '',
    questionnaire_name: '',
    description: '',
    questions: [],
    questions_customer_project: [],
  },
  customerProjectChecklists: {
    id: '',
    name: '',
    checklists: [],
    activities: [],
    project_status: '',
    is_active: false,
  },
  customerProjectThreats: {
    id: '',
    friendly_id: '',
    name: '',
    description: '',
    is_active: false,
    registration_status: '',
    vulnerabilities: [],
    risk_factors: [],
    threats: [],
    damages: [],
    controls: [],
    risks: [],
    questionnaires_origin: [],
    checklists_origin: [],
    justification_of_inactivation: ''
  },
  customerProjectScoreCard: {
    id: '',
    name: '',
    crown_jewels: [],
    csf_core: {},
    score_card: {
      id: '',
      customer_project: '',
      sum_crown_jewels: 0,
      sum_csf_core: 0,
      top_score_crown_jewels: 0,
      top_score_csf_core: 0,
      crown_jewels_normalized: 0,
      crown_jewels_lisa_classification: '',
      csf_core_lisa_classification: '',
      csf_core_normalized: 0,
      final_score: 0,
      score_card_rating: {
        id: '',
        name: '',
        category: '',
        from: 0,
        to: 0
      }
    }
  },
  scoreCardRadarChart: {
    percentage_identify: 0,
    percentage_protect: 0,
    percentage_detect: 0,
    percentage_respond: 0,    
    percentage_recover: 0,
  },
  riskDonutChart: {
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
    info: 0,
  },
  riskHeatMap: {
    risks_probability_low_impact_low_count: 0,
    risks_probability_low_impact_medium_count: 0,
    risks_probability_low_impact_high_count: 0,
    risks_probability_medium_impact_low_count: 0,
    risks_probability_medium_impact_medium_count: 0,
    risks_probability_medium_impact_high_count: 0,
    risks_probability_high_impact_low_count: 0,
    risks_probability_high_impact_medium_count: 0,
    risks_probability_high_impact_high_count: 0,
  },
  allReportsTexts: {
    final_considerations: '',
    id: '',
    introduction: '',
    name: '',
    recommendation: '',
    the_organization: '',
    project_scope: '',
  },
  organograms: {
    id: '',
    name: '',
    organogram: ''
  },
  reportProject: {
    id: '',
    report_pdf: false,
    pdf_report_is_approved: false,
    latest_generation_pdf_report: new Date(),
    pdf_report_sent_by_the_analyst: false,
    report_data_sent_by_analyst: new Date(),
    report_doc: false,
    date_of_last_approval_of_the_pdf: new Date()
  },
  loading: false,
  projectThreats: {
    id: '',
    name: '',
    project_status: '',
    is_active: false,
    threats: [],
  },
  customersProjectsCustomer: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
};

export const customerProjectReducer: Reducer<CustomerProjectState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_CUSTOMER_PROJECT_REQUEST: {
        break;
      }

      case GET_ALL_CUSTOMER_PROJECT_SUCCESS: {
        const { customerProjects } = action.payload;

        draft.allCustomerProjects = customerProjects;
        break;
      }

      case GET_ALL_CUSTOMER_PROJECT_FAILURE: {
        draft.allCustomerProjects = [];
        break;
      }

      case GET_ORGANOGRAM_CUSTOMER_PROJECT_REQUEST: {
        break;
      }

      case GET_ORGANOGRAM_CUSTOMER_PROJECT_SUCCESS: {
        const { organogram } = action.payload;

        draft.organograms = organogram;
        break;

      }

      case GET_ORGANOGRAM_CUSTOMER_PROJECT_FAILURE: {
        draft.organograms = {
          id: '',
          name: '',
          organogram: ''
        };
        break;
      }

      case GET_ALL_REPORTS_PROJECT_CUSTOMER_PROJECT_REQUEST: {
        break;
      }

      case GET_ALL_REPORTS_PROJECT_CUSTOMER_PROJECT_SUCCESS: {
        const { report } = action.payload;

        draft.reportProject = report;
        break;
      }

      case GET_ALL_REPORTS_PROJECT_CUSTOMER_PROJECT_FAILURE: {
        draft.reportProject = {
          id: '',
          report_pdf: false,
          pdf_report_is_approved: false,
          latest_generation_pdf_report: new Date(),
          pdf_report_sent_by_the_analyst: false,
          report_data_sent_by_analyst: new Date(),
          report_doc: false,
          date_of_last_approval_of_the_pdf: new Date()
        };
        break;
      }

      case GET_CUSTOMER_PROJECT_CHECKLISTS_REQUEST: {
        break;
      }

      case GET_CUSTOMER_PROJECT_CHECKLISTS_SUCCESS: {
        const { customerProjectChecklists } = action.payload;

        draft.customerProjectChecklists = customerProjectChecklists;
        break;
      }

      case GET_CUSTOMER_PROJECT_CHECKLISTS_FAILURE: {
        draft.customerProjectChecklists = {
          id: '',
          name: '',
          checklists: [],
          activities: [],
          project_status: '',
          is_active: false,
        };
        break;
      }

      case GET_CUSTOMER_PROJECT_DETAIL_REQUEST:
      case GET_CUSTOMER_PROJECT_DETAIL_FAILURE:
      case GET_PROJECT_CUSTOMER_DETAIL_REQUEST:        
      case GET_PROJECT_CUSTOMER_DETAIL_FAILURE: {
        draft.customersProjectDetail = {
          id: '',
          name: '',
          project_type: {
            activities: [],
            checklists: [],
            crown_jewels: [],
            id: '',
            project_name: '',
            questionnaires: [],
          },
          customer: {
            id: '',
            corporate_name_or_name: '',
            revenue: 0,
            it_budget: 0,
            company_size: '',
            interviewees: []
          },
          sector: '',
          subsector: '',
          segment: '',
          accept_additional_customer_data: false,
          responsible: {
            id: '',
            corporate_name_or_name: ''
          },
          responsible_project_manager: {
            id: '',
            corporate_name_or_name: ''
          },
          project_status: '',
          created_at: '',
          project_expiration_date: '',
          has_scorecard: false,
          justification_of_inactivation: '',
          is_active: true,
          framework: {
            id: '',
            name: '',
            version: '',
            is_active: false,
            approved: false,
            approval_date: '',
          },
          analysts: [],    
          nist_version: '',    
        };
        break;
      }

      case GET_CUSTOMER_PROJECT_DETAIL_SUCCESS: 
      case GET_PROJECT_CUSTOMER_DETAIL_SUCCESS: {
        const { customerProjectDetail } = action.payload;

        draft.customersProjectDetail = customerProjectDetail;

        break;
      }

      case GET_ACTIVITY_DETAILS_REQUEST: {
        break;
      }

      case GET_ACTIVITY_DETAILS_SUCCESS: {
        const { activityBody } = action.payload;

        draft.checklistActivityDetailsData = activityBody;

        break;
      }

      case GET_ACTIVITY_DETAILS_FAILURE: {
        draft.checklistActivityDetailsData = {
          activity_id: '',
          activity_name: '',
          has_evidence: false,
          answer: '',
          checklist_id: '',
          controls: [],
          customer_project_id: '',
          evidences: [],
          id: '',
          risk_factors: [],
          vulnerabilities: [],
        };
        break;
      }

      case GET_QUESTION_DETAILS_REQUEST: {
        break;
      }

      case GET_QUESTION_DETAILS_SUCCESS: {
        const { questionBody } = action.payload;

        draft.checklistQuestionDetails = questionBody;

        break;
      }

      case GET_QUESTION_DETAILS_FAILURE: {
        draft.checklistQuestionDetails = {
          answer: '',
          checklist_id: '',
          alternative_id: '',
          comment: '',
          controls: [],
          customer_project_id: '',
          evidences: [],
          id: '',
          question: {
            id: '',
            name: '',
            question_type: '',
            has_evidence: false,
            example: '',
            is_active: false,
            alternatives: []
          },
          risk_factors: [],
          vulnerabilities: []
        };
        break;
      }

      case GET_ALL_CONTROL_PROJECT_TYPE_REQUEST: {
        break;
      }

      case GET_ALL_CONTROL_PROJECT_TYPE_SUCCESS: {
        const { projectType } = action.payload;

        draft.allProjectType = projectType;

        break;
      }

      case GET_ALL_CONTROL_PROJECT_TYPE_FAILURE: {
        break;
      }

      case GET_ALL_CONTROL_CUSTOMERS_REQUEST: {
        break;
      }

      case GET_ALL_CONTROL_CUSTOMERS_SUCCESS: {
        const { customer } = action.payload;

        draft.allCustomers = customer;

        break;
      }

      case GET_ALL_CONTROL_CUSTOMERS_FAILURE: {
        draft.allCustomers = [];
        break;
      }

      case GET_ALL_ANALYSTS_REQUEST: {
        break;
      }

      case GET_ALL_ANALYSTS_SUCCESS: {
        const { analysts } = action.payload;

        draft.allAnalysts = analysts;

        break;
      }

      case GET_ALL_ANALYSTS_FAILURE: {
        break;
      }

      case GET_CUSTOMER_QUESTIONNAIRE_ANSWER_DETAIL_REQUEST: {
        break;
      }

      case GET_CUSTOMER_QUESTIONNAIRE_ANSWER_DETAIL_SUCCESS: {
        break;
      }

      case GET_CUSTOMER_QUESTIONNAIRE_ANSWER_DETAIL_FAILURE: {
        break;
      }

      case CHECKLIST_ACTIVITY_ANSWER_REQUEST: {
        break;
      }

      case CHECKLIST_ACTIVITY_ANSWER_SUCCESS: {
        break;
      }

      case CHECKLIST_ACTIVITY_ANSWER_FAILURE: {
        break;
      }

      case CHECKLIST_ACTIVITY_EVIDENCES_REQUEST: {
        break;
      }

      case CHECKLIST_ACTIVITY_EVIDENCES_SUCCESS: {
        break;
      }

      case CHECKLIST_ACTIVITY_EVIDENCES_FAILURE: {
        break;
      }

      case CREATE_NEW_CUSTOMER_PROJECT_REQUEST: {
        break;
      }

      case CREATE_NEW_CUSTOMER_PROJECT_SUCCESS: {
        break;
      }

      case CREATE_NEW_CUSTOMER_PROJECT_FAILURE: {
        break;
      }

      case EDIT_CUSTOMER_PROJECT_REQUEST: {
        break;
      }

      case EDIT_CUSTOMER_PROJECT_SUCCESS: {
        break;
      }

      case EDIT_CUSTOMER_PROJECT_FAILURE: {
        break;
      }

      case EDIT_ACTIVITY_ANSWER_REQUEST: {
        break;
      }

      case EDIT_ACTIVITY_ANSWER_SUCCESS: {
        break;
      }

      case EDIT_ACTIVITY_ANSWER_FAILURE: {
        break;
      }

      case EDIT_ACTIVITY_ANSWER_EVIDENCES_REQUEST: {
        break;
      }

      case EDIT_ACTIVITY_ANSWER_EVIDENCES_SUCCESS: {
        break;
      }

      case EDIT_ACTIVITY_ANSWER_EVIDENCES_FAILURE: {
        break;
      }

      case EDIT_QUESTION_ANSWER_EVIDENCES_REQUEST: {
        break;
      }

      case EDIT_QUESTION_ANSWER_EVIDENCES_SUCCESS: {
        break;
      }

      case EDIT_QUESTION_ANSWER_EVIDENCES_FAILURE: {
        break;
      }

      case EDIT_QUESTIONNAIRES_INTERWEES_REQUEST: {
        break;
      }

      case EDIT_QUESTIONNAIRES_INTERWEES_SUCCESS: {
        break;
      }

      case EDIT_QUESTIONNAIRES_INTERWEES_FAILURE: {
        break;
      }

      case EDIT_ACTIVITY_REGISTRATIONS_REQUEST: {
        break;
      }

      case EDIT_ACTIVITY_REGISTRATIONS_SUCCESS: {
        break;
      }

      case EDIT_ACTIVITY_REGISTRATIONS_FAILURE: {
        break;
      }

      case ANALYST_ANSWERING_QUESTION_REQUEST: {
        break;
      }

      case ANALYST_ANSWERING_QUESTION_SUCCESS: {
        break;
      }

      case ANALYST_ANSWERING_QUESTION_FAILURE: {
        break;
      }

      case DELETE_CUSTOMER_PROJECT_QUESTIONNAIRE_REQUEST: {
        break;
      }

      case DELETE_CUSTOMER_PROJECT_QUESTIONNAIRE_SUCCESS: {
        break;
      }

      case DELETE_CUSTOMER_PROJECT_QUESTIONNAIRE_FAILURE: {
        break;
      }

      case GET_CUSTOMER_PROJECT_THREATS_REQUEST:
        break;

      case GET_CUSTOMER_PROJECT_THREATS_SUCCESS: {
        const { projectsThreats } = action.payload;
        draft.projectThreats = projectsThreats;
        break;
      }

      case GET_CUSTOMER_PROJECT_THREATS_FAILURE: {
        draft.projectThreats = {
          id: '',
          name: '',
          project_status: '',
          is_active: false,
          threats: [],
        };
        break;
      }

      case GET_THREATS_CUSTOMER_PROJECT_DETAIL_REQUEST: {
        break;
      }

      case GET_THREATS_CUSTOMER_PROJECT_DETAIL_SUCCESS: {
        const { customerProjectThreats } = action.payload;

        draft.customerProjectThreats = customerProjectThreats;
        break;
      }

      case GET_THREATS_CUSTOMER_PROJECT_DETAIL_FAILURE: {
        draft.customerProjectThreats = {
          id: '',
          friendly_id: '',
          name: '',
          description: '',
          is_active: false,
          registration_status: '',
          vulnerabilities: [],
          risk_factors: [],
          threats: [],
          damages: [],
          controls: [],
          risks: [],
          questionnaires_origin: [],
          checklists_origin: [],
          justification_of_inactivation: ''
        };
        break;
      }

      case GET_CUSTOMER_PROJECT_SCORE_CARD_REQUEST:
        break;

      case GET_CUSTOMER_PROJECT_SCORE_CARD_SUCCESS: {
        const { customerProjectScoreCard } = action.payload;
        draft.customerProjectScoreCard = customerProjectScoreCard;
        break;
      }

      case GET_CUSTOMER_PROJECT_SCORE_CARD_FAILURE: {
        draft.customerProjectScoreCard = {
          id: '',
          name: '',
          crown_jewels: [],
          csf_core: {},
          score_card: {
            id: '',
            customer_project: '',
            sum_crown_jewels: 0,
            sum_csf_core: 0,
            top_score_crown_jewels: 0,
            top_score_csf_core: 0,
            crown_jewels_normalized: 0,
            crown_jewels_lisa_classification: '',
            csf_core_lisa_classification: '',
            csf_core_normalized: 0,
            final_score: 0,
            score_card_rating: {
              id: '',
              name: '',
              category: '',
              from: 0,
              to: 0
            }
          }
        };
        break;
      }

      case GET_ALL_REPORTS_TEXTS_CUSTOMER_PROJECT_REQUEST: {
        break;
      }

      case GET_ALL_REPORTS_TEXTS_CUSTOMER_PROJECT_SUCCESS: {
        const { allReportsText } = action.payload;

        draft.allReportsTexts = allReportsText;
        break;
      }

      case GET_ALL_REPORTS_TEXTS_CUSTOMER_PROJECT_FAILURE: {
        draft.allReportsTexts = {
          final_considerations: '',
          id: '',
          introduction: '',
          name: '',
          recommendation: '',
          the_organization: '',
          project_scope: '',
        };
        break;
      }

      case EDIT_ACTIVITY_REGISTRATIONS_REQUEST_DETAIL:
        break;

      case EDIT_ACTIVITY_REGISTRATIONS_REQUEST_DETAIL_TWO:
        break;

      case GET_FILTERED_CUSTOMER_PROJECT_REQUEST:
        break;

      case GET_FILTERED_CUSTOMER_PROJECT_SUCCESS: {
        const { customerProjects } = action.payload;
        draft.allCustomerProjects = customerProjects;
        break;
      }

      case GET_FILTERED_CUSTOMER_PROJECT_FAILURE:
        break;

      case GET_CUSTOMER_PROJECT_QUESTIONNAIRES_REQUEST: {
        draft.customerProjectQuestionnaires = {
          id: '',
          name: '',
          customer: {
            id: '',
            corporate_name_or_name: '',
            revenue: 0,
            it_budget: 0,
            company_size: '',
            interviewees: []
          },
          questionnaires: [],
          project_status: '',
          is_active: false
        };
        draft.loading = true;
        break;
      }

      case GET_CUSTOMER_PROJECT_QUESTIONNAIRES_SUCCESS: {
        const { customerProjectQuestionnaires } = action.payload;

        draft.customerProjectQuestionnaires = customerProjectQuestionnaires;
        draft.loading = false;
        break;
      }

      case GET_CUSTOMER_PROJECT_QUESTIONNAIRES_FAILURE: {
        draft.customerProjectQuestionnaires = {
          id: '',
          name: '',
          customer: {
            id: '',
            corporate_name_or_name: '',
            revenue: 0,
            it_budget: 0,
            company_size: '',
            interviewees: []
          },
          questionnaires: [],
          project_status: '',
          is_active: false
        };
        draft.loading = false;
        break;
      }

      case NEW_GET_CUSTOMER_PROJECT_QUESTIONNAIRES_REQUEST: {
        draft.newCustomerProjectQuestionnaires = {
          id: '',
          name: '',
          customer: {
            id: '',
            corporate_name_or_name: '',
            revenue: 0,
            it_budget: 0,
            company_size: '',
            interviewees: []
          },
          questionnaires: [],
          project_status: '',
          is_active: false,
          questionnaires_external_percentage_total: 0,
          questionnaires_internal_percentage_total: 0,
        };
        draft.loading = true;
        break;
      }

      case NEW_GET_CUSTOMER_PROJECT_QUESTIONNAIRES_SUCCESS: {
        const { newCustomerProjectQuestionnaires } = action.payload;

        draft.newCustomerProjectQuestionnaires = newCustomerProjectQuestionnaires;
        draft.loading = false;
        break;
      }

      case NEW_GET_CUSTOMER_PROJECT_QUESTIONNAIRES_FAILURE: {
        draft.newCustomerProjectQuestionnaires = {
          id: '',
          name: '',
          customer: {
            id: '',
            corporate_name_or_name: '',
            revenue: 0,
            it_budget: 0,
            company_size: '',
            interviewees: []
          },
          questionnaires: [],
          project_status: '',
          is_active: false,
          questionnaires_external_percentage_total: 0,
          questionnaires_internal_percentage_total: 0,
        };
        draft.loading = false;
        break;
      }

      case GET_CUSTOMER_PROJECT_QUESTIONNAIRES_DETAIL_REQUEST: {
        draft.customerProjectsQuestionnaireDetails = {
          customer_project_questionnaire_id: '',
          questionnaire_id: '',
          questionnaire_name: '',
          description: '',
          questions: [],
          questions_customer_project: [],
        };
        draft.loading = true;
        break;
      }

      case GET_CUSTOMER_PROJECT_QUESTIONNAIRES_DETAIL_SUCCESS: {
        const { customerProjectsQuestionnaireDetails } = action.payload;

        draft.customerProjectsQuestionnaireDetails = customerProjectsQuestionnaireDetails;
        draft.loading = false;
        break;
      }

      case GET_CUSTOMER_PROJECT_QUESTIONNAIRES_DETAIL_FAILURE: {
        draft.customerProjectsQuestionnaireDetails = {
          customer_project_questionnaire_id: '',
          questionnaire_id: '',
          questionnaire_name: '',
          description: '',
          questions: [],
          questions_customer_project: [],
        };
        draft.loading = false;
        break;
      }

      case GET_SCORE_CARD_CHART_REQUEST:
        break;

      case GET_SCORE_CARD_CHART_SUCCESS: {
        const { scoreCardRadarChart } = action.payload;
        draft.scoreCardRadarChart = scoreCardRadarChart;
        break;
      }

      case GET_SCORE_CARD_CHART_FAILURE: {
        draft.scoreCardRadarChart = {
          percentage_identify: 0,
          percentage_protect: 0,
          percentage_detect: 0,
          percentage_respond: 0,    
          percentage_recover: 0,
        };
        break;
      }

      case GET_RISK_DONUT_CHART_REQUEST:
        break;

      case GET_RISK_DONUT_CHART_SUCCESS: {
        const { riskDonutChart } = action.payload;
        draft.riskDonutChart = riskDonutChart;
        break;
      }

      case GET_RISK_DONUT_CHART_FAILURE: {
        draft.riskDonutChart = {
          critical: 0,
          high: 0,
          medium: 0,
          low: 0,
          info: 0,
        };
        break;
      }

      case GET_RISK_HEAT_MAP_REQUEST:
        break;

      case GET_RISK_HEAT_MAP_SUCCESS: {
        const { riskHeatMap } = action.payload;
        draft.riskHeatMap = riskHeatMap;
        break;
      }

      case GET_RISK_HEAT_MAP_FAILURE: {
        draft.riskHeatMap = {
          risks_probability_low_impact_low_count: 0,
          risks_probability_low_impact_medium_count: 0,
          risks_probability_low_impact_high_count: 0,
          risks_probability_medium_impact_low_count: 0,
          risks_probability_medium_impact_medium_count: 0,
          risks_probability_medium_impact_high_count: 0,
          risks_probability_high_impact_low_count: 0,
          risks_probability_high_impact_medium_count: 0,
          risks_probability_high_impact_high_count: 0,
        };
        break;
      }

      case UPDATE_PROJECT_EXPIRATION_DATE_REQUEST:
        break;

      case UPDATE_PROJECT_EXPIRATION_DATE_SUCCESS:
        break;

      case UPDATE_PROJECT_EXPIRATION_DATE_FAILURE:
        break;

      case GET_ALL_CUSTOMER_PROJECT_CUSTOMER_REQUEST:
        break;
      
      case GET_ALL_CUSTOMER_PROJECT_CUSTOMER_SUCCESS: {
        const { customersProjectsCustomer } = action.payload;
        draft.customersProjectsCustomer = customersProjectsCustomer;
        break;
      }

      case GET_ALL_CUSTOMER_PROJECT_CUSTOMER_FAILURE: {
        draft.customersProjectsCustomer = {
          count: 0,
          next: null,
          previous: null,
          results: [],
        };
        break;
      }

      default: {
        return draft;
      }
    }
  });
};
