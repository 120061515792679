import produce from 'immer';
import { Reducer } from 'redux';

import type { VulnerabilitiesState } from './types';
import { VulnerabilitiesActions } from './types';

const {
  GET_ALL_VULNERABILITIES_REQUEST,
  GET_ALL_VULNERABILITIES_SUCCESS,
  GET_ALL_VULNERABILITIES_FAILURE,
  GET_FILTERED_PROJECTS_VULNERABILITY_REQUEST,
  GET_FILTERED_VULNERABILITY_SUCCESS,
  GET_FILTERED_VULNERABILITY_FAILURE,
  GET_FILTERED_THREAT_REQUEST,
  GET_FILTERED_THREAT_SUCCESS,
  GET_FILTERED_THREAT_FAILURE,
  GET_FILTERED_PROJECTS_CONTROL_REQUEST,
  GET_FILTERED_CONTROL_SUCCESS,
  GET_FILTERED_CONTROL_FAILURE,
  GET_VULNERABILITY_DETAIL_REQUEST,
  GET_VULNERABILITY_DETAIL_SUCCESS,
  GET_VULNERABILITY_DETAIL_FAILURE,
  ADD_NEW_VULNERABILITY_REQUEST,
  ADD_NEW_VULNERABILITY_SUCCESS,
  ADD_NEW_VULNERABILITY_FAILURE,
  EDIT_VULNERABILITY_REQUEST,
  EDIT_VULNERABILITY_SUCCESS,
  EDIT_VULNERABILITY_FAILURE,
  ACTIVE_OR_INACTIVE_VULNERABILITY_REQUEST,
  ACTIVE_OR_INACTIVE_VULNERABILITY_SUCCESS,
  ACTIVE_OR_INACTIVE_VULNERABILITY_FAILURE,
  GET_ALL_CVEs_REQUEST,
  GET_ALL_CVEs_SUCCESS,
  GET_ALL_CVEs_FAILURE,
  GET_ALL_THREATS_REQUEST,
  GET_ALL_THREATS_SUCCESS,
  GET_ALL_THREATS_FAILURE,
  GET_ALL_CONTROLS_REQUEST,
  GET_ALL_CONTROLS_SUCCESS,
  GET_ALL_CONTROLS_FAILURE,
  GET_ALL_CONTROL_ENUMS_REQUEST,
  GET_ALL_CONTROL_ENUMS_SUCCESS,
  GET_ALL_CONTROL_ENUMS_FAILURE,
  RESET_THE_VULNERABILITY_DETAIL_STATE,
  GET_FILTERED_CVE_REQUEST,
  GET_FILTERED_CVE_SUCCESS,
  GET_FILTERED_CVE_FAILURE,
  GET_CVE_DETAIL_REQUEST,
  GET_CVE_DETAIL_SUCCESS,
  GET_CVE_DETAIL_FAILURE,
  GET_ALL_CVES_MODULE_REQUEST,
  GET_ALL_CVES_MODULE_SUCCESS,
  GET_ALL_CVES_MODULE_FAILURE,
  GET_ALL_SIMPLE_VULNERABILITIES_REQUEST,
  GET_ALL_SIMPLE_VULNERABILITIES_SUCCESS,
  GET_ALL_SIMPLE_VULNERABILITIES_FAILURE,
  GET_ALL_SIMPLE_CONTROLS_REQUEST,
  GET_ALL_SIMPLE_CONTROLS_SUCCESS,
  GET_ALL_SIMPLE_CONTROLS_FAILURE,
  GET_FILTERED_SIMPLE_CONTROL_REQUEST,
  GET_FILTERED_SIMPLE_CONTROL_SUCCESS,
  GET_FILTEREDSIMPLE_CONTROL_FAILURE,
} = VulnerabilitiesActions;

const initialState: VulnerabilitiesState = {
  vulnerabilities: [],
  visited_pages: [],
  has_next: true,
  total: 0,
  total_threats: 0,
  has_next_threats: true,
  total_vulnerabilities: 0,
  has_next_vulnerabilities: true,
  total_cve: 0,
  has_next_cve: true,
  currentVulnerabilityDetail: {
    id: '',
    friendly_id: '',
    name: '',
    vulnerability_type: '',
    attack_vector: '',
    attack_complexity: '',
    privileges_required: '',
    user_interaction: '',
    scope: '',
    confidentiality: '',
    integrity: '',
    availability: '',
    is_active: false,
    registration_status: '',
    threats: [],
    controls: [],
    cves: [],
  },
  cves: [],
  currentCVEDetail: {
    id: '',
    cve_status: '',
    cve_reference: '',
    cve_name_id: '',
    cve_description: ''
  },
  threats: [],
  controls: [],
  simpleControl: [],
  controlEnums: {
    controlTypes: [],
    groups: [],
    types: [],
    categories: [],
    classes: [],
  },
  simpleVulnerability: [],  
};

export const vulnerabilitiesReducer: Reducer<VulnerabilitiesState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_VULNERABILITIES_REQUEST: {
        break;
      }

      case GET_ALL_VULNERABILITIES_SUCCESS: {
        const { vulnerabilities, total_vulnerabilities, last_page, has_next_vulnerabilities } = action.payload;
        const pagineAlreadyVisited = state.visited_pages.find (page => page === last_page);

        if(!pagineAlreadyVisited || state.vulnerabilities) {
          draft.visited_pages.push(last_page);
          draft.vulnerabilities = last_page === 1 ? vulnerabilities : [...state.vulnerabilities, ...vulnerabilities];
          draft.total_vulnerabilities = total_vulnerabilities;
          draft.has_next_vulnerabilities = has_next_vulnerabilities;
        }

        break;
      }

      case GET_ALL_VULNERABILITIES_FAILURE: {
        draft.vulnerabilities = [];

        break;
      }

      case GET_FILTERED_PROJECTS_VULNERABILITY_REQUEST: {
        break;
      }

      case GET_FILTERED_VULNERABILITY_SUCCESS: {
        const { simpleVulnerability } = action.payload;
        draft.simpleVulnerability = simpleVulnerability;
        break;
      }

      case GET_FILTERED_VULNERABILITY_FAILURE: {
        break;
      }

      case GET_FILTERED_THREAT_REQUEST: {
        break;
      }

      case GET_FILTERED_THREAT_SUCCESS: {
        const { threats } = action.payload;
        
        draft.threats = threats.results;

        break;
      }

      case GET_FILTERED_THREAT_FAILURE: {
        break;
      }

      case GET_FILTERED_PROJECTS_CONTROL_REQUEST: {
        break;
      }

      case GET_FILTERED_CONTROL_SUCCESS: {
        const { controls } = action.payload;

        draft.controls = controls;

        break;
      }

      case GET_FILTERED_CONTROL_FAILURE: {
        break;
      }

      case GET_VULNERABILITY_DETAIL_REQUEST: {
        break;
      }

      case GET_VULNERABILITY_DETAIL_SUCCESS: {
        const { vulnerability } = action.payload;

        draft.currentVulnerabilityDetail = vulnerability;

        break;
      }

      case GET_VULNERABILITY_DETAIL_FAILURE: {
        break;
      }

      case ADD_NEW_VULNERABILITY_REQUEST: {
        break;
      }

      case ADD_NEW_VULNERABILITY_SUCCESS: {
        const { vulnerabilityAdded } = action.payload;

        draft.currentVulnerabilityDetail = vulnerabilityAdded;

        break;
      }

      case ADD_NEW_VULNERABILITY_FAILURE: {
        break;
      }

      case EDIT_VULNERABILITY_REQUEST: {
        break;
      }

      case EDIT_VULNERABILITY_SUCCESS: {
        break;
      }

      case EDIT_VULNERABILITY_FAILURE: {
        break;
      }

      case ACTIVE_OR_INACTIVE_VULNERABILITY_REQUEST: {
        break;
      }

      case ACTIVE_OR_INACTIVE_VULNERABILITY_SUCCESS: {
        break;
      }

      case ACTIVE_OR_INACTIVE_VULNERABILITY_FAILURE: {
        break;
      }

      case GET_ALL_CVEs_REQUEST: {
        break;
      }

      case GET_ALL_CVEs_SUCCESS: {
        const { cves } = action.payload;

        draft.cves = cves;

        break;
      }

      case GET_ALL_CVEs_FAILURE: {
        draft.cves = [];

        break;
      }

      case GET_ALL_THREATS_REQUEST: {
        break;
      }

      case GET_ALL_THREATS_SUCCESS: {
        const { threats, total_threats, last_page_threats, has_next_threats } = action.payload;

        const pagineAlreadyVisited = state.visited_pages.find(page => page === last_page_threats);

        if(!pagineAlreadyVisited || state.threats) {
          draft.threats = last_page_threats === 1 ? threats : [...state.threats, ...threats];
          draft.visited_pages.push(last_page_threats);
          draft.total_threats = total_threats;
          draft.has_next = has_next_threats;
        }

        break;
      }

      case GET_ALL_THREATS_FAILURE: {
        draft.threats = [];

        break;
      }

      case GET_ALL_CONTROLS_REQUEST: {
        break;
      }

      case GET_ALL_CONTROLS_SUCCESS: {
        const { controls, total, last_page, has_next } = action.payload;

        const pagineAlreadyVisited = state.visited_pages.find(page => page === last_page);

        if(!pagineAlreadyVisited || state.controls) {
          draft.controls = last_page === 1 ? controls : [...state.controls, ...controls];
          draft.visited_pages.push(last_page);
          draft.total = total;
          draft.has_next = has_next;
        }

        break;
      }

      case GET_ALL_CONTROLS_FAILURE: {
        draft.controls = [];

        break;
      }

      case GET_ALL_CONTROL_ENUMS_REQUEST: {
        break;
      }

      case GET_ALL_CONTROL_ENUMS_SUCCESS: {
        const { controlEnums } = action.payload;

        draft.controlEnums.classes = controlEnums.classes;
        draft.controlEnums.groups = controlEnums.groups;
        draft.controlEnums.types = controlEnums.types;
        draft.controlEnums.categories = controlEnums.categories;
        draft.controlEnums.controlTypes = controlEnums.control_types;

        break;
      }

      case GET_ALL_CONTROL_ENUMS_FAILURE: {
        draft.controlEnums.classes = [];
        draft.controlEnums.groups = [];
        draft.controlEnums.types = [];
        draft.controlEnums.categories = [];
        draft.controlEnums.controlTypes = [];

        break;
      }

      case RESET_THE_VULNERABILITY_DETAIL_STATE: {
        draft.currentVulnerabilityDetail.id = '';
        draft.currentVulnerabilityDetail.friendly_id = '';
        draft.currentVulnerabilityDetail.name = '';
        draft.currentVulnerabilityDetail.vulnerability_type = '';
        draft.currentVulnerabilityDetail.attack_vector = '';
        draft.currentVulnerabilityDetail.attack_complexity = '';
        draft.currentVulnerabilityDetail.privileges_required = '';
        draft.currentVulnerabilityDetail.user_interaction = '';
        draft.currentVulnerabilityDetail.scope = '';
        draft.currentVulnerabilityDetail.confidentiality = '';
        draft.currentVulnerabilityDetail.integrity = '';
        draft.currentVulnerabilityDetail.availability = '';
        draft.currentVulnerabilityDetail.is_active = false;
        draft.currentVulnerabilityDetail.registration_status = '';
        draft.currentVulnerabilityDetail.threats = [];
        draft.currentVulnerabilityDetail.controls = [];
        draft.currentVulnerabilityDetail.cves = [];

        break;
      }

      case GET_FILTERED_CVE_REQUEST:
        break;

      case GET_FILTERED_CVE_SUCCESS: {
        const { cves } = action.payload;
        draft.cves = cves;
        break;
      }

      case GET_FILTERED_CVE_FAILURE:
        break;

      case GET_CVE_DETAIL_REQUEST:
        break;

      case GET_CVE_DETAIL_SUCCESS: {
        const { cve } = action.payload;

        draft.currentCVEDetail = cve;

        break;
      }

      case GET_CVE_DETAIL_FAILURE:
        break;

      case GET_ALL_CVES_MODULE_REQUEST:
        break;

      case GET_ALL_CVES_MODULE_SUCCESS: {
        const { cves, total_cve, last_page, has_next_cve } = action.payload;
        const pagineAlreadyVisited = state.visited_pages.find (page => page === last_page);

        if(!pagineAlreadyVisited || draft.cves) {
          draft.visited_pages.push(last_page);
          draft.cves =  last_page === 1 ? cves :  [...state.cves, ...cves];
          draft.total_cve = total_cve;
          draft.has_next_cve = has_next_cve;
        }

        break;
      }

      case GET_ALL_CVES_MODULE_FAILURE: {
        draft.cves = [];
        break;
      }

      case GET_ALL_SIMPLE_VULNERABILITIES_REQUEST: {
        break;
      }

      case GET_ALL_SIMPLE_VULNERABILITIES_SUCCESS: {
        const { simpleVulnerability, total_vulnerabilities, last_page, has_next_vulnerabilities } = action.payload;
        const pagineAlreadyVisited = state.visited_pages.find (page => page === last_page);

        if(!pagineAlreadyVisited || draft.simpleVulnerability) {
          draft.visited_pages.push(last_page);                    
          draft.simpleVulnerability = last_page === 1 ? simpleVulnerability : [...state.simpleVulnerability, ...simpleVulnerability];
          draft.total_vulnerabilities = total_vulnerabilities;
          draft.has_next_vulnerabilities = has_next_vulnerabilities;
        }

        break;
      }

      case GET_ALL_SIMPLE_VULNERABILITIES_FAILURE: {
        draft.simpleVulnerability = [];
        break;
      }

      case GET_ALL_SIMPLE_CONTROLS_REQUEST: {
        break;
      }

      case GET_ALL_SIMPLE_CONTROLS_SUCCESS: {
        const { simpleControl, total, last_page, has_next } = action.payload;

        const pagineAlreadyVisited = state.visited_pages.find(page => page === last_page);

        if(!pagineAlreadyVisited || state.simpleControl) {
          draft.simpleControl = last_page === 1 ? simpleControl : [...state.simpleControl, ...simpleControl];
          draft.visited_pages.push(last_page);
          draft.total = total;
          draft.has_next = has_next;
        }

        break;
      }

      case GET_ALL_SIMPLE_CONTROLS_FAILURE: {
        draft.simpleControl = [];
        break;
      }

      case GET_FILTERED_SIMPLE_CONTROL_REQUEST: {
        break;
      }

      case GET_FILTERED_SIMPLE_CONTROL_SUCCESS: {
        const { simpleControl } = action.payload;
        draft.simpleControl = simpleControl;
        break;
      }

      case GET_FILTEREDSIMPLE_CONTROL_FAILURE: {
        break;
      }

      default: {
        return draft;
      }
    }
  });
};
