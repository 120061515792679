import type { Action } from 'store/types';

import { ObjectWithId } from '../Manutention/vulnerabilities/types';
import { 
  ChangeStatusBodyType, 
  CustomerProjectRisksActions, 
  CustomerProjetcRiskRecommendationsBodyType, 
  CustomerProjetcRiskRegistrationsBodyType, 
  DeleteEvidencesResponse, 
  Evidences, 
  QuestionsAnswerBodyType, 
  RiskCustomerProject, 
  RiskMatrixCustomerProject 
} from './types';

const {
  GET_ALL_RISKS_CUSTOMER_PROJECT_REQUEST,
  GET_ALL_RISKS_CUSTOMER_PROJECT_SUCCESS,
  GET_ALL_RISKS_CUSTOMER_PROJECT_FAILURE,
  GENERATE_RISKS_CUSTOMER_PROJECT_REQUEST,
  GENERATE_RISKS_CUSTOMER_PROJECT_SUCCESS,
  GENERATE_RISKS_CUSTOMER_PROJECT_FAILURE,
  GET_RISKS_CUSTOMER_PROJECT_DETAIL_REQUEST,
  GET_RISKS_CUSTOMER_PROJECT_DETAIL_SUCCESS,
  GET_RISKS_CUSTOMER_PROJECT_DETAIL_FAILURE,
  EDIT_RISKS_CUSTOMER_PROJECT_STATUS_REQUEST,
  EDIT_RISKS_CUSTOMER_PROJECT_STATUS_SUCCESS,
  EDIT_RISKS_CUSTOMER_PROJECT_STATUS_FAILURE,
  EDIT_RISKS_CUSTOMER_PROJECT_QUESTIONS_ANSWER_REQUEST,
  EDIT_RISKS_CUSTOMER_PROJECT_QUESTIONS_ANSWER_SUCCESS,
  EDIT_RISKS_CUSTOMER_PROJECT_QUESTIONS_ANSWER_FAILURE,
  EDIT_RISKS_CUSTOMER_PROJECT_REGISTRATION_REQUEST,
  EDIT_RISKS_CUSTOMER_PROJECT_REGISTRATION_SUCCESS,
  EDIT_RISKS_CUSTOMER_PROJECT_REGISTRATION_FAILURE,
  EDIT_RISKS_CUSTOMER_PROJECT_RECOMMENDATION_REQUEST,
  EDIT_RISKS_CUSTOMER_PROJECT_RECOMMENDATION_SUCCESS,
  EDIT_RISKS_CUSTOMER_PROJECT_RECOMMENDATION_FAILURE,
  DELETE_RISK_CUSTOMER_PROJECT_EVIDENCES_REQUEST,
  DELETE_RISK_CUSTOMER_PROJECT_EVIDENCES_SUCCESS,
  DELETE_RISK_CUSTOMER_PROJECT_EVIDENCES_FAILURE,
  GET_EXPORT_RISKS_CUSTOMER_PROJECT_REQUEST,
  GET_EXPORT_RISKS_CUSTOMER_PROJECT_SUCCESS,
  GET_EXPORT_RISKS_CUSTOMER_PROJECT_FAILURE,
  GET_RISK_CUSTOMER_PROJECT_EVIDENCES_DOWNLOAD_REQUEST,
  GET_RISK_CUSTOMER_PROJECT_EVIDENCES_DOWNLOAD_SUCCESS,
  GET_RISK_CUSTOMER_PROJECT_EVIDENCES_DOWNLOAD_FAILURE,
  GET_ALL_RISKS_MATRIX_CUSTOMER_PROJECT_REQUEST,
  GET_ALL_RISKS_MATRIX_CUSTOMER_PROJECT_SUCCESS,
  GET_ALL_RISKS_MATRIX_CUSTOMER_PROJECT_FAILURE,
  EDIT_EVIDENCE_NAME_REQUEST,
  EDIT_EVIDENCE_NAME_SUCCESS,
  EDIT_EVIDENCE_NAME_FAILURE,
  ALTER_POSITION_EVIDENCE_REQUEST,
  ALTER_POSITION_EVIDENCE_SUCCESS,
  ALTER_POSITION_EVIDENCE_FAILURE,
  GET_ALL_EVIDENCES_REQUEST,
  GET_ALL_EVIDENCES_SUCCESS,
  GET_ALL_EVIDENCES_FAILURE,
  ALTER_STATUS_EVIDENCE_REQUEST,
  ALTER_STATUS_EVIDENCE_SUCCESS,
  ALTER_STATUS_EVIDENCE_FAILURE,
  CONFIRM_CLIENT_DATA_REQUEST,
  CONFIRM_CLIENT_DATA_SUCCESS,
  CONFIRM_CLIENT_DATA_FAILURE
} = CustomerProjectRisksActions;

function getCustomerProjectRisksRequest(customerProjectId: string): Action {
  return {
    type: GET_ALL_RISKS_CUSTOMER_PROJECT_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function getCustomerProjectRisksSuccess(customerProjectRisk: RiskCustomerProject): Action {
  return {
    type: GET_ALL_RISKS_CUSTOMER_PROJECT_SUCCESS,
    payload: {
      customerProjectRisk
    }
  };
}

function getCustomerProjectRisksFailure(): Action {
  return {
    type: GET_ALL_RISKS_CUSTOMER_PROJECT_FAILURE,
  };
}

function getExportCustomerProjectRiskRequest(customerProjectId: string, customerName: string): Action {
  return {
    type: GET_EXPORT_RISKS_CUSTOMER_PROJECT_REQUEST,
    payload: {
      customerProjectId,
      customerName
    }
  };
}

function getExportCustomerProjectRiskSuccess(): Action {
  return {
    type: GET_EXPORT_RISKS_CUSTOMER_PROJECT_SUCCESS,
  };
}

function getExportCustomerProjectRiskFailure(): Action {
  return {
    type: GET_EXPORT_RISKS_CUSTOMER_PROJECT_FAILURE,
  };
}

function getCustomerProjectRisksDetailRequest(riskCustomerProjectId: string): Action {
  return {
    type: GET_RISKS_CUSTOMER_PROJECT_DETAIL_REQUEST,
    payload: {
      riskCustomerProjectId,
    }
  };
}

function getCustomerProjectRisksDetailSuccess(riskCustomerProjectDetail: RiskCustomerProject): Action {
  return {
    type: GET_RISKS_CUSTOMER_PROJECT_DETAIL_SUCCESS,
    payload: {
      riskCustomerProjectDetail
    }
  };
}

function getCustomerProjectRisksDetailFailure(): Action {
  return {
    type: GET_RISKS_CUSTOMER_PROJECT_DETAIL_FAILURE,
  };
}

function generateCustomerProjectRisksRequest(customerProjectId: string): Action {
  return {
    type: GENERATE_RISKS_CUSTOMER_PROJECT_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function generateCustomerProjectRisksSuccess(): Action {
  return {
    type: GENERATE_RISKS_CUSTOMER_PROJECT_SUCCESS,
  };
}

function generateCustomerProjectRisksFailure(): Action {
  return {
    type: GENERATE_RISKS_CUSTOMER_PROJECT_FAILURE,
  };
}

function editCustomerProjectRisksStatusRequest(
  riskId: string,
  changeStatusBody: ChangeStatusBodyType,
  customerProjectId: string
  ): Action {
  return {
    type: EDIT_RISKS_CUSTOMER_PROJECT_STATUS_REQUEST,
    payload: {
      riskId,
      changeStatusBody,
      customerProjectId
    }
  };
}

function editCustomerProjectRisksStatusSuccess(): Action {
  return {
    type:  EDIT_RISKS_CUSTOMER_PROJECT_STATUS_SUCCESS,
  };
}

function editCustomerProjectRisksStatusFailure(): Action {
  return {
    type:  EDIT_RISKS_CUSTOMER_PROJECT_STATUS_FAILURE,
  };
}

function editCustomerProjectRisksQuestionInformationRequest(
  riskId: string,
  questionsAnswerBody: QuestionsAnswerBodyType,
  ): Action {
  return {
    type: EDIT_RISKS_CUSTOMER_PROJECT_QUESTIONS_ANSWER_REQUEST,
    payload: {
      riskId,
      questionsAnswerBody,
    }
  };
}

function editCustomerProjectRisksQuestionInformationSuccess(): Action {
  return {
    type: EDIT_RISKS_CUSTOMER_PROJECT_QUESTIONS_ANSWER_SUCCESS,
  };
}

function editCustomerProjectRisksQuestionInformationFailure(): Action {
  return {
    type:  EDIT_RISKS_CUSTOMER_PROJECT_QUESTIONS_ANSWER_FAILURE,
  };
}

function editCustomerProjectRisksRegistrationsRequest(
  riskId: string,
  riskRegistrationsBody: CustomerProjetcRiskRegistrationsBodyType,
  customerProjectId: string,
  disableChange: string,
  ): Action {
  return {
    type: EDIT_RISKS_CUSTOMER_PROJECT_REGISTRATION_REQUEST,
    payload: {
      riskId,
      riskRegistrationsBody,
      customerProjectId,
      disableChange
    }
  };
}

function editCustomerProjectRisksRegistrationsSuccess(): Action {
  return {
    type: EDIT_RISKS_CUSTOMER_PROJECT_REGISTRATION_SUCCESS,
  };
}

function editCustomerProjectRisksRegistrationsFailure(): Action {
  return {
    type: EDIT_RISKS_CUSTOMER_PROJECT_REGISTRATION_FAILURE,
  };
}

function editCustomerProjectRecommendationRequest(
  riskId: string,
  riskRecommendation: CustomerProjetcRiskRecommendationsBodyType,
  customerProjectId: string,
  disableChange: string,
  ): Action {
  return {
    type: EDIT_RISKS_CUSTOMER_PROJECT_RECOMMENDATION_REQUEST,
    payload: {
      riskId,
      riskRecommendation,
      customerProjectId,
      disableChange
    }
  };
}

function editCustomerProjectRecommendationSuccess(): Action {
  return {
    type: EDIT_RISKS_CUSTOMER_PROJECT_RECOMMENDATION_SUCCESS,
  };
}

function editCustomerProjectRecommendationFailure(): Action {
  return {
    type: EDIT_RISKS_CUSTOMER_PROJECT_RECOMMENDATION_FAILURE,
  };
}

function deleteRiskCustomerProjectEvidenceRequest(
  customerProjectId: string,
  riskId: string,
  evidencesRemove: ObjectWithId[]
  ): Action {
  return {
    type: DELETE_RISK_CUSTOMER_PROJECT_EVIDENCES_REQUEST,
    payload: {
      customerProjectId,
      riskId,
      evidencesRemove
    }
  };
}

function deleteRiskCustomerProjectEvidenceSuccess(response: DeleteEvidencesResponse): Action {
  return {
    type: DELETE_RISK_CUSTOMER_PROJECT_EVIDENCES_SUCCESS,
    payload: {
      response
    }
  };
}

function deleteRiskCustomerProjectEvidenceFailure(): Action {
  return {
    type: DELETE_RISK_CUSTOMER_PROJECT_EVIDENCES_FAILURE,
  };
}

function getRiskCustomerProjectEvidenceDownloadRequest(
    evidenceId: string, 
    evidenceName: string
  ): Action {
  return {
    type: GET_RISK_CUSTOMER_PROJECT_EVIDENCES_DOWNLOAD_REQUEST,
    payload: {
      evidenceId,
      evidenceName
    }
  };
}

function getRiskCustomerProjectEvidenceDownloadSuccess(): Action {
  return {
    type: GET_RISK_CUSTOMER_PROJECT_EVIDENCES_DOWNLOAD_SUCCESS,
  };
}

function getRiskCustomerProjectEvidenceDownloadFailure(): Action {
  return {
    type: GET_RISK_CUSTOMER_PROJECT_EVIDENCES_DOWNLOAD_FAILURE,
  };
}

function getCustomerProjectRiskMatrixRequest(projectId: string): Action {
  return {
    type: GET_ALL_RISKS_MATRIX_CUSTOMER_PROJECT_REQUEST,
    payload: {
      projectId,
    }
  };
}

function getCustomerProjectRiskMatrixSuccess(customerProjectRiskMatrix: RiskMatrixCustomerProject): Action {
  return {
    type: GET_ALL_RISKS_MATRIX_CUSTOMER_PROJECT_SUCCESS,
    payload: {
      customerProjectRiskMatrix
    }
  };
}

function getCustomerProjectRiskMatrixFailure(): Action {
  return {
    type: GET_ALL_RISKS_MATRIX_CUSTOMER_PROJECT_FAILURE,
  };
}

function renameEvidenceRequest(
  evidence_id: string,
  evidence_name: string,
  riskId: string,
  ): Action {
  return {
    type: EDIT_EVIDENCE_NAME_REQUEST,
    payload: {
      evidence_id,
      evidence_name,
      riskId,
    }
  };
}

function renameEvidenceSuccess(): Action {
  return {
    type: EDIT_EVIDENCE_NAME_SUCCESS,
  };
}

function renameEvidenceFailure(): Action {
  return {
    type: EDIT_EVIDENCE_NAME_FAILURE,
  };
}

function alterPositionEvidenceRequest(
  evidence_id: string,
  position: number,
  riskId: string,
  ): Action {
  return {
    type: ALTER_POSITION_EVIDENCE_REQUEST,
    payload: {
      evidence_id,
      position,
      riskId,
    }
  };
}

function alterPositionEvidenceSuccess(): Action {
  return {
    type: ALTER_POSITION_EVIDENCE_SUCCESS
  };
}

function alterPositionEvidenceFailure(): Action {
  return {
    type: ALTER_POSITION_EVIDENCE_FAILURE,
  };
}

function alterStatusEvidenceRequest(
  riskId: string,
  typeStatus: string,
  evidences: any[],
  ): Action {
  return {
    type: ALTER_STATUS_EVIDENCE_REQUEST,
    payload: {
      riskId,
      typeStatus,
      evidences,
    }
  };
}

function alterStatusEvidenceSuccess(): Action {
  return {
    type: ALTER_STATUS_EVIDENCE_SUCCESS
  };
}

function alterStatusEvidenceFailure(): Action {
  return {
    type: ALTER_STATUS_EVIDENCE_FAILURE,
  };
}

function getAllEvidencesRequest(
  risk_id: string,
  msg?: string,
): Action {
  return {
    type: GET_ALL_EVIDENCES_REQUEST,
    payload: {
      risk_id,
      msg
    },
  };
}

function getAllEvidencesSuccess(evidences: Evidences[]): Action {
  return {
    type: GET_ALL_EVIDENCES_SUCCESS,
    payload: {
      evidences,
    },
  };
}

function getAllEvidencesFailure(): Action {
  return {
    type: GET_ALL_EVIDENCES_FAILURE,
  };
}

function confirmClientDataRequest(customerProjectId: string): Action {
  return {
    type:CONFIRM_CLIENT_DATA_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function confirmClientDataSuccess(): Action {
  return {
    type: CONFIRM_CLIENT_DATA_SUCCESS,
  };
}

function confirmClientDataFailure(): Action {
  return {
    type: CONFIRM_CLIENT_DATA_FAILURE,
  };
}

export const customerProjectRiskActionsFunctions = {
  deleteRiskCustomerProjectEvidenceRequest,
  deleteRiskCustomerProjectEvidenceSuccess,
  deleteRiskCustomerProjectEvidenceFailure,
  getCustomerProjectRisksRequest,
  getCustomerProjectRisksSuccess,
  getCustomerProjectRisksFailure,
  generateCustomerProjectRisksRequest,
  generateCustomerProjectRisksSuccess,
  generateCustomerProjectRisksFailure,
  getCustomerProjectRisksDetailRequest,
  getCustomerProjectRisksDetailSuccess,
  getCustomerProjectRisksDetailFailure,
  editCustomerProjectRisksStatusRequest,
  editCustomerProjectRisksStatusSuccess,
  editCustomerProjectRisksStatusFailure,
  editCustomerProjectRisksQuestionInformationRequest,
  editCustomerProjectRisksQuestionInformationSuccess,
  editCustomerProjectRisksQuestionInformationFailure,
  editCustomerProjectRisksRegistrationsRequest,
  editCustomerProjectRisksRegistrationsSuccess,
  editCustomerProjectRisksRegistrationsFailure,
  editCustomerProjectRecommendationRequest,
  editCustomerProjectRecommendationSuccess,
  editCustomerProjectRecommendationFailure,
  getExportCustomerProjectRiskRequest,
  getExportCustomerProjectRiskSuccess,
  getExportCustomerProjectRiskFailure,
  getRiskCustomerProjectEvidenceDownloadRequest,
  getRiskCustomerProjectEvidenceDownloadSuccess,
  getRiskCustomerProjectEvidenceDownloadFailure,
  getCustomerProjectRiskMatrixRequest,
  getCustomerProjectRiskMatrixSuccess,
  getCustomerProjectRiskMatrixFailure,  
  renameEvidenceRequest,
  renameEvidenceSuccess,
  renameEvidenceFailure,
  alterPositionEvidenceRequest,
  alterPositionEvidenceSuccess,
  alterPositionEvidenceFailure,
  getAllEvidencesRequest,
  getAllEvidencesSuccess,
  getAllEvidencesFailure,
  alterStatusEvidenceRequest,
  alterStatusEvidenceSuccess,
  alterStatusEvidenceFailure,
  confirmClientDataRequest,
  confirmClientDataSuccess,
  confirmClientDataFailure
};
