import produce from 'immer';
import { Reducer } from 'redux';

import { CustomersActions, CustomersState } from './types';

const {
  GET_ALL_CUSTOMERS_REQUEST,
  GET_ALL_CUSTOMERS_SUCCESS,
  GET_ALL_CUSTOMERS_FAILURE,
  GET_CUSTOMERS_DETAIL_REQUEST,
  GET_CUSTOMERS_DETAIL_SUCCESS,
  GET_CUSTOMERS_DETAIL_FAILURE,
  GET_CEP_REQUEST,
  GET_CEP_REQUEST_SUCCESS,
  GET_CEP_REQUEST_FAILURE,
  EDIT_CUSTOMER_REQUEST,
  EDIT_CUSTOMER_SUCCESS,
  EDIT_CUSTOMER_FAILURE,
  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAILURE,
  GET_CLASSES_REQUEST,
  GET_CLASSES_SUCCESS,
  GET_CLASSES_FAILURE,
  EDIT_CUSTOMER_STATUS_REQUEST,
  EDIT_CUSTOMER_STATUS_SUCCESS,
  EDIT_CUSTOMER_STATUS_FAILURE,
  GET_FILTERED_CUSTOMERS_REQUEST,
  GET_FILTERED_CUSTOMERS_SUCCESS,
  GET_FILTERED_CUSTOMERS_FAILURE
} = CustomersActions;

const initialState: CustomersState = {
  customers: [],
  customersDetails:
  {
    id: '',
    segment: {
      id: '',
      subsector: '',
      name: '',
      is_active: false
    },
    segment_name: '',
    document_type: '',
    document_number: '',
    corporate_name_or_name: '',
    fantasy_name: '',
    phone: '',
    email: '',
    state_registration: '',
    municipal_registration: '',
    opting_for_the_simples: false,
    inclusion_date_rf: new Date(),
    status_rf: '',
    revenue: '',
    it_budget: '',
    class_enum: {
      id: 0,
      name: ''
    },
    is_active: false,
    address: {
      id: '',
      zip_code: '',
      address: '',
      complement: '',
      number_address: '',
      neighborhood: '',
      city: '',
      state: '',
      country: '',
    },
    interviewees: [],
    parent_company_subsidiary: '',
    sector: {
      id: '',
      name: '',
    },
    subsector: {
      id: '',
      name: '',
    },
  },
  address: {
    id: '',
    zip_code: '',
    address: '',
    complement: '',
    number_address: '',
    neighborhood: '',
    city: '',
    state: '',
    country: '',
  },

  classes: {
    classes: []
  },
  zipCode: {
    address:
    {
      bairro: '',
      cep: '',
      cidade: '',
      complemento: '',
      logradouro: '',
      uf: ''
    }
  }
};

export const customerReducer: Reducer<CustomersState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_CUSTOMERS_REQUEST: {
        break;
      }

      case GET_ALL_CUSTOMERS_SUCCESS: {
        const { customers } = action.payload;

        draft.customers = customers;

        break;
      }

      case GET_ALL_CUSTOMERS_FAILURE: {
        draft.customers = [];
        break;
      }

      case GET_FILTERED_CUSTOMERS_REQUEST: {
        break;
      }

      case GET_FILTERED_CUSTOMERS_SUCCESS: {
        const { customers } = action.payload;

        draft.customers = customers;

        break;
      }

      case GET_FILTERED_CUSTOMERS_FAILURE: {
        break;
      }


      case GET_CUSTOMERS_DETAIL_REQUEST: {
        break;
      }

      case GET_CLASSES_REQUEST: {
        break;
      }

      case GET_CLASSES_SUCCESS: {
        const { classes } = action.payload;

        draft.classes = classes;
        break;
      }

      case GET_CLASSES_FAILURE: {
        break;
      }

      case GET_CUSTOMERS_DETAIL_SUCCESS: {
        const { customer } = action.payload;

        draft.customersDetails = customer;

        break;
      }

      case GET_CUSTOMERS_DETAIL_FAILURE: {
        draft.customersDetails = initialState.customersDetails;
        break;
      }

      case GET_CEP_REQUEST: {
        break;
      }

      case GET_CEP_REQUEST_SUCCESS: {
        const { cepInfos } = action.payload;

        draft.zipCode = cepInfos;
        break;
      }

      case GET_CEP_REQUEST_FAILURE: {
        break;
      }

      case EDIT_CUSTOMER_REQUEST: {
        break;
      }

      case EDIT_CUSTOMER_SUCCESS: {
        break;
      }

      case EDIT_CUSTOMER_FAILURE: {
        break;
      }

      case EDIT_CUSTOMER_STATUS_REQUEST: {
        break;
      }

      case EDIT_CUSTOMER_STATUS_SUCCESS: {
        break;
      }

      case EDIT_CUSTOMER_STATUS_FAILURE: {
        break;
      }

      case CREATE_CUSTOMER_REQUEST: {
        break;
      }

      case CREATE_CUSTOMER_SUCCESS: {
        break;
      }

      case CREATE_CUSTOMER_FAILURE: {
        break;
      }


      default: {
        return draft;
      }
    }
  });
};
