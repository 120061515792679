import type { Action } from 'store/types';

import { AllInterwiies, InterwiiesActions, NewInterwiies } from './types';


const {
  GET_ALL_INTERWEES_REQUEST,
  GET_ALL_INTERWEES_SUCCESS,
  GET_ALL_INTERWEES_FAILURE,
  CREATE_NEW_INTERWIED_REQUEST,
  CREATE_NEW_INTERWIED_SUCCESS,
  CREATE_NEW_INTERWIED_FAILURE,
  UPDATE_STATUS_REQUEST,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_FAILURE,
  EDIT_INTERWIED_REQUEST,
  EDIT_INTERWIED_SUCCESS,
  EDIT_INTERWIED_FAILURE
} = InterwiiesActions;

function getInterwieesRequest(): Action {
  return {
    type: GET_ALL_INTERWEES_REQUEST,
  };
}

function getInterwieesSuccess(interwiies: AllInterwiies[]): Action {
  return {
    type: GET_ALL_INTERWEES_SUCCESS,
    payload: {
      interwiies
    }
  };
}

function getInterwieesFailure(): Action {
  return {
    type: GET_ALL_INTERWEES_FAILURE,
  };
}

function createNewInterwieesRequest(interwieesBodyData: NewInterwiies): Action {
  return {
    type: CREATE_NEW_INTERWIED_REQUEST,
    payload: {
      interwieesBodyData
    }
  };
}

function createNewInterwieesSuccess(): Action {
  return {
    type: CREATE_NEW_INTERWIED_SUCCESS,
  };
}

function createNewInterwieesFailure(): Action {
  return {
    type: CREATE_NEW_INTERWIED_FAILURE,
  };
}

function editInterweesRequest(interwiedId: string | undefined, interwied: NewInterwiies): Action {
  return {
    type: EDIT_INTERWIED_REQUEST,
    payload: {
      interwiedId,
      interwied
    }
  };
}

function editInterweesSuccess(): Action {
  return {
    type: EDIT_INTERWIED_SUCCESS,
  };
}

function editInterweesFailure(): Action {
  return {
    type: EDIT_INTERWIED_FAILURE,
  };
}

function updateInterwiiesStatusRequest(interwiedId: string, is_active: boolean): Action {
  return {
    type: UPDATE_STATUS_REQUEST,
    payload: {
      interwiedId,
      is_active
    }
  };
}

function updateInterwiiesStatusSuccess(): Action {
  return {
    type: UPDATE_STATUS_SUCCESS,
  };
}

function updateInterwiiesStatusFailure(): Action {
  return {
    type: UPDATE_STATUS_FAILURE,
  };
}


export const interwiiesActionsFunctions = {
  getInterwieesRequest,
  getInterwieesSuccess,
  getInterwieesFailure,
  createNewInterwieesRequest,
  createNewInterwieesSuccess,
  createNewInterwieesFailure,
  updateInterwiiesStatusRequest,
  updateInterwiiesStatusSuccess,
  updateInterwiiesStatusFailure,
  editInterweesRequest,
  editInterweesSuccess,
  editInterweesFailure
};
