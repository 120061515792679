import { combineReducers } from 'redux';

import { authReducer } from './auth/reducer';
import { customerSectorsReducer } from './customer/customerSector/reducer';
import { customerReducer } from './customer/reducer';
import { customerProjectReducer } from './customerProject/reducer';
import { controlRecommendationsReducer } from './customerProjectControl/ControlRecommendations/reducer';
import { customerProjectControlReducer } from './customerProjectControl/CustomerControls/reducer';
import { customerProjectNistReducer } from './customerProjectNist/reducer';
import { customerProjectReportReducer } from './customerProjectReport/reducer';
import { riskCustomerProjectReducer } from './customerProjectRisk/reducer';
import { interwiiesReducer } from './interwiees/reducer';
import { lastApiFetchDataReducer } from './lastApiFetchData/reducer';
import { activitiesReducer } from './Manutention/Activities/reducer';
import { applicationLogReducer } from './Manutention/ApplicationLogs/reducer';
import { checklistsReducer } from './Manutention/checklists/reducer';
import { controlReducer } from './Manutention/Control/reducer';
import { controlTypesReducer } from './Manutention/controlTypes/reducer';
import { crownJewelsReducer } from './Manutention/CrownJewels/reducer';
import { damagesReducer } from './Manutention/damages/reducer';
import { frameworksReducer } from './Manutention/Frameworks/reducer';
import { questionnairesReducer } from './Manutention/questionnaries/reducer';
import { questionsReducer } from './Manutention/questions/reducer';
import { riskFactorsReducer } from './Manutention/riskFactors/reducer';
import { riskReducer } from './Manutention/Risks/reducer';
import { sectorsReducer } from './Manutention/sectors/reducer';
import { solutionsReducer } from './Manutention/Solutions/reducer';
import { standardsReducer } from './Manutention/Standards/reducer';
import { vulnerabilitiesReducer } from './Manutention/vulnerabilities/reducer';
import { marketAverageReducer } from './marketAverage/reducer';
import { openedModalReducer } from './openedModal/reducer';
import { projectSettingsReducer } from './Project/ProjectSettings/reducer';
import { projectsForAnonimizationReducer } from './Project/ProjectsForAnonymization/reducer';
import { threatsReducer } from './threats/reducer';
import { userProfilesReducer } from './userProfile/reducer';
import { usersReducer } from './users/reducer';

export default combineReducers({
  auth: authReducer,
  lastApiFetchData: lastApiFetchDataReducer,
  openedModal: openedModalReducer,
  users: usersReducer,
  sectors: sectorsReducer,
  customers: customerReducer,
  threats: threatsReducer,
  interwiies: interwiiesReducer,
  checklists: checklistsReducer,
  vulnerabilities: vulnerabilitiesReducer,
  riskFactors: riskFactorsReducer,
  risk: riskReducer,
  damages: damagesReducer,
  controlTypes: controlTypesReducer,
  activities: activitiesReducer,
  questions: questionsReducer,
  crownJewels: crownJewelsReducer,
  control: controlReducer,
  customerProject: customerProjectReducer,
  project: projectSettingsReducer,
  questionnaires: questionnairesReducer,
  riskCustomerProject: riskCustomerProjectReducer,
  controlCustomerProject: customerProjectControlReducer,
  reportsCustomerProject: customerProjectReportReducer,
  controlRecommendations: controlRecommendationsReducer,
  customerProjectNist: customerProjectNistReducer,
  customerSectors: customerSectorsReducer,
  marketAverage: marketAverageReducer,
  frameworks: frameworksReducer,
  standards: standardsReducer,
  projectsForAnonimization: projectsForAnonimizationReducer,
  applicationLogs: applicationLogReducer,
  solutions: solutionsReducer,
  userProfiles: userProfilesReducer,
});
