import { createTheme } from '@mui/material';

export const baseTheme = createTheme({
  breakpoints: {
    values: {
      xl: 1920,
      lg: 1200,
      md: 900,
      sm: 425,
      xs: 280,
    },
  },
  palette: {
    primary: {
      main: '#3e4a6e',
      light: '#3e4a6e',
      dark: '#3e4a6e',
    },
    secondary: {
      main: '#fdba2b',
      light: '#fdba2b',
      dark: '#fdba2b',
    },
    grey: {
      '500': '#c4c4c4',
      '600': '#646464',
      '700': '#787878',
      '800': '#323232',
    },
  },
  typography: {
    htmlFontSize: 16,
  },
  components: {
    MuiTablePagination: {
      styleOverrides: {
        displayedRows: {
          color: '#3e4a6e',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '1.4rem',
          lineHeight: '1.6rem',
          textAlign: 'center',
        },
        actions: {
          '*': {
            color: '#3e4a6e',
          },
          svg: {
            width: 24,
            height: 24,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: 'var(--white-900)',
          backgroundColor: 'var(--primary-color)',

          fontSize: '1.4rem',
          lineHeight: '1.6rem',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--purple-300)',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '1.6rem',
          letterSpacing: 'normal',
        },
        input: {
          fontSize: '1.6rem',
          letterSpacing: 'normal',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '1.6rem',
          letterSpacing: 'normal',
        },
        input: {
          fontSize: '1.6rem',
          letterSpacing: 'normal',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.6rem',
          letterSpacing: 'normal',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: '1.6rem',
          letterSpacing: 'normal',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          minWidth: '15rem',
          maxWidth: '100%',
          fontSize: '1.6rem',
          letterSpacing: 'normal',
        },
        inputRoot: {
          fontSize: '1.6rem',
          letterSpacing: 'normal',
        },
        popper: {
          fontSize: '1.6rem',
          letterSpacing: 'normal',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
          fontWeight: 700,
          fontSize: '1.4rem',
          lineHeight: '1.6rem',
          borderRadius: '5px',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        text: {
          fontWeight: 900,
          fontSize: '1.2rem',
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          margin: '0 1.2rem',
          top: '2.2rem',
          color: 'var(--grey-700)',
          borderRadius: '2px',
          borderColor: 'var(--grey-700)',
        },
      },
    },
  },
});
