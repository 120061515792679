import type { Action } from 'store/types';

import {
  ObjectWithId,
  Threat,
  ThreatPostBody,
  ThreatPutBody,
  ThreatsActions,
  ChangeStatusBodyType,
  LinkedControlsType,
  SimpleThreat
} from './types';

const {
  GET_ALL_THREATS_MODULE_REQUEST,
  GET_ALL_THREATS_MODULE_SUCCESS,
  GET_ALL_THREATS_MODULE_FAILURE,
  GET_THREATS_DETAILS_REQUEST,
  GET_THREATS_DETAILS_SUCCESS,
  GET_THREATS_DETAILS_FAILURE,
  CREATE_NEW_THREAT_REQUEST,
  CREATE_NEW_THREAT_SUCCESS,
  CREATE_NEW_THREAT_FAILURE,
  EDIT_THREAT_REQUEST,
  EDIT_THREAT_SUCCESS,
  EDIT_THREAT_FAILURE,
  ACTIVE_OR_INACTIVE_THREAT_REQUEST,
  ACTIVE_OR_INACTIVE_THREAT_SUCCESS,
  ACTIVE_OR_INACTIVE_THREAT_FAILURE,
  GET_SEARCH_FILTER_THREAT_REQUEST,
  GET_SEARCH_FILTER_THREAT_SUCCESS,
  GET_SEARCH_FILTER_THREAT_FAILURE,
  UPDATE_RISK_REQUEST,
  UPDATE_RISK_SUCCESS,
  UPDATE_RISK_FAILURE,
  EDIT_THREAT_CUSTOMER_PROJECT_STATUS_REQUEST,
  EDIT_THREAT_CUSTOMER_PROJECT_STATUS_SUCCESS,
  EDIT_THREAT_CUSTOMER_PROJECT_STATUS_FAILURE,
  GET_LINKED_CONTROLS_REQUEST,
  GET_LINKED_CONTROLS_SUCCESS,
  GET_LINKED_CONTROLS_FAILURE,
  GET_ALL_SIMPLE_THREATS_MODULE_REQUEST,
  GET_ALL_SIMPLE_THREATS_MODULE_SUCCESS,
  GET_ALL_SIMPLE_THREATS_MODULE_FAILURE,
  GET_SEARCH_FILTER_SIMPLE_THREAT_REQUEST,
  GET_SEARCH_FILTER_SIMPLE_THREAT_SUCCESS,
  GET_SEARCH_FILTER_SIMPLE_THREAT_FAILURE,
} = ThreatsActions;

function getAllThreatsRequest(last_page?: number): Action {
  return {
    type: GET_ALL_THREATS_MODULE_REQUEST,
    payload: {
      last_page
    },
  };
}

function getAllThreatsSuccess(allThreat: Threat[], total: number, last_page: number, has_next: boolean): Action {
  return {
    type: GET_ALL_THREATS_MODULE_SUCCESS,
    payload: {
      allThreat,
      total,
      last_page,
      has_next
    },
  };
}

function getAllThreatsFailure(): Action {
  return {
    type: GET_ALL_THREATS_MODULE_FAILURE,
  };
}

function getAllLinkedControlsRequest(threatId: string): Action {
  return {
    type: GET_LINKED_CONTROLS_REQUEST,
    payload: {
      threatId
    }
  };
}

function getAllLinkedControlsSuccess(linkedControls: LinkedControlsType): Action {
  return {
    type: GET_LINKED_CONTROLS_SUCCESS,
    payload: {
      linkedControls,
    },
  };
}

function getAllLinkedControlsFailure(): Action {
  return {
    type: GET_LINKED_CONTROLS_FAILURE,
  };
}

function updateRiskRequest(threatId: string, riskUpdateAdd: ObjectWithId[], riskUpdateRemove: ObjectWithId[]): Action {
  return {
    type: UPDATE_RISK_REQUEST,
    payload: {
      threatId,
      riskUpdateAdd,
      riskUpdateRemove
    }
  };
}

function updateRiskSuccess(): Action {
  return {
    type: UPDATE_RISK_SUCCESS,
  };
}

function updateRiskFailure(): Action {
  return {
    type: UPDATE_RISK_FAILURE,
  };
}

function getSearchFilterThreatsRequest(
  filteredThreats: Array<string | boolean | null>,
  isActive: boolean | null
): Action {
  return {
    type: GET_SEARCH_FILTER_THREAT_REQUEST,
    payload: {
      filteredThreats,
      isActive
    },
  };
}

function getSearchFilterThreatsSuccess(threat: Threat[]): Action {
  return {
    type: GET_SEARCH_FILTER_THREAT_SUCCESS,
    payload: {
      threat,
    },
  };
}

function getSearchFilterThreatsFailure(): Action {
  return {
    type: GET_SEARCH_FILTER_THREAT_FAILURE,
  };
}

function getThreatDetailRequest(threatId: string): Action {
  return {
    type: GET_THREATS_DETAILS_REQUEST,
    payload: {
      threatId
    }
  };
}

function getThreatDetailSuccess(threatDetail: Threat): Action {
  return {
    type: GET_THREATS_DETAILS_SUCCESS,
    payload: {
      threatDetail
    },
  };
}

function getThreatDetailFailure(): Action {
  return {
    type: GET_THREATS_DETAILS_FAILURE,
  };
}

function createNewThreatRequest(threatPostBody: ThreatPostBody): Action {
  return {
    type: CREATE_NEW_THREAT_REQUEST,
    payload: {
      threatPostBody
    }
  };
}

function createNewThreatSuccess(): Action {
  return {
    type: CREATE_NEW_THREAT_SUCCESS,
  };
}

function createNewThreatFailure(): Action {
  return {
    type: CREATE_NEW_THREAT_FAILURE,
  };
}

function editThreatRequest(threatId: string, threatPostBody: ThreatPutBody): Action {
  return {
    type: EDIT_THREAT_REQUEST,
    payload: {
      threatId,
      threatPostBody
    }
  };
}

function editThreatSuccess(): Action {
  return {
    type: EDIT_THREAT_SUCCESS,
  };
}

function editThreatFailure(): Action {
  return {
    type: EDIT_THREAT_FAILURE,
  };
}

function activeOrInactiveThreatRequest(
  threatId: string,
  newThreatStatus: boolean
): Action {
  return {
    type: ACTIVE_OR_INACTIVE_THREAT_REQUEST,
    payload: {
      threatId,
      newThreatStatus,
    },
  };
}

function activeOrInactiveThreatSuccess(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_THREAT_SUCCESS,
  };
}

function activeOrInactiveThreatFailure(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_THREAT_FAILURE,
  };
}

// function saveTableData(tableDataIds: string[]) {
//   return {
//     type: SAVE_TABLE_DATA,
//     payload: {
//       tableDataIds
//     },
//   };
// }

// function saveTableDataSuccess(): Action {
//   return {
//     type: ACTIVE_OR_INACTIVE_THREAT_SUCCESS,
//   };
// }

// function saveTableDataInLocalStorageFailure(): Action {
//   return {
//     type: ACTIVE_OR_INACTIVE_THREAT_FAILURE,
//   };
// }

function editCustomerProjectThreatsStatusRequest(
  threatId: string,
  changeStatusBody: ChangeStatusBodyType,
  customerProjectId: string
): Action {
  return {
    type: EDIT_THREAT_CUSTOMER_PROJECT_STATUS_REQUEST,
    payload: {
      threatId,
      changeStatusBody,
      customerProjectId
    }
  };
}

function editCustomerProjectThreatsStatusSuccess(): Action {
  return {
    type: EDIT_THREAT_CUSTOMER_PROJECT_STATUS_SUCCESS,
  };
}

function editCustomerProjectThreatsStatusFailure(): Action {
  return {
    type: EDIT_THREAT_CUSTOMER_PROJECT_STATUS_FAILURE,
  };
}

function getAllSimpleThreatsRequest(
  last_page?: number
): Action {
  return {
    type: GET_ALL_SIMPLE_THREATS_MODULE_REQUEST,
    payload: {
      last_page
    },
  };
}

function getAllSimpleThreatsSuccess(
  simpleThreat: SimpleThreat[],
  total: number,
  last_page: number,
  has_next: boolean
): Action {
  return {
    type: GET_ALL_SIMPLE_THREATS_MODULE_SUCCESS,
    payload: {
      simpleThreat,
      total,
      last_page,
      has_next
    },
  };
}

function getAllSimpleThreatsFailure(): Action {
  return {
    type: GET_ALL_SIMPLE_THREATS_MODULE_FAILURE,
  };
}

function getSearchFilterSimpleThreatsRequest(
  filteredThreats: Array<string | boolean | null>,
  isActive: boolean | null
): Action {
  return {
    type: GET_SEARCH_FILTER_SIMPLE_THREAT_REQUEST,
    payload: {
      filteredThreats,
      isActive
    },
  };
}

function getSearchFilterSimpleThreatsSuccess(
  simpleThreat: SimpleThreat[]
): Action {
  return {
    type: GET_SEARCH_FILTER_SIMPLE_THREAT_SUCCESS,
    payload: {
      simpleThreat,
    },
  };
}

function getSearchFilterSimpleThreatsFailure(): Action {
  return {
    type: GET_SEARCH_FILTER_SIMPLE_THREAT_FAILURE,
  };
}

export const threatsActionsFunctions = {
  getAllThreatsRequest,
  getAllThreatsSuccess,
  getAllThreatsFailure,
  getThreatDetailRequest,
  getThreatDetailSuccess,
  getThreatDetailFailure,
  createNewThreatRequest,
  createNewThreatSuccess,
  createNewThreatFailure,
  editThreatRequest,
  editThreatSuccess,
  editThreatFailure,
  activeOrInactiveThreatRequest,
  activeOrInactiveThreatSuccess,
  activeOrInactiveThreatFailure,
  getSearchFilterThreatsRequest,
  getSearchFilterThreatsSuccess,
  getSearchFilterThreatsFailure,
  updateRiskRequest,
  updateRiskSuccess,
  updateRiskFailure,
  editCustomerProjectThreatsStatusRequest,
  editCustomerProjectThreatsStatusSuccess,
  editCustomerProjectThreatsStatusFailure,
  getAllLinkedControlsRequest,
  getAllLinkedControlsSuccess,
  getAllLinkedControlsFailure,
  getAllSimpleThreatsRequest,
  getAllSimpleThreatsSuccess,
  getAllSimpleThreatsFailure,
  getSearchFilterSimpleThreatsRequest,
  getSearchFilterSimpleThreatsSuccess,
  getSearchFilterSimpleThreatsFailure,
};
