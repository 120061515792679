import produce from 'immer';
import { Reducer } from 'redux';

import type { QuestionsState } from './types';
import { QuestionsActions } from './types';

const {
  ACTIVE_OR_INACTIVE_QUESTION_FAILURE,
  ACTIVE_OR_INACTIVE_QUESTION_SUCCESS,
  ACTIVE_OR_INACTIVE_QUESTION_REQUEST,
  ADD_NEW_QUESTION_FAILURE,
  ADD_NEW_QUESTION_REQUEST,
  ADD_NEW_QUESTION_SUCCESS,
  EDIT_QUESTION_FAILURE,
  EDIT_QUESTION_REQUEST,
  EDIT_QUESTION_SUCCESS,
  GET_ALL_QUESTIONS_MODULE_FAILURE,
  GET_ALL_QUESTIONS_MODULE_REQUEST,
  GET_ALL_QUESTIONS_MODULE_SUCCESS,
  GET_QUESTION_DETAIL_FAILURE,
  GET_QUESTION_DETAIL_REQUEST,
  GET_QUESTION_DETAIL_SUCCESS,
  GET_FILTERED_QUESTION_FAILURE,
  GET_FILTERED_QUESTION_REQUEST,
  GET_FILTERED_QUESTION_SUCCESS,
  RESET_THE_QUESTION_DETAIL_STATE,
  GET_ALL_QUESTIONS_DEPARTMENTS_REQUEST,
  GET_ALL_QUESTIONS_DEPARTMENTS_SUCCESS,
  GET_ALL_QUESTIONS_DEPARTMENTS_FAILURE,
  GET_ALL_QUESTIONS_CONTROLS_FAILURE,
  GET_ALL_QUESTIONS_CONTROLS_REQUEST,
  GET_ALL_QUESTIONS_CONTROLS_SUCCESS,
  GET_ALL_RISKFACTORS_FAILURE,
  GET_ALL_RISKFACTORS_REQUEST,
  GET_ALL_RISKFACTORS_SUCCESS,
  GET_ALL_QUESTIONS_VULNERABILITIES_FAILURE,
  GET_ALL_QUESTIONS_VULNERABILITIES_REQUEST,
  GET_ALL_QUESTIONS_VULNERABILITIES_SUCCESS,
  GET_FILTERED_CONTROL_FAILURE,
  GET_FILTERED_CONTROL_REQUEST,
  GET_FILTERED_CONTROL_SUCCESS,
  GET_FILTERED_RISKFACTOR_FAILURE,
  GET_FILTERED_RISKFACTOR_REQUEST,
  GET_FILTERED_RISKFACTOR_SUCCESS,
  GET_FILTERED_VULNERABILITY_FAILURE,
  GET_FILTERED_VULNERABILITY_REQUEST,
  GET_FILTERED_VULNERABILITY_SUCCESS
} = QuestionsActions;

const initialState: QuestionsState = {
  questions: [],
  questionsList: [],
  visited_pages: [],
  has_next: true,
  total: 0,
  loading: true,
  currentQuestionDetail: {
    id: '',
    name: '',
    question_type: '',
    has_evidence: false,
    is_active: false,
    vulnerabilities: [],
    risk_factors: [],
    controls: [],
    departments: [],
    alternatives: [],
    example: '',
    columns: [],
  },
  departments: [],
  vulnerabilities: [],
  controls: [],
  risk_factors: [],
};

export const questionsReducer: Reducer<QuestionsState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_QUESTIONS_MODULE_REQUEST: {
        draft.loading =  true;
        break;
      }

      case GET_ALL_QUESTIONS_MODULE_SUCCESS: {
        const { questions, total, last_page, has_next } = action.payload;
        const pagineAlreadyVisited = state.visited_pages.find (page => page === last_page);
        draft.loading =  false;

        if(!pagineAlreadyVisited || draft.questions || draft.questionsList) {
          draft.visited_pages.push(last_page);
          draft.questions = last_page === 1 ? questions : [...state.questions, ...questions];
          draft.questionsList = last_page === 1 ? questions : [...state.questionsList, ...questions];
          draft.total = total;
          draft.has_next = has_next;
        }

        break;
      }

      case GET_ALL_QUESTIONS_MODULE_FAILURE: {
        draft.questions = [];
        draft.questionsList = [];
        draft.loading =  false;

        break;
      }

      case GET_FILTERED_QUESTION_REQUEST: {
        draft.loading =  true;
        break;
      }

      case GET_FILTERED_QUESTION_SUCCESS: {
        const { questions, total } = action.payload;
        draft.loading =  false;
        draft.questions = questions;
        draft.questionsList = questions;
        draft.total = total;

        break;
      }

      case GET_FILTERED_QUESTION_FAILURE: {
        draft.loading =  true;
        break;
      }

      case GET_FILTERED_VULNERABILITY_REQUEST: {
        break;
      }

      case GET_FILTERED_VULNERABILITY_SUCCESS: {
        const { vulnerabilities } = action.payload;

        draft.vulnerabilities = vulnerabilities;

        break;
      }

      case GET_FILTERED_VULNERABILITY_FAILURE: {
        break;
      }

      case GET_FILTERED_RISKFACTOR_REQUEST: {
        break;
      }

      case GET_FILTERED_RISKFACTOR_SUCCESS: {
        const { riskFactors } = action.payload;

        draft.risk_factors = riskFactors;

        break;
      }

      case GET_FILTERED_RISKFACTOR_FAILURE: {
        break;
      }

      case GET_FILTERED_CONTROL_REQUEST: {
        break;
      }

      case GET_FILTERED_CONTROL_SUCCESS: {
        const { controls } = action.payload;

        draft.controls = controls;

        break;
      }

      case GET_FILTERED_CONTROL_FAILURE: {
        break;
      }

      case GET_QUESTION_DETAIL_REQUEST: {
        break;
      }

      case GET_QUESTION_DETAIL_SUCCESS: {
        const { question } = action.payload;

        draft.currentQuestionDetail = question;

        break;
      }

      case GET_QUESTION_DETAIL_FAILURE: {
        break;
      }

      case GET_ALL_QUESTIONS_DEPARTMENTS_REQUEST: {
        break;
      }

      case GET_ALL_QUESTIONS_DEPARTMENTS_SUCCESS: {
        const { departments } = action.payload;

        draft.departments = departments;

        break;
      }

      case GET_ALL_QUESTIONS_DEPARTMENTS_FAILURE: {
        draft.departments = [];

        break;
      }

      case GET_ALL_RISKFACTORS_REQUEST: {
        break;
      }

      case GET_ALL_RISKFACTORS_SUCCESS: {
        const { riskFactors } = action.payload;

        draft.risk_factors = riskFactors;

        break;
      }

      case GET_ALL_RISKFACTORS_FAILURE: {
        draft.risk_factors = [];

        break;
      }

      case GET_ALL_QUESTIONS_VULNERABILITIES_REQUEST: {
        break;
      }

      case GET_ALL_QUESTIONS_VULNERABILITIES_SUCCESS: {
        const { vulnerabilities } = action.payload;

        draft.vulnerabilities = vulnerabilities;

        break;
      }

      case GET_ALL_QUESTIONS_VULNERABILITIES_FAILURE: {
        draft.vulnerabilities = [];

        break;
      }

      case GET_ALL_QUESTIONS_CONTROLS_REQUEST: {
        break;
      }

      case GET_ALL_QUESTIONS_CONTROLS_SUCCESS: {
        const { controls } = action.payload;

        draft.controls = controls;

        break;
      }

      case GET_ALL_QUESTIONS_CONTROLS_FAILURE: {
        draft.controls = [];

        break;
      }

      case ADD_NEW_QUESTION_REQUEST: {
        break;
      }

      case ADD_NEW_QUESTION_SUCCESS: {
        const { QuestionAdded } = action.payload;

        draft.currentQuestionDetail = QuestionAdded;

        break;
      }

      case ADD_NEW_QUESTION_FAILURE: {
        break;
      }

      case EDIT_QUESTION_REQUEST: {
        break;
      }

      case EDIT_QUESTION_SUCCESS: {
        break;
      }

      case EDIT_QUESTION_FAILURE: {
        break;
      }

      case ACTIVE_OR_INACTIVE_QUESTION_REQUEST: {
        break;
      }

      case ACTIVE_OR_INACTIVE_QUESTION_SUCCESS: {
        break;
      }

      case ACTIVE_OR_INACTIVE_QUESTION_FAILURE: {
        break;
      }

      case RESET_THE_QUESTION_DETAIL_STATE: {
        draft.currentQuestionDetail.id = '';
        draft.currentQuestionDetail.name = '';
        draft.currentQuestionDetail.question_type = '';
        draft.currentQuestionDetail.has_evidence = false;
        draft.currentQuestionDetail.vulnerabilities = [];
        draft.currentQuestionDetail.risk_factors = [];
        draft.currentQuestionDetail.controls = [];
        draft.currentQuestionDetail.departments = [];
        draft.currentQuestionDetail.alternatives = [];

        break;
      }

      default: {
        return draft;
      }
    }
  });
};
