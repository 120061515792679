import { toastDefaultProps } from 'constants/toast';
import { useEffect, useState, useRef, ReactNode } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import 'react-toastify/dist/ReactToastify.css';

interface CustomSwitchProps {
  readonly children?: ReactNode;
}

export function CustomSwitch({ children }: CustomSwitchProps) {
  const location = useLocation();
  const progressBar = useRef<LoadingBarRef>(null);

  const progressBarStartingValue = 0;
  const progressBarRefreshRate = 1000;

  const [prevLocation, setPrevLocation] = useState<string>('');

  useEffect(() => {
    setPrevLocation(location?.pathname);
    progressBar?.current?.continuousStart(
      progressBarStartingValue,
      progressBarRefreshRate
    );

    if (location?.pathname === prevLocation) {
      setPrevLocation('');
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimeout(() => progressBar?.current?.complete(), 500);
  }, [prevLocation]);

  return (
    <>
      <LoadingBar ref={progressBar} color='var(--red-700)' />

      <ToastContainer {...toastDefaultProps} />

      <Switch>{children}</Switch>
    </>
  );
}
