export enum LastApiFetchDataStateActions {
  SET_LOADING_STATUS_FOR_THE_LAST_API_FETCH_DATA = 'SET_LOADING_STATUS_FOR_THE_LAST_API_FETCH_DATA',
  LAST_API_FETCH_DATA_SUCCESS = 'LAST_API_FETCH_DATA_SUCCESS',
  LAST_API_FETCH_DATA_FAILURE = 'LAST_API_FETCH_DATA_FAILURE',
  RESET_THE_LAST_API_FETCH_DATA_STATUS = 'RESET_THE_LAST_API_FETCH_DATA_STATUS',
}
export type ApiFetchStatusResponse = {
  url: string | undefined;
  message?: string;
  status: number;
  statusText: string;
};

export type ApiFetchState = {
  url: string;
  message: string;
  status: number | null;
  statusText: string;
};

export type LastApiFetchDataState = {
  status: 'idle' | 'loading' | 'success' | 'error';
  error: ApiFetchState;
  success: ApiFetchState;
};
