/* eslint-disable consistent-return */
import produce from 'immer';
import { Reducer } from 'redux';

import { 
  CustomerProjectReportActions, 
  CustomerProjectReportState 
} from './types';

const {
  GET_ALL_REPORTS_CUSTOMER_PROJECT_REQUEST,
  GET_ALL_REPORTS_CUSTOMER_PROJECT_SUCCESS,
  GET_ALL_REPORTS_CUSTOMER_PROJECT_FAILURE,
  GET_ALL_REPORTS_REQUEST,
  GET_ALL_REPORTS_SUCCESS,
  GET_ALL_REPORTS_FAILURE,
} = CustomerProjectReportActions;

const initialState: CustomerProjectReportState = {
  allCustomerProjectReports: '',
  allProjectReports: [],
  visited_pages: [],
  total: 0,
  has_next: true,
};

export const customerProjectReportReducer: Reducer<CustomerProjectReportState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_REPORTS_CUSTOMER_PROJECT_REQUEST: {
        break;
      }

      case GET_ALL_REPORTS_CUSTOMER_PROJECT_SUCCESS: {
        const { allReports } = action.payload;

        draft.allCustomerProjectReports = allReports;
        break;
      }

      case GET_ALL_REPORTS_CUSTOMER_PROJECT_FAILURE: {
        draft.allCustomerProjectReports = [];
        break;
      }

      case GET_ALL_REPORTS_REQUEST:
        break;

      case GET_ALL_REPORTS_SUCCESS: {
        const { reports, total, last_page, has_next } = action.payload;
        const pagineAlreadyVisited = state.visited_pages.find (page => page === last_page);

        if(!pagineAlreadyVisited || draft.allProjectReports) {
          draft.visited_pages.push(last_page);
          draft.allProjectReports = last_page === 1 ? reports : [...state.allProjectReports, ...reports];
          draft.total = total;
          draft.has_next = has_next;
        }
        break;
      }

      case GET_ALL_REPORTS_FAILURE: {
        draft.allProjectReports = [];
        break;
      }

      default: {
        return draft;
      }
    }
  });
};


