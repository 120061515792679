import type { Action } from 'store/types';

import {
  AllSolutions,
  ChangeRankingControlSolutionPayload,
  EditSolutionPayload,
  JustId,
  SolutionControlsDetail,
  SolutionControlsPayload,
  SolutionCsfCoreProject,
  SolutionCsfCoreProjectPayload,
  SolutionDetails,
  SolutionIdPayload,
  SolutionPayload,
  SolutionProjectIdPayload,
  SolutionProjectStatusPayload,
  SolutionsActions,
  SolutionsProjectDetail,
  UpdateStatusControlSolutionPayload,
  UpdateStatusSolutionPayload,
} from './types';

const {
  EDIT_SOLUTION_REQUEST,
  EDIT_SOLUTION_SUCCESS,
  EDIT_SOLUTION_FAILURE,
  ADD_NEW_SOLUTION_REQUEST,
  ADD_NEW_SOLUTION_SUCCESS,
  ADD_NEW_SOLUTION_FAILURE,
  GET_ALL_SOLUTIONS_REQUEST,
  GET_ALL_SOLUTIONS_SUCCESS,
  GET_ALL_SOLUTIONS_FAILURE,
  GET_SOLUCTION_DETAIL_REQUEST,
  GET_SOLUCTION_DETAIL_SUCCESS,
  GET_SOLUCTION_DETAIL_FAILURE,
  EDIT_SOLUTION_CONTROL_REQUEST,
  EDIT_SOLUTION_CONTROL_SUCCESS,
  EDIT_SOLUTION_CONTROL_FAILURE,
  GET_SOLUTIONS_PROJECT_REQUEST,
  GET_SOLUTIONS_PROJECT_SUCCESS,
  GET_SOLUTIONS_PROJECT_FAILURE,
  LINK_CSFCORE_SOLUTION_REQUEST,
  LINK_CSFCORE_SOLUTION_SUCCESS,
  LINK_CSFCORE_SOLUTION_FAILURE,
  GET_FILTERED_SOLUTIONS_REQUEST,
  GET_FILTERED_SOLUTIONS_SUCCESS,
  GET_FILTERED_SOLUTIONS_FAILURE,
  LINK_OR_UNLINK_CONTROLS_REQUEST,
  LINK_OR_UNLINK_CONTROLS_SUCCESS,
  LINK_OR_UNLINK_CONTROLS_FAILURE,
  UPDATE_CSFCORE_SOLUTION_REQUEST,
  UPDATE_CSFCORE_SOLUTION_SUCCESS,
  UPDATE_CSFCORE_SOLUTION_FAILURE,
  EDIT_SOLUTION_INFORMATION_REQUEST,
  EDIT_SOLUTION_INFORMATION_SUCCESS,
  EDIT_SOLUTION_INFORMATION_FAILURE,
  ACTIVE_OR_INACTIVE_SOLUCTIONS_REQUEST,
  ACTIVE_OR_INACTIVE_SOLUCTIONS_SUCCESS,
  ACTIVE_OR_INACTIVE_SOLUCTIONS_FAILURE,
  GET_SOLUTIONS_CSFCORE_PROJECT_REQUEST,
  GET_SOLUTIONS_CSFCORE_PROJECT_SUCCESS,
  GET_SOLUTIONS_CSFCORE_PROJECT_FAILURE,
  EDIT_CONTROLS_SOLUTION_RANKING_REQUEST,
  EDIT_CONTROLS_SOLUTION_RANKING_SUCCESS,
  EDIT_CONTROLS_SOLUTION_RANKING_FAILURE,  
  ADD_AND_REMOVE_SOLUTION_CONTROLS_REQUEST,
  ADD_AND_REMOVE_SOLUTION_CONTROLS_SUCCESS,
  ADD_AND_REMOVE_SOLUTION_CONTROLS_FAILURE,
  GET_CONTROL_SOLUTIONS_PROJECT_DETAIL_REQUEST,
  GET_CONTROL_SOLUTIONS_PROJECT_DETAIL_SUCCESS,
  GET_CONTROL_SOLUTIONS_PROJECT_DETAIL_FAILURE,
  ACTIVE_OR_INACTIVE_CONTROL_SOLUCTION_REQUEST,
  ACTIVE_OR_INACTIVE_CONTROL_SOLUCTION_SUCCESS,
  ACTIVE_OR_INACTIVE_CONTROL_SOLUCTION_FAILURE,
  EDIT_SOLUTION_CUSTOMER_PROJECT_STATUS_REQUEST,
  EDIT_SOLUTION_CUSTOMER_PROJECT_STATUS_SUCCESS,
  EDIT_SOLUTION_CUSTOMER_PROJECT_STATUS_FAILURE,
  GENERATE_CONTROL_SOLUTION_CUSTOMER_PROJECT_REQUEST,
  GENERATE_CONTROL_SOLUTION_CUSTOMER_PROJECT_SUCCESS,
  GENERATE_CONTROL_SOLUTION_CUSTOMER_PROJECT_FAILURE,  
} = SolutionsActions;

function getSolutionsRequest(): Action {
  return {
    type: GET_ALL_SOLUTIONS_REQUEST,
  };
}

function getSolutionsSuccess(solutions: AllSolutions): Action {
  return {
    type: GET_ALL_SOLUTIONS_SUCCESS,
    payload: {
      solutions,
    },
  };
}

function getSolutionsFailure(): Action {
  return {
    type: GET_ALL_SOLUTIONS_FAILURE,
  };
}

function addNewSolutionRequest({
  name,
}: SolutionPayload): Action {
  return {
    type: ADD_NEW_SOLUTION_REQUEST,
    payload: {
      name,
    },
  };
}

function addNewSolutionSuccess(): Action {
  return {
    type: ADD_NEW_SOLUTION_SUCCESS,
  };
}

function addNewSolutionFailure(): Action {
  return {
    type: ADD_NEW_SOLUTION_FAILURE,
  };
}

function editSolutionRequest(
  {
    id,
    name,
  }: EditSolutionPayload): Action {
  return {
    type: EDIT_SOLUTION_REQUEST,
    payload: {
      id,
      name,
    },
  };
}

function editSolutionSuccess(): Action {
  return {
    type: EDIT_SOLUTION_SUCCESS,
  };
}

function editSolutionFailure(): Action {
  return {
    type: EDIT_SOLUTION_FAILURE,
  };
}

function activeOrInactiveSoluctionRequest({
  id,
  is_active
}: UpdateStatusSolutionPayload): Action {
  return {
    type: ACTIVE_OR_INACTIVE_SOLUCTIONS_REQUEST,
    payload: {
      id,
      is_active,
    },
  };
}

function activeOrInactiveSoluctionSuccess(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_SOLUCTIONS_SUCCESS,
  };
}

function activeOrInactiveSoluctionFailure(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_SOLUCTIONS_FAILURE,
  };
}

function getSoluctionDetailRequest({ id }: SolutionIdPayload): Action {
  return {
    type: GET_SOLUCTION_DETAIL_REQUEST,
    payload: {
      id,
    },
  };
}

function getSoluctionDetailSuccess(solutionDetails: SolutionDetails): Action {
  return {
    type: GET_SOLUCTION_DETAIL_SUCCESS,
    payload: {
      solutionDetails,
    },
  };
}

function getSoluctionDetailFailure(): Action {
  return {
    type: GET_SOLUCTION_DETAIL_FAILURE,
  };
}

function addAndRemoveSolutionControlsRequest(
  {
    id,
    controlsAdd,
    controlsRemove,
  }: SolutionControlsPayload): Action {
  return {
    type: ADD_AND_REMOVE_SOLUTION_CONTROLS_REQUEST,
    payload: {
      id,
      controlsAdd,
      controlsRemove,
    },
  };
}

function addAndRemoveSolutionControlsSuccess(): Action {
  return {
    type: ADD_AND_REMOVE_SOLUTION_CONTROLS_SUCCESS,
  };
}

function addAndRemoveSolutionControlsFailure(): Action {
  return {
    type: ADD_AND_REMOVE_SOLUTION_CONTROLS_FAILURE,
  };
}

function getSoluctionProjectDetailRequest({ id }: SolutionProjectIdPayload): Action {
  return {
    type: GET_SOLUTIONS_PROJECT_REQUEST,
    payload: {
      id,
    },
  };
}

function getSoluctionProjectDetailSuccess(solutionsProjectDetail: SolutionsProjectDetail): Action {
  return {
    type: GET_SOLUTIONS_PROJECT_SUCCESS,
    payload: {
      solutionsProjectDetail,
    },
  };
}

function getSoluctionProjectDetailFailure(): Action {
  return {
    type: GET_SOLUTIONS_PROJECT_FAILURE,
  };
}

function generateControlSolutionCustomerProjectRequest(id: string): Action {
  return {
    type: GENERATE_CONTROL_SOLUTION_CUSTOMER_PROJECT_REQUEST,
    payload: {
      id,
    }
  };
}

function generateControlSolutionCustomerProjectSuccess(): Action {
  return {
    type: GENERATE_CONTROL_SOLUTION_CUSTOMER_PROJECT_SUCCESS,
  };
}

function generateControlSolutionCustomerProjectFailure(): Action {
  return {
    type: GENERATE_CONTROL_SOLUTION_CUSTOMER_PROJECT_FAILURE,
  };
}

function editSolutionProjectStatusRequest({
  id,
  justification_of_inactivation,
  is_active,
  customerProjectId,
}: SolutionProjectStatusPayload): Action {
  return {
    type: EDIT_SOLUTION_CUSTOMER_PROJECT_STATUS_REQUEST,
    payload: {
      id,
      justification_of_inactivation,
      is_active,
      customerProjectId,
    }
  };
}

function editSolutionProjectStatusSuccess(): Action {
  return {
    type: EDIT_SOLUTION_CUSTOMER_PROJECT_STATUS_SUCCESS,
  };
}

function editSolutionProjectStatusFailure(): Action {
  return {
    type: EDIT_SOLUTION_CUSTOMER_PROJECT_STATUS_FAILURE,
  };
}

function getControlSolutionProjectDetailRequest(id: string): Action {
  return {
    type: GET_CONTROL_SOLUTIONS_PROJECT_DETAIL_REQUEST,
    payload: {
      id,
    }
  };
}

function getControlSolutionProjectDetailSuccess(solutionControlsDetail: SolutionControlsDetail): Action {
  return {
    type: GET_CONTROL_SOLUTIONS_PROJECT_DETAIL_SUCCESS,
    payload: {
      solutionControlsDetail
    }
  };
}

function getControlSolutionProjectDetailFailure(): Action {
  return {
    type: GET_CONTROL_SOLUTIONS_PROJECT_DETAIL_FAILURE,
  };
}

function linkOrUnlinkControlsRequest(
  {
    id,
    controlsAdd,
    controlsRemove,
  }: SolutionControlsPayload): Action {
  return {
    type: LINK_OR_UNLINK_CONTROLS_REQUEST,
    payload: {
      id,
      controlsAdd,
      controlsRemove,
    },
  };
}

function linkOrUnlinkControlsSuccess(): Action {
  return {
    type: LINK_OR_UNLINK_CONTROLS_SUCCESS,
  };
}

function linkOrUnlinkControlsFailure(): Action {
  return {
    type: LINK_OR_UNLINK_CONTROLS_FAILURE,
  };
}

function activeOrInactiveControlSolutionRequest({
  controlId,
  is_active,
  solutionId,
}: UpdateStatusControlSolutionPayload): Action {
  return {
    type: ACTIVE_OR_INACTIVE_CONTROL_SOLUCTION_REQUEST,
    payload: {
      controlId,
      is_active,
      solutionId,
    },
  };
}

function activeOrInactiveControlSolutionSuccess(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_CONTROL_SOLUCTION_SUCCESS,
  };
}

function activeOrInactiveControlSolutionFailure(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_CONTROL_SOLUCTION_FAILURE,
  };
}

function editControlsSolutionRankingRequest({
  id,
  position,
  customerProjectId,
}: ChangeRankingControlSolutionPayload): Action {
  return {
    type: EDIT_CONTROLS_SOLUTION_RANKING_REQUEST,
    payload: {
      id,
      position,
      customerProjectId,
    }
  };
}

function editControlsSolutionRankingSuccess(): Action {
  return {
    type: EDIT_CONTROLS_SOLUTION_RANKING_SUCCESS,
  };
}

function editControlsSolutionRankingFailure(): Action {
  return {
    type: EDIT_CONTROLS_SOLUTION_RANKING_FAILURE,
  };
}

function editSolutionControlRequest(
  solution_control_risk_id: string,
  unit_of_measure: string | null,
  amount: number,
  ): Action {
  return {
    type: EDIT_SOLUTION_CONTROL_REQUEST,
    payload: {
      solution_control_risk_id,
      unit_of_measure,
      amount
    }
  };
}

function editSolutionControlSuccess(): Action {
  return {
    type: EDIT_SOLUTION_CONTROL_SUCCESS,
  };
}

function editSolutionControlFailure(): Action {
  return {
    type: EDIT_SOLUTION_CONTROL_FAILURE,
  };
}

function editSolutionInformationRequest(
  solution_project_id: string,
  total_value: number,
  solution_implementation_deadline_months: number
): Action {
  return {
    type: EDIT_SOLUTION_INFORMATION_REQUEST,
    payload: {
      solution_project_id,
      total_value,
      solution_implementation_deadline_months
    }
  };
}

function editSolutionInformationSuccess(): Action {
  return {
    type: EDIT_SOLUTION_INFORMATION_SUCCESS,
  };
}

function editSolutionInformationFailure(): Action {
  return {
    type: EDIT_SOLUTION_INFORMATION_FAILURE,
  };
}

function getSearchFilteredSolutionsRequest(
  filteredSolutions: Array<string | boolean | null>,
  isActive: boolean | null
): Action {
  return {
    type: GET_FILTERED_SOLUTIONS_REQUEST,
    payload: {
      filteredSolutions,
      isActive
    },
  };
}

function getSearchFilteredSolutionsSuccess(solutions: AllSolutions): Action {
  return {
    type: GET_FILTERED_SOLUTIONS_SUCCESS,
    payload: {
      solutions,
    },
  };
}

function getSearchFilteredSolutionsFailure(): Action {
  return {
    type: GET_FILTERED_SOLUTIONS_FAILURE,
  };
}

function getSoluctionCSFCoreProjectRequest({ id }: SolutionProjectIdPayload): Action {
  return {
    type: GET_SOLUTIONS_CSFCORE_PROJECT_REQUEST,
    payload: {
      id,
    },
  };
}

function getSoluctionCSFCoreProjectSuccess(solutionCsfCoreProject: SolutionCsfCoreProject): Action {
  return {
    type: GET_SOLUTIONS_CSFCORE_PROJECT_SUCCESS,
    payload: {
      solutionCsfCoreProject,
    },
  };
}

function getSoluctionCSFCoreProjectFailure(): Action {
  return {
    type: GET_SOLUTIONS_CSFCORE_PROJECT_FAILURE,
  };
}

function linkCSFCoreSolutionRequest(
  {
    id,
  }: JustId): Action {
  return {
    type: LINK_CSFCORE_SOLUTION_REQUEST,
    payload: {
      id,
    },
  };
}

function linkCSFCoreSolutionSuccess(): Action {
  return {
    type: LINK_CSFCORE_SOLUTION_SUCCESS,
  };
}

function linkCSFCoreSolutionFailure(): Action {
  return {
    type: LINK_CSFCORE_SOLUTION_FAILURE,
  };
}

function updateCSFCoreSolutionRequest(
  {
    id,
    projectId,
    solution_projects,
  }: SolutionCsfCoreProjectPayload): Action {
  return {
    type: UPDATE_CSFCORE_SOLUTION_REQUEST,
    payload: {
      id,
      projectId,
      solution_projects,
    },
  };
}

function updateCSFCoreSolutionSuccess(): Action {
  return {
    type: UPDATE_CSFCORE_SOLUTION_SUCCESS,
  };
}

function updateCSFCoreSolutionFailure(): Action {
  return {
    type: UPDATE_CSFCORE_SOLUTION_FAILURE,
  };
}

export const SolutionsFunctions = {
  getSolutionsRequest,
  getSolutionsSuccess,
  getSolutionsFailure,
  editSolutionRequest,
  editSolutionSuccess,
  editSolutionFailure,
  addNewSolutionRequest,
  addNewSolutionSuccess,
  addNewSolutionFailure,
  getSoluctionDetailRequest,
  getSoluctionDetailSuccess,
  getSoluctionDetailFailure,
  editSolutionControlRequest,
  editSolutionControlSuccess,
  editSolutionControlFailure,
  linkOrUnlinkControlsRequest,
  linkOrUnlinkControlsSuccess,
  linkOrUnlinkControlsFailure,
  editSolutionInformationRequest,
  editSolutionInformationSuccess,
  editSolutionInformationFailure,
  activeOrInactiveSoluctionRequest,
  activeOrInactiveSoluctionSuccess,
  activeOrInactiveSoluctionFailure,
  getSoluctionProjectDetailRequest,
  getSoluctionProjectDetailSuccess,
  getSoluctionProjectDetailFailure,
  editSolutionProjectStatusRequest,
  editSolutionProjectStatusSuccess,
  editSolutionProjectStatusFailure,
  getSearchFilteredSolutionsRequest,
  getSearchFilteredSolutionsSuccess,
  getSearchFilteredSolutionsFailure,
  getSoluctionCSFCoreProjectRequest,
  getSoluctionCSFCoreProjectSuccess,
  getSoluctionCSFCoreProjectFailure,
  editControlsSolutionRankingRequest,
  editControlsSolutionRankingSuccess,
  editControlsSolutionRankingFailure,
  addAndRemoveSolutionControlsRequest,
  addAndRemoveSolutionControlsSuccess,
  addAndRemoveSolutionControlsFailure,
  getControlSolutionProjectDetailRequest,
  getControlSolutionProjectDetailSuccess,
  getControlSolutionProjectDetailFailure,
  activeOrInactiveControlSolutionRequest,
  activeOrInactiveControlSolutionSuccess,
  activeOrInactiveControlSolutionFailure,
  generateControlSolutionCustomerProjectRequest,
  generateControlSolutionCustomerProjectSuccess,
  generateControlSolutionCustomerProjectFailure,
  linkCSFCoreSolutionRequest,
  linkCSFCoreSolutionSuccess,
  linkCSFCoreSolutionFailure,
  updateCSFCoreSolutionRequest,
  updateCSFCoreSolutionSuccess,
  updateCSFCoreSolutionFailure,
};
