/* eslint global-require: "error" */
import moment from 'moment';
import { Sectors, Segments, SubSectors } from 'store/modules/customer/customerSector/types';
import {
  AnalystQuestionCreate,
  Answers,
  EditQuestionnaireAnswer,
  EvidencesToRemove
} from 'store/modules/customerProject/types';
import { Checklist } from 'store/modules/Manutention/checklists/types';
import type { Damage } from 'store/modules/Manutention/damages/types';
import { Quiz } from 'store/modules/Manutention/questionnaries/types';
import type { Question } from 'store/modules/Manutention/questions/types';
import type { RiskFactor } from 'store/modules/Manutention/riskFactors/types';
import type {
  Control, SimpleControl, SimpleVulnerability, Threat, Vulnerability
} from 'store/modules/Manutention/vulnerabilities/types';

interface ObjectWithIdAndName {
  id: string;
  name: string;
};

type IdandName = {
  id: string;
  name: string;
}

type Recommendations = {
  type_recommendation: string;
  controls: IdandName[];
}

type allStages = {
  id: string;
  name: string;
  risk_customer_project?: string;
  recommendations: Recommendations[];
}

type MyData = string | Date | number | null;
type MyArray = Sectors | SubSectors | Segments;

export function hasDuplicatesControls(pObject: allStages[]): boolean {
  /**
   * - This function checks for duplicate controls at every step of the input object
   * 
   * @param {allStages[]} pObject - The input is an array of 'allStages' objects, where 
   * each 'allStages' object consists of a 'recommendations' array and within that a 
   * 'controls' array, and each 'controls' object will have a Unique ID.
   * @returns {boolean} Returns 'true' if there are duplicate IDs in 'controls' across 
   * all 'stages', 'false' if there are no duplicates.
  */

  // Maps all controls in each stage
  const matrizControls_1 = pObject.map(element => element.recommendations.map((e) => e.controls));

  // Flatten the two control matrices below
  const matrizControls_2 = matrizControls_1.reduce((list, sub) => list.concat(sub), []);
  const arrayControls = matrizControls_2.reduce((list, sub) => list.concat(sub), []);

  // Maps all control IDs from the array to an array
  const arr = arrayControls.map(element => element.id);

  // Returns 'true' if the number of unique elements in array 'arr' is less than the total 
  // number of elements, which implies the presence of duplicate elements (duplicate control IDs)
  return new Set(arr).size !== arr.length;
};

export function filteredDamages(pObject: Damage[]): Damage[] {
  /**
   * - Filters the array of Damage objects, returning only those that are marked as active.
   * 
   * @param {Damage[]} pObject - An array of Damage objects.
   * @returns {Damage[]} Returns a new array containing only Damage objects that have the 
   * is_active property set to true.
  */
  return pObject.filter(value => value.is_active);
}

export function filteredThreats(pObject: Threat[]): Threat[] {
  /**
   * - Filters the array of Threat objects, returning only those that are marked as active.
   * 
   * @param {Threat[]} pObject - An array of Threat objects.
   * @returns {Threat[]} Returns a new array containing only Threat objects that have the 
   * is_active property set to true.
  */
  return pObject.filter(value => value.is_active);
}

export function filteredControls(pObject: Control[]): Control[] {
  /**
   * - Filters the array of Control objects, returning only those that are marked as active.
   * 
   * @param {Control[]} pObject - An array of Control objects.
   * @returns {Control[]} Returns a new array containing only Control objects that have the 
   * is_active property set to true.
  */
  return pObject.filter(value => value.is_active);
}

export function filteredSimpleControls(pObject: SimpleControl[]): SimpleControl[] {
  /**
   * - Filters the array of SimpleControl objects, returning only those that are marked as active.
   * 
   * @param {SimpleControl[]} pObject - An array of SimpleControl objects.
   * @returns {SimpleControl[]} Returns a new array containing only SimpleControl objects that have the 
   * is_active property set to true.
  */
  return pObject.filter(value => value.is_active);
}

export function filteredRiskFactors(pObject: RiskFactor[]): RiskFactor[] {
  /**
   * - Filters the array of RiskFactor objects, returning only those that are marked as active.
   * 
   * @param {RiskFactor[]} pObject - An array of RiskFactor objects.
   * @returns {RiskFactor[]} Returns a new array containing only RiskFactor objects that have the 
   * is_active property set to true.
  */
  return pObject.filter(value => value.is_active);
}

export function filteredVulnerabilities(pObject: Vulnerability[]): Vulnerability[] {
  /**
   * - Filters the array of Vulnerability objects, returning only those that are marked as active.
   * 
   * @param {Vulnerability[]} pObject - An array of Vulnerability objects.
   * @returns {Vulnerability[]} Returns a new array containing only Vulnerability objects that have the 
   * is_active property set to true.
  */
  return pObject.filter(value => value.is_active);
}

export function filteredSimpleVulnerabilities(pObject: SimpleVulnerability[]): SimpleVulnerability[] {
  /**
   * - Filters the array of SimpleVulnerability objects, returning only those that are marked as active.
   * 
   * @param {SimpleVulnerability[]} pObject - An array of SimpleVulnerability objects.
   * @returns {SimpleVulnerability[]} Returns a new array containing only SimpleVulnerability objects that have the 
   * is_active property set to true.
  */
  return pObject.filter(value => value.is_active);
}

export function filteredQuestionnaires(pObject: Quiz[]): Quiz[] {
  /**
   * - Filters the array of Quiz objects, returning only those that are marked as active.
   * 
   * @param {Quiz[]} pObject - An array of Quiz objects.
   * @returns {Quiz[]} Returns a new array containing only Quiz objects that have the 
   * is_active property set to true.
  */
  return pObject.filter(value => value.is_active);
}

export function filteredChecklists(pObject: Checklist[]): Checklist[] {
  /**
   * - Filters the array of Checklist objects, returning only those that are marked as active.
   * 
   * @param {Checklist[]} pObject - An array of Checklist objects.
   * @returns {Checklist[]} Returns a new array containing only Checklist objects that have the 
   * is_active property set to true.
  */
  return pObject.filter(value => value.is_active);
}

export function filteredQuestions(pObject: Question[]): Question[] {
  /**
   * - Filters the array of Question objects, returning only those that are marked as active.
   * 
   * @param {Question[]} pObject - An array of Question objects.
   * @returns {Question[]} Returns a new array containing only Question objects that have the 
   * is_active property set to true.
  */
  return pObject.filter(value => value.is_active);
}

export function addDelay(milliseconds: number) {
  /**
   * - Creates a promise that resolves after a certain number of milliseconds.
   * 
   * @param {number} milliseconds - The number of milliseconds to wait before the promise resolves.
   * @returns {Promise} Returns a new promise that will resolve after the defined number of milliseconds.
   * 
   * @example
   * (async () => {
   *   await addDelay(2000);
   * })();
  */
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export function thereAreQuestionsCreatedWithoutAnswers(pQuestionCreate: AnalystQuestionCreate[]): boolean {
  /**
   * - Checks for questions created without answers.
   * 
   * @param {AnalystQuestionCreate[]} pQuestionCreate - Array with created questions.
   * @returns {boolean} Returns "true" if there is at least one unanswered question, 
   * "false" otherwise.
  */

  let result = true;

  pQuestionCreate.forEach(element => {
    // If any of these checks fail, the function returns "false", indicating that there are one or more unanswered questions
    switch (element.question_type) {
      case 'OPEN_QUESTION': {
        // If the question type is "OPEN_QUESTION", it checks whether the text answer is empty
        if (element.answer.answer !== '')
          result = false;
        break;
      }

      case 'BINARY_QUESTION': {
        // If the question type is "BINARY_QUESTION", it checks for alternatives (which would be possible answers)
        if (element.alternatives.length > 0)
          result = false;
        break;
      }

      case 'MULTIPLE_CHOICE': {
        // If the question type is "MULTIPLE_CHOICE", it checks if there is any answer in the alternatives
        element.alternatives.forEach(e => {
          if (Object.values(e.answer).length > 0)
            result = false;
        });
        break;
      }

      default: {
        result = true;
        break;
      }
    }
  });

  return result;
}

export function ThereIsEvidenceInAnswer(pAnsewr: Answers | EditQuestionnaireAnswer | undefined): boolean {
  /**
   * - Checks if there is a response
   * 
   * @param {Answers | EditQuestionnaireAnswer | undefined} pAnsewr - An object of class Answers or 
   * EditQuestionnaireAnswer or 'undefined'.
   * @returns {boolean} Returns "true" if there is evidence in the response, that is, if the response 
   * is not 'undefined'. Returns "false" otherwise
  */
  return pAnsewr !== undefined;
};

export function ThereIsEvidenceInQuestionCreate(pAnsewr: AnalystQuestionCreate | undefined): boolean {
  /**
   * - Checks if there is a response
   * 
   * @param {AnalystQuestionCreate | undefined} pAnsewr - An object of class AnalystQuestionCreate or 'undefined'.
   * @returns {boolean} Returns "true" if there is evidence in the response, that is, if the response 
   * is not 'undefined'. Returns "false" otherwise
  */
  return pAnsewr !== undefined;
};

export function ThereIsEvidenceToRemove(pAnswers: EvidencesToRemove[]): boolean {
  /**
   * - Checks if there is evidence to remove
   * 
   * @param {EvidencesToRemove} pAnswers - Array of evidence to remove.
   * @returns {boolean} Returns "true" if there is at least one piece of evidence to remove, i.e. if the length of 
   * the array is greater than 0. Returns "false" otherwise.
  */
  return pAnswers.length > 0;
};

export function isEmptyObject(pObject: any): boolean {
  /**
   * - Checks if any object is empty
   * 
   * @param {any} pObject - The object that will be checked. 
   * @returns {boolean} Returns "true" if the given object is empty, that is, if it has no properties. Returns 
   * "false" otherwise.
  */
  return Object.values(pObject).length === 0;
};

export function simpleDateFormatting(pData: MyData): string {
  /**
   * - Formats the date in 'DD/MM/YYYY' format
   * 
   * @param {string | Date | number | null} pData - The date that will be formatted. It may be null.
   * @returns {string} Returns the date formatted in 'DD/MM/YYYY' format. If the date is null, returns an empty string.
  */
  if (pData === null)
    return '';
  return moment(pData).format('DD/MM/YYYY');
};

export function fullDateFormatting(pData: MyData): string {
  /**
   * - Formats the date in 'DD/MM/YYYY' format and adds the time in 'LT' (Time Locale) format
   * 
   * @param {string | Date | number | null} pData - The date that will be formatted. It may be null.
   * @returns {string} Returns the date and time formatted in 'DD/MM/YYYY at LT' format. If the date 
   * is null, returns an empty string.
  */
  if (pData === null)
    return '';
  return `${moment(pData).format('DD/MM/YYYY')} às ${moment(pData).format('LT')}`;
};

export function simpleDateFormattingOther(pData: MyData): string {
  /**
   * - Formats the date in 'YYYY-MM-DD' format
   * 
   * @param {string | Date | number | null} pData - The date that will be formatted. It may be null.
   * @returns {string} Returns the date formatted in 'YYYY-MM-DD' format. If the date is null, returns 
   * an empty string.
  */
  if (pData === null)
    return '';
  return moment(pData).format('YYYY-MM-DD');
};

export function formatLabelProgress(pValue: number): string {
  /**
   * - Formats the progress percentage by removing the unnecessary zero after the decimal point
   * 
   * @param {number} pValue - The value representing the current progress, as a percentage.
   * @returns {string} Returns a formatted string representing progress as a percentage with the 
   * phrase "Completed" appended.
  */
  let newValue = pValue.toFixed(2);
  for (let i = 0; i <= 100; i += 10) {
    if (newValue === i.toFixed(2)) {
      newValue = newValue.replace(i.toFixed(2), i.toString());
      break;
    }
  }
  return `${newValue}% Concluído`;
}

export function stringForDate(pDate: string, pFormat: string): Date | null {
  /**
   * - Converts the date, represented as a string and in the provided format, to a Date object
   * 
   * @param {string} pDate - The date as a string.
   * @param {string} pFormat - The format of the given date. Currently, it only supports the 'YYYY-mm-dd' format.
   * @returns {Date | null} Returns a Date object corresponding to the given date. If the given format is not 
   * supported or the date is null, returns null.
  */
  if ((pFormat === 'YYYY-mm-dd') && (pDate !== null)) {
    const arrayData = pDate.split('-');
    return new Date(`${arrayData[1]}-${arrayData[2]}-${arrayData[0]}`);
  }
  return null;
}

export function stringForDateTwo(pDate: string, pFormat: string): Date | null {
  /**
   * - Converts the date, represented as a string and in a given format, to a Date object
   * 
   * @param {string} pDate - The date represented as a string.
   * @param {string} pFormat - The format of the given date. Currently, it only supports the 'YYYY-mm-dd' format.
   * @returns {Date | null} Returns a Date object corresponding to the given date in the expected format. If the 
   * given date string does not have a length of 10, is 'Invalid date', or the given format is not supported or 
   * data is null, returns null.
  */
  if ((pFormat === 'YYYY-mm-dd') && (pDate !== null)) {
    // checks that the given date string has a length of 10 and is not equal to 'Invalid date'
    if (pDate.length !== 10 || pDate === 'Invalid date')
      return null;

    // Split the date string into an array and create a new date string in the format 'mm-dd-YYYY'
    const arrayData = pDate.split('-');
    return new Date(`${arrayData[1]}-${arrayData[2]}-${arrayData[0]}`);
  }
  return null;
}

export function simpleStringForDate(pValue: string): Date {
  /**
   * - Convert a date string in 'DD/MM/YYYY' format to a Date object
   * 
   * @param {string} pValue - The date represented as a string in the format 'DD/MM/YYYY'.
   * @returns {Date} Returns a Date object that matches the date given in the string.
  */
  const [day, month, year] = pValue.split('/').map(Number);
  return new Date(year, month - 1, day);
};

export function formarCurrencyDollar(pCurrent: number): string {
  /**
   * - Forms a dollar currency value
   * 
   * @param {number} pCurrent - The current value to be converted to dollar format.
   * @returns {string} Returns a string formatted as a representation of the currency value in dollars.
  */
  let lValue = 0;
  if (pCurrent !== null)
    lValue = pCurrent;
  const result = lValue.toLocaleString(
    'pt-br',
    {
      minimumFractionDigits: 2
    }
  );
  return `US$ ${result}`;
}

export function returnImageColor(pDisableChange: boolean, pOrganogram: string): 'disabled' | 'inherit' | 'action' | 'success' | 'error' | 'primary' | 'secondary' | 'info' | 'warning' | undefined {
  /**
   * - Returns the image color based on the input parameters
   * 
   * @param {boolean} pDisableChange - Disable color change. If true, returns 'disabled'.
   * @param {string} pOrganogram - Represents an organization chart value. If the organizer 
   * is not empty, returns 'success'. Otherwise, 'primary'.
   * @returns {('disabled' | 'inherit' | 'action' | 'success' | 'error' | 'primary' | 'secondary' | 'info' | 'warning' | undefined)} Returns the string representing the color of image.
  */
  if (pDisableChange)
    return 'disabled';
  return pOrganogram !== '' ? 'success' : 'primary';
};

export function returnID(): number {
  /**
   * - Generates a unique ID number using the Web Crypto API
   * 
   * @returns {number} Returns a unique three-digit integer derived from a random value.
  */
  const { crypto } = window;
  const array = new Uint32Array(1);
  crypto.getRandomValues(array);
  return Number(crypto.getRandomValues(array)[0].toString().substring(0, 3)) - 100;
}

export function returnFileNameWithExtension(pFileName: string, pType: string): string {
  /**
   * - Returns the file name with its extension based on the provided file type.
   *
   * @param {string} pFileName - The file name
   * @param {string} pType - The type of the file (e.g., 'image/png', 'application/pdf')
   * @returns {string} The file name with extension
  */

  let lExtension: string;
  switch (pType) {
    case 'image/png':
      lExtension = '.png';
      break;
    case 'image/bmp':
      lExtension = '.bmp';
      break;
    case 'image/jpeg':
      lExtension = '.jpeg';
      break;
    case 'image/svg+xml':
      lExtension = '.svg';
      break;
    case 'application/pdf':
      lExtension = '.pdf';
      break;
    case 'application/zip':
      lExtension = '.zip';
      break;
    case 'multipart/x-zip':
      lExtension = '.zip';
      break;
    case 'application/msword':
      lExtension = '.doc';
      break;
    case 'application/vnd.rar':
      lExtension = '.rar';
      break;
    case 'application/octet-stream':
      lExtension = '.txt';
      break;
    case 'application/vnd.ms-excel':
      lExtension = '.xls';
      break;
    case 'application/x-zip-compressed':
      lExtension = '.zip';
      break;
    case 'application/x-rar-compressed':
      lExtension = '.rar';
      break;
    case 'application/vnd.ms-powerpoint':
      lExtension = '.ppt';
      break;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      lExtension = '.xlsx';
      break;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      lExtension = '.docx';
      break;
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      lExtension = '.pptx';
      break;
    default:
      lExtension = '.txt';
      break;
  }
  return `${pFileName}${lExtension}`;
}

export function returnArrayOfStringInSortedForm(pArray: any[]): string[] {
  /**
   * - Sorts an array of strings and returns the sorted array.
   *
   * @param {any[]} pArray - The array to be sorted
   * @returns {string[]} The sorted array
  */
  return pArray.sort((a, b) => (a - b));
}

export function returnCepMask(pCep: string | undefined): string {
  /**
   * - Applies a mask to a given Brazilian postal code (CEP).
   *
   * @param {string|undefined} pCep - The postal code to apply the mask to
   * @returns {string} The masked postal code, or an empty string if the input is empty or undefined
  */
  if (pCep === '' || pCep === undefined)
    return '';
  return pCep.replace(/(\d{5})-*(\d{3})/, '$1-$2');
};

export function returnArrayWithIdAndName(pArray: MyArray[]): ObjectWithIdAndName[] {
  /**
   * - Returns the modified array with extracted 'id' and 'name' properties from the original array.
   *
   * @param {(Sectors | SubSectors | Segments)[]} pArray - The original array
   * @returns {ObjectWithIdAndName[]} The modified array with only 'id' and 'name' properties
  */
  return (
    pArray.map(element => {
      return {
        id: element.id,
        name: element.name
      };
    })
  );
}

export function returnArraySubSects(pArray: Sectors[]): SubSectors[] {
  /**
   * - Returns an array of subsectors extracted from the original array of sectors, excluding any empty subsectors.
   *
   * @param {Sectors[]} pArray - The original array of sectors
   * @returns {SubSectors[]} The array of subsectors
  */
  return pArray.map(element => element.subsectors).filter(element => element.length > 0).reduce((previousValue, currentValue) => previousValue.concat(currentValue), []);
}

export function returnArraySegments(pArray: SubSectors[]): Segments[] {
  /**
   * - Returns an array of segments extracted from the original array of subSectors, excluding any empty segments.
   *
   * @param {SubSectors[]} pArray - The original array of subSectors.
   * @returns {Segments[]} The array of segments.
  */
  return pArray.map(element => element.segments).filter(element => element.length > 0).reduce((previousValue, currentValue) => previousValue.concat(currentValue), []);
}

export function putDash(pValue: string | number): string | number {
  /**
   * - Replaces a falsy value with a dash.
   *
   * @param {string} pValue - The value to be evaluated
   * @returns {string} The original string if it is truthy, otherwise, a dash
  */
  if (!pValue)
    return '-';
  return pValue;
};

export function openLinkInAnotherTab(pLink: string): void {
  /**
   * Opens a given link in a new browser tab and focuses on it.
   *
   * @param {string} pLink - The URL of the web page to open
   * @returns {void}
  */
  const win = window.open(pLink, '_blank');
  win?.focus();
}

export function returnTheItemsThatWillBeRemoved<ItemTypeA, ItemTypeB>(
  originalArray: ItemTypeA[],
  comparisonArray: ItemTypeB[],
  compareFunction: (itemA: ItemTypeA, itemB: ItemTypeB) => boolean
): ItemTypeA[] {
  /** Returns a new list containing items from the original array that are not present in the comparison array.
    *
    * @template ItemTypeA - The type of items contained in the original array.
    * @template ItemTypeB - The type of items contained in the comparison array.
    * 
    * @param {ItemTypeA[]} originalArray - The original array.
    * @param {ItemTypeB[]} comparisonArray - The array to compare against to find items to remove.
    * @param {(itemA: ItemTypeA, itemB: ItemTypeB) => boolean} [compareFunction] - A function that defines the 
    * logic for comparing items. This function should return `true` if the elements are considered identical, 
    * and `false` otherwise. If this is not provided, strict equality (===) will be used.
    * 
    * @returns {ItemTypeA[]} Returns a new list containing items of originalArray that aren't present in comparisonArray.
  */
  return originalArray?.filter(
    originalItem => !comparisonArray?.some(
      comparisonItem => compareFunction(originalItem, comparisonItem)
    )
  );
};

export function removeDuplicates(originalArray: any[], key: string): any[] {
  /**
   * Removes duplicates from an array of objects based on a specific key.
   *
   * @param {Object[]} originalArray - The original array of objects from which duplicates will be removed.
   * @param {string} key - The key on the object that will be used for comparison and removal of duplicates.
   *
   * @returns {Object[]} Returns a new array of objects with no duplicates.
  */
  return Array.from(new Map(originalArray.map(item => [item[key], item])).values());
};

export function removeEvidenceByName(pEvidencesTemp: any[], pEvidenceName: string): any[] | [] {
  /**
 * Removes an object from an array of evidences based on the specified name.
 * @param {Array} pEvidencesTemp - The array containing the evidence objects to filter.
 * @param {string} pEvidenceName - The name of the evidence to be removed from the array.
 * @returns {Array} A new array of evidences without the object that has the specified name.
 *                  Returns an empty array if no object is removed.
 */

  const newObjectEvidence = pEvidencesTemp.filter(
    element => element.name !== pEvidenceName
  );
  return newObjectEvidence.length ? newObjectEvidence : [];
};

export function returnBorderLeftChecklist(pStatus: string | undefined): string {
  /**
 * Returns the left border style based on the provided status.
 * 
 * @param {string | undefined} pStatus - The status of the item. Can be 'IN_PROGRESS', 'CONCLUDED', or undefined.
 * @returns {string} - The CSS style for the left border, based on the provided status:
 *  - 'IN_PROGRESS': '10px solid #FF8C00' (orange)
 *  - 'CONCLUDED': '10px solid #24B31D' (green)
 *  - Any other value or undefined: '10px solid #646464' (gray)
 */

  if (pStatus === 'IN_PROGRESS') return '10px solid #FF8C00';
  if (pStatus === 'CONCLUDED') return '10px solid #24B31D';
  return '10px solid #646464';
};

export function returnPhoneMask(pValue: string): string {
  /**
 * Formats a phone number into a readable format.
 * 
 * @param {string} pValue - The phone number to be formatted. Can contain non-numeric characters.
 * @returns {string} - The formatted phone number according to Brazilian standards:
 *  - For 11-digit mobile numbers: '(XX) 9 XXXX-XXXX'
 *  - For 10-digit landline numbers: '(XX) XXXX-XXXX'
 * 
 * @example
 * // Example usage
 * returnPhoneMask('85987654321'); // '(85) 9 8765-4321'
 * returnPhoneMask('8532167890');  // '(85) 3216-7890'
 */

  let lValue = pValue.replace(/\D/g, '');

  if (lValue.length <= 11) {
    if (lValue.length > 10) {
      lValue = lValue.replace(/(\d{2})(\d)/, '($1) $2');
      lValue = lValue.replace(/(\d{1})(\d{4})(\d{4})$/, '$1 $2-$3');
    } else {
      lValue = lValue.replace(/(\d{2})(\d)/, '($1) $2');
      lValue = lValue.replace(/(\d{4})(\d{4})$/, '$1-$2');
    }
  }

  return lValue;
};