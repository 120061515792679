import type { Action } from 'store/types';

import {
  AllAnonymizationRequest,
  AnonymizationDetailsPayload,
  AnonymizationDetailsRequest,
  ProjectsForAnonimizationActions,
  UpdateAnonymizationPayloadParams,
  CustomersProjectsForAnonymization,
  CustomersProjectsForAnonymizationPayload,    
} from './types';

const {
  GET_ALL_ANONIMIZATION_REQUEST,
  GET_ALL_ANONIMIZATION_SUCCESS,
  GET_ALL_ANONIMIZATION_FAILURE,
  APPROVED_ANONIMIZATION_REQUEST,
  APPROVED_ANONIMIZATION_SUCCESS,
  APPROVED_ANONIMIZATION_FAILURE,
  GET_ANONIMIZATION_DETAILS_REQUEST,
  GET_ANONIMIZATION_DETAILS_SUCCESS,
  GET_ANONIMIZATION_DETAILS_FAILURE,
  SET_PROJECTS_FOR_ANONIMIZATION_REQUEST,
  SET_PROJECTS_FOR_ANONIMIZATION_SUCCESS,
  SET_PROJECTS_FOR_ANONIMIZATION_FAILURE,
  GET_ALL_PROJECTS_FOR_ANONIMIZATION_REQUEST,
  GET_ALL_PROJECTS_FOR_ANONIMIZATION_SUCCESS,
  GET_ALL_PROJECTS_FOR_ANONIMIZATION_FAILURE,
} = ProjectsForAnonimizationActions;

function getProjectsForAnonimizationRequest(): Action {
  return {
    type: GET_ALL_PROJECTS_FOR_ANONIMIZATION_REQUEST,
  };
}

function getProjectsForAnonimizationSuccess(allProjectsForAnonimizations: CustomersProjectsForAnonymization[]): Action {
  return {
    type: GET_ALL_PROJECTS_FOR_ANONIMIZATION_SUCCESS,
    payload: {
      allProjectsForAnonimizations,
    },
  };
}

function getProjectsForAnonimizationFailure(): Action {
  return {
    type: GET_ALL_PROJECTS_FOR_ANONIMIZATION_FAILURE,
  };
}

function setProjectsForAnonimizationRequest(customers_projects: CustomersProjectsForAnonymizationPayload): Action {
  return {
    type: SET_PROJECTS_FOR_ANONIMIZATION_REQUEST,
    payload: {
      customers_projects,
    },
  };
}

function setProjectsForAnonimizationSuccess(): Action {
  return {
    type: SET_PROJECTS_FOR_ANONIMIZATION_SUCCESS,
  };
}

function setProjectsForAnonimizationFailure(): Action {
  return {
    type: SET_PROJECTS_FOR_ANONIMIZATION_FAILURE,
  };
}

function getAnonimizationRequest(approved: boolean | null): Action {
  return {
    type: GET_ALL_ANONIMIZATION_REQUEST,
    payload: {
      approved,
    }
  };
}

function getAnonimizationSuccess(allAnonymizationRequest: AllAnonymizationRequest): Action {
  return {
    type: GET_ALL_ANONIMIZATION_SUCCESS,
    payload: {
      allAnonymizationRequest,
    },
  };
}

function getAnonimizationFailure(): Action {
  return {
    type: GET_ALL_ANONIMIZATION_FAILURE,
  };
}

function updateAnonimizationRequest({
  id,
  approved,
}: UpdateAnonymizationPayloadParams): Action {
  return {
    type: APPROVED_ANONIMIZATION_REQUEST,
    payload: {
      id,
      approved,
    },
  };
}

function updateAnonimizationSuccess(): Action {
  return {
    type: APPROVED_ANONIMIZATION_SUCCESS,
  };
}

function updateAnonimizationFailure(): Action {
  return {
    type: APPROVED_ANONIMIZATION_FAILURE,
  };
}

function getAnonimizationDetailsRequest({ id }: AnonymizationDetailsPayload): Action {
  return {
    type: GET_ANONIMIZATION_DETAILS_REQUEST,
    payload: {
      id,
    }
  };
}

function getAnonimizationDetailsSuccess(anonymizationDetails: AnonymizationDetailsRequest): Action {
  return {
    type: GET_ANONIMIZATION_DETAILS_SUCCESS,
    payload: {
      anonymizationDetails,
    },
  };
}

function getAnonimizationDetailsFailure(): Action {
  return {
    type: GET_ANONIMIZATION_DETAILS_FAILURE,
  };
}

export const projectsForAnonimizationActionsFunctions = {
  getAnonimizationRequest,
  getAnonimizationSuccess,
  getAnonimizationFailure,
  updateAnonimizationRequest,
  updateAnonimizationSuccess,
  updateAnonimizationFailure,
  getAnonimizationDetailsRequest,
  getAnonimizationDetailsSuccess,
  getAnonimizationDetailsFailure,
  getProjectsForAnonimizationRequest,
  getProjectsForAnonimizationSuccess,
  getProjectsForAnonimizationFailure,
  setProjectsForAnonimizationRequest,
  setProjectsForAnonimizationSuccess,
  setProjectsForAnonimizationFailure,  
};
