import { SimpleControl } from '../vulnerabilities/types';

export enum QuestionsActions {
  GET_ALL_QUESTIONS_MODULE_FAILURE = 'GET_ALL_QUESTIONS_MODULE_FAILURE',
  GET_ALL_QUESTIONS_MODULE_REQUEST = 'GET_ALL_QUESTIONS_MODULE_REQUEST',
  GET_ALL_QUESTIONS_MODULE_SUCCESS = 'GET_ALL_QUESTIONS_MODULE_SUCCESS',
  GET_FILTERED_QUESTION_REQUEST = 'GET_FILTERED_QUESTION_REQUEST',
  GET_FILTERED_QUESTION_SUCCESS = 'GET_FILTERED_QUESTION_SUCCESS',
  GET_FILTERED_QUESTION_FAILURE = 'GET_FILTERED_QUESTION_FAILURE',
  GET_QUESTION_DETAIL_REQUEST = 'GET_QUESTION_DETAIL_REQUEST',
  GET_QUESTION_DETAIL_SUCCESS = 'GET_QUESTION_DETAIL_SUCCESS',
  GET_QUESTION_DETAIL_FAILURE = 'GET_QUESTION_DETAIL_FAILURE',
  ADD_NEW_QUESTION_REQUEST = 'ADD_NEW_QUESTION_REQUEST',
  ADD_NEW_QUESTION_SUCCESS = 'ADD_NEW_QUESTION_SUCCESS',
  ADD_NEW_QUESTION_FAILURE = 'ADD_NEW_QUESTION_FAILURE',
  EDIT_QUESTION_ADD_ALTERNATIVE_REQUEST = 'EDIT_QUESTION_ADD_ALTERNATIVE_REQUEST',
  EDIT_QUESTION_REQUEST = 'EDIT_QUESTION_REQUEST',
  EDIT_QUESTION_SUCCESS = 'EDIT_QUESTION_SUCCESS',
  EDIT_QUESTION_FAILURE = 'EDIT_QUESTION_FAILURE',
  ACTIVE_OR_INACTIVE_QUESTION_REQUEST = 'ACTIVE_OR_INACTIVE_QUESTION_REQUEST',
  ACTIVE_OR_INACTIVE_QUESTION_SUCCESS = 'ACTIVE_OR_INACTIVE_QUESTION_SUCCESS',
  ACTIVE_OR_INACTIVE_QUESTION_FAILURE = 'ACTIVE_OR_INACTIVE_QUESTION_FAILURE',
  RESET_THE_QUESTION_DETAIL_STATE = 'RESET_THE_QUESTION_DETAIL_STATE',
  GET_ALL_QUESTIONS_DEPARTMENTS_REQUEST = 'GET_ALL_QUESTIONS_DEPARTMENTS_REQUEST',
  GET_ALL_QUESTIONS_DEPARTMENTS_SUCCESS = 'GET_ALL_QUESTIONS_DEPARTMENTS_SUCCESS',
  GET_ALL_QUESTIONS_DEPARTMENTS_FAILURE = 'GET_ALL_QUESTIONS_DEPARTMENTS_FAILURE',
  GET_ALL_QUESTIONS_VULNERABILITIES_REQUEST = 'GET_ALL_QUESTIONS_VULNERABILITIES_REQUEST',
  GET_ALL_QUESTIONS_VULNERABILITIES_SUCCESS = 'GET_ALL_QUESTIONS_VULNERABILITIES_SUCCESS',
  GET_ALL_QUESTIONS_VULNERABILITIES_FAILURE = 'GET_ALL_QUESTIONS_VULNERABILITIES_FAILURE',
  GET_ALL_RISKFACTORS_REQUEST = 'GET_ALL_RISKFACTORS_REQUEST',
  GET_ALL_RISKFACTORS_SUCCESS = 'GET_ALL_RISKFACTORS_SUCCESS',
  GET_ALL_RISKFACTORS_FAILURE = 'GET_ALL_RISKFACTORS_FAILURE',
  GET_ALL_QUESTIONS_CONTROLS_REQUEST = 'GET_ALL_QUESTIONS_CONTROLS_REQUEST',
  GET_ALL_QUESTIONS_CONTROLS_SUCCESS = 'GET_ALL_QUESTIONS_CONTROLS_SUCCESS',
  GET_ALL_QUESTIONS_CONTROLS_FAILURE = 'GET_ALL_QUESTIONS_CONTROLS_FAILURE',
  GET_FILTERED_VULNERABILITY_REQUEST = 'GET_FILTERED_VULNERABILITY_REQUEST',
  GET_FILTERED_VULNERABILITY_SUCCESS = 'GET_FILTERED_VULNERABILITY_SUCCESS',
  GET_FILTERED_VULNERABILITY_FAILURE = 'GET_FILTERED_VULNERABILITY_FAILURE',
  GET_FILTERED_RISKFACTOR_REQUEST = 'GET_FILTERED_RISKFACTOR_REQUEST',
  GET_FILTERED_RISKFACTOR_SUCCESS = 'GET_FILTERED_RISKFACTOR_SUCCESS',
  GET_FILTERED_RISKFACTOR_FAILURE = 'GET_FILTERED_RISKFACTOR_FAILURE',
  GET_FILTERED_CONTROL_REQUEST = 'GET_FILTERED_CONTROL_REQUEST',
  GET_FILTERED_CONTROL_SUCCESS = 'GET_FILTERED_CONTROL_SUCCESS',
  GET_FILTERED_CONTROL_FAILURE = 'GET_FILTERED_CONTROL_FAILURE',
}

export type SearchFilteredQuestionRequestPayload = {
  filteredQuestions: Array<string | boolean | null>;
  filterDepartment: string | null;
};

export type SearchFilteredControlRequestPayload = {
  filteredControls: Array<string | boolean | null>;
};

export type SearchFilteredRiskFactorRequestPayload = {
  filteredRiskFactors: Array<string | boolean | null>;
};

export type SearchFilteredVulnerabilityRequestPayload = {
  filteredVulnerabilities: Array<string | boolean | null>;
};

export type AddNewQuestionRequestPayload = {
  newQuestion: AddNewQuestionData;
};

export type QuestionDetailRequestPayload = {
  questionID: string;
};

export type EditQuestionRequestPayload = {
  updatedQuestion: EditQuestion;
};

export type UpdateQuestionStatusRequestPayload = {
  QuestionId: string;
  newQuestionStatus: boolean;
};

export type ObjectWithId = {
  id: string;
}

export type AddNewQuestionData = {
  name: string;
  question_type: string;
  has_evidence: boolean;
  is_active: boolean;
  vulnerabilities: ObjectWithId[];
  risk_factors: ObjectWithId[];
  controls: ObjectWithId[];
  departments: ObjectWithId[];
  alternatives: any[];
  example: string;
  columns: any[];
}

export type AddNewQuestionResponseData = {
  id: string;
  order: number;
  name: string;
  question_type: string;
  has_evidence: boolean;
  is_active: boolean;
  vulnerabilities: ObjectWithId[];
  risk_factors: ObjectWithId[];
  controls: ObjectWithId[];
  departments: ObjectWithId[];
  example: string;
};

export type EditQuestionData = {
  id: string;
  name: string;
  question_type: string;
  has_evidence: boolean;
  is_active?: boolean;
  vulnerabilities: ObjectWithId[];
  risk_factors: ObjectWithId[];
  controls: ObjectWithId[];
  departments: ObjectWithId[];
  alternatives: AlternativesOfQuestions[];
  example: string;
};

export type EditQuestion = {
  id: string;
  name: string;
  question_type: string;
  has_evidence: boolean,
  is_active: boolean,
  controls_add?: any[],
  vulnerabilities_add?: any[],
  risk_factors_add?: any[],
  departments_add?: any[],
  alternatives_add?: any[],
  controls_remove?: any[],
  vulnerabilities_remove?: ObjectWithId[],
  risk_factors_remove?: ObjectWithId[],
  departments_remove?: ObjectWithId[],
  alternatives_remove?: ObjectWithId[],
  alternatives_update?: AlternativesOfQuestionsUpdate[],
  controls_update?: ObjectWithId[],
  vulnerabilities_update?: ObjectWithId[],
  risk_factors_update?: ObjectWithId[],
  departments_update?: ObjectWithId[],
  example: string;
  columns_add?: any[],
  columns_update?: ColumnOfQuestions[],
  columns_remove?: ObjectWithId[],
};

export type QuestionResults = {
  results: Question[];
  count: number;
  next: boolean;
}

export type ColumnOfQuestions = {
  id: string;
  name: string;
}

export type Question = {
  id: string;
  name: string;
  question_type: string;
  has_evidence: boolean;
  is_active: boolean;
  vulnerabilities: Vulnerability[];
  risk_factors: RiskFactor[];
  // controls: Control[];
  controls: SimpleControl[];
  departments: Department[];
  alternatives: AlternativesOfQuestions[];
  example: string;
  columns?: ColumnOfQuestions[];
};

export type ControlOfQuestion = {
  id: string;
  name: string;
}

export type VulnerabilityOfQuestion = {
  id: string;
  name: string;
}

export type riskFactorOfQuestion = {
  id: string;
  name: string;
}

export type AlternativesOfQuestions = {
  id: string;
  name: string;
  vulnerabilities: ObjectWithId[];
  risk_factors: ObjectWithId[];
  controls: ObjectWithId[];
};

export type AlternativesOfQuestionsUpdate = {
  id: string;
  name: string;
  question: string;
  vulnerabilities_add: ObjectWithId[];
  risk_factors_add: ObjectWithId[];
  controls_add: ObjectWithId[];
  vulnerabilities_remove: ObjectWithId[];
  risk_factors_remove: ObjectWithId[];
  controls_remove: ObjectWithId[];
};

export type Department = {
  id: string;
  name: string;
};

export type DepartmentResults = {
  results: Department[];
}

export type VulnerabilityResults = {
  results: Vulnerability[];
}

export type RiskFactorResults = {
  results: RiskFactor[];
}

export type Vulnerability = {
  id: string;
  friendly_id: string;
  name: string;
  vulnerability_type: string;
  attack_vector: string;
  privileges_required: string;
  user_interaction: string;
  scope: string;
  confidentiality: string;
  integrity: string;
  availability: string;
  is_active: boolean;
  registration_status: string;
  controls: ObjectWithId[];
  cves: CVEOfVulnerability[];
};

export type CVEOfVulnerability = {
  id: string;
  cve_name: string;
  cve_description: string;
};

export type RiskFactor = {
  id: string;
  friendly_id: string;
  name: string;
  is_active: boolean;
  registration_status: string;
  controls: ObjectWithId[];
  threats: ObjectWithId[];
};

export type Subcontrol = {
  id: string;
  control: string;
  name: string;
  cost: number;
  benefit: number;
  cost_benefit: string;
  technical_capacity: number;
  final_benefit: string;
  is_active: boolean;
};

export type ControlResults = {
  results: Control[];
}

export type Control = {
  id: string;
  friendly_id: string;
  name: string;
  class_name: number;
  group_name: number;
  type_name: number;
  category: number;
  control_type: string;
  confidentiality: boolean;
  integrity: boolean;
  availability: boolean;
  identify: boolean;
  protect: boolean;
  detect: boolean;
  respond: boolean;
  recover: boolean;
  attack_vector: string;
  attack_complexity: string;
  privileges_required: string;
  user_interaction: string;
  scope: string;
  confidentiality_level: string;
  integrity_level: string;
  availability_level: string;
  cost: number;
  technical_capacity: number;
  benefit: number;
  cost_benefit: string;
  final_benefit: string;
  subcontrols: Subcontrol[];
  is_active: boolean;
};

export type QuestionList = {
  id: string;
  name: string;
  question_type: string;
  is_active: boolean;
  has_evidence: boolean;  
  controls: number;
  risk_factors: number;
  vulnerabilities: number;
  departments: Department[];
};

export type QuestionListResults = {
  results: QuestionList[];
  count: number;
  next: boolean;
}

export type QuestionsState = {
  questions: Question[];
  loading: boolean;
  total: number,
  visited_pages: number[];
  has_next: boolean;
  currentQuestionDetail: Question;
  departments: Department[];
  controls: Control[];
  risk_factors: RiskFactor[];
  vulnerabilities: Vulnerability[];
  questionsList: QuestionList[];
};




