import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { history } from 'services/history';
import { EditCustomerStatus } from 'store/modules/customer/types';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import type { Action } from 'store/types';

import { vulnerabilitiesActionsFunctions } from '../vulnerabilities/actions';
import { controlActionsFunctions } from './actions';
import { apiControlRequestActionPayload, apiCreateNewControlRequestActionPayload, apiEditControlRegistrationsRequestActionPayload, ControlActions, ControlDetail, ControlEditBodyResponse, ControlEditRequestBody, ControlInfoUpdateStatus } from './types';


const {
  DEFAULT_ERROR_MESSAGE,
} = ToastMessages;

const {
  getControlDetailSuccess,
  getControlDetailFailure,
  createNewControlSuccess,
  createNewControlFailure,
  editControlRegistrationsRequest,
  editControlRegistrationsSuccess,
  editControlRegistrationsFailure,
  editControlSuccess,
  editControlFailure,
  editControlStatusSuccess,
  editControlStatusFailure
} = controlActionsFunctions;

const {
  getControlsRequest,
} = vulnerabilitiesActionsFunctions;

const {
  GET_CONTROL_DETAIL_REQUEST,
  CREATE_NEW_CONTROL_REQUEST,
  EDIT_CONTROL_REGISTRATION_REQUEST,
  EDIT_CONTROL_REQUEST,
  EDIT_CONTROL_STATUS_REQUEST
} = ControlActions;

const { getLastApiFetchDataSuccess, getLastApiFetchDataFailure } =
  lastApiFetchDataActionsFunctions;

function* fetchControlDetailRequest(action: Action): Generator {
  const { controlId } = action.payload as apiControlRequestActionPayload;
  try {
    const riskFactorsResponse: AxiosResponse<ControlDetail> | unknown =
      yield call(api, 'GET', `controls/controls/${controlId}/detail/`, {});

    const {
      data: control,
      config: { url },
      status,
      statusText,
    } = riskFactorsResponse as AxiosResponse<ControlDetail>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getControlDetailSuccess(control));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getControlDetailFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
        DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchEditControlStatus(action: Action): Generator {
  const { controlId, controlStatus } = action.payload as ControlInfoUpdateStatus;
  try {
    const editControlStatus: AxiosResponse<EditCustomerStatus> | unknown = yield call(
      api,
      'PUT',
      `/controls/controls/${controlId}/update-status/`,
      {
        is_active: controlStatus
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = editControlStatus as AxiosResponse<EditCustomerStatus>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editControlStatusSuccess());
    toast.success('Status editado com Sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editControlStatusFailure());

    toast.error('Falha ao editar status.');
  }
}

function* fetchCreateNewControlRequest(action: Action): Generator {
  const { controlBody } = action.payload as apiCreateNewControlRequestActionPayload;
  try {
    const riskFactorsResponse: AxiosResponse<ControlDetail> | unknown =
      yield call(api, 'POST', 'controls/controls/create/', {
        ...controlBody
      });

    const {
      data: control,
      config: { url },
      status,
      statusText,
    } = riskFactorsResponse as AxiosResponse<ControlDetail>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(createNewControlSuccess());
    history.push(`/control-registration/${control.id}`);
    toast.success('Controle criado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(createNewControlFailure());

    if(currentError?.response?.data?.name[0] === 'control with this name already exists.') {
      toast.warn('Já existe um controle com este nome.');      
    } else {
      toast.error(
        currentError?.response?.data?.messages[0]?.message ??
          DEFAULT_ERROR_MESSAGE
      );
    }    
  }
}

function* fetchEditControlRegistrationRequest(action: Action): Generator {
  const { controlId, controlEditBody } = action.payload as apiEditControlRegistrationsRequestActionPayload;
  try {
    const riskFactorsResponse: AxiosResponse<ControlEditBodyResponse> | unknown =
      yield call(api, 'PUT', `controls/controls/${controlId}/update-links/`, {
        ...controlEditBody
      });

    const {
      data: control,
      config: { url },
      status,
      statusText,
    } = riskFactorsResponse as AxiosResponse<ControlEditBodyResponse>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editControlRegistrationsSuccess());
    // history.push('/controls');
    history.push(`/control-visualization/${controlId}/`);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editControlRegistrationsFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
        DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchEditControlRequest(action: Action): Generator {
  const { controlId, controlEditBody, controlEditRegistrationBody } = action.payload as apiEditControlRegistrationsRequestActionPayload;
  try {
    const controlEditRequestResponse: AxiosResponse<ControlEditRequestBody> | unknown =
      yield call(api, 'PUT', `controls/controls/${controlId}/`, {
        ...controlEditBody
      });

    const {
      data: control,
      config: { url },
      status,
      statusText,
    } = controlEditRequestResponse as AxiosResponse<ControlEditRequestBody>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editControlSuccess());
    yield put(editControlRegistrationsRequest(controlId, controlEditRegistrationBody));
    history.push(`/control-visualization/${controlId}/`);
    toast.success('Vínculos registrados com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(editControlFailure());
    yield put(editControlRegistrationsFailure());
    history.push('/controls');
    toast.error('Não foi possível editar esse controle.');
  }
}

export function* controlSagas() {
  yield all([
    takeLatest(GET_CONTROL_DETAIL_REQUEST, fetchControlDetailRequest),
    takeLatest(CREATE_NEW_CONTROL_REQUEST, fetchCreateNewControlRequest),
    takeLatest(EDIT_CONTROL_REGISTRATION_REQUEST, fetchEditControlRegistrationRequest),
    takeLatest(EDIT_CONTROL_REQUEST, fetchEditControlRequest),
    takeLatest(EDIT_CONTROL_STATUS_REQUEST, fetchEditControlStatus),
  ]);
}
