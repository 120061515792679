import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { history } from 'services/history';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import { riskFactorsActionsFunctions } from 'store/modules/Manutention/riskFactors/actions';
import { vulnerabilitiesActionsFunctions } from 'store/modules/Manutention/vulnerabilities/actions';
import type { Action } from 'store/types';
import { checkPage } from 'utils/number';

import type {
  EditRiskFactorRequestPayload,
  SearchFilteredRiskFactorRequestPayload,
  UpdateRiskFactorStatusRequestPayload,
  RiskFactor,
  RiskFactorDetailRequestPayload,
  AddNewRiskFactorRequestPayload,
  EditRiskFactorData,
  RiskFactorResults,
  RiskFactorStaticResults,
} from './types';
import { RiskFactorsActions } from './types';

const {
  DEFAULT_ERROR_MESSAGE,
  NEW_RISK_FACTOR_ADDED_SUCCESS_MESSAGE,
  NEW_RISK_FACTOR_ADDED_ERROR_MESSAGE,
  SEARCH_FILTERED_RISK_FACTOR_SUCCESS_MESSAGE,
  SEARCH_FILTERED_RISK_FACTOR_ERROR_MESSAGE,
  EDIT_RISK_FACTOR_SUCCESS_MESSAGE,
  EDIT_RISK_FACTOR_ERROR_MESSAGE,
  ACTIVE_OR_INACTIVE_RISK_FACTOR_SUCCESS_MESSAGE,
  ACTIVE_OR_INACTIVE_RISK_FACTOR_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE
} = ToastMessages;

const {
  GET_ALL_RISK_FACTORS_REQUEST,
  GET_FILTERED_RISK_FACTOR_REQUEST,
  GET_RISK_FACTOR_DETAIL_REQUEST,
  ADD_NEW_RISK_FACTOR_REQUEST,
  EDIT_RISK_FACTOR_REQUEST,
  ACTIVE_OR_INACTIVE_RISK_FACTOR_REQUEST,
  GET_ALL_RISK_FACTORS_STATIC_REQUEST,
  GET_FILTERED_RISK_STATIC_FACTOR_REQUEST,
} = RiskFactorsActions;

const {
  getRiskFactorsRequest,
  getRiskFactorsSuccess,
  getRiskFactorsFailure,
  getSearchFilteredRiskFactorSuccess,
  getSearchFilteredRiskFactorFailure,
  getRiskFactorDetailSuccess,
  getRiskFactorDetailFailure,
  addNewRiskFactorSuccess,
  addNewRiskFactorFailure,
  editRiskFactorSuccess,
  editRiskFactorFailure,
  activeOrInactiveRiskFactorSuccess,
  activeOrInactiveRiskFactorFailure,
  resetTheRiskFactorDetailState,
  getRiskFactorStaticSuccess,
  getRiskFactorStaticFailure,
  getSearchFilteredRiskFactorStaticSuccess,
  getSearchFilteredRiskFactorStaticFailure,
} = riskFactorsActionsFunctions;

const { getCVEsRequest, getThreatsRequest, getControlsRequest } =
  vulnerabilitiesActionsFunctions;

const { getLastApiFetchDataSuccess, getLastApiFetchDataFailure } =
  lastApiFetchDataActionsFunctions;

// Assimque possível remover esse código
function* fetchAllRiskFactors(action: Action): Generator {
  try {
    const { payload } = action as any;
    const total: number = (yield select(state => state.riskFactors.total)) as number;
    const last_page = checkPage(payload.last_page, total);
    const path = `controls/risk-factors/?page=${last_page}`;
    const has_next = yield select(state => state.riskFactors.has_next);

    if (has_next || last_page === 1) {
      const riskFactorsResponse: AxiosResponse<RiskFactorResults> | unknown =
        yield call(api, 'GET', path, {});

      const {
        data: { results, count, next },
        config: { url },
        status,
        statusText,
      } = riskFactorsResponse as AxiosResponse<RiskFactorResults>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      yield put(getRiskFactorsSuccess(results, count, last_page, !!next));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getRiskFactorsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}
// Assimque possível remover esse código
function* fetchSearchFilteredRiskFactor(action: Action): Generator {
  const { filteredRiskFactors } =
    action.payload as SearchFilteredRiskFactorRequestPayload;

  const hasFilteredOptionsWithoutStatus = filteredRiskFactors
    .filter(response => response !== null && typeof response !== 'boolean')
    .join(',');

  const hasStatusFilteredOption = filteredRiskFactors.some(
    option => typeof option === 'boolean'
  );

  const filteredOptionStatus = `?is_active=${filteredRiskFactors.filter(
    response => typeof response === 'boolean'
  )}`;

  const filteredOptionsWithoutStatus = `?search=${filteredRiskFactors
    .filter(response => response !== null && typeof response !== 'boolean')
    .join(',')}`;

  const routeWithFilter = `controls/risk-factors/${hasFilteredOptionsWithoutStatus ? filteredOptionsWithoutStatus : ''
    }${hasStatusFilteredOption ? filteredOptionStatus : ''}`;

  try {
    const riskFactorsResponse: AxiosResponse<RiskFactorResults> | unknown =
      yield call(api, 'GET', routeWithFilter, {});

    const {
      data: riskFactors,
      config: { url },
      status,
      statusText,
    } = riskFactorsResponse as AxiosResponse<RiskFactorResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredRiskFactorSuccess(riskFactors.results));

    toast.success(SEARCH_FILTERED_RISK_FACTOR_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredRiskFactorFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      SEARCH_FILTERED_RISK_FACTOR_ERROR_MESSAGE
    );
  }
}

function* fetchAddNewRiskFactor(action: Action): Generator {
  const { newRiskFactor } = action.payload as AddNewRiskFactorRequestPayload;

  try {
    const addNewRiskFactorResponse: AxiosResponse<RiskFactor> | unknown =
      yield call(api, 'POST', 'controls/risk-factors/create/', {
        ...newRiskFactor,
      });

    const {
      data: riskFactorAdded,
      config: { url },
      status,
      statusText,
    } = addNewRiskFactorResponse as AxiosResponse<RiskFactor>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(addNewRiskFactorSuccess(riskFactorAdded));

    history.push(`/risk-factor-registration/${riskFactorAdded.id}`);

    yield put(getRiskFactorsRequest());
    yield put(getCVEsRequest());
    yield put(getThreatsRequest());
    yield put(getControlsRequest());

    toast.success(NEW_RISK_FACTOR_ADDED_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(resetTheRiskFactorDetailState());
    yield put(addNewRiskFactorFailure());

    if (currentError?.response?.data?.name[0] === 'risk factor with this name already exists.') {
      toast.warn('Já existe um fator de risco com este nome.');
    } else {
      toast.error(
        currentError?.response?.data?.email[0] ?? currentError?.response?.data?.detail ?? NEW_RISK_FACTOR_ADDED_ERROR_MESSAGE
      );
    }
  }
}

function* fetchRiskFactorDetail(action: Action): Generator {
  const { riskFactorId } = action.payload as RiskFactorDetailRequestPayload;

  try {
    const riskFactorDetailResponse: AxiosResponse<RiskFactor> | unknown =
      yield call(
        api,
        'GET',
        `controls/risk-factors/${riskFactorId}/detail/`,
        {}
      );

    const {
      data: riskFactorDetail,
      config: { url },
      status,
      statusText,
    } = riskFactorDetailResponse as AxiosResponse<RiskFactor>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getRiskFactorDetailSuccess(riskFactorDetail));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(resetTheRiskFactorDetailState());
    yield put(getRiskFactorDetailFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
      DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchToRiskFactorEditing(action: Action): Generator {
  const { updatedRiskFactor } = action.payload as EditRiskFactorRequestPayload;

  try {
    const riskFactorEditingResponse:
      | AxiosResponse<EditRiskFactorData>
      | unknown = yield call(
        api,
        'PUT',
        `controls/risk-factors/${updatedRiskFactor.id}/`,
        {
          ...updatedRiskFactor,
        }
      );

    const {
      config: { url },
      status,
      statusText,
    } = riskFactorEditingResponse as AxiosResponse<EditRiskFactorData>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editRiskFactorSuccess());
    yield put(getRiskFactorsRequest());
    yield put(getCVEsRequest());
    yield put(getThreatsRequest());
    yield put(getControlsRequest());

    toast.success(EDIT_RISK_FACTOR_SUCCESS_MESSAGE);

    history.push(`/risk-factor-visualization/${updatedRiskFactor.id}`);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(resetTheRiskFactorDetailState());
    yield put(editRiskFactorFailure());

    toast.error(
      currentError?.response?.data?.detail ?? EDIT_RISK_FACTOR_ERROR_MESSAGE
    );
  }
}

function* fetchToUpdateRiskFactorStatus(action: Action): Generator {
  const { riskFactorId, newRiskFactorStatus } =
    action.payload as UpdateRiskFactorStatusRequestPayload;

  try {
    const updateRiskFactorResponse:
      | AxiosResponse<{ is_active: boolean }>
      | unknown = yield call(
        api,
        'PUT',
        `controls/risk-factors/${riskFactorId}/update-status/`,
        {
          is_active: newRiskFactorStatus,
        }
      );

    const {
      config: { url },
      status,
      statusText,
    } = updateRiskFactorResponse as AxiosResponse<{ is_active: boolean }>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(activeOrInactiveRiskFactorSuccess());
    yield put(getRiskFactorsRequest());
    yield put(getCVEsRequest());
    yield put(getThreatsRequest());
    yield put(getControlsRequest());

    toast.success(ACTIVE_OR_INACTIVE_RISK_FACTOR_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(activeOrInactiveRiskFactorFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      ACTIVE_OR_INACTIVE_RISK_FACTOR_ERROR_MESSAGE
    );
  }
}

function* fetchAllRiskFactorStatic(action: Action): Generator {
  try {
    const { payload } = action as any;
    const total: number = (yield select(state => state.riskFactors.total)) as number;
    const last_page = checkPage(payload.last_page, total);
    const path = `controls/risk-factors/?page=${last_page}`;
    const has_next = yield select(state => state.riskFactors.has_next);

    if (has_next || last_page === 1) {
      const riskFactorsResponse: AxiosResponse<RiskFactorStaticResults> | unknown =
        yield call(api, 'GET', path, {});

      const {
        data: { results, count, next },
        config: { url },
        status,
        statusText,
      } = riskFactorsResponse as AxiosResponse<RiskFactorStaticResults>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      yield put(getRiskFactorStaticSuccess(results, count, last_page, !!next));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getRiskFactorStaticFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchSearchFilteredRiskFactorStatic(action: Action): Generator {
  const { filteredRiskFactors } =
    action.payload as SearchFilteredRiskFactorRequestPayload;

  const hasFilteredOptionsWithoutStatus = filteredRiskFactors
    .filter(response => response !== null && typeof response !== 'boolean')
    .join(',');

  const hasStatusFilteredOption = filteredRiskFactors.some(
    option => typeof option === 'boolean'
  );

  const filteredOptionStatus = `?is_active=${filteredRiskFactors.filter(
    response => typeof response === 'boolean'
  )}`;

  const filteredOptionsWithoutStatus = `?search=${filteredRiskFactors
    .filter(response => response !== null && typeof response !== 'boolean')
    .join(',')}`;

  const routeWithFilter = `controls/risk-factors/${hasFilteredOptionsWithoutStatus ? filteredOptionsWithoutStatus : ''
    }${hasStatusFilteredOption ? filteredOptionStatus : ''}`;

  try {
    const riskFactorsResponse: AxiosResponse<RiskFactorStaticResults> | unknown = yield call(api, 'GET', routeWithFilter, {});

    const {
      data: riskFactorStatic,
      config: { url },
      status,
      statusText,
    } = riskFactorsResponse as AxiosResponse<RiskFactorStaticResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredRiskFactorStaticSuccess(riskFactorStatic.results));
    toast.success(SEARCH_FILTERED_RISK_FACTOR_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredRiskFactorStaticFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      SEARCH_FILTERED_RISK_FACTOR_ERROR_MESSAGE
    );
  }
}

export function* riskFactorsSagas() {
  yield all([
    takeEvery(GET_ALL_RISK_FACTORS_REQUEST, fetchAllRiskFactors),
    takeLatest(GET_FILTERED_RISK_FACTOR_REQUEST, fetchSearchFilteredRiskFactor),

    takeLatest(GET_ALL_RISK_FACTORS_STATIC_REQUEST, fetchAllRiskFactorStatic),
    takeLatest(GET_RISK_FACTOR_DETAIL_REQUEST, fetchRiskFactorDetail),
    takeLatest(ADD_NEW_RISK_FACTOR_REQUEST, fetchAddNewRiskFactor),
    takeLatest(EDIT_RISK_FACTOR_REQUEST, fetchToRiskFactorEditing),
    takeLatest(ACTIVE_OR_INACTIVE_RISK_FACTOR_REQUEST, fetchToUpdateRiskFactorStatus),
    takeLatest(GET_FILTERED_RISK_STATIC_FACTOR_REQUEST, fetchSearchFilteredRiskFactorStatic),
  ]);
}
