import produce from 'immer';
import { Reducer } from 'redux';

import { LinkedControlsType, ThreatsActions, ThreatState } from './types';


const {
  GET_ALL_THREATS_MODULE_REQUEST,
  GET_ALL_THREATS_MODULE_SUCCESS,
  GET_ALL_THREATS_MODULE_FAILURE,
  GET_THREATS_DETAILS_REQUEST,
  GET_THREATS_DETAILS_SUCCESS,
  GET_THREATS_DETAILS_FAILURE,
  CREATE_NEW_THREAT_REQUEST,
  CREATE_NEW_THREAT_SUCCESS,
  CREATE_NEW_THREAT_FAILURE,
  EDIT_THREAT_REQUEST,
  EDIT_THREAT_SUCCESS,
  EDIT_THREAT_FAILURE,
  ACTIVE_OR_INACTIVE_THREAT_REQUEST,
  ACTIVE_OR_INACTIVE_THREAT_SUCCESS,
  ACTIVE_OR_INACTIVE_THREAT_FAILURE,
  GET_SEARCH_FILTER_THREAT_REQUEST,
  GET_SEARCH_FILTER_THREAT_SUCCESS,
  GET_SEARCH_FILTER_THREAT_FAILURE,
  UPDATE_RISK_REQUEST,
  UPDATE_RISK_SUCCESS,
  UPDATE_RISK_FAILURE,
  GET_LINKED_CONTROLS_REQUEST,
  GET_LINKED_CONTROLS_SUCCESS,
  GET_LINKED_CONTROLS_FAILURE,
  GET_ALL_SIMPLE_THREATS_MODULE_REQUEST,
  GET_ALL_SIMPLE_THREATS_MODULE_SUCCESS,
  GET_ALL_SIMPLE_THREATS_MODULE_FAILURE,
  GET_SEARCH_FILTER_SIMPLE_THREAT_REQUEST,
  GET_SEARCH_FILTER_SIMPLE_THREAT_SUCCESS,
  GET_SEARCH_FILTER_SIMPLE_THREAT_FAILURE,
} = ThreatsActions;

const initialState: ThreatState = {
  allThreats: [],
  visited_pages: [],
  has_next: true,
  total: 0,
  threatDetail: {
    id: '',
    friendly_id: '',
    name: '',
    description: '',
    is_active: false,
    registration_status: '',
    vulnerabilities: [],
    risk_factors: [],
    threats: [],
    damages: [],
    controls: [],
    risks: [],
    questionnaires_origin: [],
    checklists_origin: []
  },
  linkedControls: {} as LinkedControlsType,
  simpleThreat: [],
};

export const threatsReducer: Reducer<ThreatState> = (
  state = initialState,
  action
) => {
  // eslint-disable-next-line consistent-return
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_THREATS_MODULE_REQUEST: {
        break;
      }

      case GET_ALL_THREATS_MODULE_SUCCESS: {
        const { allThreat, total, last_page, has_next } = action.payload;
        const pagineAlreadyVisited = state.visited_pages.find (page => page === last_page);

        if(!pagineAlreadyVisited || draft.allThreats) {
          draft.visited_pages.push(last_page);
          draft.allThreats = last_page === 1 ? allThreat : [...state.allThreats, ...allThreat];
          draft.total = total;
          draft.has_next = has_next;
        }
        break;
      }

      case GET_ALL_THREATS_MODULE_FAILURE: {
        draft.allThreats = [];
        break;
      }

      case GET_LINKED_CONTROLS_REQUEST: {
        break;
      }

      case GET_LINKED_CONTROLS_SUCCESS: {
        const { linkedControls } = action.payload;

        draft.linkedControls = linkedControls;
        break;
      }

      case GET_LINKED_CONTROLS_FAILURE: {
        break;
      }


      case GET_THREATS_DETAILS_REQUEST: {
        break;
      }

      case GET_THREATS_DETAILS_SUCCESS: {
        const { threatDetail } = action.payload;

        draft.threatDetail = threatDetail;

        break;
      }

      case GET_THREATS_DETAILS_FAILURE: {
        draft.threatDetail = {
          id: '',
          friendly_id: '',
          name: '',
          description: '',
          is_active: false,
          registration_status: '',
          vulnerabilities: [],
          risk_factors: [],
          threats: [],
          damages: [],
          controls: [],
          risks: [],
          questionnaires_origin: [],
          checklists_origin: []
        };
        break;
      }

      case CREATE_NEW_THREAT_REQUEST: {
        break;
      }

      case CREATE_NEW_THREAT_SUCCESS: {
        break;
      }

      case CREATE_NEW_THREAT_FAILURE: {
        break;
      }

      case EDIT_THREAT_REQUEST: {
        break;
      }

      case EDIT_THREAT_SUCCESS: {
        break;
      }

      case EDIT_THREAT_FAILURE: {
        break;
      }

      case UPDATE_RISK_REQUEST: {
        break;
      }

      case UPDATE_RISK_SUCCESS: {
        break;
      }

      case UPDATE_RISK_FAILURE: {
        break;
      }

      case ACTIVE_OR_INACTIVE_THREAT_REQUEST: {
        break;
      }

      case ACTIVE_OR_INACTIVE_THREAT_SUCCESS: {
        break;
      }

      case ACTIVE_OR_INACTIVE_THREAT_FAILURE: {
        break;
      }

      case GET_SEARCH_FILTER_THREAT_REQUEST: {
        break;
      }

      case GET_SEARCH_FILTER_THREAT_SUCCESS: {
        const { threat } = action.payload;

        draft.allThreats = threat;
        break;
      }

      case GET_SEARCH_FILTER_THREAT_FAILURE: {
        break;
      }

      case GET_ALL_SIMPLE_THREATS_MODULE_REQUEST: {
        break;
      }

      case GET_ALL_SIMPLE_THREATS_MODULE_SUCCESS: {
        const { simpleThreat, total, last_page, has_next } = action.payload;
        const pagineAlreadyVisited = state.visited_pages.find (page => page === last_page);

        if(!pagineAlreadyVisited || draft.simpleThreat) {
          draft.visited_pages.push(last_page);
          draft.simpleThreat = last_page === 1 ? simpleThreat : [...state.simpleThreat, ...simpleThreat];
          draft.total = total;
          draft.has_next = has_next;
        }
        break;
      }

      case GET_ALL_SIMPLE_THREATS_MODULE_FAILURE: {
        draft.simpleThreat = [];
        break;
      }

      case GET_SEARCH_FILTER_SIMPLE_THREAT_REQUEST: {
        break;
      }

      case GET_SEARCH_FILTER_SIMPLE_THREAT_SUCCESS: {
        const { simpleThreat } = action.payload;
        draft.simpleThreat = simpleThreat;
        break;
      }

      case GET_SEARCH_FILTER_SIMPLE_THREAT_FAILURE: {
        break;
      }

      default: {
        return draft;
      }
    }
  });
};
