import type { ChangePasswordFormData } from 'pages/Profile/ModalChangePassword/types';

export enum AuthStateActions {
  USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST',
  USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS',
  USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE',
  CURRENT_USER_DATA_REQUEST = 'CURRENT_USER_DATA_REQUEST',
  CURRENT_USER_DATA_SUCCESS = 'CURRENT_USER_DATA_SUCCESS',
  CURRENT_USER_DATA_FAILURE = 'CURRENT_USER_DATA_FAILURE',
  USER_CREATE_TOTP_DEVICE_REQUEST = 'USER_CREATE_TOTP_DEVICE_REQUEST',
  USER_CREATE_TOTP_DEVICE_SUCCESS = 'USER_CREATE_TOTP_DEVICE_SUCCESS',
  USER_CREATE_TOTP_DEVICE_FAILURE = 'USER_CREATE_TOTP_DEVICE_FAILURE',
  USER_VERIFY_OR_ENABLE_USER_TOTP_DEVICE_REQUEST = 'USER_VERIFY_OR_ENABLE_USER_TOTP_DEVICE_REQUEST',
  USER_VERIFY_OR_ENABLE_USER_TOTP_DEVICE_SUCCESS = 'USER_VERIFY_OR_ENABLE_USER_TOTP_DEVICE_SUCCESS',
  USER_VERIFY_OR_ENABLE_USER_TOTP_DEVICE_FAILURE = 'USER_VERIFY_OR_ENABLE_USER_TOTP_DEVICE_FAILURE',
  USER_AUTH_TOTP_DEVICE_REQUEST = 'USER_AUTH_TOTP_DEVICE_REQUEST',
  USER_AUTH_TOTP_DEVICE_SUCCESS = 'USER_AUTH_TOTP_DEVICE_SUCCESS',
  USER_AUTH_TOTP_DEVICE_FAILURE = 'USER_AUTH_TOTP_DEVICE_FAILURE',
  USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST',
  USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS',
  USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE',
  USER_FORGOT_PASSWORD_REQUEST = 'USER_FORGOT_PASSWORD_REQUEST',
  USER_FORGOT_PASSWORD_SUCCESS = 'USER_FORGOT_PASSWORD_SUCCESS',
  USER_FORGOT_PASSWORD_FAILURE = 'USER_FORGOT_PASSWORD_FAILURE',
  USER_RESET_PASSWORD_REQUEST = 'USER_RESET_PASSWORD_REQUEST',
  USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS',
  USER_RESET_PASSWORD_FAILURE = 'USER_RESET_PASSWORD_FAILURE',
  USER_REGISTER_PASSWORD_REQUEST = 'USER_REGISTER_PASSWORD_REQUEST',
  USER_REGISTER_PASSWORD_SUCCESS = 'USER_REGISTER_PASSWORD_SUCCESS',
  USER_REGISTER_PASSWORD_FAILURE = 'USER_REGISTER_PASSWORD_FAILURE',
  USER_TOKEN_VERIFY_REQUEST = 'USER_TOKEN_VERIFY_REQUEST',
  USER_TOKEN_VERIFY_SUCCESS = 'USER_TOKEN_VERIFY_SUCCESS',
  USER_TOKEN_VERIFY_FAILURE = 'USER_TOKEN_VERIFY_FAILURE',
  UPDATE_USER_PICTURE_REQUEST = 'UPDATE_USER_PICTURE_REQUEST',
  UPDATE_USER_PICTURE_SUCCESS = 'UPDATE_USER_PICTURE_SUCCESS',
  UPDATE_USER_PICTURE_FAILURE = 'UPDATE_USER_PICTURE_FAILURE',
  DELETE_USER_PICTURE_REQUEST = 'DELETE_USER_PICTURE_REQUEST',
  DELETE_USER_PICTURE_SUCCESS = 'DELETE_USER_PICTURE_SUCCESS',
  DELETE_USER_PICTURE_FAILURE = 'DELETE_USER_PICTURE_FAILURE',
  USER_CHANGE_PASSWORD_REQUEST = 'USER_CHANGE_PASSWORD_REQUEST',
  USER_CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD_SUCCESS',
  USER_CHANGE_PASSWORD_FAILURE = 'USER_CHANGE_PASSWORD_FAILURE',
  RESET_THE_USERS_LAST_EMAIL_ON_THE_FORGOT_PASSWORD_SCREEN = 'RESET_THE_USERS_LAST_EMAIL_ON_THE_FORGOT_PASSWORD_SCREEN',
}

type ApiFetchTokenExpiredMessagesFieldResponse = {
  token_class: string;
  token_type: string;
  message: string;
};

export type ApiFetchTokenExpiredResponse = {
  detail: string;
  code: string;
  messages: ApiFetchTokenExpiredMessagesFieldResponse[];
};

export type UserLoginSuccessResponse = {
  access_token: string;
  refresh_token: string;
  access_token_lifetime: string;
  refresh_token_lifetime: string;
  user: string;
  user_id: string;
  email: string;
  phone: string;
  position: string;
  profile: string[];
  photo: string;
  device_totp: boolean;
  is_verified: boolean;
  is_customer: boolean;
  first_access: boolean;
};

export type CurrentUserDataSuccessRequest = {
  userId: string;
};

export type CurrentUserDataSuccessResponse = {
  id: string;
  document_number: string;
  corporate_name_or_name: string;
  fantasy_name: string;
  profile: string[];
  phone: string;
  position: string;
  email: string;
  number_of_days_password_expiration: number | Date;
  password_inclusion_date: string;
  status: true;
};

export type UserCreatedTotpDeviceSuccessResponse = {
  totp_url: string;
};

export type VerifiedOrEnabledUserTotpDeviceRequest = {
  totpToken: string;
  isCustomer: boolean;
};

export type VerifiedOrEnabledUserTotpDeviceSuccessResponse = {
  msg: string;
};

export type UserAuthTotpDeviceRequest = {
  totpToken: string;
  isCustomer: boolean | null;
};

export type UserAuthTotpDeviceSuccessResponse = {
  msg: string;
};

export type UserForgotPasswordSuccessResponse = {
  status: string;
};

export type UserResetPasswordSuccessResponse = {
  success: boolean;
  message: string;
};

export type UserResetPasswordSuccessRequest = {
  newPassword: string;
  confirmNewPassword: string;
  uuid: string;
  token: string;
};

export type UserRegisterPasswordSuccessRequest = {
  newPassword: string;
  confirmNewPassword: string;
  uuid: string;
  token: string;
};

export type UserRegisterPasswordSuccessResponse = {
  success: boolean;
  message: string;
};

export type UpdateUserPictureSuccessRequest = {
  userId: string;
  newPictureFormData: FormData;
};

export type UpdateUserPictureSuccessResponse = {
  // TODO - check response data type
  success: boolean;
  message: string;
};

export type DeleteUserPictureSuccessRequest = {
  userId: string;
};

export type DeleteUserPictureSuccessResponse = {
  // TODO - check response data type
  success: boolean;
  message: string;
};

export type ChangeUserPasswordSuccessRequest = {
  changePasswordData: ChangePasswordFormData;
};

export type ChangeUserPasswordSuccessResponse = {
  status: string;
  code: number;
  message: string;
  data: Array<unknown>;
};

export type ApiFetchLogoutRequestPayloadParams = {
  refreshToken: string | null;
};

export type User = {
  id?: string | null;
  email: string | null;
  password: string | null;
  accessToken: string | null;
  accessTokenLifetime: Date | string | null;
  refreshToken: string | null;
  refreshTokenLifetime: Date | string | null;
  username: string | null;
  phone: string | null;
  position: string | null;
  profile: string[] | null;
  photo: string | null;
};

export type UserAuthState = {
  id: string | null;
  isAuthenticated: boolean;
  isVerified: boolean | null;
  tokenStatus: 'invalid' | 'valid' | null;
  totpUrl: string | null;
  totpDevice: boolean | null;
  email: string | null;
  lastEmailOfForgotPassword: string | null;
  accessToken: string | null;
  accessTokenLifetime: Date | string | null;
  refreshToken: string | null;
  refreshTokenLifetime: Date | string | null;
  username: string | null;
  phone: string | null;
  position: string | null;
  profile: string[] | null;
  photo: string | null;
  expiredTokenData: ApiFetchTokenExpiredResponse;
  is_customer: boolean | null;
  first_access: boolean | null;
};

export type AuthState = {
  user: UserAuthState;
};
