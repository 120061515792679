import { answerActivity, IdAndSubject } from '../customerProject/types';
import { QuestionsBody } from '../Manutention/Risks/types';
import { IdAndName } from '../Manutention/vulnerabilities/types';
import { ObjectWithId } from '../threats/types';

export enum CustomerProjectRisksActions {
  GET_ALL_RISKS_CUSTOMER_PROJECT_REQUEST = 'GET_ALL_RISKS_CUSTOMER_PROJECT_REQUEST',
  GET_ALL_RISKS_CUSTOMER_PROJECT_SUCCESS = 'GET_ALL_RISKS_CUSTOMER_PROJECT_SUCCESS',
  GET_ALL_RISKS_CUSTOMER_PROJECT_FAILURE = 'GET_ALL_RISKS_CUSTOMER_PROJECT_FAILURE',
  GET_EXPORT_RISKS_CUSTOMER_PROJECT_REQUEST = 'GET_EXPORT_RISKS_CUSTOMER_PROJECT_REQUEST',
  GET_EXPORT_RISKS_CUSTOMER_PROJECT_SUCCESS = 'GET_EXPORT_RISKS_CUSTOMER_PROJECT_SUCCESS',
  GET_EXPORT_RISKS_CUSTOMER_PROJECT_FAILURE = 'GET_EXPORT_RISKS_CUSTOMER_PROJECT_FAILURE',
  GENERATE_RISKS_CUSTOMER_PROJECT_REQUEST = 'GENERATE_RISKS_CUSTOMER_PROJECT_REQUEST',
  GENERATE_RISKS_CUSTOMER_PROJECT_SUCCESS = 'GENERATE_RISKS_CUSTOMER_PROJECT_SUCCESS',
  GENERATE_RISKS_CUSTOMER_PROJECT_FAILURE = 'GENERATE_RISKS_CUSTOMER_PROJECT_FAILURE',
  GET_RISKS_CUSTOMER_PROJECT_DETAIL_REQUEST = 'GET_RISKS_CUSTOMER_PROJECT_DETAIL_REQUEST',
  GET_RISKS_CUSTOMER_PROJECT_DETAIL_SUCCESS = 'GET_RISKS_CUSTOMER_PROJECT_DETAIL_SUCCESS',
  GET_RISKS_CUSTOMER_PROJECT_DETAIL_FAILURE = 'GET_RISKS_CUSTOMER_PROJECT_DETAIL_FAILURE',
  EDIT_RISKS_CUSTOMER_PROJECT_STATUS_REQUEST = 'EDIT_RISKS_CUSTOMER_PROJECT_STATUS_REQUEST',
  EDIT_RISKS_CUSTOMER_PROJECT_STATUS_SUCCESS = 'EDIT_RISKS_CUSTOMER_PROJECT_STATUS_SUCCESS',
  EDIT_RISKS_CUSTOMER_PROJECT_STATUS_FAILURE = 'EDIT_RISKS_CUSTOMER_PROJECT_STATUS_FAILURE',
  EDIT_RISKS_CUSTOMER_PROJECT_QUESTIONS_ANSWER_REQUEST = 'EDIT_RISKS_CUSTOMER_PROJECT_QUESTIONS_ANSWER_REQUEST',
  EDIT_RISKS_CUSTOMER_PROJECT_QUESTIONS_ANSWER_SUCCESS = 'EDIT_RISKS_CUSTOMER_PROJECT_QUESTIONS_ANSWER_SUCCESS',
  EDIT_RISKS_CUSTOMER_PROJECT_QUESTIONS_ANSWER_FAILURE = 'EDIT_RISKS_CUSTOMER_PROJECT_QUESTIONS_ANSWER_FAILURE',
  EDIT_RISKS_CUSTOMER_PROJECT_REGISTRATION_REQUEST = 'EDIT_RISKS_CUSTOMER_PROJECT_REGISTRATION_REQUEST',
  EDIT_RISKS_CUSTOMER_PROJECT_REGISTRATION_SUCCESS = 'EDIT_RISKS_CUSTOMER_PROJECT_REGISTRATION_SUCCESS',
  EDIT_RISKS_CUSTOMER_PROJECT_REGISTRATION_FAILURE = 'EDIT_RISKS_CUSTOMER_PROJECT_REGISTRATION_FAILURE',
  EDIT_RISKS_CUSTOMER_PROJECT_RECOMMENDATION_REQUEST = 'EDIT_RISKS_CUSTOMER_PROJECT_RECOMMENDATION_REQUEST',
  EDIT_RISKS_CUSTOMER_PROJECT_RECOMMENDATION_SUCCESS = 'EDIT_RISKS_CUSTOMER_PROJECT_RECOMMENDATION_SUCCESS',
  EDIT_RISKS_CUSTOMER_PROJECT_RECOMMENDATION_FAILURE = 'EDIT_RISKS_CUSTOMER_PROJECT_RECOMMENDATION_FAILURE',
  DELETE_RISK_CUSTOMER_PROJECT_EVIDENCES_REQUEST = 'DELETE_RISK_CUSTOMER_PROJECT_EVIDENCES_REQUEST',
  DELETE_RISK_CUSTOMER_PROJECT_EVIDENCES_SUCCESS = 'DELETE_RISK_CUSTOMER_PROJECT_EVIDENCES_SUCCESS',
  DELETE_RISK_CUSTOMER_PROJECT_EVIDENCES_FAILURE = 'DELETE_RISK_CUSTOMER_PROJECT_EVIDENCES_FAILURE',
  GET_RISK_CUSTOMER_PROJECT_EVIDENCES_DOWNLOAD_REQUEST = 'GET_RISK_CUSTOMER_PROJECT_EVIDENCES_DOWNLOAD_REQUEST',
  GET_RISK_CUSTOMER_PROJECT_EVIDENCES_DOWNLOAD_SUCCESS = 'GET_RISK_CUSTOMER_PROJECT_EVIDENCES_DOWNLOAD_SUCCESS',
  GET_RISK_CUSTOMER_PROJECT_EVIDENCES_DOWNLOAD_FAILURE = 'GET_RISK_CUSTOMER_PROJECT_EVIDENCES_DOWNLOAD_FAILURE',
  GET_ALL_RISKS_MATRIX_CUSTOMER_PROJECT_REQUEST = 'GET_ALL_RISKS_MATRIX_CUSTOMER_PROJECT_REQUEST',
  GET_ALL_RISKS_MATRIX_CUSTOMER_PROJECT_SUCCESS = 'GET_ALL_RISKS_MATRIX_CUSTOMER_PROJECT_SUCCESS',
  GET_ALL_RISKS_MATRIX_CUSTOMER_PROJECT_FAILURE = 'GET_ALL_RISKS_MATRIX_CUSTOMER_PROJECT_FAILURE',
  EDIT_EVIDENCE_NAME_REQUEST = 'EDIT_EVIDENCE_NAME_REQUEST',
  EDIT_EVIDENCE_NAME_SUCCESS = 'EDIT_EVIDENCE_NAME_SUCCESS',
  EDIT_EVIDENCE_NAME_FAILURE = 'EDIT_EVIDENCE_NAME_FAILURE',
  ALTER_POSITION_EVIDENCE_REQUEST = 'ALTER_POSITION_EVIDENCE_REQUEST',
  ALTER_POSITION_EVIDENCE_SUCCESS = 'ALTER_POSITION_EVIDENCE_SUCCESS',
  ALTER_POSITION_EVIDENCE_FAILURE = 'ALTER_POSITION_EVIDENCE_FAILURE',
  GET_ALL_EVIDENCES_REQUEST = 'GET_ALL_EVIDENCES_REQUEST',
  GET_ALL_EVIDENCES_SUCCESS = 'GET_ALL_EVIDENCES_SUCCESS',
  GET_ALL_EVIDENCES_FAILURE = 'GET_ALL_EVIDENCES_FAILURE',
  ALTER_STATUS_EVIDENCE_REQUEST = 'ALTER_STATUS_EVIDENCE_REQUEST',
  ALTER_STATUS_EVIDENCE_SUCCESS = 'ALTER_STATUS_EVIDENCE_SUCCESS',
  ALTER_STATUS_EVIDENCE_FAILURE = 'ALTER_STATUS_EVIDENCE_FAILURE',
  CONFIRM_CLIENT_DATA_REQUEST  = 'CONFIRM_CLIENT_DATA_REQUEST',
  CONFIRM_CLIENT_DATA_SUCCESS  = 'CONFIRM_CLIENT_DATA_SUCCESS',
  CONFIRM_CLIENT_DATA_FAILURE  = 'CONFIRM_CLIENT_DATA_FAILURE',
}

export type EvidencesBody = {
  evidence_name: string
  evidence_url: string
  id: string
}

export type DeleteEvidencesResponse = {
  evidences: EvidencesBody[]
  friendly_id: string
  id: string
  name: string
}

export type ChangeStatusBodyType = {
  justification_of_inactivation: string,
  is_active: boolean
}

export type CustomerProjetcChangeStatusApiPayload = {
  riskId: string;
  changeStatusBody: ChangeStatusBodyType;
  customerProjectId: string;
}

export type QuestionsAnswerBodyType = {
  comment: string,
  skill_level: string,
  motive: string,
  opportunity: string,
  size: string,
  ease_of_discovery: string,
  ease_of_exploit: string,
  awareness: string,
  instrusion_detection: string,
  loss_of_confidentiality: string,
  loss_of_integrity: string,
  loff_of_availability: string,
  loff_of_accountability: string,
  financial_damage: string,
  reputation_damage: string,
  non_compliance: string,
  privacy_violation: string
}

export type CustomerProjetcRiskQuestionsApiPayload = {
  riskId: string;
  questionsAnswerBody: QuestionsAnswerBodyType;
}

export type CustomerProjetcRiskRegistrationsApiPayload = {
  riskId: string;
  riskRegistrationsBody: CustomerProjetcRiskRegistrationsBodyType;
  customerProjectId: string;
  disableChange: string;
}

export type CustomerProjetcRiskRegistrationsBodyType = {
  damages_add: ObjectWithId[],
  damages_remove: ObjectWithId[]
}

export type CustomerProjetcRiskRecommendationsApiPayload = {
  riskId: string;
  riskRecommendation: CustomerProjetcRiskRecommendationsBodyType;
  customerProjectId: string;
  disableChange: string;
}

export type DeleteCustomerProjetcRiskEvidencesApiPayload = {
  riskId: string;
  evidencesRemove: ObjectWithId[];
  customerProjectId?: string;
}

export type RiskMatrixCustomerProjetcApiPayload = {
  customerProjectId: string,
  customerName: string
}

export type CustomerProjectRiskStagesRecommendations = {
  type_recommendation: string,
  controls: ObjectWithId[]
}

export type CustomerProjectRiskStagesAdd = {
  id: string,
  name: string,
  risk_customer_project: string,
  recommendations: CustomerProjectRiskStagesRecommendations[]
}

export type CustomerProjectRiskRecommendationUpdate = {
  id: string,
  type_recommendation: string,
  controls_add: ObjectWithId[],
  controls_remove: ObjectWithId[]

}

export type CustomerProjectRiskStagesUpdate = {
  id: string,
  recommendations_add: CustomerProjectRiskStagesRecommendations[],
  recommendations_update: CustomerProjectRiskRecommendationUpdate[],
  recommendations_remove: ObjectWithId[]
}

export type CustomerProjetcRiskRecommendationsBodyType = {
  stages_add: CustomerProjectRiskStagesAdd[],
  stages_remove: ObjectWithId[],
  stages_update: CustomerProjectRiskStagesUpdate[]
}

export type CustomerProjectRiskDamages = {
  id: string,
  name: string,
  type: string
}

export type CustomerProjectRiskStagesRecommendationControlsCategory = {
  id: string,
  name: string,
  importance: number,
  description: string
}

export type CustomerProjectRiskStagesRecommendationControls = {
  id: string,
  name: string,
  control_type: IdAndName,
  class_name: IdAndName,
  group_name: IdAndName,
  type_name: IdAndName,
  category: CustomerProjectRiskStagesRecommendationControlsCategory,
  final_benefit: number,
  vulnerabilities: [],
  risk_factors: [],
  threats: []
}

export type CustomerProjectRiskStagesRecommendation = {
  id: string,
  type: IdAndName,
}


export type CustomerProjectRiskStages = {
  controls: CustomerProjectRiskStagesRecommendationControls[]
  id: string,
  name: string,
  recommendation: CustomerProjectRiskStagesRecommendation,
  total_benefit_controls_stage: number,
  stage_percentage: number,
  stage_percentage_accumulated: number,
  residual_risk: string
}

export type QuestionnairesOrigin = {
  description: string,
  id: string,
  name: string,
  type_of_questionnaire: string
}

export type CustomerProjectRiskThreats = {
  description: string
  id: string
  name: string
  risk_factors: []
  vulnerabilities: []
}

export type CustomerProjectRisk = {
  id: string,
  friendly_id: string,
  customer_project: string,
  is_active: boolean,
  name: string,
  description: string,
  comment: string | null,
  inherent_risk: string,
  stages: CustomerProjectRiskStages[],
  threats: CustomerProjectRiskThreats[],
  questionnaires_origin: QuestionnairesOrigin[],
  checklists_origin: IdAndSubject[],
  activities_origin: IdAndName[],
  evidences_count: number,
  in_revision?: boolean
}

export type CustomerProjetcIdApiPayload = {
  customerProjectId: string;
  isCustomer: boolean;
}

export type CustomerProjetcPDFApproveIdApiPayload = {
  customerProjectId: string;
  approveStatus: boolean
}

export type sendInterviewEmailApiPayload = {
  questionnaireCustomerProjectId: string
}

export type CustomerProjetcDetailApiPayload = {
  riskCustomerProjectId: string;
}

export type RiskCustomerProject = {
  id: string,
  name: string,
  is_active: boolean;
  project_status: string;
  last_update_risks_matrix: string;
  risks: CustomerProjectRisk[];
}

export type ActivitiesOriginType = {
  id: string,
  name: string,
  has_evidence: boolean,
}

export type CustomerProjectDamagesType = {
  id: string,
  name: string,
  type: string,
}

export type RiskDetailQuestionnaireOrigin = {
  id: string,
  name: string,
  type_of_questionnaire: string,
  description: string,
}

export type RiskCustomerProjectDetailStagesRecommendation = {
  total_final_benefit_controls: number,
  id: string,
  type: IdAndName,
}

export type RiskCustomerProjectDetailStages = {
  controls: IdAndName[],
  id: string,
  name: string,
  recommendation: RiskCustomerProjectDetailStagesRecommendation,
  residual_risk: string,
  stage_percentage: number,
  stage_percentage_accumulated: number,
  total_benefit_controls_stage: number,
}

export type ThreatRiskCustomerProject = {
  id: string,
  name: string,
  description: string,
  vulnerabilities: IdAndName[],
  risk_factors: IdAndName[]
}

export type RiskCustomerProjectDetail = {
  activities_origin: ActivitiesOriginType[],
  answers_origin: answerActivity[],
  awareness: QuestionsBody,
  checklists_origin: IdAndSubject[],
  controls_customer_project: IdAndName[],
  comment: null | string,
  damages: CustomerProjectDamagesType[],
  description: string,
  evidences: Evidences[],
  ease_of_discovery: QuestionsBody,
  ease_of_exploit: QuestionsBody,
  financial_damage: QuestionsBody,
  friendly_id: string,
  id: string,
  impact_category: string,
  impact_score: number
  inherent_risk: string,
  inherent_risk_score: number
  instrusion_detection: QuestionsBody,
  is_active: boolean,
  justification_of_inactivation: null | string,
  loff_of_accountability: QuestionsBody,
  loff_of_availability: QuestionsBody,
  loss_of_confidentiality: QuestionsBody,
  loss_of_integrity: QuestionsBody,
  motive: QuestionsBody,
  name: string
  non_compliance: QuestionsBody,
  opportunity: QuestionsBody,
  privacy_violation: QuestionsBody,
  probability_category: string
  probability_score: number
  questionnaires_origin: RiskDetailQuestionnaireOrigin[],
  questions_origin: IdAndName[],
  reputation_damage: QuestionsBody,
  size: QuestionsBody,
  skill_level: QuestionsBody,
  stages: RiskCustomerProjectDetailStages[]
  threats: IdAndName[],
  vulnerabilities: IdAndName[],
  risk_factors: IdAndName[]
}

export type ProjetcIdApiPayload = {
  projectId: string;
}

export type risk_matrix = {
  id: string;
  name: string;
  classification: string;
  questionnaires: string;
  checklists: string;
  activities: string;
  threats: string;
  recommendation: string;
  controls_of_first_stage_count: number;
  residual_risk: string;
  evidences_count: number;
  friendly_id: string;
  is_active: boolean;
}

export type RiskMatrixCustomerProject = {
  last_update_risks_matrix: string;
  risks_matrix: risk_matrix[];
}

export type EvidenceIdApiPayload = {
  evidenceId: string;
  evidenceName: string;
}

export type Evidences = {
  id: string;
  evidence_id: string;
  evidence_name: string;  
  position: number;
  is_active: boolean;  
  url: string;
}

export type EvidenceIdNameApiPayload = {
  evidence_id: string;
  evidence_name: string;
  riskId: string;
}

export type EvidenceAlterPositionApiPayload = {
  evidence_id: string;
  position: number;
  riskId: string;
}

export type RiskIdApiPayload = {
  risk_id: string;
  msg?: string;
}

export type EvidenceAlterStatusApiPayload = {
  riskId: string;
  typeStatus: string;
  evidences: any[];
}

export type CustomerProjectRiskState = {
  allRisksCustomerProjects: RiskCustomerProject;
  riskCustomerProjectDetail: RiskCustomerProjectDetail;
  riskMatrixCustomerProject: RiskMatrixCustomerProject;
  allEvidences: Evidences[];
}