import type { Action } from 'store/types';

import { 
  MarketAverageActions, 
  MarketAverageDetail, 
  MarketScoreCardAverage, 
  Sector 
} from './types';


const {
  GET_ALL_MARKET_AVERAGE_REQUEST,
  GET_ALL_MARKET_AVERAGE_SUCCESS,
  GET_ALL_MARKET_AVERAGE_FAILURE,
  GET_MARKET_SCORECARD_AVERAGE_REQUEST,
  GET_MARKET_SCORECARD_AVERAGE_SUCCESS,
  GET_MARKET_SCORECARD_AVERAGE_FAILURE,
  GET_MARKET_AVERAGE_DETAILS_REQUEST,
  GET_MARKET_AVERAGE_DETAILS_SUCCESS,
  GET_MARKET_AVERAGE_DETAILS_FAILURE,
} = MarketAverageActions;

function getMarketAverageSectorsRequest(): Action {
  return {
    type: GET_ALL_MARKET_AVERAGE_REQUEST,
  };
}

function getMarketAverageSectorsSuccess(
  results: Sector[],
  count?: number,
  next?: boolean,
  previous?: boolean,
): Action {
  return {
    type: GET_ALL_MARKET_AVERAGE_SUCCESS,
    payload: {
      results,
      count,
      next,
      previous
    },
  };
}

function getMarketAverageSectorsFailure(): Action {
  return {
    type: GET_ALL_MARKET_AVERAGE_FAILURE,
  };
}

function getMarketScoreCardAverageRequest(): Action {
  return {
    type: GET_MARKET_SCORECARD_AVERAGE_REQUEST,
  };
}

function getMarketScoreCardAverageSuccess(
  marketScoreCardAverage: MarketScoreCardAverage
): Action {
  return {
    type: GET_MARKET_SCORECARD_AVERAGE_SUCCESS,
    payload: {
      marketScoreCardAverage
    },
  };
}

function getMarketScoreCardAverageFailure(): Action {
  return {
    type: GET_MARKET_SCORECARD_AVERAGE_FAILURE,
  };
}

function getMarketAverageDetailRequest(
  id: string,
  type: string
): Action {
  return {
    type: GET_MARKET_AVERAGE_DETAILS_REQUEST,
    payload: {
      id,
      type
    }
  };
}

function getMarketAverageDetailSuccess(
  marketAverageDetail: MarketAverageDetail
): Action {
  return {
    type: GET_MARKET_AVERAGE_DETAILS_SUCCESS,
    payload: {
      marketAverageDetail
    },
  };
}

function getMarketAverageDetailFailure(): Action {
  return {
    type: GET_MARKET_AVERAGE_DETAILS_FAILURE,
  };
}

export const marketAverageActionsFunctions = {
  getMarketAverageSectorsRequest,
  getMarketAverageSectorsSuccess,
  getMarketAverageSectorsFailure,
  getMarketScoreCardAverageRequest,
  getMarketScoreCardAverageSuccess,
  getMarketScoreCardAverageFailure,
  getMarketAverageDetailRequest,
  getMarketAverageDetailSuccess,
  getMarketAverageDetailFailure,
};
