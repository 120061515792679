import type { Action } from 'store/types';

import type {
  AddNewQuestionData,
  AddNewQuestionResponseData,
  Control,
  Department,
  EditQuestion,
  Question,
  QuestionList,
  RiskFactor,
  Vulnerability
} from './types';
import { QuestionsActions } from './types';

const {
  ACTIVE_OR_INACTIVE_QUESTION_FAILURE,
  ACTIVE_OR_INACTIVE_QUESTION_SUCCESS,
  ACTIVE_OR_INACTIVE_QUESTION_REQUEST,
  ADD_NEW_QUESTION_FAILURE,
  ADD_NEW_QUESTION_REQUEST,
  ADD_NEW_QUESTION_SUCCESS,
  EDIT_QUESTION_FAILURE,
  EDIT_QUESTION_REQUEST,
  EDIT_QUESTION_ADD_ALTERNATIVE_REQUEST,
  EDIT_QUESTION_SUCCESS,
  GET_ALL_QUESTIONS_MODULE_FAILURE,
  GET_ALL_QUESTIONS_MODULE_REQUEST,
  GET_ALL_QUESTIONS_MODULE_SUCCESS,
  GET_QUESTION_DETAIL_FAILURE,
  GET_QUESTION_DETAIL_REQUEST,
  GET_QUESTION_DETAIL_SUCCESS,
  GET_FILTERED_QUESTION_FAILURE,
  GET_FILTERED_QUESTION_REQUEST,
  GET_FILTERED_QUESTION_SUCCESS,
  RESET_THE_QUESTION_DETAIL_STATE,
  GET_ALL_QUESTIONS_DEPARTMENTS_REQUEST,
  GET_ALL_QUESTIONS_DEPARTMENTS_SUCCESS,
  GET_ALL_QUESTIONS_DEPARTMENTS_FAILURE,
  GET_ALL_QUESTIONS_CONTROLS_FAILURE,
  GET_ALL_QUESTIONS_CONTROLS_REQUEST,
  GET_ALL_QUESTIONS_CONTROLS_SUCCESS,
  GET_ALL_RISKFACTORS_FAILURE,
  GET_ALL_RISKFACTORS_REQUEST,
  GET_ALL_RISKFACTORS_SUCCESS,
  GET_ALL_QUESTIONS_VULNERABILITIES_FAILURE,
  GET_ALL_QUESTIONS_VULNERABILITIES_REQUEST,
  GET_ALL_QUESTIONS_VULNERABILITIES_SUCCESS,
  GET_FILTERED_VULNERABILITY_REQUEST,
  GET_FILTERED_VULNERABILITY_SUCCESS,
  GET_FILTERED_VULNERABILITY_FAILURE,
  GET_FILTERED_RISKFACTOR_REQUEST,
  GET_FILTERED_RISKFACTOR_SUCCESS,
  GET_FILTERED_RISKFACTOR_FAILURE,
  GET_FILTERED_CONTROL_REQUEST,
  GET_FILTERED_CONTROL_SUCCESS,
  GET_FILTERED_CONTROL_FAILURE,
} = QuestionsActions;

function getQuestionsModuleRequest(last_page?: number): Action {
  return {
    type: GET_ALL_QUESTIONS_MODULE_REQUEST,
    payload: {
      last_page
    },
  };
}

function getQuestionsModuleSuccess(questions: Question[] | QuestionList[], total: number, last_page: number, has_next: boolean): Action {
  return {
    type: GET_ALL_QUESTIONS_MODULE_SUCCESS,
    payload: {
      questions,
      total,
      last_page,
      has_next
    },
  };
}

function getQuestionsModuleFailure(): Action {
  return {
    type: GET_ALL_QUESTIONS_MODULE_FAILURE,
  };
}

function getSearchFilteredQuestionRequest(
  filteredQuestions: Array<string | boolean | null>,
  filterDepartment: string | null
): Action {
  return {
    type: GET_FILTERED_QUESTION_REQUEST,
    payload: {
      filteredQuestions,
      filterDepartment
    },
  };
}

function getSearchFilteredQuestionSuccess(
  questions: Question[] | QuestionList[],
  total: number,
): Action {
  return {
    type: GET_FILTERED_QUESTION_SUCCESS,
    payload: {
      questions,
      total,
    },
  };
}

function getSearchFilteredQuestionFailure(): Action {
  return {
    type: GET_FILTERED_QUESTION_FAILURE,
  };
}

function getQuestionDetailRequest(questionID: string): Action {
  return {
    type: GET_QUESTION_DETAIL_REQUEST,
    payload: {
      questionID,
    },
  };
}

function getQuestionDetailSuccess(question: Question): Action {
  return {
    type: GET_QUESTION_DETAIL_SUCCESS,
    payload: {
      question,
    },
  };
}

function getQuestionDetailFailure(): Action {
  return {
    type: GET_QUESTION_DETAIL_FAILURE,
  };
}

function getQuestionsDepartmentsRequest(): Action {
  return {
    type: GET_ALL_QUESTIONS_DEPARTMENTS_REQUEST,
  };
}

function getQuestionsDepartmentsSuccess(departments: Department[]): Action {
  return {
    type: GET_ALL_QUESTIONS_DEPARTMENTS_SUCCESS,
    payload: {
      departments,
    },
  };
}

function getQuestionsDepartmentsFailure(): Action {
  return {
    type: GET_ALL_QUESTIONS_DEPARTMENTS_FAILURE,
  };
}

function getQuestionsVulnerabilitiesRequest(): Action {
  return {
    type: GET_ALL_QUESTIONS_VULNERABILITIES_REQUEST,
  };
}

function getQuestionsVulnerabilitiesSuccess(vulnerabilities: Vulnerability[]): Action {
  return {
    type: GET_ALL_QUESTIONS_VULNERABILITIES_SUCCESS,
    payload: {
      vulnerabilities,
    },
  };
}

function getQuestionsVulnerabilitiesFailure(): Action {
  return {
    type: GET_ALL_QUESTIONS_VULNERABILITIES_FAILURE,
  };
}

function getRiskFactorsRequest(): Action {
  return {
    type: GET_ALL_RISKFACTORS_REQUEST,
  };
}

function getRiskFactorsSuccess(riskFactors: RiskFactor[]): Action {
  return {
    type: GET_ALL_RISKFACTORS_SUCCESS,
    payload: {
      riskFactors,
    },
  };
}

function getRiskFactorsFailure(): Action {
  return {
    type: GET_ALL_RISKFACTORS_FAILURE,
  };
}

function getQuestionsControlsRequest(): Action {
  return {
    type: GET_ALL_QUESTIONS_CONTROLS_REQUEST,
  };
}

function getQuestionsControlsSuccess(controls: Control[]): Action {
  return {
    type: GET_ALL_QUESTIONS_CONTROLS_SUCCESS,
    payload: {
      controls,
    },
  };
}

function getQuestionsControlsFailure(): Action {
  return {
    type: GET_ALL_QUESTIONS_CONTROLS_FAILURE,
  };
}

function getSearchFilteredVulnerabilityRequest(
  filteredVulnerabilities: Array<string | boolean | null>
): Action {
  return {
    type: GET_FILTERED_VULNERABILITY_REQUEST,
    payload: {
      filteredVulnerabilities,
    },
  };
}

function getSearchFilteredVulnerabilitySuccess(
  vulnerabilities: Vulnerability[]
): Action {
  return {
    type: GET_FILTERED_VULNERABILITY_SUCCESS,
    payload: {
      vulnerabilities,
    },
  };
}

function getSearchFilteredVulnerabilityFailure(): Action {
  return {
    type: GET_FILTERED_VULNERABILITY_FAILURE,
  };
}

function getSearchFilteredRiskFactorRequest(
  filteredRiskFactors: Array<string | boolean | null>
): Action {
  return {
    type: GET_FILTERED_RISKFACTOR_REQUEST,
    payload: {
      filteredRiskFactors,
    },
  };
}

function getSearchFilteredRiskFactorSuccess(riskFactors: RiskFactor[]): Action {
  return {
    type: GET_FILTERED_RISKFACTOR_SUCCESS,
    payload: {
      riskFactors,
    },
  };
}

function getSearchFilteredRiskFactorFailure(): Action {
  return {
    type: GET_FILTERED_RISKFACTOR_FAILURE,
  };
}

function getSearchFilteredControlRequest(
  filteredControls: Array<string | boolean | null>
): Action {
  return {
    type: GET_FILTERED_CONTROL_REQUEST,
    payload: {
      filteredControls,
    },
  };
}

function getSearchFilteredControlSuccess(controls: Control[]): Action {
  return {
    type: GET_FILTERED_CONTROL_SUCCESS,
    payload: {
      controls,
    },
  };
}

function getSearchFilteredControlFailure(): Action {
  return {
    type: GET_FILTERED_CONTROL_FAILURE,
  };
}

function addNewQuestionRequest(
  newQuestion: AddNewQuestionData
): Action {
  return {
    type: ADD_NEW_QUESTION_REQUEST,
    payload: {
      newQuestion,
    },
  };
}

function addNewQuestionSuccess(
  QuestionAdded: AddNewQuestionResponseData
): Action {
  return {
    type: ADD_NEW_QUESTION_SUCCESS,
    payload: {
      QuestionAdded,
    },
  };
}

function addNewQuestionFailure(): Action {
  return {
    type: ADD_NEW_QUESTION_FAILURE,
  };
}

function editQuestionRequest(
  updatedQuestion: EditQuestion
): Action {
  return {
    type: EDIT_QUESTION_REQUEST,
    payload: {
      updatedQuestion,
    },
  };
}

function editQuestionAddAlternativeRequest(
  updatedQuestion: EditQuestion
): Action {
  return {
    type: EDIT_QUESTION_ADD_ALTERNATIVE_REQUEST,
    payload: {
      updatedQuestion,
    },
  };
}

function editQuestionSuccess(): Action {
  return {
    type: EDIT_QUESTION_SUCCESS,
  };
}

function editQuestionFailure(): Action {
  return {
    type: EDIT_QUESTION_FAILURE,
  };
}

function activeOrInactiveQuestionRequest(
  QuestionId: string,
  newQuestionStatus: boolean
): Action {
  return {
    type: ACTIVE_OR_INACTIVE_QUESTION_REQUEST,
    payload: {
      QuestionId,
      newQuestionStatus,
    },
  };
}

function activeOrInactiveQuestionSuccess(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_QUESTION_SUCCESS,
  };
}

function activeOrInactiveQuestionFailure(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_QUESTION_FAILURE,
  };
}

function resetTheQuestionDetailState(): Action {
  return {
    type: RESET_THE_QUESTION_DETAIL_STATE,
  };
}

export const questionsActionsFunctions = {
  getQuestionsModuleRequest,
  getQuestionsModuleSuccess,
  getQuestionsModuleFailure,
  getSearchFilteredQuestionRequest,
  getSearchFilteredQuestionSuccess,
  getSearchFilteredQuestionFailure,
  getQuestionDetailRequest,
  getQuestionDetailSuccess,
  getQuestionDetailFailure,
  addNewQuestionRequest,
  addNewQuestionSuccess,
  addNewQuestionFailure,
  editQuestionAddAlternativeRequest,
  editQuestionRequest,
  editQuestionSuccess,
  editQuestionFailure,
  activeOrInactiveQuestionRequest,
  activeOrInactiveQuestionSuccess,
  activeOrInactiveQuestionFailure,
  resetTheQuestionDetailState,
  getQuestionsDepartmentsRequest,
  getQuestionsDepartmentsSuccess,
  getQuestionsDepartmentsFailure,
  getQuestionsVulnerabilitiesRequest,
  getQuestionsVulnerabilitiesSuccess,
  getQuestionsVulnerabilitiesFailure,
  getQuestionsControlsRequest,
  getQuestionsControlsSuccess,
  getQuestionsControlsFailure,
  getRiskFactorsRequest,
  getRiskFactorsFailure,
  getRiskFactorsSuccess,
  getSearchFilteredControlFailure,
  getSearchFilteredControlRequest,
  getSearchFilteredControlSuccess,
  getSearchFilteredRiskFactorFailure,
  getSearchFilteredRiskFactorRequest,
  getSearchFilteredRiskFactorSuccess,
  getSearchFilteredVulnerabilityFailure,
  getSearchFilteredVulnerabilityRequest,
  getSearchFilteredVulnerabilitySuccess,
};

