import { ControlDetail } from '../Control/types';

export enum RiskActions {
  GET_ALL_RISK_REQUEST = 'GET_ALL_RISK_REQUEST',
  GET_ALL_RISK_SUCCESS = 'GET_ALL_RISK_SUCCESS',
  GET_ALL_RISK_FAILURE = 'GET_ALL_RISK_FAILURE',
  GET_ALL_RISK_OPTIONS_REQUEST = 'GET_ALL_RISK_OPTIONS_REQUEST',
  GET_ALL_RISK_OPTIONS_SUCCESS = 'GET_ALL_RISK_OPTIONS_SUCCESS',
  GET_ALL_RISK_OPTIONS_FAILURE = 'GET_ALL_RISK_OPTIONS_FAILURE',
  CREATE_NEW_RISK_REQUEST = 'CREATE_NEW_RISK_REQUEST',
  CREATE_NEW_RISK_SUCCESS = 'CREATE_NEW_RISK_SUCCESS',
  CREATE_NEW_RISK_FAILURE = 'CREATE_NEW_RISK_FAILURE',
  GET_SEARCH_FILTERED_RISKS_REQUEST = 'GET_SEARCH_FILTERED_RISKS_REQUEST',
  GET_SEARCH_FILTERED_RISKS_SUCCESS = 'GET_SEARCH_FILTERED_RISKS_SUCCESS',
  GET_SEARCH_FILTERED_RISKS_FAILURE = 'GET_SEARCH_FILTERED_RISKS_FAILURE',
  GET_RISK_DETAIL_REQUEST = 'GET_RISK_DETAIL_REQUEST',
  GET_RISK_DETAIL_SUCCESS = 'GET_RISK_DETAIL_SUCCESS',
  GET_RISK_DETAIL_FAILURE = 'GET_RISK_DETAIL_FAILURE',
  GET_ALL_RECOMENDATIONS_REQUEST = 'GET_ALL_RECOMENDATIONS_REQUEST',
  GET_ALL_RECOMENDATIONS_SUCCESS = 'GET_ALL_RECOMENDATIONS_SUCCESS',
  GET_ALL_RECOMENDATIONS_FAILURE = 'GET_ALL_RECOMENDATIONS_FAILURE',
  EDIT_RECOMENDATIONS_REQUEST = 'EDIT_RECOMENDATIONS_REQUEST',
  EDIT_RECOMENDATIONS_SUCCESS = 'EDIT_RECOMENDATIONS_SUCCESS',
  EDIT_RECOMENDATIONS_FAILURE = 'EDIT_RECOMENDATIONS_FAILURE',
  GET_ALL_RISK_STATUS_REQUEST = 'GET_ALL_RISK_STATUS_REQUEST',
  GET_ALL_RISK_STATUS_SUCCESS = 'GET_ALL_RISK_STATUS_SUCCESS',
  GET_ALL_RISK_STATUS_FAILURE = 'GET_ALL_RISK_STATUS_FAILURE',
  EDIT_RISK_REQUEST = 'EDIT_RISK_REQUEST',
  EDIT_RISK_SUCCESS = 'EDIT_RISK_SUCCESS',
  EDIT_RISK_FAILURE = 'EDIT_RISK_FAILURE',
  UPDATE_RISK_THREAT_REQUEST = 'UPDATE_RISK_THREAT_REQUEST',
  UPDATE_RISK_THREAT_SUCCESS = 'UPDATE_RISK_THREAT_SUCCESS',
  UPDATE_RISK_THREAT_FAILURE = 'UPDATE_RISK_THREAT_FAILURE',
}

export type ObjectWithId = {
  id: string;
}

export type RiskInfoUpdateStatus = {
  riskId: string;
  riskStatus: boolean;
}

export type RecomendationsInsideStage = {
  type_recommendation: string,
  controls: ObjectWithId[]
}

export type StagesAdd = {
  name: string,
  recommendations: RecomendationsInsideStage[]
}

export type RecomendationsUpdate = {
  id: string;
  type_recommendation: string;
  controls_add: ObjectWithId[];
  controls_remove: ObjectWithId[];
}

export type StagesUpdate = {
  id: string,
  recommendations_add: RecomendationsInsideStage[]
  recommendations_update: RecomendationsUpdate[],
  recommendations_remove: ObjectWithId[]
}

export type RecomendationsBodyType = {
  stages_add: StagesAdd[],
  stages_remove: ObjectWithId[],
  stages_update: StagesUpdate[],
}

export type SearchFilteredRisksRequestPayload = {
  filteredRisks: Array<string | boolean | null>;
  isActive: boolean;
};

export type ApiFetchGetRiskDetailRequestPayloadParams = {
  riskId: string;
};

export type TypesRecomendApiResponse = {
  id: string;
  name: string;
}

export type TypesRecomendApiResponseWithType = {
  id: string;
  type: string;
}

export type TypesRecommendation = {
  types_recommendation: TypesRecomendApiResponse[]
}

type Recommendations = {
  id: string;
  stage: number;
  type_recommendation: TypesRecomendApiResponse[];
  controls: ObjectWithId[]
}

export type Stages = {
  id: number;
  name: string;
  risk: string;
  recommendations: Recommendations[]
}

type IdandName = {
  id: string
  name: string;
};

type IdandType = {
  id: string;
  type: IdandName;
}

export type StagesDetail = {
  id: string;
  name: string;
  recommendation: IdandType;
  controls: ControlDetail[];
}

export type Recomendations = {
  id: string;
  name: string;
}

export type RiskResults = {
  results: Risk[];
  count: number;
  next: boolean;
}

export type Risk = {
  id: string;
  friendly_id: string;
  name: string;
  description: string;
  skill_level: string;
  motive: string;
  opportunity: string;
  size: string;
  ease_of_discovery: string;
  ease_of_exploit: string;
  awareness: string;
  instrusion_detection: string;
  loss_of_confidentiality: string;
  loss_of_integrity: string;
  loff_of_availability: string;
  loff_of_accountability: string;
  financial_damage: string;
  reputation_damage: string;
  non_compliance: string;
  privacy_violation: string;
  stages: Stages[];
  threats: ObjectWithId[];
  is_active: boolean
}

export type ThreatFromRiskDetail = {
  id: string;
  name: string;
  description: string
}

export type RiskDetail = {
  awareness: QuestionsBody;
  description: string;
  ease_of_discovery: QuestionsBody;
  ease_of_exploit: QuestionsBody;
  financial_damage: QuestionsBody;
  friendly_id: string;
  id: string;
  impact_category: string;
  impact_score: number;
  inherent_risk: string;
  inherent_risk_score: number;
  instrusion_detection: QuestionsBody;
  is_active: boolean
  loff_of_accountability: QuestionsBody;
  loff_of_availability: QuestionsBody;
  loss_of_confidentiality: QuestionsBody;
  loss_of_integrity: QuestionsBody;
  motive: QuestionsBody;
  name: string;
  non_compliance: QuestionsBody;
  opportunity: QuestionsBody;
  privacy_violation: QuestionsBody;
  probability_category: string;
  probability_score: number;
  reputation_damage: QuestionsBody;
  size: QuestionsBody;
  skill_level: QuestionsBody;
  stages: StagesDetail[]
  threats: ThreatFromRiskDetail[]
}

export type ApiFetchCreateNewRiskRequestPayloadParams = {
  riskBody: RiskPostBody
}

export type ApiFetchEditRecommendationRequestPayloadParams = {
  riskId: string;
  currentRecommendation: RecomendationsBodyType[];
}

export type ApiFetchEditRiskRequestPayloadParams = {
  riskId: string;
  riskPutBody: RiskPutBody;
}

export type ApiFetchEditRiskTreatsRequestPayloadParams = {
  riskId: string;
  riskUpdateAdd: ObjectWithId[];
  riskUpdateRemove: ObjectWithId[];
}

export type RiskPutBody = {
  id?: string,
  name: string,
  description: string,
  skill_level: string,
  motive: string,
  opportunity: string,
  size: string,
  ease_of_discovery: string,
  ease_of_exploit: string,
  awareness: string,
  instrusion_detection: string,
  loss_of_confidentiality: string,
  loss_of_integrity: string,
  loff_of_availability: string,
  loff_of_accountability: string,
  financial_damage: string,
  reputation_damage: string,
  non_compliance: string,
  privacy_violation: string,
  stages: Stages[],
  threats: ObjectWithId[],
  is_active: boolean
}

export type RiskPostBody = {
  id?: string,
  name: string,
  description: string,
  skill_level: string,
  motive: string,
  opportunity: string,
  size: string,
  ease_of_discovery: string,
  ease_of_exploit: string,
  awareness: string,
  instrusion_detection: string,
  loss_of_confidentiality: string,
  loss_of_integrity: string,
  loff_of_availability: string,
  loff_of_accountability: string,
  financial_damage: string,
  reputation_damage: string,
  non_compliance: string,
  privacy_violation: string,
  stages: Stages[],
  threats: ObjectWithId[],
  is_active: boolean
}

export type QuestionsBody = {
  alternative: string;
  id: string;
  subtype: string;
  type: string;
  variable: string;
  weight: number;
}

export type AllQuestions = {
	skill_level: QuestionsBody[],
	motive: QuestionsBody[],
	opportunity: QuestionsBody[],
	size: QuestionsBody[],
	ease_of_discovery: QuestionsBody[],
	ease_of_exploit: QuestionsBody[],
	awareness: QuestionsBody[],
	intrusion_detection: QuestionsBody[],
	loss_of_confidentiality: QuestionsBody[],
	loss_of_integrity: [],
	loss_of_availability: QuestionsBody[],
	loss_of_accountability: QuestionsBody[],
	financial_damage: QuestionsBody[],
	reputation_damage: QuestionsBody[],
	non_compliance: QuestionsBody[],
	privacy_violation: QuestionsBody[]
}

export type RiskState = {
  visited_pages: number[],
  has_next: boolean,
  total: number,
  risk: Risk[];
  riskDetail: RiskDetail;
  questions: AllQuestions;
  recomendations: TypesRecommendation;
};
