import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { sha512 } from 'js-sha512';
import type { ForgotPasswordFormData } from 'pages/ForgotPassword/types';
import type { LoginFormData } from 'pages/Login/types';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { history } from 'services/history';
import { store } from 'store';
import { authActionsFunctions } from 'store/modules/auth/actions';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import { openedModalActionsFunctions } from 'store/modules/openedModal/actions';
import type { Action } from 'store/types';

import type {
  UserLoginSuccessResponse,
  UserForgotPasswordSuccessResponse,
  UserResetPasswordSuccessRequest,
  UserResetPasswordSuccessResponse,
  ApiFetchTokenExpiredResponse,
  UserRegisterPasswordSuccessRequest,
  UserRegisterPasswordSuccessResponse,
  UserCreatedTotpDeviceSuccessResponse,
  VerifiedOrEnabledUserTotpDeviceSuccessResponse,
  VerifiedOrEnabledUserTotpDeviceRequest,
  UpdateUserPictureSuccessRequest,
  DeleteUserPictureSuccessRequest,
  ChangeUserPasswordSuccessRequest,
  ChangeUserPasswordSuccessResponse,
  CurrentUserDataSuccessRequest,
  CurrentUserDataSuccessResponse,
  UserAuthTotpDeviceRequest,
  UserAuthTotpDeviceSuccessResponse,
} from './types';
import { AuthStateActions } from './types';

const {
  USER_LOGIN_REQUEST,
  CURRENT_USER_DATA_REQUEST,
  USER_CREATE_TOTP_DEVICE_REQUEST,
  USER_VERIFY_OR_ENABLE_USER_TOTP_DEVICE_REQUEST,
  USER_AUTH_TOTP_DEVICE_REQUEST,
  USER_LOGOUT_REQUEST,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_REQUEST,
  USER_REGISTER_PASSWORD_REQUEST,
  USER_TOKEN_VERIFY_REQUEST,
  UPDATE_USER_PICTURE_REQUEST,
  DELETE_USER_PICTURE_REQUEST,
  USER_CHANGE_PASSWORD_REQUEST,
} = AuthStateActions;

const {
  DEFAULT_SUCCESS_MESSAGE,
  DEFAULT_ERROR_MESSAGE,
  LOGIN_SUCCESS_MESSAGE,
  LOGIN_ERROR_MESSAGE,
  USER_CREATE_TOTP_DEVICE_SUCCESS_MESSAGE,
  USER_CREATE_TOTP_DEVICE_ERROR_MESSAGE,
  CURRENT_USER_DATA_ERROR_MESSAGE,
  LOGOUT_SUCCESS_MESSAGE,
  UPDATE_USER_PICTURE_SUCCESS_MESSAGE,
  UPDATE_USER_PICTURE_FAILURE_MESSAGE,
  DELETE_USER_PICTURE_SUCCESS_MESSAGE,
  DELETE_USER_PICTURE_FAILURE_MESSAGE,
  USER_CHANGE_PASSWORD_SUCCESS_MESSAGE,
  USER_CHANGE_PASSWORD_FAILURE_MESSAGE,
} = ToastMessages;

const {
  getUserLoginSuccess,
  getUserLoginFailure,
  getCurrentUserDataSuccess,
  getCurrentUserDataFailure,
  getUserLogoutSuccess,
  getUserLogoutFailure,
  getUserCreateTotpDeviceSuccess,
  getUserCreateTotpDeviceFailure,
  getVerifyOrEnableUserTotpDeviceSuccess,
  getVerifyOrEnableUserTotpDeviceFailure,
  getUserForgotPasswordSuccess,
  getUserForgotPasswordFailure,
  getUserResetPasswordSuccess,
  getUserResetPasswordFailure,
  getUserRegisterPasswordSuccess,
  getUserRegisterPasswordFailure,
  getTokenVerifySuccess,
  getTokenVerifyFailure,
  getUpdateUserPictureSuccess,
  getUpdateUserPictureFailure,
  getDeleteUserPictureSuccess,
  getDeleteUserPictureFailure,
  getUserChangePasswordSuccess,
  getUserChangePasswordFailure,
} = authActionsFunctions;

const { getLastApiFetchDataSuccess, getLastApiFetchDataFailure } =
  lastApiFetchDataActionsFunctions;

const { setOpenedModal } = openedModalActionsFunctions;

function* fetchUserLogin(action: Action): Generator {
  const { email, password } = action.payload as LoginFormData;

  try {
    const userLoginResponse: AxiosResponse<UserLoginSuccessResponse> | unknown =
      yield call(api, 'POST', 'accounts/login/', {
        email,
        password: sha512(password),
      });

    const {
      data,
      status,
      statusText,
      config: { url },
    } = userLoginResponse as AxiosResponse<UserLoginSuccessResponse>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(
      getUserLoginSuccess({
        isVerified: data.is_verified,
        totpDevice: data.device_totp,
        id: data.user_id,
        email,
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
        accessTokenLifetime: data.access_token_lifetime,
        refreshTokenLifetime: data.refresh_token_lifetime,
        username: data.user,
        phone: data.phone,
        position: data.position,
        profile: data.profile,
        photo: data.photo,
        is_customer: data.is_customer,
        first_access: data.first_access,
      })
    );

    toast.success(LOGIN_SUCCESS_MESSAGE);

    if (data.device_totp) {
      history.push('/two-factor-authentication');
    } else {
      history.push('/multiple-factor-authentication');
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getUserLoginFailure());

    if (currentError.response?.data.status === 'Senha expirada. Foi enviado um email para você realizar o cadastro de uma nova senha.')
      toast.info('Senha expirada. Foi enviado um email para você realizar o cadastro de uma nova senha.');
    else
      toast.error(LOGIN_ERROR_MESSAGE);
  }
}

function* fetchCurrentUserData(action: Action): Generator {
  const { userId } = action.payload as CurrentUserDataSuccessRequest;

  try {
    const currentUserDataResponse:
      | AxiosResponse<CurrentUserDataSuccessResponse>
      | unknown = yield call(
        api,
        'GET',
        `accounts/users/${userId}/detail/`,
        {}
      );

    const {
      data: userDataResponse,
      status,
      statusText,
      config: { url },
    } = currentUserDataResponse as AxiosResponse<CurrentUserDataSuccessResponse>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCurrentUserDataSuccess(userDataResponse));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCurrentUserDataFailure());

    toast.error(CURRENT_USER_DATA_ERROR_MESSAGE);
  }
}

function* fetchUserCreateTotpDevice(): Generator {
  try {
    const UserCreateTotpDeviceResponse:
      | AxiosResponse<UserCreatedTotpDeviceSuccessResponse>
      | unknown = yield call(api, 'GET', 'accounts/totp/create/', {});

    const {
      data,
      status,
      statusText,
      config: { url },
    } = UserCreateTotpDeviceResponse as AxiosResponse<UserCreatedTotpDeviceSuccessResponse>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getUserCreateTotpDeviceSuccess(data.totp_url));

    toast.success(USER_CREATE_TOTP_DEVICE_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getUserCreateTotpDeviceFailure());

    toast.error(USER_CREATE_TOTP_DEVICE_ERROR_MESSAGE);
  }
}

function* fetchVerifyOrEnableUserTotpDevice(action: Action): Generator {
  const { totpToken, isCustomer } =
    action.payload as VerifiedOrEnabledUserTotpDeviceRequest;

  try {
    const VerifyOrEnableUserTotpDeviceResponse:
      | AxiosResponse<VerifiedOrEnabledUserTotpDeviceSuccessResponse>
      | unknown = yield call(
        api,
        'POST',
        `accounts/totp/login/${totpToken}/`,
        {}
      );

    const {
      data,
      status,
      statusText,
      config: { url },
    } = VerifyOrEnableUserTotpDeviceResponse as AxiosResponse<VerifiedOrEnabledUserTotpDeviceSuccessResponse>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getVerifyOrEnableUserTotpDeviceSuccess(!!data.msg));

    toast.success(data.msg);

    if(isCustomer)
      history.push('/customer-project-detail');
    else
      history.push('/users');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getVerifyOrEnableUserTotpDeviceFailure());

    toast.error(
      currentError.response?.data?.errors?.token[0] ?? DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchUserAuthTotpDevice(action: Action): Generator {
  const { totpToken, isCustomer } = action.payload as UserAuthTotpDeviceRequest;

  try {
    const VerifyOrEnableUserTotpDeviceResponse:
      | AxiosResponse<UserAuthTotpDeviceSuccessResponse>
      | unknown = yield call(
        api,
        'POST',
        `accounts/totp/login/${totpToken}/`,
        {}
      );

    const {
      data,
      status,
      statusText,
      config: { url },
    } = VerifyOrEnableUserTotpDeviceResponse as AxiosResponse<UserAuthTotpDeviceSuccessResponse>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getVerifyOrEnableUserTotpDeviceSuccess(!!data.msg));

    toast.success(data.msg);

    if (localStorage.getItem('CustomerProjectId') !== null)
      localStorage.removeItem('CustomerProjectId');
    
    if(isCustomer)
      history.push('/customer-project-detail');
    else
      history.push('/customer-project');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getVerifyOrEnableUserTotpDeviceFailure());

    toast.error(
      currentError.response?.data?.errors?.token[0] ?? DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchUserLogout(): Generator {
  const {
    auth: {
      user: { refreshToken },
    },
  } = store.getState();

  try {
    const userLogoutResponse: AxiosResponse | unknown = yield call(
      api,
      'POST',
      'accounts/logout/',
      { refresh_token: refreshToken }
    );

    const {
      config: { url },
      status,
      statusText,
    } = userLogoutResponse as AxiosResponse;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getUserLogoutSuccess());

    toast.success(LOGOUT_SUCCESS_MESSAGE);

    history.push('/');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(
      getUserLogoutFailure(
        currentError?.response?.status as number,
        currentError?.response?.data as unknown
      )
    );

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
      DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchUserForgotPassword(action: Action): Generator {
  const { email } = action.payload as ForgotPasswordFormData;

  try {
    const userForgotPasswordResponse:
      | AxiosResponse<UserForgotPasswordSuccessResponse>
      | unknown = yield call(api, 'POST', 'accounts/reset-password/', {
        email,
      });

    const {
      status,
      statusText,
      config: { url },
    } = userForgotPasswordResponse as AxiosResponse<UserForgotPasswordSuccessResponse>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getUserForgotPasswordSuccess(email));

    yield put(setOpenedModal('forgotPasswordOpenedModal'));

    toast.success(DEFAULT_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getUserForgotPasswordFailure());

    toast.error(currentError?.response?.data?.status ?? DEFAULT_ERROR_MESSAGE);
  }
}

function* fetchUserResetPassword(action: Action): Generator {
  const { newPassword, confirmNewPassword, uuid, token } =
    action.payload as UserResetPasswordSuccessRequest;

  try {
    const userResetPasswordResponse:
      | AxiosResponse<UserResetPasswordSuccessResponse>
      | unknown = yield call(
        api,
        'PATCH',
        `accounts/reset-password-confirm/${uuid}/${token}/`,
        {
          password: sha512(newPassword),
          confirm_password: sha512(confirmNewPassword),
          id: uuid,
          token,
        }
      );

    const {
      data,
      config: { url },
      status,
      statusText,
    } = userResetPasswordResponse as AxiosResponse<UserResetPasswordSuccessResponse>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getUserResetPasswordSuccess());

    toast.success(data?.message);

    history.push('/two-factor-authentication');
  } catch (error) {
    const currentError = error as AxiosError;
    const ERRO_MESSENGER = 'Cannot be the same as previous password';
    const REPEATED_PASSWORD_MESSENGER = 'A senha atual, não pode ser igual à senha anterior.';

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getUserResetPasswordFailure());

    if (currentError?.response?.data?.detail === ERRO_MESSENGER)
      toast.warning(REPEATED_PASSWORD_MESSENGER);
    else
      toast.error(currentError?.response?.data?.detail ?? DEFAULT_ERROR_MESSAGE);
  }
}

function* fetchUserRegisterPassword(action: Action): Generator {
  const { newPassword, confirmNewPassword, uuid, token } =
    action.payload as UserRegisterPasswordSuccessRequest;

  try {
    const userRegisterPasswordResponse:
      | AxiosResponse<UserRegisterPasswordSuccessResponse>
      | unknown = yield call(
        api,
        'PATCH',
        `accounts/register-password/${uuid}/${token}/`,
        {
          password: sha512(newPassword),
          confirm_password: sha512(confirmNewPassword),
        }
      );

    const {
      data,
      config: { url },
      status,
      statusText,
    } = userRegisterPasswordResponse as AxiosResponse<UserRegisterPasswordSuccessResponse>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getUserRegisterPasswordSuccess());

    toast.success(data?.message);

    history.push('/');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getUserRegisterPasswordFailure());

    toast.error(currentError?.response?.data?.detail ?? DEFAULT_ERROR_MESSAGE);
  }
}

function* fetchTokenVerify(): Generator {
  const {
    auth: {
      user: { accessToken: token },
    },
  } = store.getState();

  try {
    const tokenVerifyResponse:
      | AxiosResponse<ApiFetchTokenExpiredResponse>
      | unknown = yield call(api, 'POST', 'accounts/token/verify/', {
        token,
      });

    const {
      data,
      status,
      statusText,
      config: { url },
    } = tokenVerifyResponse as AxiosResponse<ApiFetchTokenExpiredResponse>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(
      getTokenVerifySuccess({
        detail: data?.detail,
        code: data?.code,
        messages: data?.messages,
      })
    );

    toast.success(DEFAULT_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getTokenVerifyFailure(currentError.code as string));

    toast.error(currentError?.response?.data?.detail ?? DEFAULT_ERROR_MESSAGE);
  }
}

function* fetchUpdateUserPicture(action: Action): Generator {
  const { userId, newPictureFormData } =
    action.payload as UpdateUserPictureSuccessRequest;

  try {
    // TODO - check response data type
    const updateUserPictureResponse: AxiosResponse<unknown> | unknown =
      yield call(
        api,
        'PUT',
        `accounts/users/${userId}/update-picture/`,
        newPictureFormData
      );

    const {
      config: { url },
      status,
      statusText,
    } = updateUserPictureResponse as AxiosResponse<unknown>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getUpdateUserPictureSuccess());

    toast.success(UPDATE_USER_PICTURE_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getUpdateUserPictureFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      UPDATE_USER_PICTURE_FAILURE_MESSAGE
    );
  }
}

function* fetchDeleteUserPicture(action: Action): Generator {
  const { userId } = action.payload as DeleteUserPictureSuccessRequest;

  try {
    // TODO - check response data type
    const deleteUserPictureResponse: AxiosResponse<unknown> | unknown =
      yield call(api, 'DELETE', `accounts/users/${userId}/delete-picture/`, {});

    const {
      config: { url },
      status,
      statusText,
    } = deleteUserPictureResponse as AxiosResponse<unknown>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getDeleteUserPictureSuccess());

    toast.success(DELETE_USER_PICTURE_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getDeleteUserPictureFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      DELETE_USER_PICTURE_FAILURE_MESSAGE
    );
  }
}

function* fetchChangeUserPassword(action: Action): Generator {
  const { changePasswordData } =
    action.payload as ChangeUserPasswordSuccessRequest;

  try {
    const changeUserPasswordResponse:
      | AxiosResponse<ChangeUserPasswordSuccessResponse>
      | unknown = yield call(api, 'PUT', 'accounts/change-password/', {
        old_password: sha512(changePasswordData.currentPassword),
        new_password: sha512(changePasswordData.newPassword),
        confirm_password: sha512(changePasswordData.confirmNewPassword),
      });

    const {
      data,
      config: { url },
      status,
      statusText,
    } = changeUserPasswordResponse as AxiosResponse<ChangeUserPasswordSuccessResponse>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(
      getUserChangePasswordSuccess(changePasswordData.confirmNewPassword)
    );

    toast.success(data?.message ?? USER_CHANGE_PASSWORD_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getUserChangePasswordFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      USER_CHANGE_PASSWORD_FAILURE_MESSAGE
    );
  }
}

export function* authSagas() {
  yield all([
    takeLatest(USER_LOGIN_REQUEST, fetchUserLogin),
    takeLatest(CURRENT_USER_DATA_REQUEST, fetchCurrentUserData),
    takeLatest(USER_CREATE_TOTP_DEVICE_REQUEST, fetchUserCreateTotpDevice),
    takeLatest(
      USER_VERIFY_OR_ENABLE_USER_TOTP_DEVICE_REQUEST,
      fetchVerifyOrEnableUserTotpDevice
    ),
    takeLatest(USER_AUTH_TOTP_DEVICE_REQUEST, fetchUserAuthTotpDevice),
    takeLatest(USER_LOGOUT_REQUEST, fetchUserLogout),
    takeLatest(USER_FORGOT_PASSWORD_REQUEST, fetchUserForgotPassword),
    takeLatest(USER_RESET_PASSWORD_REQUEST, fetchUserResetPassword),
    takeLatest(USER_REGISTER_PASSWORD_REQUEST, fetchUserRegisterPassword),
    takeLatest(USER_TOKEN_VERIFY_REQUEST, fetchTokenVerify),
    takeLatest(UPDATE_USER_PICTURE_REQUEST, fetchUpdateUserPicture),
    takeLatest(DELETE_USER_PICTURE_REQUEST, fetchDeleteUserPicture),
    takeLatest(USER_CHANGE_PASSWORD_REQUEST, fetchChangeUserPassword),
  ]);
}
