import { AxiosError } from 'axios';
import type { Action } from 'store/types';

import type { ApiFetchStatusResponse } from './types';
import { LastApiFetchDataStateActions } from './types';

const {
  SET_LOADING_STATUS_FOR_THE_LAST_API_FETCH_DATA,
  LAST_API_FETCH_DATA_SUCCESS,
  LAST_API_FETCH_DATA_FAILURE,
  RESET_THE_LAST_API_FETCH_DATA_STATUS,
} = LastApiFetchDataStateActions;

function setLoadingStatusForTheLastApiFetchData(): Action {
  return {
    type: SET_LOADING_STATUS_FOR_THE_LAST_API_FETCH_DATA,
  };
}

function getLastApiFetchDataSuccess({
  url,
  message,
  status,
  statusText,
}: ApiFetchStatusResponse): Action {
  return {
    type: LAST_API_FETCH_DATA_SUCCESS,
    payload: {
      url,
      message,
      status,
      statusText,
    },
  };
}

function getLastApiFetchDataFailure(error: AxiosError): Action {
  return {
    type: LAST_API_FETCH_DATA_FAILURE,
    payload: {
      error,
    },
  };
}

function resetTheLastApiFetchDataStatus(): Action {
  return {
    type: RESET_THE_LAST_API_FETCH_DATA_STATUS,
  };
}

export const lastApiFetchDataActionsFunctions = {
  setLoadingStatusForTheLastApiFetchData,
  getLastApiFetchDataSuccess,
  getLastApiFetchDataFailure,
  resetTheLastApiFetchDataStatus,
};
