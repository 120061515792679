import { Profile } from "pages/Users/UserEditingModal";

export enum UsersActions {
  GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST',
  GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS',
  GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE',
  GET_FILTERED_USER_REQUEST = 'GET_FILTERED_USER_REQUEST',
  GET_FILTERED_USER_SUCCESS = 'GET_FILTERED_USER_SUCCESS',
  GET_FILTERED_USER_FAILURE = 'GET_FILTERED_USER_FAILURE',
  ADD_NEW_USER_REQUEST = 'ADD_NEW_USER_REQUEST',
  ADD_NEW_USER_SUCCESS = 'ADD_NEW_USER_SUCCESS',
  ADD_NEW_USER_FAILURE = 'ADD_NEW_USER_FAILURE',
  SEND_CONFIRMATION_EMAIL_TO_USER_REQUEST = 'SEND_CONFIRMATION_EMAIL_TO_USER_REQUEST',
  SEND_CONFIRMATION_EMAIL_TO_USER_SUCCESS = 'SEND_CONFIRMATION_EMAIL_TO_USER_SUCCESS',
  SEND_CONFIRMATION_EMAIL_TO_USER_FAILURE = 'SEND_CONFIRMATION_EMAIL_TO_USER_FAILURE',
  EDIT_USER_REQUEST = 'EDIT_USER_REQUEST',
  EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS',
  EDIT_USER_FAILURE = 'EDIT_USER_FAILURE',
  ACTIVE_OR_INACTIVE_USER_REQUEST = 'ACTIVE_OR_INACTIVE_USER_REQUEST',
  ACTIVE_OR_INACTIVE_USER_SUCCESS = 'ACTIVE_OR_INACTIVE_USER_SUCCESS',
  ACTIVE_OR_INACTIVE_USER_FAILURE = 'ACTIVE_OR_INACTIVE_USER_FAILURE',
}

export type User = {
  id?: string;
  document_number: string;
  corporate_name_or_name: string;
  fantasy_name?: string;
  user_profile: Profile;
  phone?: string;
  position?: string;
  email: string;
  password_inclusion_date: string;
  number_of_linked_projects: number;
  status: boolean;
  status_user: string;
  profile?: string;
  is_customer: boolean;
};

export type AddNewUserDataType = {
  name: string;
  user_profile: string;
  role: string;
  cellPhone: string;
  email: string;
  sendAccessInvitation: boolean;
  is_customer: boolean;
};

export type ApiFetchAddNewUserRequestPayloadParams = {
  user: AddNewUserDataType;
};

export type ApiFetchSendEmailToUserRequestPayloadParams = {
  userId: string;
};

export type ApiFetchSearchFilteredUserRequestPayloadParams = {
  filteredUsers: Array<string | boolean | null>;
};

export type ApiFetchToUserEditingRequestPayloadParams = {
  user: EditUserData;
};

export type ApiFetchToUpdateUserStatusRequestPayloadParams = {
  userId: string;
  newUserStatus: boolean;
};

export type UpdateUserStatusResponse = {
  id: string;
  status: boolean;
};

export type EditUserData = {
  id: string;
  user_profile: string | null;
  position: string;
  phone: string;
  profile?: string;
  is_customer: boolean;
};

export type UserState = {
  id: string | null;
  document_number: string | null;
  corporate_name_or_name: string | null;
  fantasy_name: string | null;
  user_profile: Profile;
  phone: string | null;
  position: string | null;
  email: string | null;
  password_inclusion_date: string | null;
  status: boolean | null;
  status_user: string | null;
};

interface LastUserAdded extends UserState {
  sendAccessInvitation: boolean | null;
}

export type UsersState = {
  users: UserState[];
  lastUserAdded: LastUserAdded;
  lastUserIdClickedToResendEmail: string | null;
};
