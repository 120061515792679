import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import type { Action } from 'store/types';
import { checkPage } from 'utils/number';

import { activitiesActionsFunctions } from './actions';
import type {
  Activities,
  ActivitiesDetails,
  ActivitiesDetailsRequest,
  AddNewActivitiesRequestPayload,
  UpdateStatusRequestPayloadParams,
  SearchFilteredActivitiesRequestPayloadParams,
  ActivitiesWithId,
  ActivitiesResults
} from './types';
import { ActivitiesActions } from './types';

const {
  DEFAULT_ERROR_MESSAGE,
  EDIT_ACTIVITIES_ERROR_MESSAGE,
  EDIT_ACTIVITIES_SUCCESS_MESSAGE,
  NEW_ACTIVITIES_ADDED_ERROR_MESSAGE,
  NEW_ACTIVITIES_ADDED_SUCCESS_MESSAGE,
  GET_ACTIVITIES_DETAILS_ERROR_MESSAGE,
  GET_ACTIVITIES_DETAILS_SUCCESS_MESSAGE,
  SEARCH_FILTERED_ACTIVITIES_ERROR_MESSAGE,
  SEARCH_FILTERED_ACTIVITIES_SUCCESS_MESSAGE,
  ACTIVE_OR_INACTIVE_ACTIVITIES_ERROR_MESSAGE,
  ACTIVE_OR_INACTIVE_ACTIVITIES_SUCCESS_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE
} = ToastMessages;

const {
  EDIT_ACTIVITIES_REQUEST,
  ADD_NEW_ACTIVITIES_REQUEST,
  GET_ALL_ACTIVITIES_MODULE_REQUEST,
  GET_ACTIVITIES_DETAILS_REQUEST,
  GET_FILTERED_ACTIVITIES_REQUEST,
  ACTIVE_OR_INACTIVE_ACTIVITIES_REQUEST,
  GET_ALL_ACTIVITIES_PROJECTSETTINGS_REQUEST,
  GET_FILTERED_ACTIVITIES_PROJECTSETTINGS_REQUEST
} = ActivitiesActions;

const {
  getActivitiesModuleRequest,
  getActivitiesModuleSuccess,
  getActivitiesModuleFailure,
  editActivitiesSuccess,
  editActivitiesFailure,
  addNewActivitiesSuccess,
  addNewActivitiesFailure,
  getActivitiesDetailsSuccess,
  getActivitiesDetailsFailure,
  resetTheLastActivitiesDetails,
  activeOrInactiveActivitiesSuccess,
  activeOrInactiveActivitiesFailure,
  getSearchFilteredActivitiesSuccess,
  getSearchFilteredActivitiesFailure,
} = activitiesActionsFunctions;

const { getLastApiFetchDataSuccess, getLastApiFetchDataFailure } = lastApiFetchDataActionsFunctions;

function* fetchAllActivitiesModule(action: Action): Generator {
  try {
    const { payload } = action as any;
    const total: number = (yield select(state => state.activities.total)) as number;
    const last_page = checkPage(payload.last_page, total);
    const path = `controls/activities/?page=${last_page}`;
    const has_next = yield select(state => state.activities.has_next);

    if(has_next || last_page === 1){
      const activitiesResponse: AxiosResponse<ActivitiesResults> | unknown = yield call(api,'GET',path,{});

      const {
        data: { results, count, next },
        config: { url },
        status,
        statusText,
      } = activitiesResponse as AxiosResponse<ActivitiesResults>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      yield put(getActivitiesModuleSuccess(results, count, last_page, !!next));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getActivitiesModuleFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchAllActivitiesProjetcSettings(): Generator {

  try {
    const activitiesResponse: AxiosResponse<Activities[]> | unknown = yield call(
      api,
      'GET',
      'projects/activities/',
      {}
    );

    const {
      data: activities,
      config: { url },
      status,
      statusText,
    } = activitiesResponse as AxiosResponse<Activities[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );


    /*  Corrigir isso aqui para funcionar em projects */
    yield put(getActivitiesModuleSuccess(activities, 1000, 1, true));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getActivitiesModuleFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
        DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchActivitiesDetails(action: Action): Generator {
  const { activitiesId } = action.payload as ActivitiesDetailsRequest;

  try {
    const activitiesDetailsResponse: AxiosResponse<ActivitiesDetails> | unknown = yield call(api, 'GET', `activities/${activitiesId}/detail/`, {});

    const {
      data: activitiesDetails,
      config: { url },
      status,
      statusText,
    } = activitiesDetailsResponse as AxiosResponse<ActivitiesDetails>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getActivitiesDetailsSuccess(activitiesDetails));

    toast.success(GET_ACTIVITIES_DETAILS_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getActivitiesDetailsFailure());

    toast.error(GET_ACTIVITIES_DETAILS_ERROR_MESSAGE);
  }
}

function* fetchSearchFilteredActivities(action: Action): Generator {
  const { filteredActivities, hasEvidence, isActive } = action.payload as SearchFilteredActivitiesRequestPayloadParams;

  const parsedResponses = filteredActivities.filter(response => response !== null).join(',');

  try {
    const filteredActivitiesResponse: AxiosResponse<ActivitiesResults> | unknown = yield call(
      api,
      'GET',
      `controls/activities/?search=${parsedResponses}&&has_evidence=${hasEvidence}&&is_active=${isActive}`,
      {}
    );

    const {
      data: filteredActivities,
      config: { url },
      status,
      statusText,
    } = filteredActivitiesResponse as AxiosResponse<ActivitiesResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredActivitiesSuccess(filteredActivities.results));
    toast.success(SEARCH_FILTERED_ACTIVITIES_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredActivitiesFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      SEARCH_FILTERED_ACTIVITIES_ERROR_MESSAGE
    );
  }
}

function* fetchSearchFilteredActivitiesProjectSettings(action: Action): Generator {
  const { filteredActivities, hasEvidence, isActive } = action.payload as SearchFilteredActivitiesRequestPayloadParams;

  const parsedResponses = filteredActivities.filter(response => response !== null).join(',');

  try {
    const filteredActivitiesResponse: AxiosResponse<Activities[]> | unknown = yield call(
      api,
      'GET',
      `projects/activities/?search=${parsedResponses}&&has_evidence=${hasEvidence}&&is_active=${isActive}`,
      {}
    );

    const {
      data: filteredActivities,
      config: { url },
      status,
      statusText,
    } = filteredActivitiesResponse as AxiosResponse<Activities[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredActivitiesSuccess(filteredActivities));
    toast.success(SEARCH_FILTERED_ACTIVITIES_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredActivitiesFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      SEARCH_FILTERED_ACTIVITIES_ERROR_MESSAGE
    );
  }
}

function* fetchAddNewActivities(action: Action): Generator {
  const {
    activities: { name, has_evidence, is_active },
  } = action.payload as AddNewActivitiesRequestPayload;

  try {
    const addNewActivitiesResponse: AxiosResponse<Activities> | unknown = yield call(
      api,
      'POST',
      'controls/activities/create/',
      {
        name,
        has_evidence,
        is_active,
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = addNewActivitiesResponse as AxiosResponse<Activities>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(addNewActivitiesSuccess());
    yield put(resetTheLastActivitiesDetails());
    yield put(getActivitiesModuleRequest());

    toast.success(NEW_ACTIVITIES_ADDED_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(addNewActivitiesFailure());

    if(currentError?.response?.data?.name[0] === 'activity with this name already exists.')
      toast.warn('Já existe uma atividade com este nome.');
    else {
      toast.error(
        currentError?.response?.data?.name[0] ?? currentError?.response?.data?.detail ?? NEW_ACTIVITIES_ADDED_ERROR_MESSAGE
      );
    }
  }
}

function* fetchToActivitiesEditing(action: Action): Generator {
  const { activitiesId, name, has_evidence, is_active } = action.payload as ActivitiesWithId;

  try {
    const activitiesEditingResponse: AxiosResponse<Activities> | unknown = yield call(
      api,
      'PUT',
      `controls/activities/${activitiesId}/`,
      {
        name,
        has_evidence,
        is_active
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = activitiesEditingResponse as AxiosResponse<Activities>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editActivitiesSuccess());
    yield put(getActivitiesModuleRequest());

    toast.success(EDIT_ACTIVITIES_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editActivitiesFailure());

    toast.error(
      currentError?.response?.data?.detail ?? EDIT_ACTIVITIES_ERROR_MESSAGE
    );
  }
}

function* fetchToUpdateActivitiesStatus(action: Action): Generator {
  const { id, updatedStatus } =
    action.payload as UpdateStatusRequestPayloadParams;

  try {
    const updateActivitiesStatusResponse: AxiosResponse<Activities> | unknown =
      yield call(api, 'PUT', `controls/activities/${id}/update-status/`, {
        is_active: updatedStatus,
      });

    const {
      config: { url },
      status,
      statusText,
    } = updateActivitiesStatusResponse as AxiosResponse<Activities>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(activeOrInactiveActivitiesSuccess());
    yield put(getActivitiesModuleRequest());

    toast.success(ACTIVE_OR_INACTIVE_ACTIVITIES_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(activeOrInactiveActivitiesFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      ACTIVE_OR_INACTIVE_ACTIVITIES_ERROR_MESSAGE
    );
  }
}

export function* activitiesSagas() {
  yield all([
    takeEvery(GET_ALL_ACTIVITIES_MODULE_REQUEST, fetchAllActivitiesModule),
    takeLatest(GET_ACTIVITIES_DETAILS_REQUEST, fetchActivitiesDetails),
    takeLatest(GET_FILTERED_ACTIVITIES_REQUEST, fetchSearchFilteredActivities),
    takeLatest(ADD_NEW_ACTIVITIES_REQUEST, fetchAddNewActivities),
    takeLatest(EDIT_ACTIVITIES_REQUEST, fetchToActivitiesEditing),
    takeLatest(ACTIVE_OR_INACTIVE_ACTIVITIES_REQUEST, fetchToUpdateActivitiesStatus),
    takeLatest(GET_ALL_ACTIVITIES_PROJECTSETTINGS_REQUEST, fetchAllActivitiesProjetcSettings),
    takeLatest(GET_FILTERED_ACTIVITIES_PROJECTSETTINGS_REQUEST, fetchSearchFilteredActivitiesProjectSettings)
  ]);
}
