import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import { usersActionsFunctions } from 'store/modules/users/actions';
import type { Action } from 'store/types';

import type {
  ApiFetchAddNewUserRequestPayloadParams,
  ApiFetchSearchFilteredUserRequestPayloadParams,
  ApiFetchSendEmailToUserRequestPayloadParams,
  ApiFetchToUpdateUserStatusRequestPayloadParams,
  ApiFetchToUserEditingRequestPayloadParams,
  UpdateUserStatusResponse,
  User,
} from './types';
import { UsersActions } from './types';

const {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE,
  NEW_USER_ADDED_SUCCESS_MESSAGE,
  NEW_USER_ADDED_ERROR_MESSAGE,
  SEARCH_FILTERED_USER_SUCCESS_MESSAGE,
  SEARCH_FILTERED_USER_ERROR_MESSAGE,
  SEND_EMAIL_TO_NEW_USER_SUCCESS_MESSAGE,
  SEND_EMAIL_TO_NEW_USER_ERROR_MESSAGE,
  EDIT_USER_SUCCESS_MESSAGE,
  EDIT_USER_ERROR_MESSAGE,
  ACTIVE_OR_INACTIVE_USER_SUCCESS_MESSAGE,
  ACTIVE_OR_INACTIVE_USER_ERROR_MESSAGE,
} = ToastMessages;

const {
  GET_ALL_USERS_REQUEST,
  GET_FILTERED_USER_REQUEST,
  ADD_NEW_USER_REQUEST,
  SEND_CONFIRMATION_EMAIL_TO_USER_REQUEST,
  EDIT_USER_REQUEST,
  ACTIVE_OR_INACTIVE_USER_REQUEST,
} = UsersActions;

const {
  getUsersRequest,
  getUsersSuccess,
  getUsersFailure,
  getSearchFilteredUserSuccess,
  getSearchFilteredUserFailure,
  addNewUserSuccess,
  addNewUserFailure,
  sendConfirmationEmailToUserSuccess,
  sendConfirmationEmailToUserFailure,
  editUserSuccess,
  editUserFailure,
  activeOrInactiveUserSuccess,
  activeOrInactiveUserFailure,
} = usersActionsFunctions;

const { getLastApiFetchDataSuccess, getLastApiFetchDataFailure } =
  lastApiFetchDataActionsFunctions;

function* fetchAllUsers(): Generator {
  try {
    const usersResponse: AxiosResponse<User[]> | unknown = yield call(
      api,
      'GET',
      'accounts/users/',
      {}
    );

    const {
      data: users,
      config: { url },
      status,
      statusText,
    } = usersResponse as AxiosResponse<User[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getUsersSuccess(users));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getUsersFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchSearchFilteredUser(action: Action): Generator {
  const { filteredUsers } =
    action.payload as ApiFetchSearchFilteredUserRequestPayloadParams;

  const parsedResponses = filteredUsers
    .filter(response => response !== null)
    .join(',');

  try {
    const usersResponse: AxiosResponse<User[]> | unknown = yield call(
      api,
      'GET',
      `accounts/users/?search=${parsedResponses}`,
      {}
    );

    const {
      data: users,
      config: { url },
      status,
      statusText,
    } = usersResponse as AxiosResponse<User[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredUserSuccess(users));

    toast.success(SEARCH_FILTERED_USER_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredUserFailure());

    toast.error(
      currentError?.response?.data?.detail ?? SEARCH_FILTERED_USER_ERROR_MESSAGE
    );
  }
}

function* fetchAddNewUser(action: Action): Generator {
  const { user } = action.payload as ApiFetchAddNewUserRequestPayloadParams;

  try {
    const addNewUserResponse: AxiosResponse<User> | unknown = yield call(
      api,
      'POST',
      'accounts/users/create/',
      {
        corporate_name_or_name: user.name,
        user_profile: user.user_profile,
        position: user.role,
        phone: user.cellPhone,
        email: user.email,
        profile: ["Analista"],
        is_customer: user.is_customer
      }
    );

    const {
      data,
      config: { url },
      status,
      statusText,
    } = addNewUserResponse as AxiosResponse<User>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(
      addNewUserSuccess({
        newUserAdded: data,
        sendAccessInvitation: user.sendAccessInvitation,
      })
    );

    yield put(getUsersRequest());

    toast.success(NEW_USER_ADDED_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(addNewUserFailure());

    toast.error(
      currentError?.response?.data?.msg[0] ??
        currentError?.response?.data?.email[0] ??
        currentError?.response?.data?.detail ??
        NEW_USER_ADDED_ERROR_MESSAGE
    );
  }
}

function* fetchSendEmailToNewUser(action: Action): Generator {
  const { userId } =
    action.payload as ApiFetchSendEmailToUserRequestPayloadParams;

  try {
    const sendEmailToNewUserResponse: AxiosResponse<User> | unknown =
      yield call(api, 'POST', 'accounts/send-email-confirmation/', {
        id: userId,
      });

    const {
      config: { url },
      status,
      statusText,
    } = sendEmailToNewUserResponse as AxiosResponse<User>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(sendConfirmationEmailToUserSuccess(userId));

    toast.success(SEND_EMAIL_TO_NEW_USER_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(sendConfirmationEmailToUserFailure());

    toast.error(
      currentError?.response?.data?.status ??
        SEND_EMAIL_TO_NEW_USER_ERROR_MESSAGE
    );
  }
}

function* fetchToUserEditing(action: Action): Generator {
  const { user } = action.payload as ApiFetchToUserEditingRequestPayloadParams;

  try {
    const userEditingResponse: AxiosResponse<User> | unknown = yield call(
      api,
      'PUT',
      `accounts/users/${user.id}/`,
      {
        user_profile: user.user_profile,
        position: user.position,
        phone: user.phone,
        profile: user.profile,
        is_customer: user.is_customer
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = userEditingResponse as AxiosResponse<User>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editUserSuccess());

    yield put(getUsersRequest());

    toast.success(EDIT_USER_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editUserFailure());

    toast.error(
      currentError?.response?.data?.detail ?? EDIT_USER_ERROR_MESSAGE
    );
  }
}

function* fetchToUpdateUserStatus(action: Action): Generator {
  const { userId, newUserStatus } =
    action.payload as ApiFetchToUpdateUserStatusRequestPayloadParams;

  try {
    const updateUserResponse:
      | AxiosResponse<UpdateUserStatusResponse>
      | unknown = yield call(
      api,
      'PUT',
      `accounts/users/${userId}/update-status/`,
      {
        status: newUserStatus,
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = updateUserResponse as AxiosResponse<UpdateUserStatusResponse>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(activeOrInactiveUserSuccess(newUserStatus));

    yield put(getUsersRequest());

    toast.success(ACTIVE_OR_INACTIVE_USER_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(activeOrInactiveUserFailure());

    toast.error(
      currentError?.response?.data?.detail ??
        ACTIVE_OR_INACTIVE_USER_ERROR_MESSAGE
    );
  }
}

export function* usersSagas() {
  yield all([
    takeLatest(GET_ALL_USERS_REQUEST, fetchAllUsers),
    takeLatest(GET_FILTERED_USER_REQUEST, fetchSearchFilteredUser),
    takeLatest(ADD_NEW_USER_REQUEST, fetchAddNewUser),
    takeLatest(
      SEND_CONFIRMATION_EMAIL_TO_USER_REQUEST,
      fetchSendEmailToNewUser
    ),
    takeLatest(EDIT_USER_REQUEST, fetchToUserEditing),
    takeLatest(ACTIVE_OR_INACTIVE_USER_REQUEST, fetchToUpdateUserStatus),
  ]);
}
