import { ThemeProvider } from '@mui/material';
import { Loading } from 'components/layout/Loading';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Routes } from 'routes/routes';
import { history } from 'services/history';
import { store, persistor } from 'store';
import { baseTheme } from 'styles/baseTheme';

import 'styles/global.scss';

export function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <ThemeProvider theme={baseTheme}>
          <Router history={history}>
            <Routes />
          </Router>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}
