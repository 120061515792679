import type { Action } from 'store/types';

import type {
  AddNewRiskFactorData,
  EditRiskFactorData,
  RiskFactor,
  RiskFactorStatic,
} from './types';
import { RiskFactorsActions } from './types';

const {
  GET_ALL_RISK_FACTORS_REQUEST,
  GET_ALL_RISK_FACTORS_SUCCESS,
  GET_ALL_RISK_FACTORS_FAILURE,
  GET_FILTERED_RISK_FACTOR_REQUEST,
  GET_FILTERED_RISK_FACTOR_SUCCESS,
  GET_FILTERED_RISK_FACTOR_FAILURE,

  GET_RISK_FACTOR_DETAIL_REQUEST,
  GET_RISK_FACTOR_DETAIL_SUCCESS,
  GET_RISK_FACTOR_DETAIL_FAILURE,
  ADD_NEW_RISK_FACTOR_REQUEST,
  ADD_NEW_RISK_FACTOR_SUCCESS,
  ADD_NEW_RISK_FACTOR_FAILURE,
  EDIT_RISK_FACTOR_REQUEST,
  EDIT_RISK_FACTOR_SUCCESS,
  EDIT_RISK_FACTOR_FAILURE,
  ACTIVE_OR_INACTIVE_RISK_FACTOR_REQUEST,
  ACTIVE_OR_INACTIVE_RISK_FACTOR_SUCCESS,
  ACTIVE_OR_INACTIVE_RISK_FACTOR_FAILURE,
  RESET_THE_RISK_FACTOR_DETAIL_STATE,

  GET_ALL_RISK_FACTORS_STATIC_REQUEST,
  GET_ALL_RISK_FACTORS_STATIC_SUCCESS,
  GET_ALL_RISK_FACTORS_STATIC_FAILURE,
  GET_FILTERED_RISK_STATIC_FACTOR_REQUEST,
  GET_FILTERED_RISK_FACTOR_STATIC_SUCCESS,
  GET_FILTERED_RISK_FACTOR_STATIC_FAILURE,
} = RiskFactorsActions;

// Assimque possível remover esse código
function getRiskFactorsRequest(last_page?: number): Action {
  return {
    type: GET_ALL_RISK_FACTORS_REQUEST,
    payload: { 
      last_page
    }
  };
}
// Assimque possível remover esse código
function getRiskFactorsSuccess(riskFactors: RiskFactor[], total: number, last_page: number, has_next: boolean): Action {
  return {
    type: GET_ALL_RISK_FACTORS_SUCCESS,
    payload: {
      riskFactors,
      total,
      last_page, 
      has_next
    },
  };
}
// Assimque possível remover esse código
function getRiskFactorsFailure(): Action {
  return {
    type: GET_ALL_RISK_FACTORS_FAILURE,
  };
}
// Assimque possível remover esse código
function getSearchFilteredRiskFactorRequest(
  filteredRiskFactors: Array<string | boolean | null>
): Action {
  return {
    type: GET_FILTERED_RISK_FACTOR_REQUEST,
    payload: {
      filteredRiskFactors,
    },
  };
}
// Assimque possível remover esse código
function getSearchFilteredRiskFactorSuccess(riskFactors: RiskFactor[]): Action {
  return {
    type: GET_FILTERED_RISK_FACTOR_SUCCESS,
    payload: {
      riskFactors,
    },
  };
}
// Assimque possível remover esse código
function getSearchFilteredRiskFactorFailure(): Action {
  return {
    type: GET_FILTERED_RISK_FACTOR_FAILURE,
  };
}

function getRiskFactorDetailRequest(riskFactorId: string): Action {
  return {
    type: GET_RISK_FACTOR_DETAIL_REQUEST,
    payload: {
      riskFactorId,
    },
  };
}

function getRiskFactorDetailSuccess(riskFactor: RiskFactor): Action {
  return {
    type: GET_RISK_FACTOR_DETAIL_SUCCESS,
    payload: {
      riskFactor,
    },
  };
}

function getRiskFactorDetailFailure(): Action {
  return {
    type: GET_RISK_FACTOR_DETAIL_FAILURE,
  };
}

function addNewRiskFactorRequest(newRiskFactor: AddNewRiskFactorData): Action {
  return {
    type: ADD_NEW_RISK_FACTOR_REQUEST,
    payload: {
      newRiskFactor,
    },
  };
}

function addNewRiskFactorSuccess(riskFactorAdded: RiskFactor): Action {
  return {
    type: ADD_NEW_RISK_FACTOR_SUCCESS,
    payload: {
      riskFactorAdded,
    },
  };
}

function addNewRiskFactorFailure(): Action {
  return {
    type: ADD_NEW_RISK_FACTOR_FAILURE,
  };
}

function editRiskFactorRequest(updatedRiskFactor: EditRiskFactorData): Action {
  return {
    type: EDIT_RISK_FACTOR_REQUEST,
    payload: {
      updatedRiskFactor,
    },
  };
}

function editRiskFactorSuccess(): Action {
  return {
    type: EDIT_RISK_FACTOR_SUCCESS,
  };
}

function editRiskFactorFailure(): Action {
  return {
    type: EDIT_RISK_FACTOR_FAILURE,
  };
}

function activeOrInactiveRiskFactorRequest(
  riskFactorId: string,
  newRiskFactorStatus: boolean
): Action {
  return {
    type: ACTIVE_OR_INACTIVE_RISK_FACTOR_REQUEST,
    payload: {
      riskFactorId,
      newRiskFactorStatus,
    },
  };
}

function activeOrInactiveRiskFactorSuccess(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_RISK_FACTOR_SUCCESS,
  };
}

function activeOrInactiveRiskFactorFailure(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_RISK_FACTOR_FAILURE,
  };
}

function resetTheRiskFactorDetailState(): Action {
  return {
    type: RESET_THE_RISK_FACTOR_DETAIL_STATE,
  };
}

function getRiskFactorStaticRequest(last_page?: number): Action {
  return {
    type: GET_ALL_RISK_FACTORS_STATIC_REQUEST,
    payload: { 
      last_page
    }
  };
}

function getRiskFactorStaticSuccess(
    riskFactorStatic: RiskFactorStatic[], 
    total: number, 
    last_page: number, 
    has_next: boolean
  ): Action {
  return {
    type: GET_ALL_RISK_FACTORS_STATIC_SUCCESS,
    payload: {
      riskFactorStatic,
      total,
      last_page, 
      has_next
    },
  };
}

function getRiskFactorStaticFailure(): Action {
  return {
    type: GET_ALL_RISK_FACTORS_STATIC_FAILURE,
  };
}

function getSearchFilteredRiskFactorStaticRequest(
  filteredRiskFactors: Array<string | boolean | null>
): Action {
  return {
    type: GET_FILTERED_RISK_STATIC_FACTOR_REQUEST,
    payload: {
      filteredRiskFactors,
    },
  };
}

function getSearchFilteredRiskFactorStaticSuccess(riskFactorStatic: RiskFactorStatic[]): Action {
  return {
    type: GET_FILTERED_RISK_FACTOR_STATIC_SUCCESS,
    payload: {
      riskFactorStatic,
    },
  };
}

function getSearchFilteredRiskFactorStaticFailure(): Action {
  return {
    type: GET_FILTERED_RISK_FACTOR_STATIC_FAILURE,
  };
}

export const riskFactorsActionsFunctions = {
  getRiskFactorsRequest,
  getRiskFactorsSuccess,
  getRiskFactorsFailure,
  getSearchFilteredRiskFactorRequest,
  getSearchFilteredRiskFactorSuccess,
  getSearchFilteredRiskFactorFailure,

  getRiskFactorDetailRequest,
  getRiskFactorDetailSuccess,
  getRiskFactorDetailFailure,
  addNewRiskFactorRequest,
  addNewRiskFactorSuccess,
  addNewRiskFactorFailure,
  editRiskFactorRequest,
  editRiskFactorSuccess,
  editRiskFactorFailure,
  activeOrInactiveRiskFactorRequest,
  activeOrInactiveRiskFactorSuccess,
  activeOrInactiveRiskFactorFailure,
  resetTheRiskFactorDetailState,

  getRiskFactorStaticRequest,
  getRiskFactorStaticSuccess,
  getRiskFactorStaticFailure,
  getSearchFilteredRiskFactorStaticRequest,
  getSearchFilteredRiskFactorStaticSuccess,
  getSearchFilteredRiskFactorStaticFailure,
};
