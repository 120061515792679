import produce from 'immer';
import { Reducer } from 'redux';

import type { DamagesState } from './types';
import { DamagesActions } from './types';

const {
  GET_ALL_DAMAGES_REQUEST,
  GET_ALL_DAMAGES_SUCCESS,
  GET_ALL_DAMAGES_FAILURE,
  GET_FILTERED_DAMAGE_REQUEST,
  GET_FILTERED_DAMAGE_SUCCESS,
  GET_FILTERED_DAMAGE_FAILURE,
  GET_DAMAGE_DETAIL_REQUEST,
  GET_DAMAGE_DETAIL_SUCCESS,
  GET_DAMAGE_DETAIL_FAILURE,
  ADD_NEW_DAMAGE_REQUEST,
  ADD_NEW_DAMAGE_SUCCESS,
  ADD_NEW_DAMAGE_FAILURE,
  EDIT_DAMAGE_REQUEST,
  EDIT_DAMAGE_SUCCESS,
  EDIT_DAMAGE_FAILURE,
  ACTIVE_OR_INACTIVE_DAMAGE_REQUEST,
  ACTIVE_OR_INACTIVE_DAMAGE_SUCCESS,
  ACTIVE_OR_INACTIVE_DAMAGE_FAILURE,
  RESET_THE_DAMAGE_DETAIL_STATE,
} = DamagesActions;

const initialState: DamagesState = {
  damages: [],
  visited_pages: [],
  has_next: true,
  total: 0,
  currentDamageDetail: {
    id: '',
    friendly_id: '',
    name: '',
    type: '',
    threats: [],
    is_active: false,
  },
};

export const damagesReducer: Reducer<DamagesState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_DAMAGES_REQUEST: {
        break;
      }

      case GET_ALL_DAMAGES_SUCCESS: {
        const { damages, total, last_page, has_next } = action.payload;
        const pagineAlreadyVisited = state.visited_pages.find (page => page === last_page);

        if(!pagineAlreadyVisited || state.damages) {
          draft.visited_pages.push(last_page);
          draft.damages = last_page === 1 ? damages : [...state.damages, ...damages];
          draft.total = total;
          draft.has_next = has_next;
        }

        break;
      }

      case GET_ALL_DAMAGES_FAILURE: {
        draft.damages = [];

        break;
      }

      case GET_FILTERED_DAMAGE_REQUEST: {
        break;
      }

      case GET_FILTERED_DAMAGE_SUCCESS: {
        const { damages } = action.payload;

        draft.damages = damages;

        break;
      }

      case GET_FILTERED_DAMAGE_FAILURE: {
        break;
      }

      case ADD_NEW_DAMAGE_REQUEST: {
        break;
      }

      case ADD_NEW_DAMAGE_SUCCESS: {
        const { damageAdded } = action.payload;

        draft.currentDamageDetail = damageAdded;

        break;
      }

      case ADD_NEW_DAMAGE_FAILURE: {
        break;
      }

      case GET_DAMAGE_DETAIL_REQUEST: {
        break;
      }

      case GET_DAMAGE_DETAIL_SUCCESS: {
        const { damage } = action.payload;

        draft.currentDamageDetail = damage;

        break;
      }

      case GET_DAMAGE_DETAIL_FAILURE: {
        break;
      }

      case EDIT_DAMAGE_REQUEST: {
        break;
      }

      case EDIT_DAMAGE_SUCCESS: {
        break;
      }

      case EDIT_DAMAGE_FAILURE: {
        break;
      }

      case ACTIVE_OR_INACTIVE_DAMAGE_REQUEST: {
        break;
      }

      case ACTIVE_OR_INACTIVE_DAMAGE_SUCCESS: {
        break;
      }

      case ACTIVE_OR_INACTIVE_DAMAGE_FAILURE: {
        break;
      }

      case RESET_THE_DAMAGE_DETAIL_STATE: {
        draft.currentDamageDetail.id = '';
        draft.currentDamageDetail.friendly_id = '';
        draft.currentDamageDetail.name = '';
        draft.currentDamageDetail.type = '';
        draft.currentDamageDetail.threats = [];
        draft.currentDamageDetail.is_active = false;

        break;
      }

      default: {
        return draft;
      }
    }
  });
};
