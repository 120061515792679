export enum SolutionsActions {
  EDIT_SOLUTION_REQUEST = 'EDIT_SOLUTION_REQUEST',
  EDIT_SOLUTION_SUCCESS = 'EDIT_SOLUTION_SUCCESS',
  EDIT_SOLUTION_FAILURE = 'EDIT_SOLUTION_FAILURE',
  ADD_NEW_SOLUTION_REQUEST = 'ADD_NEW_SOLUTION_REQUEST',
  ADD_NEW_SOLUTION_SUCCESS = 'ADD_NEW_SOLUTION_SUCCESS',
  ADD_NEW_SOLUTION_FAILURE = 'ADD_NEW_SOLUTION_FAILURE',
  GET_ALL_SOLUTIONS_REQUEST = 'GET_ALL_SOLUTIONS_REQUEST',
  GET_ALL_SOLUTIONS_SUCCESS = 'GET_ALL_SOLUTIONS_SUCCESS',
  GET_ALL_SOLUTIONS_FAILURE = 'GET_ALL_SOLUTIONS_FAILURE',
  GET_SOLUCTION_DETAIL_REQUEST = 'GET_SOLUCTION_DETAIL_REQUEST',
  GET_SOLUCTION_DETAIL_SUCCESS = 'GET_SOLUCTION_DETAIL_SUCCESS',
  GET_SOLUCTION_DETAIL_FAILURE = 'GET_SOLUCTION_DETAIL_FAILURE',
  EDIT_SOLUTION_CONTROL_REQUEST = 'EDIT_SOLUTION_CONTROL_REQUEST',
  EDIT_SOLUTION_CONTROL_SUCCESS = 'EDIT_SOLUTION_CONTROL_SUCCESS',
  EDIT_SOLUTION_CONTROL_FAILURE = 'EDIT_SOLUTION_CONTROL_FAILURE',
  GET_SOLUTIONS_PROJECT_REQUEST = 'GET_SOLUTIONS_PROJECT_REQUEST',
  GET_SOLUTIONS_PROJECT_SUCCESS = 'GET_SOLUTIONS_PROJECT_SUCCESS',
  GET_SOLUTIONS_PROJECT_FAILURE = 'GET_SOLUTIONS_PROJECT_FAILURE',
  LINK_CSFCORE_SOLUTION_REQUEST = 'LINK_CSFCORE_SOLUTION_REQUEST',
  LINK_CSFCORE_SOLUTION_SUCCESS = 'LINK_CSFCORE_SOLUTION_SUCCESS',
  LINK_CSFCORE_SOLUTION_FAILURE = 'LINK_CSFCORE_SOLUTION_FAILURE',  
  GET_FILTERED_SOLUTIONS_REQUEST = 'GET_FILTERED_SOLUTIONS_REQUEST',
  GET_FILTERED_SOLUTIONS_SUCCESS = 'GET_FILTERED_SOLUTIONS_SUCCESS',
  GET_FILTERED_SOLUTIONS_FAILURE = 'GET_FILTERED_SOLUTIONS_FAILURE',
  LINK_OR_UNLINK_CONTROLS_REQUEST = 'LINK_OR_UNLINK_CONTROLS_REQUEST',
  LINK_OR_UNLINK_CONTROLS_SUCCESS = 'LINK_OR_UNLINK_CONTROLS_SUCCESS',
  LINK_OR_UNLINK_CONTROLS_FAILURE = 'LINK_OR_UNLINK_CONTROLS_FAILURE',
  UPDATE_CSFCORE_SOLUTION_REQUEST = 'UPDATE_CSFCORE_SOLUTION_REQUEST',
  UPDATE_CSFCORE_SOLUTION_SUCCESS = 'UPDATE_CSFCORE_SOLUTION_SUCCESS',
  UPDATE_CSFCORE_SOLUTION_FAILURE = 'UPDATE_CSFCORE_SOLUTION_FAILURE',
  EDIT_SOLUTION_INFORMATION_REQUEST = 'EDIT_SOLUTION_INFORMATION_REQUEST',
  EDIT_SOLUTION_INFORMATION_SUCCESS = 'EDIT_SOLUTION_INFORMATION_SUCCESS',
  EDIT_SOLUTION_INFORMATION_FAILURE = 'EDIT_SOLUTION_INFORMATION_FAILURE',
  ACTIVE_OR_INACTIVE_SOLUCTIONS_REQUEST = 'ACTIVE_OR_INACTIVE_SOLUCTIONS_REQUEST',
  ACTIVE_OR_INACTIVE_SOLUCTIONS_SUCCESS = 'ACTIVE_OR_INACTIVE_SOLUCTIONS_SUCCESS',
  ACTIVE_OR_INACTIVE_SOLUCTIONS_FAILURE = 'ACTIVE_OR_INACTIVE_SOLUCTIONS_FAILURE',
  GET_SOLUTIONS_CSFCORE_PROJECT_REQUEST = 'GET_SOLUTIONS_CSFCORE_PROJECT_REQUEST',
  GET_SOLUTIONS_CSFCORE_PROJECT_SUCCESS = 'GET_SOLUTIONS_CSFCORE_PROJECT_SUCCESS',
  GET_SOLUTIONS_CSFCORE_PROJECT_FAILURE = 'GET_SOLUTIONS_CSFCORE_PROJECT_FAILURE',
  EDIT_CONTROLS_SOLUTION_RANKING_REQUEST = 'EDIT_CONTROLS_SOLUTION_RANKING_REQUEST',
  EDIT_CONTROLS_SOLUTION_RANKING_SUCCESS = 'EDIT_CONTROLS_SOLUTION_RANKING_SUCCESS',
  EDIT_CONTROLS_SOLUTION_RANKING_FAILURE = 'EDIT_CONTROLS_SOLUTION_RANKING_FAILURE',
  ADD_AND_REMOVE_SOLUTION_CONTROLS_REQUEST = 'ADD_AND_REMOVE_SOLUTION_CONTROLS_REQUEST',
  ADD_AND_REMOVE_SOLUTION_CONTROLS_SUCCESS = 'ADD_AND_REMOVE_SOLUTION_CONTROLS_SUCCESS',
  ADD_AND_REMOVE_SOLUTION_CONTROLS_FAILURE = 'ADD_AND_REMOVE_SOLUTION_CONTROLS_FAILURE',
  GET_CONTROL_SOLUTIONS_PROJECT_DETAIL_REQUEST = 'GET_CONTROL_SOLUTIONS_PROJECT_DETAIL_REQUEST',
  GET_CONTROL_SOLUTIONS_PROJECT_DETAIL_SUCCESS = 'GET_CONTROL_SOLUTIONS_PROJECT_DETAIL_SUCCESS',
  GET_CONTROL_SOLUTIONS_PROJECT_DETAIL_FAILURE = 'GET_CONTROL_SOLUTIONS_PROJECT_DETAIL_FAILURE',
  ACTIVE_OR_INACTIVE_CONTROL_SOLUCTION_REQUEST = 'ACTIVE_OR_INACTIVE_CONTROL_SOLUCTION_REQUEST',
  ACTIVE_OR_INACTIVE_CONTROL_SOLUCTION_SUCCESS = 'ACTIVE_OR_INACTIVE_CONTROL_SOLUCTION_SUCCESS',
  ACTIVE_OR_INACTIVE_CONTROL_SOLUCTION_FAILURE = 'ACTIVE_OR_INACTIVE_CONTROL_SOLUCTION_FAILURE',
  EDIT_SOLUTION_CUSTOMER_PROJECT_STATUS_REQUEST = 'EDIT_SOLUTION_CUSTOMER_PROJECT_STATUS_REQUEST',
  EDIT_SOLUTION_CUSTOMER_PROJECT_STATUS_SUCCESS = 'EDIT_SOLUTION_CUSTOMER_PROJECT_STATUS_SUCCESS',
  EDIT_SOLUTION_CUSTOMER_PROJECT_STATUS_FAILURE = 'EDIT_SOLUTION_CUSTOMER_PROJECT_STATUS_FAILURE',
  GENERATE_CONTROL_SOLUTION_CUSTOMER_PROJECT_REQUEST = 'GENERATE_CONTROL_SOLUTION_CUSTOMER_PROJECT_REQUEST',
  GENERATE_CONTROL_SOLUTION_CUSTOMER_PROJECT_SUCCESS = 'GENERATE_CONTROL_SOLUTION_CUSTOMER_PROJECT_SUCCESS',
  GENERATE_CONTROL_SOLUTION_CUSTOMER_PROJECT_FAILURE = 'GENERATE_CONTROL_SOLUTION_CUSTOMER_PROJECT_FAILURE',  
}

export type JustId = {
  id: string;
}

export type OpenedSolutionsModal = {
  SolutionsModal: boolean;
  ActiveOrInactiveModal: boolean;
  ActiveOrInactiveControlModal: boolean;
};

export type Solution = {
  id: string;
  name: string;
  is_active: boolean;
}

export type AllSolutions = {
  count: number | null;
  next: number | null;
  previous: number | null;
  results: Solution[];
}

export type ObjectWithIdAndName = {
  id: string;
  name: string;
}

export type Category = {
  id: string;
  name: string;
  importance: number;
  description: string;
}

export type SolutionControls = {
  id: string;
  name: string;
  control_type: ObjectWithIdAndName;
  class_name: ObjectWithIdAndName;
  group_name: ObjectWithIdAndName;
  type_name: ObjectWithIdAndName;
  category: Category;
}

export type SolutionDetails = {
  id: string;
  name: string;
  is_active: boolean;
  controls: SolutionControls[];
}

export type SolutionProject = {
  id: string;
  name: string;
  position: number;
  sum_total_benefit: number;
  total_value: number;
  is_active: boolean;
  solution_implementation_deadline_months: number;
  controls: ControlWithAmountAndMeasure[];
}

export type SolutionsProjectDetail = {
  solutions: SolutionProject[];
}

export type ControlSolution = {
  id: string;
  name: string;
  is_active: boolean;
  total_benefit: number;
  control_risk_customer_project_id: string;
}

export type SolutionControlsDetail = {
  id: string;
  name: string;
  is_active: boolean;
  position: number;
  controls: ControlSolution[];
}

export type CsfCoreSolutionSerializer = {
  id: string;
  friendly_id: string;
  csf_function: string;
  solution_projects_id: string;
  solution_projects_name: string;
  solution_projects_position: number;
  solution_projects_solution_implementation_deadline_months: number;
  solution_projects_total_value: number;
};

export type SolutionProjectCSFCore = {
  id: string;
  name: string;
  position: number;
  solution_implementation_deadline_months: number;
  total_value: number;
}

export type CsfCoreSolution = {
  id: string;
  friendly_id: string;
  csf_function: string;
  solution_projects: SolutionProjectCSFCore[];
}

export type SolutionCsfCoreProject = {
  id: string;
  name: string;
  csf_core_projects: CsfCoreSolution[];
}

export type SolutionsState = {
  allSolutions: AllSolutions;
  solutionDetails: SolutionDetails;
  solutionsProjectDetail: SolutionsProjectDetail;
  solutionControlsDetail: SolutionControlsDetail;
  solutionCsfCoreProject: SolutionCsfCoreProject;
};

export type SearchedSimpleControls = {
  name: string | null;
  controlType: string | null;
  groupName: string | null;
  typeName: string | null;
  category: string | null;
  className: string | null;
  is_active: string | null;
};

export type SolutionPayload = {
  name: string;
}

export type EditSolutionPayload = {
  id: string;
  name: string;
}

export type UpdateStatusSolutionPayload = {
  id: string | undefined;
  is_active: boolean;
}

export type SolutionIdPayload = {
  id: string;
}

export type SolutionControlsPayload = {
  id: string;
  controlsAdd: JustId[];
  controlsRemove: JustId[];
}

export type SolutionProjectIdPayload = {
  id: string;
}

export type SolutionProjectStatusPayload = {
  id: string;
  justification_of_inactivation: string;
  is_active: boolean;
  customerProjectId: string;
}

export type UpdateStatusControlSolutionPayload = {
  controlId: string | undefined;
  is_active: boolean;
  solutionId: string;
}

export type ChangeRankingControlSolutionPayload = {
  id: string;
  position: number;
  customerProjectId: string;
}

export type ControlWithAmountAndMeasure = {
  id: string;
  control_risk_customer_project_id: string;
  name: string;
  total_benefit: number;
  unit_of_measure: string;
  amount: number;
  is_active: boolean;
}

export type SolutionControlPayload = {
  solution_control_risk_id: string,
  unit_of_measure: string | null,
  amount: number,
};

export type SolutionInformationPayload = {
  solution_project_id: string,
  total_value: number,
  solution_implementation_deadline_months: number
};

export type SearchFilteredSolutionsRequestPayloadParams = {
  filteredSolutions: Array<string | boolean | null>;
  isActive: boolean | null;
};

export type SolutionCsfCoreProjectPayload = {
  id: string;
  projectId: string;
  solution_projects: string[];
}