/* eslint-disable no-bitwise */
import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { history } from 'services/history';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import { vulnerabilitiesActionsFunctions } from 'store/modules/Manutention/vulnerabilities/actions';
import type { Action } from 'store/types';
import { checkPage } from 'utils/number';

import { ControlDetailResults } from '../Control/types';
import type {
  AddNewVulnerabilityRequestPayload,
  AddNewVulnerabilityResponseData,
  ControlEnums,
  CVEResults,
  EditVulnerabilityRequestPayload,
  SearchFilteredControlRequestPayload,
  SearchFilteredThreatRequestPayload,
  SearchFilteredVulnerabilityRequestPayload,
  Threat,
  ThreatResults,
  UpdateVulnerabilityStatusRequestPayload,
  Vulnerability,
  VulnerabilityControlResults,
  VulnerabilityDetailRequestPayload,
  VulnerabilityResults,
  SearchFilteredCVEsRequestPayload,
  CVE,
  CVEDetailRequestPayload,
  SimpleVulnerabilityResults,
  SimpleControl,
  Control,
} from './types';
import { VulnerabilitiesActions } from './types';

const {
  DEFAULT_ERROR_MESSAGE,
  NEW_VULNERABILITY_ADDED_SUCCESS_MESSAGE,
  NEW_VULNERABILITY_ADDED_ERROR_MESSAGE,
  SEARCH_FILTERED_VULNERABILITY_SUCCESS_MESSAGE,
  SEARCH_FILTERED_VULNERABILITY_ERROR_MESSAGE,
  SEARCH_FILTERED_THREAT_SUCCESS_MESSAGE,
  SEARCH_FILTERED_THREAT_ERROR_MESSAGE,
  SEARCH_FILTERED_CONTROL_SUCCESS_MESSAGE,
  SEARCH_FILTERED_CONTROL_ERROR_MESSAGE,
  EDIT_VULNERABILITY_SUCCESS_MESSAGE,
  EDIT_VULNERABILITY_ERROR_MESSAGE,
  ACTIVE_OR_INACTIVE_VULNERABILITY_SUCCESS_MESSAGE,
  ACTIVE_OR_INACTIVE_VULNERABILITY_ERROR_MESSAGE,
  SEARCH_FILTERED_CVE_SUCCESS_MESSAGE,
  SEARCH_FILTERED_CVE_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE
} = ToastMessages;

const {
  GET_ALL_VULNERABILITIES_REQUEST,
  GET_VULNERABILITY_DETAIL_REQUEST,
  GET_FILTERED_PROJECTS_VULNERABILITY_REQUEST,
  GET_FILTERED_THREAT_REQUEST,
  GET_FILTERED_PROJECTS_CONTROL_REQUEST,
  ADD_NEW_VULNERABILITY_REQUEST,
  EDIT_VULNERABILITY_REQUEST,
  ACTIVE_OR_INACTIVE_VULNERABILITY_REQUEST,
  GET_ALL_CVEs_REQUEST,
  GET_ALL_THREATS_REQUEST,
  GET_ALL_CONTROLS_REQUEST,
  GET_ALL_CONTROL_ENUMS_REQUEST,
  GET_FILTERED_CVE_REQUEST,
  GET_CVE_DETAIL_REQUEST,
  GET_ALL_CVES_MODULE_REQUEST,
  GET_ALL_SIMPLE_VULNERABILITIES_REQUEST,
  GET_ALL_SIMPLE_CONTROLS_REQUEST,
  GET_FILTERED_SIMPLE_CONTROL_REQUEST,
} = VulnerabilitiesActions;

const {
  getVulnerabilitiesRequest,
  getVulnerabilitiesSuccess,
  getVulnerabilitiesFailure,
  getSearchFilteredVulnerabilitySuccess,
  getSearchFilteredVulnerabilityFailure,
  getSearchFilteredThreatSuccess,
  getSearchFilteredThreatFailure,
  getSearchFilteredControlSuccess,
  getSearchFilteredControlFailure,
  getVulnerabilityDetailSuccess,
  getVulnerabilityDetailFailure,
  addNewVulnerabilitySuccess,
  addNewVulnerabilityFailure,
  editVulnerabilitySuccess,
  editVulnerabilityFailure,
  activeOrInactiveVulnerabilitySuccess,
  activeOrInactiveVulnerabilityFailure,
  getCVEsRequest,
  getCVEsSuccess,
  getCVEsFailure,
  getThreatsRequest,
  getThreatsSuccess,
  getThreatsFailure,
  getControlsRequest,
  getControlsSuccess,
  getControlsFailure,
  getControlEnumsSuccess,
  getControlEnumsFailure,
  resetTheVulnerabilityDetailState,
  getSearchFilteredCVESuccess,
  getSearchFilteredCVEFailure,
  getCVEDetailSuccess,
  getCVEsModuleSuccess,
  getCVEsModuleFailure,
  getSimpleVulnerabilitiesSuccess,
  getSimpleVulnerabilitiesFailure,
  getSimpleControlsSuccess,
  getSimpleControlsFailure,
  getSearchFilteredSimpleControlSuccess,
  getSearchFilteredSimpleControlFailure,
} = vulnerabilitiesActionsFunctions;

const { getLastApiFetchDataSuccess, getLastApiFetchDataFailure } =
  lastApiFetchDataActionsFunctions;

function* fetchAllVulnerabilities(action: Action): Generator {
  try {
    const { payload } = action as any;
    const total: number = (yield select(state => state.vulnerabilities.total_vulnerabilities)) as number;
    const last_page = checkPage(payload.last_page, total);
    const path = `controls/vulnerabilities/?page=${last_page}`;
    const has_next = yield select(state => state.vulnerabilities.has_next_vulnerabilities);
    const vulnerabilities = (yield select(state => state.vulnerabilities.vulnerabilities)) as number[];

    if (has_next || !vulnerabilities.length || last_page === 1) {
      const vulnerabilitiesResponse: AxiosResponse<VulnerabilityResults> | unknown =
        yield call(api, 'GET', path, {});

      const {
        data: { results, count, next, },
        config: { url },
        status,
        statusText,
      } = vulnerabilitiesResponse as AxiosResponse<VulnerabilityResults>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      yield put(getVulnerabilitiesSuccess(results, count, last_page, !!next));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getVulnerabilitiesFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchSearchFilteredVulnerability(action: Action): Generator {
  const { filteredVulnerabilities } = action.payload as SearchFilteredVulnerabilityRequestPayload;

  const hasFilteredOptionsWithoutStatus = filteredVulnerabilities
    .filter(response => response !== null && typeof response !== 'boolean')
    .join(',');

  const hasStatusFilteredOption = filteredVulnerabilities.some(
    option => typeof option === 'boolean'
  );

  const filteredOptionStatus = `?is_active=${filteredVulnerabilities.filter(
    response => typeof response === 'boolean'
  )}`;

  const filteredOptionsWithoutStatus = `?search=${filteredVulnerabilities
    .filter(response => response !== null && typeof response !== 'boolean')
    .join(',')}`;

  const routeWithFilter = `controls/vulnerabilities/${hasFilteredOptionsWithoutStatus ? filteredOptionsWithoutStatus : ''
    }${hasStatusFilteredOption ? filteredOptionStatus : ''}`;

  try {
    const vulnerabilitiesResponse: AxiosResponse<SimpleVulnerabilityResults> | unknown = yield call(api, 'GET', routeWithFilter, {});

    const {
      data: simpleVulnerability,
      config: { url },
      status,
      statusText,
    } = vulnerabilitiesResponse as AxiosResponse<SimpleVulnerabilityResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    if (status === 200) {
      yield put(getSearchFilteredVulnerabilitySuccess(simpleVulnerability.results));
      toast.success(SEARCH_FILTERED_VULNERABILITY_SUCCESS_MESSAGE);
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredVulnerabilityFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      SEARCH_FILTERED_VULNERABILITY_ERROR_MESSAGE
    );
  }
}

function* fetchSearchFilteredThreat(action: Action): Generator {
  const { filteredThreats } =
    action.payload as SearchFilteredThreatRequestPayload;

  const parsedResponses = filteredThreats
    .filter(response => response !== null)
    .join(',');

  try {
    const filteredThreatsResponse: AxiosResponse<Threat[]> | unknown =
      yield call(api, 'GET', `controls/threats/?search=${parsedResponses}`, {});

    const {
      data: threats,
      config: { url },
      status,
      statusText,
    } = filteredThreatsResponse as AxiosResponse<Threat[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredThreatSuccess(threats));

    toast.success(SEARCH_FILTERED_THREAT_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredThreatFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      SEARCH_FILTERED_THREAT_ERROR_MESSAGE
    );
  }
}

function ReturnAPIRequest(pSearch: string, pActive: boolean | null): string {
  let result = 'controls/controls/?';
  if (pSearch !== '')
    result = `${result}search=${pSearch}`;

  if (pActive !== null) {
    if (pSearch === '')
      result = `${result}is_active=${pActive}`;
    else
      result = `${result}&&is_active=${pActive}`;
  }

  return result;
}

function* fetchSearchFilteredControl(action: Action): Generator {
  const { filteredControls, isActive } =
    action.payload as SearchFilteredControlRequestPayload;

  const parsedResponses = filteredControls
    .filter(response => response !== null)
    .join(',');

  try {
    const filteredControlsResponse: AxiosResponse<VulnerabilityControlResults> | unknown =
      yield call(
        api,
        'GET',
        ReturnAPIRequest(parsedResponses, isActive),
        {}
      );

    const {
      data: controls,
      config: { url },
      status,
      statusText,
    } = filteredControlsResponse as AxiosResponse<VulnerabilityControlResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredControlSuccess(controls.results as Control[]));

    toast.success(SEARCH_FILTERED_CONTROL_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredControlFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      SEARCH_FILTERED_CONTROL_ERROR_MESSAGE
    );
  }
}

function* fetchAddNewVulnerability(action: Action): Generator {
  const { newVulnerability } =
    action.payload as AddNewVulnerabilityRequestPayload;

  try {
    const addNewVulnerabilityResponse:
      | AxiosResponse<AddNewVulnerabilityResponseData>
      | unknown = yield call(api, 'POST', 'controls/vulnerabilities/create/', {
        ...newVulnerability,
      });

    const {
      data: vulnerabilityAdded,
      config: { url },
      status,
      statusText,
    } = addNewVulnerabilityResponse as AxiosResponse<AddNewVulnerabilityResponseData>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(addNewVulnerabilitySuccess(vulnerabilityAdded));

    history.push(`/vulnerability-registration/${vulnerabilityAdded.id}`);

    yield put(getVulnerabilitiesRequest());
    yield put(getCVEsRequest());
    yield put(getThreatsRequest());
    yield put(getControlsRequest());

    toast.success(NEW_VULNERABILITY_ADDED_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(resetTheVulnerabilityDetailState());
    yield put(addNewVulnerabilityFailure());

    if (currentError?.response?.data?.name[0] === 'vulnerability with this name already exists.')
      toast.warn('Já existe uma vulnerabilidade cadastrada com este nome.');
    else {
      toast.error(
        currentError?.response?.data?.email[0] ??
        currentError?.response?.data?.detail ??
        NEW_VULNERABILITY_ADDED_ERROR_MESSAGE
      );
    }
  }
}

function* fetchVulnerabilityDetail(action: Action): Generator {
  const { vulnerabilityId } =
    action.payload as VulnerabilityDetailRequestPayload;

  try {
    const vulnerabilityDetailResponse: AxiosResponse<Vulnerability> | unknown =
      yield call(
        api,
        'GET',
        `controls/vulnerabilities/${vulnerabilityId}/detail/`,
        {}
      );

    const {
      data: vulnerabilityDetail,
      config: { url },
      status,
      statusText,
    } = vulnerabilityDetailResponse as AxiosResponse<Vulnerability>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getVulnerabilityDetailSuccess(vulnerabilityDetail));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(resetTheVulnerabilityDetailState());
    yield put(getVulnerabilityDetailFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
      DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchToVulnerabilityEditing(action: Action): Generator {
  const { updatedVulnerability } =
    action.payload as EditVulnerabilityRequestPayload;

  try {
    const vulnerabilityEditingResponse: AxiosResponse<Vulnerability> | unknown =
      yield call(
        api,
        'PUT',
        `controls/vulnerabilities/${updatedVulnerability.id}/`,
        {
          ...updatedVulnerability,
        }
      );

    const {
      config: { url },
      status,
      statusText,
    } = vulnerabilityEditingResponse as AxiosResponse<Vulnerability>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editVulnerabilitySuccess());
    yield put(getVulnerabilitiesRequest());
    yield put(getCVEsRequest());
    yield put(getThreatsRequest());
    yield put(getControlsRequest());

    toast.success(EDIT_VULNERABILITY_SUCCESS_MESSAGE);

    // history.push('/vulnerabilities');
    history.push(`/vulnerability-visualization/${updatedVulnerability.id}`);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(resetTheVulnerabilityDetailState());
    yield put(editVulnerabilityFailure());

    toast.error(
      currentError?.response?.data?.detail ?? EDIT_VULNERABILITY_ERROR_MESSAGE
    );
  }
}

function* fetchToUpdateVulnerabilityStatus(action: Action): Generator {
  const { vulnerabilityId, newVulnerabilityStatus } =
    action.payload as UpdateVulnerabilityStatusRequestPayload;

  try {
    const updateVulnerabilityResponse:
      | AxiosResponse<{ is_active: boolean }>
      | unknown = yield call(
        api,
        'PUT',
        `controls/vulnerabilities/${vulnerabilityId}/update-status/`,
        {
          is_active: newVulnerabilityStatus,
        }
      );

    const {
      config: { url },
      status,
      statusText,
    } = updateVulnerabilityResponse as AxiosResponse<{ is_active: boolean }>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(activeOrInactiveVulnerabilitySuccess());
    yield put(getVulnerabilitiesRequest());
    yield put(getCVEsRequest());
    yield put(getThreatsRequest());
    yield put(getControlsRequest());

    toast.success(ACTIVE_OR_INACTIVE_VULNERABILITY_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(activeOrInactiveVulnerabilityFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      ACTIVE_OR_INACTIVE_VULNERABILITY_ERROR_MESSAGE
    );
  }
}

function* fetchAllCVEs(): Generator {
  try {
    const cvesResponse: AxiosResponse<CVEResults> | unknown = yield call(
      api,
      'GET',
      'controls/cve/',
      {}
    );

    const {
      data: cves,
      config: { url },
      status,
      statusText,
    } = cvesResponse as AxiosResponse<CVEResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCVEsSuccess(cves.results));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCVEsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchAllThreats(action: Action): Generator {
  try {
    const { payload } = action as any;
    const total: number = (yield select(state => state.vulnerabilities.total_threats)) as number;
    const last_page = checkPage(payload.last_page, total);
    const path = `controls/threats/?page=${last_page}`;
    const has_next = yield select(state => state.vulnerabilities.has_next_threats);
    const threats = (yield select(state => state.vulnerabilities.threats)) as number[];

    if (has_next || !threats.length || last_page === 1) {
      const threatsResponse: AxiosResponse<ThreatResults> | unknown = yield call(api, 'GET', path, {});

      const {
        data: { results, count, next },
        config: { url },
        status,
        statusText,
      } = threatsResponse as AxiosResponse<ThreatResults>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      yield put(getThreatsSuccess(results, count, last_page, !!next));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getThreatsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchAllControls(action: Action): Generator {
  try {
    const { payload } = action as any;
    const total: number = (yield select(state => state.vulnerabilities.total)) as number;
    const last_page = checkPage(payload.last_page, total);
    const path = payload.isActive ? `controls/controls/?page=${last_page}&&is_active=true` : `controls/controls/?page=${last_page}`;
    const has_next = yield select(state => state.vulnerabilities.has_next);
    const controls = (yield select(state => state.vulnerabilities.controls)) as number[];

    if (has_next || !controls.length || last_page === 1) {
      const controlsResponse: AxiosResponse<ControlDetailResults> | unknown = yield call(api, 'GET', path, {});

      const {
        data: { results, count, next },
        config: { url },
        status,
        statusText,
      } = controlsResponse as AxiosResponse<ControlDetailResults>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      yield put(getControlsSuccess(results as Control[], count, last_page, !!next));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getControlsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchAllControlEnums(): Generator {
  try {
    const controlEnumsResponse: AxiosResponse<ControlEnums> | unknown =
      yield call(api, 'GET', 'controls/enums/', {});

    const {
      data: controlEnums,
      config: { url },
      status,
      statusText,
    } = controlEnumsResponse as AxiosResponse<ControlEnums>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getControlEnumsSuccess(controlEnums));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getControlEnumsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchSearchFilteredCVEs(action: Action): Generator {
  const { filteredCVEs } = action.payload as SearchFilteredCVEsRequestPayload;

  const parsedResponses = filteredCVEs.filter(response => response !== null).join(',');

  try {
    const filteredCVEsResponse: AxiosResponse<CVEResults> | unknown = yield call(api, 'GET', `controls/cve/?search=${parsedResponses}`, {});

    const {
      data: cves,
      config: { url },
      status,
      statusText,
    } = filteredCVEsResponse as AxiosResponse<CVEResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredCVESuccess(cves.results));

    toast.success(SEARCH_FILTERED_CVE_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredCVEFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      SEARCH_FILTERED_CVE_ERROR_MESSAGE
    );
  }
}

function* fetchCVEDetail(action: Action): Generator {
  const { cveId } = action.payload as CVEDetailRequestPayload;

  try {
    const cveDetailResponse: AxiosResponse<CVE> | unknown = yield call(api, 'GET', `controls/cves/${cveId}/detail/`, {});

    const {
      data: cveDetail,
      config: { url },
      status,
      statusText,
    } = cveDetailResponse as AxiosResponse<CVE>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCVEDetailSuccess(cveDetail));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(resetTheVulnerabilityDetailState());
    yield put(getVulnerabilityDetailFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
      DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchAllCVEsModuleRequest(action: Action): Generator {
  try {
    const { payload } = action as any;
    const total: number = (yield select(state => state.vulnerabilities.total_cve)) as number;
    const last_page = checkPage(payload.last_page, total);
    const path = `controls/cve/?page=${last_page}`;
    const has_next = yield select(state => state.vulnerabilities.has_next_cve);

    if (has_next || last_page === 1) {
      const cvesResponse: AxiosResponse<CVEResults> | unknown = yield call(api, 'GET', path, {});

      const {
        data: { results, count, next },
        config: { url },
        status,
        statusText,
      } = cvesResponse as AxiosResponse<CVEResults>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );
      yield put(getCVEsModuleSuccess(results, count, last_page, !!next));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCVEsModuleFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}
 
function* fetchSimpleAllVulnerabilities(action: Action): Generator {
  try {
    const { payload } = action as any;
    const total: number = (yield select(state => state.vulnerabilities.total_vulnerabilities)) as number;
    const last_page = checkPage(payload.last_page, total);
    const path = `controls/vulnerabilities/?page=${last_page}`;
    const has_next = yield select(state => state.vulnerabilities.has_next_vulnerabilities);

    if (has_next || last_page === 1) {
      const vulnerabilitiesResponse: AxiosResponse<SimpleVulnerabilityResults> | unknown =
        yield call(api, 'GET', path, {});

      const {
        data: { results, count, next, },
        config: { url },
        status,
        statusText,
      } = vulnerabilitiesResponse as AxiosResponse<SimpleVulnerabilityResults>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      yield put(getSimpleVulnerabilitiesSuccess(results, count, last_page, !!next));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSimpleVulnerabilitiesFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
      DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchSimpleAllControls(action: Action): Generator {
  try {
    const { payload } = action as any;
    const total: number = (yield select(state => state.vulnerabilities.total)) as number;
    const last_page = checkPage(payload.last_page, total);
    const path = payload.isActive ? `controls/controls/?page=${last_page}&&is_active=true` : `controls/controls/?page=${last_page}`;
    const has_next = yield select(state => state.vulnerabilities.has_next);
    const controls = (yield select(state => state.vulnerabilities.controls)) as number[];

    if (has_next || !controls.length || last_page === 1) {
      const controlsResponse: AxiosResponse<ControlDetailResults> | unknown = yield call(api, 'GET', path, {});

      const {
        data: { results, count, next },
        config: { url },
        status,
        statusText,
      } = controlsResponse as AxiosResponse<ControlDetailResults>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      yield put(getSimpleControlsSuccess(results as SimpleControl[], count, last_page, !!next));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSimpleControlsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchSearchFilteredSimpleControl(action: Action): Generator {
  const { filteredControls, isActive } = action.payload as SearchFilteredControlRequestPayload;
  const parsedResponses = filteredControls.filter(response => response !== null).join(',');

  try {
    const filteredControlsResponse: AxiosResponse<VulnerabilityControlResults> | unknown =
      yield call(
        api,
        'GET',
        ReturnAPIRequest(parsedResponses, isActive),
        {}
      );

    const {
      data: controls,
      config: { url },
      status,
      statusText,
    } = filteredControlsResponse as AxiosResponse<VulnerabilityControlResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredSimpleControlSuccess(controls.results as SimpleControl[]));
    toast.success(SEARCH_FILTERED_CONTROL_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredSimpleControlFailure());

    toast.error(currentError?.response?.data?.detail ?? SEARCH_FILTERED_CONTROL_ERROR_MESSAGE);
  }
}

export function* vulnerabilitiesSagas() {
  yield all([
    takeLatest(GET_ALL_VULNERABILITIES_REQUEST, fetchAllVulnerabilities),
    takeLatest(GET_VULNERABILITY_DETAIL_REQUEST, fetchVulnerabilityDetail),
    takeLatest(GET_FILTERED_PROJECTS_VULNERABILITY_REQUEST, fetchSearchFilteredVulnerability),
    takeLatest(GET_FILTERED_THREAT_REQUEST, fetchSearchFilteredThreat),
    takeLatest(GET_FILTERED_PROJECTS_CONTROL_REQUEST, fetchSearchFilteredControl),
    takeLatest(ADD_NEW_VULNERABILITY_REQUEST, fetchAddNewVulnerability),
    takeLatest(EDIT_VULNERABILITY_REQUEST, fetchToVulnerabilityEditing),
    takeLatest(ACTIVE_OR_INACTIVE_VULNERABILITY_REQUEST, fetchToUpdateVulnerabilityStatus),
    takeLatest(GET_ALL_CVEs_REQUEST, fetchAllCVEs),
    takeLatest(GET_ALL_THREATS_REQUEST, fetchAllThreats),
    takeLatest(GET_ALL_CONTROLS_REQUEST, fetchAllControls),
    takeLatest(GET_ALL_CONTROL_ENUMS_REQUEST, fetchAllControlEnums),
    takeLatest(GET_FILTERED_CVE_REQUEST, fetchSearchFilteredCVEs),
    takeLatest(GET_CVE_DETAIL_REQUEST, fetchCVEDetail),
    takeLatest(GET_ALL_CVES_MODULE_REQUEST, fetchAllCVEsModuleRequest),
    takeLatest(GET_ALL_SIMPLE_VULNERABILITIES_REQUEST, fetchSimpleAllVulnerabilities),

    takeLatest(GET_ALL_SIMPLE_CONTROLS_REQUEST, fetchSimpleAllControls),
    takeLatest(GET_FILTERED_SIMPLE_CONTROL_REQUEST, fetchSearchFilteredSimpleControl),
  ]);
}
