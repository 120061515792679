import type { Action } from 'store/types';

import {
  CustomerProjectNist,
  CustomerProjectNistActions,
} from './type';

const {
  GET_CUSTOMER_PROJECT_NIST_REQUEST,
  GET_CUSTOMER_PROJECT_NIST_SUCCESS,
  GET_CUSTOMER_PROJECT_NIST_FAILURE,
} = CustomerProjectNistActions;

function getCustomersProjectNISTQuestionnaireRequest(
  customerProjectId: string
): Action {
  return {
    type: GET_CUSTOMER_PROJECT_NIST_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function getCustomersProjectNISTQuestionnaireSuccess(
  customerProjectNist: CustomerProjectNist
): Action {
  return {
    type: GET_CUSTOMER_PROJECT_NIST_SUCCESS,
    payload: {
      customerProjectNist
    }
  };
}

function getCustomersProjectNISTQuestionnaireFailure(): Action {
  return {
    type: GET_CUSTOMER_PROJECT_NIST_FAILURE,
  };
}

export const customerProjectNistActionsFunctions = {
  getCustomersProjectNISTQuestionnaireRequest,
  getCustomersProjectNISTQuestionnaireSuccess,
  getCustomersProjectNISTQuestionnaireFailure,
};