import type { Action } from 'store/types';

import type { AddNewDamageData, EditDamageData, Damage } from './types';
import { DamagesActions } from './types';

const {
  GET_ALL_DAMAGES_REQUEST,
  GET_ALL_DAMAGES_SUCCESS,
  GET_ALL_DAMAGES_FAILURE,
  GET_FILTERED_DAMAGE_REQUEST,
  GET_FILTERED_DAMAGE_SUCCESS,
  GET_FILTERED_DAMAGE_FAILURE,
  GET_DAMAGE_DETAIL_REQUEST,
  GET_DAMAGE_DETAIL_SUCCESS,
  GET_DAMAGE_DETAIL_FAILURE,
  ADD_NEW_DAMAGE_REQUEST,
  ADD_NEW_DAMAGE_SUCCESS,
  ADD_NEW_DAMAGE_FAILURE,
  EDIT_DAMAGE_REQUEST,
  EDIT_DAMAGE_SUCCESS,
  EDIT_DAMAGE_FAILURE,
  ACTIVE_OR_INACTIVE_DAMAGE_REQUEST,
  ACTIVE_OR_INACTIVE_DAMAGE_SUCCESS,
  ACTIVE_OR_INACTIVE_DAMAGE_FAILURE,
  RESET_THE_DAMAGE_DETAIL_STATE,
} = DamagesActions;

function getDamagesRequest(last_page?: number): Action {
  return {
    type: GET_ALL_DAMAGES_REQUEST,
    payload:{
      last_page
    }
  };
}

function getDamagesSuccess(damages: Damage[], total: number, last_page: number, has_next: boolean): Action {
  return {
    type: GET_ALL_DAMAGES_SUCCESS,
    payload: {
      damages,
      total,
      last_page, 
      has_next
    },
  };
}

function getDamagesFailure(): Action {
  return {
    type: GET_ALL_DAMAGES_FAILURE,
  };
}

function getSearchFilteredDamageRequest(
  filteredDamages: Array<string | boolean | null>
): Action {
  return {
    type: GET_FILTERED_DAMAGE_REQUEST,
    payload: {
      filteredDamages,
    },
  };
}

function getSearchFilteredDamageSuccess(damages: Damage[]): Action {
  return {
    type: GET_FILTERED_DAMAGE_SUCCESS,
    payload: {
      damages,
    },
  };
}

function getSearchFilteredDamageFailure(): Action {
  return {
    type: GET_FILTERED_DAMAGE_FAILURE,
  };
}

function getDamageDetailRequest(damageId: string): Action {
  return {
    type: GET_DAMAGE_DETAIL_REQUEST,
    payload: {
      damageId,
    },
  };
}

function getDamageDetailSuccess(damage: Damage): Action {
  return {
    type: GET_DAMAGE_DETAIL_SUCCESS,
    payload: {
      damage,
    },
  };
}

function getDamageDetailFailure(): Action {
  return {
    type: GET_DAMAGE_DETAIL_FAILURE,
  };
}

function addNewDamageRequest(newDamage: AddNewDamageData): Action {
  return {
    type: ADD_NEW_DAMAGE_REQUEST,
    payload: {
      newDamage,
    },
  };
}

function addNewDamageSuccess(damageAdded: Damage): Action {
  return {
    type: ADD_NEW_DAMAGE_SUCCESS,
    payload: {
      damageAdded,
    },
  };
}

function addNewDamageFailure(): Action {
  return {
    type: ADD_NEW_DAMAGE_FAILURE,
  };
}

function editDamageRequest(updatedDamage: EditDamageData): Action {
  return {
    type: EDIT_DAMAGE_REQUEST,
    payload: {
      updatedDamage,
    },
  };
}

function editDamageSuccess(): Action {
  return {
    type: EDIT_DAMAGE_SUCCESS,
  };
}

function editDamageFailure(): Action {
  return {
    type: EDIT_DAMAGE_FAILURE,
  };
}

function activeOrInactiveDamageRequest(
  damageId: string,
  newDamageStatus: boolean
): Action {
  return {
    type: ACTIVE_OR_INACTIVE_DAMAGE_REQUEST,
    payload: {
      damageId,
      newDamageStatus,
    },
  };
}

function activeOrInactiveDamageSuccess(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_DAMAGE_SUCCESS,
  };
}

function activeOrInactiveDamageFailure(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_DAMAGE_FAILURE,
  };
}

function resetTheDamageDetailState(): Action {
  return {
    type: RESET_THE_DAMAGE_DETAIL_STATE,
  };
}

export const damagesActionsFunctions = {
  getDamagesRequest,
  getDamagesSuccess,
  getDamagesFailure,
  getSearchFilteredDamageRequest,
  getSearchFilteredDamageSuccess,
  getSearchFilteredDamageFailure,
  getDamageDetailRequest,
  getDamageDetailSuccess,
  getDamageDetailFailure,
  addNewDamageRequest,
  addNewDamageSuccess,
  addNewDamageFailure,
  editDamageRequest,
  editDamageSuccess,
  editDamageFailure,
  activeOrInactiveDamageRequest,
  activeOrInactiveDamageSuccess,
  activeOrInactiveDamageFailure,
  resetTheDamageDetailState,
};
