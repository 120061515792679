export enum ControlRecommendationsActions {
  GET_CONTROL_RECOMMENDATIONS_REQUEST = 'GET_CONTROL_RECOMMENDATIONS_REQUEST',
  GET_CONTROL_RECOMMENDATIONS_SUCCESS = 'GET_CONTROL_RECOMMENDATIONS_SUCCESS',
  GET_CONTROL_RECOMMENDATIONS_FAILURE = 'GET_CONTROL_RECOMMENDATIONS_FAILURE',
  GET_CONTROL_CUSTOMER_RECOMMENDATIONS_REQUEST = 'GET_CONTROL_CUSTOMER_RECOMMENDATIONS_REQUEST',
  GET_CONTROL_CUSTOMER_RECOMMENDATIONS_SUCCESS = 'GET_CONTROL_CUSTOMER_RECOMMENDATIONS_SUCCESS',
  GET_CONTROL_CUSTOMER_RECOMMENDATIONS_FAILURE = 'GET_CONTROL_CUSTOMER_RECOMMENDATIONS_FAILURE',
  GET_CONTROL_RECOMMENDATIONS_DETAIL_REQUEST = 'GET_CONTROL_RECOMMENDATIONS_DETAIL_REQUEST',
  GET_CONTROL_RECOMMENDATIONS_DETAIL_SUCCESS = 'GET_CONTROL_RECOMMENDATIONS_DETAIL_SUCCESS',
  GET_CONTROL_RECOMMENDATIONS_DETAIL_FAILURE = 'GET_CONTROL_RECOMMENDATIONS_DETAIL_FAILURE',
  EDIT_CONTROL_RECOMMENDATIONS_STATUS_REQUEST = 'EDIT_CONTROL_RECOMMENDATIONS_STATUS_REQUEST',
  EDIT_CONTROL_RECOMMENDATIONS_STATUS_SUCCESS = 'EDIT_CONTROL_RECOMMENDATIONS_STATUS_SUCCESS',
  EDIT_CONTROL_RECOMMENDATIONS_STATUS_FAILURE = 'EDIT_CONTROL_RECOMMENDATIONS_STATUS_FAILURE',
  EDIT_CONTROL_RECOMMENDATIONS_RANKING_PHASE_REQUEST = 'EDIT_CONTROL_RECOMMENDATIONS_RANKING_PHASE_REQUEST',
  EDIT_CONTROL_RECOMMENDATIONS_RANKING_PHASE_SUCCESS = 'EDIT_CONTROL_RECOMMENDATIONS_RANKING_PHASE_SUCCESS',
  EDIT_CONTROL_RECOMMENDATIONS_RANKING_PHASE_FAILURE = 'EDIT_CONTROL_RECOMMENDATIONS_RANKING_PHASE_FAILURE',
  GENERATE_CONTROL_RECOMMENDATIONS_CUSTOMER_PROJECT_REQUEST = 'GENERATE_CONTROL_RECOMMENDATIONS_CUSTOMER_PROJECT_REQUEST',
  GENERATE_CONTROL_RECOMMENDATIONS_CUSTOMER_PROJECT_SUCCESS = 'GENERATE_CONTROL_RECOMMENDATIONS_CUSTOMER_PROJECT_SUCCESS',
  GENERATE_CONTROL_RECOMMENDATIONS_CUSTOMER_PROJECT_FAILURE = 'GENERATE_CONTROL_RECOMMENDATIONS_CUSTOMER_PROJECT_FAILURE',
  GET_GENERATE_PHASES_RECOMMENDATIONS_REQUEST = 'GET_GENERATE_PHASES_RECOMMENDATIONS_REQUEST',
  GET_GENERATE_PHASES_RECOMMENDATIONS_SUCCESS = 'GET_GENERATE_PHASES_RECOMMENDATIONS_SUCCESS',
  GET_GENERATE_PHASES_RECOMMENDATIONS_FAILURE = 'GET_GENERATE_PHASES_RECOMMENDATIONS_FAILURE',
}

export type ControlsRisk = {
  id: string;
  position: number;
  name: string;
  stage: number;
  group: string;
  count_critical_risks: number;
  count_high_risks: number;
  count_medium_risks: number;
  count_low_risks: number;
  count_info_risks: number;
  total_benefit: number;
  is_active: boolean;
  minimized_risks: number;
  justification_of_inactivation: string;
}

export type ControlRecommendations = {
  id: string;
  name: string;
  last_update_recommended_controls: string;
  controls_risk_customer_project: Array<ControlsRisk>;
}

export type ControlCustomerRecommendationsApiPayload = {
  projectId: string;
}

export type recommended_control = {
  id: string;
  position: number;
  name: string;
  stage: number;
  group_name: string;
  count_risks_critical: number;
  count_risks_high: number;
  count_risks_medium: number;
  total_benefit: number;
  is_active: boolean;
  phase?: number;
  control_type: string;
}

export type ControlCustomerRecommendations = {
  last_update_recommended_controls: string;
  total_budget_infosec: number;
  total_cost_recommended_controls: number;
  total_other_controls: number;
  total_pareto: number;
  recommended_controls: recommended_control[];
}

export type RisksCritical = {
  id: string;
  name: string;
}

export type RisksHigh = {
  id: string;
  name: string;
}

export type RisksMedium = {
  id: string;
  name: string;
}

export type RisksLow = {
  id: string;
  name: string;
}

export type RisksInfo = {
  id: string;
  name: string;
}

export type ControlsRiskDetail = {
  id: string;
  name: string;
  group: string;
  is_active: boolean;
  justification_of_inactivation: string;
  position: number;
  stage: number;
  risks_critical: Array<RisksCritical>;
  risks_high: Array<RisksHigh>;
  risks_medium: Array<RisksMedium>;
  risks_low: Array<RisksLow>;
  risks_info: Array<RisksInfo>;
}

export type ControlRecommendationsState = {
  controlsRisk: ControlsRisk;
  controlsRiskDetail: ControlsRiskDetail;
  controlRecommendations: ControlRecommendations;
  controlCustomerRecommendations: ControlCustomerRecommendations;
}

export type ControlRecommendationsApiPayload = {
  customerProjectId: string;
}

export type ChangeStatusBodyType = {
  justification_of_inactivation: string,
  is_active: boolean
}

export type ControlRecommendationsChangeStatusApiPayload = {
  controlId: string;
  changeStatusBody: ChangeStatusBodyType;
  customerProjectId: string;
}

export type ControlRecommendationsDetailApiPayload = {
  controlId: string;
}

export type ChangeRankingPhaseBodyType = {
  stage: number;
  position: number;
}

export type ControlRecommendationsChangeRankingPhaseApiPayload = {
  controlId: string;
  changeRankingPhaseBody: ChangeRankingPhaseBodyType;
}