import produce from 'immer';
import { Reducer } from 'redux';

import type {
  SectorsState,
  SegmentsOfSectorDetails
} from './types';
import { SectorsActions } from './types';

const {
  GET_ALL_SECTORS_REQUEST,
  GET_ALL_SECTORS_SUCCESS,
  GET_ALL_SECTORS_FAILURE,
  GET_SECTOR_DETAILS_REQUEST,
  GET_SECTOR_DETAILS_SUCCESS,
  GET_SECTOR_DETAILS_FAILURE,
  GET_ALL_SUB_SECTORS_REQUEST,
  GET_ALL_SUB_SECTORS_SUCCESS,
  GET_ALL_SUB_SECTORS_FAILURE,
  GET_ALL_SEGMENTS_REQUEST,
  GET_ALL_SEGMENTS_SUCCESS,
  GET_ALL_SEGMENTS_FAILURE,
  GET_ALL_DEPARTMENTS_REQUEST,
  GET_ALL_DEPARTMENTS_SUCCESS,
  GET_ALL_DEPARTMENTS_FAILURE,
  GET_FILTERED_SECTOR_REQUEST,
  GET_FILTERED_SECTOR_SUCCESS,
  GET_FILTERED_SECTOR_FAILURE,
  GET_FILTERED_DEPARTMENT_REQUEST,
  GET_FILTERED_DEPARTMENT_SUCCESS,
  GET_FILTERED_DEPARTMENT_FAILURE,
  ADD_NEW_SECTOR_REQUEST,
  ADD_NEW_SECTOR_SUCCESS,
  ADD_NEW_SECTOR_FAILURE,
  EDIT_SECTOR_REQUEST,
  EDIT_SECTOR_SUCCESS,
  EDIT_SECTOR_FAILURE,
  ADD_NEW_SUB_SECTOR_REQUEST,
  ADD_NEW_SUB_SECTOR_SUCCESS,
  ADD_NEW_SUB_SECTOR_FAILURE,
  EDIT_SUB_SECTOR_REQUEST,
  EDIT_SUB_SECTOR_SUCCESS,
  EDIT_SUB_SECTOR_FAILURE,
  ADD_NEW_SEGMENT_REQUEST,
  ADD_NEW_SEGMENT_SUCCESS,
  ADD_NEW_SEGMENT_FAILURE,
  EDIT_SEGMENT_REQUEST,
  EDIT_SEGMENT_SUCCESS,
  EDIT_SEGMENT_FAILURE,
  ADD_NEW_DEPARTMENT_REQUEST,
  ADD_NEW_DEPARTMENT_SUCCESS,
  ADD_NEW_DEPARTMENT_FAILURE,
  EDIT_DEPARTMENT_REQUEST,
  EDIT_DEPARTMENT_SUCCESS,
  EDIT_DEPARTMENT_FAILURE,
  ACTIVE_OR_INACTIVE_SECTOR_REQUEST,
  ACTIVE_OR_INACTIVE_SECTOR_SUCCESS,
  ACTIVE_OR_INACTIVE_SECTOR_FAILURE,
  ACTIVE_OR_INACTIVE_SUB_SECTOR_REQUEST,
  ACTIVE_OR_INACTIVE_SUB_SECTOR_SUCCESS,
  ACTIVE_OR_INACTIVE_SUB_SECTOR_FAILURE,
  ACTIVE_OR_INACTIVE_SEGMENT_REQUEST,
  ACTIVE_OR_INACTIVE_SEGMENT_SUCCESS,
  ACTIVE_OR_INACTIVE_SEGMENT_FAILURE,
  ACTIVE_OR_INACTIVE_DEPARTMENT_REQUEST,
  ACTIVE_OR_INACTIVE_DEPARTMENT_SUCCESS,
  ACTIVE_OR_INACTIVE_DEPARTMENT_FAILURE,
  RESET_THE_LAST_SECTOR_DETAILS,
} = SectorsActions;

const initialState: SectorsState = {
  allSectors: [],
  loadedSectorsWithDetails: [],
  currentSectorDetails: {
    id: '',
    name: '',
    subsectors: [],
    segments: [],
    departments: [],
    is_active: false,
  },
  allSubSectors: [],
  allSegments: [],
  allDepartments: [],
};

export const sectorsReducer: Reducer<SectorsState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_SECTORS_REQUEST: {
        break;
      }

      case GET_ALL_SECTORS_SUCCESS: {
        const { sectors } = action.payload;

        draft.allSectors = sectors;

        break;
      }

      case GET_ALL_SECTORS_FAILURE: {
        draft.allSectors = [];

        break;
      }

      case GET_SECTOR_DETAILS_REQUEST: {
        break;
      }

      case GET_SECTOR_DETAILS_SUCCESS: {
        const { sectorDetails } = action.payload;

        sectorDetails.subsectors?.forEach((subsector: any) => {
          if (subsector.segments) {
            draft.currentSectorDetails.segments.push(...subsector.segments);
          }

          subsector.segments.forEach((segment: SegmentsOfSectorDetails) => {
            draft.currentSectorDetails.departments = segment.departments;
          });
        });

        draft.currentSectorDetails.id = sectorDetails.id;
        draft.currentSectorDetails.name = sectorDetails.name;
        draft.currentSectorDetails.subsectors = sectorDetails.subsectors;
        draft.currentSectorDetails.is_active = sectorDetails.is_active;

        const index = draft.loadedSectorsWithDetails.findIndex(sector => sector.id === sectorDetails.id);
        if(index > -1) {
          draft.loadedSectorsWithDetails.splice(index, 1);
        }

        draft.loadedSectorsWithDetails.push(sectorDetails);
        break;
      }

      case GET_SECTOR_DETAILS_FAILURE: {
        draft.currentSectorDetails.id = '';
        draft.currentSectorDetails.name = '';
        draft.currentSectorDetails.subsectors = [];

        draft.currentSectorDetails.segments = [];
        draft.currentSectorDetails.departments = [];

        draft.currentSectorDetails.is_active = false;

        break;
      }

      case GET_ALL_SUB_SECTORS_REQUEST: {
        break;
      }

      case GET_ALL_SUB_SECTORS_SUCCESS: {
        const { subSectors } = action.payload;

        draft.allSubSectors = subSectors;

        break;
      }

      case GET_ALL_SUB_SECTORS_FAILURE: {
        draft.allSubSectors = [];
        break;
      }

      case GET_ALL_SEGMENTS_REQUEST: {
        break;
      }

      case GET_ALL_SEGMENTS_SUCCESS: {
        const { segments } = action.payload;

        draft.allSegments = segments;

        break;
      }

      case GET_ALL_SEGMENTS_FAILURE: {
        draft.allSegments = [];

        break;
      }

      case GET_ALL_DEPARTMENTS_REQUEST: {
        break;
      }

      case GET_ALL_DEPARTMENTS_SUCCESS: {
        const { departments } = action.payload;

        draft.allDepartments = departments;

        break;
      }

      case GET_ALL_DEPARTMENTS_FAILURE: {
        draft.allDepartments = [];

        break;
      }

      case GET_FILTERED_SECTOR_REQUEST: {
        break;
      }

      case GET_FILTERED_SECTOR_SUCCESS: {
        const { sectors } = action.payload;

        draft.allSectors = sectors;

        break;
      }

      case GET_FILTERED_SECTOR_FAILURE: {
        break;
      }

      case GET_FILTERED_DEPARTMENT_REQUEST: {
        break;
      }

      case GET_FILTERED_DEPARTMENT_SUCCESS: {
        const { departments } = action.payload;

        draft.allDepartments = departments;

        break;
      }

      case GET_FILTERED_DEPARTMENT_FAILURE: {
        break;
      }

      case ADD_NEW_SECTOR_REQUEST: {
        break;
      }

      case ADD_NEW_SECTOR_SUCCESS: {
        break;
      }

      case ADD_NEW_SECTOR_FAILURE: {
        break;
      }

      case EDIT_SECTOR_REQUEST: {
        break;
      }

      case EDIT_SECTOR_SUCCESS: {
        break;
      }

      case EDIT_SECTOR_FAILURE: {
        break;
      }

      case ADD_NEW_SUB_SECTOR_REQUEST: {
        break;
      }

      case ADD_NEW_SUB_SECTOR_SUCCESS: {
        break;
      }

      case ADD_NEW_SUB_SECTOR_FAILURE: {
        break;
      }

      case EDIT_SUB_SECTOR_REQUEST: {
        break;
      }

      case EDIT_SUB_SECTOR_SUCCESS: {
        break;
      }

      case EDIT_SUB_SECTOR_FAILURE: {
        break;
      }

      case ADD_NEW_SEGMENT_REQUEST: {
        break;
      }

      case ADD_NEW_SEGMENT_SUCCESS: {
        break;
      }

      case ADD_NEW_SEGMENT_FAILURE: {
        break;
      }

      case EDIT_SEGMENT_REQUEST: {
        break;
      }

      case EDIT_SEGMENT_SUCCESS: {
        break;
      }

      case EDIT_SEGMENT_FAILURE: {
        break;
      }

      case ADD_NEW_DEPARTMENT_REQUEST: {
        break;
      }

      case ADD_NEW_DEPARTMENT_SUCCESS: {
        break;
      }

      case ADD_NEW_DEPARTMENT_FAILURE: {
        break;
      }

      case EDIT_DEPARTMENT_REQUEST: {
        break;
      }

      case EDIT_DEPARTMENT_SUCCESS: {
        break;
      }

      case EDIT_DEPARTMENT_FAILURE: {
        break;
      }

      case ACTIVE_OR_INACTIVE_SECTOR_REQUEST: {
        break;
      }

      case ACTIVE_OR_INACTIVE_SECTOR_SUCCESS: {
        break;
      }

      case ACTIVE_OR_INACTIVE_SECTOR_FAILURE: {
        break;
      }

      case ACTIVE_OR_INACTIVE_SUB_SECTOR_REQUEST: {
        break;
      }

      case ACTIVE_OR_INACTIVE_SUB_SECTOR_SUCCESS: {
        break;
      }

      case ACTIVE_OR_INACTIVE_SUB_SECTOR_FAILURE: {
        break;
      }

      case ACTIVE_OR_INACTIVE_SEGMENT_REQUEST: {
        break;
      }

      case ACTIVE_OR_INACTIVE_SEGMENT_SUCCESS: {
        break;
      }

      case ACTIVE_OR_INACTIVE_SEGMENT_FAILURE: {
        break;
      }

      case ACTIVE_OR_INACTIVE_DEPARTMENT_REQUEST: {
        break;
      }

      case ACTIVE_OR_INACTIVE_DEPARTMENT_SUCCESS: {
        break;
      }

      case ACTIVE_OR_INACTIVE_DEPARTMENT_FAILURE: {
        break;
      }

      case RESET_THE_LAST_SECTOR_DETAILS: {
        draft.currentSectorDetails.id = '';
        draft.currentSectorDetails.name = '';
        draft.currentSectorDetails.subsectors = [];

        draft.currentSectorDetails.segments = [];
        draft.currentSectorDetails.departments = [];

        draft.currentSectorDetails.is_active = false;

        break;
      }

      default: {
        return draft;
      }
    }
  });
};
