import produce from 'immer';
import { Reducer } from 'redux';

import { CustomerSectorsActions, CustomerSectorsState } from './types';


const {
  GET_ALL_CUSTOMER_SECTORS_REQUEST,
  GET_ALL_CUSTOMER_SECTORS_SUCCESS,
  GET_ALL_CUSTOMER_SECTORS_FAILURE,
} = CustomerSectorsActions;

const initialState: CustomerSectorsState = {
  allSectorsCustomer: [],
};

export const customerSectorsReducer: Reducer<CustomerSectorsState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_CUSTOMER_SECTORS_REQUEST: {
        break;
      }

      case GET_ALL_CUSTOMER_SECTORS_SUCCESS: {
        const { sectors } = action.payload;
        draft.allSectorsCustomer = sectors;
        break;
      }

      case GET_ALL_CUSTOMER_SECTORS_FAILURE: {
        draft.allSectorsCustomer = [];
        break;
      }      
      
      default: {
        return draft;
      }
    }
  });
};
