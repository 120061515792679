import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import type { Action } from 'store/types';
import { checkPage } from 'utils/number';

import { ApplicationLogFunctions } from './actions';
import {
  AllLogs,
  ApplicationLogActions,
  LogDetailsRequestPayload,
  ModelLogsDetail,
  SearchFilteredLogsRequestPayload,
} from './types';

const {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE
} = ToastMessages;

const {
  GET_FILTERED_LOGS_REQUEST,
  GET_ALL_CONTROL_LOG_REQUEST,
  GET_ALL_ACCOUNTS_LOG_REQUEST,
  GET_ALL_PROJECTS_LOG_REQUEST,
  GET_MODEL_LOGS_DETAIL_REQUEST,
  GET_ALL_CUSTOMERS_LOG_REQUEST,
} = ApplicationLogActions;

const {
  getLogDetailsSuccess,
  getLogDetailsFailure,
  getControlsLogSuccess,
  getControlsLogFailure,
  getAccountsLogSuccess,
  getAccountsLogFailure,
  getProjectsLogSuccess,
  getProjectsLogFailure,
  getCustomersLogSuccess,
  getCustomersLogFailure,
  getSearchFilteredLogsSuccess,
  getSearchFilteredLogsFailure,
} = ApplicationLogFunctions;

const { getLastApiFetchDataSuccess, getLastApiFetchDataFailure } = lastApiFetchDataActionsFunctions;

function* fetchAllControlsLogs(action: Action): Generator {
  try {
    const { payload } = action as any;
    const total: number = (yield select(state => state.applicationLogs.allControlsLog.count)) as number;
    const last_page = checkPage(payload.last_page, total);
    const path = `controls/auditlogs/?page=${last_page}`;
    const has_next = yield select(state => state.applicationLogs.allControlsLog.next);

    if(has_next || last_page === 1){
      const controlsResponse: AxiosResponse<AllLogs> | unknown = yield call(api, 'GET', path, {});

      const {
        data: { count, next, previous, results  },
        config: { url },
        status,
        statusText,
      } = controlsResponse as AxiosResponse<AllLogs>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      yield put(getControlsLogSuccess(!!next, count, previous, last_page, results));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getControlsLogFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchAllAccountsLogs(action: Action): Generator {
  try {
    const { payload } = action as any;
    const total: number = (yield select(state => state.applicationLogs.allAccountsLog.count)) as number;
    const last_page = checkPage(payload.last_page, total);
    const path = `accounts/auditlogs/?page=${last_page}`;
    const has_next = yield select(state => state.applicationLogs.allAccountsLog.next);

    if(has_next || last_page === 1){
      const accountsResponse: AxiosResponse<AllLogs> | unknown = yield call(api, 'GET', path, {});

      const {
        data: { count, next, previous, results  },
        config: { url },
        status,
        statusText,
      } = accountsResponse as AxiosResponse<AllLogs>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );

      yield put(getAccountsLogSuccess(!!next, count, previous, last_page, results));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getAccountsLogFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchAllProjectsLogs(action: Action): Generator {
  try {
    const { payload } = action as any;
    const total: number = (yield select(state => state.applicationLogs.allProjectsLog.count)) as number;
    const last_page = checkPage(payload.last_page, total);
    const path = `projects/auditlogs/?page=${last_page}`;
    const has_next = yield select(state => state.applicationLogs.allProjectsLog.next);

    if(has_next || last_page === 1){
      const projectsResponse: AxiosResponse<AllLogs> | unknown = yield call(api, 'GET', path, {});

      const {
        data: { count, next, previous, results  },
        config: { url },
        status,
        statusText,
      } = projectsResponse as AxiosResponse<AllLogs>;
  
      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );
  
      yield put(getProjectsLogSuccess(!!next, count, previous, last_page, results));
    }    
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getProjectsLogFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchAllCustomersLogs(action: Action): Generator {
  try {
    const { payload } = action as any;
    const total: number = (yield select(state => state.applicationLogs.allCustomersLog.count)) as number;
    const last_page = checkPage(payload.last_page, total);
    const path = `customers/auditlogs/?page=${last_page}`;
    const has_next = yield select(state => state.applicationLogs.allCustomersLog.next);

    if(has_next || last_page === 1){
      const customersResponse: AxiosResponse<AllLogs> | unknown = yield call(api, 'GET', path, {});

      const {
        data: { count, next, previous, results  },
        config: { url },
        status,
        statusText,
      } = customersResponse as AxiosResponse<AllLogs>;
  
      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );
  
      yield put(getCustomersLogSuccess(!!next, count, previous, last_page, results));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCustomersLogFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchLogDetailsRequest(action: Action): Generator {
  let result = '';
  const { id, type } = action.payload as LogDetailsRequestPayload;

  switch (type) {
    case 'accounts': {
      result = `accounts/auditlogs/${id}/detail/`;
      break;
    }

    case 'controls': {
      result = `controls/auditlogs/${id}/detail/`;
      break;
    }

    case 'projects': {
      result = `projects/auditlogs/${id}/detail/`;
      break;
    }

    case 'customers': {
      result = `customers/auditlogs/${id}/detail/`;
      break;
    }

    default: {
      break;
    }
  }

  try {
    const logDetailsResponse: AxiosResponse<ModelLogsDetail> | unknown =
      yield call(
        api,
        'GET',
        result,
        {}
      );

    const {
      data: details,
      config: { url },
      status,
      statusText,
    } = logDetailsResponse as AxiosResponse<ModelLogsDetail>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getLogDetailsSuccess(details));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getLogDetailsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchSearchFilteredLogs(action: Action): Generator {
  const { search, actor, time, type } = action.payload as SearchFilteredLogsRequestPayload;

  let parsedResponses = 'auditlogs/?';
  if (search)
    parsedResponses = parsedResponses.concat(`search=${search}`);

  if (time) {
    if (search)
      parsedResponses = parsedResponses.concat(`,${time}`);
    else
      parsedResponses = parsedResponses.concat(`search=${time}`);
  }

  if (actor) {
    if (search || time)
      parsedResponses = parsedResponses.concat(`&&actor=${actor}`);
    else
      parsedResponses = parsedResponses.concat(`actor=${actor}`);
  }

  let resultResponse = '';
  switch (type) {
    case 'accounts': {
      resultResponse = 'accounts/'.concat(parsedResponses);
      break;
    }

    case 'controls': {
      resultResponse = 'controls/'.concat(parsedResponses);
      break;
    }

    case 'projects': {
      resultResponse = 'projects/'.concat(parsedResponses);
      break;
    }

    case 'customers': {
      resultResponse = 'customers/'.concat(parsedResponses);
      break;
    }

    default: {
      break;
    }
  }

  try {
    const filteredLogsResponse: AxiosResponse<AllLogs> | unknown = yield call(
      api,
      'GET',
      resultResponse,
      {}
    );

    const {
      data: logs,
      config: { url },
      status,
      statusText,
    } = filteredLogsResponse as AxiosResponse<AllLogs>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredLogsSuccess({ logs, type }));
    toast.success('Filtragem de logs foi realizada com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredLogsFailure());

    toast.error(currentError?.response?.data?.detail ?? 'Tentativa de filtragem de logs falhou.');
  }
}

export function* applicationLogSagas() {
  yield all([
    takeLatest(GET_ALL_CONTROL_LOG_REQUEST, fetchAllControlsLogs),
    takeLatest(GET_ALL_ACCOUNTS_LOG_REQUEST, fetchAllAccountsLogs),
    takeLatest(GET_ALL_PROJECTS_LOG_REQUEST, fetchAllProjectsLogs),
    takeLatest(GET_FILTERED_LOGS_REQUEST, fetchSearchFilteredLogs),
    takeLatest(GET_ALL_CUSTOMERS_LOG_REQUEST, fetchAllCustomersLogs),
    takeLatest(GET_MODEL_LOGS_DETAIL_REQUEST, fetchLogDetailsRequest),
  ]);
}
