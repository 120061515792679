import produce from 'immer';
import { Reducer } from 'redux';

import { RiskActions, RiskState } from './types';


const {
  GET_ALL_RISK_REQUEST,
  GET_ALL_RISK_SUCCESS,
  GET_ALL_RISK_FAILURE,
  GET_ALL_RISK_OPTIONS_REQUEST,
  GET_ALL_RISK_OPTIONS_SUCCESS,
  GET_ALL_RISK_OPTIONS_FAILURE,
  CREATE_NEW_RISK_REQUEST,
  CREATE_NEW_RISK_SUCCESS,
  CREATE_NEW_RISK_FAILURE,
  GET_SEARCH_FILTERED_RISKS_REQUEST,
  GET_SEARCH_FILTERED_RISKS_SUCCESS,
  GET_SEARCH_FILTERED_RISKS_FAILURE,
  GET_RISK_DETAIL_REQUEST,
  GET_RISK_DETAIL_SUCCESS,
  GET_RISK_DETAIL_FAILURE,
  GET_ALL_RECOMENDATIONS_REQUEST,
  GET_ALL_RECOMENDATIONS_SUCCESS,
  GET_ALL_RECOMENDATIONS_FAILURE,
  EDIT_RECOMENDATIONS_REQUEST,
  EDIT_RECOMENDATIONS_SUCCESS,
  EDIT_RECOMENDATIONS_FAILURE,
  GET_ALL_RISK_STATUS_REQUEST,
  GET_ALL_RISK_STATUS_SUCCESS,
  GET_ALL_RISK_STATUS_FAILURE,
  EDIT_RISK_REQUEST,
  EDIT_RISK_SUCCESS,
  EDIT_RISK_FAILURE,
  UPDATE_RISK_THREAT_REQUEST,
  UPDATE_RISK_THREAT_SUCCESS,
  UPDATE_RISK_THREAT_FAILURE
} = RiskActions;

const defaultObject = {
  alternative: '',
  id: '',
  subtype: '',
  type: '',
  variable: '',
  weight: 0
};


const initialState: RiskState = {
  visited_pages: [],
  has_next: true,
  total: 0,
  risk: [],
  riskDetail: {
    awareness: defaultObject,
    description: '',
    ease_of_discovery: defaultObject,
    ease_of_exploit: defaultObject,
    financial_damage: defaultObject,
    friendly_id: '',
    id: '',
    impact_category: '',
    impact_score: 0,
    inherent_risk: '',
    inherent_risk_score: 0,
    instrusion_detection: defaultObject,
    is_active: false,
    loff_of_accountability: defaultObject,
    loff_of_availability: defaultObject,
    loss_of_confidentiality: defaultObject,
    loss_of_integrity: defaultObject,
    motive: defaultObject,
    name: '',
    non_compliance: defaultObject,
    opportunity: defaultObject,
    privacy_violation: defaultObject,
    probability_category: '',
    probability_score: 0,
    reputation_damage: defaultObject,
    size: defaultObject,
    skill_level: defaultObject,
    stages: [],
    threats: []
  },
  questions: {
    skill_level: [],
    motive: [],
    opportunity: [],
    size: [],
    ease_of_discovery: [],
    ease_of_exploit: [],
    awareness: [],
    intrusion_detection: [],
    loss_of_confidentiality: [],
    loss_of_integrity: [],
    loss_of_availability: [],
    loss_of_accountability: [],
    financial_damage: [],
    reputation_damage: [],
    non_compliance: [],
    privacy_violation: []
  },
  recomendations: {
    types_recommendation: []
  }
};

export const riskReducer: Reducer<RiskState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_RISK_REQUEST: {
        break;
      }

      case GET_ALL_RISK_SUCCESS: {
        const { risk, total, last_page, has_next } = action.payload;
        const pagineAlreadyVisited = state.visited_pages.find (page => page === last_page);

        if(!pagineAlreadyVisited || draft.risk) {
          draft.visited_pages.push(last_page);
          draft.risk = last_page === 1 ? risk : [...state.risk, ...risk];
          draft.total = total;
          draft.has_next = has_next;
        }

        break;
      }

      case GET_ALL_RISK_FAILURE: {
        draft.risk = [];

        break;
      }

      case GET_ALL_RISK_STATUS_REQUEST: {
        break;
      }

      case GET_ALL_RISK_STATUS_SUCCESS: {
        break;
      }

      case GET_ALL_RISK_STATUS_FAILURE: {
        break;
      }

      case GET_ALL_RECOMENDATIONS_REQUEST: {
        break;
      }

      case GET_ALL_RECOMENDATIONS_SUCCESS: {
        const { recomendations } = action.payload;

        draft.recomendations = recomendations;

        break;
      }

      case GET_ALL_RECOMENDATIONS_FAILURE: {
        draft.risk = [];

        break;
      }

      case GET_RISK_DETAIL_REQUEST: {
        break;
      }

      case GET_RISK_DETAIL_SUCCESS: {
        const { risk } = action.payload;

        draft.riskDetail = risk;

        break;
      }

      case GET_RISK_DETAIL_FAILURE: {
        draft.risk = [];

        break;
      }

      case GET_SEARCH_FILTERED_RISKS_REQUEST: {
        break;
      }

      case GET_SEARCH_FILTERED_RISKS_SUCCESS: {
        const { risks } = action.payload;

        draft.risk = risks;

        break;
      }

      case GET_SEARCH_FILTERED_RISKS_FAILURE: {
        draft.risk = [];

        break;
      }

      case GET_ALL_RISK_OPTIONS_REQUEST: {
        break;
      }

      case GET_ALL_RISK_OPTIONS_SUCCESS: {
        const { allQuestions } = action.payload;

        draft.questions = allQuestions;

        break;
      }

      case GET_ALL_RISK_OPTIONS_FAILURE: {
        break;
      }

      case CREATE_NEW_RISK_REQUEST: {
        break;
      }

      case CREATE_NEW_RISK_SUCCESS: {
        break;
      }

      case CREATE_NEW_RISK_FAILURE: {
        break;
      }

      case EDIT_RECOMENDATIONS_REQUEST: {
        break;
      }

      case EDIT_RECOMENDATIONS_SUCCESS: {
        break;
      }

      case EDIT_RECOMENDATIONS_FAILURE: {
        break;
      }

      case EDIT_RISK_REQUEST: {
        break;
      }

      case EDIT_RISK_SUCCESS: {
        break;
      }

      case EDIT_RISK_FAILURE: {
        break;
      }

      case UPDATE_RISK_THREAT_REQUEST: {
        break;
      }

      case UPDATE_RISK_THREAT_SUCCESS: {
        break;
      }

      case UPDATE_RISK_THREAT_FAILURE: {
        break;
      }

      default: {
        return draft;
      }
    }
  });
};
