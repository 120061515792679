import produce from 'immer';
import { Reducer } from 'redux';

import type { LastApiFetchDataState } from './types';
import { LastApiFetchDataStateActions } from './types';

const {
  SET_LOADING_STATUS_FOR_THE_LAST_API_FETCH_DATA,
  LAST_API_FETCH_DATA_SUCCESS,
  LAST_API_FETCH_DATA_FAILURE,
  RESET_THE_LAST_API_FETCH_DATA_STATUS,
} = LastApiFetchDataStateActions;

const initialState: LastApiFetchDataState = {
  status: 'idle',
  error: {
    url: '',
    message: '',
    status: null,
    statusText: '',
  },
  success: {
    url: '',
    message: '',
    status: null,
    statusText: '',
  },
};

export const lastApiFetchDataReducer: Reducer<LastApiFetchDataState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case SET_LOADING_STATUS_FOR_THE_LAST_API_FETCH_DATA: {
        draft.error.url = '';
        draft.error.message = '';
        draft.error.status = null;
        draft.error.statusText = '';

        draft.success.url = '';
        draft.success.message = '';
        draft.success.status = null;
        draft.success.statusText = '';

        draft.status = 'loading';

        break;
      }

      case LAST_API_FETCH_DATA_SUCCESS: {
        draft.error.url = '';
        draft.error.message = '';
        draft.error.status = null;
        draft.error.statusText = '';

        draft.status = 'success';

        const { url, message, status, statusText } = action.payload;

        draft.success.url = url;
        draft.success.message = message;
        draft.success.status = status;
        draft.success.statusText = statusText;

        break;
      }

      case LAST_API_FETCH_DATA_FAILURE: {
        draft.success.url = '';
        draft.success.message = '';
        draft.success.status = null;
        draft.success.statusText = '';

        draft.status = 'error';

        const { error } = action.payload;

        draft.error.url = error?.response?.config?.url;
        draft.error.message = error?.response?.data?.Error;
        draft.error.status = error?.response?.status;
        draft.error.statusText = error?.response?.statusText;

        break;
      }

      case RESET_THE_LAST_API_FETCH_DATA_STATUS: {
        draft.status = 'idle';

        break;
      }

      default: {
        return draft;
      }
    }
  });
};
