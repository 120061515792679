import type { Action } from 'store/types';

import {
  AddNewFrameworkRequestPayload,
  AllFramewoks,
  AllStandards,
  AlternativeIdPayload,
  AlternativesEditPayload,
  ApprovedRequestPayloadParams,
  CSFCoreRefCreatePayload,
  CSFCoreRefEditPayload,
  CreateAlternativePayload,
  DeleteItemCSFCoreRefParam,
  FrameworkControlsDetails,
  FrameworkControlsDetailsRequestPayload,
  FrameworkDetails,
  FrameworkDetailsRequestPayload,
  FrameworkEditPayload,
  FrameworkItemsDetailsResponse,
  FrameworkStandardsDetails,
  FrameworkStandardsDetailsRequestPayload,
  FrameworksActions,
  FrameworksControlsPayload,
  FrameworksItemsPayload,
  SearchFilteredFrameworksRequestPayloadParams,
  StandardItemsPayload,
  UpdateStatusRequestPayloadParams
} from './types';

const {
  EDIT_FRAMEWORKS_REQUEST,
  EDIT_FRAMEWORKS_SUCCESS,
  EDIT_FRAMEWORKS_FAILURE,
  GET_ALL_FRAMEWORKS_REQUEST,
  GET_ALL_FRAMEWORKS_SUCCESS,
  GET_ALL_FRAMEWORKS_FAILURE,
  ADD_NEW_FRAMEWORKS_REQUEST,
  ADD_NEW_FRAMEWORKS_SUCCESS,
  ADD_NEW_FRAMEWORKS_FAILURE,
  ADD_STANDARDS_ITEMS_REQUEST,
  ADD_STANDARDS_ITEMS_SUCCESS,
  ADD_STANDARDS_ITEMS_FAILURE,
  APPROVED_FRAMEWORKS_REQUEST,
  APPROVED_FRAMEWORKS_SUCCESS,
  APPROVED_FRAMEWORKS_FAILURE,
  GET_STANDARDS_ITEMS_REQUEST,
  GET_STANDARDS_ITEMS_SUCCESS,
  GET_STANDARDS_ITEMS_FAILURE,
  GET_FRAMEWORKS_DETAIL_REQUEST,
  GET_FRAMEWORKS_DETAIL_SUCCESS,
  GET_FRAMEWORKS_DETAIL_FAILURE,
  CREATE_CSFCOREREF_ITEM_REQUEST,
  CREATE_CSFCOREREF_ITEM_SUCCESS,
  CREATE_CSFCOREREF_ITEM_FAILURE,
  GET_FILTERED_FRAMEWORKS_REQUEST,
  GET_FILTERED_FRAMEWORKS_SUCCESS,
  GET_FILTERED_FRAMEWORKS_FAILURE,
  ADD_FRAMEWORKS_CONTROLS_REQUEST,
  ADD_FRAMEWORKS_CONTROLS_SUCCESS,
  ADD_FRAMEWORKS_CONTROLS_FAILURE,
  EDIT_CSFCOREREF_FRAMEWORKS_REQUEST,
  EDIT_CSFCOREREF_FRAMEWORKS_SUCCESS,
  EDIT_CSFCOREREF_FRAMEWORKS_FAILURE,
  EDIT_ALTERNATIVES_FRAMEWORKS_REQUEST,
  EDIT_ALTERNATIVES_FRAMEWORKS_SUCCESS,
  EDIT_ALTERNATIVES_FRAMEWORKS_FAILURE,
  DELETE_CSFCOREREF_FRAMEWORKS_REQUEST,
  DELETE_CSFCOREREF_FRAMEWORKS_SUCCESS,
  DELETE_CSFCOREREF_FRAMEWORKS_FAILURE,
  CREATE_CSFCOREREF_ALTERNATIVE_REQUEST,
  CREATE_CSFCOREREF_ALTERNATIVE_SUCCESS,
  CREATE_CSFCOREREF_ALTERNATIVE_FAILURE,
  ACTIVE_OR_INACTIVE_FRAMEWORKS_REQUEST,
  ACTIVE_OR_INACTIVE_FRAMEWORKS_SUCCESS,
  ACTIVE_OR_INACTIVE_FRAMEWORKS_FAILURE,
  GET_FRAMEWORKS_CONTROLS_DETAIL_REQUEST,
  GET_FRAMEWORKS_CONTROLS_DETAIL_SUCCESS,
  GET_FRAMEWORKS_CONTROLS_DETAIL_FAILURE,
  DELETE_CSFCOREREF_ALTERNATIVES_REQUEST,
  DELETE_CSFCOREREF_ALTERNATIVES_SUCCESS,
  DELETE_CSFCOREREF_ALTERNATIVES_FAILURE,
  GET_FRAMEWORKS_STANDARDS_DETAIL_REQUEST,
  GET_FRAMEWORKS_STANDARDS_DETAIL_SUCCESS,
  GET_FRAMEWORKS_STANDARDS_DETAIL_FAILURE,
  GET_FRAMEWORKS_ITEMS_DETAIL_REQUEST,
  GET_FRAMEWORKS_ITEMS_DETAIL_SUCCESS,
  GET_FRAMEWORKS_ITEMS_DETAIL_FAILURE,
  ADD_FRAMEWORKS_ITEMS_REQUEST,
  ADD_FRAMEWORKS_ITEMS_SUCCESS,
  ADD_FRAMEWORKS_ITEMS_FAILURE
} = FrameworksActions;

function getFrameworksRequest(isCustomerProjectPage = false): Action {
  return {
    type: GET_ALL_FRAMEWORKS_REQUEST,
    payload: {
      isCustomerProjectPage,
    },
  };
}

function getFrameworksSuccess(frameworks: AllFramewoks): Action {
  return {
    type: GET_ALL_FRAMEWORKS_SUCCESS,
    payload: {
      frameworks,
    },
  };
}

function getFrameworksFailure(): Action {
  return {
    type: GET_ALL_FRAMEWORKS_FAILURE,
  };
}

function getSearchFilteredFrameworksRequest({
  name,
  is_active
}: SearchFilteredFrameworksRequestPayloadParams
): Action {
  return {
    type: GET_FILTERED_FRAMEWORKS_REQUEST,
    payload: {
      name,
      is_active
    },
  };
}

function getSearchFilteredFrameworksSuccess(frameworks: AllFramewoks): Action {
  return {
    type: GET_FILTERED_FRAMEWORKS_SUCCESS,
    payload: {
      frameworks,
    },
  };
}

function getSearchFilteredFrameworksFailure(): Action {
  return {
    type: GET_FILTERED_FRAMEWORKS_FAILURE,
  };
}

function activeOrInactiveFrameworksRequest({
  id,
  is_active
}: UpdateStatusRequestPayloadParams): Action {
  return {
    type: ACTIVE_OR_INACTIVE_FRAMEWORKS_REQUEST,
    payload: {
      id,
      is_active,
    },
  };
}

function activeOrInactiveFrameworksSuccess(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_FRAMEWORKS_SUCCESS,
  };
}

function activeOrInactiveFrameworksFailure(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_FRAMEWORKS_FAILURE,
  };
}

function addNewFrameworkRequest({
  name,
  version,
  publication_date,
  successor
}: AddNewFrameworkRequestPayload): Action {
  return {
    type: ADD_NEW_FRAMEWORKS_REQUEST,
    payload: {
      name,
      version,
      publication_date,
      successor
    },
  };
}

function addNewFrameworkSuccess(): Action {
  return {
    type: ADD_NEW_FRAMEWORKS_SUCCESS,
  };
}

function addNewFrameworkFailure(): Action {
  return {
    type: ADD_NEW_FRAMEWORKS_FAILURE,
  };
}

function editFrameworkRequest(
  {
    id,
    name,
    version,
    publication_date,
    successor,
    is_active
  }: FrameworkEditPayload): Action {
  return {
    type: EDIT_FRAMEWORKS_REQUEST,
    payload: {
      id,
      name,
      version,
      publication_date,
      successor,
      is_active
    },
  };
}

function editFrameworkSuccess(): Action {
  return {
    type: EDIT_FRAMEWORKS_SUCCESS,
  };
}

function editFrameworkFailure(): Action {
  return {
    type: EDIT_FRAMEWORKS_FAILURE,
  };
}

function getFrameworkDetailsRequest({ id }: FrameworkDetailsRequestPayload): Action {
  return {
    type: GET_FRAMEWORKS_DETAIL_REQUEST,
    payload: {
      id,
    },
  };
}

function getFrameworkDetailsSuccess(frameworkDetails: FrameworkDetails): Action {
  return {
    type: GET_FRAMEWORKS_DETAIL_SUCCESS,
    payload: {
      frameworkDetails,
    },
  };
}

function getFrameworkDetailsFailure(): Action {
  return {
    type: GET_FRAMEWORKS_DETAIL_FAILURE,
  };
}

function editAlternativesFrameworkRequest(
  {
    id,
    name,
    peso,
    csf_core_ref,
    frameworkId,
  }: AlternativesEditPayload): Action {
  return {
    type: EDIT_ALTERNATIVES_FRAMEWORKS_REQUEST,
    payload: {
      id,
      name,
      peso,
      csf_core_ref,
      frameworkId,
    },
  };
}

function editAlternativesFrameworkSuccess(): Action {
  return {
    type: EDIT_ALTERNATIVES_FRAMEWORKS_SUCCESS,
  };
}

function editAlternativesFrameworkFailure(): Action {
  return {
    type: EDIT_ALTERNATIVES_FRAMEWORKS_FAILURE,
  };
}

function editCSFCoreRefFrameworkRequest(
  {
    id,
    friendly_id,
    csf_function,
    category_name,
    category_description,
    subcategory,
    subcategory_ptbr,
    version,
    evidence_to_be_collected,
    frameworkId,
    implementation_examples
  }: CSFCoreRefEditPayload): Action {
  return {
    type: EDIT_CSFCOREREF_FRAMEWORKS_REQUEST,
    payload: {
      id,
      friendly_id,
      csf_function,
      category_name,
      category_description,
      subcategory,
      subcategory_ptbr,
      version,
      evidence_to_be_collected,
      frameworkId,
      implementation_examples
    },
  };
}

function editCSFCoreRefFrameworkSuccess(): Action {
  return {
    type: EDIT_CSFCOREREF_FRAMEWORKS_SUCCESS,
  };
}

function editCSFCoreRefFrameworkFailure(): Action {
  return {
    type: EDIT_CSFCOREREF_FRAMEWORKS_FAILURE,
  };
}

function approvedFrameworksRequest({
  id,
  name,
  approved,
  approval_date,
}: ApprovedRequestPayloadParams): Action {
  return {
    type: APPROVED_FRAMEWORKS_REQUEST,
    payload: {
      id,
      name,
      approved,
      approval_date,
    },
  };
}

function approvedFrameworksSuccess(): Action {
  return {
    type: APPROVED_FRAMEWORKS_SUCCESS,
  };
}

function approvedFrameworksFailure(): Action {
  return {
    type: APPROVED_FRAMEWORKS_FAILURE,
  };
}

function getFrameworkStandardsDetailsRequest({ id }: FrameworkStandardsDetailsRequestPayload): Action {
  return {
    type: GET_FRAMEWORKS_STANDARDS_DETAIL_REQUEST,
    payload: {
      id,
    },
  };
}

function getFrameworkStandardsDetailsSuccess(frameworkStandardsDetails: FrameworkStandardsDetails): Action {
  return {
    type: GET_FRAMEWORKS_STANDARDS_DETAIL_SUCCESS,
    payload: {
      frameworkStandardsDetails,
    },
  };
}

function getFrameworkStandardsDetailsFailure(): Action {
  return {
    type: GET_FRAMEWORKS_STANDARDS_DETAIL_FAILURE,
  };
}

function getFrameworkStandardsRequest(): Action {
  return {
    type: GET_STANDARDS_ITEMS_REQUEST,
  };
}

function getFrameworkStandardsSuccess(allStandards: AllStandards): Action {
  return {
    type: GET_STANDARDS_ITEMS_SUCCESS,
    payload: {
      allStandards,
    },
  };
}

function getFrameworkStandardsFailure(): Action {
  return {
    type: GET_STANDARDS_ITEMS_FAILURE,
  };
}

function addStandardItemsRequest(
  {
    id,
    standardItemsAdd,
    standardItemsRemove,
  }: StandardItemsPayload): Action {
  return {
    type: ADD_STANDARDS_ITEMS_REQUEST,
    payload: {
      id,
      standardItemsAdd,
      standardItemsRemove,
    },
  };
}

function addStandardItemsSuccess(): Action {
  return {
    type: ADD_STANDARDS_ITEMS_SUCCESS,
  };
}

function addStandardItemsFailure(): Action {
  return {
    type: ADD_STANDARDS_ITEMS_FAILURE,
  };
}

function getFrameworkControlsDetailsRequest({ id }: FrameworkControlsDetailsRequestPayload): Action {
  return {
    type: GET_FRAMEWORKS_CONTROLS_DETAIL_REQUEST,
    payload: {
      id,
    },
  };
}

function getFrameworkControlsDetailsSuccess(frameworkControlsDetails: FrameworkControlsDetails): Action {
  return {
    type: GET_FRAMEWORKS_CONTROLS_DETAIL_SUCCESS,
    payload: {
      frameworkControlsDetails,
    },
  };
}

function getFrameworkControlsDetailsFailure(): Action {
  return {
    type: GET_FRAMEWORKS_CONTROLS_DETAIL_FAILURE,
  };
}

function addFrameworksControlsRequest(
  {
    id,
    frameworksControlsAdd,
    frameworksControlsRemove,
  }: FrameworksControlsPayload): Action {
  return {
    type: ADD_FRAMEWORKS_CONTROLS_REQUEST,
    payload: {
      id,
      frameworksControlsAdd,
      frameworksControlsRemove,
    },
  };
}

function addFrameworksControlsSuccess(): Action {
  return {
    type: ADD_FRAMEWORKS_CONTROLS_SUCCESS,
  };
}

function addFrameworksControlsFailure(): Action {
  return {
    type: ADD_FRAMEWORKS_CONTROLS_FAILURE,
  };
}

function deleteItemCSFCoreRefRequest({
  csfCoreRefId,
  frameworkId,
}: DeleteItemCSFCoreRefParam): Action {
  return {
    type: DELETE_CSFCOREREF_FRAMEWORKS_REQUEST,
    payload: {
      csfCoreRefId,
      frameworkId,
    },
  };
}

function deleteItemCSFCoreRefSuccess(): Action {
  return {
    type: DELETE_CSFCOREREF_FRAMEWORKS_SUCCESS,
  };
}

function deleteItemCSFCoreRefFailure(): Action {
  return {
    type: DELETE_CSFCOREREF_FRAMEWORKS_FAILURE,
  };
}

function createCSFCoreRefItemRequest(
  {
    friendly_id,
    csf_function,
    category_name,
    category_description,
    subcategory,
    subcategory_ptbr,
    framework,
    evidence_to_be_collected,
    implementation_examples
  }: CSFCoreRefCreatePayload): Action {
  return {
    type: CREATE_CSFCOREREF_ITEM_REQUEST,
    payload: {
      friendly_id,
      csf_function,
      category_name,
      category_description,
      subcategory,
      subcategory_ptbr,
      framework,
      evidence_to_be_collected,
      implementation_examples
    },
  };
}

function createCSFCoreRefItemSuccess(): Action {
  return {
    type: CREATE_CSFCOREREF_ITEM_SUCCESS,
  };
}

function createCSFCoreRefItemFailure(): Action {
  return {
    type: CREATE_CSFCOREREF_ITEM_FAILURE,
  };
}

function deleteAlternativeCSFCoreRequest({ id }: AlternativeIdPayload): Action {
  return {
    type: DELETE_CSFCOREREF_ALTERNATIVES_REQUEST,
    payload: {
      id,
    },
  };
}

function deleteAlternativeCSFCoreSuccess(): Action {
  return {
    type: DELETE_CSFCOREREF_ALTERNATIVES_SUCCESS,
  };
}

function deleteAlternativeCSFCoreFailure(): Action {
  return {
    type: DELETE_CSFCOREREF_ALTERNATIVES_FAILURE,
  };
}

function createAlternativeCSFCoreRequest(
  {
    peso,
    name,
    csf_core_ref,
    frameworkId,
  }: CreateAlternativePayload): Action {
  return {
    type: CREATE_CSFCOREREF_ALTERNATIVE_REQUEST,
    payload: {
      peso,
      name,
      csf_core_ref,
      frameworkId,
    },
  };
}

function createAlternativeCSFCoreSuccess(): Action {
  return {
    type: CREATE_CSFCOREREF_ALTERNATIVE_SUCCESS,
  };
}

function createAlternativeCSFCoreFailure(): Action {
  return {
    type: CREATE_CSFCOREREF_ALTERNATIVE_FAILURE,
  };
}

function getFrameworkItemsDetailsRequest({id}: FrameworkStandardsDetailsRequestPayload): Action {
  return {
    type: GET_FRAMEWORKS_ITEMS_DETAIL_REQUEST,
    payload: {id}
  };
}

function getFrameworkItemsDetailsSuccess(frameworkItemsDetails: FrameworkItemsDetailsResponse): Action {
  return {
    type: GET_FRAMEWORKS_ITEMS_DETAIL_SUCCESS,
    payload: {
      frameworkItemsDetails,
    },
  };
}

function getFrameworkItemsDetailsFailure(): Action {
  return {
    type: GET_FRAMEWORKS_ITEMS_DETAIL_FAILURE,
  };
}

function addFrameworksItemsRequest(
  {
    id,
    frameworksItems,
  }: FrameworksItemsPayload): Action {
  return {
    type: ADD_FRAMEWORKS_ITEMS_REQUEST,
    payload: {
      id,
      frameworksItems,
    },
  };
}

function addFrameworksItemsSuccess(): Action {
  return {
    type: ADD_FRAMEWORKS_ITEMS_SUCCESS,
  };
}

function addFrameworksItemsFailure(): Action {
  return {
    type: ADD_FRAMEWORKS_ITEMS_FAILURE,
  };
}

export const FrameworksActionsFunctions = {
  editFrameworkRequest,
  editFrameworkSuccess,
  editFrameworkFailure,
  getFrameworksRequest,
  getFrameworksSuccess,
  getFrameworksFailure,
  addNewFrameworkRequest,
  addNewFrameworkSuccess,
  addNewFrameworkFailure,
  addStandardItemsRequest,
  addStandardItemsSuccess,
  addStandardItemsFailure,
  approvedFrameworksRequest,
  approvedFrameworksSuccess,
  approvedFrameworksFailure,
  getFrameworkDetailsRequest,
  getFrameworkDetailsSuccess,
  getFrameworkDetailsFailure,
  createCSFCoreRefItemRequest,
  createCSFCoreRefItemSuccess,
  createCSFCoreRefItemFailure,
  deleteItemCSFCoreRefRequest,
  deleteItemCSFCoreRefSuccess,
  deleteItemCSFCoreRefFailure,
  getFrameworkStandardsRequest,
  getFrameworkStandardsSuccess,
  getFrameworkStandardsFailure,
  addFrameworksControlsRequest,
  addFrameworksControlsSuccess,
  addFrameworksControlsFailure,
  editCSFCoreRefFrameworkRequest,
  editCSFCoreRefFrameworkSuccess,
  editCSFCoreRefFrameworkFailure,
  createAlternativeCSFCoreRequest,
  createAlternativeCSFCoreSuccess,
  createAlternativeCSFCoreFailure,
  deleteAlternativeCSFCoreRequest,
  deleteAlternativeCSFCoreSuccess,
  deleteAlternativeCSFCoreFailure,
  editAlternativesFrameworkRequest,
  editAlternativesFrameworkSuccess,
  editAlternativesFrameworkFailure,
  activeOrInactiveFrameworksRequest,
  activeOrInactiveFrameworksSuccess,
  activeOrInactiveFrameworksFailure,
  getSearchFilteredFrameworksRequest,
  getSearchFilteredFrameworksSuccess,
  getSearchFilteredFrameworksFailure,
  getFrameworkControlsDetailsRequest,
  getFrameworkControlsDetailsSuccess,
  getFrameworkControlsDetailsFailure,
  getFrameworkStandardsDetailsRequest,
  getFrameworkStandardsDetailsSuccess,
  getFrameworkStandardsDetailsFailure,
  getFrameworkItemsDetailsRequest,
  getFrameworkItemsDetailsSuccess,
  getFrameworkItemsDetailsFailure,
  addFrameworksItemsRequest,
  addFrameworksItemsSuccess,
  addFrameworksItemsFailure
};
