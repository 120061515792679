import produce from 'immer';
import { Reducer } from 'redux';

import { InterwiiesActions, InterwiiesState } from './types';

const {
  GET_ALL_INTERWEES_REQUEST,
  GET_ALL_INTERWEES_SUCCESS,
  GET_ALL_INTERWEES_FAILURE,
  CREATE_NEW_INTERWIED_REQUEST,
  CREATE_NEW_INTERWIED_SUCCESS,
  CREATE_NEW_INTERWIED_FAILURE,
  UPDATE_STATUS_REQUEST,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_FAILURE,
  EDIT_INTERWIED_REQUEST,
  EDIT_INTERWIED_SUCCESS,
  EDIT_INTERWIED_FAILURE
} = InterwiiesActions;

const initialState: InterwiiesState = {
  interwiies: []
};

export const interwiiesReducer: Reducer<InterwiiesState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_INTERWEES_REQUEST: {
        break;
      }

      case GET_ALL_INTERWEES_SUCCESS: {
        break;
      }

      case GET_ALL_INTERWEES_FAILURE: {
        break;
      }

      case CREATE_NEW_INTERWIED_REQUEST: {
        break;
      }

      case CREATE_NEW_INTERWIED_SUCCESS: {
        break;
      }

      case CREATE_NEW_INTERWIED_FAILURE: {
        break;
      }

      case EDIT_INTERWIED_REQUEST: {
        break;
      }

      case EDIT_INTERWIED_SUCCESS: {
        break;
      }

      case EDIT_INTERWIED_FAILURE: {
        break;
      }

      case UPDATE_STATUS_REQUEST: {
        break;
      }

      case UPDATE_STATUS_SUCCESS: {
        break;
      }

      case UPDATE_STATUS_FAILURE: {
        break;
      }
      
      default: {
        return draft;
      }
    }
  });
};
