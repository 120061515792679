import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import type { Action } from 'store/types';

import { interwiiesActionsFunctions } from './actions';
import { AllInterwiies, ApiFetchInterwieesRequestPayloadParams, InterwiiesActions, NewInterwiies, InterwiiesInfoUpdateStatus, EditInterwiies } from './types';

const {
  GET_ALL_INTERWEES_REQUEST,
  CREATE_NEW_INTERWIED_REQUEST,
  UPDATE_STATUS_REQUEST,
  EDIT_INTERWIED_REQUEST
} = InterwiiesActions;

const {
  getInterwieesSuccess,
  getInterwieesFailure,
  createNewInterwieesSuccess,
  createNewInterwieesFailure
} = interwiiesActionsFunctions;

const {
  getLastApiFetchDataSuccess,
  getLastApiFetchDataFailure
} = lastApiFetchDataActionsFunctions;

const { 
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE
} = ToastMessages;


function* fetchAllInterWees(): Generator {
  try {
    const customerResponse: AxiosResponse<AllInterwiies[]> | unknown = yield call(
      api,
      'GET',
      '/interviewees/interviewees/',
      {}
    );

    const {
      data: interviewees,
      config: { url },
      status,
      statusText,
    } = customerResponse as AxiosResponse<AllInterwiies[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getInterwieesSuccess(interviewees));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getInterwieesFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchCreateNewInterWied(action: Action): Generator {
  const { interwieesBodyData } = action.payload as ApiFetchInterwieesRequestPayloadParams;
  try {
    const customerResponse: AxiosResponse<NewInterwiies> | unknown = yield call(
      api,
      'POST',
      '/customers/interviewees/create/',
      { ...interwieesBodyData }
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerResponse as AxiosResponse<NewInterwiies>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(createNewInterwieesSuccess());
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(createNewInterwieesFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
        DEFAULT_ERROR_MESSAGE
    );
  }
}

function* editInterwiedRequest(action: Action): Generator {
  const { interwiedId, interwied } = action.payload as EditInterwiies;
  try {
    const customerResponse: AxiosResponse<NewInterwiies> | unknown = yield call(
      api,
      'PUT',
      `/customers/interviewees/${interwiedId}/`,
      { ...interwied }
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerResponse as AxiosResponse<NewInterwiies>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(createNewInterwieesSuccess());
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(createNewInterwieesFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
        DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchUpdateInterwiedStatus(action: Action): Generator {
  const { interwiedId,  is_active} = action.payload as InterwiiesInfoUpdateStatus;
  try {
    const customerResponse: AxiosResponse<NewInterwiies> | unknown = yield call(
      api,
      'PUT',
      `/customers/interviewees/${interwiedId}/update-status/`,
      { 
        is_active
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = customerResponse as AxiosResponse<NewInterwiies>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(createNewInterwieesSuccess());
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(createNewInterwieesFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
        DEFAULT_ERROR_MESSAGE
    );
  }
}


export function* interwiiesSagas() {
  yield all([
    takeLatest(GET_ALL_INTERWEES_REQUEST, fetchAllInterWees),
    takeLatest(CREATE_NEW_INTERWIED_REQUEST, fetchCreateNewInterWied),
    takeLatest(UPDATE_STATUS_REQUEST, fetchUpdateInterwiedStatus),
    takeLatest(EDIT_INTERWIED_REQUEST, editInterwiedRequest),
  ]);
}
