export enum InterwiiesActions {
  GET_ALL_INTERWEES_REQUEST = 'GET_ALL_INTERWEES_REQUEST',
  GET_ALL_INTERWEES_SUCCESS = 'GET_ALL_INTERWEES_SUCCESS',
  GET_ALL_INTERWEES_FAILURE = 'GET_ALL_INTERWEES_FAILURE',
  CREATE_NEW_INTERWIED_REQUEST = 'CREATE_NEW_INTERWIED_REQUEST',
  CREATE_NEW_INTERWIED_SUCCESS = 'CREATE_NEW_INTERWIED_SUCCESS',
  CREATE_NEW_INTERWIED_FAILURE = 'CREATE_NEW_INTERWIED_FAILURE',
  UPDATE_STATUS_REQUEST = 'UPDATE_STATUS_REQUEST',
  UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS',
  UPDATE_STATUS_FAILURE = 'UPDATE_STATUS_FAILURE',
  EDIT_INTERWIED_REQUEST = 'EDIT_INTERWIED_REQUEST',
  EDIT_INTERWIED_SUCCESS = 'EDIT_INTERWIED_SUCCESS',
  EDIT_INTERWIED_FAILURE = 'EDIT_INTERWIED_FAILURE'
}

export type ApiFetchInterwieesRequestPayloadParams = {
  interwieesBodyData: NewInterwiies
  interwiiesUpdateStatus: InterwiiesInfoUpdateStatus
}

export type InterwiiesInfoUpdateStatus = {
  interwiedId: string;
  is_active: boolean
}

export type EditInterwiies = {
  interwiedId: string;
  interwied: NewInterwiies
}

export type AllInterwiies = {
  id: string;
  customer: string;
  name: string;
  position: string;
  phone: string;
  email:  string;
  is_active: boolean
}

export type NewInterwiies = {
  customer: string;
  name: string;
  position: string;
  phone: string;
  email:  string;
  is_active: boolean
}

export type InterwiiesState = {
  interwiies: AllInterwiies[]
}

