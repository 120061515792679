import produce from 'immer';
import { Reducer } from 'redux';

import type { QuestionnariesState } from './types';
import { QuestionnariesActions } from './types';

const {
  ACTIVE_OR_INACTIVE_QUIZ_FAILURE,
  ACTIVE_OR_INACTIVE_QUIZ_SUCCESS,
  ACTIVE_OR_INACTIVE_QUIZ_REQUEST,
  ADD_NEW_QUIZ_FAILURE,
  ADD_NEW_QUIZ_REQUEST,
  ADD_NEW_QUIZ_SUCCESS,
  EDIT_QUIZ_FAILURE,
  EDIT_QUIZ_REQUEST,
  EDIT_QUIZ_SUCCESS,
  GET_ALL_QUESTIONNARIES_MODULE_FAILURE,
  GET_ALL_QUESTIONNARIES_MODULE_REQUEST,
  GET_ALL_QUESTIONNARIES_MODULE_SUCCESS,
  GET_QUIZ_DETAIL_FAILURE,
  GET_QUIZ_DETAIL_REQUEST,
  GET_QUIZ_DETAIL_SUCCESS,
  GET_FILTERED_QUIZ_FAILURE,
  GET_FILTERED_QUIZ_REQUEST,
  GET_FILTERED_QUIZ_SUCCESS,
  RESET_THE_QUIZ_DETAIL_STATE,
} = QuestionnariesActions;

const initialState: QuestionnariesState = {
  questionnaires: [],
  visited_pages: [],
  has_next_questionnaire: true,
  total_questionnaire: 0,
  currentQuizDetail: {
    id: '',
    description: '',
    type_of_questionnaire: '',
    name: '',
    departments: [],
    questions: [],
    is_active: false,
  },
  departments: [],
};

export const questionnairesReducer: Reducer<QuestionnariesState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_QUESTIONNARIES_MODULE_REQUEST: {
        break;
      }

      case GET_ALL_QUESTIONNARIES_MODULE_SUCCESS: {
        const { questionnaires, total_questionnaire, last_page, has_next_questionnaire } = action.payload;
        const pagineAlreadyVisited = state.visited_pages.find(page => page === last_page);

        if(!pagineAlreadyVisited || draft.questionnaires) {
          draft.visited_pages.push(last_page);
          draft.questionnaires = last_page === 1 ? questionnaires : [...state.questionnaires, ...questionnaires];
          draft.total_questionnaire = total_questionnaire;
          draft.has_next_questionnaire = has_next_questionnaire;
        }

        break;
      }

      case GET_ALL_QUESTIONNARIES_MODULE_FAILURE: {
        draft.questionnaires = [];

        break;
      }

      case GET_FILTERED_QUIZ_REQUEST: {
        break;
      }

      case GET_FILTERED_QUIZ_SUCCESS: {
        const { questionnaires } = action.payload;

        draft.questionnaires = questionnaires.results;

        break;
      }

      case GET_FILTERED_QUIZ_FAILURE: {
        break;
      }

      case GET_QUIZ_DETAIL_REQUEST: {
        break;
      }

      case GET_QUIZ_DETAIL_SUCCESS: {
        const { quiz } = action.payload;

        draft.currentQuizDetail = quiz;

        break;
      }

      case GET_QUIZ_DETAIL_FAILURE: {
        break;
      }

      case ADD_NEW_QUIZ_REQUEST: {
        break;
      }

      case ADD_NEW_QUIZ_SUCCESS: {
        const { QuizAdded } = action.payload;

        draft.currentQuizDetail = QuizAdded;

        break;
      }

      case ADD_NEW_QUIZ_FAILURE: {
        break;
      }

      case EDIT_QUIZ_REQUEST: {
        break;
      }

      case EDIT_QUIZ_SUCCESS: {
        break;
      }

      case EDIT_QUIZ_FAILURE: {
        break;
      }

      case ACTIVE_OR_INACTIVE_QUIZ_REQUEST: {
        break;
      }

      case ACTIVE_OR_INACTIVE_QUIZ_SUCCESS: {
        break;
      }

      case ACTIVE_OR_INACTIVE_QUIZ_FAILURE: {
        break;
      }

      case RESET_THE_QUIZ_DETAIL_STATE: {
        draft.currentQuizDetail.id = '';
        draft.currentQuizDetail.name = '';
        draft.currentQuizDetail.type_of_questionnaire = '';
        draft.currentQuizDetail.questions = [];
        draft.currentQuizDetail.departments = [];
        draft.currentQuizDetail.description = '';
        draft.currentQuizDetail.is_active = false;
        break;
      }

      default: {
        return draft;
      }
    }
  });
};
