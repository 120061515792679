export enum CustomersActions {
  GET_ALL_CUSTOMERS_REQUEST = 'GET_ALL_CUSTOMERS_REQUEST',
  GET_ALL_CUSTOMERS_SUCCESS = 'GET_ALL_CUSTOMERS_SUCCESS',
  GET_ALL_CUSTOMERS_FAILURE = 'GET_ALL_CUSTOMERS_FAILURE',
  GET_CUSTOMERS_DETAIL_REQUEST = 'GET_CUSTOMERS_DETAIL_REQUEST',
  GET_CUSTOMERS_DETAIL_SUCCESS = 'GET_CUSTOMERS_DETAIL_SUCCESS',
  GET_CUSTOMERS_DETAIL_FAILURE = 'GET_CUSTOMERS_DETAIL_FAILURE',
  GET_CEP_REQUEST = 'GET_CEP_REQUEST',
  GET_CEP_REQUEST_SUCCESS = 'GET_CEP_REQUEST_SUCCESS',
  GET_CEP_REQUEST_FAILURE = 'GET_CEP_REQUEST_FAILURE',
  EDIT_CUSTOMER_REQUEST = 'EDIT_CUSTOMER_REQUEST',
  EDIT_CUSTOMER_SUCCESS = 'EDIT_CUSTOMER_SUCCESS',
  EDIT_CUSTOMER_FAILURE = 'EDIT_CUSTOMER_FAILURE',
  CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST',
  CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS',
  CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE',
  GET_CLASSES_REQUEST = 'GET_CLASSES_REQUEST',
  GET_CLASSES_SUCCESS = 'GET_CLASSES_SUCCESS',
  GET_CLASSES_FAILURE = 'GET_CLASSES_FAILURE',
  EDIT_CUSTOMER_STATUS_REQUEST = 'EDIT_CUSTOMER_STATUS_REQUEST',
  EDIT_CUSTOMER_STATUS_SUCCESS = 'EDIT_CUSTOMER_STATUS_SUCCESS',
  EDIT_CUSTOMER_STATUS_FAILURE = 'EDIT_CUSTOMER_STATUS_FAILURE',
  GET_FILTERED_CUSTOMERS_REQUEST = 'GET_FILTERED_CUSTOMERS_REQUEST',
  GET_FILTERED_CUSTOMERS_SUCCESS = 'GET_FILTERED_CUSTOMERS_SUCCESS',
  GET_FILTERED_CUSTOMERS_FAILURE = 'GET_FILTERED_CUSTOMERS_FAILURE'
}

export type ApiFetchCustomerRequestPayloadParams = {
  customerId: string;
  cep: string;
  id: string;
  customerData: Customer;
};

export type ApiFetchSearchFilteredCustomersRequestPayloadParams = {
  filteredCustomers: Array<string | boolean | null>;
  isActive: boolean | null;
  customerSector: string | null;
  customerSubSector: string | null;
  customerSegment: string | null;
};

export interface ApiFetchCustomerStatusRequestPayloadParams {
  customerId: string
}

export interface Address {
  id: string;
  zip_code: string;
  address: string;
  complement: string;
  number_address: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
}

export interface AddressObject {
  zip_code: string;
  address: string;
  complement: string;
  number_address: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
}

export interface ZipCodeResponse {
  bairro: string;
  cep: string;
  cidade: string;
  complemento: string;
  logradouro: string;
  uf: string;
}

export interface Segment {
  id: string;
  subsector: string;
  name: string;
  is_active: boolean;
}

export interface Class {
  id: number | null;
  name: string;
}

export interface Sector {
  id: string;
  name: string;
}

export interface Subsector {
  id: string;
  name: string;
}

export interface Customer {
  id: string;
  segment: Segment;
  document_type: string;
  document_number: string;
  corporate_name_or_name: string;
  fantasy_name: string;
  phone: string;
  email: string;
  state_registration: string;
  municipal_registration: string;
  opting_for_the_simples: boolean;
  inclusion_date_rf: Date;
  interviewees: CustomerIntervieweesDetail[];
  status_rf: string;
  revenue: string;
  it_budget: string;
  class_enum: Class;
  is_active: boolean;
  address: Address;
  parent_company_subsidiary: string;
  sector: Sector;
  subsector: Subsector;
}

export interface EditCustomerInfos {
  id: string;
  segment: string;
  segment_name: string;
  document_number: string;
  corporate_name_or_name: string;
  fantasy_name: string;
  phone: string;
  email: string;
  state_registration: string;
  municipal_registration: string;
  opting_for_the_simples: boolean;
  inclusion_date_rf: Date;
  interviewees: CustomerIntervieweesDetail[];
  status_rf: string;
  revenue: string;
  it_budget: string;
  class_enum: string;
  is_active: boolean;
  address: Address;
  parent_company_subsidiary: string;
}

export interface Classes {
  classes: Class[];
}

export interface AddNewCustomerData {
  segment: string;
  segment_name: string;
  document_type: string;
  document_number: string;
  corporate_name_or_name: string;
  fantasy_name: string;
  phone: string;
  email: string;
  state_registration: string;
  municipal_registration: string;
  opting_for_the_simples: boolean;
  inclusion_date_rf: Date;
  status_rf: string;
  revenue: string;
  it_budget: string;
  class_enum: number | null;
  is_active: boolean;
  address: AddressObject;
  parent_company_subsidiary: string;
}

export interface CustomerIntervieweesDetail {
  id: string;
  name: string;
  position: string;
  phone: string;
  email: string;
  cpf: string;
  is_active: boolean;
}

export interface intervieweesSemID {
  name: string;
  position: string;
  phone: string;
  email: string;
  cpf: string;
  is_active: boolean;
}

export type customerInfoUpdateStatus = {
  customerId: string;
  customerStatus: boolean
}

export interface CustomerInterviewees {
  id: string;
  segment: Segment;
  segment_name: string;
  document_type: string;
  document_number: string;
  corporate_name_or_name: string;
  fantasy_name: string;
  phone: string;
  email: string;
  state_registration: string;
  municipal_registration: string;
  opting_for_the_simples: boolean;
  inclusion_date_rf: Date;
  status_rf: string;
  revenue: string;
  it_budget: string;
  class_enum: Class;
  is_active: boolean;
  address: Address;
  interviewees: CustomerIntervieweesDetail[];
  parent_company_subsidiary: string;
  sector: Sector;
  subsector: Subsector;
}

export interface EditCustomerStatus {
  id: string,
  is_active: boolean
}

export type EditCustomerParamsType = {
  customerId: string;
  customerData: AddNewCustomerData;
}


export interface ZipAdressObject {
  address: ZipCodeResponse
}

export type CustomersState = {
  customers: Customer[];
  customersDetails: CustomerInterviewees;
  address: Address;
  classes: Classes;
  zipCode: ZipAdressObject;
};

