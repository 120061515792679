import { useSelector } from 'react-redux';
import type { GlobalStore, StoreKeys } from 'store/types';

export function useMySelector<T>(storeName: StoreKeys) {

  const selector: T = useSelector<GlobalStore, T>(
    state => state[storeName] as unknown as T
  );

  if (!selector)
    throw new Error(
      'useMySelector must be used with a state valid option of the store'
    );

  return selector;
}
